import styled, { css } from 'styled-components';

// BlockSection is a group of related blocks.
// For example, a "Education" or "Employment" section.
const BlockSection = styled.div`
  ${({ theme, left, isBreakable }) => css`
    padding: ${theme.getPaddingMargin({ top: 30, bottom: 20 })};
    break-inside: avoid;
    page-break-inside: avoid;
    ${isBreakable &&
    css`
      break-inside: auto;
      page-break-inside: auto;
    `}
    ${left &&
    css`
      padding-top: ${theme.convertPx(25)};
      padding-bottom: 0;
      &:last-child {
        padding-bottom: ${theme.convertPx(15)};
      }
    `}
  `}
`;

export default BlockSection;
