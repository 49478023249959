import ImageWrapper from '../atoms/ImageWrapper';
import { getArticlesImages } from 'imports/help/api/articlesImages';
import { StyledText, StyledListContainer, StyledTextWithSpan } from '../atoms/Typography';
import { useResponsive } from 'imports/core/api/responsiveContext';

import ArticlePageWrapper from '/imports/help/ui/components/ArticlePageWrapper';
import useIntl from '/imports/core/api/useIntl';

const CustomizeResume = () => {
  const { t } = useIntl();
  const { host } = useResponsive();
  const images = getArticlesImages(host);
  return (
    <ArticlePageWrapper title="help.customize_resume">
      <StyledListContainer>
        <li>{t('help.customize_resume.list_item_1')}</li>
        <li>{t('help.customize_resume.list_item_2')}</li>
      </StyledListContainer>
      <StyledText>{t('help.customize_resume.layout_customization')}</StyledText>
      <StyledTextWithSpan dangerouslySetInnerHTML={{ __html: t('help.customize_resume.theme_and_color') }} />
      <ImageWrapper src={images.customize_resume_1} />
      <StyledTextWithSpan dangerouslySetInnerHTML={{ __html: t('help.customize_resume.change_template') }} />
      <ImageWrapper src={images.customize_resume_2} />
      <StyledTextWithSpan dangerouslySetInnerHTML={{ __html: t('help.customize_resume.add_remove_photo') }} />
      <StyledTextWithSpan dangerouslySetInnerHTML={{ __html: t('help.customize_resume.reorder_sections') }} />
      <ImageWrapper src={images.customize_resume_3} />
      <StyledTextWithSpan dangerouslySetInnerHTML={{ __html: t('help.customize_resume.column_switcher') }} />
      <ImageWrapper src={images.customize_resume_4} />
      <StyledTextWithSpan noMargin dangerouslySetInnerHTML={{ __html: t('help.customize_resume.change_fonts') }} />
      <StyledTextWithSpan noMargin indent dangerouslySetInnerHTML={{ __html: t('help.customize_resume.title_font') }} />
      <StyledTextWithSpan noMargin indent dangerouslySetInnerHTML={{ __html: t('help.customize_resume.body_font') }} />
      <ImageWrapper src={images.customize_resume_5} />
    </ArticlePageWrapper>
  );
};

export default CustomizeResume;
