const SuccessIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 96 96" fill="none">
      <circle opacity="0.05" cx="48" cy="48" r="48" fill="#1688FE" />
      <circle opacity="0.1" cx="48" cy="48" r="34" fill="#1688FE" />
      <circle cx="48" cy="48" r="24" fill="#1688FE" />
      <g filter="url(#filter0_d_3144_28375)">
        <path
          d="M44.7629 56.8565C44.3485 56.8565 43.935 56.6987 43.6186 56.3823L36 48.7637L38.2886 46.4742L44.7629 52.9485L57.7114 40L60 42.2895L45.9072 56.3823C45.5908 56.6987 45.1772 56.8565 44.7629 56.8565Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_3144_28375"
          x="32"
          y="40"
          width="32"
          height="24.8564"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3144_28375" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3144_28375" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default SuccessIcon;
