import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { displayTemplateTitle } from '/imports/generator/api/helpers';
import { renderDate } from '/imports/pdf/core/api/helpers';
import TextAtom from '/imports/pdf/budapest/ui/atoms/Text';
import useIntl from '/imports/core/api/useIntl';

const DateRangeDisplay = ({ startDate, endDate, current, showOnlyYear, hideStartDate, left, language }) => {
  const { t } = useIntl();
  return (
    <Text left={left}>
      {displayTemplateTitle(
        hideStartDate ? '' : renderDate(startDate, showOnlyYear, language, t),
        current ? t('present', language) : renderDate(endDate, showOnlyYear, language, t),
        '\u00A0-\u00A0',
      )}
    </Text>
  );
};

DateRangeDisplay.propTypes = {
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
  current: PropTypes.bool,
  showOnlyYear: PropTypes.bool,
  hideStartDate: PropTypes.bool,
  language: PropTypes.string,
  left: PropTypes.bool,
};

const Text = styled(TextAtom)`
  font-size: ${({ theme: { convertPx } }) => convertPx(11, true)};
  ${({ left, theme }) =>
    left &&
    css`
      text-transform: uppercase;
      display: inline;
      color: #e6e7e8;
      ${theme.color &&
      theme.color === 'budapestGirlBlue' &&
      css`
        color: #bcbec0;
      `}
    `}
`;

export default DateRangeDisplay;
