import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import Chips from '/imports/core/ui/atoms/Chips';
import Flex from '/imports/core/ui/atoms/Flex';
import { useState, useEffect } from 'react';
import { addSkillsToResume, removeSkillFromResume } from '/imports/generator/api/helpers';
import useTracking from '/imports/core/hooks/useTracking';
import { useRouter } from 'next/router';
import { useResponsive } from 'imports/core/api/responsiveContext';

const RelatedSkillsCloud = ({ resume, updateImmue, relatedSkills, currentView }) => {
  const [skills, setSkills] = useState(relatedSkills);
  const { trackEvent } = useTracking();
  const {
    query: { step },
  } = useRouter();
  const { isMobile } = useResponsive();
  useEffect(() => {
    setSkills(relatedSkills);
  }, [relatedSkills]);
  const addSkill = (skill) => {
    addSkillsToResume(resume, [skill], updateImmue, true);
  };

  const handleSkillClick = (index) => {
    addSkill(skills[index]);
    const leftSkills = skills.filter((skill, i) => i !== index);
    setSkills(leftSkills);
    trackEvent('related_skills_clicked', { skill: skills[index], view: step });
  };

  const render10FirstSkills = () => {
    return skills.slice(0, 10).map((skill, index) => (
      <Chips key={skills.indexOf(skill)} onClick={() => handleSkillClick(index)} isCloudVariant>
        {skill}
      </Chips>
    ));
  };

  return (
    <Wrapper $isSkillsView={currentView === 'skills' && !isMobile}>
      {skills.length > 0 && <SkillsList>{render10FirstSkills()}</SkillsList>}
    </Wrapper>
  );
};
export default RelatedSkillsCloud;
RelatedSkillsCloud.propTypes = {
  resume: PropTypes.object,
  updateImmue: PropTypes.func,
  relatedSkills: PropTypes.array,
  currentView: PropTypes.string,
};

const Wrapper = styled.div`
  margin-bottom: 32px;
  ${({ $isSkillsView }) =>
    $isSkillsView &&
    css`
      margin-top: -25px;
    `}
`;
const SkillsList = styled(Flex)`
  gap: 8px;
  flex-wrap: wrap;
`;
