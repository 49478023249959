import React from 'react';
import styled from 'styled-components';

const SliderIcon = () => {
  return (
    <SvgIcon width="26" height="26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 4a1 1 0 012 0v8H4V4z" fill="#fff" />
      <rect x="1" y="14" width="8" height="2" rx="1" fill="#fff" />
      <path d="M4 16h2v6a1 1 0 11-2 0v-6zM12 3a1 1 0 112 0v5h-2V3z" fill="#fff" />
      <rect x="9" y="8" width="8" height="2" rx="1" fill="#fff" />
      <path d="M12 12h2v10a1 1 0 11-2 0V12z" fill="#fff" />
      <rect x="20" y="3" width="2" height="11" rx="1" fill="#fff" />
      <rect x="17" y="16" width="8" height="2" rx="1" fill="#fff" />
      <path d="M20 18h2v4a1 1 0 11-2 0v-4z" fill="#fff" />
    </SvgIcon>
  );
};

const SvgIcon = styled.svg`
  stroke: none;
`;

export default SliderIcon;
