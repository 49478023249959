import React from 'react';
import { staticFile } from 'lib/helpers';
import styled, { css } from 'styled-components';

import BasicContainer from '/imports/homepageV3/ui/atoms/BasicContainer';
import BasicSectionWrapper from '/imports/homepageV3/ui/atoms/BasicSectionWrapper';
import DescriptionImageWrapper from '/imports/homepageV3/ui/atoms/DescriptionImageWrapper';
import IconRight from '/imports/homepageV3/ui/atoms/svg/IconRight';
import ImageBlock from '/imports/homepageV3/ui/atoms/ImageBlock';
import PrimaryBtn from '/imports/homepageV3/ui/atoms/PrimaryBtn';
import StyledH2 from '/imports/homepageV3/ui/atoms/StyledH2';
import StyledImage from '/imports/homepageV3/ui/atoms/StyledImage';
import StyledParagraph from '/imports/homepageV3/ui/atoms/StyledParagraph';
import TextBlock from '/imports/homepageV3/ui/atoms/TextBlock';
import TextWrapper from '/imports/homepageV3/ui/atoms/TextWrapper';
import useIntl from '/imports/core/api/useIntl';

const Section3 = ({ userIdentified, onClickLandingCTA }) => {
  const { t } = useIntl();
  return (
    <Section3Wrapper>
      <RelativeContainer>
        <ReverseDescriptionImageWrapper>
          <ImageBlock>
            <StyledImage
              src={staticFile('img/landingv3/section3.png')}
              loading="lazy"
              sizes="(max-width: 479px) 93vw, (max-width: 767px) 55vw, 38vw"
              $srcset={`
                  ${staticFile('section3.png')} 500w,
                  ${staticFile('section3.png')} 800w,
                  ${staticFile('section3.png')}       972w
                `}
              alt=""
            />
          </ImageBlock>
          <TextBlock $direction="column">
            <TextWrapper $direction="column">
              <StyledH2>{t('resumizeme_landing_sec3_title')}</StyledH2>
              <StyledParagraph>{t('resumizeme_landing_sec3_text')}</StyledParagraph>
            </TextWrapper>
            <PrimaryBtn onClick={onClickLandingCTA}>
              <div>{!userIdentified ? t('landing.cta') : t('landing.my_resumes')}</div>
              <IconRight />
            </PrimaryBtn>
          </TextBlock>
        </ReverseDescriptionImageWrapper>
      </RelativeContainer>
      <GreenHighlight />
    </Section3Wrapper>
  );
};
const Section3Wrapper = styled(BasicSectionWrapper)`
  min-height: 630px;
  position: relative;
  @media (max-width: 991px) {
    min-height: 560px;
  }
`;
const RelativeContainer = styled(BasicContainer)`
  margin-top: 72px;
  margin-bottom: 72px;
  @media (max-width: 767px) {
    margin-top: 0;
    margin-bottom: 0;
  }
`;
const ReverseDescriptionImageWrapper = styled(DescriptionImageWrapper)`
  @media (max-width: 767px) {
    flex-direction: column-reverse;
  }
`;
const GreenHighlight = styled.div`
  position: absolute;
  left: 0px;
  top: 0;
  right: auto;
  bottom: 0%;
  z-index: -1;
  width: 384px;
  height: 100%px;
  border-radius: 0px 48px 48px 0px;
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      left: auto;
      top: 0;
      right: 0px;
      bottom: 0;
      z-index: -1;
      border-radius: 48px 0px 0px 48px;
    `}
  background-color: #e6fcff;
  @media (max-width: 980px) {
    width: 40%;
  }
  @media (max-width: 767px) {
    display: none;
  }
`;
export default Section3;
