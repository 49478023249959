import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes, css } from 'styled-components';
import { useMutation } from 'react-apollo';

import env from '/env';
import { getCountry } from '/imports/checkout/api/utils';
import { MARK_AS_PAID_USER } from '/imports/checkout/api/apollo/client/mutations';
import { useAccount } from '/imports/core/api/accounts/accountContext';
import useBilling from '/imports/checkout/api/useBilling';
import useIntl from '/imports/core/api/useIntl';
import { useResponsive } from '/imports/core/api/responsiveContext';

function PaymentFormV4({ trackPaymentError, onPaymentSuccess }) {
  const { client, currentPlan } = useBilling();
  const { currentUser } = useAccount();
  const { host } = useResponsive();
  const [isLoadScript, setIsLoadScript] = useState(false);
  const [frameLoaded, setFrameLoaded] = useState(false);
  const [upgradingUser, setUpgradingUser] = useState(false);
  const { locale } = useIntl();

  const [markAsPaidUser] = useMutation(MARK_AS_PAID_USER, {
    client,
  });

  const onSuccess = async () => {
    setUpgradingUser(true);

    // Mark the user as paid_user to update the user role
    await markAsPaidUser();
    onPaymentSuccess();
  };

  useEffect(() => {
    let timeout;
    if (!!window.Paddle) return setIsLoadScript(true);
    const paddleScript = document.querySelector('#paddle-js');
    if (!paddleScript) {
      const script = document.createElement('script');
      script.src = 'https://cdn.paddle.com/paddle/paddle.js';
      script.id = 'paddle-js';
      document.querySelector('head').appendChild(script);
      script.onload = () => {
        setIsLoadScript(true);
      };
    } else {
      paddleScript.onload = () => {
        setIsLoadScript(true);
      };
    }
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    if (!isLoadScript) return;
    const { Paddle } = window;
    Paddle.Setup({
      vendor: +env.PADDLE_VENDOR_ID, // convert to integer
      eventCallback: function (data) {
        const { event } = data;
        switch (event) {
          case 'Checkout.Complete':
            return onSuccess();
          case 'Checkout.Error': // TODO: identify payment errors events for GA logs
            trackPaymentError(data.eventData.error);
            break;
        }
      },
    });
    Paddle.Checkout.open({
      method: 'inline',
      product: currentPlan.paddleProduct,
      email: currentUser.email,
      postcode: currentUser.postalCode,
      allowQuantity: false,
      disableLogout: true,
      frameTarget: 'checkout-container-paddle',
      frameInitialHeight: 416,
      frameStyle: 'width:100%; min-width:312px; background-color: transparent; border: none;',
      override: currentPlan.overrideUrl,
      locale,
      passthrough: `{"host": ${host}, "country": ${getCountry()}}`,
      loadCallback: () => {
        setFrameLoaded(true);
      },
    });
    return () => {};
  }, [isLoadScript]);

  return (
    <PaddleContainer className="checkout-container-paddle" style={{ minHeight: 600 }}>
      {(!frameLoaded || upgradingUser) && (
        <LoaderWrapper blurred={upgradingUser}>
          <Loader />
        </LoaderWrapper>
      )}
    </PaddleContainer>
  );
}

const PaddleContainer = styled.div`
  min-height: 600px;
  width: 100%;
  position: relative;
`;

export const spinAnimation = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: #fff;
  z-index: 9999;
  ${({ blurred }) =>
    blurred &&
    css`
      opacity: 0.8;
      top: 0;
    `}
`;

const Loader = styled.div`
  background: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid rgb(235, 236, 240);
  border-top-color: rgb(187, 187, 187);
  animation: 1s ${spinAnimation} infinite linear;
`;

PaymentFormV4.propTypes = {
  trackPaymentError: PropTypes.func,
  onPaymentSuccess: PropTypes.func,
};

export default PaymentFormV4;
