import React from 'react';

const Quality11Icon = () => {
  return (
    <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M42.5 2.38542H6.375C4.02779 2.38542 2.125 4.2509 2.125 6.55209V41.3646C2.125 43.6658 4.02779 45.5312 6.375 45.5312H42.5C44.8472 45.5312 46.75 43.6658 46.75 41.3646V6.55209C46.75 4.2509 44.8472 2.38542 42.5 2.38542Z"
        fill="#5DDFA9"
      />
      <path
        d="M24.8198 2.38542L24.8198 45.5313H6.37482C4.0267 45.5313 2.12482 43.6667 2.12482 41.3646L2.12482 6.55209C2.12482 4.25 4.0267 2.38542 6.37482 2.38542L24.8198 2.38542Z"
        fill="#83F8C7"
      />
      <path
        d="M38.6323 16.7812H11.0073C9.83372 16.7812 8.88232 17.714 8.88232 18.8646V36.1771C8.88232 37.3277 9.83372 38.2604 11.0073 38.2604H38.6323C39.8059 38.2604 40.7573 37.3277 40.7573 36.1771V18.8646C40.7573 17.714 39.8059 16.7812 38.6323 16.7812Z"
        fill="#0043A6"
      />
      <path
        d="M33.7875 18.3438H29.8032V14.4375H19.8369V18.3438H15.8525V14.4375C15.8525 12.2813 17.6375 10.5312 19.8369 10.5312H29.8032C32.0025 10.5312 33.7875 12.2813 33.7875 14.4375V18.3438Z"
        fill="#0050C7"
      />
      <path
        d="M38.6323 16.7812H11.0073C9.83372 16.7812 8.88232 17.714 8.88232 18.8646V27.4062C8.88232 28.5568 9.83372 29.4896 11.0073 29.4896H38.6323C39.8059 29.4896 40.7573 28.5568 40.7573 27.4062V18.8646C40.7573 17.714 39.8059 16.7812 38.6323 16.7812Z"
        fill="#0866F5"
      />
      <path
        d="M38.6323 16.7812H11.0073C9.83372 16.7812 8.88232 17.714 8.88232 18.8646V36.1771C8.88232 37.3277 9.83372 38.2604 11.0073 38.2604H38.6323C39.8059 38.2604 40.7573 37.3277 40.7573 36.1771V18.8646C40.7573 17.714 39.8059 16.7812 38.6323 16.7812Z"
        fill="#0043A6"
      />
      <path
        d="M11.0073 16.7812H24.8198V38.2604H11.0073C9.83857 38.2604 8.88232 37.3229 8.88232 36.1771V18.8646C8.88232 17.7188 9.83857 16.7812 11.0073 16.7812Z"
        fill="#0050C7"
      />
      <path
        d="M33.7875 18.3438H29.8032V14.4375H19.8369V18.3438H15.8525V14.4375C15.8525 12.2813 17.6375 10.5312 19.8369 10.5312H29.8032C32.0025 10.5312 33.7875 12.2813 33.7875 14.4375V18.3438Z"
        fill="#0050C7"
      />
      <path
        d="M24.82 10.5312H19.8369C17.6375 10.5312 15.8525 12.2813 15.8525 14.4375V18.3438H19.8369V14.4375H24.82V10.5312Z"
        fill="#0050C7"
      />
      <path
        d="M11.0073 16.7812H38.6323C39.8011 16.7812 40.7573 17.7187 40.7573 18.8646V25.3229C40.7573 27.625 38.8554 29.4896 36.5073 29.4896H13.1323C10.7842 29.4896 8.88232 27.625 8.88232 25.3229V18.8646C8.88232 17.7187 9.83857 16.7812 11.0073 16.7812Z"
        fill="#0866F5"
      />
      <path
        d="M11.0073 16.7812H24.8198V29.4896H13.1323C10.7842 29.4896 8.88232 27.625 8.88232 25.3229V18.8646C8.88232 17.7187 9.83857 16.7812 11.0073 16.7812Z"
        fill="#378EFF"
      />
      <path
        d="M17.4995 26.5625H17.4888C16.3915 26.5625 15.502 27.4346 15.502 28.5104V30.4792C15.502 31.555 16.3915 32.4271 17.4888 32.4271H17.4995C18.5968 32.4271 19.4863 31.555 19.4863 30.4792V28.5104C19.4863 27.4346 18.5968 26.5625 17.4995 26.5625Z"
        fill="white"
      />
      <path
        d="M32.1621 26.5625H32.1514C31.0541 26.5625 30.1646 27.4346 30.1646 28.5104V30.4687C30.1646 31.5446 31.0541 32.4167 32.1514 32.4167H32.1621C33.2594 32.4167 34.1489 31.5446 34.1489 30.4687V28.5104C34.1489 27.4346 33.2594 26.5625 32.1621 26.5625Z"
        fill="white"
      />
    </svg>
  );
};
export default Quality11Icon;
