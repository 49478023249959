import gql from 'graphql-tag';

import { userFull } from '/imports/core/api/apollo/client/fragments';

export const SET_USER_DETAILS = gql`
  mutation SetUserDetails(
    $firstName: String
    $lastName: String
    $email: String!
    $phone: String
    $address: String
    $postalCode: String
    $city: String
    $showDisclaimer: Boolean
    $primaryLanguage: String
    $gender: String
  ) {
    setUserDetails(
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
      address: $address
      postalCode: $postalCode
      city: $city
      showDisclaimer: $showDisclaimer
      primaryLanguage: $primaryLanguage
      gender: $gender
    ) {
      success
      next
      user {
        ...UserFull
      }
    }
  }
  ${userFull}
`;

export const GO_BACK_TO_NAME = gql`
  mutation GoBackToName {
    goBackToName {
      success
      user {
        ...UserFull
      }
      error
      next
      finished
    }
  }
  ${userFull}
`;
