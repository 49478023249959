import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Flex from '/imports/core/ui/atoms/Flex';
import Logo from '/imports/core/ui/components/Logo';
import { useResponsive } from '/imports/core/api/responsiveContext';

const Header = () => {
  const { isMobile } = useResponsive();
  return (
    <HeaderWrapper $isMobile={isMobile}>
      <Container>
        <StyledFlex $justifyContent="space-between" $fullWidth>
          <HeaderFlex $alignItems="center">
            <Logo noLink />
          </HeaderFlex>
        </StyledFlex>
      </Container>
    </HeaderWrapper>
  );
};

const Container = styled.div`
  display: flex;
`;

const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  background: #fff;
  padding-inline: 20px;
  z-index: 1;
  box-shadow: 0 2px 4px 0 rgba(199, 207, 214, 0.2);
  position: relative;

  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      justify-content: end;
    `}

  ${({ $isMobile }) =>
    !$isMobile &&
    css`
      padding-block: 20px;
    `}

  ${({ theme }) => theme.max('md')`
    height: 64px;
    margin: 0;
  `}
`;

const StyledFlex = styled(Flex)`
  ${({ theme }) => theme.max('md')`
    padding: 20px 0;
    align-items: center;
  `}
`;

const HeaderFlex = styled(Flex)`
  align-self: center;
`;

Header.propTypes = {
  isFullScreen: PropTypes.bool,
};

export default Header;
