import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useForm, Controller, Form, FormProvider } from 'react-hook-form';
import { useMutation } from 'react-apollo';
import { isEmpty } from 'lodash';

import Button from '/imports/core/ui/atoms/Button';
import { CURRENT_USER } from '/imports/core/api/apollo/client/queries';
import { ERROR_MESSAGES } from '/imports/core/api/constants';
import FieldLabel from '/imports/generator/ui/atoms/FieldLabel';
import Flex from '/imports/core/ui/atoms/Flex';
import FormGroup from '/imports/core/ui/atoms/FormGroup';
import { isJobTrackEnable } from '/lib/helpers';
import Label from '/imports/core/ui/atoms/Label';
import PhotoUpload from '/imports/core/ui/components/PhotoUpload';
import { replaceString } from '/imports/core/ui/helpers';
import { TextContainer } from '/imports/dashboard/ui/atoms';
import { UPDATE_USER } from '/imports/dashboard/api/apollo/client/mutations';
import ValidatedInput from '/imports/core/ui/atoms/ValidatedInput';
import useIntl from '/imports/core/api/useIntl';
import useDomain from 'imports/core/hooks/useDomain';
import { emailValidatorPattern } from '/imports/core/api/validators';
import GeneratorValidatedInput from '/imports/core/ui/atoms/GeneratorValidatedInput';
import { useAccount } from '/imports/core/api/accounts/accountContext';

AccountSettingsForm.propTypes = {
  onSubmit: PropTypes.func,
  updateUserDetails: PropTypes.func,
  currentUser: PropTypes.object,
  userLoading: PropTypes.bool,
  buttonContent: PropTypes.string,
  showPhoto: PropTypes.bool,
  isCheckout: PropTypes.bool,
  t: PropTypes.func,
  buttonContentSlug: PropTypes.string,
  getDomainName: PropTypes.func,
};

const inputList = [
  {
    name: 'firstName',
    slug: 'generator.heading.first_name',
    placeholder: 'Peter',
    placeholderSlug: 'peter',
    rules: { required: ERROR_MESSAGES.required },
  },
  {
    name: 'lastName',
    slug: 'generator.heading.last_name',
    placeholder: 'Cetera',
    placeholderSlug: 'cetera',
    rules: { required: ERROR_MESSAGES.required },
  },
  ,
  {
    name: 'city',
    slug: 'generator.heading.city',
    placeholder: 'London',
    placeholderSlug: 'london',
  },
  ,
  {
    name: 'postalCode',
    slug: 'generator.heading.postal_code',
    placeholder: 'E2 4XF"',
    placeholderSlug: 'postalcode',
  },
  ,
  {
    name: 'address',
    slug: 'generator.heading.address',
    placeholder: '123 Example Road',
    placeholderSlug: 'example_address',
    fullWidth: true,
  },
  ,
  {
    type: 'email',
    name: 'email',
    slug: 'generator.heading.email',
    placeholder: 'you@example.com',
    rules: {
      required: ERROR_MESSAGES.requiredEmail,
      pattern: { value: emailValidatorPattern, message: ERROR_MESSAGES.email },
    },
  },
  {
    type: 'tel',
    name: 'phone',
    slug: 'generator.heading.phone',
    placeholder: '+442223334444',
  },
  {
    type: 'password',
    name: 'password',
    slug: 'tc_que3_d',
    placeholder: 'Password',
    placeholderSlug: 'tc_que3_d',
  },
];
export default function AccountSettingsForm(props) {
  const { onSubmit, showPhoto = true, buttonContent = 'Save', buttonContentSlug = 'checkout.save' } = props;
  const { currentUser } = useAccount();
  const [updateUserDetails, updateUserDetailsMutationResults] = useMutation(UPDATE_USER);
  const [loading, setLoading] = useState(props.userLoading || false);
  const [globalErrors, setGlobalsErrors] = useState({
    error: false,
    messages: '',
  });
  const formRef = useRef();
  const { t } = useIntl();
  const { getDomainName } = useDomain();
  const user_info = replaceString(t('account.use_info'), { 'resumedone.co': getDomainName() });
  const isJobTrack = isJobTrackEnable();

  const hookFormMethods = useForm({
    defaultValues: currentUser,
    mode: 'onChange',
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = hookFormMethods;

  const onSettingFormSubmit = async (data) => {
    const { isCheckout = false } = props;
    const { firstName, lastName, email, city, address, postalCode, phone, password } = data;
    setLoading(true);

    try {
      const variables = {
        firstName,
        lastName,
        email: email.toLowerCase(),
        address,
        city,
        postalCode,
        phone,
      };

      if (password) {
        variables.password = password;
      }
      await updateUserDetails({
        variables,
        refetchQueries: [{ query: CURRENT_USER }],
      });
      setLoading(false);
    } catch (error) {
      const { message } = error;
      const finalMessage = message.split(': ');
      setGlobalsErrors({
        error: true,
        messages: isCheckout
          ? t('error_email_duplicate').replace('UNDEFINED', email.toLowerCase())
          : t('error_email_duplicate_without_variables'),
      });
      setLoading(false);
    }
  };
  const handleUpload = (value) => {
    if (currentUser.email === null) {
      if (this.formRef.current) {
        this.formRef.current.submit();
      }
      return;
    }
    updateUserDetails({
      variables: {
        ...currentUser,
        photo: value,
      },
      refetchQueries: [{ query: CURRENT_USER }],
    });
  };
  const onRemovePhoto = (e) => {
    e.stopPropagation();

    updateUserDetails({
      variables: {
        ...currentUser,
        photo: null,
        isValidUserPicture: true,
      },
      refetchQueries: [{ query: CURRENT_USER }],
    });
  };
  return (
    <Container>
      <FlexContainer justifyContent="space-between">
        {showPhoto && (
          <AccountPhotoUpload
            onUploaded={handleUpload}
            value={currentUser?.photo}
            onRemovePhoto={onRemovePhoto}
            account
          />
        )}
        <FormProvider {...hookFormMethods}>
          <StyledValidatorForm ref={formRef} control={control} onSubmit={handleSubmit(onSettingFormSubmit)}>
            <FormInputsContainer>
              <FormInputsContainer>
                {inputList.map((input, i) => {
                  return (
                    <FormSettingsGroup key={i} $fullWidth={input.fullWidth}>
                      {isJobTrack ? (
                        <FieldLabel
                          label={t(input.slug)}
                          background="transparent"
                          summary
                          accountPage
                          htmlFor={input.name}
                        />
                      ) : (
                        <Label form={1} htmlFor={input.name}>
                          {t(input.slug)}
                        </Label>
                      )}
                      <Controller
                        control={control}
                        name={input.name}
                        rules={input?.rules || {}}
                        render={({ field, field: { name, value } }) => (
                          <GeneratorValidatedInput
                            field={field}
                            type={input.type}
                            id={name}
                            placeholder={input.placeholder}
                            placeholderSlug={input.placeholderSlug}
                            errors={errors}
                            value={value}
                            isJobTrack={isJobTrack}
                          />
                        )}
                      />
                    </FormSettingsGroup>
                  );
                })}
                <TextContainerForm>
                  {globalErrors?.error ? (
                    <ErrorMessage dangerouslySetInnerHTML={{ __html: globalErrors?.messages }} />
                  ) : (
                    <p>
                      <span dangerouslySetInnerHTML={{ __html: user_info }} />
                    </p>
                  )}
                </TextContainerForm>
              </FormInputsContainer>
              <ButtonContainer>
                <SaveButton rounded type="submit" disabled={loading}>
                  {loading
                    ? `${t('checkout.saving')}...`
                    : buttonContentSlug && t(buttonContentSlug)
                      ? t(buttonContentSlug)
                      : buttonContent}
                </SaveButton>
              </ButtonContainer>
            </FormInputsContainer>
          </StyledValidatorForm>
        </FormProvider>
      </FlexContainer>
    </Container>
  );
}

const StyledValidatorForm = styled(Form)`
  width: 100%;
  @media (min-width: 768px) {
    width: auto;
  }
`;

const InputCss = css`
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 15px;
  line-height: 22px;
  padding: 13px 12px;
  color: var(--dark-values-black);
  border-radius: 4px;
  border: 1px solid #e3e3e4;
  background-color: var(--light-values-white) !important;
  ::placeholder {
    font-family: ${({ theme }) => theme.font.family.websiteMedium};
    color: var(--grayscale-n400);
  }
`;

const Input = styled(ValidatedInput)`
  ${({ theme }) =>
    theme.isJobTrack &&
    css`
      :-webkit-autofill {
        transition: none;
      }
      ${InputCss}
    `}
`;

const Container = styled.div`
  width: 100%;
`;

const SaveButton = styled(Button)`
  border: 1px solid #0087c1;
  background: #0087c1;
  color: #fff;
  transition: all 0.2s;
  &:hover {
    border-color: #1a5885;
    background: #1a5885;
    color: #fff;
  }
  ${({ theme: { max } }) => max('sm')`
    width: 100%;
  `}
  ${({ theme }) =>
    theme.isJobTrack &&
    css`
      background: #1688fe;
      border-radius: 4px;
      font-size: 15px;
      line-height: 22px;
      font-family: ${theme.font.family.websiteSemiBold};
      border: none;
    `}
`;

const FormInputsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  ${({ theme }) => theme.max('sm')`
      flex-direction: column;
      align-items: start;
  `}
  ${({ theme }) =>
    theme.isJobTrack &&
    css`
      margin-top: 8px;
      gap: 16px;
      @media (min-width: 768px) {
        flex-direction: row;
        max-width: 491px;
      }
      @media (min-width: 1024px) {
        flex-direction: row;
        width: 100%;
        max-width: 100%;
      }
    `}
`;

const FormSettingsGroup = styled((p) => <FormGroup {...p} />)`
  width: 50%;
  &:nth-child(odd) {
    flex: 0 0 calc(50% - 20px);
  }
  ${({ theme }) => theme.max('sm')`
  width: 100%;
`}
  ${({ $fullWidth }) =>
    $fullWidth &&
    `
flex: 0 0 100% !important;
`}

  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      right: 14px;
    `}
  & span {
    position: initial;
  }
  ${({ theme }) =>
    theme.isJobTrack &&
    css`
      position: relative;
      @media (min-width: 768px) {
        width: 50%;
      }
      & > label {
        div {
          top: 12px;
        }
      }
    `}
`;

const AccountPhotoUpload = styled((p) => <PhotoUpload {...p} />)`
  flex-wrap: wrap;
`;

const FlexContainer = styled((p) => <Flex {...p} />)`
  ${({ theme }) => theme.max('sm')`
    flex-wrap: wrap;
    flex-direction: column;
  `}

  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
    ${({ theme }) =>
    theme.isJobTrack &&
    css`
      flex-direction: row-reverse;
      gap: 16px;
      @media (min-width: 768px) {
        flex-direction: row-reverse;
      }
    `}
`;

const TextContainerForm = styled((p) => <TextContainer {...p} />)`
  flex: auto;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 25px;

  & p {
    max-width: none;
  }
  ${({ theme }) =>
    theme.isJobTrack &&
    css`
      padding: 0;
      & p {
        font-size: 15px;
        line-height: 22px;
        color: var(--dark-values-dark-regular);
        font-family: ${theme.font.family.websiteMedium};
      }
      a {
        cursor: default;
      }
    `}
  ${({ theme }) => theme.max('sm')`
width: 100%;
max-width: none;
padding-top: 5px;
padding-bottom: 15px;
margin-top: -8px;
  & p {
    max-width: none;
  }
`}
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
`;

const ButtonContainer = styled.div`
  width: 100%;
  line-height: 28px;
  padding-top: 8px;
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
  ${({ theme }) =>
    theme.isJobTrack &&
    css`
      padding-top: 0;
    `}
`;

const ErrorMessage = styled.p`
  color: ${(p) => p.theme.colors.red} !important;

  &:first-letter {
    text-transform: capitalize;
  }
`;
