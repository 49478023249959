import React from 'react';
import styled, { css } from 'styled-components';

import { cdnFile, isBlogDomain } from '/lib/helpers';
import HeroItem from '/imports/landing/ui/atoms/HeroItem';
import RightImageMain from '/imports/landing/ui/atoms/RightImageMain';
import { SimpleArrow } from '/imports/landing/ui/assets';
import useIntl from '/imports/core/api/useIntl';
import { useResponsive } from '/imports/core/api/responsiveContext';

const RightSide = ({ onClickLandingCTA }) => {
  const { host } = useResponsive();
  const blogDomain = isBlogDomain(host);
  const { locale } = useIntl();

  const getImage = () => {
    if (blogDomain && locale === 'es') return 'landing/ESLanding.jpg';
    if (blogDomain && locale === 'fr') return 'landing/FRLanding.jpg';
    return '';
  };

  return (
    <HeroItem onClick={onClickLandingCTA}>
      <HeroWrapper>
        <ArrowWrapperComp>
          <SimpleArrow />
        </ArrowWrapperComp>
        <HeroItemInner>
          <RightImageMain $path={getImage()}>
            <ImgInner />
          </RightImageMain>
          <RightImageSecondary>
            <ImgInner />
          </RightImageSecondary>
        </HeroItemInner>
      </HeroWrapper>
    </HeroItem>
  );
};

const ImgInner = styled.div`
  padding-top: 141.1992263%;
`;

const HeroItemInner = styled.div`
  position: relative;
  padding: 20px;
`;

const HeroWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  height: 100%;
  margin-left: 0;
`;

const RightImageSecondary = styled.div`
  width: 45vw;
  background-image: ${({ theme: { isRTL } }) =>
    `url(${isRTL ? cdnFile('vladivostok-fliped.jpg') : cdnFile('vladivostok.jpg')})`};
  max-width: 517px;
  background-position-x: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0 20px 100px 0 rgba(23, 31, 38, 0.2);
  position: absolute;
  top: 20px;
  left: calc(100% + 20px);
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      transform: scale(-1, 1);
    `};
  ${({ theme }) =>
    theme.min('sm')`
      width: 32.3125vw;
  `};
`;

const ArrowWrapperComp = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 12px 0 0 12px;
  cursor: pointer;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.5s;
  background-color: rgba(243, 113, 1, 0.89);
  &:hover {
    background-color: rgba(243, 113, 1);
  }
  width: 52px;
  height: 104px;
  ${({ theme }) =>
    theme.max('sm')`
     display:none;
   `}
`;

export default RightSide;
