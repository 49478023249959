import React from 'react';
import Skeleton from 'react-loading-skeleton';
import styled, { css } from 'styled-components';

import AccountPageWrapper from '/imports/dashboard/ui/wrapper/AccountPageWrapper';

const AccountSkeleton = ({ isNew }) => (
  <AccountPageWrapper isNew={isNew} isSkeleton disableTitle>
    <Box>
      <Skeleton width={75} height={75} circle />
      <SmallTitle>
        <Skeleton count={4} />
      </SmallTitle>
    </Box>
    <MainBox>
      <Skeleton width={140} height={140} circle />
      <RowWrapper>
        <Skeleton count={5} />
      </RowWrapper>
    </MainBox>
  </AccountPageWrapper>
);

export default AccountSkeleton;

const RowWrapper = styled.div`
  font-size: 40px;
  line-height: 70px;
  display: flex;
  width: 100%;
  flex-direction: column;
  ${({ theme: { max, isRTL } }) => css`
    margin: ${isRTL ? '0 60px 0 0' : '0 0 0 60px'};
    ${max('sm')`
      margin: 15px 0 0 0;
    `}
  `}
`;

const SmallTitle = styled.div`
  font-size: 13px;
  line-height: 2;
  display: flex;
  flex-direction: column;
  width: 100%;
  ${({ theme: { isRTL, max } }) => css`
    margin: ${isRTL ? '0 30px 0 0' : '0 0 0 30px'};
    ${max('sm')`
      margin: 15px 0 0 0;
    `}
  `}
`;

const Box = styled.div`
  display: flex;
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
  padding: 24px 30px;
  margin-bottom: 40px;
  ${({ theme: { isRTL, max } }) => css`
    ${isRTL &&
    css`
      direction: rtl;
    `}
    ${max('sm')`
      width: 100%;
      border-radius: 0;
      padding: 20px !important;
      flex-direction: column;
      align-items: center;
    `}
  `}
`;

const MainBox = styled(Box)`
  padding: 42px 30px;
  ${({ theme }) => theme.max('sm')`
    align-items: flex-start;
  `}
`;
