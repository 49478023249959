import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { ClearCloseIcon, AlertTriangleIcon } from '/imports/core/ui/assets';
import Modal from '/imports/core/ui/atoms/Modal';
import useIntl from '/imports/core/api/useIntl';

const modalStyles = {
  modalContainer: {
    display: 'flex',
    backgroundColor: 'rgba(52, 60, 73, 0.5)',
  },
  modalBackdrop: {
    backgroundColor: 'transparent',
  },
  modalBody: {
    maxWidth: '660px',
    background: '#fff',
    margin: 'auto',
    width: '100%',
    height: 'auto',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '4px',
    flexGrow: '0',
  },
};

function DocxModel({ toggleModal, onClickDownload, open, loading }) {
  const { t } = useIntl();
  return (
    <Modal onClose={toggleModal} styles={modalStyles} openStateBase open={open} animation="empty">
      <CloseWrapper>
        <button onClick={toggleModal}>
          <ClearCloseIcon />
        </button>
      </CloseWrapper>
      <ContentWrapper>
        <Header>
          <AlertTriangleIcon />
          {t('header.model_docx_t1')}
        </Header>
        <DetailWrapper>{t('header.model_docx_t2')}</DetailWrapper>
        <DetailWrapper>{t('header.model_docx_t3')}</DetailWrapper>
        <FooterWrapper>
          <DownloadLink disabled={loading} onClick={onClickDownload} data-value={'docx'} passHref>
            {t('header.model_docx_download')}
          </DownloadLink>
        </FooterWrapper>
      </ContentWrapper>
    </Modal>
  );
}

export default DocxModel;

DocxModel.propTypes = {
  toggleModal: PropTypes.func,
  onClickDownload: PropTypes.func,
  open: PropTypes.bool,
  link: PropTypes.string,
};

const DownloadLink = styled.a`
  margin: 0 auto;
  padding: 14px 30px 13px;
  border-radius: 3px;
  background-color: #1688fe;
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 16px;
  font-weight: bold;
  color: var(--light-values-white);
  cursor: pointer;
  text-decoration: none;
  ${({ disabled }) =>
    disabled &&
    css`
      border-color: ${(p) => p.theme.colors.gray.light};
      background-color: ${(p) => p.theme.colors.gray.light};
    `}
`;

const FooterWrapper = styled.div`
  display: flex;
  margin-top: 28px;
`;

const DetailWrapper = styled.div`
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #747baa;
  margin-top: 12px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  color: #424467;
  font-weight: 600;
  font-size: 24px;
  svg {
    margin: 3px 12px 0 0;
    ${({ theme: { isRTL } }) =>
      isRTL &&
      css`
        margin: 3px 0 0 12px;
      `}
  }
`;

const ContentWrapper = styled.div`
  padding: 42px 56px 48px;
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
`;

const CloseWrapper = styled.div`
  border-radius: 100%;
  position: absolute;
  width: 40px;
  height: 40px;

  button {
    background: transparent;
    border: none;
    cursor: pointer;
    position: relative;
    top: 6px;
  }

  padding: 10px 10px 0 0;
  background-color: transparent;
  float: right;
  position: static;
  transform: none;
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      float: left;
      padding: 10px 0 0 10px;
    `}
`;
