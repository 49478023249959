import {
  setBoards,
  addToBoards,
  openModal,
  closeModal,
  deleteBoard,
  boardRename,
  addToBoardsJob,
  jobStartApplication,
  boardColumnJobDrag,
  boardColumnJobDelete,
  boardColumnJobPush,
  taskModalClose,
  updateJob,
  JobDragByIndex,
  setTask,
  pushTask,
  deleteTask,
  deleteMultipleTask,
  flushTask,
  updateTask,
  setIcon,
  updateBoard,
} from '/imports/job-tracking/api/library/boards.lib';

const Reducer = (state, action) => {
  switch (action.type) {
    case 'SET_BOARD':
      return setBoards(state, action);
    case 'OPEN_NEW_BOARD_MODAL':
      return openModal(state);
    case 'CLOSE_NEW_BOARD_MODAL':
      return closeModal(state);
    case 'ADD_TO_BOARDS_LIST':
      return addToBoards(state, action);
    case 'DELETE_BOARD':
      return deleteBoard(state, action);
    case 'BOARD_RENAME':
      return boardRename(state, action);
    case 'ADD_TO_BOARDS_JOB':
      return addToBoardsJob(state, action);
    case 'JOB_START_APPLICATION':
      return jobStartApplication(state, action);
    case 'BOARD_COLUMN_JOB_DRAG':
      return boardColumnJobDrag(state, action);
    case 'BOARD_COLUMN_JOB_DELETE':
      return boardColumnJobDelete(state, action);
    case 'BOARD_COLUMN_JOB_PUSH':
      return boardColumnJobPush(state, action);
    case 'TASK_LIST_CLOSE_MODAL':
      return taskModalClose(state, action);
    case 'UPDATE_JOB':
      return updateJob(state, action);
    case 'DRAG_JOB_BY_INDEX':
      return JobDragByIndex(state, action);
    case 'SET_ICON':
      return setIcon(state, action);
    case 'SET_TASKS':
      return setTask(state, action);
    case 'FLUSH_TASKS':
      return flushTask(state);
    case 'PUSH_TASK':
      return pushTask(state, action);
    case 'DELETE_TASK':
      return deleteTask(state, action);
    case 'MULTIPLE_TASK_DELETE':
      return deleteMultipleTask(state, action);
    case 'UPDATE_TASK':
      return updateTask(state, action);
    case 'UPDATE_BOARD':
      return updateBoard(state, action);
    default:
      return state;
  }
};

export default Reducer;
