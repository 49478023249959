import React, { PureComponent } from 'react';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import { apolloClient as client } from '/lib/initApollo';
import { generateDebounceKey } from '/imports/core/ui/helpers';

class MutationWrap extends PureComponent {
  static propTypes = {
    onCompleted: PropTypes.func,
    docType: PropTypes.string,
    mutation: PropTypes.object,
    update: PropTypes.func,
    onError: PropTypes.func,
  };

  render() {
    const { mutation, onError, update, onCompleted } = this.props;
    return (
      <Mutation mutation={mutation} update={update} onError={onError} onCompleted={onCompleted}>
        {(mutate) => <Autosave mutate={mutate} {...this.props} />}
      </Mutation>
    );
  }
}

class Autosave extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    variables: PropTypes.object,
    children: PropTypes.func,
    mutate: PropTypes.func,
    optimisticResponse: PropTypes.func,
    update: PropTypes.func,
    isCoverLetter: PropTypes.bool,
    updateImmue: PropTypes.func,
  };

  blockTimeout = false;

  onChange = (e) => {
    const { onChange } = this.props;
    const { needUpdate } = this.props.variables;

    client.writeData({
      data: {
        savingStaus: 'SAVING',
      },
    });

    if (onChange) onChange(e);

    this.save(e.target.value);

    // if(!(e.target.value instanceof Date) && !SAVE_TYPES.includes(field)) {

    if (!needUpdate) {
      window.isTyping = true;
    }

    clearTimeout(this.blockTimeout);

    this.blockTimeout = setTimeout(() => {
      window.isTyping = false;
    }, 1500);
  };

  save = debounce(async (value) => {
    const { mutate, variables, optimisticResponse, isCoverLetter, updateImmue } = this.props;
    const options = {
      variables: {
        ...variables,
        value,
      },
      context: {
        debounceKey: generateDebounceKey(variables),
      },
    };
    if (!isCoverLetter) {
      options.variables.checkForLinkedin = false;
    }
    if (isCoverLetter) {
      options.context.client = 'coverLetter';
    }
    if (optimisticResponse) optimisticResponse(value);
    const res = await mutate(options);
    updateImmue && updateImmue(res.data.updateResumeDetail);
  }, 30);

  onBlur = (e) => {
    const regExp = RegExp('^(https?://)');
    const { type, value } = e.target;
    if (type !== 'url') return;

    if (!regExp.test(value)) this.save(`http://${value}`);
  };

  render() {
    return this.props.children({
      onChange: this.onChange,
      onBlur: this.onBlur,
    });
  }
}

export default MutationWrap;
