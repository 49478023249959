import styled from 'styled-components';

const ExtraCurricularIcon = ({ width = '24px', height = '24px', viewBox = '0 0 24 24' }) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
    <path d="M12 2a7 7 0 017 7c0 1.91-.574 3.325-1.694 4.848-.166.225-.68.89-.73.956a4.62 4.62 0 00-.459.7.938.938 0 00-.117.411V18a4 4 0 11-8 0v-2.085c0-.195-.07-.408-.233-.689a6.664 6.664 0 00-.449-.652c-.026-.035-.48-.623-.622-.816C5.566 12.232 5 10.87 5 9a7 7 0 017-7zm0 16c-.7 0-1.367-.113-2-.337V18a2 2 0 104 0v-.338A5.942 5.942 0 0112 18zm0-14a5 5 0 00-5 5c0 1.379.407 2.357 1.304 3.568.129.174.573.752.61.8.25.332.429.589.582.852.238.408.396.806.465 1.22A3.85 3.85 0 0012 16c.745 0 1.42-.185 2.046-.562.05-.277.144-.548.278-.819.158-.32.358-.625.653-1.017.064-.084.57-.739.717-.939C16.583 11.455 17 10.428 17 9a5 5 0 00-5-5z" />
  </SvgIcon>
);

const SvgIcon = styled.svg`
  stroke: none;
  fill: currentColor;
`;

ExtraCurricularIcon.displayName = 'ExtraCurricularIcon';

export default ExtraCurricularIcon;
