import ImageWrapper from '../atoms/ImageWrapper';
import { getArticlesImages } from 'imports/help/api/articlesImages';
import { StyledText } from '../atoms/Typography';
import { useResponsive } from 'imports/core/api/responsiveContext';

import ArticlePageWrapper from '/imports/help/ui/components/ArticlePageWrapper';
import useIntl from '/imports/core/api/useIntl';

const AddPhotoToResume = () => {
  const { t } = useIntl();
  const { host } = useResponsive();
  const images = getArticlesImages(host);
  return (
    <ArticlePageWrapper title="help.add_photo">
      <StyledText>{t('help.resume_photo.upload_resume_photo')}</StyledText>
      <ImageWrapper src={images.add_photo_1} />
      <StyledText>{t('help.resume_photo.ideal_resume_photo')}</StyledText>
      <ImageWrapper src={images.add_photo_2} />
      <StyledText>{t('help.resume_photo.resume_photo_guidelines')}</StyledText>
    </ArticlePageWrapper>
  );
};

export default AddPhotoToResume;
