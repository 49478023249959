import React from 'react';

import useIntl from '/imports/core/api/useIntl';

const Error = () => {
  const { t } = useIntl();

  return (
    <p>
      {t('error_para1')}
      <br />
      {t('error_para2')}
    </p>
  );
};

Error.displayName = 'Error';

export default Error;
