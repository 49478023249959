import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { getCharacterLength } from '/imports/core/ui/helpers';
import { inputStyle } from '/imports/core/ui/mixins';
import SvgIcon from '/imports/core/ui/atoms/SvgIcon';
import { withIntl } from '/imports/core/api/useIntl';
import GeneratorInputWrap from 'imports/generator/ui/atoms/GeneratorInputWrap';

@withIntl
class SearchInput extends PureComponent {
  state = {
    value: this.props.lastJob || this.props.value || '',
    expanded: false,
    options: this.props.options || [],
  };

  _input = null;

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.options !== nextProps.options) {
      return { options: nextProps.options };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.area !== this.props.area && this.props.area !== this.state.value) {
      this.setState({ value: this.props.area });
    }

    if (prevProps.search !== this.props.search && this.props.search !== this.state.value) {
      this.setState({ value: this.props.search });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.windowClick);
  }

  windowClick = () => {
    this.setState({ expanded: false });
    window.removeEventListener('click', this.windowClick);
  };

  toggleExpanded = (e) => {
    e.stopPropagation();
    if (this.state.expanded === false) {
      window.addEventListener('click', this.windowClick);
    } else {
      window.removeEventListener('click', this.windowClick);
    }
    this.setState((st) => ({ expanded: !st.expanded }));
  };

  onChange = (value) => {
    const { onChange, onDataChange } = this.props;

    if (!this.state.expanded && value) {
      window.addEventListener('click', this.windowClick);
    } else if (!value) {
      window.removeEventListener('click', this.windowClick);
    }

    this.setState({ value, expanded: !!value });

    if (onDataChange) onDataChange(value);
    if (onChange) onChange({ target: { value } });
  };

  onSelect = (e, area) => {
    const { onSelect, onChange, sideEffect } = this.props;
    this.setState({ value: area });
    if (onSelect) onSelect(area);
    if (onChange) onChange({ target: { value: area } });
    if (sideEffect) sideEffect(area);
    this.toggleExpanded(e);
  };

  getInput = (node) => {
    this._input = node;
  };

  handleBlur = () => {
    this._input.validate(this.state.value, true, false);
  };

  getPlaceholder = () => {
    const { placeholder, placeholderSlug, t } = this.props;
    if (placeholderSlug && t(placeholderSlug)) return t(placeholderSlug);
    return placeholder || t('generator.suggestions_wizard_placeholder');
  };

  render() {
    const { light, dark, showIcon, validated, type, ...rest } = this.props;
    const { value, expanded, options } = this.state;
    return (
      <InputWrapper dark={dark}>
        {validated ? (
          <GeneratorInputWrap
            {...rest}
            defaultvalue={value}
            onChange={(e) => this.onChange(e.target.value)}
            placeholder={this.getPlaceholder()}
            validated
            ref={this.getInput}
            onBlur={this.handleBlur}
          />
        ) : (
          <Input
            type={type}
            value={value}
            onChange={(e) => this.onChange(e.target.value)}
            placeholder={this.getPlaceholder()}
            light={light}
            dark={dark}
            expanded={expanded}
            showIcon={showIcon}
          />
        )}
        {showIcon && (
          <IconContainer>
            <SvgIcon.Search />
          </IconContainer>
        )}
        <DropdownMenu expanded={expanded} onClick={(e) => e.stopPropagation()} dark={dark}>
          {options && options.length && options.length > 0 ? (
            <OptionsScroll>
              {options.map((option, index) => (
                <OptionButton
                  key={index}
                  value={option}
                  onSelect={(e) => this.onSelect(e, option)}
                  search={value}
                  dark={dark}
                />
              ))}
            </OptionsScroll>
          ) : null}
        </DropdownMenu>
      </InputWrapper>
    );
  }
}

const splitOption = (option, search) => {
  if (search === '') return option;
  let _option = option.toLowerCase();
  const _search = search.toLowerCase();

  if (!option || !option.includes('[') || !option.includes(']')) return option;
  if (getCharacterLength(option, true) !== getCharacterLength(option)) return option;

  let words = [],
    pointer = 0;
  while (_option.includes(_search)) {
    pointer = _option.indexOf(_search);
    if (pointer === 0) {
      words.push(option.substring(0, _search.length));
      option = option.substring(_search.length, option.length);
      _option = _option.substring(_search.length, _option.length);
    } else {
      words.push(option.substring(0, pointer));
      option = option.substring(pointer, option.length);
      _option = _option.substring(pointer, _option.length);
    }
  }
  words.push(option);
  return (
    <span>
      {words.map((word, index) => (
        <span key={index}>{word.toLowerCase() === _search ? <b>{word}</b> : word}</span>
      ))}
    </span>
  );
};

const OptionButton = ({ value, onSelect, search, dark }) => {
  return (
    <Option onClick={onSelect}>
      <OptionText dark={dark}>{splitOption(value, search)}</OptionText>
    </Option>
  );
};

const Option = styled.div`
  cursor: pointer;
`;

const OptionText = styled.p`
  font-size: 14px;
  padding: 5px 10px 5px 30px;
  margin: 0;

  &:hover {
    color: #1688fe;
    background-color: ${(p) => p.theme.colors.gray.lighter};
  }

  ${(p) =>
    p.dark &&
    css`
      &:hover {
        background-color: #fff;
      }
    `}
`;

const DropdownMenu = styled.div`
  position: absolute;
  width: 100%;
  top: 100%;
  border-radius: 0 3px;
  border-top: solid 1px ${(p) => p.theme.colors.gray.lighter};
  background-color: #fff;
  transform-origin: top;
  transform: scaleY(0);
  opacity: 0;
  z-index: 10;

  ${(p) =>
    p.dark &&
    css`
      background-color: #f0f4f8;
      border: solid 1px #dbe0e5;
      top: 100%;
    `}

  ${(p) =>
    p.expanded &&
    css`
      transform: scaleY(1);
      opacity: 1;
    `}
`;

const OptionsScroll = styled.div`
  max-height: 200px;
  overflow: auto;

  &::-webkit-scrollbar-track {
    border-radius: 2px;
    background-color: ${(p) => p.theme.colors.gray.light};
  }
  &::-webkit-scrollbar {
    width: 3px;
    border-radius: 2px;
    background-color: ${(p) => p.theme.colors.gray.light};
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: ${(p) => p.theme.colors.primary};
    height: 100px;
  }
`;

const InputWrapper = styled.div`
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 12px;
  position: relative;

  ${(p) =>
    p.dark &&
    css`
      z-index: 200;
    `}
`;

const Input = styled.input`
  ${inputStyle}
  font-size: 14px;
  height: 44px;
  flex-shrink: 0;
  border-radius: 3px;
  box-shadow: none;
  border: solid 2px #e6e6ff;
  background-color: var(--light-values-white);

  ${({ showIcon }) =>
    !showIcon &&
    css`
      padding: 13px 16px 9px;
    `}

  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.colors.gray.light};
  }

  &:focus {
    border: solid 2px #1688fe;
  }

  ${(p) =>
    p.light &&
    css`
      background: #fff;
    `}
  ${(p) =>
    p.expanded &&
    p.dark &&
    css`
      z-index: 99;
      border: 1px solid #dbe0e5;
    `}

  ${(p) =>
    p.showIcon &&
    css`
      padding-left: 33px;
    `}

  ${(p) => p.theme.max('xs')`
    background: #fff;
    border-radius: 3px;
    border: solid 2px #e6e6ff;
    padding: 13px 16px 9px 33px;
  `};
`;

const IconContainer = styled.div`
  position: absolute;
  top: 17px;
  left: 16px;
  ${({ theme }) =>
    theme.max('xs')`
      top:16px;
  `}
  svg {
    fill: #1688fe;
  }
`;

export default SearchInput;

SearchInput.propTypes = {
  lastJob: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.array,
  area: PropTypes.string,
  search: PropTypes.string,
  onChange: PropTypes.func,
  onDataChange: PropTypes.func,
  onSelect: PropTypes.func,
  sideEffect: PropTypes.func,
  placeholder: PropTypes.string,
  placeholderSlug: PropTypes.string,
  t: PropTypes.func,
  light: PropTypes.bool,
  dark: PropTypes.bool,
  showIcon: PropTypes.any,
  validated: PropTypes.bool,
  type: PropTypes.string,
};

OptionButton.propTypes = {
  value: PropTypes.string,
  onSelect: PropTypes.func,
  search: PropTypes.string,
  dark: PropTypes.bool,
};
