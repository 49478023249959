import styled from 'styled-components';

const StyledLink = styled.div`
  background-color: transparent;
  &:active {
    outline: 0;
  }
  &:hover {
    outline: 0;
  }
`;
export default StyledLink;
