export const layoutEmojiPicker = (state, action) => {
  state.layoutEmojiPicker = action.payload;
  state.jobTrackEmojiPicker = null;
  return { ...state };
};

export const jobTrackEmojiPicker = (state, action) => {
  state.jobTrackEmojiPicker = action.payload;
  state.layoutEmojiPicker = null;
  return { ...state };
};

export const closeEmojiPicker = (state) => {
  state.jobTrackEmojiPicker = null;
  state.layoutEmojiPicker = null;
  return { ...state };
};

export const isMobileCompanyDetail = (state, action) => {
  state.isMobileCompanyDetail = action.payload;
  return { ...state };
};

export const openReportModal = (state, action) => {
  state.reportJobModal = action.payload;
  return { ...state };
};

export const closeReportModal = (state) => {
  state.reportJobModal = null;
  return { ...state };
};

export const startTrackingModal = (state, action) => {
  state.startTrackingModal = action.payload;
  return { ...state };
};

export const closeTrackingModal = (state) => {
  state.startTrackingModal = null;
  return { ...state };
};

export const openSignUpModal = (state) => {
  state.signUpModal = true;
  return { ...state };
};

export const closeSignUpModal = (state) => {
  state.signUpModal = false;
  return { ...state };
};
