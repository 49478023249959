import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const GooglePlusIcon = ({ width, height }) => {
  return (
    <SvgIcon width={width} height={height} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="#DB4A39"
        d="M7.617 10.017v2.971s2.926-.005 4.121-.005c-.647 1.922-1.648 2.97-4.12 2.97A4.423 4.423 0 013.166 11.5a4.427 4.427 0 014.45-4.454c1.325 0 2.178.458 2.961 1.095.63-.62.577-.707 2.173-2.19A7.676 7.676 0 007.623 4C3.414 4 0 7.36 0 11.5 0 15.64 3.414 19 7.623 19c6.293 0 7.83-5.392 7.317-8.983H7.617zm13.963.903V8.5h-2.082v2.42H17v2.082h2.498V15.5h2.082v-2.498H24V10.92h-2.42z"
      />
    </SvgIcon>
  );
};

const SvgIcon = styled.svg`
  stroke: none;
`;

GooglePlusIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

export default GooglePlusIcon;
