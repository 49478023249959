import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const AddressIcon = () => (
  <SvgIcon width="20px" height="26px" viewBox="0 0 20 26">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <StyledG id="Riga-Add-Info" transform="translate(-202.000000, -214.000000)">
        <g id="Group-5" transform="translate(84.000000, 214.000000)">
          <path
            d="M132.783,18.134 C132.606,18.432 132.222,18.755 132.021,19.077 C131.72,19.562 131.373,20.064 131.072,20.568 C129.97,22.296 128.847,24.087 127.715,25.858 C125.889,23.147 124.042,20.433 122.261,17.672 C120.61,15.101 118.395,12.363 118.043,9.789 C118.021163,9.56821652 118.006822,9.34675586 118,9.125 L118,8.022 C118.63,2.621 123.321,1.13686838e-13 127.58,1.13686838e-13 C130.765,1.13686838e-13 134.706,1.426 136.511,5.401 C136.663,5.725 136.863,6.091 136.964,6.436 C136.989,6.506 137.423,7.628 137.423,7.953 L137.423,9.17 C137.423,12.135 134.56,15.144 132.783,18.134 L132.783,18.134 Z M127.766,5.356 C126.14,5.356 124.267,6.277 124.267,8.294 L124.267,9.008 C124.267,9.055 124.259,9.56 124.36,9.814 C125.037,11.405 126.408,11.975 127.66,11.975 C129.667,11.975 131.321,10.504 131.321,8.668 C131.321,6.822 129.774,5.356 127.766,5.356 L127.766,5.356 Z"
            id="location"
          />
        </g>
      </StyledG>
    </g>
  </SvgIcon>
);

const SvgIcon = styled.svg`
  stroke: none;
  width: ${({ theme }) => theme.convertPx(20)};
  height: ${({ theme }) => theme.convertPx(26)};
`;

const StyledG = styled.g`
  fill: ${({ theme }) => theme.rigaColor('#929292')};
`;

export default AddressIcon;
