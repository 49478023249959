import ImageWrapper from '../atoms/ImageWrapper';
import { firstCharToUpperCase, getDomainName } from 'lib/helpers';
import { getArticlesImages } from 'imports/help/api/articlesImages';
import { replaceString } from 'imports/core/ui/helpers';
import { StyledText } from '../atoms/Typography';
import { useResponsive } from 'imports/core/api/responsiveContext';

import ArticlePageWrapper from '/imports/help/ui/components/ArticlePageWrapper';
import useIntl from '/imports/core/api/useIntl';

const CreateMultipleVersion = () => {
  const { t } = useIntl();
  const { host } = useResponsive();
  const images = getArticlesImages(host);
  return (
    <ArticlePageWrapper title="help.create_multiple_version">
      <StyledText>
        {replaceString(t('help.create_multiple_version.duplicate_resume'), {
          BRAND_NAME: firstCharToUpperCase(getDomainName(host)),
        })}
      </StyledText>
      <ImageWrapper src={images.duplicate_resume_1} />
      <StyledText>{t('help.create_multiple_version.name_your_resume')}</StyledText>
      <ImageWrapper src={images.duplicate_resume_2} />
      <StyledText>{t('help.create_multiple_version.dashboard_resume_names')}</StyledText>
      <ImageWrapper src={images.duplicate_resume_3} />
    </ArticlePageWrapper>
  );
};

export default CreateMultipleVersion;
