import React from 'react';
import { getArticlesImages } from 'imports/help/api/articlesImages';
import { replaceString } from 'imports/core/ui/helpers';
import { useResponsive } from 'imports/core/api/responsiveContext';

import ArticlePageWrapper from '/imports/help/ui/components/ArticlePageWrapper';
import { firstCharToUpperCase, getDomainName, isBlogDomain } from '/lib/helpers';
import ImageWrapper from '/imports/help/ui/atoms/ImageWrapper';
import { TextWithLinkWrapper } from '/imports/help/ui/atoms/Typography';
import useIntl from '/imports/core/api/useIntl';

const CancelSubscription = () => {
  const { t, locale } = useIntl();
  const { host } = useResponsive();
  const images = getArticlesImages(host);
  React.useEffect(() => {
    const goToContactPage = () => {
      const newTabUrl = getContactUrlNewTab();
      window.open(newTabUrl, '_blank'); // Open in new tab
    };
    const contactLink = document.getElementById('contact_link');
    contactLink?.addEventListener('click', goToContactPage);
    return () => {
      contactLink?.removeEventListener('click', goToContactPage);
    };
  }, []);

  const getContactUrlNewTab = () => {
    const blog = isBlogDomain(host); // Determine if the current domain is a blog domain
    const basePath = blog ? `/builder` : ''; // Adjust base path if it's a blog domain
    const domainName = getDomainName(host).replace(/^www\./i, ''); // Remove subdomain if exists
    return `https://${domainName}${basePath}/${locale}/contact-us`;
  };

  return (
    <ArticlePageWrapper title="help.cancel_subscription">
      <TextWithLinkWrapper
        dangerouslySetInnerHTML={{
          __html: replaceString(t('help.cancel_subscription.description'), {
            BRAND_NAME: firstCharToUpperCase(getDomainName(host)),
          }),
        }}
      />
      <ImageWrapper src={images.cancel_subscription} />
    </ArticlePageWrapper>
  );
};

export default CancelSubscription;
