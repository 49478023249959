import React from 'react';
import styled from 'styled-components';

const InfoIcon = () => (
  <SvgIcon width="16" height="16" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 0a8 8 0 110 16A8 8 0 018 0zm0 2a6 6 0 100 12A6 6 0 008 2zm1.004 4.998v4H7.003v-4h2zM8 3.998a1 1 0 110 2 1 1 0 010-2z"
      fill="#0087C1"
      fillRule="evenodd"
    />
  </SvgIcon>
);

const SvgIcon = styled.svg`
  stroke: none;
`;

export default InfoIcon;
