import styled from 'styled-components';

import TextAtom from '/imports/pdf/core/ui/atoms/Text';

const Heading = styled(TextAtom)`
  font-family: ${({ theme }) => theme.headingFont('medium')};
  font-weight: 500;
  font-size: ${({ theme }) => theme.convertPx(18)};
  line-height: 1.5em;
  color: var(--black);
  text-transform: uppercase;
  letter-spacing: ${({ theme }) => theme.convertPx(3.6)};
  word-break: break-word;
  page-break-inside: avoid;
  break-inside: avoid-page;
`;

export default Heading;
