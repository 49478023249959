import styled, { css } from 'styled-components';

const InputStripe = styled.div`
  position: absolute;
  height: 2px;
  bottom: 0;
  background-color: ${(p) => p.theme.colors.primary};
  left: 0;
  width: 100%;
  visibility: hidden;
  transform: rotateY(90deg);
  ${'' /* transition: all 0.3s ease; */}
  will-change: transform;

  ${(p) =>
    p.error &&
    css`
      background-color: ${(p) => p.theme.colors.danger};
      visibility: visible;
      transform: rotateY(0);
      width: 100%;
      height: 2px;
    `}
  ${(p) =>
    p.focus &&
    css`
      visibility: visible;
      transform: rotateY(0);
      background-color: ${(p) => p.theme.colors.primary};
    `}
      
  ${(p) => p.error && p.customInputStripeErrorCss}
  
  ${(p) => p.focus && p.customInputStripeCss}
`;

export default InputStripe;
