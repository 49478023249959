import React, { Fragment, PureComponent } from 'react';
import AISuggestion from 'imports/generator/ui/components/AISuggestion';
import Box from 'imports/core/ui/atoms/Box';
import Collapse from 'imports/generator/ui/atoms/Collapse';
import compose from 'lodash.flowright';
import Flex from 'imports/core/ui/atoms/Flex';
import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import PreAIEdit from 'imports/generator/ui/components/PreAIEdit';
import BlockItemControls from 'imports/generator/ui/components/BlockItemControls';
import { graphql } from 'react-apollo';
import { PropTypes } from 'prop-types';
import styled, { css } from 'styled-components';

import {
  blockItemImmutableUpdate,
  blockItemRemoveOptimistic,
  blockItemReorderOptimistic,
} from '/imports/generator/api/apollo/client/helpers';
import { BLOCKS_MAP } from '/imports/generator/api/form';
import { apolloClient as client } from '/lib/initApollo';
import FieldLabel from '/imports/generator/ui/atoms/FieldLabel';
import { focusElement } from '/imports/generator/api/helpers';
import { GET_EXPANDED_ITEM } from '/imports/generator/api/apollo/client/queries';
import { parseDraftText, WITH_AI_SUGGESTION_VARIANTS, getAISuggestionVariant } from '/lib/helpers';
import ItemControlsMobileButton from '/imports/generator/ui/atoms/ItemControlsMobileButton';
import {
  REMOVE_BLOCK_ITEM,
  REMOVE_COVER_LETTER_BLOCK_ITEM,
  REORDER_BLOCK_ITEM,
  REORDER_COVER_LETTER_BLOCK_ITEM,
  UPDATE_BLOCK_ITEM_FIELD,
  UPDATE_COVER_LETTER_BLOCK_ITEM_FIELD,
} from '/imports/generator/api/apollo/client/mutations';
import { ResponsiveConsumer, withResponsiveContext } from '/imports/core/api/responsiveContext';
import { BLOCK_ADD_DESCRIPTION, SINGLE_ITEM_BLOCKS } from '/imports/generator/api/constants';
import { withConfirm } from '/imports/core/api/confirm';
import { withIntl } from '/imports/core/api/useIntl';
import { withWindowSize } from '/imports/core/api/useWindowSize';
import withUseFormContext from '/imports/core/hooks/withUseFormContext';
import { withBlockResposition } from 'imports/generator/context/blockresposition.context';
import { withRouter } from 'next/router';

@withWindowSize
@withIntl
@withConfirm
@withResponsiveContext
@withBlockResposition
@withRouter
@withUseFormContext
@compose(
  graphql(REORDER_BLOCK_ITEM, { name: 'reorder' }),
  graphql(REORDER_COVER_LETTER_BLOCK_ITEM, {
    name: 'reorderCoverLetterBlockItem',
  }),
  graphql(REMOVE_BLOCK_ITEM, { name: 'remove' }),
  graphql(REMOVE_COVER_LETTER_BLOCK_ITEM, {
    name: 'removeCoverLetterBlockItem',
  }),
)
class BlockItem extends PureComponent {
  static propTypes = {
    block: PropTypes.object,
    item: PropTypes.object,
    remove: PropTypes.func,
    getForm: PropTypes.func,
    isFormValid: PropTypes.func,
    source: PropTypes.object,
    reorder: PropTypes.func,
    confirm: PropTypes.func,
    type: PropTypes.string,
    items: PropTypes.array,
    docType: PropTypes.string,
    startExpanded: PropTypes.bool,
    isCoverLetter: PropTypes.bool,
    host: PropTypes.string,
    isDragging: PropTypes.bool,
  };
  draftWrapper = React.createRef();
  static defaultProps = {
    getForm: () => {},
  };

  _form = null;

  state = {
    expandedItem: client.cache.readQuery({ query: GET_EXPANDED_ITEM }),
    showDescription: false,
    selectedAISuggestion: '',
    isSelectionRemoving: false,
    isSelectedBullet: true,
    isSelectionRephrasing: false,
    preAiText: '',
  };

  updateSelectedAISuggestion = (text, isRemoving = false, isRephrasing = false) => {
    const parseData = parseDraftText(text, true);
    this.setState({
      selectedAISuggestion: parseData,
      isSelectionRemoving: isRemoving,
      isSelectionRephrasing: isRephrasing,
    });
    setTimeout(() => {
      this.setState({ selectedAISuggestion: '' });
    }, 500);
  };
  updateIsSelectedBullet = (bool) => {
    this.setState({ isSelectedBullet: bool });
  };

  updatePreAiText = (text) => {
    const parseData = parseDraftText(text, true);
    this.setState({ preAiText: parseData, isSelectionRephrasing: true });
    setTimeout(() => {
      this.setState({ preAiText: '' });
    }, 500);
  };

  moveUp = () => this.move('UP');

  moveDown = () => this.move('DOWN');

  move = (direction) => {
    const {
      source,
      block: { id: blockId },
      item: { id: itemId },
      reorder,
      reorderCoverLetterBlockItem,
      isCoverLetter,
      updateImmue,
    } = this.props;
    const { id: sourceId } = source;
    blockItemReorderOptimistic(updateImmue)(source, blockId, itemId, direction);
    if (isCoverLetter) {
      return reorderCoverLetterBlockItem({
        context: { client: 'coverLetter' },
        variables: { id: sourceId, blockId, itemId, direction },
      });
    }
    return reorder({
      variables: { resumeId: sourceId, blockId, itemId, direction },
    });
  };

  remove = () => {
    const {
      source,
      block: { id: blockId },
      item: { id: itemId },
      remove,
      removeCoverLetterBlockItem,
      confirm,
      getForm,
      isCoverLetter,
      t,
      afterRemove,
      updateImmue,
    } = this.props;
    const { id: sourceId } = source;
    confirm({
      title: t('delete_entry'),
      text: t('generator.delete_item_confirm'),
      confirmText: t('account.delete_account_confirm_text'),
      async onConfirm() {
        getForm();
        blockItemRemoveOptimistic(updateImmue, sourceId, blockId, itemId);
        try {
          if (isCoverLetter) {
            removeCoverLetterBlockItem({
              context: { client: 'coverLetter' },
              variables: { id: sourceId, blockId, itemId },
            });
          } else {
            remove({
              variables: {
                resumeId: sourceId,
                blockId,
                itemId,
              },
            });
          }
          setTimeout(() => {
            afterRemove();
          }, 0);
        } catch (e) {
          console.log(e);
        }
      },
    });
  };

  componentDidMount() {
    this.changeExpandedItem = client
      .watchQuery({
        query: GET_EXPANDED_ITEM,
      })
      .subscribe({
        next: ({ data }) => {
          this.setState({
            expandedItem: data.expandedItem,
          });
        },
        error: console.log,
      });
    this.addPlusIconButtonListener();
    window.addEventListener('focus', this.addPlusIconButtonListener);
  }

  componentWillUnmount() {
    this.changeExpandedItem.unsubscribe();
    this.removePlusIconButtonListener();
    window.removeEventListener('focus', this.addPlusIconButtonListener);
  }

  componentDidUpdate(prevProps) {
    const { cache } = client;
    const {
      context: {
        state: { isSummaryLoading },
      },
      source: { id: sourceId },
      block: { type, id: blockId },
      item: { id, animationKey },
    } = this.props;

    if (prevProps.context.state.isSummaryLoading !== isSummaryLoading && !isSummaryLoading) {
      this.addPlusIconButtonListener();
    }

    const { expandedItem } = cache.readQuery({ query: GET_EXPANDED_ITEM });
    const {
      source: { id: prevResumeId },
      block: { id: prevBlockId },
      item: { animationKey: prevAnimationKey },
    } = prevProps;

    const currentExpanded = `${sourceId}.${blockId}.${animationKey}`;
    const prevExpanded = `${prevResumeId}.${prevBlockId}.${prevAnimationKey}`;

    if (expandedItem === currentExpanded && currentExpanded !== prevExpanded) {
      const selector = `#${type}`;
      const content = `#${id}`;
      focusElement(selector, content);
    }
  }

  addPlusIconButtonListener = () => {
    const plusIconButton = document.getElementById('summary-suggestion-trigger');
    if (plusIconButton) {
      plusIconButton.addEventListener('click', this.handleOnSummarySuggestionSelect);
    }
  };

  removePlusIconButtonListener = () => {
    const plusIconButton = document.getElementById('summary-suggestion-trigger');
    if (plusIconButton) {
      plusIconButton.removeEventListener('click', this.handleOnSummarySuggestionSelect);
    }
  };

  handleOnSummarySuggestionSelect = () => {
    const summarySuggestionText = document.getElementById('suggestion-text-wrapper');
    if (summarySuggestionText) {
      this.updateSelectedAISuggestionV2(summarySuggestionText.innerText, true);
    }
  };

  updateSelectedAISuggestionV2 = (text, isRephrasing = false) => {
    const {
      context: { dispatch },
      source,
    } = this.props;
    const resumeId = source?.id;
    const parseData = parseDraftText(text, true);
    this.setState({ selectedAISuggestion: parseData, isSelectionRephrasing: isRephrasing });
    dispatch({ type: 'UPDATE_STATE', name: 'isDataSelected', payload: true });
    if (resumeId) {
      const storedResumeIds = JSON.parse(localStorage.getItem('resumeIDs') || '[]');

      if (!storedResumeIds.includes(resumeId)) {
        storedResumeIds.push(resumeId);
        localStorage.setItem('resumeIDs', JSON.stringify(storedResumeIds));
      }
    }
    setTimeout(() => {
      this.setState({ selectedAISuggestion: '' });
    }, 500);
  };

  isCollapsed = () => {
    const { cache } = client;
    const {
      source: { id: sourceId },
      block: { id: blockId },
      item: { animationKey },
    } = this.props;

    const { expandedItem } = cache.readQuery({ query: GET_EXPANDED_ITEM });

    return expandedItem !== `${sourceId}.${blockId}.${animationKey}`;
  };

  toggleCollapsed = async () => {
    const {
      source: { id: sourceId },
      block: { id: blockId },
      item: { animationKey },
      getForm,
      isFormValid,
      formMethods: { trigger, goToError },
    } = this.props;

    if (isFormValid === false) {
      trigger();
      setTimeout(() => {
        goToError();
      }, 0);
      return;
    }

    if (this.isCollapsed()) {
      client.writeQuery({
        query: GET_EXPANDED_ITEM,
        data: {
          expandedItem: `${sourceId}.${blockId}.${animationKey}`,
        },
      });

      return getForm(this._form);
    }

    return client.writeQuery({
      query: GET_EXPANDED_ITEM,
      data: {
        expandedItem: '',
      },
    });
  };

  renderControls = (isMobile) => {
    if (isMobile) {
      return (
        <BlockControls isCollapsed={this.isCollapsed()}>
          <ItemControlsMobileButton
            {...this.props}
            expand={this.toggleCollapsed}
            moveUp={this.moveUp}
            moveDown={this.moveDown}
            removeBlock={this.remove}
            isCollapsed={this.isCollapsed()}
            isMobile={isMobile}
          />
        </BlockControls>
      );
    }

    return (
      <BlockControls>
        <BlockItemControls
          expand={this.toggleCollapsed}
          moveUp={this.moveUp}
          moveDown={this.moveDown}
          removeBlock={this.remove}
          isCollapsed={this.isCollapsed()}
          isDragging={this.props.isDragging}
          {...this.props}
        />
      </BlockControls>
    );
  };

  renderSingleItemBlocks = () => {
    const {
      source,
      item,
      type,
      block,
      isCoverLetter,
      t,
      updateImmue,
      width,
      host,
      refernceBlockItem,
      language,
      isMobile,
      isGeneratorMobileView,
      locale,
    } = this.props;
    const BLOCK = BLOCKS_MAP;
    const mutation = isCoverLetter ? UPDATE_COVER_LETTER_BLOCK_ITEM_FIELD : UPDATE_BLOCK_ITEM_FIELD;
    const { fields } = BLOCK[type];
    const currentStep = source.currentStep;
    const isHobby = type === 'HOBBIES';
    // const isSummary = type === 'PROFESSIONAL_SUMMARY';
    const showAIUpgrade = () => {
      const aiVariant = getAISuggestionVariant(isGeneratorMobileView, locale);
      if (!WITH_AI_SUGGESTION_VARIANTS.includes(aiVariant)) {
        return false;
      }
      return type === 'PROFESSIONAL_SUMMARY';
    };

    const showSummaryAIUpgrade = () => {
      if (!isMobile) {
        return false;
      }
      return type === 'PROFESSIONAL_SUMMARY';
    };

    const showPreAIEdit = () => {
      return type === 'PROFESSIONAL_SUMMARY' && source.generatedByOpenAI && width >= 768;
    };
    const { __typename, ...rest } = source.settings;

    return (
      <StyledFlex grid id={`single-item-block-${item?.id}`}>
        {fields.map((field, i) => {
          const FormComponent = field.component;
          const { props = {} } = field;
          const gridWidth = props.fullWidth ? 12 : 6;

          const label = field.translationSlug && t(field.translationSlug) ? t(field.translationSlug) : field.label;
          return (
            <FormBox ref={this.draftWrapper} md={gridWidth} sm={gridWidth} xs={12} key={i} padded>
              {showPreAIEdit() && (
                <PreAIEdit
                  container={this.draftWrapper}
                  source={source}
                  item={item}
                  currentStep={currentStep}
                  type={type}
                  data={refernceBlockItem && refernceBlockItem.fields !== null ? refernceBlockItem.fields : {}}
                  updateSelectedPreAiText={this.updatePreAiText}
                />
              )}
              <FieldLabel language={language} label={label} longTitle={isHobby} smallFont={isHobby} />
              <FormComponent
                mutation={mutation}
                variables={{
                  docId: source.id,
                  blockId: block.id,
                  itemId: item.id,
                  setting: rest,
                  field: field.name,
                }}
                name={field?.name}
                optimisticResponse={blockItemImmutableUpdate(updateImmue)(source.id, block.id, item.id, field.name)}
                value={(item.fields || {})[field.name]}
                lang={source.settings.language}
                type={type}
                // lastJob={this.getLastJob()}
                // needUpdate={isSummary}
                {...props}
                {...(showAIUpgrade() && {
                  withAIButton: true,
                  selectedAISuggestion: this.state.selectedAISuggestion,
                  isSelectedBullet: this.state.isSelectedBullet,
                  isSelectionRephrasing: this.state.isSelectionRephrasing,
                  isRemoving: this.state.isSelectionRemoving,
                  type,
                })}
                {...(showSummaryAIUpgrade() && {
                  selectedAISuggestion: this.state.selectedAISuggestion,
                  isSelectionRephrasing: this.state.isSelectionRephrasing,
                  type,
                })}
                {...(showPreAIEdit() && {
                  preAiText: this.state.preAiText,
                  isSelectionRephrasing: this.state.isSelectionRephrasing,
                })}
                resume={source}
                updateImmue={updateImmue}
              />
              {showAIUpgrade() && (
                <AISuggestion
                  container={this.draftWrapper}
                  source={source}
                  item={item}
                  type={type}
                  updateSelectedAISuggestion={this.updateSelectedAISuggestion}
                  updateIsSelectedBullet={this.updateIsSelectedBullet}
                />
              )}
            </FormBox>
          );
        })}
      </StyledFlex>
    );
  };

  getLastJob = () => {
    const {
      source: {
        blocks,
        details: { title },
      },
    } = this.props;
    const employment = blocks.find((block) => block.type === 'EMPLOYMENT');

    if (title) return title;
    if (!employment) return null;

    return (
      (employment.items &&
        employment.items.length > 0 &&
        employment.items[0].fields &&
        employment.items[0].fields.title) ||
      null
    );
  };

  renderItems = () => {
    const {
      source,
      block,
      type,
      docType,
      item,
      startExpanded,
      isCoverLetter,
      t,
      updateImmue,
      host,
      width,
      refernceBlockItem,
      language,
      isMobile,
      isGeneratorMobileView,
      locale,
    } = this.props;
    const { showDescription } = this.state;
    const mutation = isCoverLetter ? UPDATE_COVER_LETTER_BLOCK_ITEM_FIELD : UPDATE_BLOCK_ITEM_FIELD;
    const BLOCK = BLOCKS_MAP;
    const currentStep = source.currentStep;
    return BLOCK[type]?.items?.map((d) => {
      const FormComponent = d.component;
      let props = d.props || {};
      const gridWidth = d.fullWidth ? 12 : 6;
      const styledGridWidth = d.fullWidthMobile ? 12 : gridWidth;
      const SkillBlocks = ['SKILLS', 'SOFT_SKILLS', 'HARD_SKILLS', 'CUSTOM_SKILLS_CATEGORY'];
      const isSkill = SkillBlocks.includes(type);
      const isEducation = type === 'EDUCATION';
      if (props.errorMessages && props.errorMessages.length) {
        const errorMessages = props.errorMessages.map((errorMessage) => t(errorMessage));
        props = { ...props, errorMessages };
      }
      // We need that to check for dateRange component, that has multiple fields
      const value = (() => {
        if (Array.isArray(d.name)) {
          return d.name.map((n) => (item.fields || {})[n]);
        }
        return (item.fields || {})[d.name];
      })();

      const skillsProps = isSkill
        ? {
            selectedOptions: block.items.map((item) => (item.fields != null && item.fields.skill) || ''),
            lastJob: this.getLastJob(),
            startExpanded,
            lang: source.settings.language,
          }
        : {};

      if (type === 'EMPLOYMENT' && d.name === 'description') {
        props.lastJob = (item.fields || {}).title || null;
      }

      let refProps = {};

      if (d.childRef) refProps.ref = this.getRef;
      if (d.parentRef) refProps.getChild = this.getChildRef;
      const label = d.translationSlug && t(d.translationSlug) ? t(d.translationSlug) : d.label;

      let customLongTitle = false;
      //might add other condition later
      if (type === 'CUSTOM' && d.name === 'title') {
        customLongTitle = true;
      }
      const showAIUpgrade = () => {
        const aiVariant = getAISuggestionVariant(isGeneratorMobileView, locale);
        if (!WITH_AI_SUGGESTION_VARIANTS.includes(aiVariant)) {
          return false;
        }
        return d.name === 'description' && (type === 'EMPLOYMENT' || type === 'EDUCATION');
      };
      const showPreAIEdit = () => {
        return (
          d.name === 'description' &&
          (type === 'EMPLOYMENT' || type === 'EDUCATION') &&
          source.generatedByOpenAI &&
          width >= 768
        );
      };

      const useSmallFont = customLongTitle && d.label === 'Activity name, job title, book title etc.';
      const { __typename, ...rest } = source.settings;
      const preAIEditEducation = type === 'EDUCATION' && showPreAIEdit();

      return (
        <Fragment key={`${item.id}-form-${d.name}`}>
          {d.name === 'description' && !showDescription && (
            <FlexView onClick={() => this.setState({ showDescription: true })}>
              <FlexIcon>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                  <path fill="#1688FE" fillRule="evenodd" d="M9 7L14 7 14 9 9 9 9 14 7 14 7 9 2 9 2 7 7 7 7 2 9 2z" />
                </svg>
              </FlexIcon>
              <FlexText>{t(BLOCK_ADD_DESCRIPTION[type])}</FlexText>
            </FlexView>
          )}

          <FormBox
            ref={this.draftWrapper}
            key={`${item.id}-form-${d.name}`}
            md={gridWidth}
            sm={styledGridWidth}
            xs={12}
            padded
            marginTop={(isSkill && d.name === 'skill') || preAIEditEducation}
            hide={d.name === 'description' && !showDescription}
          >
            {showPreAIEdit() && (
              <PreAIEdit
                container={this.draftWrapper}
                source={source}
                item={item}
                type={type}
                currentStep={currentStep}
                data={refernceBlockItem && refernceBlockItem.fields !== null ? refernceBlockItem.fields : {}}
                updateSelectedPreAiText={this.updatePreAiText}
              />
            )}
            {d.label && (
              <FieldLabel
                language={language}
                smallFont={useSmallFont}
                label={label}
                zIndex={(isEducation && d.name === 'degree') || d.increasedZindex || isSkill}
                customLongTitle={customLongTitle}
              />
            )}
            <FormComponent
              autoFocus={false}
              language={language}
              mutation={mutation}
              name={d.name}
              docType={docType}
              variables={{
                docId: source.id,
                blockId: block.id,
                itemId: item.id,
                field: d.name,
                setting: rest,
                needUpdate: d.needUpdate,
              }}
              optimisticResponse={blockItemImmutableUpdate(updateImmue)(source.id, block.id, item.id, d.name)}
              lang={source.settings.language}
              value={value}
              type={type}
              {...skillsProps}
              {...refProps}
              {...props}
              isCoverLetter={isCoverLetter}
              {...(showAIUpgrade() && {
                withAIButton: true,
                selectedAISuggestion: this.state.selectedAISuggestion,
                isSelectedBullet: this.state.isSelectedBullet,
                isSelectionRephrasing: this.state.isSelectionRephrasing,
                isRemoving: this.state.isSelectionRemoving,
                type,
              })}
              {...(showPreAIEdit() && {
                preAiText: this.state.preAiText,
                isSelectionRephrasing: this.state.isSelectionRephrasing,
              })}
            />
            {showAIUpgrade() && (
              <AISuggestion
                container={this.draftWrapper}
                source={source}
                item={item}
                type={type}
                updateSelectedAISuggestion={this.updateSelectedAISuggestion}
                updateIsSelectedBullet={this.updateIsSelectedBullet}
                showDescription={showDescription}
              />
            )}
          </FormBox>
        </Fragment>
      );
    });
  };

  getForm = (node) => {
    this._form = node;
    this.props.getForm(node);
  };

  getRef = (node) => {
    this._ref = node;
  };

  getChildRef = () => {
    return this._ref;
  };

  render() {
    const { type, item, t, locale, isCoverLetter, source, items, refernceBlockItem, language, isDragging } = this.props;
    const singleItemBlock = SINGLE_ITEM_BLOCKS.includes(type);
    const SkillBlocks = ['SKILLS', 'SOFT_SKILLS', 'HARD_SKILLS', 'CUSTOM_SKILLS_CATEGORY'];
    const isSkill = SkillBlocks.includes(type);
    const isSocialLetter = 'SOCIAL_LINKS' && isCoverLetter;

    return (
      <ResponsiveConsumer>
        {({ isMobile }) => (
          <ItemCont $noBorder={singleItemBlock} data-item-cont style={{ cursor: isDragging ? 'move' : 'pointer' }}>
            {(singleItemBlock && this.renderSingleItemBlocks()) || (
              <ItemTitle isCollapsed={this.isCollapsed()} data-testid="block-item-title">
                <Title
                  onClick={this.toggleCollapsed}
                  language={language}
                  style={{ color: isDragging && '#1688fe' }}
                  id={`toggle-item-${item.id}`}
                  data-is-collapsed={!this.isCollapsed()}
                >
                  {BLOCKS_MAP[type]?.getItemTitle(item, t, locale)}
                </Title>
                {this.renderControls(isMobile)}
              </ItemTitle>
            )}
            {isSocialLetter && (
              <StyledCollapse collapsed={this.isCollapsed()} isSkill={isSkill}>
                <FormFlex grid isSkill={isSkill}>
                  {this.renderItems()}
                </FormFlex>
              </StyledCollapse>
            )}
            {!isSocialLetter && !singleItemBlock ? (
              <StyledCollapse collapsed={this.isCollapsed()} isSkill={isSkill} id={`collapse-item-${item.id}`}>
                <FormFlex grid isSkill={isSkill}>
                  {this.renderItems()}
                </FormFlex>
              </StyledCollapse>
            ) : null}
          </ItemCont>
        )}
      </ResponsiveConsumer>
    );
  }
}

BlockItem.displayName = 'BlockItem';
const AIUpgradeButton = styled.button`
  position: absolute;
  right: 25px;
  bottom: 7px;

  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  background-color: #1688fe;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  height: 40px;
  padding: 16px 20px;
  display: flex;

  color: #fff;
  font-family: Gilroy SemiBold;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  &:hover {
    background-color: #0072e8;
  }
  &:active {
    background-color: #0062c7;
  }
  &:disabled {
    background-color: #e3e3e4;
    pointer-events: none;
    color: #ffffff;
  }
  @media (max-width: 449px) {
    right: 5px;
  }
`;
const AIUpgradeButtonFlex = styled(Flex)`
  gap: 4px;
`;
const StyledFlex = styled(Flex)`
  ${({ theme }) =>
    theme.max('xs')`
    margin: 0;
  `}
`;

const FlexIcon = styled.div`
  width: 6%;
`;

const FlexView = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 5px;
  @media (min-width: 450px) {
    display: none;
  }
`;

const FlexText = styled.div`
  font-family: ${({ theme }) => theme.font.family.websiteRegular};
  font-size: 14px;
  font-weight: 600;
  color: #1688fe;
  letter-spacing: 0.2px;
`;

const StyledCollapse = styled(Collapse)`
  ${({ collapsed, isSkill }) =>
    !collapsed &&
    !isSkill &&
    css`
      margin-top: 20px;
    `}
  ${({ collapsed }) =>
    !collapsed &&
    css`
      margin-top: 12px;
    `}
`;

const BlockControls = styled.div`
  position: relative;
  display: flex;
  > div {
    font-size: 1.15em;
    color: ${(p) => p.theme.colors.gray.light};
    &:hover {
      color: #1688fe !important;
    }
    ${(p) =>
      !p.isCollapsed &&
      css`
        color: #1688fe;
      `}
  }
`;

const FormFlex = styled(Flex)`
  padding-bottom: 14px;
  margin-top: 10px;

  ${({ isSkill }) =>
    isSkill &&
    css`
      margin-top: 5px;
    `}
  ${({ theme }) =>
    theme.max('xs')`
    margin: 0;
  `}
`;

const FormBox = styled(Box)`
  position: relative;
  margin-bottom: 24px;

  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: 24px;
    `}

  ${({ theme }) =>
    theme.max('xs')`
    padding: 0;
  `}

  ${({ hide }) =>
    hide &&
    css`
      @media (max-width: 449px) {
        display: none;
      }
    `}

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const ItemCont = styled.div`
  ${({ $noBorder }) =>
    !$noBorder &&
    css`
      border-top: solid 1px #e6e6ff;
    `}
`;

const ItemTitle = styled(Flex)`
  align-items: center;
  &:hover {
    button {
      color: #1688fe;
      display: inline-block;
      svg > path {
        stroke: #1688fe;
      }
    }
  }
  > div,
  p {
    flex-shrink: 0;
  }
  button {
    font-size: 1.15em;
  }
  p {
    margin: 0;
    padding: 14px 0;
    flex-grow: 1;
    font-size: 16px;
    cursor: pointer;

    &:hover {
      color: #1688fe;
    }
  }

  ${({ theme, isCollapsed }) =>
    theme.max('xs')`
    p {
    color: ${!isCollapsed ? '#1688fe' : '#7171a6'} !important;
    }
  `}
`;

const Title = styled.p`
  width: calc(100% - 110px);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 16px;
  line-height: normal;
  letter-spacing: normal;
  color: #7171a6;
`;

export default BlockItem;
