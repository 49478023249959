import React from 'react';
import styled from 'styled-components';

const AddIcon = () => {
  return (
    <SvgIcon width="29" height="40" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.2 25.4">
      <path
        d="M11.6.8H1.5c-.5 0-.9.4-.9.9v22.1c0 .5.4.9.9.9h14.2c.5 0 .9-.4.9-.9v-18c0-.2-.1-.5-.3-.7l-4-4.1c-.2-.1-.4-.2-.7-.2zm3.6 22.6H1.9V2.2h8.7V6c0 .5.4.9.9.9h3.7v16.5zM8.6 9.9C6 9.9 4 12 4 14.6s2 4.7 4.6 4.7 4.6-2.1 4.6-4.7c0-1.2-.5-2.4-1.3-3.3-1-.9-2.1-1.4-3.3-1.4zm2.8 5.4H9.2v2.4H8v-2.4H5.7v-1.2H8v-2.4h1.1v2.4h2.3v1.2z"
        fill="#d0d7de"
      />
    </SvgIcon>
  );
};

const SvgIcon = styled.svg`
  stroke: none;
  * {
    width: 100%;
    height: 100%;
  }
`;

export default AddIcon;
