import React from 'react';
import Logo from 'imports/linkedin/ui/assets/Logo';
import styled, { css } from 'styled-components';

import Flex from '/imports/core/ui/atoms/Flex';
import useIntl from '/imports/core/api/useIntl';

const Header = () => {
  const { t } = useIntl();
  return (
    <Main fullWidth alignItems="center" justifyContent="center">
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      <BrandLogo>{t('extension_resumegpt')}</BrandLogo>
    </Main>
  );
};

export default Header;
const BrandLogo = styled.span`
  font-family: 'GILROY BLACK';
  font-weight: 600;
  font-size: 22px;
`;
const LogoWrapper = styled(Flex)`
  svg {
    height: 24px;
    width: 24px;
  }
  flex-shrink: 0;
`;

const Main = styled(Flex)`
  padding: 24px 0;
  gap: 12px;
  border-bottom: 1px solid var(--grayscale-n200);
`;
