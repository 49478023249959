import styled from 'styled-components';

const CardParagraph = styled.div`
  margin-bottom: 0px;
  color: #3a3a43;
  font-weight: 500;
  @media (max-width: 479px) {
    font-size: 14px;
    line-height: 22px;
  }
`;
export default CardParagraph;
