import styled from 'styled-components';

const EducationIcon = ({ width = '24px', height = '24px', viewBox = '0 0 24 24' }) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
    <g>
      <path d="M22.058 8.076l-2.17.996-1.823.838-1.85.85c-1.02.467-2.04.938-3.06 1.406-.622.285-1.249.564-1.865.856l-.03.015h.91l-1.557-.715-3.043-1.4c-.708-.326-1.419-.65-2.127-.976-.509-.236-1.02-.468-1.529-.704L1.421 8.098a.937.937 0 01-.048-.022v1.555l1.073-.494L5.023 7.95l3.12-1.433 2.698-1.238c.437-.202.878-.394 1.313-.602a.072.072 0 00.019-.01h-.91c.358.166.715.329 1.073.494l2.578 1.186 3.12 1.433 2.697 1.238a218.539 218.539 0 001.332.612c.226.103.447.159.694.091a.919.919 0 00.537-.412c.11-.21.166-.461.092-.694-.068-.211-.204-.442-.413-.538-.363-.165-.725-.333-1.087-.499L19.3 6.391c-1.044-.48-2.088-.96-3.13-1.437l-2.695-1.239c-.43-.197-.857-.398-1.289-.593-.194-.086-.37-.141-.588-.112a1.202 1.202 0 00-.35.115c-.048.021-.094.043-.14.065l-.669.307-2.436 1.118c-1.042.478-2.083.958-3.125 1.435A3164.933 3164.933 0 01.538 8.047l-.072.034c-.281.13-.447.485-.447.777 0 .293.166.648.447.778l2.179 1c.605.28 1.212.558 1.817.836l1.867.857 3.081 1.416c.612.28 1.222.566 1.834.842.194.089.374.147.595.118a1.2 1.2 0 00.36-.12l.216-.099c.324-.148.646-.297.97-.444l3.005-1.38c.792-.364 1.584-.727 2.373-1.092l1.303-.597L22.79 9.72c.06-.029.123-.055.183-.084.194-.089.36-.34.413-.538a.91.91 0 00-.092-.693c-.124-.192-.307-.363-.537-.413-.255-.062-.468-.022-.699.084z" />
      <path d="M17.642 10.678v5.433c0 .295.022.605-.016.9.01-.079.021-.158.03-.24a1.794 1.794 0 01-.12.435c.032-.072.06-.144.092-.216-.06.136-.134.261-.226.379.048-.06.094-.123.142-.183-.086.108-.18.202-.288.288.06-.048.122-.093.182-.141a1.779 1.779 0 01-.379.225l.216-.09c-.139.057-.283.098-.434.12l.24-.032c-.389.05-.8.017-1.19.017H7.78c-.399 0-.8.002-1.198 0-.079 0-.158-.005-.24-.017.08.01.159.022.24.031a1.794 1.794 0 01-.434-.12l.216.091a1.779 1.779 0 01-.38-.225c.06.048.123.093.183.141a1.816 1.816 0 01-.288-.288c.048.06.094.123.142.183a1.779 1.779 0 01-.226-.38l.091.217a1.794 1.794 0 01-.12-.435c.01.08.022.159.031.24-.03-.25-.016-.509-.016-.758v-1.445-3.353-.78l-1.354.778 2.342 1.075 3.68 1.69c.268.122.535.247.804.37.17.076.268.126.46.126.188 0 .298-.052.464-.127l.086-.04.418-.193 3.508-1.612 2.76-1.268.048-.021c.195-.089.36-.341.413-.538a.91.91 0 00-.091-.693c-.125-.192-.307-.363-.538-.413-.25-.058-.463-.015-.693.091l-2.321 1.066c-1.224.561-2.446 1.123-3.67 1.687-.28.13-.559.257-.84.386h.91l-2.32-1.065c-1.225-.562-2.446-1.124-3.67-1.688-.281-.13-.56-.256-.84-.386-.3-.14-.61-.175-.91 0a.91.91 0 00-.446.778v5.527c0 .245-.017.499.007.744.06.624.29 1.19.73 1.646.184.192.41.365.65.483.324.16.619.24.974.283.192.024.384.017.579.017h9.857c.729 0 1.459-.272 1.958-.816a2.696 2.696 0 00.708-1.923v-2.882-2.911-.166c0-.47-.413-.922-.9-.9-.49.02-.903.394-.903.898zM1.812 14.866v-2.038-3.233-.741c0-.47-.413-.922-.9-.9-.487.021-.9.396-.9.9v6.012c0 .47.413.921.9.9.487-.022.9-.396.9-.9z" />
    </g>
  </SvgIcon>
);

const SvgIcon = styled.svg`
  stroke: none;
  fill: currentColor;
`;

EducationIcon.displayName = 'EducationIcon';

export default EducationIcon;
