import { getArticlesImages } from 'imports/help/api/articlesImages';
import { useResponsive } from 'imports/core/api/responsiveContext';

import ArticlePageWrapper from '/imports/help/ui/components/ArticlePageWrapper';
import ImageWrapper from '/imports/help/ui/atoms/ImageWrapper';
import { StyledText } from '/imports/help/ui//atoms/Typography';
import useIntl from '/imports/core/api/useIntl';

const DownloadWordFormat = () => {
  const { t } = useIntl();
  const { host } = useResponsive();
  const images = getArticlesImages(host);
  return (
    <ArticlePageWrapper title="help.download_word_format">
      <StyledText>{t('help.download_word_format.export_resume_options')}</StyledText>
      <ImageWrapper src={images.word_format_1} />
      <ImageWrapper src={images.word_format_2} />
    </ArticlePageWrapper>
  );
};

export default DownloadWordFormat;
