import React from 'react';

const Crown = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Capa_1"
      enableBackground="new 0 0 512.06 512.06"
      height="40"
      viewBox="0 0 512.06 512.06"
      width="40"
    >
      <g>
        <g>
          <g>
            <path
              d="m69.146 243.953 22.174-110.87c2.537-12.687 18.943-16.413 26.709-6.058l65.676 87.568c10.425 13.9-2.469 17.142-45.676 70.432-57.238-28.073-72.583-22.565-68.883-41.072z"
              fill="#ffc34d"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="m375.029 285.025c-43.45-52.144-57.372-56.171-46.676-70.432l65.676-87.568c7.765-10.352 24.171-6.633 26.709 6.058l22.174 110.87c3.857 19.292-14.686 14.416-67.883 41.072z"
              fill="#f93"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="m488.079 183.985-107.42 79.9-111.61-195.3c-2.87-5.03-7.93-7.55-13.01-7.55-5.07 0-10.15 2.52-13.03 7.55l-111.61 195.3-107.42-79.9c-11.31-8.41-26.95 2.1-23.49 15.74l41 161.3 27.14 16.33-19.52 13.67 12.38 48.7c1.69 6.64 7.68 11.3 14.54 11.3h360c6.86 0 12.85-4.66 14.54-11.3l12.38-48.7-11.92-15 19.54-15 41-161.3c3.47-13.67-12.2-24.13-23.49-15.74z"
              fill="#ffea80"
            />
          </g>
        </g>
        <path
          d="m511.569 199.725-41 161.3-19.54 15 11.92 15-12.38 48.7c-1.69 6.64-7.68 11.3-14.54 11.3h-179.99v-389.99c5.08 0 10.14 2.52 13.01 7.55l111.61 195.3 107.42-79.9c11.29-8.39 26.96 2.07 23.49 15.74z"
          fill="#ffc34d"
        />
        <g>
          <g>
            <path
              d="m313.889 293.305-45-75c-2.92-4.86-7.88-7.28-12.85-7.28-4.98 0-9.97 2.44-12.87 7.28l-45 75c-2.85 4.75-2.85 10.69 0 15.44l45 75c2.92 4.86 7.9 7.28 12.87 7.28 4.97-.01 9.95-2.45 12.85-7.28l45-75c2.85-4.75 2.85-10.69 0-15.44z"
              fill="#ff6a4d"
            />
          </g>
        </g>
        <path
          d="m313.889 308.745-45 75c-2.9 4.83-7.88 7.27-12.85 7.28v-180c4.97 0 9.93 2.42 12.85 7.28l45 75c2.85 4.75 2.85 10.69 0 15.44z"
          fill="#f03800"
        />
        <g>
          <path d="m151.029 376.025c0 8.28-6.72 15-15 15h-86.92l-7.62-30h94.54c8.28 0 15 6.72 15 15z" fill="#ffc34d" />
        </g>
        <g>
          <path d="m470.569 361.025-7.62 30h-86.92c-8.28 0-15-6.72-15-15s6.72-15 15-15z" fill="#f93" />
        </g>
      </g>
    </svg>
  );
};

export default Crown;
