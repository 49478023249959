import ErrorMessage from './InputErrorMessage';
import Input from './Input';
import styled from 'styled-components';
import React from 'react';

const ValidatedInput = ({
  children,
  errorMessages,
  errorMessageCustomCss,
  customErrorStyle,
  isValid,
  emailNotFound,
  isErrorthere,
  ...rest
}) => {
  const errormessagecustomcss = errorMessageCustomCss || errorMessages;
  return (
    <InputCont error={!!isValid || emailNotFound} customErrorStyle={customErrorStyle}>
      <Input error={!!isValid || emailNotFound} isErrorthere={isErrorthere} {...rest} />
      {(!!isValid || emailNotFound) && (
        <ErrorMessage
          data-test-error={rest.name}
          dangerouslySetInnerHTML={{
            __html: errormessagecustomcss,
          }}
          errorMessageCustomCss={errormessagecustomcss}
        />
      )}
      {children}
    </InputCont>
  );
};

const InputCont = styled.div`
  position: relative;
  ${({ customErrorStyle, error }) => error && customErrorStyle}
`;

export default ValidatedInput;
