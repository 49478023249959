import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '/imports/core/ui/atoms/Button';
import { CircleRightIcon } from '/imports/generator/ui/assets';
import Modal from '/imports/core/ui/atoms/Modal';
import useIntl from '/imports/core/api/useIntl';

const modalStyles = {
  modalContainer: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
  modalBackdrop: {
    backgroundColor: 'transparent',
  },
  modalBody: {
    margin: 'auto',
    flexGrow: '0',
    width: '92%',
    backgroundColor: '#ffffff',
    borderRadius: '5px',
  },
};

function EmailNotifiedModal({ toggleModal, open, notification }) {
  const { t } = useIntl();
  return (
    <Modal onClose={toggleModal} styles={modalStyles} openStateBase open={open} animation="empty">
      <ContentWrapper>
        {notification === 'success' && (
          <IconWrapper>
            <IconBackground>
              <CircleRightIcon />
            </IconBackground>
          </IconWrapper>
        )}
        <Header>
          {notification === 'success'
            ? t('header.export.success')
            : notification === 'loading'
              ? t('header.export.loading')
              : t('header.export.fail')}
        </Header>
        {notification === 'success' && <Text>{t('header.export.success_message')}</Text>}
        <FooterWrapper>
          <DownloadLink onClick={toggleModal} disabled={notification === 'loading'}>
            {t('header.export.back_to_preview')}
          </DownloadLink>
        </FooterWrapper>
      </ContentWrapper>
    </Modal>
  );
}

export default EmailNotifiedModal;

EmailNotifiedModal.propTypes = {
  toggleModal: PropTypes.func,
  open: PropTypes.bool,
  notification: PropTypes.string,
};

const IconWrapper = styled.div`
  display: flex;
`;

const Header = styled.div`
  font-family: ${({ theme }) => theme.font.family.websiteExtraBold};
  font-size: 30px;
  text-align: center;
  color: #1688fe;
  margin-top: 8px;
`;

const Text = styled.div`
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 16px;
  font-weight: 500;
  line-height: 1.38;
  letter-spacing: 0.2px;
  text-align: center;
  color: #7171a6;
  margin-top: 11px;
`;

const IconBackground = styled.div`
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: rgba(22, 136, 254, 0.2);
  margin: auto;
  display: flex;
  svg {
    margin: auto;
  }
`;

const DownloadLink = styled(Button)`
  padding: 13px;
  border-radius: 3px;
  background-color: #1688fe;
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 16px;
  font-weight: bold;
  color: var(--light-values-white);
  width: 100%;
  text-align: center;
`;

const FooterWrapper = styled.div`
  display: flex;
  margin-top: 30px;
`;

const ContentWrapper = styled.div`
  padding: 50px 24px;
`;
