import styled from 'styled-components';

const BodyCss = styled.div`
  position: relative;
  min-width: 320px;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: visible;
  background-color: #fff;
  color: var(--black);
  font-family: ${({ theme }) => theme.font.family.websiteRegular};
  font-size: 1.6rem;
  line-height: 1.35;
  font-weight: 400;
  -webkit-overflow-scrolling: touch;

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  iframe,
  menu,
  nav,
  section,
  svg {
    display: block;
  }

  ol,
  ul {
    list-style: none;
  }

  blockquote,
  q {
    quotes: none;
  }

  blockquote:after,
  blockquote:before,
  q:after,
  q:before {
    content: '';
    content: none;
  }

  img {
    display: block;
    max-width: 100%;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  th {
    text-align: left;
  }

  :focus {
    outline: 0;
  }

  ins {
    text-decoration: none;
  }

  a,
  button,
  input,
  select,
  textarea {
    background-color: transparent;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  a,
  button {
    letter-spacing: inherit;
    word-spacing: inherit;
    cursor: pointer;
  }

  button {
    display: block;
    overflow: visible;
    color: inherit;
  }

  input {
    word-break: normal;
  }

  input[type='checkbox'],
  input[type='radio'] {
    cursor: pointer;
  }

  input[type='color'] {
    border: none;
    outline: none;
    -webkit-appearance: none;
    appearance: none;
  }

  input[type='color']::-webkit-color-swatch-wrapper {
    padding: 0;
  }

  input[type='color']::-moz-color-swatch-wrapper {
    padding: 0;
  }

  input[type='color']::-webkit-color-swatch {
    border: none;
  }

  input[type='color']::-moz-color-swatch {
    border: none;
  }

  input,
  select,
  textarea {
    display: block;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
  }

  label {
    cursor: pointer;
  }

  a {
    text-decoration: inherit;
  }

  a,
  mark {
    color: inherit;
  }

  mark {
    background-color: transparent;
  }

  pre {
    white-space: pre-wrap;
  }

  @-moz-document url-prefix() {
    button::-moz-focus-inner,
    input[type='button']::-moz-focus-inner,
    input[type='file'] > input[type='button']::-moz-focus-inner,
    input[type='reset']::-moz-focus-inner,
    input[type='submit']::-moz-focus-inner {
      margin: 0;
      border: 0;
      padding: 0;
    }
  }

  ::selection {
    background: #2274b0;
    color: #fff;
  }

  *,
  :after,
  :before {
    box-sizing: border-box;
  }
`;

export default BodyCss;
