import React from 'react';

const MailIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      d="M21 3c1.105 0 2 .895 2 2v14c0 1.105-.895 2-2 2H3c-1.105 0-2-.895-2-2V5c0-1.105.895-2 2-2h18zm0 6.618l-9 4.5-9-4.5V19h18V9.618zM21 5H3v2.382l9 4.5 9-4.5V5z"
    />
  </svg>
);

export default MailIcon;
