import React from 'react';

const CurveArrow = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="216" height="111" viewBox="0 0 216 111" fill="none">
      <path
        d="M210.224 0.445259L204.049 10.2029L215.587 10.6712L210.224 0.445259ZM10.9939 103.06C9.67659 100.426 6.47301 99.3577 3.83845 100.675C1.20389 101.992 0.136038 105.196 1.45331 107.83C2.77059 110.465 5.97417 111.533 8.60873 110.216C11.2433 108.898 12.3111 105.695 10.9939 103.06ZM208.862 9.34866C204.256 65.359 156.652 93.8854 130.274 100.025L130.727 101.973C157.601 95.7178 206.153 66.7003 210.856 9.51257L208.862 9.34866ZM130.274 100.025C123.367 101.632 112.178 102.722 99.214 103.444C86.2715 104.166 71.6284 104.518 57.8512 104.666C44.0754 104.814 31.173 104.759 21.7141 104.667C16.9848 104.621 13.1168 104.565 10.4315 104.522C9.08883 104.5 8.04184 104.481 7.33071 104.467C6.97516 104.46 6.70356 104.455 6.52094 104.451C6.42964 104.449 6.36057 104.448 6.3144 104.447C6.2913 104.446 6.27392 104.446 6.26235 104.446C6.25655 104.446 6.25222 104.446 6.24935 104.446C6.2479 104.446 6.24684 104.446 6.24612 104.446C6.24542 104.446 6.24509 104.445 6.22359 105.445C6.20209 106.445 6.20249 106.445 6.20326 106.445C6.204 106.445 6.20514 106.445 6.20663 106.445C6.20961 106.445 6.21404 106.445 6.21994 106.445C6.2317 106.446 6.24927 106.446 6.27256 106.447C6.31914 106.447 6.3886 106.449 6.48032 106.451C6.66373 106.455 6.93618 106.46 7.2926 106.467C8.00544 106.48 9.05425 106.499 10.3988 106.521C13.088 106.565 16.9604 106.621 21.6946 106.667C31.1625 106.759 44.0793 106.814 57.8727 106.666C71.6647 106.517 86.3408 106.165 99.3252 105.441C112.289 104.719 123.634 103.624 130.727 101.973L130.274 100.025Z"
        fill="#1688FE"
      />
    </svg>
  );
};

export default CurveArrow;
