import React from 'react';
import styled from 'styled-components';

const EditIcon = ({ width = 17, height = 17 }) => {
  return (
    <span>
      <SvgIcon width={width} height={height} fill="none" viewBox="0 0 24.9 25.1" xmlns="http://www.w3.org/2000/svg">
        <path d="M23 10.8c-.7-.7-1.8-.7-2.5 0l-9 8.9-.1.1v.1l-1.2 3.9c-.1.2 0 .4.1.5.1.1.2.2.4.2h.2l3.9-1.2h.1s.1 0 .1-.1l8.9-8.9c.7-.7.7-1.8 0-2.5l-.9-1zM11.5 23.2l.6-2.1 1.4 1.4-2 .7zm3.1-1.1l-1.9-1.9 6.8-6.8 1.9 1.9-6.8 6.8zm8.6-8.6l-1 1-1.9-1.9 1-1c.3-.3.7-.3 1 0l.9.9c.2.3.2.7 0 1zM4.7 3.8h12.2c.6 0 1 .4 1 1v.7c0 .6-.4 1-1 1H4.7c-.6 0-1-.4-1-1v-.7c0-.6.4-1 1-1zM4.7 9.2h12.2c.6 0 1 .4 1 1v.8c0 .6-.4 1-1 1H4.7c-.6 0-1-.4-1-1v-.7c0-.6.4-1.1 1-1.1zM4.7 14.7h6.7c.6 0 1 .4 1 1v.7c0 .6-.4 1-1 1H4.7c-.6 0-1-.4-1-1v-.7c0-.6.4-1 1-1z" />
        <path
          d="M19.1 1.5H2.4c-.6 0-1 .4-1 1v20c0 .6.4 1 1 1H8v1H2.4c-1.1 0-2-.9-2-2v-20c0-1.1.9-2 2-2h16.7c1.1 0 2 .9 2 2v6.7h-1V2.5c0-.5-.4-1-1-1z"
          fillRule="evenodd"
          clipRule="evenodd"
        />
      </SvgIcon>
    </span>
  );
};

const SvgIcon = styled.svg`
  stroke: none;
`;

export default EditIcon;
