import React from 'react';
import styled, { css } from 'styled-components';

import View from '/imports/pdf/core/ui/atoms/View';

const AddressIcon = () => {
  return (
    <IconCont>
      <SvgIcon viewBox="0 0 10 14">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M5.206.824C2.479.824.26 2.892.26 5.434c0 1.024.701 2.693 2.144 5.103 1.021 1.704 2.025 3.125 2.067 3.185l.735 1.039.735-1.039a54.65 54.65 0 0 0 2.067-3.185c1.443-2.41 2.145-4.079 2.145-5.103 0-2.542-2.219-4.61-4.947-4.61zm0 6.969c-1.416 0-2.564-1.07-2.564-2.39S3.79 3.014 5.206 3.014c1.416 0 2.564 1.069 2.564 2.389 0 1.32-1.148 2.39-2.564 2.39z"
        />
      </SvgIcon>
    </IconCont>
  );
};

const IconCont = styled(View)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${({ theme: { convertPx, isRTL } }) => css`
    left: ${isRTL ? 'auto' : convertPx(-2)};
    background: var(--black);
    width: ${convertPx(20)};
    height: ${convertPx(20)};
    border-radius: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: ${convertPx(4)};
  `}
`;

const SvgIcon = styled.svg`
  stroke: none;
  width: ${({ theme }) => theme.convertPx(9.5)};
  height: ${({ theme }) => theme.convertPx(12)};
  margin-top: ${({ theme }) => theme.convertPx(-1)};
`;

export default AddressIcon;
