import React from 'react';
import styled, { css } from 'styled-components';

import Heading from '/imports/core/ui/atoms/Heading';

const DashboardHeading = styled((p) => <Heading as="h1" noMargin {...p} />)`
  margin: -12px 0 16px;
  color: var(--black);
  font-size: 42px;
  font-weight: 800;
  line-height: 56px;
  letter-spacing: 0.04em;
  font-family: ${({ theme }) => theme.font.family.websiteRegular};
  margin-bottom: -14px;
  ${({ theme }) => theme.max('sm')`
    text-align: center;
    margin: -11px 0 19px !important;
    font-size: 34px;
    line-height: 46px;
    margin-bottom: 10px !important;
  `}
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
`;

export default DashboardHeading;
