import React from 'react';

const LikeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23" viewBox="0 0 24 23" fill="none">
      <g id="Group">
        <path
          id="Vector"
          d="M22.9235 9.72944C22.6459 9.34982 22.2829 9.04079 21.8638 8.8273C21.4447 8.61382 20.9813 8.50185 20.511 8.50044H14.5V4.50044C14.5381 3.93594 14.4582 3.36969 14.2656 2.83771C14.073 2.30574 13.7717 1.81967 13.381 1.41044C12.7236 0.811778 11.8626 0.486337 10.9735 0.50044C10.8541 0.506673 10.7409 0.555497 10.6544 0.638056C10.5679 0.720616 10.5138 0.831444 10.502 0.95044C10.3284 2.77194 9.99593 4.5748 9.50851 6.33844C9.16185 7.21206 8.69847 8.0347 8.13101 8.78394C7.41296 9.71062 6.86574 10.7578 6.51501 11.8764C6.50484 11.917 6.4998 11.9586 6.50001 12.0004V22.0004C6.50001 22.133 6.55268 22.2602 6.64645 22.354C6.74022 22.4478 6.8674 22.5004 7.00001 22.5004H19.2875C20.0031 22.5022 20.6953 22.2458 21.2371 21.7785C21.779 21.3111 22.1342 20.664 22.2375 19.9559L23.462 11.9559C23.5236 11.5676 23.5078 11.171 23.4154 10.7889C23.3229 10.4067 23.1558 10.0467 22.9235 9.72944Z"
          fill="#1688FE"
        />
        <path
          id="Vector_2"
          d="M5.0005 9.5H1C0.723858 9.5 0.5 9.72386 0.5 10V22C0.5 22.2761 0.723858 22.5 1 22.5H5.0005C5.27664 22.5 5.5005 22.2761 5.5005 22V10C5.5005 9.72386 5.27664 9.5 5.0005 9.5Z"
          fill="#1688FE"
        />
      </g>
    </svg>
  );
};

export default LikeIcon;
