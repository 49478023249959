import styled, { css } from 'styled-components';

import ButtonPrimary from '/imports/job-tracking/ui/atoms/ButtonPrimary';
import Flex from '/imports/core/ui/atoms/Flex';
import { LinkConfig } from '/imports/job-tracking/api/constants';
import useIntl from '/imports/core/api/useIntl';
import useMountedState from '/imports/core/api/useMountedState';

const LayoutSkeleton = (props) => {
  const { t } = useIntl();
  const { renderIfMounted } = useMountedState();
  return renderIfMounted(
    <Main>
      <LeftSection direction="column">
        <ButtonPrimary alignItems="center" fullWidth>
          <span>{LinkConfig[0].logo()}</span>
          {t(LinkConfig[0].slug)}
        </ButtonPrimary>
        <ButtonPrimary alignItems="center" fullWidth>
          <span>{LinkConfig[1].logo()}</span>
          {t(LinkConfig[1].slug)}
        </ButtonPrimary>
        <ButtonPrimary alignItems="center" fullWidth>
          <span>{LinkConfig[2].logo()}</span>
          {t(LinkConfig[2].slug)}
        </ButtonPrimary>
      </LeftSection>
      <RightSection>{props.children}</RightSection>
    </Main>,
  );
};

const Wrapper = styled.div`
  display: block;
  width: 100%;
  > span > span {
    height: 40px;
  }
`;

const Main = styled.div`
  min-height: 100vh;
  background-color: var(--light-values-white);
  ${({ theme }) =>
    theme.isRTL &&
    css`
      direction: rtl;
    `}
`;

const LeftSection = styled(Flex)`
  min-width: 240px;
  max-width: 240px;
  padding-top: 20px;
  padding: 16px 16px 17px;
  gap: 8px;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  ${({ theme }) =>
    theme.isRTL &&
    css`
      right: 0;
      left: unset;
    `}
`;

const RightSection = styled(Flex)`
  min-width: calc(100% - 240px);
  min-height: 100vh;
  margin-left: 240px;
  border-left: solid 1px #e2e8f1;
  overflow: hidden;
  ${({ theme }) =>
    theme.isRTL &&
    css`
      margin-right: 190px;
      margin-left: 0;
    `}
`;

export default LayoutSkeleton;
