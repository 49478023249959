import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Text from '/imports/core/ui/atoms/Text';
import { withIntl } from '/imports/core/api/useIntl';

@withIntl
class ContactFormStatusMessage extends PureComponent {
  static propTypes = {
    message: PropTypes.string,
    t: PropTypes.func,
    $nopadding: PropTypes.bool,
  };

  render() {
    const { message, t, $nopadding } = this.props;
    const emailError = message === 'Email not found';
    return message ? (
      <ContentSectionMessage $nopadding={$nopadding}>
        <ContactFormStatusText {...this.props}>
          {emailError ? t('contact.email_not_found') : message}
        </ContactFormStatusText>
      </ContentSectionMessage>
    ) : null;
  }
}

const ContactFormStatusText = styled((p) => <Text {...p} />)`
  background: #dfffe9;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.success};
  padding: 15px 20px;
  margin: 0;
  font-size: 15px;
  ${({ error, theme }) =>
    error &&
    css`
      color: ${theme.colors.danger};
      background: #ffdfdf;
    `}
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl !important;
    `}
`;

const ContentSectionMessage = styled.div`
  padding: 35px 0;
  ${({ theme }) =>
    theme.isJobTrack &&
    css`
      padding: 10px 0 35px !important;
    `}

  ${({ $nopadding }) =>
    $nopadding &&
    css`
      padding: 0 !important;
    `}
  ${({ theme }) => theme.max('xs')`
    padding: 25px 0;
    border-radius: 0;
  `}
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl !important;
      width: 100% !important;
    `}
`;

export default ContactFormStatusMessage;
