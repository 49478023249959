import InternshipsIconV3 from './InternshipsIconV3';
import IconsExperiment from './IconsExperiment';

const InternshipsIconExperiment = (props) => {
  return <IconsExperiment iconV3={<InternshipsIconV3 {...props} />} />;
};

InternshipsIconExperiment.displayName = 'InternshipsIconExperiment';

export default InternshipsIconExperiment;
