import styled from 'styled-components';

import Button from '/imports/core/ui/atoms/Button';

const DashboardButton = styled(Button)`
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0;
  background-image: none;
  font-weight: bold;
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  touch-action: manipulation;
  height: 55px;
  border: 1px solid #0087c1;
  background: #0087c1;
  color: #fff;
  transition: all 0.2s;
  min-width: 180px;
  border-radius: 27.5px;
  padding: 15px 20px;
  font-size: 14px;
  line-height: normal;
  font-family: TTCommons;
  &:hover {
    border-color: #1a5885;
    background: #1a5885;
    color: #fff;
  }
  ${({ theme }) => theme.max('sm')`
  width: 100%;
  height: 45px;
  border-radius: 5px;
  margin-top: 16px;
  font-size: 16px;
  background-color: #2291eb;
  border: 1px solid #2291eb;
  &:hover {
    border-color: transparent;
    background: transparent;
    color: #0075a7;
  }
  > i {
    display: block;
`}
`;

export default DashboardButton;
