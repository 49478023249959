import styled, { css } from 'styled-components';

import View from '/imports/pdf/core/ui/atoms/View';

const BlockItem = styled(View)`
  ${({ theme: { convertPx }, left, theme, $blockType, $isTag }) => css`
    padding-top: ${convertPx(left ? 22.5 : 26)};
    padding-bottom: ${convertPx(left ? 22.5 : 22)};
    ${left &&
    css`
      position: relative;
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: ${convertPx(-6)};
        height: 1px;
        background-color: #808183;
      }
    `}
  `}
`;

export default BlockItem;
