import styled, { css } from 'styled-components';

export const StyledText = styled.p`
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.63;
  color: #33334f;
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
`;

export const ListContainer = styled.ul`
  margin: 0;
  > li:not(:last-child) {
    margin-bottom: 12px;
  }
  margin-bottom: 21px;
`;

export const StyledListContainer = styled(ListContainer)`
  padding-left: 24px;
`;

export const StyledTextWithSpan = styled(StyledText)`
  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: ${marginBottom}px !important;
    `}
  ${({ noMargin }) =>
    noMargin &&
    css`
      margin: 0 !important;
    `}
  ${({ indent }) =>
    indent &&
    css`
      text-indent: 16px !important;
    `}
  > span {
    color: #494949;
    font-weight: 700;
  }
`;

export const StyledListItem = styled.li`
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.63;
  color: #33334f;
`;

export const SubsectionTitle = styled(StyledText)`
  font-size: 24px;
  font-weight: bold;
  margin: 9px 0 16px 0;
`;

export const TextWithLinkWrapper = styled(StyledTextWithSpan)`
  > span {
    color: #1688fe;
    cursor: pointer;
    font-weight: 500;
  }
`;
