import React from 'react';
import styled from 'styled-components';

const CloseIcon = ({ color = '#fff' }) => {
  return (
    <SvgIcon width="13" height="13" xmlns="http://www.w3.org/2000/svg">
      <path fill="none" d="M-1-1h15v15H-1z" />
      <g>
        <path
          fill={color}
          d="M1.55 1.175a1 1 0 000 1.415l3.536 3.535L1.55 9.661a1 1 0 101.415 1.414L6.5 7.539l3.536 3.536A1 1 0 1011.45 9.66L7.914 6.125 11.45 2.59a1 1 0 00-1.414-1.415L6.5 4.711 2.965 1.175a1 1 0 00-1.415 0z"
          clipRule="evenodd"
          fillRule="evenodd"
        />
      </g>
    </SvgIcon>
  );
};

const SvgIcon = styled.svg`
  stroke: none;
`;

export default CloseIcon;
