import styled from 'styled-components';

const PlusIcon2 = ({ width = '16px', height = '16px', viewBox = '0 0 16 16' }) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
    <path d="M9 7V4H7L7 7H4V9H7L7 12H9V9H12V7H9Z" />
  </SvgIcon>
);

const SvgIcon = styled.svg`
  stroke: none;
  fill: currentColor;
`;

PlusIcon2.displayName = 'PlusIcon2';

export default PlusIcon2;
