import React from 'react';

import { mobileElement, FlexWrapper } from '/imports/generator/ui/components/BlockItemMobileControls';
import {
  OutlinedDeleteIcon,
  UpArrowLargeIcon,
  DownArrowLargeIcon,
  CancelRoundIcon,
  LeftToRightIcon,
  RightToLeftIcon,
} from '/imports/generator/ui/assets';
import { PencilIcon } from '/imports/core/ui/assets';

const BlockItemMobileControls = ({
  isCustom,
  toggleEditMode,
  moveUp,
  moveDown,
  moveRight,
  moveLeft,
  showUp,
  showRight,
  showDown,
  showLeft,
  t,
  remove,
  onAfterAction,
  required,
  isMainBlock,
}) => {
  return (
    <FlexWrapper>
      {isCustom && mobileElement(<PencilIcon width="24" height="24" />, t('edit'), toggleEditMode)}
      {showUp && mobileElement(<UpArrowLargeIcon />, t('move_up'), moveUp)}
      {showDown && mobileElement(<DownArrowLargeIcon />, t('move_down'), moveDown)}
      {showRight &&
        mobileElement(<LeftToRightIcon width="24" height="24" />, t('generator.control.move_right'), moveRight)}
      {showLeft &&
        mobileElement(<RightToLeftIcon width="24" height="24" />, t('generator.control.move_left'), moveLeft)}
      {!required && !isMainBlock && mobileElement(<OutlinedDeleteIcon width="24" height="24" />, t('remove'), remove)}
      {mobileElement(<CancelRoundIcon />, t('cancel'), onAfterAction ? onAfterAction : null)}
    </FlexWrapper>
  );
};

export default BlockItemMobileControls;
