import styled from 'styled-components';

const ImageWrapper = (props) => {
  return props.src ? (
    <StyledDiv>
      <img src={props.src}></img>
    </StyledDiv>
  ) : (
    <></>
  );
};

const StyledDiv = styled.div`
  margin: 16px 0 24px 0 !important;
  display: block;
  box-sizing: border-box;
  width: 100%;
  > img {
    max-width: 100%;
    padding: 4px;
    border-radius: 4px;
    border: 1px solid #d6d6d6;
  }
`;

export default ImageWrapper;
