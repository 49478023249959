import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rgba } from 'polished';

import Heading from '/imports/core/ui/atoms/Heading';
import Link from '/imports/core/ui/atoms/Link';
import Text from '/imports/core/ui/atoms/Text';
import { withDomain } from '/imports/core/hooks/useDomain';
import { withIntl } from '/imports/core/api/useIntl';

@withDomain
@withIntl
class ErrorOccured extends PureComponent {
  static propTypes = {
    t: PropTypes.func,
    getContactEmail: PropTypes.func,
  };

  render() {
    const { t, getContactEmail } = this.props;
    const contactInfo = getContactEmail();
    return (
      <ErrorCont>
        <Heading as="h4">{t('payment.unexpected_error')}</Heading>
        <Text>
          {t('error.contact_support')}{' '}
          <Link href={`mailto:${contactInfo?.value || contactInfo}`}>{contactInfo?.title || contactInfo}</Link>
        </Text>
      </ErrorCont>
    );
  }
}

const ErrorCont = styled.div`
  background: ${(props) => rgba(props.theme.colors.danger, 0.1)};
  border: ${(props) => props.theme.general.borderWidth} solid ${(props) => props.theme.colors.danger};
  margin: 20px;
  padding: 20px;
  text-align: center;
`;

export default ErrorOccured;
