import styled from 'styled-components';

const PersonalInfoIcon = ({ width = '22px', height = '22px', viewBox = '0 0 22 22' }) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
    <path d="M11 0c6.075 0 11 4.925 11 11s-4.925 11-11 11S0 17.075 0 11 4.925 0 11 0zm0 16c-3.57 0-5.672.687-5.965 1.74A8.966 8.966 0 0011 20a8.966 8.966 0 005.964-2.26C16.672 16.687 14.569 16 11 16zm0-14a9 9 0 00-7.4 14.125C4.728 14.638 7.372 14 11 14c3.629 0 6.273.638 7.4 2.124A9 9 0 0011 2zm0 3c2.414 0 4 1.922 4 4.2 0 3.28-1.782 4.8-4 4.8-2.24 0-4-1.573-4-5 0-2.244 1.58-4 4-4zm0 2c-1.266 0-2 .816-2 2 0 2.27.818 3 2 3 1.178 0 2-.702 2-2.8 0-1.25-.784-2.2-2-2.2z" />
  </SvgIcon>
);

const SvgIcon = styled.svg`
  stroke: none;
  fill: currentColor;
`;

PersonalInfoIcon.displayName = 'PersonalInfoIcon';

export default PersonalInfoIcon;
