import React from 'react';

const RadioIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 16 16">
    <path
      fill="#0099DA"
      fillRule="evenodd"
      d="M8 3a5 5 0 110 10A5 5 0 018 3zm0 2a3 3 0 100 6 3 3 0 000-6zm0 1a2 2 0 110 4 2 2 0 010-4z"
    />
  </svg>
);

export default RadioIcon;
