import React from 'react';

import LoaderContainer from '/imports/generator/ui/atoms/LoaderContainer';
import { PDF_LOADER } from '/imports/generator/api/constants';

const PreviewSkeleton = () => (
  <LoaderContainer>
    <img src={PDF_LOADER} />
  </LoaderContainer>
);

export default PreviewSkeleton;
