import React from 'react';
import styled from 'styled-components';

const DeleteIcon = () => {
  return (
    <SvgIcon width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.846 3.692h10.462v9.52c0 1.2-.973 2.173-2.172 2.173H5.018c-1.2 0-2.172-.973-2.172-2.172v-9.52z"
        stroke="#BFC5D6"
        strokeWidth="1.231"
      />
      <rect x="1" y="3.077" width="14.154" height="1.231" rx=".615" fill="#BFC5D6" />
      <rect x="5.923" y="6.154" width="1.231" height="6.154" rx=".615" fill="#BFC5D6" />
      <rect x="9" y="6.154" width="1.231" height="6.154" rx=".615" fill="#BFC5D6" />
      <path
        d="M5.308 3.077v-1.23c0-.68.55-1.232 1.23-1.232h3.077c.68 0 1.231.551 1.231 1.231v1.23"
        stroke="#BFC5D6"
        strokeWidth="1.231"
      />
    </SvgIcon>
  );
};

const SvgIcon = styled.svg`
  stroke: none;
`;

export default DeleteIcon;
