import styled from 'styled-components';

const StyledImage = styled.img`
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  width: 100%;
  height: 100%;
`;
export default StyledImage;
