import styled from 'styled-components';

const InternshipsIcon = ({ width = '24px', height = '24px', viewBox = '0 0 24 24' }) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
    <path d="M14.538 0c.468 0 .847.38.847.846v4.23h6.769c.467 0 .846.38.846.847v13.539c0 .467-.38.846-.846.846H1.846A.846.846 0 011 19.462V5.923c0-.467.38-.846.846-.846h6.77V.847c0-.468.378-.847.846-.847zm6.77 6.77H2.692v11.845h18.616V6.77zM9.462 12.691c1.4 0 2.538 1.139 2.538 2.539v1.692c0 .467-.38.846-.846.846h-6.77a.846.846 0 01-.846-.846v-1.692c0-1.4 1.139-2.539 2.539-2.539zm0 1.693H6.077a.847.847 0 00-.846.846v.846h5.077v-.846a.847.847 0 00-.846-.846zm10.153-.847a.846.846 0 010 1.693h-5.923a.846.846 0 010-1.693zM7.77 7.615c1.4 0 2.539 1.139 2.539 2.539 0 1.4-1.139 2.538-2.539 2.538a2.541 2.541 0 01-2.538-2.538c0-1.4 1.138-2.539 2.538-2.539zM19.615 11a.846.846 0 010 1.692h-5.923a.846.846 0 010-1.692zM7.77 9.308a.847.847 0 10.002 1.694.847.847 0 00-.002-1.694zm11.846-.846a.846.846 0 010 1.692h-5.923a.846.846 0 010-1.692zm-5.923-6.77h-3.384v3.385h3.384V1.692z" />
  </SvgIcon>
);

const SvgIcon = styled.svg`
  stroke: none;
  fill: currentColor;
`;

InternshipsIcon.displayName = 'InternshipsIcon';

export default InternshipsIcon;
