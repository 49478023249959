import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { FORM_LOADER } from '/imports/generator/api/constants';
import LoaderContainer from '/imports/generator/ui/atoms/LoaderContainer';
import Page from '/imports/core/ui/atoms/Page';
import PreviewSkeleton from '/imports/generator/ui/skeleton/PreviewSkeleton';

const ResumeGeneratorSkeleton = ({ isCoverLetter = false }) => {
  return (
    <PageWrap>
      <Section isCoverLetter={isCoverLetter}>
        <LoaderContainer>
          <img src={FORM_LOADER} alt="loader" />
        </LoaderContainer>
      </Section>
      <Section isRight isCoverLetter={isCoverLetter}>
        <PreviewSkeleton />
      </Section>
    </PageWrap>
  );
};

ResumeGeneratorSkeleton.propTypes = {
  isCoverLetter: PropTypes.bool,
};

export default ResumeGeneratorSkeleton;

const PageWrap = styled(Page)`
  display: flex !important;
  width: 100%;
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: var(--light-values-white);
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
`;

const Section = styled.div`
  display: flex;
  justify-content: center;
  width: 50%;
  ${({ theme: { max }, isCoverLetter, isRight, isNew }) => css`
    ${max('sm')`
      width: 100%;
    `}
    ${isCoverLetter &&
    css`
      p {
        width: 100%;
      }
    `}
    ${isRight &&
    css`
      background-color: ${isNew ? 'var(--light-values-light-medium-v1)' : '#e5e4ea'};
      height: 100vh;
      overflow-y: scroll;
      p {
        width: ${isCoverLetter ? '36vw' : '32vw'};
        margin: 20px auto;
      }
      ${max('sm')`
          display: none;
        `}
    `}
  `}
`;
