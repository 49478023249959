import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import ControlledInput from '/imports/core/ui/atoms/ControlledInput';
import ErrorMessage from '/imports/core/ui/atoms/InputErrorMessage';
import { getRoute, isBlogDomain } from '/lib/helpers';
import Input from '/imports/core/ui/atoms/Input';
import { replaceString } from '/imports/core/ui/helpers';
import { useResponsive } from 'imports/core/api/responsiveContext';
import useIntl from 'imports/core/api/useIntl';
import { useFormContext } from 'react-hook-form';

const InputCont = styled.div`
  position: relative;
  ${({ theme }) =>
    theme.max('xs')`
    margin-bottom: 0;
 `}
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
`;

const GeneratorValidatedInput = (props) => {
  const { children, view, suggested = false, errorCallback, errors, field, ...rest } = props;
  const [errorText, setErrorText] = useState('');
  const { locale, t } = useIntl();
  const { host } = useResponsive();
  const {
    setValue,
    clearErrors,
    formState: { dirtyFields },
  } = useFormContext();
  const updateErrorText = (errorText, value) => {
    if (typeof errorText === 'string' && errorText.includes('UNDEFINED')) {
      return replaceString(t(errorText), {
        UNDEFINED: value,
        '/login': getRoute('/login', locale, false, host, isBlogDomain(host)),
      });
    }
    return t(errorText);
  };
  useEffect(() => {
    if (errors?.[field?.name]) {
      if (errors?.[field?.name]?.type === 'required' && dirtyFields?.[field?.name] != true) {
        clearErrors(field?.name);
        setValue(field?.name, '', { shouldDirty: true });
      } else {
        setErrorText(errors[field?.name]?.message);
        if (suggested) errorCallback();
      }
      if (errors?.[field?.name]?.type === 'isEmailDuplicate') {
        const link = document.getElementsByTagName('a')[0];
        link && link.setAttribute('href', `/login?email=${rest.value}`);
      }
    }
  }, [errors, field, suggested, errorCallback]);
  const Component = rest.validated ? ControlledInput : Input;
  const shouldDisplayError = !!(
    (errors?.[field?.name] && dirtyFields?.[field?.name] == true) ||
    errors?.[field?.name]?.type === 'isEmailDuplicate'
  );
  return (
    <InputCont view={view}>
      <Component
        view={view}
        error={shouldDisplayError}
        autoComplete="off"
        field={field}
        {...rest}
        name={field?.name}
        errorType={errors?.[field?.name]?.type}
      />
      {children}
      {shouldDisplayError && (
        <ErrorMessage
          view={view}
          name={field?.name}
          color="#e84c85"
          dangerouslySetInnerHTML={{
            __html: updateErrorText(errorText, rest.defaultvalue),
          }}
        />
      )}
    </InputCont>
  );
};

GeneratorValidatedInput.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.node]),
  view: PropTypes.string,
  suggested: PropTypes.bool,
  errorCallback: PropTypes.func,
  placeholderSlug: PropTypes.string,
  t: PropTypes.func,
  errors: PropTypes.object,
  field: PropTypes.object,
};

export default GeneratorValidatedInput;
