import React from 'react';
import styled from 'styled-components';
import types from 'prop-types';
const Button = React.forwardRef(
  ({ color = 'primary', type = 'default', iconLeft, iconRight, disabled, children, ...rest }, ref) => {
    return (
      <Btn ref={ref} disabled={disabled} type={type} color={color} {...rest}>
        {iconLeft}
        {children}
        {iconRight}
      </Btn>
    );
  },
);

export default Button;

const Btn = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  border: none;
  text-align: center;
  font-size: 16px;
  height: 32px;
  gap: 8px;
  font-weight: 600;
  text-decoration: none;
  white-space: nowrap;
  border-radius: 2.4rem;
  cursor: pointer;
  font-family: ${(p) => p.theme.font.family.text};
  box-sizing: border-box;
  ${({ type, color, theme }) =>
    type == 'default' &&
    `
       ${
         (color == 'primary' &&
           `
            background-color: ${theme.colors.primary};
            color: #fff;
            &:hover {
                background-color: ${theme.colors.primaryAccent};
                box-shadow: inset 0 0 0 1px ${theme.colors.primaryAccent};
            }
       `) ||
         ''
       }
        ${
          (color == 'secondary' &&
            `
            background-color: ${theme.colors.secondary};
            color: #fff;
            &:hover {
                background-color: ${theme.colors.secondaryAccent};
                box-shadow: inset 0 0 0 1px ${theme.colors.secondaryAccent};
            }
       `) ||
          ''
        }
    `}
  ${({ type, color, theme }) =>
    type == 'outlined' &&
    `
       ${
         (color == 'primary' &&
           `
            background-color: #fff;
            color: ${theme.colors.primary};
            box-shadow: inset 0 0 0 1px ${theme.colors.primary};
            &:hover {
                box-shadow: inset 0 0 0 2px ${theme.colors.primary};
                background-color:#70b5f933;     
            }
       `) ||
         ''
       }
        ${
          (color == 'secondary' &&
            `
            background-color: #fff !important;
            color: ${theme.colors.secondaryAccent};
            box-shadow: inset 0 0 0 1px ${theme.colors.shadow};
            background-color: #70b5f933;
            &:hover {
                box-shadow: inset 0 0 0 2px ${theme.colors.secondary};
            }
       `) ||
          ''
        }
    `}
`;

Button.types = {
  color: 'primary' | 'secondary',
  type: 'outlined' | 'default',
  iconLeft: types.element,
  iconRight: types.element,
  disabled: types.bool,
};
