import styled, { css } from 'styled-components';

const InputErrorMessage = styled.span.attrs({
  'data-error': 'error',
})`
  top: ${(p) => (p.experiment ? '85px' : '48px')};
  font-size: 11px;
  display: ${(p) => (p.hidden ? 'none' : 'inline-block')};
  font-family: ${({ theme }) => theme.font.family.websiteRegular};
  padding-top: 5px;
  color: #ff2d2d;
  ${({ theme, color }) =>
    color &&
    theme.max('xs')`
    color: ${color};
    font-size: 12px;
    letter-spacing: 0.2px;
    margin-top: ${({ theme }) => theme.designV2 && '-4px'}; 
  `}
  span {
    font-size: 14px;
  }

  a {
    color: ${(p) => p.theme.colors.blue};

    &:hover {
      text-decoration: none;
    }
  }

  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      right: 0;
    `}
  ${(props) => props.errorMessageCustomCss}
`;

export default InputErrorMessage;
