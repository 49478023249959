import React from 'react';

const SkipIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14 3V13" stroke="#4F5E75" strokeWidth="2" strokeLinecap="round" />
      <path
        d="M14 8L4.47885 2.80665C3.81248 2.44317 3 2.92548 3 3.68454V12.3155C3 13.0745 3.81248 13.5568 4.47885 13.1934L14 8Z"
        stroke="#4F5E75"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SkipIcon;
