import { isArabic } from 'styles/pdf-fonts/helpers';
import styled, { css } from 'styled-components';

import TextAtom from '/imports/pdf/core/ui/atoms/Text';

const Text = styled(TextAtom)`
  ${({ theme: { contentFont, convertPx }, children }) => css`
    font-family: ${contentFont()};
    font-size: ${convertPx(12, true)};
    line-height: ${convertPx(20)};
    letter-spacing: ${isArabic(children) ? 'normal' : convertPx(0.2)};
  `}
  color: #58595b;
`;

export default Text;
