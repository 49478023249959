import styled from 'styled-components';

const ReferencesIconV3 = ({ width = '25px', height = '24px', viewBox = '0 0 25 24' }) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
    <path
      d="M22.8749 2.44688V5.72906C22.8749 5.97234 22.6757 6.17156 22.432 6.17156H20.4974L19.124 7.54547L17.7505 6.17156H16.1324H15.816C15.5723 6.17156 15.373 5.97234 15.373 5.72906V5.1525V2.44688C15.373 2.20313 15.5723 2.00391 15.816 2.00391H22.432C22.6757 2.00391 22.8749 2.20313 22.8749 2.44688Z"
      opacity="0.3"
    />
    <path d="M19.5242 17.4562L17.375 19.6055L19.0944 21.3248L21.2436 19.1755L19.5242 17.4562Z" />
    <path
      d="M21.4726 19.4014C22.0637 19.9925 22.0632 20.9595 21.4726 21.5506C21.1773 21.8459 20.7873 21.994 20.3977 21.994C20.0082 21.994 19.6182 21.8459 19.3229 21.5506L19.0946 21.3223L21.2438 19.1731L21.4726 19.4014ZM18.5438 16.4731L19.5245 17.4537L17.3752 19.6029L16.3946 18.6223C16.2174 18.4451 16.2174 18.155 16.3946 17.9773L17.147 17.2254L17.8993 16.4731C18.0765 16.2954 18.3666 16.2954 18.5438 16.4731Z"
      opacity="1"
    />
    <path
      d="M17.7641 13.8148C18.0449 14.0956 18.0449 14.555 17.7641 14.8358L16.2599 16.34L14.7552 17.8447C14.4745 18.1255 14.0151 18.1255 13.7343 17.8447L12.6543 16.7642C13.5365 16.4084 14.3629 15.8727 15.0777 15.1578C15.7926 14.443 16.3284 13.6166 16.6841 12.7344L17.7641 13.8148Z"
      fill="#E0E1E2"
    />
    <path
      d="M10.3159 12.6484L10.6388 15.8448C10.1616 15.9039 9.67836 15.9039 9.20117 15.8448L9.52414 12.6484H10.3159Z"
      fill="#A0A0A0"
    />
    <path
      d="M9.91945 11.9922L10.5054 12.2491L10.3155 12.647H9.52383L9.33398 12.2491V12.2486L9.91945 11.9922Z"
      fill="#A0A0A0"
    />
    <path
      d="M11.5957 11.5866L10.7749 12.3684L10.5059 12.2508L9.91992 11.9939L10.8865 11.2266L11.5957 11.5866Z"
      fill="#A0A0A0"
    />
    <path
      d="M9.91945 11.9939L9.33398 12.2503L9.06445 12.3684L8.24414 11.5866L8.95289 11.2266L9.91945 11.9939Z"
      fill="#A0A0A0"
    />
    <path
      d="M12.5436 15.2736C11.9394 15.5745 11.2944 15.7648 10.6386 15.8445L10.3156 12.6481L10.5055 12.2502L10.7745 12.3678L11.5953 11.5859L13.6241 12.6167C13.797 12.7048 13.9077 12.8548 13.9405 13.0461L14.1247 14.1219C14.1116 14.1359 14.098 14.15 14.0839 14.1641C13.6208 14.6272 13.0995 14.997 12.5436 15.2736ZM7.29594 15.2736C6.74 14.997 6.21875 14.6272 5.75563 14.1641C5.74156 14.15 5.72797 14.1359 5.71484 14.1219L5.89906 13.0461C5.93187 12.8548 6.0425 12.7048 6.21547 12.6167L8.24422 11.5859L9.06453 12.3678L9.33406 12.2497V12.2502L9.52391 12.6481L9.20094 15.8445C8.54516 15.7648 7.89969 15.5745 7.29594 15.2736Z"
      fill="#616161"
    />
    <path
      d="M10.8863 10.3794V11.2259L9.91969 11.9933L8.95312 11.2259V10.3794V10.3789C9.24047 10.5378 9.57 10.6278 9.91969 10.6278C10.2694 10.6278 10.5989 10.5378 10.8863 10.3789V10.3794Z"
      fill="#F3F3F3"
    />
    <path
      d="M11.9352 7.32951V8.6106C11.9352 9.36904 11.5105 10.0328 10.887 10.3764C10.5997 10.5353 10.2702 10.6253 9.92047 10.6253C9.57078 10.6253 9.24125 10.5353 8.95391 10.3764C8.78609 10.284 8.63234 10.1683 8.49781 10.0333C8.13266 9.66857 7.90625 9.16466 7.90625 8.6106V7.42513C8.47109 7.15045 9.2975 6.87435 9.71234 7.34123C10.152 7.83623 11.2358 7.4331 11.922 7.1017C11.9309 7.17623 11.9352 7.25263 11.9352 7.32951Z"
      fill="#F3F3F3"
    />
    <path
      d="M9.92047 5.31641C10.9517 5.31641 11.8081 6.10109 11.922 7.10328C11.2358 7.43469 10.152 7.83781 9.71234 7.34281C9.2975 6.87594 8.47109 7.15203 7.90625 7.42672V7.33109C7.90625 6.22297 8.81281 5.31641 9.92047 5.31641Z"
      opacity="1"
    />
    <path
      d="M14.0845 5.83414C16.3706 8.12023 16.3847 11.8177 14.1253 14.1207L13.9411 13.0449C13.9083 12.8537 13.7977 12.7037 13.6247 12.6155L11.5959 11.5848L10.8867 11.2248V10.3782V10.3777C11.5102 10.0341 11.9348 9.37039 11.9348 8.61195V7.33086C11.9348 7.25398 11.9306 7.17758 11.9217 7.10305C11.8078 6.10086 10.9514 5.31617 9.92016 5.31617C8.8125 5.31617 7.90594 6.22273 7.90594 7.33086V7.42648V8.61195C7.90594 9.16602 8.13235 9.66992 8.4975 10.0346C8.63203 10.1696 8.78578 10.2854 8.9536 10.3777V10.3782V11.2248L8.24485 11.5848L6.2161 12.6155C6.04313 12.7037 5.9325 12.8537 5.89969 13.0449L5.71547 14.1207C3.4561 11.8177 3.47016 8.12023 5.75625 5.83414C8.05594 3.53445 11.7848 3.53445 14.0845 5.83414Z"
      opacity="0.3"
    />
    <path
      d="M10.6386 15.8429C11.2944 15.7632 11.9394 15.5729 12.5436 15.272C13.0996 14.9954 13.6208 14.6255 14.0839 14.1624C14.098 14.1484 14.1116 14.1343 14.1247 14.1202C16.3841 11.8173 16.37 8.11977 14.0839 5.83367C11.7843 3.53398 8.05535 3.53398 5.75566 5.83367C3.46957 8.11977 3.45551 11.8173 5.71488 14.1202C5.72801 14.1343 5.7416 14.1484 5.75566 14.1624C6.21879 14.6255 6.74004 14.9954 7.29598 15.272C7.89973 15.5729 8.5452 15.7632 9.20098 15.8429C9.67816 15.902 10.1614 15.902 10.6386 15.8429ZM16.1324 6.17211C17.3586 8.15961 17.5429 10.607 16.6846 12.7327C16.3288 13.6149 15.793 14.4413 15.0782 15.1562C14.3633 15.871 13.5369 16.4068 12.6547 16.7626C10.0227 17.8257 6.89566 17.2904 4.76145 15.1562C1.91285 12.3076 1.91285 7.68852 4.76145 4.83992C7.61051 1.99086 12.2291 1.99086 15.0782 4.83992C15.1804 4.94211 15.2783 5.04664 15.373 5.15305V5.72961C15.373 5.97289 15.5722 6.17211 15.816 6.17211H16.1324Z"
      opacity="1"
    />
    <path d="M19.1239 7.77984C19.0618 7.77984 19.0021 7.75514 18.9582 7.71117L17.6534 6.40594H15.816C15.4425 6.40594 15.1387 6.10228 15.1387 5.72906V2.44688C15.1387 2.07338 15.4425 1.76953 15.816 1.76953H22.432C22.8055 1.76953 23.1093 2.07338 23.1093 2.44688V5.72906C23.1093 6.10228 22.8055 6.40594 22.432 6.40594H20.5945L19.2897 7.71117C19.2457 7.75514 19.1861 7.77984 19.1239 7.77984ZM15.816 2.23828C15.7009 2.23828 15.6074 2.33189 15.6074 2.44688V5.72906C15.6074 5.84381 15.7009 5.93719 15.816 5.93719H17.7505C17.8127 5.93719 17.8723 5.96189 17.9163 6.00586L19.1239 7.21392L20.3316 6.00586C20.3756 5.96189 20.4352 5.93719 20.4974 5.93719H22.432C22.547 5.93719 22.6405 5.84381 22.6405 5.72906V2.44688C22.6405 2.33184 22.547 2.23828 22.432 2.23828H15.816Z" />
    <path d="M21.8277 3.34344H16.4211C16.2916 3.34344 16.1863 3.23848 16.1863 3.10906C16.1863 2.97964 16.2907 2.87469 16.4202 2.87469H21.8277C21.9571 2.87469 22.062 2.97964 22.062 3.10906C22.062 3.23848 21.9572 3.34344 21.8277 3.34344ZM21.8277 4.32312H16.4211C16.2916 4.32312 16.1863 4.21817 16.1863 4.08875C16.1863 3.95933 16.2907 3.85437 16.4202 3.85437H21.8277C21.9571 3.85437 22.062 3.95933 22.062 4.08875C22.062 4.21817 21.9572 4.32312 21.8277 4.32312ZM21.8277 5.30281H16.4211C16.2916 5.30281 16.1863 5.19786 16.1863 5.06844C16.1863 4.93901 16.2907 4.83406 16.4202 4.83406H21.8277C21.9571 4.83406 22.062 4.93901 22.062 5.06844C22.062 5.19786 21.9572 5.30281 21.8277 5.30281ZM9.91814 17.5304C7.95923 17.5305 6.03806 16.7645 4.59572 15.3221C3.17377 13.9002 2.39062 12.0095 2.39062 9.99828C2.39062 7.98711 3.17377 6.0964 4.59572 4.6744C7.53141 1.73876 12.3083 1.73872 15.2439 4.6744C15.3447 4.77519 15.447 4.88389 15.5481 4.99747C15.6341 5.09417 15.6255 5.24229 15.5288 5.32836C15.4321 5.41447 15.284 5.40584 15.198 5.30909C15.1028 5.20226 15.0068 5.10022 14.9124 5.00586C12.1595 2.25294 7.68005 2.25294 4.92712 5.00586C3.59367 6.33931 2.85933 8.11231 2.85933 9.99823C2.85933 11.8842 3.59372 13.6572 4.92712 14.9906C6.27947 16.343 8.08162 17.0613 9.91833 17.0615C10.8089 17.0615 11.7072 16.8927 12.5669 16.5455C13.4461 16.1909 14.2352 15.6677 14.9124 14.9906C15.5895 14.3135 16.1126 13.5245 16.4672 12.6453C17.3052 10.5696 17.1055 8.19589 15.933 6.29534C15.865 6.18519 15.8992 6.04076 16.0094 5.9728C16.1194 5.90483 16.264 5.93905 16.3319 6.0492C17.5824 8.07608 17.7955 10.6075 16.9019 12.8207C16.5237 13.7585 15.9658 14.6001 15.2439 15.322C14.522 16.044 13.6803 16.6019 12.7424 16.9801C11.826 17.3503 10.8675 17.5303 9.91814 17.5304Z" />
    <path d="M9.92055 16.1192C9.67071 16.1192 9.42096 16.1039 9.1728 16.0732C8.48018 15.989 7.81357 15.7892 7.19205 15.4794C6.60068 15.1852 6.0618 14.7971 5.59052 14.3259C5.5751 14.3104 5.56038 14.2952 5.54618 14.28C4.41077 13.1216 3.78949 11.5901 3.79694 9.96726C3.80435 8.34252 4.44133 6.81487 5.59052 5.66563C7.97805 3.2781 11.8628 3.27815 14.2503 5.66563C15.3994 6.81482 16.0364 8.34247 16.0438 9.96726C16.0513 11.5902 15.4301 13.1217 14.2946 14.2801C14.2804 14.2952 14.2657 14.3104 14.2506 14.3256C13.7791 14.7971 13.2402 15.1852 12.6487 15.4795C12.0266 15.7893 11.3601 15.9891 10.6676 16.0733C10.4199 16.1039 10.1702 16.1192 9.92055 16.1192ZM9.92046 4.34357C8.47244 4.34357 7.02443 4.89477 5.92207 5.99713C3.73844 8.18076 3.72086 11.7501 5.88288 13.9538C5.88424 13.9552 5.8856 13.9566 5.88691 13.9581C5.8983 13.9702 5.91011 13.9825 5.92235 13.9947C6.35739 14.4297 6.85497 14.7882 7.40107 15.0598C7.97477 15.3458 8.59 15.5301 9.22994 15.6079C9.68838 15.6646 10.1527 15.6647 10.6106 15.608C11.2504 15.5302 11.8657 15.3458 12.4398 15.0599C12.9861 14.7882 13.4837 14.4297 13.9189 13.9945C13.9309 13.9824 13.9428 13.9701 13.9542 13.958C13.9555 13.9566 13.9568 13.9552 13.9581 13.9538C16.1201 11.7501 16.1025 8.18076 13.9189 5.99718C12.8165 4.89477 11.3685 4.34357 9.92046 4.34357Z" />
    <path d="M14.1244 14.3524C14.0124 14.3524 13.9133 14.2717 13.8937 14.1575L13.7094 13.0817C13.6892 12.9637 13.6247 12.8761 13.5176 12.8216L11.4891 11.791C11.3737 11.7324 11.3277 11.5913 11.3863 11.4759C11.445 11.3604 11.5862 11.3146 11.7014 11.3731L13.7302 12.4038C13.9695 12.5258 14.1261 12.7384 14.1714 13.0026L14.3557 14.0784C14.3776 14.206 14.2918 14.3271 14.1642 14.349C14.1509 14.3513 14.1376 14.3524 14.1244 14.3524ZM5.71516 14.3524C5.70204 14.3524 5.68872 14.3513 5.67532 14.349C5.54772 14.3271 5.46199 14.206 5.48388 14.0784L5.6681 13.0027C5.71347 12.7383 5.87008 12.5258 6.10915 12.404L8.13813 11.3731C8.25344 11.3145 8.39458 11.3605 8.45323 11.4759C8.51187 11.5913 8.46583 11.7324 8.35043 11.791L6.32168 12.8218C6.21494 12.8762 6.1504 12.9637 6.1301 13.0819L5.94593 14.1576C5.92633 14.2717 5.82724 14.3524 5.71516 14.3524ZM7.29597 15.5041C7.16655 15.5041 7.0616 15.3991 7.0616 15.2697V14.1437C7.0616 14.0143 7.16655 13.9094 7.29597 13.9094C7.4254 13.9094 7.53035 14.0143 7.53035 14.1437V15.2697C7.53035 15.3991 7.4254 15.5041 7.29597 15.5041ZM12.5436 15.5041C12.4142 15.5041 12.3093 15.3991 12.3093 15.2697V14.1437C12.3093 14.0143 12.4142 13.9094 12.5436 13.9094C12.6731 13.9094 12.778 14.0143 12.778 14.1437V15.2697C12.778 15.3991 12.6731 15.5041 12.5436 15.5041Z" />
    <path d="M9.06451 12.5989C9.00545 12.5989 8.94723 12.5765 8.90274 12.5342L8.08243 11.7523C8.02735 11.6998 8.0012 11.6239 8.01226 11.5486C8.02328 11.4734 8.0702 11.4081 8.13803 11.3737L8.84678 11.0137C8.92853 10.9722 9.02687 10.9821 9.09863 11.0391L10.0652 11.8064C10.1296 11.8575 10.1625 11.9387 10.1519 12.0202C10.1413 12.1017 10.0888 12.1717 10.0135 12.2047L9.15859 12.5792C9.12831 12.5924 9.09629 12.5989 9.06451 12.5989ZM8.64568 11.6416L9.11326 12.0873L9.46881 11.9315L8.92492 11.4997L8.64568 11.6416Z" />
    <path d="M10.7749 12.5989C10.7432 12.5989 10.7113 12.5925 10.6811 12.5793L9.82583 12.2047C9.7506 12.1717 9.6981 12.1017 9.6875 12.0201C9.67691 11.9386 9.70986 11.8576 9.77422 11.8064L10.7408 11.0391C10.8125 10.9821 10.9108 10.9722 10.9926 11.0137L11.7018 11.3737C11.7696 11.4081 11.8166 11.4733 11.8276 11.5486C11.8387 11.6239 11.8125 11.6999 11.7574 11.7523L10.9366 12.5342C10.8921 12.5766 10.834 12.5989 10.7749 12.5989ZM10.3704 11.9316L10.7261 12.0874L11.194 11.6417L10.9145 11.4998L10.3704 11.9316Z" />
    <path d="M10.8863 11.4598C10.7568 11.4598 10.6519 11.3549 10.6519 11.2255V10.3789C10.6519 10.2495 10.7568 10.1445 10.8863 10.1445C11.0157 10.1445 11.1206 10.2495 11.1206 10.3789V11.2255C11.1206 11.3549 11.0157 11.4598 10.8863 11.4598ZM8.95312 11.4598C8.8237 11.4598 8.71875 11.3549 8.71875 11.2255V10.3789C8.71875 10.2495 8.8237 10.1445 8.95312 10.1445C9.08255 10.1445 9.1875 10.2495 9.1875 10.3789V11.2255C9.1875 11.3549 9.08255 11.4598 8.95312 11.4598Z" />
    <path d="M9.92047 10.8613C9.54303 10.8613 9.16958 10.7651 8.84047 10.583C8.65372 10.4803 8.48244 10.3514 8.33178 10.2003C7.90634 9.77539 7.67188 9.21125 7.67188 8.61219V7.33109C7.67188 6.09097 8.68058 5.08203 9.92047 5.08203C11.065 5.08203 12.0257 5.93961 12.1549 7.0768C12.1646 7.15752 12.1695 7.24353 12.1695 7.33109V8.61219C12.1695 9.43044 11.7215 10.1857 11.0002 10.5832C10.6714 10.7651 10.2979 10.8613 9.92047 10.8613ZM9.92047 5.55078C8.93905 5.55078 8.14062 6.34944 8.14062 7.33109V8.61219C8.14062 9.08595 8.3263 9.5323 8.66342 9.86905C8.78305 9.98909 8.91866 10.0911 9.06687 10.1727C9.32712 10.3166 9.62216 10.3925 9.92042 10.3925C10.2187 10.3925 10.5138 10.3166 10.7735 10.1728C11.3456 9.85756 11.7007 9.25962 11.7007 8.61219V7.33109C11.7007 7.26205 11.6969 7.19473 11.6892 7.13108C11.5868 6.22958 10.8265 5.55078 9.92047 5.55078Z" />
    <path d="M10.3343 7.80485C10.015 7.80485 9.73385 7.71836 9.53716 7.49692C9.21095 7.1298 8.43958 7.42647 8.0088 7.63596C7.89241 7.69249 7.75216 7.64407 7.69553 7.52767C7.63891 7.41128 7.68742 7.27099 7.80381 7.21441C8.76738 6.7458 9.48794 6.73586 9.8876 7.18558C10.136 7.46533 10.8585 7.35503 11.8202 6.89069C11.9367 6.83444 12.0768 6.88328 12.1331 6.99982C12.1894 7.11639 12.1406 7.2565 12.024 7.3128C11.4965 7.56757 10.8631 7.8048 10.3343 7.80485ZM10.3166 12.88H9.52492C9.4346 12.88 9.35228 12.8281 9.31338 12.7466L9.12353 12.3486C9.0678 12.2318 9.11735 12.0918 9.23416 12.0362C9.35102 11.9804 9.49089 12.03 9.54663 12.1468L9.67281 12.4113H10.1688L10.295 12.1468C10.3507 12.0299 10.4905 11.9803 10.6075 12.0362C10.7243 12.0918 10.7738 12.2318 10.7181 12.3486L10.5282 12.7466C10.4893 12.8281 10.407 12.88 10.3166 12.88Z" />
    <path d="M9.20117 16.0756C9.07175 16.0756 8.9668 15.971 8.9668 15.8415C8.9668 15.8336 8.96717 15.8253 8.96797 15.8174L9.29094 12.621C9.30397 12.4923 9.41886 12.3984 9.54767 12.4114C9.67648 12.4244 9.77033 12.5393 9.7573 12.6682L9.43461 15.862C9.42402 15.9816 9.32352 16.0756 9.20117 16.0756ZM10.6388 16.0756C10.5156 16.0756 10.4145 15.9807 10.4052 15.8599L10.0827 12.6682C10.0696 12.5393 10.1635 12.4244 10.2923 12.4114C10.4209 12.3983 10.536 12.4923 10.549 12.621L10.872 15.8174C10.8728 15.8252 10.8732 15.8331 10.8732 15.841C10.8732 15.9705 10.7682 16.0756 10.6388 16.0756ZM14.2454 18.2865C14.0005 18.2865 13.7556 18.1934 13.5692 18.0069L12.4891 16.9264C12.3976 16.8349 12.3976 16.6865 12.4892 16.595C12.5807 16.5035 12.7291 16.5035 12.8206 16.595L13.9006 17.6755C14.0908 17.8656 14.4 17.8655 14.5901 17.6755L17.599 14.6666C17.789 14.4765 17.789 14.1672 17.599 13.9771L16.5189 12.8966C16.4274 12.8051 16.4274 12.6567 16.519 12.5652C16.6105 12.4737 16.7589 12.4737 16.8504 12.5652L17.9304 13.6457C18.3033 14.0185 18.3033 14.6252 17.9304 14.9981L14.9215 18.0069C14.7352 18.1934 14.4903 18.2865 14.2454 18.2865Z" />
    <path d="M17.1461 17.4572C17.0861 17.4572 17.0261 17.4343 16.9804 17.3885L16.094 16.5016C16.0025 16.4101 16.0025 16.2617 16.0941 16.1702C16.1856 16.0787 16.334 16.0786 16.4255 16.1703L17.3119 17.0572C17.4034 17.1487 17.4033 17.2971 17.3118 17.3886C17.266 17.4343 17.2061 17.4572 17.1461 17.4572Z" />
    <path d="M20.3979 22.2284C19.9282 22.2284 19.4875 22.0465 19.1573 21.7163L16.2291 18.788C15.9602 18.5192 15.9601 18.0813 16.2288 17.8118L17.7337 16.3074C17.8635 16.1773 18.0368 16.1055 18.2217 16.1055C18.4065 16.1055 18.5799 16.1772 18.7099 16.3076L21.6383 19.2355C22.3221 19.9193 22.3222 21.0321 21.6385 21.7163C21.3082 22.0465 20.8677 22.2284 20.3979 22.2284ZM18.2218 16.5743C18.1623 16.5743 18.1069 16.5971 18.0654 16.6387L16.5605 18.1431C16.4744 18.2296 16.4742 18.3703 16.5606 18.4566L19.4888 21.3849C19.7305 21.6265 20.0534 21.7597 20.398 21.7597C20.7426 21.7597 21.0654 21.6265 21.3072 21.3849C21.8081 20.8836 21.8081 20.0681 21.3072 19.5671L21.0786 19.339L18.3784 16.6389C18.3367 16.5971 18.2812 16.5743 18.2218 16.5743Z" />
    <path d="M19.0944 21.5561C19.0344 21.5561 18.9744 21.5332 18.9287 21.4874C18.8371 21.3959 18.8371 21.2475 18.9287 21.156L21.0779 19.0068C21.1694 18.9152 21.3179 18.9152 21.4093 19.0068C21.5009 19.0983 21.5009 19.2467 21.4093 19.3382L19.2601 21.4874C19.2143 21.5332 19.1543 21.5561 19.0944 21.5561ZM17.375 19.8367C17.315 19.8367 17.255 19.8138 17.2093 19.768C17.1177 19.6765 17.1177 19.5281 17.2093 19.4366L19.3585 17.2874C19.45 17.1959 19.5985 17.1959 19.6899 17.2874C19.7815 17.3789 19.7815 17.5273 19.6899 17.6188L17.5407 19.768C17.495 19.8138 17.4349 19.8367 17.375 19.8367Z" />
  </SvgIcon>
);

const SvgIcon = styled.svg`
  stroke: none;
  fill: currentColor;
`;

ReferencesIconV3.displayName = 'ReferencesIconV3';

export default ReferencesIconV3;
