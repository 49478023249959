import styled, { css } from 'styled-components';

import View from '/imports/pdf/core/ui/atoms/View';

const BlockInnerItem = styled(View)`
  ${({ theme: { convertPx, isRTL }, padding, breakableView, languages }) => css`
    ${padding &&
    css`
      &:not(:first-of-type) {
        padding-top: ${convertPx(12)};
      }
    `}
    ${breakableView &&
    css`
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 47%;
      max-width: 47%;
      margin-bottom: ${convertPx(16)};

      &:nth-child(odd) {
        padding-right: ${convertPx(isRTL ? 0 : 10)};
        padding-left: ${convertPx(isRTL ? 10 : 0)};
      }

      &:nth-child(even) {
        padding-left: ${convertPx(isRTL ? 0 : 10)};
        padding-right: ${convertPx(isRTL ? 10 : 0)};
      }

      &:nth-last-child(2) {
        > div {
          margin-bottom: 0;
        }
      }
    `}
    ${languages &&
    css`
      flex-basis: 100%;
      max-width: none;
      margin-bottom: ${convertPx(10)};
    `}
    ${padding &&
    css`
      margin-bottom: ${convertPx(16)};
    `}
  `}
`;

export default BlockInnerItem;
