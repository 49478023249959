import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { getClickToEditProps, getPersonalDetail } from '/imports/pdf/core/api/helpers';
import TextAtom from '/imports/pdf/budapest/ui/atoms/Text';
import useIntl from '/imports/core/api/useIntl';
import EditRedirectionWrapper from 'imports/pdf/core/ui/components/EditRedirectionWrapper';

const title = {
  dateOfBirth: 'date_of_birth',
  nationality: 'nationality',
  visaStatus: 'visa_status',
  maritalStatus: 'marital_status',
};

const PersonalDetailsDisplay = ({ field, type, language, left, value, itemId, fieldsName, resume }) => {
  const isPersonalBlock = resume?.blocks?.find((block) => block.type == 'PERSONAL_DETAILS');
  const isFormatted = isPersonalBlock?.isDOBFormatted === true;
  const { t } = useIntl();
  const content = t(title[field], language);
  return (
    <Container left={left}>
      <Text type={type} left={left} title={Number(!!title)}>
        {content}
      </Text>
      {value && (
        <EditRedirectionWrapper {...getClickToEditProps(itemId, { value }, fieldsName)}>
          <Text type={type} left={left}>
            {field === 'dateOfBirth' ? getPersonalDetail(value, language, isFormatted) : value}
          </Text>
        </EditRedirectionWrapper>
      )}
    </Container>
  );
};

PersonalDetailsDisplay.propTypes = {
  field: PropTypes.string,
  type: PropTypes.string,
  language: PropTypes.string,
  left: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.date]),
};

const Container = styled.div`
  ${({ theme, left }) => css`
    padding: ${theme.getPaddingMargin({ bottom: 15 })};
    page-break-inside: avoid !important;
    break-inside: avoid !important;
    direction: ${theme.isRTL ? 'rtl' : 'ltr'};
    &:last-child {
      padding-bottom: 0;
    }
    width: ${left ? 'auto' : '47%'};
  `}
`;

const Text = styled(TextAtom)`
  color: #808183;
  word-break: break-word;
  font-family: ${({ theme: { contentFont } }) => contentFont()};
  ${({ title, left, type, theme }) => css`
    ${title &&
    css`
      color: #636466;
      font-weight: 600;
    `}
    ${left &&
    css`
      color: #e6e7e8;
      ${theme.color &&
      theme.color === 'budapestGirlBlue' &&
      css`
        color: #bcbec0;
      `}
    `}
    ${type === 'cover-letter' &&
    css`
      font-size: ${theme.convertPx(13.5, true)};
    `}
  `}
`;

export default PersonalDetailsDisplay;
