import ImageWrapper from '../atoms/ImageWrapper';
import { getArticlesImages } from 'imports/help/api/articlesImages';
import { StyledListContainer, StyledListItem } from '../atoms/Typography';
import { useResponsive } from 'imports/core/api/responsiveContext';

import ArticlePageWrapper from '/imports/help/ui/components/ArticlePageWrapper';
import useIntl from '/imports/core/api/useIntl';

const ChangeResumeSection = () => {
  const { t } = useIntl();
  const { host } = useResponsive();
  const images = getArticlesImages(host);
  return (
    <ArticlePageWrapper title="help.change_resume_section">
      <StyledListContainer>
        <StyledListItem>{t('help.change_resume_section.adjust_columns')}</StyledListItem>
        <ImageWrapper src={images.change_section_block_1} />
      </StyledListContainer>
      <StyledListContainer>
        <StyledListItem>{t('help.change_resume_section.drag_drop_columns')}</StyledListItem>
        <ImageWrapper src={images.change_section_block_2} />
      </StyledListContainer>
    </ArticlePageWrapper>
  );
};

export default ChangeResumeSection;
