import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const FacebookIcon = ({ width, height }) => {
  return (
    <SvgIcon width={width} height={height} viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="256" cy="256" r="256" fill="#F1F4F9" />
      <mask id="a" maskUnits="userSpaceOnUse" x="0" y="0" width="512" height="512">
        <circle cx="256" cy="256" r="256" fill="#fff" />
      </mask>
      <g mask="url(#a)" fill="#CFD3DA">
        <path d="M71.111 429.253V512H256V91.152c-47.838-5.172-82.532 42.451-81.455 62.707v66.586c-20.686 16.161-.431 42.451 8.404 45.899 2.069 27.41 17.671 46.329 25.213 52.363v33.616c-1.035 29.479-28.014 38.142-41.374 38.788h-39.434c-25.342 1.035-48.054 25.859-56.243 38.142z" />
        <path d="M440.242 429.202V512H255.354V90.895c47.838-5.175 82.532 42.477 81.454 62.746v66.626c20.687 16.171.431 42.477-8.404 45.927-2.069 27.427-17.67 46.358-25.212 52.395v33.637c1.034 29.497 28.013 38.165 41.374 38.811H384c25.341 1.035 48.054 25.875 56.242 38.165z" />
      </g>
    </SvgIcon>
  );
};

const SvgIcon = styled.svg`
  stroke: none;
`;

FacebookIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

export default FacebookIcon;
