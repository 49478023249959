import React from 'react';

const IconRight = () => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_804_1575)">
        <path d="M1.5 7.66666H15.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M10.8333 3L15.5 7.66667L10.8333 12.3333"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_804_1575">
          <rect width="16" height="16" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconRight;
