import env from '/env';

export const generateAIResume = async (resumeId, payload, token) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  };
  const response = await fetch(`${env.BASE_API}/resume/ai-resume/${resumeId}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const getResumeById = async (resumeId, token) => {
  const fetchOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${env.BASE_API}/resume/${resumeId}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const getBucketData = async (bucketId, token) => {
  const fetchOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${env.BASE_API}/skills/${bucketId}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const updateBlockTitle = async (data, resumeId, token) => {
  const fetchOptions = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(`${env.BASE_API}/resume/block-title/${resumeId}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const parseBlockHeight = async (data, resumeId, token, agent_client_id, user_id) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ resumeId: resumeId, heights: data }),
  };
  if (agent_client_id || user_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id || user_id;
  }
  const response = await fetch(`${env.BASE_API}/resume/apply-repositioning`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const renderPreview = async (resumeId, source) => {
  const fetchOptions = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `https://api.resumedone-staging.com/v2/resume/ai-generated-resume?resumeId=${resumeId}&source=${source}`,
    fetchOptions,
  );
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const renderResume = async (userId, resumeId) => {
  const fetchOptions = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${env.BASE_API}/resume/${resumeId}/${userId}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const generateAICoverletter = async (data, token) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(`${env.COVER_LETTERS_ENDPOINT}/ai-coverletter`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

/**
 * Searches for job titles.
 * @param {string} jobTitle - The job title to search for.
 * @param {number} [index=1] - The index of the search, like  pagination.
 * @returns {Promise<Array<object>>} - The array of job titles.
 */

export const searchJobTitle = async (jobTitle, index = 1) => {
  if (jobTitle.length < 1 || index < 1) {
    return [];
  }
  //encode special characters in job title
  const encodedJobTitle = encodeURIComponent(jobTitle);
  const fetchOptions = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  try {
    const response = await fetch(
      `${env.BASE_API}/meta/jobtitle-suggestion?title=${encodedJobTitle}&index=${index}`,
      fetchOptions,
    );

    let jobTitles = await response.json();
    jobTitles = jobTitles.map((job) => ({ title: job, value: job, titleSlug: job }));

    return jobTitles;
  } catch (error) {
    // if the request is aborted, return an empty array
    if (error.name === 'AbortError') {
      return [];
    }
    throw error;
  }
};

export const searchSkills = async (skill, index = 1) => {
  if (skill.length < 1 || index < 1) {
    return [];
  }
  const encodedSkill = encodeURIComponent(skill);
  const fetchOptions = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  try {
    const response = await fetch(
      `${env.BASE_API}/meta/skill-suggestion?title=${encodedSkill}&index=${index}`,
      fetchOptions,
    );

    let skills = await response.json();
    skills = skills.map((skill) => ({ title: skill, value: skill, titleSlug: skill }));

    return skills;
  } catch (error) {
    if (error.name === 'AbortError') {
      // if the request is aborted, return an empty array
      return [];
    }
    throw error;
  }
};

export const startTranslatingResume = async (props) => {
  const { token, agent_client_id, ...payload } = props;
  const fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-source': 'webapp',
      cache: 'no-store',
    },
    body: JSON.stringify(payload),
  };

  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.BASE_API}/task-processor/translate-resume?t=${Date.now()}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const pullTranslatedResume = async (props) => {
  const { taskId, token, agent_client_id } = props;
  let fetchOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      cache: 'no-store',
    },
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.BASE_API}/task-processor/${taskId}?t=${Date.now()}`, fetchOptions);
  if (response.status === 404) {
    return null;
  }
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const getRelatedSkills = async (jobTitle) => {
  const encodedJobTitle = encodeURIComponent(jobTitle);
  const fetchOptions = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${env.BASE_API}/meta/job-skills?jobTitle=${encodedJobTitle}`, fetchOptions);
  if (response.status === 404) {
    return [];
  }
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  const data = (await response.json()).skills;
  const skills = Object.keys(data).flatMap((key) => data[key]);
  return skills;
};

export const analyseResume = async ({ payload, token }) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-source': 'webapp',
    },
    body: JSON.stringify(payload),
  };
  const response = await fetch(`${env.BASE_API}/resume/analyze-resume-v2`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const stemString = async (props = {}) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${props.token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-source': 'webapp',
    },
    body: JSON.stringify(props.payload),
  };
  const response = await fetch(`${env.BASE_API}/meta/stemming`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const optimiseResume = async (payload, token) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-source': 'webapp',
    },
    body: JSON.stringify({
      actionType: 'cv-optimization',
      gptVars: payload,
    }),
  };
  const response = await fetch(`${env.BASE_API}/meta/chat-completions-v2`, fetchOptions);
  if (![200, 201].includes(response.status)) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const createNewOptimiseResume = async ({ payload, token, resumeId }) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-source': 'webapp',
    },
    body: JSON.stringify(payload),
  };
  const response = await fetch(`${env.BASE_API}/resume/optimize-resume/${resumeId}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const parseJobDescription = async (payload, token) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-source': 'webapp',
    },
    body: JSON.stringify({
      actionType: 'calculate-ATS-scrore',
      gptVars: payload,
    }),
  };
  const response = await fetch(`${env.BASE_API}/meta/chat-completions-v2`, fetchOptions);
  if (![200, 201].includes(response.status)) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const storeBucketData = async (payload, token) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-source': 'webapp',
    },
    body: JSON.stringify(payload),
  };
  const response = await fetch(`${env.BASE_API}/skills/`, fetchOptions);
  if (![200, 201].includes(response.status)) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const fetchCancelSubscriptionList = async () => {
  const fetchOptions = {
    method: 'POST',
  };

  const response = await fetch(`${env.BASE_API}/meta/lang-keywords `, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};
