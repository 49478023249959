import styled from 'styled-components';

export default function Backdrop({ show, children, ...rest }) {
  return (
    <BackdropContainer show={show} {...rest}>
      {children}
    </BackdropContainer>
  );
}

const BackdropContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${(props) => (props.zIndex ? props.zIndex : 60)};
  background: rgba(52, 60, 73, 0.3);
  display: ${(props) => (props.show ? 'flex' : 'none')};
`;
