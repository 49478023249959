import styled, { css } from 'styled-components';

import View from '/imports/pdf/core/ui/atoms/View';

const BlockItem = styled(View)`
  display: block !important;
  ${({ theme, theme: { getPaddingMargin, isRTL, convertPx }, isCoverLetter, left, summary, swapRtlColumns }) => css`
    padding: ${((isRTL && !isCoverLetter) || !isRTL) && getPaddingMargin({ right: 65 })};
    margin-bottom: ${convertPx(55)};
    ${left &&
    css`
      padding: ${getPaddingMargin({ left: 65 })};
    `}
    ${summary &&
    css`
      padding-top: 0;
      border-top-color: transparent;
    `}
    ${isRTL &&
    swapRtlColumns &&
    left &&
    css`
      padding: 0;
    `}
  `}
`;

export default BlockItem;
