import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { getLandingAddress } from '/lib/helpers';
import { useResponsive } from '/imports/core/api/responsiveContext';

const Address = ({ isNew, newDesign, forDashboard, isNewMobileDesign }) => {
  const { host } = useResponsive();
  return (
    <AddressWrapper
      isNew={isNew}
      newDesign={newDesign}
      forDashboard={forDashboard}
      isNewMobileDesign={isNewMobileDesign}
    >
      Address: {getLandingAddress(host)}
    </AddressWrapper>
  );
};

Address.propTypes = {
  isNew: PropTypes.bool,
  newDesign: PropTypes.bool,
  forDashboard: PropTypes.bool,
  isNewMobileDesign: PropTypes.bool,
};

const AddressWrapper = styled.div`
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 12px;
  color: #8c8cb7;
  padding: 0 35px 5px;
  text-align: center;
  ${({ forDashboard }) =>
    forDashboard &&
    css`
      padding: 0 0 5px;
    `}
  ${({ isNew }) =>
    isNew &&
    css`
      padding: 0;
      margin: 12px 0 50px;
    `}
  ${({ newDesign }) =>
    newDesign &&
    css`
      display: flex;
      justify-content: center;
      color: var(--neutral-values-gray-light);
    `}
  ${({ isNewMobileDesign }) =>
    isNewMobileDesign &&
    css`
      color: #c4c4c7;
      font-size: 11px !important;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
    `}
`;

export default Address;
