import React from 'react';

const DragDropIcon = (props, ref) => {
  return (
    <svg
      ref={ref}
      width="20px"
      height="20px"
      viewBox="0 0 17 17"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fill="#0b78e8"
    >
      <path
        d="M9.52,10.96c0,0.47,0.01,0.95-0.01,1.42C9.5,12.65,9.6,12.72,9.84,12.7c0.21-0.02,0.42-0.02,0.62,0
	c0.11,0.01,0.27,0.08,0.29,0.17c0.04,0.1,0,0.27-0.08,0.35c-0.59,0.62-1.19,1.24-1.79,1.85c-0.18,0.18-0.31,0.18-0.49-0.01
	c-0.6-0.6-1.19-1.21-1.77-1.83c-0.08-0.08-0.12-0.26-0.09-0.35c0.04-0.09,0.2-0.16,0.31-0.18c0.24-0.03,0.48-0.01,0.72-0.01
	c0.15,0,0.21-0.05,0.21-0.2c0-1.03,0-2.06,0-3.1c0-0.16-0.07-0.2-0.21-0.2c-1.02,0-2.05,0-3.07,0c-0.17,0-0.24,0.06-0.24,0.23
	c0.01,0.22,0.02,0.45-0.01,0.67c-0.02,0.13-0.08,0.3-0.18,0.34C4,10.49,3.82,10.42,3.73,10.34c-0.61-0.57-1.2-1.15-1.8-1.73
	c-0.19-0.19-0.19-0.35,0-0.54C2.53,7.49,3.12,6.9,3.73,6.33C3.81,6.25,4,6.19,4.09,6.23c0.09,0.04,0.16,0.21,0.18,0.33
	c0.03,0.23,0.01,0.47,0.01,0.7c0,0.15,0.06,0.23,0.22,0.22c1.03,0,2.06,0,3.1,0c0.14,0,0.2-0.06,0.2-0.19c0-1.05,0-2.1,0-3.15
	c0-0.14-0.06-0.19-0.2-0.19c-0.26,0-0.52,0.01-0.77-0.01c-0.1-0.01-0.25-0.08-0.27-0.15C6.51,3.7,6.54,3.53,6.61,3.46
	c0.59-0.63,1.2-1.25,1.81-1.86c0.15-0.16,0.33-0.15,0.48,0c0.6,0.61,1.2,1.22,1.79,1.84c0.07,0.08,0.11,0.24,0.08,0.33
	c-0.03,0.08-0.18,0.16-0.29,0.17c-0.25,0.03-0.5,0.01-0.75,0.01c-0.15,0-0.21,0.06-0.21,0.21c0,1.04,0,2.08,0,3.12
	c0,0.15,0.07,0.2,0.21,0.2c1.02,0,2.05,0,3.07,0c0.16,0,0.23-0.07,0.23-0.22c0-0.25-0.01-0.5,0.01-0.75
	c0.01-0.11,0.09-0.26,0.17-0.29c0.1-0.03,0.27,0.01,0.35,0.09c0.62,0.59,1.23,1.19,1.84,1.79c0.16,0.16,0.15,0.33,0,0.48
	c-0.62,0.61-1.24,1.21-1.87,1.8c-0.07,0.07-0.23,0.11-0.31,0.08c-0.08-0.03-0.17-0.17-0.18-0.26c-0.03-0.26-0.02-0.52-0.01-0.77
	c0-0.18-0.09-0.22-0.25-0.22c-1.02,0-2.03,0-3.05,0c-0.16,0-0.22,0.06-0.22,0.22C9.53,9.93,9.52,10.45,9.52,10.96L9.52,10.96z"
      />
    </svg>
  );
};

export default React.forwardRef(DragDropIcon);
