import { css } from 'styled-components';

//website font styles
const fontStyle = {
  Bold: 'websiteBold',
  Regular: 'websiteRegular',
  SemiBold: 'websiteSemiBold',
  Medium: 'websiteMedium',
  ExtraBold: 'websiteExtraBold',
};

//render font function
export default {
  renderFonts: (font, size, color, lineheight) => css`
    font-family: ${({ theme }) => theme.font.family[fontStyle[font]]};
    font-size: ${size}px;
    color: ${color};
    line-height: ${lineheight};
  `,
};
