import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Textarea from './Textarea';
import styled, { css } from 'styled-components';

import Autosave from '/imports/core/ui/components/Autosave';
import { overrideInputStyleMixin } from '/imports/core/ui/mixins';
import { withIntl } from '/imports/core/api/useIntl';

@withIntl
class TextareaAutosave extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    styled: PropTypes.bool,
    t: PropTypes.func,
    placeholderSlug: PropTypes.string,
    isHobbies: PropTypes.bool,
  };

  render() {
    const { className, type, placeholder, name, value, styled, t, placeholderSlug, isHobbies } = this.props;
    return (
      <Autosave {...this.props}>
        {({ onChange }) => (
          <StyledTextarea
            className={className}
            type={type}
            name={name}
            onChange={onChange}
            placeholder={placeholderSlug && t(placeholderSlug) ? t(placeholderSlug) : placeholder}
            defaultvalue={value || ''}
            styled={styled}
            isHobbies={isHobbies}
          />
        )}
      </Autosave>
    );
  }
}

const StyledTextarea = styled(Textarea)`
  ${({ styled }) =>
    styled &&
    css`
      ${overrideInputStyleMixin}
    `}
  ${({ isHobbies }) =>
    isHobbies &&
    css`
      border: solid 2px #e3e9ee;
      border-radius: ${({ theme }) => theme.designV2 && '8px'};
      &::placeholder,
      &::-webkit-input-placeholder {
        color: ${(p) => p.theme.colors.gray.regular};
        font-weight: 500;
        font-stretch: normal;
      }
      &:-ms-input-placeholder {
        color: ${(p) => p.theme.colors.gray.regular};
        font-weight: 500;
        font-stretch: normal;
      }
      &:active,
      &:focus {
        border: solid 2px #1688fe;
      }
      & ~ div {
        display: none;
      }
      overflow-y: auto;
    `}
    ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
`;

export default TextareaAutosave;
