import React from 'react';
import styled, { css } from 'styled-components';

import Button from '/imports/core/ui/atoms/Button';

const AddBlockButton = styled((p) => <Button fullWidth unstyled {...p} />)`
  display: flex;
  align-items: center;
  padding: 13px 3px;
  border-top: 1px dashed ${(p) => p.theme.colors.gray.light};
  border-bottom: 1px dashed ${(p) => p.theme.colors.gray.light};
  text-align: left;
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 16px;
  color: #1688fe;
  font-weight: 400;
  &:hover {
    background-color: rgba(0, 153, 218, 0.05);
    border-color: rgb(191, 197, 214);
  }

  ${({ theme }) =>
    theme.max('xs')`
    border-top: dashed 1px #e6e6ff;
    border-bottom: dashed 1px #e6e6ff;
  `}
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
  ${({ blockDisabled }) =>
    blockDisabled &&
    css`
      pointer-events: none;
    `}
`;

AddBlockButton.displayName = 'AddBlockButton';

export default AddBlockButton;
