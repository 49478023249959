import React from 'react';
import Container from '../atmos/Container';
import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import useTracking from 'imports/core/hooks/useTracking';
import styled, { css } from 'styled-components';

import { cdnFile } from '/lib/helpers';
import { content_landing_slugs } from '/imports/homepageV2/api/constants';
import { Push } from '/components/Link';
import useIntl from '/imports/core/api/useIntl';
import { useResponsive } from '/imports/core/api/responsiveContext';

const SectionList = ({ isNewMobileDesign = false, isMobileV2 = false }) => {
  const { t, locale } = useIntl();
  const isNewDesign = locale === 'en';
  const { trackEvent } = useTracking();
  const { host } = useResponsive();
  const slug = content_landing_slugs(host, t);
  const getList = () => {
    if (isNewDesign)
      return [
        'Instant AI Resume <br/> Tailoring',
        'One-Click Universal <br/> Application',
        'High Job Application Success <br/> through AI Assistance',
      ];
    const content = [slug['landing.list_item_1'], slug['landing.list_item_2'], slug['landing.list_item_3']];
    return content;
  };

  const [listItem1, listItem2, listItem3] = getList();
  const showNumber = !isNewDesign && !isNewMobileDesign;
  const content = [
    {
      item: (
        <>
          <img src={cdnFile('resume-nation-landing/img-favoriteTemplate.jpg')} alt="image" />
          <div>
            {showNumber && <div>1</div>} <span dangerouslySetInnerHTML={{ __html: listItem1 }} />
          </div>
        </>
      ),
    },
    {
      item: (
        <>
          <img
            src={cdnFile(
              isNewMobileDesign
                ? 'resume-nation-landing/img-preWrittenExamples.jpg'
                : 'resume-nation-landing/img-guidance.jpg',
            )}
            alt="image"
          />
          <div>
            {showNumber && <div>2</div>} <span dangerouslySetInnerHTML={{ __html: listItem2 }} />
          </div>
        </>
      ),
    },
    {
      item: (
        <>
          <img
            src={cdnFile(
              isNewMobileDesign
                ? 'resume-nation-landing/img-guidance.jpg'
                : 'resume-nation-landing/img-preWrittenExamples.jpg',
            )}
            alt="image"
          />
          <div>
            {showNumber && <div>3</div>} <span dangerouslySetInnerHTML={{ __html: listItem3 }} />
          </div>
        </>
      ),
    },
  ];

  const handleSkillsClick = () => {
    trackEvent('homepage_skills_item_click');
    Push('/onboarding/', locale, '/onboard/start');
  };

  return (
    <Wrapper>
      <Section>
        {content.map((item, i) => (
          <Items
            onClick={handleSkillsClick}
            key={i}
            $isNewDesign={isNewDesign}
            $isNewMobileDesign={isNewMobileDesign}
            $isMobileV2={isMobileV2}
          >
            {item.item}
          </Items>
        ))}
      </Section>
    </Wrapper>
  );
};

const Items = styled.div`
  display: flex;
  gap: 32px;
  padding: 32px;
  background-color: var(--light-values-white);
  cursor: pointer;
  pointer-events: all;

  ${({ $isNewDesign }) =>
    $isNewDesign &&
    css`
      align-items: center;
    `}
  img {
    height: 100px;
    width: 100px;
    border-radius: 8px;
    flex-shrink: 0;
  }
  > div > div {
    width: 40px;
    height: 40px;
    align-items: center;
    border-radius: 8px;
    background-color: var(--light-values-light-extra-v1);
    display: flex;
    justify-content: center;
    margin-bottom: 6px;
    color: var(--text);
    font-family: ${({ theme }) => theme.font.family.websiteBold};
    line-height: 1.47;
    font-size: 15px;
    min-width: 40px;
  }
  span {
    text-align: start;
    margin-right: ${({ theme: { isRTL } }) => (isRTL ? '0' : '24px')};
    font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
    font-size: 15px;
    font-weight: 500;
    line-height: 1.54;
    color: var(--text);
    font-weight: 600;

    span {
      display: flex;
      font-weight: 600;
      color: #f37112;
    }
    ${({ $isNewDesign }) =>
      $isNewDesign &&
      css`
        @media (min-width: 768px) {
          font-size: 22px !important;
        }
      `}
  }
  ${({ theme }) => theme.max('md')`
    padding: 20px;
    border-radius: none;
    flex-direction: row-reverse;
    align-items: center;
    gap: 20px;
    img {
      height: 64px;
      width: 64px;
    }
    > div {
      > div {
        margin-bottom: 0;
      }
      align-items: center;
      display: flex;
      flex-direction: row;
      gap: 20px;
      flex: 1;
      span {
        margin-right: 0;
      }
    }
  ${({ $isNewMobileDesign, $isMobileV2 }) =>
    $isNewMobileDesign &&
    `
      padding:16px !important;
      flex-direction: ${$isMobileV2 ? 'row' : 'row-reverse'} !important;
      > div {
        > span {
          width: 100%;
          color: #14141f;
          font-family: Gilroy Bold;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }
    `}
`}
`;

const Section = styled.div`
  display: flex;
  gap: 1px;
  background-color: var(--light-values-light-medium-v1);
  ${({ theme }) => theme.max('md')`
    flex-direction: column;
    width: 100%;
  `}
`;

const Wrapper = styled.div`
  display: flex;
  border-radius: 8px;
  width: fit-content;
  box-shadow: 0 8px 24px 0 var(--border-transparent);
  position: relative;
  margin-top: -100px;
  margin-left: auto;
  margin-right: auto;
  z-index: 0;
  overflow: hidden;
  ${({ theme }) => theme.max('md')`
    margin: 0;
    width: 100%;
    border-radius: 0;
    box-shadow: none;
  `}
`;

export default SectionList;
