import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { Push } from '/components/Link';
import RightArrow from '/imports/homepageV2/ui/atmos/RightArrow.js';
import useIntl from '/imports/core/api/useIntl';
import useTracking from '/imports/core/hooks/useTracking';
import { useResponsive } from 'imports/core/api/responsiveContext';
import env from '/env';

const isProd = env.NODE_ENV === 'production';

const CTA = (
  {
    onClick,
    userIdentified,
    isLongTranslation,
    maxWidth,
    isNewMobileDesign,
    isMobileV3 = false,
    showUploadCTA = false,
  },
  ref,
) => {
  const { t, locale } = useIntl();
  const { trackEvent } = useTracking();
  const { isMobile, host } = useResponsive();
  const handleRedirect = () => {
    if (host === 'resumedone' && isProd) {
      window.location.href = `https://theresumeranger.com/${locale}/upload-resume`;
      return;
    }
    trackEvent('cta_click', { isUploadResume: true });
    Push(`/upload-resume`, locale);
  };
  return (
    <Container
      $maxWidth={isLongTranslation ? maxWidth - 20 : maxWidth}
      $showUploadCTA={showUploadCTA}
      $isLongTranslation={isLongTranslation}
      $isMobile={isMobile}
    >
      {showUploadCTA && (
        <UploadCTA $isNewDesign onClick={handleRedirect}>
          <UploadText>{t('landing.uploadcta')}</UploadText>
          <RightArrow />
        </UploadCTA>
      )}
      <ButtonInner
        $isNewDesign
        onClick={onClick}
        $isNewMobileDesign={isNewMobileDesign}
        $isMobileV3={isMobileV3}
        $showUploadCTA={showUploadCTA}
        ref={ref}
      >
        {!userIdentified
          ? locale === 'en'
            ? t('ai_headline_landing_text2')
            : t('landing_cta_text')
          : t('landing.my_resumes')}
        <RightArrow />
      </ButtonInner>
    </Container>
  );
};

const UploadCTA = styled.span`
  padding: 16px;
  min-width: 150px;
  background: #1688fe;
  position: relative;
  border-radius: 4px;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  gap: 8px;
  align-items: center;
  display: flex;
  ${({ $isNewDesign }) =>
    $isNewDesign &&
    css`
      border-radius: 11.714px;
    `}
  &:hover {
    background-color: #0072e8;
  }
  @media only screen and (max-width: 768px) {
    justify-content: center;
  }
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `}
`;

const UploadText = styled.div`
  color: #fff;
  font-family: Gilroy SemiBold;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
`;

const ButtonInner = styled.span`
  padding: 20px 63.5px 20px 66px;
  border-radius: 4px;
  min-width: 150px;
  box-shadow: 0 8px 24px 0 var(--border-transparent);
  background-color: var(--primary);
  font-family: ${({ theme }) => theme.font.family.websiteBold};
  font-size: 18px;
  line-height: 24px;
  color: var(--light-values-white);
  display: flex;
  gap: 21px;
  align-items: center;
  text-transform: capitalize;
  justify-content: center;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    justify-content: center;
  }
  ${({ theme }) => theme.max('md')`
    padding: 16px 22.5px 16px 32px;
  `}
  ${({ $showUploadCTA }) =>
    $showUploadCTA &&
    css`
      padding: 16px;
      gap: 8px;
    `}
  &:hover {
    background-color: var(--additional-colors-pink-old);
  }
  ${({ $isNewDesign }) =>
    $isNewDesign &&
    css`
      border-radius: 11.714px;
    `}
  ${({ $isNewMobileDesign, $isMobileV3 }) =>
    $isNewMobileDesign &&
    css`
      margin-top: 16px !important;
      padding: 16px 24px !important;
      gap: 8px !important;
      border-radius: 4px;
      font-family: Gilroy SemiBold;
      font-weight: 400;
      ${$isMobileV3 &&
      css`
        margin-top: 0 !important;
      `}
    `}

    ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `}
`;

const Container = styled.a`
  position: relative;
  z-index: 57;
  margin-top: 45px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  @media only screen and (max-width: 992px) {
    flex-direction: column;
    gap: 16px !important;
  }
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      margin-top: 24px;
    `}
  ${({ $maxWidth, $isLongTranslation, $isMobile }) =>
    $maxWidth &&
    !$isMobile &&
    css`
      max-width: ${$maxWidth}px;
      right: ${$isLongTranslation ? 20 : 10}px;
    `}

  ${({ $showUploadCTA }) =>
    $showUploadCTA &&
    css`
      gap: 24px;
    `}
`;

CTA.propTypes = {
  onClick: PropTypes.func,
  userIdentified: PropTypes.bool,
  isNewDesign: PropTypes.bool,
};

export default React.forwardRef(CTA);
