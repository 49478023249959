import React from 'react';

const Quality6Icon = () => {
  return (
    <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M42.5002 2.90625H17.9883C15.6411 2.90625 13.7383 4.77173 13.7383 7.07292V42.7812C13.7383 45.0824 15.6411 46.9479 17.9883 46.9479H42.5002C44.8474 46.9479 46.7502 45.0824 46.7502 42.7812V7.07292C46.7502 4.77173 44.8474 2.90625 42.5002 2.90625Z"
        fill="#5DDFA9"
      />
      <path
        d="M41.1187 16.0208H6.2793C5.10569 16.0208 4.1543 16.9536 4.1543 18.1042V29.3958C4.1543 30.5464 5.10569 31.4792 6.2793 31.4792H41.1187C42.2923 31.4792 43.2437 30.5464 43.2437 29.3958V18.1042C43.2437 16.9536 42.2923 16.0208 41.1187 16.0208Z"
        fill="#0050C7"
      />
      <path
        d="M40.0562 14.9792H5.2168C4.04319 14.9792 3.0918 15.9119 3.0918 17.0625V28.3542C3.0918 29.5048 4.04319 30.4375 5.2168 30.4375H40.0562C41.2298 30.4375 42.1812 29.5048 42.1812 28.3542V17.0625C42.1812 15.9119 41.2298 14.9792 40.0562 14.9792Z"
        fill="#69AAFF"
      />
      <path
        d="M13.7383 14.9792H40.0564C41.2252 14.9792 42.1814 15.9167 42.1814 17.0625V28.3542C42.1814 29.5 41.2252 30.4375 40.0564 30.4375H13.7383V14.9792Z"
        fill="#0866F5"
      />
      <path
        d="M38.558 16.6458H6.71484C5.54124 16.6458 4.58984 17.5786 4.58984 18.7292V26.6875C4.58984 27.8381 5.54124 28.7708 6.71484 28.7708H38.558C39.7316 28.7708 40.683 27.8381 40.683 26.6875V18.7292C40.683 17.5786 39.7316 16.6458 38.558 16.6458Z"
        fill="#EFF5FF"
      />
      <path
        d="M35.338 20.8333H18.8495C17.8281 20.8333 17 21.7661 17 22.9167C17 24.0673 17.8281 25 18.8495 25H35.338C36.3594 25 37.1875 24.0673 37.1875 22.9167C37.1875 21.7661 36.3594 20.8333 35.338 20.8333Z"
        fill="#0866F5"
      />
      <path
        d="M6.71484 16.6458H13.738V28.7708H6.71484C5.54609 28.7708 4.58984 27.8333 4.58984 26.6875V18.7292C4.58984 17.5833 5.54609 16.6458 6.71484 16.6458Z"
        fill="white"
      />
      <path
        d="M29.0806 7.29167H19.6138C18.4402 7.29167 17.4888 8.22441 17.4888 9.37501C17.4888 10.5256 18.4402 11.4583 19.6138 11.4583H29.0806C30.2543 11.4583 31.2056 10.5256 31.2056 9.37501C31.2056 8.22441 30.2543 7.29167 29.0806 7.29167Z"
        fill="#DDFFF1"
      />
      <path
        d="M38.5581 37.4479H19.6138C18.4402 37.4479 17.4888 38.3806 17.4888 39.5312C17.4888 40.6818 18.4402 41.6146 19.6138 41.6146H38.5581C39.7318 41.6146 40.6831 40.6818 40.6831 39.5312C40.6831 38.3806 39.7318 37.4479 38.5581 37.4479Z"
        fill="#DDFFF1"
      />
    </svg>
  );
};
export default Quality6Icon;
