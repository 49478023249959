import arrayMove from 'array-move';
import set from 'lodash/set';

import { EMPTY_FIELDS, TEMPLATES } from '/imports/generator/api/constants';
import { GET_RESUME } from '/imports/generator/api/apollo/client/queries';
import { sortBlocks } from '/imports/generator/api/helpers';

const updateCachedResume = (cache, resume, resumeId) => {
  const currentData = cache.readQuery({
    query: GET_RESUME,
    variables: {
      resumeId,
    },
  });

  cache.writeQuery({
    query: GET_RESUME,
    variables: {
      resumeId,
    },
    data: {
      getResume: {
        ...currentData.getResume,
        ...resume,
      },
    },
  });
};

export const updateBlocksAfterReorder =
  (resumeId) =>
  (cache, { data: { reorderBlock } }) => {
    updateCachedResume(cache, reorderBlock, resumeId);
  };

// export const updateResume = (resumeId, mutationName) => (cache, { data }) => {
//   updateCachedResume(cache, data[mutationName], resumeId);
// };

export const blockReorderOptimistic = (resume, blockId, direction, newPosition) => {
  const { blocks } = resume;
  const currentBlock = blocks.find((block) => block.id === blockId) || {};

  switch (direction) {
    case 'UP':
      // Only move un-fixed blocks that aren't already first
      if (!currentBlock.fixedPosition && currentBlock.position[1] > 0) {
        const [colIndex, currentPos] = currentBlock.position;
        const prevBlock = blocks
          // Get all blocks in column
          .filter((b) => b.position[0] === colIndex)
          // Find previous block
          .find((b) => b.position[1] === currentPos - 1);

        // Swap target block positions
        currentBlock.position[1] = Math.max(0, currentPos - 1);
        prevBlock.position[1] = Math.max(0, currentPos);
      }

      break;

    case 'DOWN':
      if (!currentBlock.fixedPosition) {
        // Get all blocks in the target column
        const [colIndex, currentPos] = currentBlock.position;
        const colBlocks = blocks.filter((b) => b.position[0] === colIndex);

        // Only move blocks that aren't already at the end
        if (currentPos + 1 < colBlocks.length) {
          const nextBlock = colBlocks.find((b) => b.position[1] === currentPos + 1);

          // Swap target block positions
          currentBlock.position[1] = Math.max(0, currentPos + 1);
          nextBlock.position[1] = Math.max(0, currentPos);
        }
      }

      break;

    case 'LEFT':
      if (!currentBlock.fixedPosition && currentBlock.position[0] > 0) {
        const [colIndex] = currentBlock.position;
        // Move block to its same Y position in the column to the left
        currentBlock.position[0] = Math.max(0, colIndex - 1);
      }

      break;

    case 'RIGHT':
      if (!currentBlock.fixedPosition) {
        const [colIndex] = currentBlock.position;
        // Move block to its same Y position in the column to the right
        currentBlock.position[0] = Math.max(0, colIndex + 1);
      }
      break;

    case 'DRAG':
      const block = blocks.find((b) => b.id === blockId);
      if (block && !block.fixedPosition && newPosition.length) {
        const [xPosition, yPosition] = newPosition;
        const [oldXPosition, oldYPosition] = block.position;

        // update the column based on xPosition
        block.position[0] = Math.max(0, xPosition);

        // if block have been added from another column
        if (xPosition !== oldXPosition) {
          // gets all the blocks in new column and which comes after the new position
          const newColumnBlocks = blocks.filter((b) => b.position[0] === xPosition && b.position[1] >= yPosition);
          // Swap all the blocks with 1 down
          newColumnBlocks.forEach((b) => {
            b.position[1] = Math.max(0, b.position[1] + 1);
          });
          block.position[1] = Math.max(0, yPosition);

          // get all old column blocks
          const oldColumnBlocks = blocks.filter((b) => b.position[0] === oldXPosition);
          oldColumnBlocks.sort((a, b) => {
            const firstElementPositionY = a.position[1];
            const secondElementPositionY = a.position[1];
            if (firstElementPositionY < secondElementPositionY) {
              return -1;
            }
            if (firstElementPositionY > secondElementPositionY) {
              return 1;
            }
            return 0;
          });
          oldColumnBlocks.forEach((b, index) => {
            b.position[1] = index;
          });
        } else {
          const existingColumnBlocks = blocks.filter((b) => b.position[0] === xPosition);
          existingColumnBlocks.sort((a, b) => {
            const firstValue = a.position[1];
            const secondValue = b.position[1];
            if (firstValue < secondValue) {
              return -1;
            }
            if (firstValue > secondValue) {
              return 1;
            }
            return 0;
          });

          arrayMove.mutate(existingColumnBlocks, oldYPosition, yPosition);
          existingColumnBlocks.forEach((b, index) => {
            b.position[1] = index;
          });
        }
      }
      break;
  }
  return {
    __typename: 'Mutation',
    reorderBlock: {
      __typename: 'Resume',
      ...resume,
      details: {
        __typename: 'ResumeDetails',
        ...resume.details,
      },
      blocks: sortBlocks(blocks).map((block) => ({
        ...block,
        __typename: 'Block',
        items: block.items ? block.items.map((item) => ({ __typename: 'BlockItem', ...item })) : null,
      })),
    },
  };
};

export const blockRemoveImmutable = (update) => (source, blockId) => {
  const { blocks } = source;
  const currentBlock = blocks.find((block) => block.id === blockId);
  const colIndex = currentBlock.position[0];
  blocks.splice(
    blocks.findIndex((block) => block.id === blockId),
    1,
  );
  blocks.filter((el) => el.position[0] === colIndex).map((el, i) => (el.position[1] = i));
  update(source);
};

export const blockItemReorderOptimistic = (update) => (source, blockId, itemId, direction) => {
  const { blocks } = source;
  const { items } = blocks.find((block) => block.id === blockId);
  const currentItem = items.find((item) => item.id === itemId);

  if (direction === 'DOWN') {
    if (currentItem.order + 1 < items.length) {
      const nextItem = items.find((item) => item.order === currentItem.order + 1);

      currentItem.order = currentItem.order + 1;
      nextItem.order = nextItem.order - 1;
    }
  } else if (direction === 'UP') {
    if (currentItem.order) {
      const prevItem = items.find((i) => i.order === currentItem.order - 1);

      currentItem.order = currentItem.order - 1;
      prevItem.order = prevItem.order + 1;
    }
  }
  update(source);
};

//update details autosuggest immutable state
export const updateOptimisticAutoSuggest = (update, variables) => {
  const source = update(null, true);
  const options = { ...variables };
  options.postalCode = options.zip;
  delete options.resumeId;
  delete options.zip;
  source.details = { ...source.details, ...options };
  update(source);
};

//update block switch immutable state
export const blockItemImmutableUpdateSwitch = (update) => (resumeId, blockId, name) => (value) => {
  const source = update(null, true);
  const block = source.blocks.find((block) => block.id === blockId);
  if (block) {
    block[name] = value;
  }
  update(source);
};

//update local block detail immutable state
export const blockDetailImmutableUpdate = (update) => (id, path) => (value) => {
  const source = update(null, true);
  if (path === 'settings.template') {
    const { defaultColor } = TEMPLATES.find((template) => template.id === value) || {};
    source.settings.color = defaultColor || 'black';
  }
  let res = { ...source };
  set(res, path, value);
  update(res);
};

//remove block item optimistic with immutable state
export const blockItemRemoveOptimistic = (update, resumeId, blockId, itemId) => {
  const source = update(null, true);
  const block = source.blocks.find((k) => k.id === blockId);
  block.items.splice(
    block.items.findIndex((item) => item.id === itemId),
    1,
  );
  block.items.map((el, i) => (el.order = i));
  update(source);
};

//update loca block detail immutable state
export const blockImmutableUpdate = (update, blockId, field, value) => {
  const source = update(null, true);
  const block = source.blocks.find((k) => k.id === blockId);
  block[field] = value;
  update(source);
};

//update local block item immutable state
export const blockItemImmutableUpdate = (update) => (resumeId, blockId, itemId, field) => (value) => {
  const source = update(null, true);
  const block = source.blocks.find((block) => block.id === blockId);
  const item = block.items.find((item) => item.id === itemId);
  if (item.fields) {
    if (typeof field === 'object' && typeof value === 'object' && field.indexOf(value.field) !== -1) {
      item.fields[value.field] = value.value;
    } else {
      item.fields[field] = value;
    }
  } else {
    item.fields = { __typename: 'BlockFields', ...EMPTY_FIELDS };
    item.fields[field] = value;
  }
  update(source);
};

export const updateBlocksAfterItemAdd =
  (update) =>
  ({ data }) => {
    if (data) {
      const { addBlockItem: { blockId, items, itemId } = {} } = data;
      try {
        const source = update(null, true);
        source.updatesCount = source.updatesCount + 1;
        const currentBlock = source.blocks.find((block) => block.id === blockId) || {};
        const newBlock = items.find((el) => el.id === itemId);
        if (!currentBlock.items) currentBlock.items = items;
        else currentBlock.items.push(newBlock);
        return update(source);
      } catch (e) {
        console.log(e);
      }
    }
  };
