import styled, { css } from 'styled-components';

import TextAtom from '/imports/pdf/core/ui/atoms/Text';

const Text = styled(TextAtom)`
  ${({ theme, light }) => css`
    font-family: ${theme.contentFont()};
    font-size: ${theme.convertPx(12, true)};
    line-height: 1.33;
    color: #808183;
    ${light &&
    css`
      color: #e6e7e8;
      ${theme.color &&
      theme.color === 'budapestGirlBlue' &&
      css`
        color: #bcbec0;
      `}
    `}
  `}
`;

export default Text;
