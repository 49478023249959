import styled from 'styled-components';
const NavMenuSvgIcon = styled.svg`
  fill: ${(p) => p.theme.colors.nav.icon};
  cursor: pointer;
  &:hover {
    fill: black;
  }
`;
export const JobIcon = ({ width = 24, height = 24 }) => {
  return (
    <NavMenuSvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className="mercado-match nav-menu-icon"
      focusable="false"
      width={width}
      height={height}
    >
      <path d="M17 6V5a3 3 0 00-3-3h-4a3 3 0 00-3 3v1H2v4a3 3 0 003 3h14a3 3 0 003-3V6zM9 5a1 1 0 011-1h4a1 1 0 011 1v1H9zm10 9a4 4 0 003-1.38V17a3 3 0 01-3 3H5a3 3 0 01-3-3v-4.38A4 4 0 005 14z"></path>
    </NavMenuSvgIcon>
  );
};
