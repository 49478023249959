import React, { useRef, useEffect } from 'react';

import Modal from '/imports/core/ui/atoms/Modal';

const ThreeDSIframe = ({ submit, onSubmit, threeDSParams: { url, threeDSMethodData, CReq, PaReq, MD, TermUrl } }) => {
  const submitButton = useRef(null);

  useEffect(() => {
    if (submit) {
      submitButton.current.click();
      onSubmit();
    }
  }, [submit]);

  return (
    <iframe>
      <form method="POST" action={url}>
        {threeDSMethodData && <input type="hidden" name="threeDSMethodData" value={threeDSMethodData} />}
        {CReq && <input type="hidden" name="creq" value={CReq} />}
        {PaReq && <input type="hidden" name="PaReq" value={PaReq} />}
        {MD && <input type="hidden" name="MD" value={MD} />}
        {TermUrl && <input type="hidden" name="TermUrl" value={TermUrl} />}
        <button ref={submitButton} type="submit" />
      </form>
    </iframe>
  );
};

export default ThreeDSIframe;
