import React from 'react';
import styled from 'styled-components';

const RightArrow = () => {
  return (
    <IconCont>
      <SvgIcon width="20" height="14" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19.728 6.188L13.776.271a.934.934 0 00-1.315 0 .92.92 0 000 1.307l4.364 4.34H.93a.927.927 0 00-.93.924c0 .51.416.925.93.925h15.895l-4.364 4.339a.92.92 0 000 1.307.93.93 0 001.315 0l5.952-5.917a.92.92 0 000-1.308z"
          fillRule="nonzero"
        />
      </SvgIcon>
    </IconCont>
  );
};

const IconCont = styled.span`
  position: absolute;
  margin-top: 1px;
  right: 16px;
`;

const SvgIcon = styled.svg`
  stroke: none;
  fill: currentColor;
`;

export default RightArrow;
