import styled from 'styled-components';

const UpArrow = ({ stroke }) => {
  return (
    <SvgIcon width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.263 14.868V1m0 0L2 7.263M8.263 1l6.263 6.263"
        stroke={stroke || '#BFC5D6'}
        strokeWidth="1.789"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

const SvgIcon = styled.svg`
  stroke: none;
`;

export default UpArrow;
