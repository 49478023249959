import { css } from 'styled-components';

import { staticFile } from '/lib/helpers';

export const FONT_FAMILY = {
  lato: css`
    @font-face {
      font-family: 'Lato';
      src: url(${staticFile('fonts/Lato-Regular.ttf')});
    }
    @font-face {
      font-family: 'Lato Italic';
      src: url(${staticFile('fonts/Lato-Italic.ttf')});
    }
    @font-face {
      font-family: 'Lato Bold';
      src: url(${staticFile('fonts/Lato-Bold.ttf')});
    }
    @font-face {
      font-family: 'Lato BoldItalic';
      src: url(${staticFile('fonts/Lato-BoldItalic.ttf')});
    }
    @font-face {
      font-family: 'Lato Medium';
      src: url(${staticFile('fonts/Lato-Medium.ttf')});
    }
    @font-face {
      font-family: 'Lato SemiBold';
      src: url(${staticFile('fonts/Lato-Semibold.ttf')});
    }
    @font-face {
      font-family: 'Lato Light';
      src: url(${staticFile('fonts/Lato-Light.ttf')});
    }
    @font-face {
      font-family: 'Lato Black';
      src: url(${staticFile('fonts/Lato-Black.ttf')});
    }
  `,
  raleway: css`
    @font-face {
      font-family: 'Raleway';
      src: url(${staticFile('fonts/Raleway-Regular.ttf')});
    }
    @font-face {
      font-family: 'Raleway Medium';
      src: url(${staticFile('fonts/Raleway-Medium.ttf')});
    }
    @font-face {
      font-family: 'Raleway Italic';
      src: url(${staticFile('fonts/Raleway-Italic.ttf')});
    }
    @font-face {
      font-family: 'Raleway SemiBold';
      src: url(${staticFile('fonts/Raleway-SemiBold.ttf')});
    }
    @font-face {
      font-family: 'Raleway Bold';
      src: url(${staticFile('fonts/Raleway-Bold.ttf')});
    }
    @font-face {
      font-family: 'Raleway BoldItalic';
      src: url(${staticFile('fonts/Raleway-BoldItalic.ttf')});
    }
    @font-face {
      font-family: 'Raleway Light';
      src: url(${staticFile('fonts/Raleway-Light.ttf')});
    }
  `,
  arial: css`
    @font-face {
      font-family: 'Arial Bold';
      src: url(${staticFile('fonts/Arial-Bold.woff')});
    }
    @font-face {
      font-family: 'Arial Italic';
      src: url(${staticFile('fonts/Arial-Italic.woff')});
    }
    @font-face {
      font-family: 'Arial BoldItalic';
      src: url(${staticFile('fonts/Arial-BoldItalic.woff')});
    }
    @font-face {
      font-family: 'Arial Medium';
      src: url(${staticFile('fonts/Arial/Arial-Medium.ttf')});
    }
    @font-face {
      font-family: 'Arial Light';
      src: url(${staticFile('fonts/Arial/Arial-Light.ttf')});
    }
  `,
  crimsonText: css`
    @font-face {
      font-family: 'CrimsonText';
      src: url(${staticFile('fonts/CrimsonText-Regular.ttf')});
    }
    @font-face {
      font-family: 'CrimsonText Italic';
      src: url(${staticFile('fonts/CrimsonText-Italic.ttf')});
    }
    @font-face {
      font-family: 'CrimsonText Bold';
      src: url(${staticFile('fonts/CrimsonText-Bold.ttf')});
    }
    @font-face {
      font-family: 'CrimsonText BoldItalic';
      src: url(${staticFile('fonts/CrimsonText-BoldItalic.ttf')});
    }
    @font-face {
      font-family: 'CrimsonText SemiBoldItalic';
      src: url(${staticFile('fonts/CrimsonText-SemiBoldItalic.ttf')});
    }
    @font-face {
      font-family: 'CrimsonText SemiBold';
      src: url(${staticFile('fonts/CrimsonText-SemiBold.ttf')});
    }
    @font-face {
      font-family: 'Crimson Roman';
      src: url(${staticFile('fonts/Crimson-Roman.ttf')});
    }
  `,
  butler: css`
    @font-face {
      font-family: 'Butler Regular';
      src: url(${staticFile('fonts/Butler-Regular.otf')});
    }
    @font-face {
      font-family: 'Butler Bold';
      src: url(${staticFile('fonts/Butler-Bold.otf')});
    }
    @font-face {
      font-family: 'Butler Medium';
      src: url(${staticFile('fonts/Butler-Medium.otf')});
    }
    @font-face {
      font-family: 'Butler Light';
      src: url(${staticFile('fonts/Butler/Butler-Light.otf')});
    }
  `,
  playfairDisplay: css`
    @font-face {
      font-family: 'PlayfairDisplay';
      src: url(${staticFile('fonts/PlayfairDisplay/PlayfairDisplay-Regular.woff')});
    }
    @font-face {
      font-family: 'PlayfairDisplay Medium';
      src: url(${staticFile('fonts/PlayfairDisplay/PlayfairDisplay-Medium.woff')});
    }
    @font-face {
      font-family: 'PlayfairDisplay Bold';
      src: url(${staticFile('fonts/PlayfairDisplay/PlayfairDisplay-Bold.woff')});
    }
    @font-face {
      font-family: 'PlayfairDisplay SemiBold';
      src: url(${staticFile('fonts/PlayfairDisplay/PlayfairDisplay-SemiBold.woff')});
    }
    @font-face {
      font-family: 'PlayfairDisplay Italic';
      src: url(${staticFile('fonts/PlayfairDisplay/PlayfairDisplay-Italic.woff')});
    }
    @font-face {
      font-family: 'PlayfairDisplay BoldItalic';
      src: url(${staticFile('fonts/PlayfairDisplay/PlayfairDisplay-BoldItalic.woff')});
    }
  `,
  kelsonSans: css`
    @font-face {
      font-family: 'KelsonSans';
      src: url(${staticFile('fonts/KelsonSans-Normal.ttf')});
    }
    @font-face {
      font-family: 'KelsonSans Bold';
      src: url(${staticFile('fonts/KelsonSans-Bold.ttf')});
    }
    @font-face {
      font-family: 'KelsonSans Light';
      src: url(${staticFile('fonts/KelsonSans-Light.ttf')});
    }
  `,
  bebasNeue: css`
    @font-face {
      font-family: 'BebasNeue';
      src: url(${staticFile('fonts/BebasNeue-Regular.ttf')});
    }
  `,
  montserrat: css`
    @font-face {
      font-family: 'Montserrat';
      src: url(${staticFile('fonts/Montserrat-Regular.ttf')});
    }
    @font-face {
      font-family: 'Montserrat Bold';
      src: url(${staticFile('fonts/Montserrat-Bold.ttf')});
    }
    @font-face {
      font-family: 'Montserrat Light';
      src: url(${staticFile('fonts/Montserrat-Light.ttf')});
    }
    @font-face {
      font-family: 'Montserrat Medium';
      src: url(${staticFile('fonts/Montserrat-Medium.ttf')});
    }
    @font-face {
      font-family: 'Montserrat SemiBold';
      src: url(${staticFile('fonts/Montserrat-SemiBold.ttf')});
    }
    @font-face {
      font-family: 'Montserrat BoldItalic';
      src: url(${staticFile('fonts/Montserrat-BoldItalic.ttf')});
    }
    @font-face {
      font-family: 'Montserrat Italic';
      src: url(${staticFile('fonts/Montserrat-Italic.ttf')});
    }
  `,
  roboto: css`
    @font-face {
      font-family: 'Roboto';
      src: url(${staticFile('fonts/Roboto-Regular.ttf')});
    }
    @font-face {
      font-family: 'Roboto Medium';
      src: url(${staticFile('fonts/Roboto-Medium.ttf')});
    }
    @font-face {
      font-family: 'Roboto Italic';
      src: url(${staticFile('fonts/Roboto-Italic.ttf')});
    }
    @font-face {
      font-family: 'Roboto Bold';
      src: url(${staticFile('fonts/Roboto-Bold.ttf')});
    }
    @font-face {
      font-family: 'Roboto BoldItalic';
      src: url(${staticFile('fonts/Roboto-BoldItalic.ttf')});
    }
    @font-face {
      font-family: 'Roboto Black';
      src: url(${staticFile('fonts/Roboto-Black.ttf')});
    }
    @font-face {
      font-family: 'Roboto Light';
      src: url(${staticFile('fonts/Roboto-Light.ttf')});
    }
  `,
  ubuntu: css`
    @font-face {
      font-family: 'Ubuntu';
      src: url(${staticFile('fonts/Ubuntu/Ubuntu-Regular.ttf')});
    }
    @font-face {
      font-family: 'Ubuntu Medium';
      src: url(${staticFile('fonts/Ubuntu/Ubuntu-Medium.ttf')});
    }
    @font-face {
      font-family: 'Ubuntu Italic';
      src: url(${staticFile('fonts/Ubuntu/Ubuntu-Italic.ttf')});
    }
    @font-face {
      font-family: 'Ubuntu Bold';
      src: url(${staticFile('fonts/Ubuntu/Ubuntu-Bold.ttf')});
    }
    @font-face {
      font-family: 'Ubuntu BoldItalic';
      src: url(${staticFile('fonts/Ubuntu/Ubuntu-BoldItalic.ttf')});
    }
    @font-face {
      font-family: 'Ubuntu Light';
      src: url(${staticFile('fonts/Ubuntu/Ubuntu-Light.ttf')});
    }
  `,
  sourceSansPro: css`
    @font-face {
      font-family: 'SourceSansPro';
      src: url(${staticFile('fonts/SourceSansPro/SourceSansPro-Regular.ttf')});
    }
    @font-face {
      font-family: 'SourceSansPro SemiBold';
      src: url(${staticFile('fonts/SourceSansPro/SourceSansPro-SemiBold.ttf')});
    }
    @font-face {
      font-family: 'SourceSansPro Italic';
      src: url(${staticFile('fonts/SourceSansPro/SourceSansPro-Italic.ttf')});
    }
    @font-face {
      font-family: 'SourceSansPro Bold';
      src: url(${staticFile('fonts/SourceSansPro/SourceSansPro-Bold.ttf')});
    }
    @font-face {
      font-family: 'SourceSansPro BoldItalic';
      src: url(${staticFile('fonts/SourceSansPro/SourceSansPro-BoldItalic.ttf')});
    }
    @font-face {
      font-family: 'SourceSansPro Light';
      src: url(${staticFile('fonts/SourceSansPro/SourceSansPro-Light.ttf')});
    }
  `,
  nunito: css`
    @font-face {
      font-family: 'Nunito';
      src: url(${staticFile('fonts/Nunito/Nunito-Regular.ttf')});
    }
    @font-face {
      font-family: 'Nunito SemiBold';
      src: url(${staticFile('fonts/Nunito/Nunito-SemiBold.ttf')});
    }
    @font-face {
      font-family: 'Nunito Italic';
      src: url(${staticFile('fonts/Nunito/Nunito-Italic.ttf')});
    }
    @font-face {
      font-family: 'Nunito Bold';
      src: url(${staticFile('fonts/Nunito/Nunito-Bold.ttf')});
    }
    @font-face {
      font-family: 'Nunito BoldItalic';
      src: url(${staticFile('fonts/Nunito/Nunito-BoldItalic.ttf')});
    }
    @font-face {
      font-family: 'Nunito Light';
      src: url(${staticFile('fonts/Nunito/Nunito-Light.ttf')});
    }
  `,
  lora: css`
    @font-face {
      font-family: 'Lora';
      src: url(${staticFile('fonts/Lora/Lora-Regular.woff')});
    }
    @font-face {
      font-family: 'Lora Medium';
      src: url(${staticFile('fonts/Lora/Lora-Medium.woff')});
    }
    @font-face {
      font-family: 'Lora Italic';
      src: url(${staticFile('fonts/Lora/Lora-Italic.woff')});
    }
    @font-face {
      font-family: 'Lora Bold';
      src: url(${staticFile('fonts/Lora/Lora-Bold.woff')});
    }
    @font-face {
      font-family: 'Lora BoldItalic';
      src: url(${staticFile('fonts/Lora/Lora-BoldItalic.woff')});
    }
    @font-face {
      font-family: 'Lora SemiBold';
      src: url(${staticFile('fonts/Lora/Lora-SemiBold.woff')});
    }
  `,
  ibmPlexSans: css`
    @font-face {
      font-family: 'IBMPlexSans';
      src: url(${staticFile('fonts/IBMPlexSans/IBMPlexSans-Regular.ttf')});
    }
    @font-face {
      font-family: 'IBMPlexSans Medium';
      src: url(${staticFile('fonts/IBMPlexSans/IBMPlexSans-Medium.ttf')});
    }
    @font-face {
      font-family: 'IBMPlexSans Italic';
      src: url(${staticFile('fonts/IBMPlexSans/IBMPlexSans-Italic.ttf')});
    }
    @font-face {
      font-family: 'IBMPlexSans Bold';
      src: url(${staticFile('fonts/IBMPlexSans/IBMPlexSans-Bold.ttf')});
    }
    @font-face {
      font-family: 'IBMPlexSans BoldItalic';
      src: url(${staticFile('fonts/IBMPlexSans/IBMPlexSans-BoldItalic.ttf')});
    }
    @font-face {
      font-family: 'IBMPlexSans SemiBold';
      src: url(${staticFile('fonts/IBMPlexSans/IBMPlexSans-SemiBold.ttf')});
    }
    @font-face {
      font-family: 'IBMPlexSans Light';
      src: url(${staticFile('fonts/IBMPlexSans/IBMPlexSans-Light.ttf')});
    }
  `,
  exo: css`
    @font-face {
      font-family: 'Exo';
      src: url(${staticFile('fonts/Exo/Exo-Regular.woff')});
    }
    @font-face {
      font-family: 'Exo Medium';
      src: url(${staticFile('fonts/Exo/Exo-Medium.woff')});
    }
    @font-face {
      font-family: 'Exo Italic';
      src: url(${staticFile('fonts/Exo/Exo-Italic.woff')});
    }
    @font-face {
      font-family: 'Exo Bold';
      src: url(${staticFile('fonts/Exo/Exo-Bold.woff')});
    }
    @font-face {
      font-family: 'Exo BoldItalic';
      src: url(${staticFile('fonts/Exo/Exo-BoldItalic.woff')});
    }
    @font-face {
      font-family: 'Exo SemiBold';
      src: url(${staticFile('fonts/Exo/Exo-SemiBold.woff')});
    }
    @font-face {
      font-family: 'Exo Light';
      src: url(${staticFile('fonts/Exo/Exo-Light.woff')});
    }
  `,
  helvetica: css`
    @font-face {
      font-family: 'Helvetica';
      src: url(${staticFile('fonts/Helvetica/Helvetica.ttf')});
    }
    @font-face {
      font-family: 'Helvetica Italic';
      src: url(${staticFile('fonts/Helvetica/Helvetica-Italic.ttf')});
    }
    @font-face {
      font-family: 'Helvetica Bold';
      src: url(${staticFile('fonts/Helvetica/Helvetica-Bold.ttf')});
    }
    @font-face {
      font-family: 'Helvetica BoldItalic';
      src: url(${staticFile('fonts/Helvetica/Helvetica-BoldItalic.ttf')});
    }
    @font-face {
      font-family: 'Helvetica Light';
      src: url(${staticFile('fonts/Helvetica/Helvetica-Light.otf')});
    }
  `,
  firaSans: css`
    @font-face {
      font-family: 'FiraSans';
      src: url(${staticFile('fonts/FiraSans/FiraSans-Regular.otf')});
    }
    @font-face {
      font-family: 'FiraSans Medium';
      src: url(${staticFile('fonts/FiraSans/FiraSans-Medium.otf')});
    }
    @font-face {
      font-family: 'FiraSans Italic';
      src: url(${staticFile('fonts/FiraSans/FiraSans-Italic.otf')});
    }
    @font-face {
      font-family: 'FiraSans Bold';
      src: url(${staticFile('fonts/FiraSans/FiraSans-Bold.otf')});
    }
    @font-face {
      font-family: 'FiraSans BoldItalic';
      src: url(${staticFile('fonts/FiraSans/FiraSans-BoldItalic.otf')});
    }
    @font-face {
      font-family: 'FiraSans SemiBold';
      src: url(${staticFile('fonts/FiraSans/FiraSans-SemiBold.otf')});
    }
    @font-face {
      font-family: 'FiraSans Light';
      src: url(${staticFile('fonts/FiraSans/FiraSans-Light.otf')});
    }
  `,
  bebasKai: css`
    @font-face {
      font-family: 'BebasKai';
      src: url(${staticFile('fonts/BebasKai/BebasKai.otf')});
    }
  `,
  aller: css`
    @font-face {
      font-family: 'Aller';
      src: url(${staticFile('fonts/Aller/Aller-Regular.ttf')});
    }
    @font-face {
      font-family: 'Aller Italic';
      src: url(${staticFile('fonts/Aller/Aller-Italic.ttf')});
    }
    @font-face {
      font-family: 'Aller Bold';
      src: url(${staticFile('fonts/Aller/Aller-Bold.ttf')});
    }
    @font-face {
      font-family: 'Aller BoldItalic';
      src: url(${staticFile('fonts/Aller/Aller-BoldItalic.ttf')});
    }
    @font-face {
      font-family: 'Aller Light';
      src: url(${staticFile('fonts/Aller/Aller-Light.ttf')});
    }
  `,
  calibri: css`
    @font-face {
      font-family: 'Calibri';
      src: url(${staticFile('fonts/Calibri/Calibri-Regular.ttf')});
    }
    @font-face {
      font-family: 'Calibri Italic';
      src: url(${staticFile('fonts/Calibri/Calibri-Italic.ttf')});
    }
    @font-face {
      font-family: 'Calibri Bold';
      src: url(${staticFile('fonts/Calibri/Calibri-Bold.ttf')});
    }
    @font-face {
      font-family: 'Calibri BoldItalic';
      src: url(${staticFile('fonts/Calibri/Calibri-BoldItalic.ttf')});
    }
    @font-face {
      font-family: 'Calibri Light';
      src: url(${staticFile('fonts/Calibri/Calibri-Light.ttf')});
    }
  `,
  workSans: css`
    @font-face {
      font-family: 'WorkSans';
      src: url(${staticFile('fonts/WorkSans/WorkSans-Regular.woff')});
    }
    @font-face {
      font-family: 'WorkSans Medium';
      src: url(${staticFile('fonts/WorkSans/WorkSans-Medium.woff')});
    }
    @font-face {
      font-family: 'WorkSans Italic';
      src: url(${staticFile('fonts/WorkSans/WorkSans-Italic.woff')});
    }
    @font-face {
      font-family: 'WorkSans Bold';
      font-weight: 600;
      src: url(${staticFile('fonts/WorkSans/WorkSans-Bold.woff')});
    }
    @font-face {
      font-family: 'WorkSans BoldItalic';
      src: url(${staticFile('fonts/WorkSans/WorkSans-BoldItalic.woff')});
    }
    @font-face {
      font-family: 'WorkSans SemiBold';
      src: url(${staticFile('fonts/WorkSans/WorkSans-SemiBold.woff')});
    }
    @font-face {
      font-family: 'WorkSans Light';
      src: url(${staticFile('fonts/WorkSans/WorkSans-Light.woff')});
    }
  `,
  robotoMono: css`
    @font-face {
      font-family: 'RobotoMono';
      src: url(${staticFile('fonts/RobotoMono/RobotoMono-Regular.woff')});
    }
    @font-face {
      font-family: 'RobotoMono Medium';
      src: url(${staticFile('fonts/RobotoMono/RobotoMono-Medium.woff')});
    }
    @font-face {
      font-family: 'RobotoMono Italic';
      src: url(${staticFile('fonts/RobotoMono/RobotoMono-Italic.woff')});
    }
    @font-face {
      font-family: 'RobotoMono Bold';
      src: url(${staticFile('fonts/RobotoMono/RobotoMono-Bold.woff')});
    }
    @font-face {
      font-family: 'RobotoMono BoldItalic';
      src: url(${staticFile('fonts/RobotoMono/RobotoMono-BoldItalic.woff')});
    }
    @font-face {
      font-family: 'RobotoMono SemiBold';
      src: url(${staticFile('fonts/RobotoMono/RobotoMono-SemiBold.woff')});
    }
    @font-face {
      font-family: 'RobotoMono Light';
      src: url(${staticFile('fonts/RobotoMono/RobotoMono-Light.woff')});
    }
  `,
  comfortaa: css`
    @font-face {
      font-family: 'Comfortaa';
      src: url(${staticFile('fonts/Comfortaa/Comfortaa-Regular.ttf')});
    }
    @font-face {
      font-family: 'Comfortaa Medium';
      src: url(${staticFile('fonts/Comfortaa/Comfortaa-Medium.woff')});
    }
    @font-face {
      font-family: 'Comfortaa Bold';
      src: url(${staticFile('fonts/Comfortaa/Comfortaa-Bold.woff')});
    }
    @font-face {
      font-family: 'Comfortaa SemiBold';
      src: url(${staticFile('fonts/Comfortaa/Comfortaa-SemiBold.woff')});
    }
    @font-face {
      font-family: 'Comfortaa Light';
      src: url(${staticFile('fonts/Comfortaa/Comfortaa-Light.woff')});
    }
  `,
  openSans: css`
    @font-face {
      font-family: 'OpenSans';
      src: url(${staticFile('fonts/OpenSans-Regular.ttf')});
    }
    @font-face {
      font-family: 'OpenSans BoldItalic';
      src: url(${staticFile('fonts/OpenSans/OpenSans-BoldItalic.ttf')});
    }
    @font-face {
      font-family: 'OpenSans Bold';
      src: url(${staticFile('fonts/OpenSans-Bold.ttf')});
    }
    @font-face {
      font-family: 'OpenSans SemiBold';
      src: url(${staticFile('fonts/OpenSans/OpenSans-SemiBold.ttf')});
    }
    @font-face {
      font-family: 'OpenSans Italic';
      src: url(${staticFile('fonts/OpenSans/OpenSans-Italic.ttf')});
    }
    @font-face {
      font-family: 'OpenSans Light';
      src: url(${staticFile('fonts/OpenSans/OpenSans-Light.ttf')});
    }
  `,
};

//for FontSelector component. For loading only regular styles
export const GENERAL_FONTS_IMPORT = css`
  @font-face {
    font-family: 'Lato';
    src: url(${staticFile('fonts/Lato-Regular.ttf')});
  }
  @font-face {
    font-family: 'Raleway';
    src: url(${staticFile('fonts/Raleway-Regular.ttf')});
  }
  @font-face {
    font-family: 'Rubik';
    src: url(${staticFile('fonts/Rubik-Regular.ttf')});
    font-weight: 400;
  }
  @font-face {
    font-family: 'Rubik Bold';
    src: url(${staticFile('fonts/Rubik-Bold.ttf')});
    font-weight: 700;
  }
  @font-face {
    font-family: 'Amiri';
    src: url(${staticFile('fonts/Amiri-Regular.ttf')});
  }
  @font-face {
    font-family: 'Amiri Bold';
    src: url(${staticFile('fonts/Amiri-Bold.ttf')});
  }
  @font-face {
    font-family: 'Lateef';
    src: url(${staticFile('fonts/Lateef-Regular.ttf')});
  }
  @font-face {
    font-family: 'Lateef Bold';
    src: url(${staticFile('fonts/Lateef-Bold.ttf')});
  }
  @font-face {
    font-family: 'Tajawal';
    src: url(${staticFile('fonts/Tajawal-Regular.ttf')});
  }
  @font-face {
    font-family: 'Tajawal Bold';
    src: url(${staticFile('fonts/Tajawal-Bold.ttf')});
  }
  @font-face {
    font-family: 'CrimsonText';
    src: url(${staticFile('fonts/CrimsonText-Regular.ttf')});
  }
  @font-face {
    font-family: 'Butler Regular';
    src: url(${staticFile('fonts/Butler-Regular.otf')});
  }
  @font-face {
    font-family: 'PlayfairDisplay';
    src: url(${staticFile('fonts/PlayfairDisplay/PlayfairDisplay-Regular.woff')});
  }
  @font-face {
    font-family: 'KelsonSans';
    src: url(${staticFile('fonts/KelsonSans-Normal.ttf')});
  }
  @font-face {
    font-family: 'BebasNeue';
    src: url(${staticFile('fonts/BebasNeue-Regular.ttf')});
  }
  @font-face {
    font-family: 'Montserrat';
    src: url(${staticFile('fonts/Montserrat-Regular.ttf')});
  }
  @font-face {
    font-family: 'Roboto';
    src: url(${staticFile('fonts/Roboto-Regular.ttf')});
  }
  @font-face {
    font-family: 'Ubuntu';
    src: url(${staticFile('fonts/Ubuntu/Ubuntu-Regular.ttf')});
  }
  @font-face {
    font-family: 'SourceSansPro';
    src: url(${staticFile('fonts/SourceSansPro/SourceSansPro-Regular.ttf')});
  }
  @font-face {
    font-family: 'Nunito';
    src: url(${staticFile('fonts/Nunito/Nunito-Regular.ttf')});
  }
  @font-face {
    font-family: 'Lora';
    src: url(${staticFile('fonts/Lora/Lora-Regular.woff')});
  }
  @font-face {
    font-family: 'IBMPlexSans';
    src: url(${staticFile('fonts/IBMPlexSans/IBMPlexSans-Regular.ttf')});
  }
  @font-face {
    font-family: 'Exo';
    src: url(${staticFile('fonts/Exo/Exo-Regular.woff')});
  }
  @font-face {
    font-family: 'Helvetica';
    src: url(${staticFile('fonts/Helvetica/Helvetica.ttf')});
  }
  @font-face {
    font-family: 'FiraSans';
    src: url(${staticFile('fonts/FiraSans/FiraSans-Regular.otf')});
  }
  @font-face {
    font-family: 'BebasKai';
    src: url(${staticFile('fonts/BebasKai/BebasKai.otf')});
  }
  @font-face {
    font-family: 'Aller';
    src: url(${staticFile('fonts/Aller/Aller-Regular.ttf')});
  }
  @font-face {
    font-family: 'Calibri';
    src: url(${staticFile('fonts/Calibri/Calibri-Regular.ttf')});
  }
  @font-face {
    font-family: 'WorkSans';
    src: url(${staticFile('fonts/WorkSans/WorkSans-Regular.woff')});
  }
  @font-face {
    font-family: 'RobotoMono';
    src: url(${staticFile('fonts/RobotoMono/RobotoMono-Regular.woff')});
  }
  @font-face {
    font-family: 'Comfortaa';
    src: url(${staticFile('fonts/Comfortaa/Comfortaa-Regular.ttf')});
  }
  @font-face {
    font-family: 'OpenSans';
    src: url(${staticFile('fonts/OpenSans-Regular.ttf')});
  }
  @font-face {
    font-family: 'Cairo';
    src: url(${staticFile('fonts/Cairo-Regular.ttf')});
  }
  @font-face {
    font-family: 'Cairo Bold';
    src: url(${staticFile('fonts/Cairo-Bold.ttf')});
  }
  @font-face {
    font-family: 'Cairo Medium';
    src: url(${staticFile('fonts/Cairo-Medium.ttf')});
  }
  @font-face {
    font-family: 'Cairo Light';
    src: url(${staticFile('fonts/Cairo-Light.ttf')});
  }
  @font-face {
    font-family: 'Cairo SemiBold';
    src: url(${staticFile('fonts/Cairo-SemiBold.ttf')});
  }
`;
