import React from 'react';
import styled from 'styled-components';

const StyledH4 = styled.h4`
  font-weight: bold;
  margin: 10px 0;
  font-size: 18px;
  line-height: 24px;
  font-family: Gilroy;
`;
export default StyledH4;
