import styled, { css } from 'styled-components';

import { cdnFile } from '/lib/helpers';

const HeroItem = styled.div`
  margin-top: 20px;
  width: 100vw;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  box-shadow: inset 10px 0 50px 0 rgba(0, 0, 0, 0.15);
  background-image: url(${cdnFile('bg_exp_2.png')});
  ${({ theme, theme: { isRTL } }) =>
    theme.min('sm')`
      margin-top: 0;
      width: 50vw;
      position: fixed;
      right: ${isRTL ? 'auto' : 0};
      top: 0;
      bottom: 0;
      height: inherit;
      min-height: inherit;
      z-index: 1;
  `}

  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      right: auto;
      left: 0;
      transform: scale(-1, 1);
    `}

  &:hover .b-hero__demo-img:first-child {
    transform: scale(1.05);
  }
`;

export default HeroItem;
