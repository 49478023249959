import React from 'react';
import styled from 'styled-components';

const GmailIcon = () => (
  <SvgIcon width="28" height="20" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient x1="0%" y1="50.003%" x2="100%" y2="50.003%" id="a">
        <stop stopColor="#C8C8C8" offset="0%" />
        <stop stopColor="#CDCDCD" offset="100%" />
      </linearGradient>
      <linearGradient x1="0%" y1="49.998%" x2="100%" y2="49.998%" id="b">
        <stop stopColor="#D9D9D9" offset="0%" />
        <stop stopColor="#E2E2E2" offset="100%" />
      </linearGradient>
    </defs>
    <g fillRule="nonzero" fill="none">
      <path
        d="M26.25 20H1.75C.784 20 0 19.254 0 18.333V1.667C0 .747.784 0 1.75 0h24.5C27.216 0 28 .746 28 1.667v16.666c0 .92-.784 1.667-1.75 1.667z"
        fill="#EAEAEA"
      />
      <path
        d="M3.517 20H1.758C.788 20 0 19.254 0 18.333V1.667C0 1.07.335.52.88.223a1.843 1.843 0 011.758 0c.544.298.879.848.879 1.444V20z"
        fill="#D54C3F"
      />
      <path
        d="M24.483 20h1.759c.97 0 1.758-.746 1.758-1.667V1.667C28 .747 27.213 0 26.242 0c-.972 0-1.759.746-1.759 1.667V20z"
        fill="#B63524"
      />
      <path
        d="M27.252 3.043c.513-.342.795-.92.742-1.515A1.676 1.676 0 0026.99.158 1.82 1.82 0 0025.248.3L14 8.122h-.002L2.753.302C1.96-.229.869-.043.315.714c-.553.757-.36 1.799.433 2.328L.702 3.01 14 12.258M27.3 3.01L14 12.258"
        fill="#DE5145"
      />
      <path
        d="M26.242 0c-.373 0-.719.112-1.002.301L14.001 8.128 14 8.13h-.001L2.759.3a1.797 1.797 0 00-1-.301h24.483z"
        fill="#EFEFEF"
      />
      <path d="M3.516 5.657L.542 2.84v.002c.061.056.128.108.198.155l-.047-.033L3.517 4.93v.747" fill="#C64132" />
      <path fill="#E3E3E3" d="M12.039 13.643L3.517 20V5.548l8.52 8.093" />
      <path fill="url(#a)" d="M12.114 13.677l1.954-1.448L3.517 4.903v.653l8.596 8.12z" />
      <path fill="url(#b)" d="M18.686 20h5.797V4.903l-10.5 7.3-1.944 1.44z" />
    </g>
  </SvgIcon>
);

const SvgIcon = styled.svg`
  stroke: none;
`;

export default GmailIcon;
