export { default as Quality1Icon } from './Quality1Icon';
export { default as Quality2Icon } from './Quality2Icon';
export { default as Quality3Icon } from './Quality3Icon';
export { default as Quality4Icon } from './Quality4Icon';
export { default as Quality5Icon } from './Quality5Icon';
export { default as Quality6Icon } from './Quality6Icon';
export { default as Quality7Icon } from './Quality7Icon';
export { default as Quality8Icon } from './Quality8Icon';
export { default as Quality9Icon } from './Quality9Icon';
export { default as Quality10Icon } from './Quality10Icon';
export { default as Quality11Icon } from './Quality11Icon';
export { default as Quality12Icon } from './Quality12Icon';
