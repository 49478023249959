import styled from 'styled-components';

const FormattingIcon = ({ width = '20px', height = '20px', viewBox = '0 0 20 20' }) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
    <path d="M7 11a2 2 0 012 2v5a2 2 0 01-2 2H2a2 2 0 01-2-2v-5a2 2 0 012-2h5zm9 0v3h3v2h-3v3h-2v-3h-3v-2h3v-3h2zm-9 2H2v5h5v-5zM7 0a2 2 0 012 2v5a2 2 0 01-2 2H2a2 2 0 01-2-2V2a2 2 0 012-2h5zm11 0a2 2 0 012 2v5a2 2 0 01-2 2h-5a2 2 0 01-2-2V2a2 2 0 012-2h5zM7 2H2v5h5V2zm11 0h-5v5h5V2z" />
  </SvgIcon>
);

const SvgIcon = styled.svg`
  stroke: none;
  fill: currentColor;
`;

FormattingIcon.displayName = 'FormattingIcon';

export default FormattingIcon;
