import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import { AccountProvider } from '/imports/core/api/accounts/accountContext';
import { CURRENT_USER } from '/imports/core/api/apollo/client/queries';
import Error from '/components/Error';
import Loading from '/imports/core/ui/components/Loading';

//THIS HOC IS FULLY DEPENDENT WITH USERIDENTIFIED
const withAccountInfo = (ComposedComponent, Loader) =>
  class extends React.Component {
    static async getInitialProps(ctx) {
      const pageProps = ComposedComponent.getInitialProps && (await ComposedComponent.getInitialProps(ctx));
      return { ...pageProps };
    }

    static propTypes = {
      userIdentified: PropTypes.bool,
    };

    render() {
      const { userIdentified = false } = this.props;
      if (userIdentified) {
        return (
          <Query query={CURRENT_USER}>
            {({ data, loading, error, refetch }) => {
              if (loading) return Loader ? <Loader /> : <Loading />;
              if (error) return <Error />;
              const currentUser = data ? data.userInfo : null;
              const props = { ...this.props, currentUser, data };
              return (
                <AccountProvider
                  value={{
                    currentUser,
                    userLoading: loading,
                    refetch,
                  }}
                >
                  <ComposedComponent {...props} />
                </AccountProvider>
              );
            }}
          </Query>
        );
      }
      return (
        <AccountProvider
          value={{
            currentUser: null,
            userLoading: false,
            refetch: () => {},
          }}
        >
          <ComposedComponent {...this.props} />
        </AccountProvider>
      );
    }
  };

export default withAccountInfo;
