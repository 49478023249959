import React from 'react';
import styled from 'styled-components';

const LeftToRightIcon = ({ width = 16, height = 16 }) => {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width={width} height={height}>
      <g transform="matrix(1 0 0 -1 2 26)" stroke="#BFC5D7" fill="none" fillRule="evenodd">
        <rect strokeWidth="2.346" strokeLinecap="square" width="23.94" height="23.935" rx="1" stroke="currentColor" />
        <path
          d="M12.057.5v23.018"
          strokeWidth="1.632"
          strokeLinecap="round"
          strokeDasharray="2.04,2.04"
          stroke="currentColor"
        />
        <g strokeLinecap="round" strokeWidth="1.428" stroke="currentColor">
          <path d="M17.804 11.746H4.387M15.222 14.319l2.726-2.479a.16.16 0 00.052-.117.16.16 0 00-.052-.117L15.222 9" />
        </g>
      </g>
    </SvgIcon>
  );
};

LeftToRightIcon.displayName = 'LeftToRight';

const SvgIcon = styled.svg`
  stroke: none;
`;

export default LeftToRightIcon;
