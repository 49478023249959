import React, { PureComponent } from 'react';

import Autosave from '/imports/core/ui/components/Autosave';
import GeneratorInputWrap from '/imports/generator/ui/atoms/GeneratorInputWrap';

class GeneratorInputAutosave extends PureComponent {
  render() {
    return (
      <Autosave {...this.props}>{({ onChange }) => <GeneratorInputWrap {...this.props} onSave={onChange} />}</Autosave>
    );
  }
}

GeneratorInputAutosave.displayName = 'GeneratorInputAutosave';

export default GeneratorInputAutosave;
