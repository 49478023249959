import gql from 'graphql-tag';

import { Subscription } from '/imports/checkout/api/apollo/client/fragments';

export const MARK_AS_PAID_USER = gql`
  mutation MarkAsPaidUser {
    markAsPaidUser {
      success
      error
    }
  }
`;

export const MARK_AS_PAID_USER_V6 = gql`
  mutation MarkAsPaidUserV6 {
    markAsPaidUserV6 {
      success
      error
    }
  }
`;

export const THREE_DS_CHECK = gql`
  mutation ThreeDSCheck($planId: ID!, $card: Card!, $deviceInfo: Device, $redirectUrl: String) {
    threeDSCheck(planId: $planId, card: $card, deviceInfo: $deviceInfo, redirectUrl: $redirectUrl) {
      redirect
      gateway
      threeDSDisabled
      metadata {
        redirectUrl
        threeDSMethodData
        threeDSServerTransID
        PaReq
        threeDSVersion
        MD
      }
    }
  }
`;

export const THREE_DS_AUTH = gql`
  mutation ThreeDSAuth($planId: ID!, $transactionId: String!) {
    threeDSAuth(planId: $planId, transactionId: $transactionId) {
      redirect
      gateway
      metadata {
        redirectUrl
        CReq
        CRes
        ACSUrl
      }
    }
  }
`;

export const CREATE_SUBSCRIPTION = gql`
  mutation CreateSubscription($planId: ID!, $forSpecialPlan: Boolean, $createItemOnPodio: Boolean) {
    subscribe(planId: $planId, forSpecialPlan: $forSpecialPlan, createItemOnPodio: $createItemOnPodio) {
      ...Subscription
    }
  }
  ${Subscription}
`;

export const CREATE_SUBSCRIPTION_V2 = gql`
  mutation CreateSubscriptionV2($planId: ID!, $countryCode: String, $card: Card!) {
    createSubscriptionV2(planId: $planId, countryCode: $countryCode, card: $card) {
      ...Subscription
    }
  }
  ${Subscription}
`;

export const CREATE_STRIPE_SUBSCRIPTION_SCA = gql`
  mutation CreateStripeSubscriptionSCA($planId: String!) {
    createStripeSubscriptionSCA(planId: $planId) {
      ...Subscription
    }
  }
  ${Subscription}
`;

export const CREATE_STRIPE_PAYMENT_INTENT = gql`
  mutation CreateStripePaymentIntent($name: String!, $amount: Float!, $currency: String!) {
    createStripePaymentIntent(name: $name, amount: $amount, currency: $currency) {
      clientSecret
    }
  }
`;

export const CANCEL_SUBSCRIPTION = gql`
  mutation CancelSubscription($language: String) {
    unsubscribe(language: $language) {
      success
      error
    }
  }
`;

export const CREATE_MOLLIE_PAYMENT = gql`
  mutation CreateMolliePayment($planId: String!, $cardToken: String!, $redirectUrl: String!) {
    createMolliePayment(planId: $planId, cardToken: $cardToken, redirectUrl: $redirectUrl) {
      status
      checkoutLink
    }
  }
`;

export const CREATE_MOLLIE_SUBSCRIPTION = gql`
  mutation CreateMollieSubscription($planId: String!) {
    createMollieSubscription(planId: $planId) {
      success
      error
    }
  }
`;
