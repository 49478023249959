export const TEMPLATES = [
  {
    id: 'harvard',
    defaultColor: 'v1',
    colors: ['v1', 'v2', 'v3', 'v4', 'v5', 'v6', 'v7'],
    image: 'harvard',
    name: 'Harvard',
  },
  {
    id: 'stanford',
    defaultColor: 'v1',
    colors: ['v1', 'v2', 'v3', 'v4', 'v5', 'v6', 'v7', 'v8'],
    withNumber: true,
    image: 'stanford',
    name: 'Stanford',
  },
];

export const TEMPLATE_DROPDOWN = TEMPLATES.map((template) => ({ value: template.id, title: template.name }));
