import React from 'react';
import { useFormContext } from 'react-hook-form';

const withUseFormContext = (WrappedComponent) => {
  return (props) => {
    const methods = useFormContext();
    const goToError = () => {
      const errors = methods.formState.errors;
      if (errors) {
        const field = Object.keys(errors)?.[0];
        if (!field) return;
        const input = document.querySelector(`[name="${field}"]`);
        input?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
        input?.focus();
      }
    };
    return <WrappedComponent {...props} formMethods={{ ...methods, goToError }} />;
  };
};

export default withUseFormContext;
