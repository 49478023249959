import React from 'react';

const CreateNew = () => (
  <svg width="17px" height="15px" viewBox="0 0 17 15">
    <g id="Dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Dashboard-website" transform="translate(-503.000000, -543.000000)" fill="#000000">
        <g id="Group-2" transform="translate(183.000000, 307.000000)">
          <g id="Group" transform="translate(320.000000, 16.000000)">
            <g id="24-/-gadgets-/-monitor" transform="translate(0.000000, 219.000000)">
              <path
                d="M14.875,1.41666667 C15.6574034,1.41666667 16.2916667,2.05092994 16.2916667,2.83333333 L16.2916667,12.0416667 C16.2916667,12.8240701 15.6574034,13.4583333 14.875,13.4583333 L10.625,13.4583333 L10.625,14.1666667 L12.0416667,14.1666667 L12.0416667,15.5833333 L4.95833333,15.5833333 L4.95833333,14.1666667 L6.375,14.1666667 L6.375,13.4583333 L2.125,13.4583333 C1.3425966,13.4583333 0.708333333,12.8240701 0.708333333,12.0416667 L0.708333333,2.83333333 C0.708333333,2.05092994 1.3425966,1.41666667 2.125,1.41666667 L14.875,1.41666667 Z M14.875,2.83333333 L2.125,2.83333333 L2.125,12.0416667 L14.875,12.0416667 L14.875,2.83333333 Z"
                id="icon"
              />
              <polygon
                id="Path"
                points="7.79166667 7.08333333 7.79166667 4.95833333 9.20833333 4.95833333 9.20833333 7.08333333 11.3333333 7.08333333 11.3333333 8.5 9.20833333 8.5 9.20833333 10.625 7.79166667 10.625 7.79166667 8.5 5.66666667 8.5 5.66666667 7.08333333"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default CreateNew;
