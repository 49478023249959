import React from 'react';

const Quality8Icon = () => {
  return (
    <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M42.391 4.25H8.60897C6.20158 4.25 4.25 6.20158 4.25 8.60897V42.391C4.25 44.7984 6.20158 46.75 8.60897 46.75H42.391C44.7984 46.75 46.75 44.7984 46.75 42.391V8.60897C46.75 6.20158 44.7984 4.25 42.391 4.25Z"
        fill="#ADFADA"
      />
      <path
        d="M4.25 28.7692H46.75V42.391C46.75 44.7994 44.7994 46.75 42.391 46.75H8.60897C6.20064 46.75 4.25 44.7994 4.25 42.391V28.7692Z"
        fill="#83F8C7"
      />
      <path
        d="M16.9345 16.5532H12.2269C11.0232 16.5532 10.0474 17.529 10.0474 18.7327V36.1359C10.0474 37.3396 11.0232 38.3154 12.2269 38.3154H16.9345C18.1382 38.3154 19.114 37.3396 19.114 36.1359V18.7327C19.114 17.529 18.1382 16.5532 16.9345 16.5532Z"
        fill="#0866F5"
      />
      <path
        d="M10.0474 28.7692H19.114V36.1359C19.114 37.3346 18.1333 38.3154 16.9345 38.3154H12.2269C11.0281 38.3154 10.0474 37.3346 10.0474 36.1359V28.7692Z"
        fill="#0050C7"
      />
      <path
        d="M31.8856 38.3154H26.3824C24.7477 38.3154 23.1676 37.7269 21.9253 36.659L19.1138 34.2179V21.5442L23.767 14.0468C24.4535 12.9353 25.8048 12.4231 27.058 12.8045C28.5074 13.2404 29.3792 14.7224 29.0522 16.1936L28.0715 20.6506C27.9734 21.1083 28.3221 21.5442 28.7907 21.5442H37.9445C39.9279 21.5442 41.3663 23.4186 40.8542 25.3365L38.8054 33.0192C37.9663 36.1468 35.133 38.3263 31.8856 38.3263V38.3154Z"
        fill="#378EFF"
      />
      <path
        d="M19.1138 28.7692V34.2179L21.9253 36.659C23.1567 37.7269 24.7368 38.3154 26.3824 38.3154H31.8856C35.1221 38.3154 37.9663 36.1359 38.8054 33.0083L39.9388 28.7692H19.1138Z"
        fill="#0866F5"
      />
    </svg>
  );
};
export default Quality8Icon;
