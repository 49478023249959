import React from 'react';
import styled from 'styled-components';

const NextIcon = ({ color = '#fff' }) => {
  return (
    <SvgIcon width="10" height="16" xmlns="http://www.w3.org/2000/svg">
      <path fill="none" d="M-1-1h12v18H-1z" />
      <g>
        <path
          fill={color}
          d="M2.582 1.168a1 1 0 00-1.414 1.414l1.414-1.414zm5.293 6.707l.707.707a1 1 0 000-1.414l-.707.707zm-6.707 5.293a1 1 0 001.414 1.414l-1.414-1.414zm0-10.586l6 6 1.414-1.414-6-6-1.414 1.414zm6 4.586l-6 6 1.414 1.414 6-6-1.414-1.414z"
        />
      </g>
    </SvgIcon>
  );
};

const SvgIcon = styled.svg`
  stroke: none;
`;

export default NextIcon;
