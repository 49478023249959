import React from 'react';
import styled from 'styled-components';

const StyledH1 = styled.h1`
  margin-top: 0px;
  margin-bottom: 8px;
  color: #14141f;
  font-size: 56px;
  line-height: 64px;
  font-weight: 500;
  font-family: Gilroy;
  @media (max-width: 479px) {
    font-size: 24px;
    line-height: 32px;
  }
`;
export default StyledH1;
