import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-apollo';

import { AccountProvider } from '/imports/core/api/accounts/accountContext';
import { CURRENT_USER } from '/imports/core/api/apollo/client/queries';
import Error from '/components/Error';
import Loading from '/imports/core/ui/components/Loading';
import NotFound from '/components/NotFound';

const Custom404 = ({ userIdentified }) => {
  if (!userIdentified) return <NotFound userIdentified={userIdentified} />;

  const { loading, error, data } = useQuery(CURRENT_USER);
  if (loading) return <Loading />;
  if (error) return <Error />;

  return (
    <AccountProvider
      value={{
        currentUser: data ? data.userInfo : null,
        userLoading: loading,
      }}
    >
      <NotFound userIdentified={userIdentified} />
    </AccountProvider>
  );
};

export default Custom404;

Custom404.propTypes = {
  userIdentified: PropTypes.bool,
};
