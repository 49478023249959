import PropTypes from 'prop-types';
import styled from 'styled-components';

import { percentage } from 'lib/helpers';
import { LEVELS, SKILL_LEVEL_LIST } from 'imports/core/ui/atoms/SkillLevel';

import Flex from 'imports/core/ui/atoms/Flex';

export const ProgressBar = ({ progressColor, progressHeight, progressPercent, children }) => {
  const progressProps = {
    progressColor,
    progressHeight,
    progressPercent,
  };

  return (
    <Flex direction="column">
      <LabelCont>{children}</LabelCont>
      <ProgressCont>
        <Progress {...progressProps} />
        <ProgressBG {...progressProps} />
      </ProgressCont>
    </Flex>
  );
};

ProgressBar.propsType = {
  progressColor: PropTypes.string,
  progressHeight: PropTypes.number,
  progressPercent: PropTypes.number,
  children: PropTypes.node,
};

/**
 *
 * @param {number} skillLevel should be same as LEVELS.title
 */
export const ProgressBarBySkillLevel = ({ progressColor, progressHeight, skillLevel, children, props }) => {
  const level = LEVELS.find((l) => l.title == skillLevel)?.value ?? 0;
  const percent = percentage(LEVELS.length, level);

  return (
    <ProgressBar {...props} progressPercent={percent}>
      {children}
    </ProgressBar>
  );
};

ProgressBarBySkillLevel.propsType = {
  progressColor: PropTypes.string,
  progressHeight: PropTypes.number,
  skillLevel: PropTypes.oneOf(SKILL_LEVEL_LIST),
  children: PropTypes.node,
};

export const DEFAULT_BG_COLOR = '#C4C4C7';

export const ProgressCont = styled.div`
  position: relative;
  height: ${({ progressHeight }) => progressHeight ?? 4 + 'px'};
  width: 100%;
`;

export const ProgressStyle = (progressColor) => {
  return `
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        background-color: ${progressColor ?? DEFAULT_BG_COLOR};
    `;
};

export const ProgressBG = styled.div`
  ${({ progressColor }) => ProgressStyle(progressColor)}
  width: 100%;
  opacity: 0.3;
`;

export const Progress = styled.div`
  ${({ progressColor }) => ProgressStyle(progressColor)}
  width: ${({ progressPercent }) => (progressPercent ?? 10) + '%'};
`;

export const LabelCont = styled.div`
  color: #7171a6;
`;
