import React from 'react';
import { ThemeProvider } from 'styled-components';

import { isJobTrackEnable } from '/lib/helpers';
import Theme from '/imports/job-tracking/api/theme';
import dynamic from 'next/dynamic';

const Header = dynamic(() => import('/imports/dashboard/ui/components/Header'), { ssr: false });

const HeaderLayout = ({ requestLoad, children }) => {
  const invertTheme = (theme) => {
    if (isJobTrackEnable) {
      return {
        ...theme,
        isJobTrack: true,
        jobTrack: Theme,
      };
    } else {
      return (theme) => theme;
    }
  };

  return (
    <ThemeProvider theme={invertTheme}>
      <Header />
      {children}
    </ThemeProvider>
  );
};

export default HeaderLayout;
