import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { darken } from 'polished';

const Button = styled.button`
  display: block;
  cursor: pointer;
  width: 100%;
  line-height: 1;
  padding: 14px 0 13px;
  border-radius: 4px;
  background-color: #ff6600;
  font-family: ${({ theme }) => theme.font.family.correctText};
  font-size: 17px;
  text-align: center;
  color: white;
  letter-spacing: 2px;
  font-weight: 600;
  border: none;
  text-decoration: none;
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};

  &:hover {
    background: ${darken(0.1, '#ff6600')};
  }
`;

const CheckoutButton = ({ button, children, ...props }) => {
  if (button) {
    return <Button {...props}>{children}</Button>;
  }
  return <div {...props}>{children}</div>;
};

CheckoutButton.propTypes = {
  button: PropTypes.bool,
  children: PropTypes.node,
};

CheckoutButton.defaultProps = {
  button: false,
};

export default CheckoutButton;
