import styled, { css } from 'styled-components';

import View from '/imports/pdf/core/ui/atoms/View';

const BlockNestedItem = styled(View)`
  ${({ breakableView }) =>
    breakableView &&
    css`
      display: table;
      width: 100%;
      flex-direction: row;
    `}
`;

export default BlockNestedItem;
