import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import styled, { css } from 'styled-components';

import { PDF_LOADER } from '/imports/generator/api/constants';

const ListsSkeleton = () => (
  <SkeletonTheme color="#ffffff" highlightColor="#f0f0f2">
    <Wrapper>
      {[...Array(2)].map((e, i) => (
        <ResumeWrapper key={i}>
          <LeftSection>
            <IMG src={PDF_LOADER} />
          </LeftSection>
          <RightSection>
            <RightTitle>
              <Skeleton />
            </RightTitle>
            <ListContent>
              <Skeleton count={4} />
            </ListContent>
          </RightSection>
        </ResumeWrapper>
      ))}
    </Wrapper>
  </SkeletonTheme>
);

const ResumeWrapper = styled.div`
  display: flex;
  width: 50%;
  padding: 15px;
  @media only screen and (max-width: 767px) {
    width: 100%;
  }
`;

const LeftSection = styled.div`
  width: 55%;
  background-color: #f2f5fa;
`;

const Wrapper = styled.div`
  margin: -20px -15px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      .react-loading-skeleton {
        animation-direction: reverse;
      }
    `}
  @media only screen and (max-width: 767px) {
    justify-content: space-around;
  }
`;

const RightSection = styled.div`
  ${({ theme: { isRTL, max } }) => css`
    padding: ${isRTL ? '20px 35px 0 0' : '20px 0 0 35px'};
    ${max('md')`
      padding: ${isRTL ? '21px 10px 0 0' : '21px 0 0 10px'};
    `}
  `}
  display: flex;
  flex-direction: column;
  width: 45%;
`;

const RightTitle = styled.div`
  font-size: 35px;
`;

const ListContent = styled.div`
  line-height: 2;
  font-size: 18px;
`;

const IMG = styled.img`
  height: 100%;
  width: 100%;
`;

export default ListsSkeleton;
