import { useState, useEffect } from 'react';
import styled from 'styled-components';
import useTracking from 'imports/core/hooks/useTracking';
import {
  LanguageRender,
  languages,
  modalStyles,
  CloseWrapper,
  ContentWrapper,
  ChooseLanguageTitle,
  ChooseLanguage,
} from 'imports/core/ui/components/LocaleSelect';
import { sortBy } from 'lodash';
import { useResponsive } from 'imports/core/api/responsiveContext';

import { ClearCloseIcon } from '/imports/core/ui/assets';
import { getDeviceLanguage, getDefaultLanguage } from '/lib/helpers';
import Modal from '/imports/core/ui/atoms/Modal';
import useIntl from '/imports/core/api/useIntl';

export default function LocaleSelect({ afterSelect }) {
  const { host } = useResponsive();
  const { t, resumeLocale, setResumeLocale } = useIntl();
  const [open, setOpen] = useState(false);
  const { trackEvent } = useTracking();

  const toggleModal = (e) => {
    e.stopPropagation();
    setOpen(!open);
  };

  const handleSelect = (language) => () => {
    setOpen(!open);
    setResumeLocale(language);
    trackEvent('resume_language_selected', {
      resume_locale: language,
      resume_popup: true,
    });
    if (typeof afterSelect === 'function') {
      afterSelect(language);
    }
  };
  const documentStatus = typeof window !== 'undefined';
  const renderLanguage = () => {
    let data = languages.sort((a, b) => (a.label !== b.label ? (a.label < b.label ? -1 : 1) : 0));
    if (getDeviceLanguage() !== 'en') {
      data = sortBy(data, ({ language }) => (language === 'en' ? 0 : 1));
    }
    data = sortBy(data, ({ language }) => (language === getDeviceLanguage() ? 0 : 1));
    return (
      <ChooseLanguage>
        {data.map((languageDetail, index) => (
          <LanguageRender
            languageDetail={languageDetail}
            key={index}
            handleSelect={handleSelect}
            selected={resumeLocale}
          />
        ))}
      </ChooseLanguage>
    );
  };
  return (
    <>
      <LocaleButton onClick={toggleModal} type="button" isNewMobileDesign={true}>
        <span>{t('onboarding.select.language.other')}</span>
      </LocaleButton>
      {documentStatus && (
        <Modal
          onClose={toggleModal}
          styles={modalStyles}
          isLanguage
          openStateBase
          open={open}
          animation="empty"
          timeout={200}
        >
          <CloseWrapper onClick={toggleModal}>
            <ClearCloseIcon />
          </CloseWrapper>
          <ContentWrapper>
            <ChooseLanguageTitle>{t('header.choose_language')}</ChooseLanguageTitle>
            {renderLanguage()}
          </ContentWrapper>
        </Modal>
      )}
    </>
  );
}

const LocaleButton = styled.button.withConfig({
  shouldForwardProp: (prop) => !['isNewMobileDesign'].includes(prop),
})`
  color: #1688fe;
  text-decoration: underline;
  line-height: 22px;
  font-weight: 400;
  font-size: 15px;
  font-family: 'GILROY MEDIUM';
  background-color: transparent;
  text-underline-offset: 3px;
  border: none;
  cursor: pointer;
`;
