import styled, { css } from 'styled-components';

import Flex from '/imports/core/ui/atoms/Flex';
import OneLineCss from '/imports/job-tracking/ui/atoms/OneLineCss';

const CheckBox = (props) => {
  const { isStep } = props;
  const color = props.primary ? '#aebacc' : 'var(--primary-default-b400)';
  return (
    <Wrapper checked={props.checked} alignItems="center" justifyContent="center" color={color} isStep={isStep}>
      <Input {...props} type="checkbox" />
      {props.checked && (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="m5.525 10.682 4.95-4.95a1 1 0 1 1 1.414 1.414l-4.95 4.95-1.414-1.414z" fill="#fff" />
          <path d="M4.11 7.854a1 1 0 0 1 1.415 0l2.829 2.828-1.415 1.414-2.828-2.828a1 1 0 0 1 0-1.414z" fill="#fff" />
        </svg>
      )}
    </Wrapper>
  );
};

export const CheckBoxLabel = ({ label, checked, small = false, searchCheckLabel, isTasked }) => (
  <Label checked={checked} small={small} searchCheckLabel={searchCheckLabel} isTasked={isTasked}>
    {label}
  </Label>
);

export default CheckBox;

const Label = styled(Flex)`
  ${OneLineCss}
  font-weight: 500;
  ${({ theme }) => theme.jobTrack.renderFonts('SemiBold', '13', 'var(--dark-values-dark)', '1.54')};
  margin-left: 16px;
  ${({ small, theme }) =>
    small &&
    css`
      ${theme.jobTrack.renderFonts('Regular', '13', 'var(--dark-values-dark-regular)', '1.54')};
      margin-left: 12px;
    `}
  ${({ checked }) =>
    checked &&
    css`
      color: var(--grayscale-n400);
      text-decoration: line-through;
    `}
  ${({ searchCheckLabel }) =>
    searchCheckLabel &&
    css`
      margin-left: 8px;
      word-break: break-all;
      ${({ theme }) => theme.jobTrack.renderFonts('Medium', '15', '#000', '22px')};
    `}
  ${({ isTasked }) =>
    isTasked &&
    css`
      -webkit-line-clamp: 2;
    `}
`;

const Wrapper = styled(Flex)`
  width: 16px;
  height: 16px;
  margin: 4px 0;
  border-radius: 4px;
  border: solid 2px ${({ color }) => color};
  background-color: #fff;
  cursor: pointer;
  position: relative;
  flex-shrink: 0;
  ${({ checked }) =>
    checked &&
    css`
      background-color: var(--primary-default-b400);
      border: solid 2px var(--primary-default-b400);
    `}
  &:hover {
    border: solid 2px var(--primary-default-b400);
  }
  ${({ isStep }) =>
    isStep &&
    css`
      border-color: var(--grayscale-n200);
      position: absolute;
      right: 24px;
      ${({ checked }) =>
        checked &&
        css`
          border: none;
          background-color: #1688fe;
        `}
    `}
`;

const Input = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: not-allowed !important;
    `}
`;
