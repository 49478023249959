import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { withRouter } from 'next/router';

import Container from '/imports/core/ui/atoms/Container';
import Flex from '/imports/core/ui/atoms/Flex';
import { getRoute, isBlogDomain } from '/lib/helpers';
import Link from '/imports/core/ui/atoms/Link';
import { withIntl } from '/imports/core/api/useIntl';
import { withResponsiveContext } from '/imports/core/api/responsiveContext';

const links = [
  { href: '/contact-us', title: 'Contact us', slug: 'header.contact_us' },
  { href: '/privacy-policy', title: 'Privacy', slug: 'footer.privacy_policy' },
  { href: '/terms-and-conditions', title: 'Terms of service', slug: 'footer.terms_conditions' },
];

@withResponsiveContext
@withRouter
@withIntl
class OnboardingFooter extends PureComponent {
  static propTypes = {
    router: PropTypes.object,
  };

  state = {
    hide: true,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    return { hide: nextProps.router.pathname === '/onboard/start' };
  }

  render() {
    const { hide } = this.state;

    const { t, locale, host } = this.props;

    return (
      <FooterContainer hide={hide}>
        <FooterContent>
          <FooterLinks>
            {links.map(({ title, href, slug }, index) => (
              <FooterLink key={index} href={getRoute(href, locale, false, host, isBlogDomain(host))} target="_blank">
                {t(slug)}
              </FooterLink>
            ))}
          </FooterLinks>
        </FooterContent>
      </FooterContainer>
    );
  }
}

OnboardingFooter.displayName = 'OnboardingFooter';

const FooterContainer = styled(Container).withConfig({
  shouldForwardProp: (prop) =>
    // prevent these style props to render on the DOM
    !['hide'].includes(prop),
})`
  margin-top: auto;
  height: auto;
  min-height: 70px;
  padding: 24px;
  background-color: transparent;

  & {
    width: 100%;
  }

  ${(p) => p.theme.max('xs')`
    height: 240px;
  `}

  ${(p) =>
    p.hide &&
    p.theme.max('sm')`
    display: none;
  `}
`;

const FooterContent = styled.div`
  position: relative;
`;

const FooterLinks = styled((p) => <Flex alignItems="center" justifyContent="center" {...p} />)`
  ${({ theme }) => theme.max('xs')`
    flex-flow: column nowrap;
  `};

  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      flex-direction: row-reverse;
    `}
`;

const FooterLink = styled((p) => <Link {...p} />)`
  margin: 0 16px;
  text-decoration: none;
  line-height: 24px;
  font-family: Avenir;
  font-size: 10px;
  font-weight: 500;
  color: #1d1e1e;
  opacity: 0.5;
  ${({ theme }) => theme.max('md')`
    margin: 8px;
 `}
`;

export default OnboardingFooter;
