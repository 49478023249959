import React, { PureComponent } from 'react';
import last from 'lodash/last';
import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import PropTypes from 'prop-types';
import qs from 'qs';
import styled, { css } from 'styled-components';
import { withRouter } from 'next/router';
import nookies from 'nookies';

import Button from '/imports/core/ui/atoms/Button';
import {
  cookieParser,
  exportHTMLString,
  exportEmailHTML,
  getRoute,
  isBlogDomain,
  exportDocx,
  getDefaultLanguage,
} from '/lib/helpers';
import CreateNew from '/imports/dashboard/ui/assets/CreateNew';
import { DocxIcon, PdfIcon, MailIcon } from '/imports/generator/ui/assets';
import DocxModel from '/imports/generator/ui/components/DocxModel';
import EmailDownloadModal from '/imports/generator/ui/components/EmailDownloadModal';
import EmailNotifiedModal from '/imports/generator/ui/components/EmailNotifiedModal';
import Link from '/components/Link';
import SvgIcon from '/imports/core/ui/atoms/SvgIcon';
import { withAccount } from '/imports/core/api/accounts/accountContext';
import { withDomain } from '/imports/core/hooks/useDomain';
import { withIntl } from '/imports/core/api/useIntl';
import { withResponsiveContext } from '/imports/core/api/responsiveContext';
import { withTracking } from '/imports/core/hooks/useTracking';
import { RTLLanguages } from 'imports/generator/api/constants';
import {
  isBudapestNewDesignActive,
  leftcolumnSizeValue,
  getMiddleContentLayoutValue,
} from 'imports/generator/api/helpers';

// Map of Docx templates that are enabled for an environment.
const DocxTemplateEnabledMap = {
  budapest: true,
  riga: true,
  chicago: true,
  perth: true,
  shanghai: true,
  vladivostok: true,
  prague: true,
  sydney: true,
  montecarlo: true,
  kiev: true,
  sf: true,
  rotterdam: true,
  atsresume: true,
};

@withTracking
@withDomain
@withAccount
@withResponsiveContext
@withRouter
@withIntl
class DownloadOptionsDropdown extends PureComponent {
  static propTypes = {
    source: PropTypes.object,
    isHorizontal: PropTypes.bool,
    containerCustomCss: PropTypes.array,
    dropdownMenuCustomCss: PropTypes.array,
    small: PropTypes.bool,
    isCoverLetter: PropTypes.bool,
  };

  static defaultProps = {
    isCoverLetter: false,
  };

  state = {
    expanded: false,
    origin: null,
    documentHeight: null,
    model: false,
    emailModal: false,
    emailNotifiedModal: false,
    notification: null,
    variant: marvelEmitter.getActiveVariant('website_resume_experiment'),
    docxButtonLoading: false,
  };

  // Checks if the current document allows Docx export.
  get allowsDocxExport() {
    const { isCoverLetter, source: { settings = {} } = {} } = this.props;
    const { template, templateVersion } = settings;

    // TODO: refactor this logic as the Docx list grows
    const isEnabled = DocxTemplateEnabledMap[template];
    if (template == 'budapest' && (templateVersion == 2 || templateVersion == 3)) {
      return false;
    }
    if (!isCoverLetter && isEnabled) {
      return true;
    }
    return false;
  }

  get allowCreateWebsite() {
    const { isCoverLetter, source: { currentStep, slug } = {} } = this.props;
    const { variant } = this.state;
    return variant === 'website' && !isCoverLetter && currentStep === 'finish' && !slug;
  }

  componentDidMount() {
    this.setState({ origin: window.location.origin });
    window.addEventListener('click', this.handleWindowClick);
  }

  componentDidUpdate(prevProps, prevState) {
    const { expanded } = this.state;
    const { expanded: prevExpanded } = prevState;
    const { source } = this.props;
    const { source: prevSource } = prevProps;

    if (expanded && prevExpanded !== expanded) {
      const templateNode = document.getElementById('template-page');

      // For templates with coloured background left/right column, templateNode will return a node
      // Templates - [Budapest, SF, Amsterdam, Kiev]
      if (!templateNode) return;

      const { offsetHeight } = templateNode;
      return this.setState({
        documentHeight: offsetHeight,
      });
    }

    if (source && prevSource && source.settings.template !== prevSource.settings.template)
      this.setState({
        documentHeight: null,
      });
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleWindowClick);
  }

  getDocxExportLink() {
    const { source = {}, docxEndpoint, currentUser } = this.props;
    const getGender = currentUser?.gender === 'female' ? 'female' : false;
    return `${docxEndpoint}/resumes/${source.id}/${getGender}`;
  }

  handleWindowClick = () => {
    this.setState({ expanded: false });
  };

  onClick = (resumeFormat) => () => {
    const {
      router: { asPath },
      source: {
        settings: { template, language, color, headingFont, contentFont, templateVersion },
      },
      isCoverLetter,
      trackEvent,
    } = this.props;

    this.setState({
      expanded: false,
    });

    if (!isCoverLetter) {
      return trackEvent('download_cta', {
        context: last(asPath.split('/')),
        template: `${template}${templateVersion > 1 ? `-v${templateVersion}` : ''}`,
        cv_format: resumeFormat,
        resume_language: language,
        color,
        resume_headingFont: headingFont,
        resume_contentFont: contentFont,
      });
    }
    return trackEvent('cover_letter_download_cta', {
      template,
      format: resumeFormat,
    });
  };

  toggleExpanded = (e) => {
    e.stopPropagation();
    this.setState((state) => ({ expanded: !state.expanded }));
  };

  toggleModal = (e) => {
    this.setState((state) => ({ model: !state.model }));
  };

  toggleEmailModal = (e) => {
    e.stopPropagation();
    this.setState((state) => ({ emailModal: !state.emailModal, expanded: false }));
  };

  toggleEmailNotifiedModal = (e) => {
    e.stopPropagation();
    if (this.state.notification !== 'loading')
      this.setState((state) => ({ emailNotifiedModal: !state.emailNotifiedModal }));
  };

  onClickDownload = async () => {
    if (this.state.docxButtonLoading) return;
    this.setState({ docxButtonLoading: true });
    const { token, agent_client_id } = cookieParser();
    const blob = await exportDocx({ token, link: this.getDocxExportLink(), userId: agent_client_id });
    const newBlob = new Blob([blob]);
    const downloadURL = window.URL.createObjectURL(newBlob);
    const link = document.createElement('a');
    link.href = downloadURL;
    link.download = 'resume.docx';
    link.click();
    this.setState((state) => ({ model: !state.model }));
    this.onClick('docx')();
    this.setState({ docxButtonLoading: false });
  };

  sendEmail = async (email, onePage, isRTL) => {
    const {
      source,
      currentUser: { gender },
      host,
    } = this.props;
    const { documentHeight } = this.state;
    this.setState({ emailModal: false, emailNotifiedModal: true, notification: 'loading' });
    const { token } = cookieParser();
    const exportObj = {
      source,
      token,
      onePage,
      redneg: gender,
      host,
      fromBuilder: true,
      isRTL,
    };
    if (documentHeight) exportObj.height = documentHeight.toString();

    const html = await exportHTMLString(exportObj);
    const options = {
      onePage,
    };

    const { template, color } = source.settings;

    const response = await exportEmailHTML({
      html,
      token,
      options,
      email,
      template,
      color,
      gender,
      isCoverLetter: this.props.isCoverLetter === 'cover-letter',
      isRTL,
    });
    if (response.status === 202) {
      this.onClick('sent_by_mail')();
    }
    this.setState({ notification: response.status === 202 ? 'success' : 'failure' });
  };

  render() {
    const { expanded, origin, documentHeight, model, emailModal, emailNotifiedModal, notification, docxButtonLoading } =
      this.state;
    const { agent_client_id } = nookies.get({});
    const {
      source: { id, settings: { language } = {} } = {},
      source,
      isHorizontal,
      containerCustomCss,
      dropdownMenuCustomCss,
      small,
      isCoverLetter,
      locale,
      t,
      isMobile,
      router: { pathname },
      currentUser,
      host,
    } = this.props;

    const {
      source: {
        settings: { template },
      },
    } = this.props;

    const queryString = {
      type: 'pdf',
      isBudapestDesignActive: isBudapestNewDesignActive(currentUser),
      leftcolumnSize: leftcolumnSizeValue(source, currentUser, agent_client_id),
      middleContentLayout: getMiddleContentLayoutValue(source, currentUser, agent_client_id),
      userId: currentUser && currentUser?.id,
    };

    if (documentHeight) {
      queryString.documentHeight = documentHeight;
    }
    queryString.locale = locale || getDefaultLanguage(host);
    queryString.redneg = currentUser?.gender || 'male';
    const path = isCoverLetter ? 'cover-letter' : 'resume';
    const url = `${origin}${getRoute(`/export/${path}/${id}?`, locale, false, host, isBlogDomain(host))}`;
    const isRTL = RTLLanguages.includes(language);
    const pdfHref = `${url}${qs.stringify(queryString)}${isCoverLetter ? (isRTL && template !== 'budapest' ? '&isSwapable=true' : '') : isRTL ? '&isSwapable=true' : ''}`;
    queryString.onePage = true;
    const onePagePdfHref = `${url}${qs.stringify(queryString)}${isCoverLetter ? (isRTL && template !== 'budapest' ? '&isSwapable=true' : '') : isRTL ? '&isSwapable=true' : ''}`;
    const ButtonComponent = isHorizontal ? MoreButtonHorizontal : MoreButton;
    const qsWebsite = {
      source: id,
    };

    return (
      <Container containerCustomCss={containerCustomCss} data-testid="download-option">
        <ButtonComponent onClick={this.toggleExpanded}>
          <SvgIcon.More width="19px" height="6px" viewBox="0 0 16.8 4" />
          {isHorizontal && <TitleWrapper>{t('more')}</TitleWrapper>}
        </ButtonComponent>

        <DropdownMenu
          expanded={expanded}
          onClick={(e) => e.stopPropagation()}
          dropdownMenuCustomCss={dropdownMenuCustomCss}
        >
          <DropdownMenuInner>
            {this.allowsDocxExport && (
              <StyledLink onClick={this.toggleModal}>
                <SvgContainer>
                  <DocxIcon />
                </SvgContainer>
                <StyledSpan>{t('header.download_docx')}</StyledSpan>
              </StyledLink>
            )}
            <StyledLink href={onePagePdfHref} target="_blank" onClick={this.onClick('pdf')} passHref>
              <SvgContainer>
                <PdfIcon viewBox={small ? '0 0 15 25' : '0 0 18 23'} />
              </SvgContainer>
              {t('dashboard.export_1_page_pdf')}
            </StyledLink>
            <StyledLink href={pdfHref} target="_blank" onClick={this.onClick('pdf/A4')} passHref>
              <SvgContainer>
                <PdfIcon viewBox={small ? '0 0 15 25' : '0 0 18 23'} />
              </SvgContainer>
              {t('dashboard.export_a4_pdf')}
            </StyledLink>
            {isMobile && pathname !== '/dashboard' && (
              <StyledLink onClick={this.toggleEmailModal}>
                <SvgContainer>
                  <MailIcon />
                </SvgContainer>
                {t('header.export_email')}
              </StyledLink>
            )}
          </DropdownMenuInner>
        </DropdownMenu>
        <DocxModel
          toggleModal={this.toggleModal}
          onClickDownload={this.onClickDownload}
          open={model}
          link={this.getDocxExportLink()}
          loading={docxButtonLoading}
        />
        <EmailDownloadModal
          toggleModal={this.toggleEmailModal}
          sendEmail={this.sendEmail}
          email={currentUser ? currentUser.email : ''}
          open={emailModal}
          isRTL={isCoverLetter ? (isRTL && template !== 'budapest' ? true : false) : isRTL ? true : false}
        />
        <EmailNotifiedModal
          toggleModal={this.toggleEmailNotifiedModal}
          open={emailNotifiedModal}
          notification={notification}
        />
      </Container>
    );
  }
}

const StyledSpan = styled.span`
  display: inline-block;
  flex-grow: 1;
`;

const Container = styled.div`
  margin-left: 10px;
  z-index: 10;
  position: relative;
  ${(props) => props.containerCustomCss};
`;

const MoreButton = styled(Button)`
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0.6em;
  background-color: ${(p) => p.theme.colors.ctaPreview};
  transition:
    background-color 0.2s ease-in-out,
    border-color 0.2s ease-in-out;

  &:hover {
    background-color: ${(p) => p.theme.colors.ctaPreviewHover};
    border-color: ${(p) => p.theme.colors.ctaPreviewHover};
  }

  &:active {
    background-color: ${(p) => p.theme.colors.ctaPreviewClick};
    border-color: ${(p) => p.theme.colors.ctaPreviewClick};
  }
`;

const MoreButtonHorizontal = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  height: 100%;
  cursor: pointer;

  &:hover {
    color: #0087c1;
  }
`;

const TitleWrapper = styled.span`
  margin-left: 11px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      margin-right: 11px;
      margin-left: 0;
    `}
`;

const DropdownMenu = styled.div`
  position: absolute;
  right: 0;
  top: 58px;
  width: 220px;
  transform-origin: top;
  transform: scaleY(0);
  opacity: 0;
  padding: 0 !important;
  background-color: transparent;

  ${(p) =>
    p.expanded &&
    css`
      transform: scaleY(1);
      opacity: 1;
    `}

  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
      right: unset;
      left: 0;
    `}
  ${(props) => props.dropdownMenuCustomCss}
`;

const DropdownMenuInner = styled.div`
  border-radius: 5px;
  box-shadow: 0 37px 37px 0 rgba(21, 26, 47, 0.18);
  background-color: ${({ theme }) => theme.colors.white};
`;

const StyledLink = styled.a`
  display: flex;
  width: 100%;
  margin: 0;
  padding: 15px 20px;
  border-radius: 0;
  font-family: TTCommons;
  color: ${({ theme }) => theme.colors.black};
  font-size: 16px;
  text-align: left;
  transition: background-color 0.2s;
  text-decoration: none;
  cursor: pointer;
  align-items: center;
  &:hover {
    background-color: rgba(241, 244, 249, 1);
    color: ${({ theme }) => theme.colors.black};
  }
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      text-align: right;
    `}
`;

const SvgContainer = styled.span`
  padding-right: 15px;
  line-height: 0;
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      padding-right: 0;
      padding-left: 15px;
    `}
`;

export default DownloadOptionsDropdown;
