import React from 'react';
import styled from 'styled-components';

const PreviewIcon = () => {
  return (
    <SvgIcon width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.5 10.371V19.5a2.25 2.25 0 01-2.25 2.25H6.75A2.25 2.25 0 014.5 19.5v-15a2.25 2.25 0 012.25-2.25h4.629a1.5 1.5 0 011.06.44l6.622 6.62a1.5 1.5 0 01.439 1.061z"
        stroke="#fff"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M12 2.625V8.25a1.5 1.5 0 001.5 1.5h5.625"
        stroke="#fff"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

const SvgIcon = styled.svg`
  stroke: none;
`;

export default PreviewIcon;
