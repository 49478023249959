import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TextareaEditor from './TextareaEditor';

import Autosave from '/imports/core/ui/components/Autosave';
import { withIntl } from '/imports/core/api/useIntl';

@withIntl
class TextareaEditorAutosave extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    placeholder: PropTypes.string,
    name: PropTypes.string,
    rows: PropTypes.number,
    cols: PropTypes.number,
    value: PropTypes.string,
    help: PropTypes.array,
    t: PropTypes.func,
    placeholderSlug: PropTypes.string,
  };

  render() {
    const { className, placeholder = '', name, rows, cols, value, help, t, placeholderSlug } = this.props;
    return (
      <Autosave {...this.props}>
        {({ onChange }) => (
          <TextareaEditor
            {...this.props}
            className={className}
            onChange={onChange}
            rows={rows}
            cols={cols}
            name={name}
            placeholder={placeholderSlug && t(placeholderSlug) ? t(placeholderSlug) : placeholder}
            defaultvalue={value || ''}
            help={help}
          />
        )}
      </Autosave>
    );
  }
}

export default TextareaEditorAutosave;
