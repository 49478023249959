import React from 'react';
import styled from 'styled-components';

const TrashIcon = () => {
  return (
    <SvgIcon width="85" height="80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="21.14" y="5.733" width="40.533" height="40.533" rx="12.6" stroke="#E41628" strokeWidth="2.8" />
      <path
        d="M63.073 26.667H11.406c-15 1.666-6.666 13.333 0 13.333v25c0 6.667 7.778 10.556 11.667 11.667h35c14.667 0 15-16.667 13.333-25V40c13.334-4 7.778-10.555 3.334-13.333H63.073z"
        fill="#fff"
      />
      <path
        d="M73.037 26.38H11.016c-12.02 0-9 13.657-.203 13.864.11.003.203.09.203.2v20.351c0 9.757 7.057 15.872 15.14 15.872h29.952c12.371 0 15.464-10.581 15.464-15.872V40.41c0-.094.072-.177.165-.197 13.33-2.862 7.299-13.832 1.3-13.832z"
        stroke="#E41628"
        strokeWidth="2.8"
      />
      <path
        d="M35.906 1.4h11a3.1 3.1 0 013.1 3.1v2.667a3.1 3.1 0 01-3.1 3.1h-11a3.1 3.1 0 01-3.1-3.1V4.5a3.1 3.1 0 013.1-3.1z"
        fill="#EED6AB"
        stroke="#E41628"
        strokeWidth="2.8"
      />
      <rect x="23.406" y="36.667" width="2.667" height="30.333" rx="1.333" fill="#E41628" />
      <rect x="56.74" y="36.667" width="2.667" height="30.333" rx="1.333" fill="#E41628" />
      <rect x="40.073" y="36.667" width="2.667" height="30.333" rx="1.333" fill="#E41628" />
      <circle cx="66.406" cy="65" r="13.6" fill="#FBC7D0" stroke="#E41628" strokeWidth="2.8" />
      <rect
        x="59.073"
        y="60.645"
        width="2.667"
        height="16.667"
        rx="1.333"
        transform="rotate(-48.5 59.073 60.645)"
        fill="#E41628"
      />
      <rect
        x="71.555"
        y="58.648"
        width="2.667"
        height="16.667"
        rx="1.333"
        transform="rotate(48.5 71.555 58.648)"
        fill="#E41628"
      />
    </SvgIcon>
  );
};

const SvgIcon = styled.svg`
  stroke: none;
`;

export default TrashIcon;
