import React from 'react';
import styled, { css } from 'styled-components';

const PlusIcon = () => {
  return (
    <IconCont>
      <SvgIcon viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <g fillRule="nonzero">
          <path
            xmlns="http://www.w3.org/2000/svg"
            fill="#1688fe"
            fillRule="evenodd"
            d="M8 0a8 8 0 110 16A8 8 0 018 0zm0 2a6 6 0 100 12A6 6 0 008 2zm1 2v3h3v2H9v3H7V9H4V7h3V4h2z"
          />
        </g>
      </SvgIcon>
    </IconCont>
  );
};

const IconCont = styled.span`
  margin-right: 5px;
  width: 16px;
  height: 16px;
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      margin-right: 0;
      margin-left: 5px;
    `}
`;

const SvgIcon = styled.svg`
  stroke: none;
  fill: currentColor;
`;

export default PlusIcon;
