import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

class Collapse extends PureComponent {
  static propTypes = {
    collapsed: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  };

  render() {
    const { collapsed } = this.props;

    return <Collapsible {...this.props}>{!collapsed && <div>{this.props.children}</div>}</Collapsible>;
  }
}

Collapse.displayName = 'Collapse';

const Collapsible = styled.div`
  position: relative;
  z-index: 100;
`;

export default Collapse;
