const AICTAIcon = () => {
  return (
    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.97279 6.76514L2.02344 12.7126" stroke="#1688FE" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M11.6029 1.99223C12.3912 1.65144 13.1889 2.41287 12.8444 3.19937C12.6503 3.6423 12.4154 4.0778 12.0922 4.60244C12.5426 5.04351 12.8926 5.4103 13.2186 5.79008C13.7804 6.44473 13.2854 7.43087 12.4284 7.32594C12.0504 7.27951 11.6623 7.20894 11.211 7.10587C11.1307 7.08768 11.0466 7.09669 10.972 7.13148C10.8974 7.16627 10.8364 7.22488 10.7987 7.29808C10.5136 7.85244 10.2601 8.29816 9.97878 8.72994C9.51542 9.44401 8.44107 9.2713 8.28042 8.43558C8.17921 7.91001 8.13092 7.36773 8.11328 6.67316C7.53382 6.65656 6.95645 6.59603 6.38614 6.49208C5.54392 6.33051 5.35357 5.24687 6.07321 4.78073C6.49107 4.50958 6.93121 4.26166 7.48278 3.9868C7.55724 3.94938 7.61695 3.88802 7.65232 3.81257C7.68768 3.73711 7.69665 3.65196 7.67778 3.5708C7.57868 3.15437 7.50273 2.73277 7.45028 2.30794C7.35092 1.46016 8.31942 0.967084 8.96664 1.52144C9.35478 1.85294 9.729 2.21787 10.184 2.6933C10.6457 2.43718 11.1192 2.20385 11.6029 1.99223Z"
        fill="#E7F3FE"
      />
      <path
        d="M11.6029 1.99223C12.3912 1.65144 13.1889 2.41287 12.8444 3.19937C12.6503 3.6423 12.4154 4.0778 12.0922 4.60244C12.5426 5.04351 12.8926 5.4103 13.2186 5.79008C13.7804 6.44473 13.2854 7.43087 12.4284 7.32594C12.0504 7.27951 11.6623 7.20894 11.211 7.10587C11.1307 7.08768 11.0466 7.09669 10.972 7.13148C10.8974 7.16627 10.8364 7.22488 10.7987 7.29808C10.5136 7.85244 10.2601 8.29816 9.97878 8.72994C9.51542 9.44401 8.44107 9.2713 8.28042 8.43558C8.17921 7.91001 8.13092 7.36773 8.11328 6.67316C7.53382 6.65656 6.95645 6.59603 6.38614 6.49208C5.54392 6.33051 5.35357 5.24687 6.07321 4.78073C6.49107 4.50958 6.93121 4.26166 7.48278 3.9868C7.55724 3.94938 7.61695 3.88802 7.65232 3.81257C7.68768 3.73711 7.69665 3.65196 7.67778 3.5708C7.57868 3.15437 7.50273 2.73277 7.45028 2.30794C7.35092 1.46016 8.31942 0.967084 8.96664 1.52144C9.35478 1.85294 9.729 2.21787 10.184 2.6933C10.6457 2.43718 11.1192 2.20385 11.6029 1.99223Z"
        stroke="#1688FE"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AICTAIcon;
