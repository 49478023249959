import React from 'react';

const Logo = ({ light = false }) => {
  return (
    <svg width="61" height="50" viewBox="0 0 61 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M45.359 0H0V3.1395H45.359V15.7175H57.8718V43.7824H46.9657V46.9219H61V15.7175L45.359 0Z" fill="#37B982" />
      <path
        d="M6.96168 6.82501V8.32651H4.27256V16.38H2.70277V8.32651H0V6.82501H6.96168Z"
        fill={light ? '#fff' : '#3A3A43'}
      />
      <path
        d="M11.617 9.37757C12.3996 9.37757 13.0321 9.62782 13.5144 10.1283C13.9967 10.6288 14.2378 11.3159 14.2378 12.1895V16.38H12.7636V12.3396C12.7636 11.83 12.6271 11.4387 12.3541 11.1657C12.0811 10.8927 11.708 10.7562 11.2348 10.7562C10.7161 10.7562 10.2974 10.92 9.97894 11.2476C9.66043 11.5661 9.50118 12.0575 9.50118 12.7218V16.38H8.02694V6.82501H9.50118V10.4286C9.94709 9.72792 10.6524 9.37757 11.617 9.37757Z"
        fill={light ? '#fff' : '#3A3A43'}
      />
      <path
        d="M16.9855 13.5954C17.0947 14.1141 17.345 14.5145 17.7363 14.7966C18.1276 15.0696 18.6054 15.2061 19.1696 15.2061C19.9522 15.2061 20.5392 14.924 20.9305 14.3598L22.1453 15.0696C21.4719 16.0615 20.4755 16.5575 19.1559 16.5575C18.0457 16.5575 17.1493 16.2208 16.4668 15.5474C15.7843 14.8649 15.443 14.0049 15.443 12.9675C15.443 11.9483 15.7797 11.0975 16.4532 10.415C17.1266 9.72337 17.9911 9.37757 19.0467 9.37757C20.0477 9.37757 20.8668 9.72792 21.5038 10.4286C22.1499 11.1293 22.473 11.9802 22.473 12.9812C22.473 13.1359 22.4548 13.3406 22.4184 13.5954H16.9855ZM16.9719 12.3942H20.9851C20.885 11.8391 20.6529 11.4205 20.2889 11.1384C19.934 10.8563 19.5154 10.7153 19.0331 10.7153C18.4871 10.7153 18.032 10.8654 17.668 11.1657C17.304 11.466 17.072 11.8755 16.9719 12.3942Z"
        fill={light ? '#fff' : '#3A3A43'}
      />
      <path
        d="M6.19726 31.7363L4.17701 28.2555H2.32056V31.7363H0.750769V22.1813H4.57287C5.42829 22.1813 6.15176 22.4816 6.74327 23.0822C7.34389 23.6737 7.6442 24.3971 7.6442 25.2525C7.6442 25.8622 7.46674 26.4219 7.11183 26.9315C6.76602 27.432 6.31101 27.796 5.7468 28.0235L7.9172 31.7363H6.19726ZM2.32056 23.6555V26.8496H4.57287C4.99148 26.8496 5.34639 26.6949 5.6376 26.3855C5.9288 26.0761 6.07441 25.6984 6.07441 25.2525C6.07441 24.8066 5.9288 24.429 5.6376 24.1196C5.34639 23.8102 4.99148 23.6555 4.57287 23.6555H2.32056Z"
        fill={light ? '#fff' : '#3A3A43'}
      />
      <path
        d="M9.82708 28.9517C9.93628 29.4704 10.1865 29.8708 10.5778 30.1529C10.9692 30.4259 11.4469 30.5624 12.0111 30.5624C12.7938 30.5624 13.3807 30.2803 13.772 29.7161L14.9869 30.4259C14.3135 31.4178 13.317 31.9137 11.9975 31.9137C10.8873 31.9137 9.99088 31.577 9.30837 30.9036C8.62585 30.2211 8.28459 29.3612 8.28459 28.3238C8.28459 27.3046 8.6213 26.4537 9.29472 25.7712C9.96813 25.0796 10.8327 24.7338 11.8883 24.7338C12.8893 24.7338 13.7083 25.0842 14.3453 25.7849C14.9915 26.4856 15.3145 27.3364 15.3145 28.3374C15.3145 28.4921 15.2963 28.6969 15.2599 28.9517H9.82708ZM9.81343 27.7505H13.8266C13.7265 27.1954 13.4945 26.7768 13.1305 26.4947C12.7756 26.2126 12.3569 26.0715 11.8746 26.0715C11.3286 26.0715 10.8736 26.2217 10.5096 26.522C10.1456 26.8223 9.91353 27.2318 9.81343 27.7505Z"
        fill={light ? '#fff' : '#3A3A43'}
      />
      <path
        d="M17.6692 26.795C17.6692 27.0225 17.7921 27.2045 18.0378 27.341C18.2926 27.4684 18.5974 27.5821 18.9523 27.6822C19.3163 27.7732 19.6804 27.8824 20.0444 28.0098C20.4084 28.1372 20.7132 28.3556 20.9589 28.665C21.2137 28.9653 21.3412 29.3475 21.3412 29.8116C21.3412 30.4668 21.0863 30.981 20.5767 31.3541C20.0762 31.7272 19.4438 31.9137 18.6793 31.9137C18.0059 31.9137 17.428 31.7727 16.9457 31.4906C16.4634 31.2085 16.1131 30.8172 15.8947 30.3167L17.1641 29.5796C17.4007 30.2348 17.9058 30.5624 18.6793 30.5624C19.4529 30.5624 19.8396 30.3076 19.8396 29.798C19.8396 29.5796 19.7122 29.4021 19.4574 29.2656C19.2117 29.1291 18.9068 29.0154 18.5428 28.9244C18.1879 28.8243 17.8285 28.7105 17.4645 28.5831C17.1004 28.4557 16.791 28.2464 16.5362 27.9552C16.2905 27.6549 16.1677 27.2773 16.1677 26.8223C16.1677 26.1944 16.4043 25.6893 16.8775 25.3071C17.3598 24.9249 17.9559 24.7338 18.6657 24.7338C19.2299 24.7338 19.7304 24.8612 20.1672 25.116C20.6131 25.3617 20.9544 25.7075 21.191 26.1534L19.9488 26.8496C19.7122 26.3309 19.2845 26.0715 18.6657 26.0715C18.3836 26.0715 18.147 26.1352 17.9559 26.2626C17.7648 26.3809 17.6692 26.5584 17.6692 26.795Z"
        fill={light ? '#fff' : '#3A3A43'}
      />
      <path
        d="M27.1573 24.9113H28.6315V31.7363H27.1573V30.8627C26.7114 31.5634 26.0061 31.9137 25.0415 31.9137C24.2588 31.9137 23.6264 31.6635 23.1441 31.163C22.6617 30.6625 22.4206 29.9754 22.4206 29.1018V24.9113H23.8948V28.9517C23.8948 29.4613 24.0313 29.8526 24.3043 30.1256C24.5773 30.3986 24.9505 30.5351 25.4237 30.5351C25.9424 30.5351 26.361 30.3758 26.6795 30.0573C26.998 29.7297 27.1573 29.2338 27.1573 28.5695V24.9113Z"
        fill={light ? '#fff' : '#3A3A43'}
      />
      <path
        d="M37.8085 24.7338C38.582 24.7338 39.2054 24.9841 39.6786 25.4846C40.1518 25.9851 40.3884 26.6585 40.3884 27.5048V31.7363H38.9142V27.6003C38.9142 27.1271 38.7959 26.7631 38.5593 26.5083C38.3226 26.2444 37.9996 26.1125 37.5901 26.1125C37.1351 26.1125 36.7711 26.2626 36.4981 26.5629C36.2341 26.8632 36.1022 27.3137 36.1022 27.9143V31.7363H34.628V27.6003C34.628 27.1271 34.5142 26.7631 34.2867 26.5083C34.0683 26.2444 33.7543 26.1125 33.3448 26.1125C32.8989 26.1125 32.5349 26.2672 32.2528 26.5766C31.9707 26.8769 31.8296 27.3228 31.8296 27.9143V31.7363H30.3554V24.9113H31.8296V25.7303C32.2664 25.066 32.9171 24.7338 33.7816 24.7338C34.6553 24.7338 35.3014 25.0933 35.72 25.8122C36.175 25.0933 36.8712 24.7338 37.8085 24.7338Z"
        fill={light ? '#fff' : '#3A3A43'}
      />
      <path
        d="M43.1265 28.9517C43.2357 29.4704 43.4859 29.8708 43.8772 30.1529C44.2685 30.4259 44.7463 30.5624 45.3105 30.5624C46.0931 30.5624 46.6801 30.2803 47.0714 29.7161L48.2863 30.4259C47.6129 31.4178 46.6164 31.9137 45.2969 31.9137C44.1866 31.9137 43.2903 31.577 42.6078 30.9036C41.9252 30.2211 41.584 29.3612 41.584 28.3238C41.584 27.3046 41.9207 26.4537 42.5941 25.7712C43.2675 25.0796 44.132 24.7338 45.1877 24.7338C46.1887 24.7338 47.0077 25.0842 47.6447 25.7849C48.2909 26.4856 48.6139 27.3364 48.6139 28.3374C48.6139 28.4921 48.5957 28.6969 48.5593 28.9517H43.1265ZM43.1128 27.7505H47.126C47.0259 27.1954 46.7939 26.7768 46.4299 26.4947C46.0749 26.2126 45.6563 26.0715 45.174 26.0715C44.628 26.0715 44.173 26.2217 43.809 26.522C43.445 26.8223 43.2129 27.2318 43.1128 27.7505Z"
        fill={light ? '#fff' : '#3A3A43'}
      />
      <path
        d="M6.19726 47.0926L4.17701 43.6118H2.32056V47.0926H0.750769V37.5375H4.57287C5.42829 37.5375 6.15176 37.8378 6.74327 38.4384C7.34389 39.0299 7.6442 39.7534 7.6442 40.6088C7.6442 41.2185 7.46674 41.7781 7.11183 42.2877C6.76602 42.7882 6.31101 43.1522 5.7468 43.3797L7.9172 47.0926H6.19726ZM2.32056 39.0117V42.2058H4.57287C4.99148 42.2058 5.34639 42.0511 5.6376 41.7417C5.9288 41.4323 6.07441 41.0547 6.07441 40.6088C6.07441 40.1629 5.9288 39.7852 5.6376 39.4758C5.34639 39.1664 4.99148 39.0117 4.57287 39.0117H2.32056Z"
        fill={light ? '#fff' : '#3A3A43'}
      />
      <path
        d="M14.0723 40.2675H15.5466V47.0926H14.0723V46.1097C13.5172 46.8833 12.721 47.27 11.6835 47.27C10.7462 47.27 9.94538 46.9242 9.28107 46.2326C8.61675 45.5319 8.28459 44.681 8.28459 43.68C8.28459 42.6699 8.61675 41.8191 9.28107 41.1275C9.94538 40.4359 10.7462 40.0901 11.6835 40.0901C12.721 40.0901 13.5172 40.4723 14.0723 41.2367V40.2675ZM10.3731 45.2498C10.7826 45.6593 11.2968 45.8641 11.9156 45.8641C12.5344 45.8641 13.0486 45.6593 13.4581 45.2498C13.8676 44.8312 14.0723 44.3079 14.0723 43.68C14.0723 43.0521 13.8676 42.5334 13.4581 42.1239C13.0486 41.7053 12.5344 41.496 11.9156 41.496C11.2968 41.496 10.7826 41.7053 10.3731 42.1239C9.96358 42.5334 9.75883 43.0521 9.75883 43.68C9.75883 44.3079 9.96358 44.8312 10.3731 45.2498Z"
        fill={light ? '#fff' : '#3A3A43'}
      />
      <path
        d="M20.855 40.0901C21.6376 40.0901 22.27 40.3403 22.7524 40.8408C23.2347 41.3413 23.4758 42.0284 23.4758 42.902V47.0926H22.0016V43.0521C22.0016 42.5425 21.8651 42.1512 21.5921 41.8782C21.3191 41.6052 20.946 41.4687 20.4728 41.4687C19.954 41.4687 19.5354 41.6325 19.2169 41.9601C18.8984 42.2786 18.7392 42.77 18.7392 43.4343V47.0926H17.2649V40.2675H18.7392V41.1411C19.1851 40.4404 19.8903 40.0901 20.855 40.0901Z"
        fill={light ? '#fff' : '#3A3A43'}
      />
      <path
        d="M30.4961 40.2675H31.943V46.7786C31.943 47.816 31.6017 48.6123 30.9192 49.1674C30.2367 49.7225 29.4086 50 28.4349 50C26.8605 50 25.7821 49.4495 25.1997 48.3484L26.4692 47.6113C26.8241 48.312 27.4884 48.6623 28.4622 48.6623C29.0992 48.6623 29.5951 48.494 29.95 48.1573C30.3141 47.8297 30.4961 47.3701 30.4961 46.7786V45.9459C29.9409 46.7376 29.1492 47.1335 28.1209 47.1335C27.1563 47.1335 26.3418 46.7923 25.6775 46.1097C25.0132 45.4181 24.681 44.5855 24.681 43.6118C24.681 42.6381 25.0132 41.81 25.6775 41.1275C26.3418 40.4359 27.1563 40.0901 28.1209 40.0901C29.1492 40.0901 29.9409 40.4859 30.4961 41.2776V40.2675ZM26.7695 45.1406C27.1881 45.5501 27.7068 45.7548 28.3256 45.7548C28.9445 45.7548 29.4586 45.5501 29.8681 45.1406C30.2867 44.7311 30.4961 44.2215 30.4961 43.6118C30.4961 43.0021 30.2867 42.4925 29.8681 42.083C29.4586 41.6735 28.9445 41.4687 28.3256 41.4687C27.7068 41.4687 27.1881 41.6735 26.7695 42.083C26.36 42.4925 26.1552 43.0021 26.1552 43.6118C26.1552 44.2215 26.36 44.7311 26.7695 45.1406Z"
        fill={light ? '#fff' : '#3A3A43'}
      />
      <path
        d="M34.8216 44.3079C34.9308 44.8266 35.1811 45.2271 35.5724 45.5092C35.9637 45.7822 36.4415 45.9187 37.0057 45.9187C37.7883 45.9187 38.3753 45.6365 38.7666 45.0723L39.9814 45.7822C39.308 46.7741 38.3116 47.27 36.992 47.27C35.8818 47.27 34.9854 46.9333 34.3029 46.2599C33.6204 45.5774 33.2791 44.7174 33.2791 43.68C33.2791 42.6608 33.6158 41.81 34.2893 41.1275C34.9627 40.4359 35.8272 40.0901 36.8828 40.0901C37.8838 40.0901 38.7029 40.4404 39.3399 41.1411C39.986 41.8418 40.3091 42.6927 40.3091 43.6937C40.3091 43.8484 40.2909 44.0531 40.2545 44.3079H34.8216ZM34.808 43.1067H38.8212C38.7211 42.5516 38.489 42.133 38.125 41.8509C37.7701 41.5688 37.3515 41.4278 36.8692 41.4278C36.3232 41.4278 35.8681 41.5779 35.5041 41.8782C35.1401 42.1785 34.9081 42.588 34.808 43.1067Z"
        fill={light ? '#fff' : '#3A3A43'}
      />
      <path
        d="M43.0805 41.4141C43.4536 40.5678 44.1543 40.1447 45.1827 40.1447V41.7417C44.6184 41.7053 44.127 41.8418 43.7084 42.1512C43.2898 42.4515 43.0805 42.952 43.0805 43.6527V47.0926H41.6063V40.2675H43.0805V41.4141Z"
        fill={light ? '#fff' : '#3A3A43'}
      />
    </svg>
  );
};
export default Logo;
