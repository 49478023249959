import React from 'react';

const RightArrow = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path d="M1 8h13m-4-6 4.5 6-4.5 6" stroke="#fff" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};

export default RightArrow;
