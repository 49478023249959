import gql from 'graphql-tag';

import { resumeFull, coverLetterFull } from '/imports/generator/api/apollo/client/fragments';
import { userFull } from '/imports/core/api/apollo/client/fragments';

export const CREATE_RESUME = gql`
  mutation CreateResume($settings: ResumeSettingsInput!) {
    createResume(settings: $settings) {
      ...ResumeFull
    }
  }
  ${resumeFull}
`;

export const CREATE_COVER_LETTER = gql`
  mutation CreateCoverLetter($settings: SettingsInput!) {
    create(settings: $settings) {
      ...CoverLetterFull
    }
  }
  ${coverLetterFull}
`;

export const REMOVE_RESUME = gql`
  mutation RemoveResume($resumeId: ID!) {
    removeResume(resumeId: $resumeId) {
      ...ResumeFull
    }
  }
  ${resumeFull}
`;

export const REMOVE_COVER_LETTER = gql`
  mutation RemoveCoverLetter($id: ID!) {
    remove(id: $id)
  }
`;

export const DUPLICATE_RESUME = gql`
  mutation DuplicateResume($resumeId: ID!) {
    duplicateResume(resumeId: $resumeId) {
      ...ResumeFull
    }
  }
  ${resumeFull}
`;

export const REMOVE_WEBSITE = gql`
  mutation DeleteResumeSlug($slug: String!) {
    deleteResumeSlug(slug: $slug) {
      success
    }
  }
`;

export const DUPLICATE_COVER_LETTER = gql`
  mutation DuplicateCoverLetter($id: ID!) {
    duplicate(id: $id) {
      ...CoverLetterFull
    }
  }
  ${coverLetterFull}
`;

export const UPDATE_USER = gql`
  mutation updateUser(
    $firstName: String
    $lastName: String
    $email: String!
    $phone: String
    $address: String
    $city: String
    $postalCode: String
    $country: String
    $photo: String
    $password: String
    $isValidUserPicture: Boolean
    $extensionOnboardOption: String
  ) {
    updateUser(
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
      address: $address
      city: $city
      postalCode: $postalCode
      country: $country
      photo: $photo
      password: $password
      isValidUserPicture: $isValidUserPicture
      extensionOnboardOption: $extensionOnboardOption
    ) {
      ...UserFull
    }
  }
  ${userFull}
`;

export const DELETE_ACCOUNT = gql`
  mutation DeleteAccount {
    deleteAccount
  }
`;

export const CREATE_RESUME_SLUG = gql`
  mutation CreateResumeSlug($sourceId: ID!, $slug: String!, $template: String!, $color: String!, $isPrivate: Boolean) {
    createResumeSlug(sourceId: $sourceId, slug: $slug, template: $template, color: $color, isPrivate: $isPrivate) {
      slug
    }
  }
`;
