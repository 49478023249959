import React, { useContext, useEffect, createContext, useReducer } from 'react';

import Reducer from '/imports/job-tracking/api/reducers/search.reducer';

const SearchContext = createContext();
export const Provider = SearchContext.Provider;
export const SearchConsumer = SearchContext.Consumer;

const initialState = {
  searchText: '',
  count: 0,
  cityText: '',
  hasMore: true,
  activeCard: null,
  localSeen: [],
  jobs: {},
  total: 0,
  openJobStatusModal: false,
  openWishlistModal: null,
  applyingState: false,
  pageToken: null,
  Loading: false,
  position: 0,
  savedFeedName: '',
  companyOptions: [],
  remoteOptions: [],
  jobTypeOptions: [],
  salaryOptions: [],
  contractOptions: [],
  providerOptions: [],
  postedDateOptions: [],
  regionCode: '',
  filterData: {
    datePost: '',
    remote: '',
    educationLevel: [],
    salary: '',
    jobType: [],
    skills: [],
    location: [],
    company: [],
    allowedJobProviders: [],
    partner: '',
    contractType: '',
    isManualCheck: false,
    language: '',
    distance: 10,
    apiProvider: 'search-v3',
  },
  orderBy: '',
  index: 0,
  podioJobs: [],
  candidateSearchs: [],
};

const SearchProvider = ({ children, data = {}, feedId, podioJobs, setPodioJobs }) => {
  const [state, dispatch] = useReducer(Reducer, { ...initialState, ...data });
  const [_state, _dispatch] = useReducer(Reducer, { ...initialState });
  const _d = !!feedId ? _dispatch : dispatch;
  const _s = !!feedId ? _state : state;
  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      _d({
        type: 'SET_DATA',
        payload: { ...initialState, ...data },
      });
    }
  }, [data]);

  const handleUpdateState = (name, payload) => {
    _d({
      type: 'UPDATE_STATE',
      name,
      payload,
    });
  };
  return (
    <Provider
      value={{
        state: _s,
        dispatch: _d,
        handleUpdateState,
        podioJobs,
        setPodioJobs,
      }}
    >
      {children}
    </Provider>
  );
};

export const withSearch = () => useContext(SearchContext);

export default SearchProvider;
