import React from 'react';
import Skeleton from 'react-loading-skeleton';
import styled, { css } from 'styled-components';

import { CheckoutContainer as Container, CheckoutText } from '/imports/checkout/ui/atoms';
import Flex from '/imports/core/ui/atoms/Flex';
import Header from '/imports/checkout/ui/components/Header';
import intlHook from '/imports/core/api/useIntl';
import Page from '/imports/core/ui/atoms/Page';

const CheckoutSkeleton = () => {
  return (
    <PageExt>
      <Header />
      <Section>
        <PriceHeading>
          <Skeleton />
        </PriceHeading>
        <CheckoutSkeletonDesktop />
        <CheckoutSkeletonMobile />
      </Section>
    </PageExt>
  );
};

export const PriceSectionSkeleton = () => {
  const { t } = intlHook();
  return (
    <Section>
      <Flex justifyContent="space-between" alignItems="center">
        <PriceHeading noMargin dangerouslySetInnerHTML={{ __html: t('checkout_title') }} withText />
      </Flex>
      <CheckoutSkeletonDesktop />
      <CheckoutSkeletonMobile />
    </Section>
  );
};

const CheckoutSkeletonDesktop = () => (
  <PriceWrapper>
    {[...Array(3)].map((e, i) => (
      <SectionTable key={i}>
        <Head>
          <HeadText styled>
            <Skeleton />
          </HeadText>
          <HeadText>
            <Skeleton />
          </HeadText>
        </Head>
        <Body>
          <p>
            <Skeleton />
          </p>
        </Body>
        <ContentWrapper>
          {[...Array(7)].map((k, j) => (
            <PriceFeature key={j}>
              <Skeleton />
            </PriceFeature>
          ))}
        </ContentWrapper>
      </SectionTable>
    ))}
  </PriceWrapper>
);

export default CheckoutSkeleton;

const CheckoutSkeletonMobile = () => (
  <MobileContainer>
    <Wrapper>
      {[...Array(3)].map((d, j) => (
        <MobileBtn key={j} large={j === 1}>
          <Skeleton />
        </MobileBtn>
      ))}
    </Wrapper>
    <PricingWrapper>
      <HeaderWrapper>
        <PriceTitle>
          <Skeleton />
        </PriceTitle>
        <PriceText>
          <Skeleton />
        </PriceText>
      </HeaderWrapper>
      {[...Array(7)].map((el, i) => (
        <DetailWrapper key={i}>
          <Skeleton />
        </DetailWrapper>
      ))}
    </PricingWrapper>
  </MobileContainer>
);

const MobileContainer = styled.div`
  display: none;
  ${({ theme: { max } }) => max('xs')`
      display: flex;
      flex-direction: column;
  `}
`;

const DetailWrapper = styled.div`
  font-size: 20px;
  padding: 0 20px;
  line-height: 2.5;
`;

const PriceText = styled.div`
  font-size: 56px;
  margin: 0 auto;
  justify-content: center;
  margin-top: 10px;
  width: 30%;
`;

const PriceTitle = styled.div`
  font-family: ${({ theme }) => theme.font.family.websiteBold};
  font-size: 16px;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 0.46px;
  text-align: center;
  color: var(--black);
`;

const HeaderWrapper = styled.div`
  padding: 20px 20px 10px;
`;

const PricingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--light-values-white);
  margin-top: 16px;
  border-radius: 5px;
  position: relative;
`;

const Wrapper = styled.div`
  display: flex;
`;

const MobileBtn = styled.div`
  line-height: 44px;
  width: ${(p) => (p.large ? '40%' : '30%')};
  background-color: var(--light-values-white);
  font-size: 15px;
  font-weight: 600;
  border-radius: 0;
  padding: 10px;
  &:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  &:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

const PriceFeature = styled.div`
  ${({ theme: { larger, medium, smaller } }) => css`
    width: 88%;
    font-size: 15px;
    line-height: 1.73;
    text-align: left;
    color: #3d4047;
    margin: 0 auto 24px;
    ${larger`
      font-size: 12px;
      line-height: 1.68;
      margin-bottom: 19px;
    `}
    ${medium`
      font-size: 11px;
      line-height: 1.7;
      margin-bottom: 15px;
    `}
    ${smaller`
      font-size: 8px;
      line-height: 1.75;
      margin-bottom: 9px;
    `};
  `}
`;

const ContentWrapper = styled.div`
  ${({ theme: { larger, medium, smaller } }) => css`
    display: flex;
    flex-direction: column;
    padding: 50px 23px 0;
    ${larger`
    padding: 43px 19px 0;
    `}
    ${medium`
      padding: 40px 17px 0;
    `}
    ${smaller`
      padding: 30px 13px 0;
    `}
  `}
`;

const Body = styled.div`
  display: flex;
  height: 90px;
  padding-top: 10px;
  justify-content: center;
  p {
    font-size: 63px;
    line-height: 0.56;
    margin: 0;
    width: 40%;
  }
  ${({ theme: { larger, medium, smaller } }) => css`
    ${larger`
      height: 75px;
      p {
        font-size: 51px;
      }
    `}
    ${medium`
      height: 70px;
      p {
        font-size: 47.5px;
      }
    `}
    ${smaller`
      height: 50px;
      p {
        font-size: 34px;
      }
    `}
  `}
`;

const HeadText = styled.div`
  ${({ theme: { larger, medium, smaller, isRTL }, styled }) => css`
    font-size: 22px;
    line-height: 1;
    width: 100%;
    ${larger`
      font-size: 18px;
    `}
    ${medium`
      font-size: 16.5px;
    `}
    ${smaller`
      font-size: 12px;
    `}
    ${styled &&
    css`
      font-size: 36.5px;
      line-height: 0.96;
      margin-bottom: 20px;
      ${larger`
        font-size: 29.5px;
        margin-bottom: 15px;
      `}
      ${medium`
        font-size: 27.5px;
        margin-bottom: 13px;
      `}
      ${smaller`
        font-size: 19.5px;
        margin-bottom: 10px;
      `}
    `}
    ${isRTL &&
    css`
      direction: rtl;
    `}
  `}
`;

const Head = styled.div`
  ${({ theme: { larger, medium, smaller } }) => css`
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 142px;
    padding: 46px 25px 32px;
    ${larger`
      height: 116px;
      padding: 38px 20px 27px;
    `}
    ${medium`
      height: 110px;
      padding: 34px 15px 25px;

    `}
    ${smaller`
      height: 79px;
      padding: 25px 10px 17px;
    `}
  `}
`;

const SectionTable = styled.div`
  ${({ theme: { larger, medium, smaller } }) => css`
    display: flex;
    width: 363px;
    background-color: #fff;
    position: relative;
    flex-direction: column;
    border-radius: 17.3px;
    ${larger`
      border-radius: 14px;
      width: 294px;
    `}
    ${medium`
      border-radius: 13px;
      width: 273px;
    `}
    ${smaller`
      border-radius: 9.3px;
      width: 197px;
    `}
    box-shadow: 0 0 30.1px 0.9px rgba(0, 1, 1, 0.07);
  `}
`;

const PriceWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  ${({ theme: { max, isRTL } }) => css`
    ${max('xs')`
      display: none;
    `}
    ${isRTL &&
    css`
      flex-direction: row-reverse;
    `}
  `}
`;

const Section = styled(Container)`
  ${({ theme: { max, larger, medium, smaller } }) => css`
    background-color: inherit;
    border: none;
    padding: 0;
    margin: 0 auto;
    width: 1153px !important;
    ${larger`
      width: 935px !important;
    `}
    ${medium`
      width:  867px !important;
    `}
    ${smaller`
      width: 622px !important;
    `}
    ${max('xs')`
      border: none;
      padding: 15px 10px 25px;
      background-color: transparent;
      margin: 0;
      width: auto !important;
    `}
  `}
`;

const PageExt = styled(Page)`
  background: #f6f7fa;
  min-height: 100vh;
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      .react-loading-skeleton {
        animation-direction: reverse;
      }
    `}
`;

const PriceHeading = styled(CheckoutText)`
  ${({ theme: { max, larger, medium, smaller, isRTL } }) => css`
    margin: 80px 0 69px;
    font-size: 37px;
    line-height: 1;
    width: 100%;
    ${larger`
      margin: 65px 0 55px;
      font-size: 30px;
      line-height: 1;
    `}
    ${medium`
      margin: 60px 0 51px;
      font-size: 27.5px;
      line-height: 1;
    `}
    ${smaller`
      margin: 43px 0 37px;
      font-size: 20px;
      line-height: 1;
      text-align: left;
    `}
    ${max('xs')`
      text-align: center;
      padding: 10px 50px 30px;
      margin: 0;
    `}
    ${isRTL &&
    css`
      direction: rtl;
    `}
  `}
  ${({ withText }) =>
    withText &&
    css`
      font-family: ${({ theme }) => theme.font.family.websiteBold};
      font-size: 37px;
      line-height: 1;
      color: #f6f7fa;
      > div {
        color: #f6f7fa;
        font-size: 30px;
        line-height: 1;
        margin-top: 15px;
        font-family: ${({ theme }) => theme.font.family.websiteMedium};
      }
    `}
`;
