import React from 'react';

const Quality3Icon = () => {
  return (
    <svg width="48" height="50" viewBox="0 0 48 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M33.3301 2.72917H6.56006C4.35092 2.72917 2.56006 4.59465 2.56006 6.89584V34.7813C2.56006 37.0824 4.35092 38.9479 6.56006 38.9479H33.3301C35.5392 38.9479 37.3301 37.0824 37.3301 34.7813V6.89584C37.3301 4.59465 35.5392 2.72917 33.3301 2.72917Z"
        fill="#5DDFA9"
      />
      <path
        d="M11.74 14.8854C9.76997 23.6042 9.84997 25.5938 10.6 28.1979C10.92 29.3125 11.59 30.2917 12.56 30.875C13.97 31.7292 16.42 32.7604 20.05 32.7604C22.51 32.7604 24.55 32.1979 26.11 31.5104C28.34 30.5313 29.62 28.0833 29.28 25.5938C28.8 22.1354 28.36 19.0208 27.78 13.625L11.74 14.8854Z"
        fill="#006F4A"
      />
      <path d="M19.7199 24.5417H17.6299V33.75H19.7199V24.5417Z" fill="#C8E0FF" />
      <path d="M21.8097 24.5417H19.7197V33.75H21.8097V24.5417Z" fill="#EFF5FF" />
      <path
        d="M12.7202 12.6979V21.0729H12.7802C13.0702 24.8229 16.0502 27.7813 19.7202 27.7813C23.3902 27.7813 26.3802 24.8229 26.6602 21.0729H26.7202V12.6979H12.7202Z"
        fill="white"
      />
      <path
        d="M15.15 31.7292C13.62 32.6146 12.59 34.2083 12.38 36.0104L11.98 38.9375H19.66V33.7396L17.63 30.2813L15.15 31.7188V31.7292Z"
        fill="#378EFF"
      />
      <path
        d="M19.7102 12.6979H12.7202V21.0729H12.7802C13.0702 24.8229 16.0402 27.7813 19.7202 27.7813V12.6979H19.7102Z"
        fill="white"
      />
      <path
        d="M21.6902 30.2917L19.6602 33.75V38.9479H27.3402L26.9402 36.0209C26.7302 34.2084 25.7002 32.625 24.1702 31.7396L21.6902 30.3021V30.2917Z"
        fill="#0866F5"
      />
      <path
        d="M15.2998 7.14585C15.2998 7.14585 18.1198 5.22918 22.6298 6.01043C27.1398 6.79168 28.1798 11.4792 28.2198 14.5104C28.2898 19.4271 29.0398 19.9271 29.0398 19.9271C21.6898 21.375 17.7898 13.5417 14.9398 14.4271C15.7498 18.0938 14.4798 20.2396 13.0698 19.2813C11.6398 20.4896 11.0898 17.1667 11.0898 17.1667C10.7398 15.6354 10.4198 14.5625 10.3998 12.9375C10.3498 7.9271 15.3098 7.14585 15.3098 7.14585H15.2998Z"
        fill="#006F4A"
      />
      <path
        d="M13.26 21.7292C12.36 21.8542 11.41 21.4688 10.83 20.7292C10.25 19.9896 10.07 18.9479 10.36 18.0521C10.55 17.4584 10.98 16.9167 11.56 16.7709C12.14 16.625 12.85 16.9896 12.94 17.6042L13.26 21.7292Z"
        fill="white"
      />
      <path
        d="M26.2598 21.7292C27.1598 21.8542 28.1098 21.4688 28.6898 20.7292C29.2698 19.9896 29.4498 18.9479 29.1598 18.0521C28.9698 17.4584 28.5398 16.9167 27.9598 16.7709C27.3798 16.625 26.6698 16.9896 26.5798 17.6042L26.2598 21.7292Z"
        fill="white"
      />
      <path
        d="M19.7604 33.1497L17.6827 30.1256C17.5735 29.9666 17.3611 29.9299 17.2084 30.0437L15.9154 31.0077C15.7627 31.1215 15.7275 31.3427 15.8368 31.5018L17.9144 34.5259C18.0237 34.6849 18.2361 34.7216 18.3888 34.6078L19.6818 33.6439C19.8345 33.53 19.8697 33.3088 19.7604 33.1497Z"
        fill="#37B982"
      />
      <path
        d="M21.2664 34.5235L23.3441 31.4994C23.4534 31.3403 23.4182 31.1191 23.2655 31.0053L21.9725 30.0414C21.8198 29.9275 21.6074 29.9642 21.4982 30.1232L19.4205 33.1473C19.3112 33.3064 19.3464 33.5276 19.4991 33.6415L20.7921 34.6054C20.9448 34.7192 21.1572 34.6825 21.2664 34.5235Z"
        fill="#37B982"
      />
      <path
        d="M37.5601 35.6561V47.7499C42.4601 45.5311 46.4401 42.4999 46.4401 37.0624C46.4401 31.6249 38.7401 30.5103 37.5601 35.6561Z"
        fill="#0866F5"
      />
      <path
        d="M28.6802 37.0625C28.6802 42.5104 32.6602 45.5312 37.5602 47.75V35.6562C36.3702 30.5104 28.6802 31.6146 28.6802 37.0625Z"
        fill="#378EFF"
      />
    </svg>
  );
};
export default Quality3Icon;
