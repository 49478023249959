import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const FacebookIcon = ({ width, height }) => {
  return (
    <SvgIcon viewBox="0 0 113 113" width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M56.098 112.196c30.982 0 56.098-25.116 56.098-56.098S87.08 0 56.098 0 0 25.116 0 56.098s25.116 56.098 56.098 56.098z"
          fill="#3B5998"
        />
        <path
          d="M66.114 56.455H59.59V80h-9.887V56.455H45V48.18h4.702v-5.355c0-3.829 1.847-9.825 9.975-9.825l7.323.03v8.032h-5.314c-.871 0-2.097.43-2.097 2.256v4.87h7.389l-.864 8.267z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0h112.196v112.196H0z" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

const SvgIcon = styled.svg`
  stroke: none;
`;

FacebookIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

export default FacebookIcon;
