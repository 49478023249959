import React from 'react';
import styled, { css } from 'styled-components';

import Heading from '/imports/core/ui/atoms/Heading';

const OnboardingSubTitle = styled((p) => <Heading as="h4" {...p} />)`
  font-size: ${({ theme }) => theme.font.size.h4};
  font-weight: 400;
  font-family: ${({ theme }) => theme.font.family.websiteRegular};
  text-align: center;
  margin-top: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  && {
    color: ${({ theme }) => theme.colors.gray.regular};
  }
  ${({ theme }) => theme.max('md')`
  font-size: ${({ theme }) => theme.font.size.h6};
`}
  margin-bottom: 0;
  font-family: Avenir;
  font-size: 20px;
  font-weight: 500;
  opacity: 0.7;
  && {
    color: ${({ theme }) => theme.colors.black3};
  }
`;

export default OnboardingSubTitle;
