import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const CheckIcon = ({ color = 'var(--black)' }) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.9 12.5">
    <path
      d="M1.9 5.8l3.8 3.8 7.8-8.1"
      fill="none"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

const SvgIcon = styled.svg`
  stroke: none;
`;

export default CheckIcon;

CheckIcon.propTypes = {
  color: PropTypes.string,
};
