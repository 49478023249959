import React from 'react';
import styled, { css } from 'styled-components';

import Heading from '/imports/core/ui/atoms/Heading';

const OnboardingTitle = styled((p) => <Heading as="h1" {...p} />).withConfig({
  shouldForwardProp: (prop) =>
    // prevent these style props to render on the DOM
    !['reduceFontSize'].includes(prop),
})`
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  color: ${({ theme }) => theme.colors.black};
  margin: 0;
  line-height: 1.5;
  position: relative;

  ${({ theme }) => theme.max('md')`
  font-size: ${({ theme }) => theme.font.size.h3};
  margin-top: 32px;
  margin-bottom: 12px;
`}

  line-height: 1.2;

  font-family: Avenir;
  font-size: ${({ reduceFontSize }) => (reduceFontSize && '45px') || '55px'};
  font-weight: 900;
  letter-spacing: -1.5px;
  color: ${({ theme }) => theme.colors.black3};
`;

export default OnboardingTitle;
