import React from 'react';
import styled, { css } from 'styled-components';
import Checkbox from '/imports/core/ui/atoms/Checkbox';

import Label from '/imports/core/ui/atoms/Label';
import { getInputName } from '/imports/generator/api/helpers';
import Flex from 'imports/core/ui/atoms/Flex';

const InputCheckBox = (props) => {
  const { checked, label, language, name, variables, onChange } = props;

  const handleChange = () => {
    onChange({ target: { value: !checked } });
  };

  return (
    <Wrapper alignItems="center">
      <Checkbox
        id="toggle-checkbox"
        checked={checked}
        onClick={handleChange}
        name={`interactive-element-${getInputName(name, variables)}`}
        color="#1688fe"
        customCss={checkBoxStyle}
      />
      <StyledLabel htmlFor="toggle-checkbox" language={language}>
        {label}
      </StyledLabel>
    </Wrapper>
  );
};

export default InputCheckBox;

const checkBoxStyle = css`
  width: 16px !important;
  height: 16px !important;
  border: solid 2px #e6e6ff;
  background: #fff;
  border-radius: 3px;
`;

const Wrapper = styled(Flex)`
  margin-top: 10px;
  gap: 5px;
`;

const StyledLabel = styled(Label)`
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 13px;
  color: #7171a6;
  cursor: pointer;
  margin: 0;
`;
