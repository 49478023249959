import React from 'react';

const ChevronLargeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path
        fill="#BFC5E0"
        fillRule="evenodd"
        d="M12 9.621L4.061 17.561 1.939 15.439 12 5.379 22.061 15.439 19.939 17.561z"
      />
    </svg>
  );
};

export default ChevronLargeIcon;
