import styled, { css } from 'styled-components';

import TextArea from '/imports/core/ui/atoms/Textarea';

export const TextAreaStyle = css`
  border-radius: 8px;
  border: 1px solid var(--light-values-light-regular);
  background-color: var(--light-values-light-extra);
  padding: 9px 12px;
  ${({ theme }) => theme.jobTrack.renderFonts('Medium', '15', 'var(--dark-values-black)', '1.47')};
  overflow-y: auto;
  resize: none;
  &::placeholder {
    ${({ theme }) => theme.jobTrack.renderFonts('Medium', '15', 'var(--grayscale-n400)', '1.47')};
  }
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
  }
  &:hover::-webkit-scrollbar-thumb {
    background: rgb(229, 228, 234);
    border-radius: 3px;
  }
  &:focus {
    ~ div {
      visibility: hidden;
    }
  }
  &:hover {
    border: solid 1px var(--mid-values-gray-light);
    background-color: var(--light-values-white);
  }
  &:focus {
    border: solid 2px var(--additional-colors-blue);
    padding: 8px 11px;
    background-color: var(--light-values-white);
  }
  ${({ jobTask, theme }) =>
    jobTask &&
    css`
      &::placeholder {
        ${theme.jobTrack.renderFonts('Medium', '15', 'var(--grayscale-n400)', '1.47')};
      }
    `}
  ${({ reportDesc }) =>
    reportDesc &&
    css`
      width: 100%;
      background-color: var(--light-values-white);
    `}
`;

const TextAreaField = styled(TextArea)`
  ${TextAreaStyle}
`;

export default TextAreaField;
