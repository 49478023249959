import React, { PureComponent } from 'react';
import animateScrollTo from 'animated-scroll-to';
import compose from 'lodash.flowright';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import styled, { css } from 'styled-components';
import nookies from 'nookies';

import { blockDetailImmutableUpdate } from '/imports/generator/api/apollo/client/helpers';
import {
  extractBlockInfo,
  handleBlockRepositioning,
  replacedBlocks,
  templateHasColor,
} from '/imports/generator/api/helpers';
import { GET_RESUME, GET_COVER_LETTER } from '/imports/generator/api/apollo/client/queries';
import { getPreviewBlocksHeight } from '/lib/helpers';
import List from '/imports/core/ui/atoms/List';
import ListItem from '/imports/core/ui/atoms/ListItem';
import { TEMPLATES, COVER_LETTER_TEMPLATES } from '/imports/generator/api/constants';
import {
  UPDATE_RESUME_DETAIL,
  UPDATE_COVER_LETTER_DETAIL,
  UPDATE_BLOCK_POSITION,
} from '/imports/generator/api/apollo/client/mutations';
import { withTracking } from '/imports/core/hooks/useTracking';
import { withAccount } from 'imports/core/api/accounts/accountContext';

const extractTemplateVersionByID = (template) => {
  const match = template.match(/-v(\d+)$/);
  const version = match ? parseInt(match[1], 10) : 1;
  return isNaN(version) ? 1 : version;
};

@withTracking
@withAccount
@compose(
  graphql(UPDATE_RESUME_DETAIL, { name: 'updateResumeDetail' }),
  graphql(UPDATE_COVER_LETTER_DETAIL, { name: 'updateCoverLetterDetail' }),
  graphql(UPDATE_BLOCK_POSITION, { name: 'updateBlockPosition' }),
)
class TemplatePicker extends PureComponent {
  static propTypes = {
    updateResumeDetail: PropTypes.func,
    updateCoverLetterDetail: PropTypes.func,
    source: PropTypes.object,
    isCoverLetter: PropTypes.bool,
  };

  handleColorUpdate = (id, value, isCoverLetter) => {
    const { updateResumeDetail, updateCoverLetterDetail } = this.props;
    const mutation = isCoverLetter ? updateCoverLetterDetail : updateResumeDetail;
    const templates = isCoverLetter ? COVER_LETTER_TEMPLATES : TEMPLATES;
    const templateObject = templates.find((tem) => tem.id === value);
    const options = {
      variables: {
        docId: id,
        path: 'settings.color',
        value: templateObject?.defaultColor || 'black',
      },
    };
    if (isCoverLetter) {
      options.context = {
        client: 'coverLetter',
      };
    }
    mutation(options);
  };

  select = async ({
    target: {
      dataset: { value: newValue },
    },
  }) => {
    const templatesWithVersion = ['budapest-v2', 'budapest-v3'];
    const value = templatesWithVersion.includes(newValue) ? 'budapest' : newValue;
    const version = templatesWithVersion.includes(newValue) ? extractTemplateVersionByID(newValue) : 1;
    const {
      source,
      source: { id },
      updateResumeDetail,
      updateCoverLetterDetail,
      isCoverLetter,
      updateImmue,
      updateBlockPosition,
      trackEvent,
    } = this.props;
    const mutation = isCoverLetter ? updateCoverLetterDetail : updateResumeDetail;
    this.props.trackEvent('switch_mobile_template', {
      template: newValue,
    });
    if (!isCoverLetter) {
      updateResumeDetail({
        variables: {
          docId: id,
          path: 'settings.templateVersion',
          value: `${(version && parseInt(version)) || 1}`,
        },
      });
      blockDetailImmutableUpdate(updateImmue)(id, 'settings.templateVersion')(version);
    }

    const options = {
      variables: {
        docId: id,
        path: 'settings.template',
        value,
      },
    };

    if (isCoverLetter) {
      options.context = {
        client: 'coverLetter',
      };
    }

    if (value !== source.settings.template) this.handleColorUpdate(id, value, isCoverLetter);
    blockDetailImmutableUpdate(updateImmue)(id, 'settings.template')(value);
    if (!isCoverLetter) {
      mutation({ ...options }).then(async (res) => {
        const resume = res.data.updateResumeDetail;
        const customBlocks =
          resume && resume.blocks && resume.blocks.length > 0
            ? resume.blocks.map((b) => (b.type === 'CUSTOM' ? b.id : null)).filter((b) => b)
            : [];
        const customSkillsBlocks =
          resume && resume.blocks && resume.blocks.length > 0
            ? resume.blocks.map((b) => (b.type === 'CUSTOM_SKILLS_CATEGORY' ? b.id : null)).filter((b) => b)
            : [];
        const heights = getPreviewBlocksHeight(customBlocks, customSkillsBlocks);
        const updatedBlocks = handleBlockRepositioning(resume.blocks, heights, null, true, true);
        const newResume = replacedBlocks(resume, updatedBlocks.blocks);
        const blocks = extractBlockInfo(updatedBlocks.blocks);
        updateImmue(newResume);
        updateBlockPosition({
          variables: {
            resumeId: newResume.id,
            blocks: blocks,
          },
        });
        trackEvent('mobile_preview_block_respositioning');
      });
    }
  };

  componentDidMount() {
    this.scrollTo();
  }

  componentDidUpdate(prevProps) {
    const { source } = this.props;
    const { source: prevSource } = prevProps;

    if (source.settings.template !== prevSource.settings.template) return this.scrollTo();
  }

  scrollTo = () => {
    animateScrollTo(this.selected, {
      offset: -50,
      horizontal: true,
      element: this.cont,
    });
  };

  filterTemplateOptionsByRole = (options) => {
    const { agent_client_id } = nookies.get({});
    if (agent_client_id !== undefined && agent_client_id !== null) {
      return options;
    } else {
      return options.filter((t) => {
        if (t?.role) {
          return t?.role?.includes(this.props?.currentUser?.role);
        }
        if (t?.displayCondition && typeof t.displayCondition == 'function') {
          return t?.displayCondition();
        }
        return true;
      });
    }
  };

  render() {
    const { source, isCoverLetter } = this.props;
    const templates = isCoverLetter
      ? COVER_LETTER_TEMPLATES
      : TEMPLATES.filter((data) => !['cali', 'lima'].includes(data.id));
    const filteredTemplates = this.filterTemplateOptionsByRole(templates);

    return (
      <PickerCont ref={(r) => (this.cont = r)} horizontal unstyled>
        {filteredTemplates.map((template) => {
          let selected = template.id === source.settings.template;
          if (source.settings.templateVersion > 1 && source.settings.template == 'budapest') {
            selected = template.id == `budapest-v${source.settings.templateVersion}`;
          }
          if (template.hidden) return;
          return (
            <TemplateItem
              key={template.id}
              onClick={this.select}
              data-value={template.id}
              selected={selected}
              ref={(ref) => {
                if (selected) {
                  this.selected = ref;
                }
              }}
            >
              {template.name}
            </TemplateItem>
          );
        })}
      </PickerCont>
    );
  }
}

TemplatePicker.displayName = 'TemplatePicker';

const PickerCont = styled(List)`
  display: inline-block;
  width: 95%;
  padding: 10px 15px;
  overflow-y: scroll;
  flex-wrap: nowrap;
  justify-content: center;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const TemplateItem = styled(ListItem)`
  color: ${(p) => p.theme.colors.gray.regular};
  display: inline;
  font-size: 16px;
  margin-right: 10px;
  padding: 0 10px;
  white-space: nowrap;
  ${(p) =>
    p.selected &&
    css`
      color: white;
    `}
`;

export default TemplatePicker;
