import { ARTICLE_SLUG_TO_COMPONENT_MAP } from './articleSlugComponent';
import { Push } from '../ui/components/Link';

export const handleReadMoreClick = (slug, locale) => {
  Push(`/article/${slug}`, locale);
};

export const getHelpArticleThanksState = (slug) => {
  try {
    return (JSON.parse(localStorage.getItem('articleThanks')) || []).includes(slug);
  } catch {
    return false;
  }
};

export const updateHelpArticleThanksState = (slug, isHelpful) => {
  let articleThanks = [];
  try {
    articleThanks = JSON.parse(localStorage.getItem('articleThanks')) || [];
  } catch {}

  if (!isHelpful) {
    localStorage.setItem('articleThanks', JSON.stringify(articleThanks.filter((item) => item !== slug)));
  } else {
    localStorage.setItem('articleThanks', JSON.stringify([...articleThanks, slug]));
  }
  return isHelpful;
};

export const getArticleComponentFromSlug = (slug) => {
  return ARTICLE_SLUG_TO_COMPONENT_MAP.find((map) => map.slug.includes(slug))?.component;
};
