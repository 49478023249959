import React, { Fragment } from 'react';
import dynamic from 'next/dynamic';
import styled, { css } from 'styled-components';

import Container from '/imports/core/ui/atoms/Container';
import HeaderSkeleton from '/imports/dashboard/ui/skeleton/HeaderSkeleton';
import ListsSkeleton from '/imports/dashboard/ui/skeleton/ListsSkeleton';
import Page from '/imports/core/ui/atoms/Page';

const DashboardHeadingTabs = dynamic(() => import('/imports/dashboard/ui/components/DashboardHeadingTabs'), {
  ssr: false,
});

const FullDashboardSkeleton = ({ role }) => {
  return (
    <Fragment>
      <HeaderSkeleton />
      <PageWrap>
        <ContainerWrapper>
          <DashboardHeadingTabs tabIndex="0" handleTabClick={null} handleCreateClick={null} role={role} />
          <ListsSkeleton />
        </ContainerWrapper>
      </PageWrap>
    </Fragment>
  );
};

export default FullDashboardSkeleton;

const PageWrap = styled(Page)`
  padding: 65px 0;
  display: flex;
  background-color: rgb(240, 240, 242);
  flex-direction: column;
  min-height: calc((100vh - 75px));
`;

const ContainerWrapper = styled(Container)`
  max-width: 1060px;
  margin: 0 auto;
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
`;
