import React, { PureComponent } from 'react';
import ColorSelect from '../components/ColorSelect';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import HeadingAtom from '/imports/core/ui/atoms/Heading';
import Link from '/components/Link';
import TemplatePicker from '/imports/generator/ui/atoms/TemplatePicker';
import { withIntl } from '/imports/core/api/useIntl';
import { withResponsiveContext } from '/imports/core/api/responsiveContext';
import { withTracking } from '/imports/core/hooks/useTracking';

@withTracking
@withResponsiveContext
@withIntl
class PreviewMobileControls extends PureComponent {
  static propTypes = {
    source: PropTypes.object,
  };

  handleGenerateWebsite = () => {
    const { source: { hasWebsite } = {}, trackEvent } = this.props;
    if (hasWebsite) return;
    trackEvent('create_new_website', { pageView: 'generator_view' });
  };

  render() {
    const {
      source,
      source: {
        id,
        settings: { color, template },
      },
      isCoverLetter,
      t,
      websiteCTAProps,
      showWebsiteFeature,
      updateImmue,
    } = this.props;
    const { currentStep, hasWebsite } = source;
    const showWeb = false; /*showWebsiteFeature && !isCoverLetter && currentStep === 'finish'*/
    return (
      <PreviewCont>
        <Heading>{t('generator.settings.template')}</Heading>
        <TemplatePicker source={source} isCoverLetter={isCoverLetter} updateImmue={updateImmue} />
        <Heading>{t('preview.color')}</Heading>
        <ColorsCont showWeb={showWeb}>
          <ColorSelect
            id={id}
            selected={color}
            template={template}
            withBorder
            isCoverLetter={isCoverLetter}
            updateImmue={updateImmue}
          />
        </ColorsCont>
      </PreviewCont>
    );
  }
}

const PreviewCont = styled.div`
  text-align: center;
  padding: 30px 0 40px;
`;

const Heading = styled((p) => <HeadingAtom as="h4" {...p} />)`
  && {
    color: white;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    margin-bottom: 0;
    font-size: 12px;
  }
`;

const ColorsCont = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 0;
  ${({ showWeb }) =>
    showWeb &&
    css`
      padding-bottom: 38px;
    `}
`;

const WebsiteButton = styled.div`
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.21px;
  color: var(--light-values-white);
  padding: 11px 24px;
  border-radius: 5px;
  border: solid 2px #1688fe;
`;

export default PreviewMobileControls;
