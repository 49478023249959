import React from 'react';
import Skeleton from 'react-loading-skeleton';
import styled, { css } from 'styled-components';

import Flex from '/imports/core/ui/atoms/Flex';
import Logo from '/imports/core/ui/components/Logo';
import useIntl from '/imports/core/api/useIntl';

const JOBTRACK_NAV_LINK = ['jobtrack_header_item1', 'jobtrack_header_item2', 'jobtrack_header_item3'];

const HeaderSkeleton = ({ account = false, isJobTrackHeader }) => {
  const { t } = useIntl();
  return (
    <HeaderCont isJobTrackHeader={isJobTrackHeader}>
      <Content>
        <Logo path={'/dashboard'} />
      </Content>
      {isJobTrackHeader && (
        <SearchLinkWrap alignItems="center">
          {JOBTRACK_NAV_LINK.map((item, k) => (
            <NavLink key={k}>{t(item)}</NavLink>
          ))}
        </SearchLinkWrap>
      )}
      <Content isJobTrackHeader={isJobTrackHeader}>
        <LocaleWrapper>
          <Inner>
            <Skeleton circle width={20} height={20} />
          </Inner>
          <Inner>
            <Skeleton width={60} height={40} />
          </Inner>
        </LocaleWrapper>
        {account && (
          <DashboardBtn>
            <Skeleton width={150} height={40} />
          </DashboardBtn>
        )}
        <CenterImage>
          <Skeleton circle width={37} height={37} />
        </CenterImage>
        <UserContainer>
          <Skeleton width={120} />
        </UserContainer>
      </Content>
    </HeaderCont>
  );
};

const SearchLinkWrap = styled(Flex)`
  margin-left: 40px;
  gap: 24px;
`;

const NavLink = styled.div`
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 15px;
  line-height: 22px;
  color: var(--grayscale-n800);
  cursor: pointer;
  ${({ active }) =>
    active &&
    css`
      color: #1688fe;
    `}
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  ${({ isJobTrackHeader }) =>
    isJobTrackHeader &&
    css`
      margin-left: auto;
    `}
`;

const HeaderCont = styled.div`
  background-color: white;
  display: flex;
  padding: 0px 32px;
  justify-content: space-between;
  align-items: center;
  ${({ theme: { general, max, isRTL } }) => css`
    height: ${general.headerHeight};
    ${max('sm')`
      padding: 0px 5vw;
    `}
    ${isRTL &&
    css`
      direction: rtl;
      .react-loading-skeleton {
        animation-direction: reverse;
      }
    `}
  `}
  ${({ isJobTrackHeader }) =>
    isJobTrackHeader &&
    css`
      border-bottom: 1px solid #e3e3e4;
      justify-content: unset;
    `}
`;

const ItemContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

const UserContainer = styled(ItemContainer)`
  ${({ theme: { max } }) => max('sm')`
    display: none;
  `}
`;

const CenterImage = styled(ItemContainer)`
  margin: ${({ theme: { isRTL } }) => (isRTL ? '0 0 0 15px' : '0 15px 0 0')};
`;

const DashboardBtn = styled(ItemContainer)`
  ${({ theme: { isRTL, max } }) => css`
    margin: ${isRTL ? '0 0 0 20px' : '0 20px 0 0'};
    ${max('sm')`
      display: none;
    `}
  `}
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
  &:nth-child(1) {
    ${({ theme: { isRTL, max } }) => css`
      margin: ${isRTL ? '0 0 0 15px' : '0 15px 0 0'};
      ${max('sm')`
        display: none;
      `}
    `}
  }
`;

const LocaleWrapper = styled.div`
  display: flex;
  ${({ theme: { isRTL, max } }) => css`
    margin: ${isRTL ? '0 0 0 50px' : '0 50px 0 0'};
    ${max('sm')`
      margin: ${isRTL ? '0 0 0 30px' : '0 30px 0 0'};
    `}
  `}
`;

export default HeaderSkeleton;
