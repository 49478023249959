import React from 'react';

const FileDownloadIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path
      fill="#FF7676"
      fillRule="evenodd"
      d="M15.414 1L21 6.586V21a2 2 0 01-2 2H5a2 2 0 01-2-2V3a2 2 0 012-2h10.414zM13 3H5v18h14V9h-4a2 2 0 01-2-2V3zm0 7v4.586l1.293-1.293 1.414 1.414L12 18.414l-3.707-3.707 1.414-1.414L11 14.586V10h2zm2-6.585V7h3.586L15 3.415z"
    />
  </svg>
);

export default FileDownloadIcon;
