import React from 'react';

const Quality4Icon = () => {
  return (
    <svg width="52" height="51" viewBox="0 0 52 51" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M39 2.58188H10.8333C8.4401 2.58188 6.5 4.48467 6.5 6.83188V34.4569C6.5 36.8041 8.4401 38.7069 10.8333 38.7069H39C41.3932 38.7069 43.3333 36.8041 43.3333 34.4569V6.83188C43.3333 4.48467 41.3932 2.58188 39 2.58188Z"
        fill="#5DDFA9"
      />
      <path
        d="M24.7759 2.58188H39C41.3942 2.58188 43.3334 4.48375 43.3334 6.83188V34.4569C43.3334 36.805 41.3942 38.7069 39 38.7069H24.7759V2.58188Z"
        fill="#5DDFA9"
      />
      <path
        d="M24.776 39.5037H15.6544L14.2894 34.0106C13.6177 31.3225 15.3077 28.6131 18.0485 27.9756L19.9444 27.5294L24.7652 27.6037V39.5037H24.776Z"
        fill="#0050C7"
      />
      <path
        d="M24.7759 39.5037H33.8975L35.2625 34.0106C35.9342 31.3225 34.2442 28.6131 31.5034 27.9756L29.6075 27.5294L24.7867 27.6037V39.5037H24.7759Z"
        fill="#0043A6"
      />
      <path d="M24.7761 22.5356H21.7861V29.6331H24.7761V22.5356Z" fill="#C8E0FF" />
      <path d="M27.7659 22.5356H24.7759V29.6331H27.7659V22.5356Z" fill="#EFF5FF" />
      <path
        d="M24.7757 8.69125H24.7649L17.6582 10.6356V16.7769C17.6582 20.655 21.2224 24.6394 24.7757 24.6394C24.7757 24.6394 24.7757 12.1656 24.7757 8.69125Z"
        fill="white"
      />
      <path
        d="M24.7759 8.69125H24.7867L31.8934 10.6356V16.7769C31.8934 20.655 28.3292 24.6394 24.7759 24.6394C24.7759 24.6394 24.7759 12.1656 24.7759 8.69125Z"
        fill="white"
      />
      <path
        d="M17.8535 18.8169C16.6401 18.8169 15.6543 17.85 15.6543 16.66C15.6543 15.47 16.6401 14.5031 17.8535 14.5031V18.8169Z"
        fill="white"
      />
      <path
        d="M31.6875 18.8169C32.9008 18.8169 33.8867 17.85 33.8867 16.66C33.8867 15.47 32.9008 14.5031 31.6875 14.5031V18.8169Z"
        fill="white"
      />
      <path
        d="M32.2184 15.6506L30.6909 15.2362C29.3043 14.5669 28.0584 13.4619 27.3326 12.2081C26.4334 13.1219 25.3934 13.8869 24.2451 14.4712C22.5659 15.3212 20.6918 15.7569 18.8176 15.7569C18.8176 15.7569 17.3009 15.7887 17.3226 15.6612C17.1709 15.64 16.4018 15.215 16.2501 15.1937C16.2501 15.1937 13.0434 10.7206 13.6501 7.17187C14.2459 7.21437 19.4568 5.21687 20.4534 4.82375C24.2559 3.32562 28.2534 3.04937 29.6943 6.36437C31.8068 4.81312 33.8976 9.58375 33.8976 9.58375C33.8976 9.58375 34.0926 14.1419 32.2293 15.6612L32.2184 15.6506Z"
        fill="#0043A6"
      />
      <path d="M22.956 32.5125L19.9443 27.5294L21.786 25.9356L24.8193 29.6225L22.956 32.5125Z" fill="#378EFF" />
      <path d="M26.6827 32.5125L29.6943 27.5294L27.8635 25.9356L24.8193 29.6225L26.6827 32.5125Z" fill="#0866F5" />
      <path
        d="M43.3335 33.745H6.50016C5.30355 33.745 4.3335 34.6964 4.3335 35.87V44.37C4.3335 45.5436 5.30355 46.495 6.50016 46.495H43.3335C44.5301 46.495 45.5002 45.5436 45.5002 44.37V35.87C45.5002 34.6964 44.5301 33.745 43.3335 33.745Z"
        fill="#378EFF"
      />
      <path
        d="M24.7759 33.745H43.3334C44.525 33.745 45.5 34.7012 45.5 35.87V44.37C45.5 45.5387 44.525 46.495 43.3334 46.495H24.7759V33.745Z"
        fill="#0866F5"
      />
      <path
        d="M24.7759 35.4981L26.5309 38.4519L29.9434 39.185L27.6251 41.735L27.9609 45.135L24.7759 43.7644L21.5801 45.135L21.9267 41.735L19.6084 39.185L23.0101 38.4519L24.7759 35.4981Z"
        fill="#EFF5FF"
      />
      <path
        d="M26.5309 38.4519L24.7759 35.4981V43.7644L27.9609 45.135L27.625 41.735L29.9434 39.185L26.5309 38.4519Z"
        fill="white"
      />
      <path
        d="M36.01 36.72L37.3208 38.9194L39.8667 39.4719L38.1333 41.3737L38.3933 43.9131L36.01 42.8931L33.6267 43.9131L33.8867 41.3737L32.1533 39.4719L34.6992 38.9194L36.01 36.72Z"
        fill="white"
      />
      <path
        d="M13.8235 36.72L15.1343 38.9194L17.6801 39.4719L15.9468 41.3737L16.2068 43.9131L13.8235 42.8931L11.4401 43.9131L11.7001 41.3737L9.9668 39.4719L12.5126 38.9194L13.8235 36.72Z"
        fill="#EFF5FF"
      />
    </svg>
  );
};
export default Quality4Icon;
