import React from 'react';

const ResumegptLogoV1 = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 2.5C0 1.39543 0.895431 0.5 2 0.5H9.33331V9.83331H0V2.5Z" fill="#FF6F5B" />
      <path d="M0 11.166H9.33331V16.4993H2C0.895429 16.4993 0 15.6039 0 14.4993V11.166Z" fill="#FFC666" />
      <path
        d="M10.6666 0.5H11.3333C13.9106 0.5 15.9999 2.58933 15.9999 5.16665C15.9999 7.74398 13.9106 9.83331 11.3333 9.83331H10.6666V0.5Z"
        fill="#27CFB1"
      />
      <path d="M10.6666 11.166C13.6121 11.166 15.9999 13.5538 15.9999 16.4993H10.6666V11.166Z" fill="#428EFF" />
    </svg>
  );
};

export default ResumegptLogoV1;
