import styled from 'styled-components';

import LinkAtom from '/imports/pdf/core/ui/atoms/Link';

const Link = styled(LinkAtom)`
  font-family: ${({ theme }) => theme.contentFont()};
  color: #808183;
  font-size: ${({ theme }) => theme.convertPx(12, true)};
  line-height: 1.33;
  text-decoration: none;
`;

export default Link;
