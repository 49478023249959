import React from 'react';

const SupportIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="24" viewBox="0 0 22 24" fill="none">
      <g id="Group">
        <path
          id="Vector"
          d="M11 0.5C5.21 0.5 0.5 5.21 0.5 11V17C0.5 18.3785 1.6215 19.5 3 19.5H6C6.276 19.5 6.5 19.276 6.5 19V12C6.5 11.724 6.276 11.5 6 11.5H1.5V11C1.5 5.7615 5.7615 1.5 11 1.5C16.2385 1.5 20.5 5.7615 20.5 11V11.5H16C15.724 11.5 15.5 11.724 15.5 12V19C15.5 19.276 15.724 19.5 16 19.5H19C19.565 19.5 20.081 19.3045 20.5 18.987V19.5C20.5 20.8785 19.3785 22 18 22H16.5V21.5C16.5 21.224 16.276 21 16 21H11C10.724 21 10.5 21.224 10.5 21.5V23C10.5 23.276 10.724 23.5 11 23.5H16C16.276 23.5 16.5 23.276 16.5 23H18C19.93 23 21.5 21.43 21.5 19.5V11C21.5 5.21 16.79 0.5 11 0.5Z"
          fill="#1688FE"
        />
      </g>
    </svg>
  );
};

export default SupportIcon;
