import env from '/env';

export const uniformingString = (inputString) => {
  return inputString.toLowerCase().replace(/[^a-z0-9]/g, '');
};

export const updateUserExtensionOnboardingOption = async (option, token) => {
  const fetchOptions = {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  const params = {
    key: 'extensionOnboardOption',
    value: uniformingString(option),
  };
  const response = await fetch(`${env.BASE_API}/user`, { ...fetchOptions, body: JSON.stringify(params) });
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return true;
};
