import React from 'react';
import styled from 'styled-components';

const VehicleIcon = () => {
  return (
    <SvgIcon viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="22" height="22">
      <path
        d="M16.382 5a2 2 0 011.789 1.106l1.605 3.21L23 11.464V16a2 2 0 01-2 2h-1.171a3.001 3.001 0 01-5.658 0H9.829a3.001 3.001 0 01-5.658 0H3a2 2 0 01-2-2V7a2 2 0 012-2h13.382zM7 16a1 1 0 100 2 1 1 0 000-2zm10 0a1 1 0 100 2 1 1 0 000-2zm-.618-9H3v9h1.17a3.001 3.001 0 015.659 0h4.342a3.001 3.001 0 015.658 0H21v-3.465l-2.776-1.85L16.382 7z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
};

VehicleIcon.displayName = 'RightToLeft';

const SvgIcon = styled.svg`
  stroke: none;
`;

export default VehicleIcon;
