import React from 'react';
import styled from 'styled-components';

const Chevron = ({ up, ...props }) => {
  const dPath = up ? 'M1.5 6.5l5-5 5 5' : 'M1.5 1.5l5 5 5-5';
  return (
    <SvgIcon {...props} width="18" height="12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d={dPath} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  );
};

const SvgIcon = styled.svg`
  stroke: currentColor;
`;

Chevron.Up = (props) => <Chevron {...props} up />;
Chevron.Down = (props) => <Chevron {...props} />;

export default Chevron;
