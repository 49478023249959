import { cloneDeep } from 'lodash';

const updateState = (state, action) => {
  const data = cloneDeep(state);
  data[action.name] = action.payload;
  return data;
};

const Reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_STATE':
      return updateState(state, action);
    default:
      return state;
  }
};

export default Reducer;
