import React, { PureComponent } from 'react';
import compose from 'lodash.flowright';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';

import PhotoUpload from '/imports/core/ui/components/PhotoUpload';
import { TEMPLATES, COVER_LETTER_TEMPLATES } from '/imports/generator/api/constants';
import { UPDATE_RESUME_DETAIL, UPDATE_COVER_LETTER_DETAIL } from '/imports/generator/api/apollo/client/mutations';

@compose(
  graphql(UPDATE_RESUME_DETAIL, { name: 'updateResumeDetail' }),
  graphql(UPDATE_COVER_LETTER_DETAIL, { name: 'updateCoverLetterDetail' }),
)
class UploadPhoto extends PureComponent {
  static propTypes = {
    updateResumeDetail: PropTypes.func,
    updateCoverLetterDetail: PropTypes.func,
    variables: PropTypes.object,
    template: PropTypes.string,
    isCoverLetter: PropTypes.bool,
    isGenerator: PropTypes.bool,
  };

  handleUpload = (value) => {
    const {
      variables: { docId, path },
      updateResumeDetail,
      updateCoverLetterDetail,
      isCoverLetter,
      optimisticResponse: immutableOptimisticResponse,
    } = this.props;
    const mutation = isCoverLetter ? updateCoverLetterDetail : updateResumeDetail;

    const options = {
      variables: { docId, path, value },
      context: {},
    };
    if (isCoverLetter) {
      options.context.client = 'coverLetter';
    }
    immutableOptimisticResponse(value);
    mutation(options);
  };

  onRemovePhoto = (e) => {
    e.stopPropagation();

    const {
      variables: { docId },
      updateResumeDetail,
      updateCoverLetterDetail,
      isCoverLetter,
      optimisticResponse: immutableOptimisticResponse,
    } = this.props;

    const mutation = isCoverLetter ? updateCoverLetterDetail : updateResumeDetail;

    const options = {
      variables: {
        docId,
        path: 'details.userPic',
        value: '',
      },
      context: {},
    };

    if (isCoverLetter) options.context.client = 'coverLetter';
    immutableOptimisticResponse(null);
    mutation(options);
    if (!isCoverLetter) {
      const options = {
        variables: {
          docId,
          path: 'details.isValidUserPicture',
          value: true,
        },
        context: {},
      };
      mutation(options);
    }
  };

  render() {
    const { template, value, isCoverLetter, isGenerator, variables, name } = this.props;
    const templates = isCoverLetter ? COVER_LETTER_TEMPLATES : TEMPLATES;
    const templateData = templates.find((t) => t.id === template);
    const { noUserPic: disabled, photoCropSize: cropSize } = templateData || {};

    return (
      <PhotoUpload
        onUploaded={this.handleUpload}
        onRemovePhoto={this.onRemovePhoto}
        photoLoaded={value !== null}
        disabled={disabled}
        value={value}
        cropSize={cropSize}
        isGenerator={isGenerator}
        variables={variables}
        isCoverLetter={isCoverLetter}
        name={name}
      />
    );
  }
}

export default UploadPhoto;
