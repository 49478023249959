import Router from 'next/router';
import { getDefaultLanguage, getDomainFromHost } from 'lib/helpers';

const getRoute = (path, locale, cropFirstSlash = false, host) => {
  const isDefault = host ? getDefaultLanguage(host) === locale : getDefaultLanguage() === locale;
  const route = host === 'cvdeboss' || isDefault ? path : `/${locale}${path}`;
  return cropFirstSlash && route.startsWith('/') ? route.slice(1) : route;
};

export const Push = (href, locale = getDefaultLanguage(), as, options = {}) => {
  const { hostname } = window.location;
  const host = getDomainFromHost(hostname);
  let newAs = getRoute(as || href, locale, false, host);
  Router.push(href, newAs, { ...options });
};
