import {
  setData,
  updateState,
  openWishlistModal,
  closeWishlistModal,
  updateApplyState,
} from '/imports/job-tracking/api/library/search.lib';

const Reducer = (state, action) => {
  switch (action.type) {
    case 'SET_DATA':
      return setData(state, action);
    case 'UPDATE_STATE':
      return updateState(state, action);
    case 'OPEN_WISHLIST_MODAL':
      return openWishlistModal(state, action);
    case 'CLOSE_WISHLIST_MODAL':
      return closeWishlistModal(state);
    case 'APPLYING_STATE':
      return updateApplyState(state, action);
    default:
      return state;
  }
};

export default Reducer;
