import React, { useEffect, useState } from 'react';
import { destroyCookie, parseCookies, setCookie } from 'nookies';
import styled, { css } from 'styled-components';

const logInCopy = `&#128170;`;
const logOutCopy = '&#128680; LOGOUT &#128680;';

function AdminBar() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [userId, setUserId] = useState('');

  useEffect(() => {
    const cookies = parseCookies();
    setLoggedIn(!!cookies.user_id);
  }, []);

  const clickHandler = () => (loggedIn ? logoutHandler() : setOpenDrawer(!openDrawer));

  const changeHandler = (e) => setUserId(e.target.value);

  const loginHandler = () => {
    if (!userId) return;

    setCookie({}, 'user_id', userId, {
      maxAge: 15 * 60 * 1000, // it expires after 15 mins
      path: '/',
    });
    return window.location.reload();
  };

  const logoutHandler = () => {
    destroyCookie({}, 'user_id');
    return window.location.reload();
  };

  return (
    <StyledAdminBar loggedIn={loggedIn} openDrawer={openDrawer}>
      <StyledButton
        onClick={clickHandler}
        loggedIn={loggedIn}
        type="button"
        dangerouslySetInnerHTML={{
          __html: loggedIn ? logOutCopy : logInCopy,
        }}
      />
      <input type="text" placeholder="enter UserID here" onChange={changeHandler} />
      <button onClick={loginHandler}>Login</button>
    </StyledAdminBar>
  );
}

AdminBar.displayName = 'AdminBar';

const StyledAdminBar = styled.section`
  width: 100%;
  padding: 25px;
  padding-left: 50%;
  border-top: 5px solid #4bb543;
  font-family: ${({ theme }) => theme.font.family.websiteRegular};
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  transform: ${({ openDrawer }) => (openDrawer ? 'transform: translateY(0%);' : 'translateY(calc(100% - 5px));')};
  transition: transform 0.2s linear;

  input,
  button:last-of-type {
    display: inline-block;
    line-height: 23px;
    padding: 5px 10px;
    vertical-align: middle;
  }

  input {
    width: 50%;
    margin-right: 10px;
    border: 1px solid #cacaca;
    font-size: 16px;
  }

  button:last-of-type {
    font-size: 13px;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    border: 1px solid #2274b0;
    border-radius: 7px;
    background-color: #2196f3;
  }

  ${({ loggedIn }) =>
    loggedIn &&
    css`
      border-color: #b33a3a;
    `}
`;

const StyledButton = styled.button`
  padding: 10px 15px;
  font-size: 30px;
  border: 0;
  background-color: #4bb543;
  color: white;
  font-weight: bold;
  border-radius: 5px 5px 0 0;
  position: absolute;
  top: calc(-100% + 26px);
  right: 30px;
  cursor: pointer;

  ${({ loggedIn }) =>
    loggedIn &&
    css`
      background-color: #b33a3a;
    `}
`;

export default AdminBar;
