import CrossIcon from '../atoms/CrossIcon';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import Flex from '/imports/core/ui/atoms/Flex';
import ButtonAtom from '/imports/core/ui/atoms/Button';
import Chips from '/imports/core/ui/atoms/Chips';
import { useState, Fragment } from 'react';
import { addSkillsToResume } from '/imports/generator/api/helpers';
import useTracking from '/imports/core/hooks/useTracking';
import { useRouter } from 'next/router';

const RelatedSkillsContainer = ({ onClose, resume, updateImmue, relatedSkills, jobTitleToShow }) => {
  const [skills, setSkills] = useState(relatedSkills);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const { trackEvent } = useTracking();
  const {
    query: { step },
  } = useRouter();

  const handleSkillClick = (index) => {
    if (selectedSkills.includes(index)) {
      // unselect the skill
      setSelectedSkills(selectedSkills.filter((skillIndex) => skillIndex !== index));
      return;
    }
    setSelectedSkills([...selectedSkills, index]);
    trackEvent('related_skills_clicked', { skill: skills[index], view: step });
  };

  const handleAddSelectedSkills = () => {
    const skillsToAdd = selectedSkills.map((index) => skills[index]);
    addSkillsToResume(resume, skillsToAdd, updateImmue);
    onClose();
  };

  return (
    <Wrapper>
      <HeaderFlex direction="column" fullWidth>
        <Flex justifyContent="space-between" fullWidth>
          <HeadText>
            We found {skills.length} top skills for {jobTitleToShow}
          </HeadText>
          <CrossIconWrapper data-testid="close-button" onClick={onClose}>
            <CrossIcon />
          </CrossIconWrapper>
        </Flex>
        <SubTitle>Add these skills recruiters are looking for:</SubTitle>
      </HeaderFlex>
      {jobTitleToShow && skills.length > 0 && (
        <SkillsWrapper>
          <SkillsList>
            {skills.map((skill, index) => (
              <Chips key={index} onClick={() => handleSkillClick(index)} selected={selectedSkills.includes(index)}>
                {skill}
              </Chips>
            ))}
          </SkillsList>
        </SkillsWrapper>
      )}
      <ButtonsWrapper fullWidth>
        <NoThanksButton fullWidth onClick={onClose}>
          No Thanks
        </NoThanksButton>
        <AddSkillsButton fullWidth onClick={handleAddSelectedSkills} disabled={selectedSkills.length === 0}>
          Add skills
        </AddSkillsButton>
      </ButtonsWrapper>
    </Wrapper>
  );
};
RelatedSkillsContainer.propTypes = {
  relatedSkills: PropTypes.array,
  onClose: PropTypes.func,
  resume: PropTypes.object,
  updateImmue: PropTypes.func,
  relatedSkills: PropTypes.array,
  jobTitleToShow: PropTypes.string,
};
export default RelatedSkillsContainer;

const Wrapper = styled.div`
  background-color: white;
  max-width: 650px;
  width: 100%;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 12px 48px 0 #14141f3d;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
const HeaderFlex = styled(Flex)`
  position: relative;
`;
const HeadText = styled.span`
  font-size: 24px;
  font-family: Gilroy SemiBold;
  font-weight: 400;
  color: #262b33;
`;
const SubTitle = styled.span`
  font-size: 16px;
  font-family: Gilroy Medium;
  margin-top: 8px;
  color: #262b33;
`;
const CrossIconWrapper = styled(Flex)`
  position: absolute;
  top: -18px;
  right: -18px;
  cursor: pointer;
  flex-shrink: 0;
  width: 40px;
`;
const SkillsWrapper = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
  align-items: start;
`;
const SkillsList = styled(Flex)`
  gap: 8px;
  flex-wrap: wrap;
  max-height: 200px;
  overflow-y: auto;
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 10px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${(p) => p.theme.colors.gray.regular};
  }
`;
const ButtonsWrapper = styled(Flex)`
  gap: 12px;
`;
const NoThanksButton = styled((p) => <ButtonAtom {...p} />)`
  color: #1688fe;
  border-color: #1688fe;
  background-color: transparent;
  transition: background 0.15s;
  height: 40px;
  &:hover {
    color: white;
  }
`;
const AddSkillsButton = styled((p) => <ButtonAtom {...p} />)`
  transition: background 0.15s;
  height: 40px;
`;
const SelectAllButton = styled((p) => <ButtonAtom {...p} />)`
  transition: background 0.15s;
  font-family: Gilroy SemiBold;
  font-size: 15px;
  color: #1688fe;
`;
