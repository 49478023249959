import React, { useState, useRef } from 'react';
import styled, { css } from 'styled-components';

import ClearCloseIcon from '/imports/core/ui/assets/ClearCloseIcon';
import useOutsideClick from '/imports/core/api/useOutsideClick';

const HamburgerMenu = ({ children }) => {
  const [expanded, setExpanded] = useState(false);
  const ref = useRef();

  useOutsideClick(ref, () => {
    if (expanded) {
      setExpanded(false);
    }
  });

  const toggleMenu = (e) => {
    e.stopPropagation();
    setExpanded(!expanded);
  };

  return (
    <MenuWrapper>
      <HamburgerButton expanded={expanded} onClick={toggleMenu}>
        <span />
      </HamburgerButton>
      <ItemsWrapper ref={ref} expanded={expanded}>
        <CloseWrapper onClick={toggleMenu}>
          <ClearCloseIcon />
        </CloseWrapper>
        {children}
      </ItemsWrapper>
    </MenuWrapper>
  );
};

const MenuWrapper = styled.div`
  position: relative;
  margin-left: 20px;
`;

const CloseWrapper = styled.div`
  display: none;
  position: absolute;
  right: 10px;
  top: 20px;
  transform: translateX(-50%);
  width: 30px;
  height: 30px;
  cursor: pointer;
  svg {
    margin: auto;
  }
  ${({ theme }) =>
    theme.max('sm')`
      display: flex;
    `};
`;

const ItemsWrapper = styled.div`
  display: flex;
  box-shadow: 10px 0 50px 0 rgba(0, 0, 0, 0.15);
  position: absolute;
  right: 0;
  top: 25px;
  opacity: 0;
  background-color: var(--light-values-white);
  align-items: flex-end;
  flex-direction: column;
  justify-content: start;
  padding: 20px 30px 20px 30px;
  z-index: 110;
  border-radius: 4px;
  pointer-events: none;
  line-height: 0;
  transition: all 0.45s;
  ${({ expanded }) =>
    expanded &&
    css`
      opacity: 1;
      pointer-events: auto;
      line-height: 1.6;
    `}
  ${({ theme }) =>
    theme.max('sm')`
    opacity: 1;
    position: fixed;
    right: -100%;
    height: 100vh;
    top: 0;
    padding: 70px 60px 20px 30px;
    transition: right 0.5s;
    border-radius: 0;
    line-height: unset;
    ${({ expanded }) =>
      expanded &&
      css`
        right: 0px;
        line-height: unset;
      `}

    `};
`;

const HamburgerButton = styled.div`
  display: block;
  width: 26px;
  height: 20px;
  cursor: pointer;
  z-index: 1;
  top: -10px;
  right: 10px;
  & > span,
  &::before,
  &::after {
    top: 7px;
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #000f3d;
  }
  &::before {
    top: 0;
  }
  &::after {
    top: 14px;
  }
  ${({ theme }) =>
    theme.max('sm')`
      display: block;
    `};
`;

export default HamburgerMenu;
