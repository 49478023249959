import React, { PureComponent, Fragment } from 'react';
import styled, { css } from 'styled-components';

class OnboardingProgress extends PureComponent {
  render() {
    return (
      <Progress>
        <Fragment>
          <Tab isCheck />
          <Tab />
          <Tab isCheckRTL />
        </Fragment>
      </Progress>
    );
  }
}

const Progress = styled.div`
  width: 500px;
  height: 4px;
  border-radius: 2px;
  background: ${({ theme }) => theme.colors.gray.light};
  position: relative;
  margin: 22px 0;
  ${({ theme }) => theme.max('md')`
    display: none;
  `}
  display: Flex;
  width: 410px;
  height: 6.4px;
  background: transparent;
`;

const Tab = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isCheck', 'isCheckRTL'].includes(prop),
})`
  flex: 1;
  margin-left: 10px;
  background: #eeeded;
  border-radius: 3.2px;

  &:first-child {
    margin-left: 0;
  }

  ${({ isCheck, theme: { isRTL } }) =>
    isCheck &&
    !isRTL &&
    css`
      background: #4c7cf7;
    `}

  ${({ theme: { isRTL }, isCheckRTL }) =>
    isRTL &&
    isCheckRTL &&
    css`
      background: #4c7cf7;
    `}
`;

export default OnboardingProgress;
