const BackIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.1 13.7999C6.65817 14.1313 6.03137 14.0418 5.7 13.5999L0.75 6.99994L5.7 0.39994C6.03137 -0.0418873 6.65817 -0.13143 7.1 0.199941C7.54183 0.531312 7.63137 1.15811 7.3 1.59994L4 5.99994L15.5 5.99994C16.0523 5.99994 16.5 6.44766 16.5 6.99994C16.5 7.55223 16.0523 7.99994 15.5 7.99994L4 7.99994L7.3 12.3999C7.63137 12.8418 7.54183 13.4686 7.1 13.7999Z"
        fill="#7171A6"
      />
    </svg>
  );
};

export default BackIcon;
