import React from 'react';

import Content from '/imports/homepage/ui/atoms/Content';
import {
  getEjemplosCurriculumContent,
  getLandingContent,
  getCvMintaContent,
  getResumeNationContent,
} from '/imports/homepage/api/home-page-content';
import { replaceString } from '/imports/core/ui/helpers';
import useDomain from '/imports/core/hooks/useDomain';
import useIntl from '/imports/core/api/useIntl';
import { useResponsive } from '/imports/core/api/responsiveContext';

const getHomePageContentResumeDone = (t, plans, locale, getBrandName) => {
  return `
## ${replaceString(t('landing_title1'), { Resumedone: getBrandName() })}
${t('landing_subtitle')}

${t('landing_chap1_par1')}

${t('landing_chap1_par2')}

## ${replaceString(t('landing_title2'), { Resumedone: getBrandName() })}

${t('landing_chap2_par1')}

${t('landing_chap2_par2')}

${locale !== 'tr' ? t('landing_chap2_par3') : ''}

${locale !== 'es' ? replaceString(t('landing_chap2_par4'), { Resumedone: getBrandName() }) : ''}

${t('landing_chap2_par5')}

${t('landing_chap2_par6')}
* ${t('landing_chap2_par6_1')}
* ${t('landing_chap2_par6_2')}
* ${t('landing_chap2_par6_3')}
* ${t('landing_chap2_par6_4')}
* ${t('landing_chap2_par6_5')}
* ${t('landing_chap2_par6_6')}
* ${t('landing_chap2_par6_7')}
        
${t('landing_chap2_par7')}

${t('landing_chap2_par8')}

${t('landing_chap2_par9')}

## ${t('landing_title3')}

${t('landing_chap3_par1')}

${t('landing_chap3_par2')}

${t('landing_chap3_par3')}

${t('landing_chap3_par4')}

${t('landing_chap3_par5')}

## ${t('landing_title4')}

${t('landing_chap4_par1')}
      
${t('landing_chap4_subtitle')}

${t('landing_chap4_par2')}

${t('landing_chap4_par3')}

${t('landing_chap4_par4')}

${t('landing_chap4_par5')}

## ${t('landing_title5')}

${t('landing_chap5_par1')}

${t('landing_chap5_par2')}

${t('landing_chap5_par3')}

${t('landing_chap5_par4')}

${t('landing_chap5_par5')}

${t('landing_chap5_par6')}

## ${t('landing_title6')}

${t('landing_chap6_par1')}

${t('landing_chap6_par2')}

* ${t('landing_chap6_par2_1')}

* ${t('landing_chap6_par2_2')}

* ${t('landing_chap6_par2_3')}

* ${t('landing_chap6_par2_4')}

## ${t('landing_title7')}

${replaceString(t('landing_chap7_par1'), { Resumedone: getBrandName() })}

* ${replaceString(t('landing_chap7_par1_1'), {
    MONTHLY_LIMITED_PLAN_AMOUNT_TRIAL: `${plans.monthlyLimitedPlanAmountTrial}`,
    MONTHLY_LIMITED_PLAN_AMOUNT: `${plans.monthlyLimitedPlanAmount}`,
  })}

* ${replaceString(t('landing_chap7_par1_2'), {
    MONTHLY_FULL_PLAN_AMOUNT_TRIAL: `${plans.monthlyFullPlanAmountTrial}`,
    MONTHLY_FULL_PLAN_AMOUNT: `${plans.monthlyFullPlanAmount}`,
  })}

* ${replaceString(t('landing_chap7_par1_3'), {
    YEARLY_PLAN_AMOUNT_TRIAL: `${plans.yearlyPlanAmountTrial}`,
    YEARLY_PLAN_AMOUNT: `${plans.yearlyPlanAmount}`,
  })}

${t('landing_chap7_par2')}

${t('landing_chap7_par3')}

    `;
};

export const HomepageContentResumedone = ({ plans }) => {
  const { t, locale } = useIntl();
  const { getBrandName } = useDomain();
  const { host } = useResponsive();

  const isCommonLanding = () => {
    return (
      (host === 'resume-example' && locale === 'en') ||
      (host === 'lebenslaufschreiben' && locale === 'de') ||
      (host === 'mau-cv' && locale === 'vi') ||
      (host === 'cv-wzor' && locale === 'pl') ||
      (host === 'cv-skabelon' && locale === 'da') ||
      (host === 'cvminta' && locale === 'hu') ||
      (host === 'ejemplos-curriculum' && locale === 'es')
    );
  };

  const getContent = () => {
    if (isCommonLanding()) return getLandingContent(t);
    if (host === 'resume-nation') return getResumeNationContent(t);
    return getHomePageContentResumeDone(t, plans, locale, getBrandName);
  };
  return <Content>{getContent()}</Content>;
};
