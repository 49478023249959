import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import styled, { css } from 'styled-components';
import Flex from '/imports/core/ui/atoms/Flex';
import { useGlobalState } from '/imports/core/api/global.context';
import useTracking from 'imports/core/hooks/useTracking';

export const CircularLoader = ({ inSlider, percentage = 5 }) => {
  const { handleUpdateState } = useGlobalState();

  const { trackEvent } = useTracking();
  const handleOpen = () => {
    trackEvent('analyse_resume_score_popup', {
      status: 'open',
    });
    handleUpdateState('scoreAnalysis', true);
  };
  return (
    <LoaderContainer justifyContent="center" alignItems="center" inSlider={inSlider} onClick={handleOpen}>
      <svg width="0" height="0">
        <defs>
          <linearGradient id="gradient1" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="5%" stopColor="#FF6F5B" />
            <stop offset="50%" stopColor="#FAC05E" />
            <stop offset="95%" stopColor="#FF9CAE" />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgressbar
        value={percentage}
        text={`${percentage}%`}
        strokeWidth={5}
        styles={buildStyles({
          textColor: '#4a4a4a',
          pathColor: 'url(#gradient1)',
          trailColor: 'var(--light-values-light-regular-v1)',
        })}
      />
    </LoaderContainer>
  );
};

const LoaderContainer = styled(Flex)`
  width: 120px;
  cursor: pointer;
  height: 120px;
  position: fixed;
  right: 16px;
  bottom: 16px;
  background-color: var(--light-values-white);
  border-radius: 50%;
  padding: 4px;
  .CircularProgressbar .CircularProgressbar-path {
    stroke: url(#gradient1);
  }
  .CircularProgressbar-text {
    color: var(--text);
    font-family: ${({ theme }) => theme.font.family.websiteBold};
    font-size: 22px !important;
    line-height: 28px;
  }
  ${({ inSlider }) =>
    inSlider &&
    css`
      position: unset;
      flex-shrink: 0;
    `}
`;

export const Loader = styled.div`
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: transparent;
  color: transparent;
  box-shadow:
    0 -18px 0 0 #0050c7,
    12.727926px -12.727926px 0 0 #0050c7,
    18px 0 0 0 #0050c7,
    12.727926px 12.727926px 0 0 rgba(0, 80, 199, 1),
    0 18px 0 0 rgba(0, 80, 199, 1),
    -12.727926px 12.727926px 0 0 rgba(0, 80, 199, 1),
    -18px 0 0 0 rgba(0, 80, 199, 1),
    -12.727926px -12.727926px 0 0 rgba(0, 80, 199, 1);

  animation: dot-spin 1.5s infinite linear;

  @keyframes dot-spin {
    0%,
    100% {
      box-shadow:
        0 -18px 0 0 #0050c7,
        12.727926px -12.727926px 0 0 #0050c7,
        18px 0 0 0 #0050c7,
        12.727926px 12.727926px 0 -5px rgba(0, 80, 199, 1),
        0 18px 0 -5px rgba(0, 80, 199, 1),
        -12.727926px 12.727926px 0 -5px rgba(0, 80, 199, 1),
        -18px 0 0 -5px rgba(0, 80, 199, 1),
        -12.727926px -12.727926px 0 -5px rgba(0, 80, 199, 1);
    }
    12.5% {
      box-shadow:
        0 -18px 0 -5px rgba(0, 80, 199, 1),
        12.727926px -12.727926px 0 0 #0050c7,
        18px 0 0 0 #0050c7,
        12.727926px 12.727926px 0 0 #0050c7,
        0 18px 0 -5px rgba(0, 80, 199, 1),
        -12.727926px 12.727926px 0 -5px rgba(0, 80, 199, 1),
        -18px 0 0 -5px rgba(0, 80, 199, 1),
        -12.727926px -12.727926px 0 -5px rgba(0, 80, 199, 1);
    }
    25% {
      box-shadow:
        0 -18px 0 -5px rgba(0, 80, 199, 1),
        12.727926px -12.727926px 0 -5px rgba(0, 80, 199, 1),
        18px 0 0 0 #0050c7,
        12.727926px 12.727926px 0 0 #0050c7,
        0 18px 0 0 #0050c7,
        -12.727926px 12.727926px 0 -5px rgba(0, 80, 199, 1),
        -18px 0 0 -5px rgba(0, 80, 199, 1),
        -12.727926px -12.727926px 0 -5px rgba(0, 80, 199, 1);
    }
    37.5% {
      box-shadow:
        0 -18px 0 -5px rgba(0, 80, 199, 1),
        12.727926px -12.727926px 0 -5px rgba(0, 80, 199, 1),
        18px 0 0 -5px rgba(0, 80, 199, 1),
        12.727926px 12.727926px 0 0 #0050c7,
        0 18px 0 0 #0050c7,
        -12.727926px 12.727926px 0 0 #0050c7,
        -18px 0 0 -5px rgba(0, 80, 199, 1),
        -12.727926px -12.727926px 0 -5px rgba(0, 80, 199, 1);
    }
    50% {
      box-shadow:
        0 -18px 0 -5px rgba(0, 80, 199, 1),
        12.727926px -12.727926px 0 -5px rgba(0, 80, 199, 1),
        18px 0 0 -5px rgba(0, 80, 199, 1),
        12.727926px 12.727926px 0 -5px rgba(0, 80, 199, 1),
        0 18px 0 0 #0050c7,
        -12.727926px 12.727926px 0 0 #0050c7,
        -18px 0 0 0 #0050c7,
        -12.727926px -12.727926px 0 -5px rgba(0, 80, 199, 1);
    }
    62.5% {
      box-shadow:
        0 -18px 0 -5px rgba(0, 80, 199, 1),
        12.727926px -12.727926px 0 -5px rgba(0, 80, 199, 1),
        18px 0 0 -5px rgba(0, 80, 199, 1),
        12.727926px 12.727926px 0 -5px rgba(0, 80, 199, 1),
        0 18px 0 -5px rgba(0, 80, 199, 1),
        -12.727926px 12.727926px 0 0 #0050c7,
        -18px 0 0 0 #0050c7,
        -12.727926px -12.727926px 0 0 #0050c7;
    }
    75% {
      box-shadow:
        0 -18px 0 0 #0050c7,
        12.727926px -12.727926px 0 -5px rgba(0, 80, 199, 1),
        18px 0 0 -5px rgba(0, 80, 199, 1),
        12.727926px 12.727926px 0 -5px rgba(0, 80, 199, 1),
        0 18px 0 -5px rgba(0, 80, 199, 1),
        -12.727926px 12.727926px 0 -5px rgba(0, 80, 199, 1),
        -18px 0 0 0 #0050c7,
        -12.727926px -12.727926px 0 0 #0050c7;
    }
    87.5% {
      box-shadow:
        0 -18px 0 0 #0050c7,
        12.727926px -12.727926px 0 0 #0050c7,
        18px 0 0 -5px rgba(0, 80, 199, 1),
        12.727926px 12.727926px 0 -5px rgba(0, 80, 199, 1),
        0 18px 0 -5px rgba(0, 80, 199, 1),
        -12.727926px 12.727926px 0 -5px rgba(0, 80, 199, 1),
        -18px 0 0 -5px rgba(0, 80, 199, 1),
        -12.727926px -12.727926px 0 0 #0050c7;
    }
  }
`;
