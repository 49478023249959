import React from 'react';

const Quality2Icon = () => {
  return (
    <svg width="51" height="52" viewBox="0 0 51 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M42.5 4.33333H8.5C6.15279 4.33333 4.25 6.27343 4.25 8.66666V43.3333C4.25 45.7266 6.15279 47.6667 8.5 47.6667H42.5C44.8472 47.6667 46.75 45.7266 46.75 43.3333V8.66666C46.75 6.27343 44.8472 4.33333 42.5 4.33333Z"
        fill="#EDF4FF"
      />
      <path
        d="M25.5957 4.33333H42.5001C44.8482 4.33333 46.7501 6.27249 46.7501 8.66666V43.3333C46.7501 45.7275 44.8482 47.6667 42.5001 47.6667H25.5957V4.33333Z"
        fill="#C8E0FF"
      />
      <path d="M31.1098 19.9658H20.0811V36.595H31.1098V19.9658Z" fill="#5DDFA9" />
      <path d="M31.1096 19.9658H25.5952V36.595H31.1096V19.9658Z" fill="#37B982" />
      <path d="M31.1096 19.9658L28.6233 15.5675H25.5952V19.9658H31.1096Z" fill="#66E0B5" />
      <path d="M20.0811 19.9658H25.5954V15.5675H22.5673L20.0811 19.9658Z" fill="#EFFFFA" />
      <path
        d="M28.6233 15.5671L26.4133 11.6562C26.2327 11.3421 25.914 11.1796 25.5952 11.1796V15.5779H28.6233V15.5671Z"
        fill="#0050C7"
      />
      <path
        d="M22.5674 15.5675H25.5955V11.1692C25.2768 11.1692 24.958 11.3317 24.7774 11.6458L22.5674 15.5567V15.5675Z"
        fill="#0866F5"
      />
      <path
        d="M30.9082 36.4862H25.5957V47.6554H30.9082V39.8121C30.9082 39.2054 30.4301 38.7071 29.8457 38.7071H30.9082C31.4926 38.7071 31.9707 38.2087 31.9707 37.6021C31.9707 36.9954 31.4926 36.4971 30.9082 36.4971V36.4862Z"
        fill="#378EFF"
      />
      <path
        d="M20.2832 36.4867C19.6988 36.4867 19.2207 36.985 19.2207 37.5917C19.2207 38.1983 19.6988 38.6967 20.2832 38.6967H21.3457C20.7613 38.6967 20.2832 39.195 20.2832 39.8017V47.645H25.5957V36.4758H20.2832V36.4867Z"
        fill="#378EFF"
      />
      <path
        d="M21.3457 47.515V43.3442C21.3457 42.7483 20.8676 42.2608 20.2832 42.2608V47.645H21.3245C21.3245 47.645 21.3457 47.5583 21.3457 47.515Z"
        fill="#0050C7"
      />
      <path
        d="M24.5332 47.515V43.3442C24.5332 42.7483 25.0113 42.2608 25.5957 42.2608V47.645H24.5545C24.5545 47.645 24.5332 47.5583 24.5332 47.515Z"
        fill="#0050C7"
      />
      <path
        d="M26.6582 47.515V43.3442C26.6582 42.7483 26.1801 42.2608 25.5957 42.2608V47.645H26.637C26.637 47.645 26.6582 47.5583 26.6582 47.515Z"
        fill="#0050C7"
      />
      <path
        d="M29.835 47.5367V43.3658C29.835 42.77 30.3131 42.2825 30.8975 42.2825V47.6667H29.8562C29.8562 47.6667 29.835 47.58 29.835 47.5367Z"
        fill="#0050C7"
      />
    </svg>
  );
};
export default Quality2Icon;
