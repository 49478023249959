import React, { PureComponent, Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import AutocompleteInput from '/imports/core/ui/atoms/AutocompleteInput';
import Flex from '/imports/core/ui/atoms/Flex';
import { getCharacterLength } from '/imports/core/ui/helpers';
import SearchInput from '/imports/core/ui/atoms/SearchInput';
import SvgIcon from '/imports/core/ui/atoms/SvgIcon';
import { withIntl } from '/imports/core/api/useIntl';

const splitOption = (option, color) => {
  if (!option || !option.includes('[') || !option.includes(']')) return option;
  if (getCharacterLength(option, true) !== getCharacterLength(option)) return option;
  let parts = [],
    start = 0,
    end = 0;
  while (option.includes('[')) {
    start = option.search('\\[');
    end = option.search('\\]');
    if (start === 0) {
      parts.push(option.substring(0, end + 1));
      option = option.substring(end + 1, option.length);
    } else {
      parts.push(option.substring(0, start));
      option = option.substring(start, option.length);
    }
  }
  parts.push(option);
  return (
    <span>
      {parts.map((part, index) => (
        <span key={index}>{part.includes('[') ? <span style={{ color }}>{part}</span> : part}</span>
      ))}
    </span>
  );
};

@withIntl
class SearchBox extends PureComponent {
  static propTypes = {
    area: PropTypes.any,
    data: PropTypes.object,
    updateUnchanged: PropTypes.func,
    onDataSelect: PropTypes.func,
    onDataChange: PropTypes.func,
    areas: PropTypes.array,
    simpleSearch: PropTypes.bool,
    hideSearchBar: PropTypes.bool,
    lastJob: PropTypes.string,
    search: PropTypes.string,
    unchanged: PropTypes.array,
    onSelect: PropTypes.func,
    t: PropTypes.func,
  };

  state = {
    searchValue: '',
    open: true,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.area !== this.props.area) {
      this.forceUpdate();
    }
    if (prevProps.data.length !== this.props.data.length) {
      this.props.updateUnchanged();
    }
  }

  onChange = (e) => {
    this.setState({ searchValue: e.target.value });
  };

  onAreaChange = (area) => {
    const { onDataSelect } = this.props;
    if (onDataSelect) onDataSelect(area);
  };

  toggleOpen = () => {
    this.setState((st) => ({ open: !st.open }));
  };

  renderSearchBar = () => {
    const { searchValue } = this.state;
    const { onDataChange, area, areas, simpleSearch, hideSearchBar, lastJob, search } = this.props;
    if (hideSearchBar) return null;
    return simpleSearch ? (
      <SearchInput value={searchValue} onChange={this.onChange} />
    ) : (
      <AutocompleteInput
        options={areas}
        defaultvalue={area}
        onSelect={this.onAreaChange}
        onDataChange={onDataChange}
        area={area}
        areas={areas}
        lastJob={lastJob}
        search={search}
        showIcon
      />
    );
  };

  render() {
    const { searchValue, open } = this.state;
    const { data, simpleSearch, hideSearchBar, unchanged, onSelect, t } = this.props;
    const filteredData = simpleSearch
      ? (data || []).filter((p) => p.text.toLowerCase().search(searchValue.toLowerCase()) !== -1)
      : data;
    const renderData = filteredData.map((p) => ({ id: p.id, value: p.text }));
    return (
      <Container>
        <HelpHeader>
          <p>{t('generator.description_example')}</p>
          <span onClick={this.toggleOpen}>{open ? t('close') : t('open')}</span>
        </HelpHeader>
        <HelpContent open={open}>
          {this.renderSearchBar()}
          <Help fullSize={hideSearchBar}>
            <SearchList data={renderData} onSelect={onSelect} unchanged={unchanged} />
          </Help>
        </HelpContent>
      </Container>
    );
  }
}

class SearchList extends Component {
  static propTypes = {
    data: PropTypes.object,
    unchanged: PropTypes.array,
    onSelect: PropTypes.func,
  };

  shouldComponentUpdate(nextProps) {
    for (let key in nextProps) {
      if (nextProps[key] !== this.props[key]) return true;
    }
    return (
      this.props.data.length !== nextProps.data.length || this.props.unchanged.length !== nextProps.unchanged.length
    );
  }

  render() {
    const { data, onSelect, unchanged } = this.props;
    return data.map(({ id, value }, index) => (
      <SearchPhrase key={index} id={id} selected={unchanged.includes(id)} value={value} onSelect={onSelect} />
    ));
  }
}

class SearchPhrase extends PureComponent {
  static propTypes = {
    onSelect: PropTypes.func,
    id: PropTypes.string,
    value: PropTypes.string,
    selected: PropTypes.string,
  };

  onSelect = () => {
    const { id, value, selected, onSelect } = this.props;
    onSelect(value, id, selected);
  };

  render() {
    const { value, selected } = this.props;
    return (
      <PhraseCont onClick={this.onSelect}>
        <Decoration selected={selected}>{selected ? <SvgIcon.Minus /> : <SvgIcon.Plus />}</Decoration>
        <Phrase>{selected ? value : splitOption(value, '#1688fe')}</Phrase>
      </PhraseCont>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  font-size: 12px;
  padding: 15px;
  ${(p) => p.theme.max('xs')`
      padding-top: 0;
      border-radius: 3px;
      border: solid 2px #e6e6ff;
   `};
`;

const HelpHeader = styled((p) => <Flex alignItems="center" justifyContent="space-between" {...p} />)`
  ${(p) => p.theme.min('xs')`
      display: none;
    `};
  & p {
    font-size: 14px;
  }
  & span {
    font-size: 11px;
    text-transform: uppercase;
    cursor: pointer;
  }
`;

const HelpContent = styled.div`
  // transition: all .25s;
  height: auto;
  ${(p) =>
    !p.open &&
    css`
      ${(p) => p.theme.max('xs')`
        height: 0;
        overflow: hidden;
      `};
    `}
`;

const Help = styled.div`
  overflow: auto;
  flex-grow: 1;
  padding-right: 10px;
  height: 210px;
  margin-top: 10px;

  &::-webkit-scrollbar-track {
    border-radius: 2px;
    background-color: ${(p) => p.theme.colors.gray.light};
  }

  &::-webkit-scrollbar {
    width: 3px;
    border-radius: 2px;
    background-color: ${(p) => p.theme.colors.gray.light};
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #1688fe;
    height: 100px;
  }

  ${(p) =>
    p.fullSize &&
    css`
      height: 250px;
    `}

  ${(p) => p.theme.max('xs')`
    padding-right: 0;
    height: 350px;
  `};
`;

const PhraseCont = styled((p) => <Flex {...p} />)`
  margin-bottom: 10px;
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 12px;
  cursor: pointer;

  ${(p) => p.theme.max('xs')`
    flex-direction: inherit;
    background-color: #f6f6f7;
    padding: 0;
    align-items: unset;
  `};
`;

const Decoration = styled((p) => <Flex alignItems="center" justifyContent="center" {...p} />)`
  width: 16px;
  height: 16px;
  background: #1688fe;
  margin-right: 9px;
  border-radius: 10px;
  margin-top: 2px;
  flex-shrink: 0;
  color: #fff;
  position: relative;
  ${(p) =>
    p.selected &&
    css`
      background: #7171a6;
    `}
  & svg {
    position: absolute;
    top: -2px;
    left: 4px;
    width: 12px;
  }
`;

const Phrase = styled.div`
  flex-grow: 1;
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 13px;
  line-height: 18px;
  color: #7171a6;

  ${(p) => p.theme.max('xs')`
    padding-right: 10px;
  `};
`;

export default SearchBox;
