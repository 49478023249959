export const getArticlesImages = (host) => {
  switch (host) {
    case 'cvtoolspro':
      return {
        delete_account_1:
          'https://lh5.googleusercontent.com/812hW6F9AznvRyZVcKx5xtOcAfGm76jHrP2lUadWat5O9E4_6FjMH4iyotWPo-8tqDKQ_SGZiR6f9LmHjJi2s21t2oilYTEGlIVDpL3lUxpBNI09J1DoEEAH1juzj0EXkOn0ioHUpp_mF9XkgmgGLOY',
        delete_account_2:
          'https://lh5.googleusercontent.com/-z-Sqi7_UNyinCIJluxZSzgI2O2JgaR_iFyr-LtArchnsa685P_3JIpR0crKesjCHnD6YqZDgSehdASCVGzeJj1skZYAPg358TAyazjIrdq_-8bLxESAXHyAsd64V4mhq1Ueka6uo2r4ttjr-9kjiBE',
        cancel_subscription: '',
        change_resume_template_1:
          'https://lh4.googleusercontent.com/vgT-Ko3gm09-ycRIuLd5UbwsQlWK1NAO_dk6lFUpyOreFEc4UEU0dPv0PoLcTRe7w27RrmESJqJBE7WqvFItmjIuz-rvWlEZE6azzxwZGaaJs-I2dCYWvB73NMARaOSLbtn_ia-zYbA6EjjMpO5cB_Y',
        change_resume_template_2:
          'https://lh3.googleusercontent.com/lxHJpvuB4d7toKzaAd2EsQlnLCkKIIOhXJKopnM8d5sahCE_O3fxhmeVKqMeRD0DwZL9AIE84OoPVxSt4ySB3jEs1xzwE1E5l7qMEw7v1Uae58TLjPNGPCbZjx9ras7iB3tEb1Qt6hfInqmTZA8xdTM',
        add_photo_1:
          'https://lh3.googleusercontent.com/0IeHI9V6z0GBQ6O7Jq97A6caFotqokjLL421H9gAEkEtIfSlVSXIWYb0xjcaneI_ucNXvFlDG9jEuDW3Cnws8RtTvpN6Da-TzZ15-NVx2mkUx-hNHkcxwBwxC1rItBZTIjUi5VFCJBDpEjTgwQ2CV54',
        add_photo_2: '',
        duplicate_resume_1:
          'https://lh6.googleusercontent.com/v2CZXB7TMxIFoOhFtsNAvtFU-U_hE-xMgx3xkQLTc6PFO8iCF4-FSDvKKmZjr6l4GqdkIvmTK4aiuKQELZMYJR2X3D5dUjR0N2qROmarf_LJESEbXtgMbZ5bAvWMMJmA_MWfBuz-qZLDBSZReHpv2cg',
        duplicate_resume_2:
          'https://lh6.googleusercontent.com/SSqAdkhSYWYyLbUP9zyZ3H8UdFm_eeHjSLt3k2iJwe4fEhdodR3OUsI2XkBT2xon_k32QImdi1Ju9TSH2d1G867-DTx7hFDux_A4mVAxD2-ibwAA2gNE_hLw9Ra7oL3cV7IsHNHyVE-Nl_jEy814_cA',
        duplicate_resume_3:
          'https://lh6.googleusercontent.com/jO1QIEBjK1YCCXFI9ouHQSuUZAzGhc9mDZGhZS6rNMPhE6o8F7ZrQlCr12gYU3lb00pZPCE08RuoN4ctfgESbBvXTG_wI9fqJEoMi46HZcjB-HxZkHEw2xH9qYu5TYMikYR8BjdQrsRPuwuxaYpXRVQ',
        access_cover_letter_1:
          'https://lh3.googleusercontent.com/VL3XsYEwEdleIThiRWsB552Tqop5VT47GcnXAdPoBACN_JS2jLbCm5lI6VYs_uknhv-d1rbq_k7dG9fuGboHgR8iEfF0AWfMVk7ydyjzI1ayTC-tPKbthMddBhuM3GNKIAWGlPXWV4Rk7tNA5DW77xc',
        access_cover_letter_2:
          'https://lh4.googleusercontent.com/M1eEDUYFlTy_IVL0ys7H4BAMnSIUYqAiqCnyLz7O6CAhxWZ6JZqtNL85pqRGnypK3gwo3jrNiNHSTArNIHGzHRgOxhMtHqEuC2wbf7ASLB80X4ULiYh3LlAiEjlm4JwaGZkMGtjVwXhiM5hSY_gPMzo',
        word_format_1:
          'https://lh3.googleusercontent.com/gwlYvjiv_kHOXK1ZJRoJtzIlSDshLqQrH-NCf3aexSUiwHOpjl7GdciPaVPPt8hpXoi2qrtKdZHvysnQAqfUEW7ez5XpotZom6PyCLp1fjVbI3yyU_ZChwtqzXYx-jer5VSEq0C818V6pxXtcwFkVDs',
        word_format_2:
          'https://lh5.googleusercontent.com/zRuYy4YN115-2S-avoLHeUK2ipxQNzMjjGv21eN9bQEWzPsCiJlPA3Y6b2ReAhx35tBqu2Ga-oil1cBkqWsfW_zuLCe2frXKPSxMmRz_233_w_hMh1X24BeKlxO_XOW5MXD8CM7QVkVV-j9vGSotssY',
        customize_resume_1:
          'https://lh4.googleusercontent.com/psqAYCcP9JoqfZp9xoj71L-i0SYkNrUZ3J1dhuH4i0cQwwXK92J2Unn4ONS2obGT2D2uWSAWcXlxOF7FA5s7tedFW1bUkShBpzlXE4Sw8rXNwjLSofuDuDEiXlUYs13sMi3oBdgZhZf_YvKpODJxW24',
        customize_resume_2:
          'https://lh3.googleusercontent.com/lxHJpvuB4d7toKzaAd2EsQlnLCkKIIOhXJKopnM8d5sahCE_O3fxhmeVKqMeRD0DwZL9AIE84OoPVxSt4ySB3jEs1xzwE1E5l7qMEw7v1Uae58TLjPNGPCbZjx9ras7iB3tEb1Qt6hfInqmTZA8xdTM',
        customize_resume_3:
          'https://lh4.googleusercontent.com/U_fLGQ-FNEPipDS_NBdqI2ULcWUQf_HHCHDSALO-GdCLEbYY3NpUiFMAoHfK4PN-OBpbW6WZ_m2doYvt9oTYYyjyqVPeHfZeY6hxcJqHpvF7QirJFzJCzvOLbmvXNk0H49CRHFon1i6GghWlumtJLeM',
        customize_resume_4: '',
        customize_resume_5: '',
        change_language:
          'https://lh4.googleusercontent.com/R7XPxAp5ArS-Gt4pI8TZ97iP_qzYxIbQh5g2zahaN-cZBr0M1FLudT5MITVizIbkSaBlmjeHSxH0uY-Rhh-8dffvf8I5TEiDtegv-dBlLK3n0zClgDzFeK9uUdPX7P050fW0DF1mHywzexOjQJ-tDk8',
        change_font:
          'https://lh3.googleusercontent.com/lxHJpvuB4d7toKzaAd2EsQlnLCkKIIOhXJKopnM8d5sahCE_O3fxhmeVKqMeRD0DwZL9AIE84OoPVxSt4ySB3jEs1xzwE1E5l7qMEw7v1Uae58TLjPNGPCbZjx9ras7iB3tEb1Qt6hfInqmTZA8xdTM',
        change_section_block_1: '',
        change_section_block_2:
          'https://lh3.googleusercontent.com/PmylJbwF6JU8qXWCr1J4emhNqFABttv0y8r8ITPet_Raz3QSFDcOXZVmuj_rMRiLl2AfEEgpDP2wTwfgUgcpjRHaif9NU2GW1AkO4woFpV9qPynS4FeU_OkSb5JKZ1kV9dOsdBMNNajjrWGlOrLwQU0',
        login_1:
          'https://lh4.googleusercontent.com/6aDS834mlSjsggs5Pa9AHeUh76et0U-gZroUgyz_84ddUawKQyucxDIrTqXO3Y-s9gkluIwcChMM2X9dhPaSIiXYjz5nYXxpnIxf72daRKgm1PobAgrI8T1twRWvbjUbDaY8QcF4dqlah3Di6RdiEHQ',
        login_2:
          'https://lh4.googleusercontent.com/YXKaflbKNPdxILYTDXlNhcn-G6Oy9jO_xH0usm25PAngpHlm3DAkAD36KEkCiRcKM05g5mdZ55cslhQpBf7BEpvRRrLhDpUFuuz1sxpZHIzE0-UZSeOkrpV93DwTtMfUcfaEBR1KsPRtzbhxKJ2kpx8',
        login_3:
          'https://lh6.googleusercontent.com/ysxAksJmqVZ7T9qJ_CuhCp7O3o4ju_h57wk8DuUPT9zO3aFChPVMLofNAMcX6vU60qIelr-jAOj5i0Qy9N9h1-ERxDGkdfh6YrVkoZLK68ssu8fVgQcPHMYNgwP-tmrAzghcppeSc9Aj1BNE4z1anm8',
      };
    case 'resumizeme':
      return {
        delete_account_1:
          'https://lh4.googleusercontent.com/1cyHjEExRWFyRR1ZIJdWYsz1ri1uZ6TkoTn_BBBMxJUnqLk2yKlGUxky8su7rf-402A00dNiv8qK7y47EASp6-VOGcioIZhgRgnAh9VY-Gd92eoYfHCbcZ1pBA-MdpB-a9vaCCbKe8RA6qn-Wqb5BVE',
        delete_account_2:
          'https://lh4.googleusercontent.com/4jHCYoqlt2lWd8k8JNNVBGYSMog6vbci3ib3adl4_BXIuTnX4FSFSnfzDsRiMHKDTcqBIQnwFJWyK3nZtDSduyv0QRrwMdj_XHsYwIRD2qgGkAf7uPk3Fwe7lF03OsrKBxWxiOFiif_nJK3DbkkE1Nk',
        cancel_subscription:
          'https://lh6.googleusercontent.com/qQ9J3nT2-Pn_IsQAJc8DkpzWOK-A6mVvUAwCbxEDD-jckjrokRR6qn6-4vY7NQz0BTsGQDRYiRRVHWeZhnz-LtW2JwtEc82AALnwTbnPIxTWjlNVoUE59HnYpQrBqWNsBUYOCD6_RRNzL1WRZ3hQkUA',
        change_resume_template_1:
          'https://lh5.googleusercontent.com/OiXzcGASnCFBVkptTqoU4o5mx_28i3_FhLVrYzsIKAgP-wrg7eWaTQ9ephrV82ZUVhu-t7fX73HKLeTcj8pWcxcPghFHgwf9tdx4Xjxeeh_WJJh9KWHzoBNv7_yE1fnnE6NXj13CZf7U7pt7fMgZMLk',
        change_resume_template_2:
          'https://lh6.googleusercontent.com/gSdMQfK0FmdoJQuuZh8g2NEF5Hp7f9gJUjxdSTwiG3fbV9nK4PUelbjFggvfEZT57whz4_zboUU_XXyhxOWztUfzQiME2Ey0yK2yIh4ZzgMs-nz8W88NJrm5xGP-mgeT63TlNVLQuBh9z12ldSYXoP0',
        add_photo_1:
          'https://lh3.googleusercontent.com/PEo4VDGQ3SZs83PdadlBAGMtSp8l9geK5uk1kv0dGNn2gb1Pswj3WqadTlfuMRG2KIGeuN5-GjyoOA8EyDKyVrMnpJ92NSPcOadoloEF46lcrqNWlSsvtvZON99SBCT9qAALpQmtvMO_t3WAZKs4krY',
        add_photo_2: '',
        duplicate_resume_1:
          'https://lh4.googleusercontent.com/ukHMuwza_sM0ud7vXsYEru6pMOwRY0fw87AMUyXQj7bcpqzituSxP26abdfUqIe3yci-0g9mwtzyRkVMupJYFVPOub_KDZWknXqAMH4EL3VNX8sXQt1W1JMWVMvlJylh2ryE52mM0Kt11usFJrl44C0',
        duplicate_resume_2:
          'https://lh5.googleusercontent.com/F9CD_borQ1mOfNE9DkpigALZZ9cewgM8ePhGrJCkUz-GW9bXRN66idSxtD1kqL-b96pX19a8FbB_6Ucm0j37mm95rZkU6sCt4WgrsxZv1I6ziquFI9LK0JVuG37kVpfxC8EOEnSBvoGQXzan2pRrVkY',
        duplicate_resume_3:
          'https://lh5.googleusercontent.com/VnJ6kAoptbNJfDW7BZcDTXi9qDhrebuzsmrde1jtHpmcB2DIon_5qqvxXvR_CBZX0DgTHtPzfBzZDp1nQ17vkSzO_mGcJIJabrl-Wwm0ss1VcOdRoKr8tHH5po63hJaTAVkVTPvPpw5e9r_69jc2YA8',
        access_cover_letter_1:
          'https://lh4.googleusercontent.com/c1QkyP11OJhaenzrjA9ZS3cgFtcEvVUVYw7EL7CETcazcwPEebbpayYmmEaC7QGDDlAT6dhjvTqzv4m2PWsjiDFIB-2FDl_NEJfqjL2qSa66Ob9J1qZ9sTHDnVypYSB4n8aPuWlFuyo935gNucG9YxA',
        access_cover_letter_2:
          'https://lh6.googleusercontent.com/BLMQDnlOL7KrliYHxCrxnfolWx5HcCUdN0tRXXiqbxaUEZ8_BMjxApRXH6CsZDY-rOTrPkVqkhiRBmIUDDCtr5qCXc4elWsLP1fob8jtxVj2BuI6FC9ghjqY9F4ixoVZPeO24vOZD3h5sFc32iBPcUQ',
        word_format_1:
          'https://lh3.googleusercontent.com/s4qBdUEKdr6YtDf3r9JanFo6gcDFGShA02abgiYo741D7pZqOa10zA6W6RKBiYLYBRhpYW2VJbvC67NPzSvfwlOP89WG9Bm3Pe4Q506ECa_TyLj3VMG3yhCXdi0UMCIGCxwSfASaijmyTVIZrXj6eOo',
        word_format_2:
          'https://lh5.googleusercontent.com/1OnnUxp8x5lmA0GEqP2ZuzN613GBOWWQ-kaoRoZgBBy3tPyqtYaeSHkM4MtJv1fYPNFCza9algLO68t-gZZqMfvfsCtK2OG3TiTy03xGlMDBhi9AFufNCmmb5VIeVNAw5heumBy6VMAPSnVWyFcjjPI',
        customize_resume_1:
          'https://lh6.googleusercontent.com/VC1CI8jWxgB7nQlP-81yxiO2sqmVq4Dg1vWfCmFC_hT8dU3k5Quh0VQRnVHLoKNaMyGQlv69k6bNnjvuZVI7vr7nVLjuBBlSjBx2qI_JsOM9ZVPMnIMYgSyDu-sSzmXiuW0SwsoRYTZ7P3_OtTULfMk',
        customize_resume_2:
          'https://lh6.googleusercontent.com/gSdMQfK0FmdoJQuuZh8g2NEF5Hp7f9gJUjxdSTwiG3fbV9nK4PUelbjFggvfEZT57whz4_zboUU_XXyhxOWztUfzQiME2Ey0yK2yIh4ZzgMs-nz8W88NJrm5xGP-mgeT63TlNVLQuBh9z12ldSYXoP0',
        customize_resume_3:
          'https://lh3.googleusercontent.com/5X4rrAmGTU0KbDDhqNL1qEkfqe4VUrh3eXfcHrIq_GydR3iX2cj1ySq2wTi7I85eMMGkivll1yhZMg1h7L0SZsEWNsPA8V42FHgby0hwvtwlj10dlKgMHoEOMsU0yfFmj5-Pf7yEAS1jvYVxAg-HBrQ',
        customize_resume_4:
          'https://lh3.googleusercontent.com/9oWZMc3wohLC8LWDTLcGJHU4B3fH6zLXnhy5BNhdfzF6AsQbtB2RZG_oCY_-Tn0QajxP_4hPTo7K1R22W-PysVWZmBzcn9052Vxtc71g_npaAQDXgK3IPib0RALJUu7WRFaq_YvCgGr8UNFfWxUfrMo',
        customize_resume_5: '',
        change_language:
          'https://lh6.googleusercontent.com/IcevO5X6pSTUkYavmdYbCkpQW6kiv93lYUkIjBChsh_4JAKsWPWrOSiify-Yl_Ir3GhQAhZz9HRq91lx1VYxXcvmiXP6P4nyaJ38cRTXWjkhPGosXk0oxA-UHvMgh6JjOjHoac_gIFqffq1ISMLixGo',
        change_font:
          'https://lh6.googleusercontent.com/gSdMQfK0FmdoJQuuZh8g2NEF5Hp7f9gJUjxdSTwiG3fbV9nK4PUelbjFggvfEZT57whz4_zboUU_XXyhxOWztUfzQiME2Ey0yK2yIh4ZzgMs-nz8W88NJrm5xGP-mgeT63TlNVLQuBh9z12ldSYXoP0',
        change_section_block_1: '',
        change_section_block_2:
          'https://lh3.googleusercontent.com/YyNlsNHbGfrMeGS_MsAPRlU-nI9M-AAftG3OqM8xup25diLXcjYVCAG-F__JD7L1SkHuTn1W24EimHhG6pDLhxGfJbho4MdUMhkNd4UarhJzNV2PPQYkZxMDQqFcl9WIlmzbJdV6s7T_9iQwebk4ZTM',
        login_1:
          'https://lh6.googleusercontent.com/QdrspBMidL9rubfHFsq_WTBGIEdIEgwqM1bz5rpmNqnyN4K0M8XYPIw0Mdyf7LUbSc1B638-2rSxPcqA6-BSreN87CaFX4MCfd4bAUwE3jb5e2iRMBZ1QR0Uc4j5wCFUTfqQtYShhtXPIaUoUcfw6nc',
        login_2:
          'https://lh6.googleusercontent.com/ic9B2K4xdmnPCgfyfp5NPM5HmpoPywGdaz9X-pcVHb-v2Uuq3AveJxpNHFYrz95_i0o6lolhjScmdm8n_kwQ3pV0DTgwjFFmE2QhCShaooAPcatvYte3HQm3kumsXNsmUwBOOltS_BMKcjKMLzpscI0',
        login_3:
          'https://lh6.googleusercontent.com/0WHqAYr3-9Pn-s5FoLKPAOgZjVn42bpZb1ODKxzAhefAbCTZNzmB2EMcEzU7ow302zm4nrYKpz639Lzoyzd0kk2t3nUifWEnBfDfWuCnje9sU9qDARM9-Z6OzQzMToF4POOkVMZwDN8bAz-IQ4JM4kc',
      };
    case 'cvkoostamine':
      return {
        delete_account_1:
          'https://lh5.googleusercontent.com/lAbMC6OxXfpGpBRVPUHahnCURp3YXxc5IiBf0kSMsjjLxwAGf9IxBOYc2EkcIJVZpKqASwjPFGJw-gB9_D014xa6Hfxx_9SKnJ3jxiYHK24ylS3TGRdws1BgXwMHifEtxiymuhD8Ts2or2q3duozX2U',
        delete_account_2:
          'https://lh4.googleusercontent.com/UP-Zsj9-iMaFAkEyL7CEbuuU_7JYiyrQN-pki60_B1ySCsUY1TV9O3KJ8ZwuTBevs0AGYKNi_Sk7kUJnUQ51DeCTwccdqj_GgkOWNIaud5SqVLblRLDOC8W5DgtEzTl_S5aNlLkw_zdG6JefsLxqSPE',
        cancel_subscription:
          'https://lh6.googleusercontent.com/a-igojNc4pp72TCNBgaLL1gVuSHvO4Cgp-N0wshb2XcU3X03x39MGUe8mlgRfP9Y7882fpDlovdzuN0OsZYANE7q0xXGDP-ZpbJMfGkTazn7imOo-y0bclAKriyLBcGOPbMbXa5oVjLzH2PO8REc22E',
        change_resume_template_1:
          'https://lh5.googleusercontent.com/92vvLNpEpkFM55mJd-C-MAutWk1TzsVObbp0sgYtrlX54jgjngkfHjdglO_ufgHvOJXVTdPPdPJ8hmxetrMJTkRXjBzrG2b14GBODDwxZpgRaHlY13UqdoUpCFyMYTxiydYarL3BSbPFTY3L1XRxD88',
        change_resume_template_2:
          'https://lh3.googleusercontent.com/WV-KPR48vK9M8v0mbKcD1lyifwGy7DTabiVV92pwvCP1TWv-n7EyqKR1acQQk_eRawmw1i6lT4EutAhaBsvikxtBxQ6I7TUv-UrPmPuCrboXBFRKnIotiOPNUGvLkea2m6yenGHdyeUGb6PGVs-tyuo',
        add_photo_1:
          'https://lh5.googleusercontent.com/c6BkhfIH3RtAlS4qi-45lHSOmFGzYJqm7sId_hfag0cMDbP4D6IX24ATuDTs6tEsdoactvBKoXanXncxXTO-5bx7W7NWf4nIyruSTpknzdiaLb3qAHnm5aY5EFmbPpisDtldZ6YwbxV4KocuLlQ7WUg',
        add_photo_2: '',
        duplicate_resume_1:
          'https://lh3.googleusercontent.com/bBajcGipuG5E4wY6uevTTe__MPBa9D7K2D6DVPy87WpPA-5YM3e2Cuv1QYWrg4Ad5EK3OPLXXdZKLQP5T_R0ovo23jJex55oE41-ddtDLN7axT2i29dvER-jvzUsebux7AcN3NlDyzlo5wHSVuW3dmc',
        duplicate_resume_2:
          'https://lh6.googleusercontent.com/pbm5dJNv4r_2ABO1liO4rRDqmnZhX9SahYYtwuZptavF5pTq5OsYPKBTKIrp3NjQ3_ht1f_wFbMSGrCLBwvh0OT07xBYjx8f-v8hs-dh6dn1Zn2mMdJv_9VPStpN8f5gMsARvb_7Hs3MyT944rihZRA',
        duplicate_resume_3:
          'https://lh5.googleusercontent.com/EyE9a5pGHxhwEoxjW3xHy14SuT1JZFO2zFxoWD0DW_uOkNrY3lUMqOdwTHpAwqfdBILxpLbdcejP5FwBCF6SW5thXoMfotrNbTVSKxASTo-KzdDt6q_ea-ZVQNf0f5CiXBIlsgIHRbhdtcCBqPqVeX4',
        access_cover_letter_1:
          'https://lh6.googleusercontent.com/-DQKit_TKGVl3yE3dPaBuvVoRvOYTJLqzfhhWt-vx55RTS10bXgfUlrtnnipzV9vHWN2UUZyDBRjlLeMxWQFYZLdzbz8zYKBDHCKRv2cKEVsmvjzT1enwCGpxTvv8VmA7aL2Q-7m2A_p7LejLS5BGXk',
        access_cover_letter_2:
          'https://lh3.googleusercontent.com/WndDUL7MaBxg6df2LETH2v-XPSRW781-loup0FUUwmSfT5lNOpvH7KpQJe8rvsRtT5upwhFBh27xzfxOB9g7UkPsrdq-gHBCR4DZf2x0vOwvCzQ2tb2KVnr5yp0BhBtvXFNaUnV0XGjnEznMmVCRiJw',
        word_format_1:
          'https://lh5.googleusercontent.com/1ibKLFmd49VwzRDn2u_y2rUcZdDc6inS2cuyH-jvn9IBv9yKTSDbi-c8lWJwY_5DFpnKfQJu2mJaFhf89lyPYoxeDBy8F6ifLdtaMximAjpenQ1O14RCNRwP64VRMSbxlZn0VXbtcmbO58g80eKUngo',
        word_format_2:
          'https://lh3.googleusercontent.com/Bm6rSwfwcbqCCiICbvouueYT7EPrmiyJTHToXZrYstrNsCn5T8XS10p_F34a65tGpbUBYLE-4SltAkcMMK_sTt2ZbgGClH8MWRaCk8tDnEpeTg7v4gxUdMRSABm2ljYufhjIDMw9LlC6EnhQSCk5h4E',
        customize_resume_1:
          'https://lh3.googleusercontent.com/jAwb4AMQ7befnN-VmXJB_SD5aAQJgrS9r1zwQSlzEg08wFt0kbjWI4seiG3DJGvvHTQQmwvE6DqX6fcHi1H4G9qqLxqocFJrnmVjGgFX6-hu-9SjxNfoCWZzs7wVpXEGcWUxAScnHCBrOTAV-SjITao',
        customize_resume_2:
          'https://lh3.googleusercontent.com/WV-KPR48vK9M8v0mbKcD1lyifwGy7DTabiVV92pwvCP1TWv-n7EyqKR1acQQk_eRawmw1i6lT4EutAhaBsvikxtBxQ6I7TUv-UrPmPuCrboXBFRKnIotiOPNUGvLkea2m6yenGHdyeUGb6PGVs-tyuo',
        customize_resume_3:
          'https://lh3.googleusercontent.com/8li6R7_COgT7TuT4vrzDW3mX3x8wCmavWgTXU3hmOuui6A2vCeQRdxaDjCmnFG5r28RsK_fKLi0pwKEMtkiP_GJXcyOflwPD6KoxsBnoaM91LVNNafgLDASRZGkhz-BOBlN9xb3LJTlxbZ4aFZoAr7w',
        customize_resume_4:
          'https://lh5.googleusercontent.com/hCMy7HDGmY23HAoHDK1A5GB4HwY55E6wQMDS6hL5YxzZNS5-w08rdnfaPNDI1oCvIByenDkULyoYF2Nxzw6sZtUimpZfHUKlXi1DDUViCmyqCwMm_BCjq44o7BfoGlROOfQSgImLqCDKwFGafMoA2n4',
        customize_resume_5:
          'https://lh3.googleusercontent.com/WV-KPR48vK9M8v0mbKcD1lyifwGy7DTabiVV92pwvCP1TWv-n7EyqKR1acQQk_eRawmw1i6lT4EutAhaBsvikxtBxQ6I7TUv-UrPmPuCrboXBFRKnIotiOPNUGvLkea2m6yenGHdyeUGb6PGVs-tyuo',
        change_language:
          'https://lh6.googleusercontent.com/m06-rO9dvfRSJAh6Sk-TEzxG8yR-27Pe4nJK3EJgszvNesMpK7FGSnT-O2wyL1wlbteqsz0ZPczPiORwJlZnIKeGDQOOfa0sgs9fZV8rW9gH8dR_DaZuq9kbKoH5W7pNQ9bvrESlZR63XI24groJn4o',
        change_font:
          'https://lh3.googleusercontent.com/WV-KPR48vK9M8v0mbKcD1lyifwGy7DTabiVV92pwvCP1TWv-n7EyqKR1acQQk_eRawmw1i6lT4EutAhaBsvikxtBxQ6I7TUv-UrPmPuCrboXBFRKnIotiOPNUGvLkea2m6yenGHdyeUGb6PGVs-tyuo',
        change_section_block_1: '',
        change_section_block_2:
          'https://lh4.googleusercontent.com/HgLiExlRQBZyWnFT16bXv2o6RklSREqKLd52TN82mW00c_BDGZTbxRJG6O6hPmFaHY68E0FRzZE9X7tT26_JARmMT7Tgkh0bLh6fAJohmSS3x6IHyQUyGs1wkbRzaDkzWz1AM1gFp6utF3W3Trb5K3Q',
        login_1:
          'https://lh4.googleusercontent.com/wlsWb41s70kBUGrch6n6WRNz_BtCU0dMwOusLks_UTpn6m8tByTzfKkMdJUtYqsXxK-Dm4_BR12TbE8KGOnPxiPrnpWSOWXniIULzuCRiDK2DU9AOrkQXKt5h2Y_deK5GP5fB4UiC69fdHhD8NaiV0Q',
        login_2:
          'https://lh3.googleusercontent.com/slc7N7beh0K26vxkbfoM3LeFI1WltEZVNudgoCwdaJkByuCmodS3nDS18MyfQPg8q3ZjLqhF56_ZDWpQHiN5p-FMauLVJfJGnR_sr31odgQGJ_IsjIJQSYX3B_zcgBUNXthNzJbuATsjRiBQMgSpfHc',
        login_3:
          'https://lh3.googleusercontent.com/7BMf8JS6BSUiQL98yRzxEg_J32Sg6qJcxtnbluif6pMNeOiM3udB7srFtBCSd202NysQIMt03jaAJb8KmNJt-QXtDcgXcndgOhanNNzKhvyk-06b0aS4ZemjVC8DxwTHAfPestldp9NP2JDCwIzlMVo',
      };
    case 'theresumeranger':
      return {
        delete_account_1:
          'https://lh3.googleusercontent.com/WDyWVtLGx1EVt2xQ7qsUFVGk98EBhVbUPY4RwKmHzBPSteYrvlZ2tBWbVzyEBd27pXAQK6tMEvvx6zJN1ULASL6241_LXZHyUxYBtSrE-tChkZsj4YSMFPIZ7AYEvuRZT3tV6XU1pAvuAD7utEoBKUY',
        delete_account_2:
          'https://lh4.googleusercontent.com/TrxD4esYojz0ZcjM2nk6n0Wlm3EEH9Wpt5w9NQ1VVEKU1h0_X8TD2DR-CtwPc1_qTtLG95e2U0pMtNXKkC64mhGwgx4e3-c9XrA48x4mBkIbZUG8clC-2A8r9ZY-ogJBqg4_hRRl57_-2Az9Cd0HY0k',
        cancel_subscription:
          'https://lh6.googleusercontent.com/M5Ic0ZrLjebpHw2TYKx7_o1T7oDab_fG-QxveMWlJOtMUyr4mZMLc71B2KgiR_q9ub1Fyj7mbeHXxMJBjRkPbsbrGv-gQzk-PlpCYjCmgbuVuJuEmDjnSr3opt_J1hzvcKuz72oogJnOJGGEK59XvCc',
        change_resume_template_1:
          'https://lh6.googleusercontent.com/0dC5OB6pwkUkuNsMqdYoeNLdQs1oUM4cc6naNhaQC_GUOKnGa6os1T3HPkiKWWg6LvGMEUiFXJkEYmkZ2c6Ey1YWAiOyJF6ELhrXIt5G_rABiTm7oBCce3UMCxxy1IiLJvk0CvoYYlhGDBL7lCecGu0',
        change_resume_template_2:
          'https://lh6.googleusercontent.com/UQXFOPo-bhVx0RZ4VC5KAZdUqA5bG8gMPLA5pyr24KzUT3ObXCdXZaykP1uXUpfaxrUubmrya-N8DKAd21ZAcIOYI7ZCZlZfSiYtn7Uma1kxizY7raY2HuC6P1FQ33ZgQLMwtbdIbt2VMJToohLkteo',
        add_photo_1:
          'https://lh3.googleusercontent.com/aV_GDzhH5WsTLlhcpzUBYY3zaH4UgVmEGhN8dkdbtA_hpPny9WLwUXUU3a6dqTZWmVJq3CErOsWsQZQE8a8ZdsHYVnTupVIFWc0qRJnzCcwqnovpNES2bE9seksH5c1v-Aw4HbacCihh3FdKt0flyHI',
        add_photo_2: '',
        duplicate_resume_1:
          'https://lh6.googleusercontent.com/hON4koCfRLVWzu64y5KoIyvWG3Gmwf3DuUJpm8KvVzXnb5VKzjpTB-bARanCEAx7Z9RXyAc-5FY5rw96M6r4FQWzGR9ar-sAgIL_FZP1XCHj_QErxTswtQmnRYK6lxLU8VuuDpMfPyGKlu46QXtndtk',
        duplicate_resume_2:
          'https://lh4.googleusercontent.com/ZP5yhQ1r51M7pI9kIIA02e6iUs8lT_5EhGudDpAnWQ75_ACzbiWDJUfjhQGxqwMxAkU1tW-lDcxOCATEqSr0_9kVAG7pUR_aZN7HIMOSgtW46HewEwA9EGoaANw99gKD033Q6_mflBJjQmRUn00f7d0',
        duplicate_resume_3:
          'https://lh3.googleusercontent.com/A-AddfZI-Y9sCHtGQvWwYeKxZYA4AXxgepFBp9fQ8W-ijaWvYC-MImWAmWgfjYB-XNupT7mZ_a6wSRjj0VVFa9Ss7B5LRa5obdZ6IOu1Y3W4MbMuQmomLMZf8R1GFym3iwcbZHuX-4m_LJKin_nTm94',
        access_cover_letter_1:
          'https://lh5.googleusercontent.com/68TlRjhjmK4x837cDhO1Ua4txoWi2XtjsLVsB82pGROfhjDTwzdUNPZT9BfcIpEAM-sfsFRTNtRkCpcK0EkgrdfezgrSTANrrGvq7PDPzhM5_JFTQgD4RwIWfZAvB_Qv2AiagwR4ju_Ewa7WI_NY-sM',
        access_cover_letter_2:
          'https://lh6.googleusercontent.com/80AYbio-kul0nvxx80f4TtrEhkDPffig2Lv5AvFmYDW0W9vreno16NMIBcj3F3McFxVnMwZ7_BSFW4nEN99g2AguCVHxLjkZllN-aOUy_NOYCUVqfYnoH2gC_rxiZhJitUrMYvboEzfpb9aikOszhmw',
        word_format_1:
          'https://lh6.googleusercontent.com/6cokWgXKi6-TxWWQi0dT8pegS61feX16WTYehYWQrCckFLhoGtfsiB_Bc8wBb1l1TKui5SpKRVUXrtxo5K0OmWPksclhjcuaS84AeCwCOnRAwmjQ9whRnr-UEVrsOtGLCc1lMG9AcE65DKM8s7HtJ60',
        word_format_2:
          'https://lh3.googleusercontent.com/Qy9QwHnaGctYt8VLPrDGBgw87kK0Vr5CVaxEFFU-6NV3WGTEIfY7ndDMWXOH6_dV6NIQebE_9A8FtBGDVCkjZaDIycCzF2B1o1xRolH0pvxmk4Tri7pSkYpI9m9RP0ogmn8NC78r5ZZ4Ne2O_Jegkv4',
        customize_resume_1:
          'https://lh3.googleusercontent.com/6OMHiTfQb6CUf95ERvAimTzndtz_2TRdWKguj_a6ykzU-AJb7uCwkHeIu9chFTjTuFI3Y3_3tJbxThlSi0yzs4sN27Hvz4fB33PF0vM_V2JCajajUzpB7QiFpAHeELelzDNm9isvA6X9NRBOhCXOkjU',
        customize_resume_2:
          'https://lh6.googleusercontent.com/UQXFOPo-bhVx0RZ4VC5KAZdUqA5bG8gMPLA5pyr24KzUT3ObXCdXZaykP1uXUpfaxrUubmrya-N8DKAd21ZAcIOYI7ZCZlZfSiYtn7Uma1kxizY7raY2HuC6P1FQ33ZgQLMwtbdIbt2VMJToohLkteo',
        customize_resume_3:
          'https://lh3.googleusercontent.com/Yh-WVzAYnmcOSrKCCh7TGp8y7Nvz90wUAtTfOzD0i5PWwgDKVVCeFRImHf-wOt-CptY0qhS8CNecR48F035dr3FglnDBGsN9EYz56aW7qwJSUS5NeuuUUobkDR-uJerE1PyGqa4xsuNKGcoepo_1tj8',
        customize_resume_4:
          'https://lh6.googleusercontent.com/Zj0_bdSe5CSumsYMFNd3I1LhpZGgIHYS4u-6NF-fs1G187qemntpKouYB9gUi25QMqAFp2RYZ9E5zXO8s_yAy0TXP2lzZSkpJ5Ku7SIkjoffuam10vj4F6UcCVAg8qXC64pDw6cH32PaCMQ8i_GuEk4',
        customize_resume_5:
          'https://lh6.googleusercontent.com/UQXFOPo-bhVx0RZ4VC5KAZdUqA5bG8gMPLA5pyr24KzUT3ObXCdXZaykP1uXUpfaxrUubmrya-N8DKAd21ZAcIOYI7ZCZlZfSiYtn7Uma1kxizY7raY2HuC6P1FQ33ZgQLMwtbdIbt2VMJToohLkteo',
        change_language:
          'https://lh4.googleusercontent.com/UsWLJH7a_9GKKNBPRfpuvL_mI_j2t0ULCunPArRPySR-Xibslt1TjkocD_wB0sG6O_iiGfGW7kReFvpr4W42p6d-EmxLl36279fAPbB07_E-f5WKZwzRGmGRI72W8iHFydwsyhRdncyZRCWTzdJGZX4',
        change_font:
          'https://lh6.googleusercontent.com/UQXFOPo-bhVx0RZ4VC5KAZdUqA5bG8gMPLA5pyr24KzUT3ObXCdXZaykP1uXUpfaxrUubmrya-N8DKAd21ZAcIOYI7ZCZlZfSiYtn7Uma1kxizY7raY2HuC6P1FQ33ZgQLMwtbdIbt2VMJToohLkteo',
        change_section_block_1: '',
        change_section_block_2:
          'https://lh3.googleusercontent.com/d6AZsgORIHdv30BoCCCP1KV-Qv8arXIMo0TYLQjESnDQgBeSw3-YlLx8sRCvWf14CnI6qTwTe6zB-hFov4OMGz6OrfyIZhZNL8eaNwuZS294azotxsHB-GwzwjqpT0K_qyevFsaEuKocNYRjEcR9SFA',
        login_1:
          'https://lh5.googleusercontent.com/LncTV41m_qtEyfmxvfAzcMWzJJcZ_5BhCaWIJQDikBL0rTArEDdM8sXSiZZJ2mEkL5WYgLzxx7DXdbKbPf75fXb_Pu1C7ClhclLN3kSVJJIeVOG5pE1VJ35JZEEPMgme896WuPFn8BljeyrPh3LCEgo',
        login_2:
          'https://lh3.googleusercontent.com/T0iGoSoumMXju5ZCjC-MIjhhd1gXkH3Tcz2arBEiPIcfZv-ZEQz6Tab0qDy3Kl5H4gv4aoh3S88x3H0XKOjlrzuMyrFzNKpQEG7V-AkRxHg3-238OB5w4K1DHh6OYVw98008-WT9PS8EURTJQsKngf8',
        login_3:
          'https://lh3.googleusercontent.com/Hwf8A9WyCICPM9j2HdsOSOhIGDOv1vgff5ZK4GPcHKYQjJRcAgnXBOifFFDUfNEvqh0_4LFo9DJXNhXlTSmTRjm1umxFMCYMvK_gLZWPUXj_PoIfhRUwDtAKG2TmvAkHZLvsG1HDBuGSEob79Q_880k',
      };
    case 'bestonlineresume':
      return {
        delete_account_1:
          'https://lh4.googleusercontent.com/gqcMRiU4qj_VVHW1BQiBXKz0E9P1ndkA0o51bdh9xZkb4gszMmrKZRSd-8v6gjC7tCdhFrPTgh6e-YRP31KBNTQ7raA6DYQIqy4JRfruHWQcD6azBNfVvlhwBV8DWS8aLzcBJHoBdwwP2NFkjA',
        delete_account_2:
          'https://lh3.googleusercontent.com/KVoKfzeY1e1HgQWg6Ce4I3rfDeoAa8VDqC-WPwg8hNCW83l12gmo7yZmHo_b9lUt214dZiN4jLX69zjfh4Clh5d8FUoVSiEb8l6NE78868ZO0TPFwatcg-8VyIwwo_MAADonUsW_HFliulLFxw',
        cancel_subscription:
          'https://lh3.googleusercontent.com/mRvWzq4PMJ87kuejCYGVBVuxEnUMY8YHz_W9ePh6nC_QvQ9Zn9XinvLX8wO_EGJpswZBkvMMxfF5HGkioaw__ErmDZ0KQO9TmW6k6Kow3WGvxlFXRABy2npVfMvo0R03wWngL6knqmk_Zcu3lg',
        change_resume_template_1:
          'https://lh4.googleusercontent.com/2Ksj4uPVHOlMsXmSuXKdnha4JTjEmdP67EDcAIXTxpuxbsNFU0qzmln-Bq7F5kMu-KFzao8VC-P96yGGuEqCyjF6TiUZ-QG3NbHrJ20RY4ONVgkgr7ewVLdkMHDc0K6Y0OYqjDc_2l_qSgokaw',
        change_resume_template_2:
          'https://lh3.googleusercontent.com/Hguy4OWevFUruiDXp6aKyI0-Epit00H_m2bPYSR2WyLEAAm0CBBd99OQFbzlIQrWr3lW4qYwFDAz-bXCXcMOBJvHihqJ0PT6A2u9p8aFw5vGgV49CpptvEyFMZpV3ThsDjrvL4kMkzz2aGLRAw',
        add_photo_1:
          'https://lh3.googleusercontent.com/u19yFd-2XU4DCqgQIdko3h8cc189XzagfCszP7U75nIDHiWqPidXyJuf5mV11EoKAgVIRvq-8ZluVIgTyx66y_W_-zQZWZTJeNiC1gGj9wlxr0u1lb8ExMHyqr0vv44vMONYJ9rEE103Hr3PHw',
        add_photo_2: '',
        duplicate_resume_1:
          'https://lh6.googleusercontent.com/j4NLCJS8dcu2HbLjm8xgN_d-o-HA6Ib_9uVWYT9-1cbnaPAJ_qgBwImF94Zymh3cyWKh7jOoAwF-aMVvtgH90O0XCtcSoQ3UPNrfeW2_zBLyCNF6yvvKiyj5aQ_tmUOlfQDVR-P8G05qYvnUNQ',
        duplicate_resume_2:
          'https://lh6.googleusercontent.com/_y9Z60SHYEu6paSeJKUpQ0GOtUeWHI2WDzLC6El4kUxqtciJBZ093oMZ2HMmMBd1kIwHxdTLi1-MpOmKUCWPEN3xDhr5Z_IOSXuz9rf93cvvkeoKWHJb35lvaP1fejtBbJab4UT_wdr7DHpfEg',
        duplicate_resume_3:
          'https://lh4.googleusercontent.com/MC5sMmy3gel9rc2V_IbWZtZZ2iBuNA2vO7ViTmOQGuJI-mxLQ4QRrYsYnne3ZUvzaRIXuVAClHVD1xg_gLRlUk41xp_cIjPjm7gH83LOFVtZ4h-Tf3uqp5BYALkPEOD8CPH6F0BAvHLR1M-HkA',
        access_cover_letter_1:
          'https://lh3.googleusercontent.com/ZKGBcxHwN9_WCKDgg6s6zI7L95MKR1MG6-AN41XgovjxbsDK5Ganq9zm5yEY9a51iU8wd1yYNWrflMotwNtE9aOdZA2W17-MGyAQSrZxnNr4jsLwef0upVy1GVcts9E_B8Bwl5O0MH6Pf6x8Fg',
        access_cover_letter_2:
          'https://lh5.googleusercontent.com/peX2jh3DoL2StFo_2XDitOpUpFZDEFAKaQpCTCCY1yXgBXqZJ6cTzX9moMwchY9jq07p67qCD4xcKD8byijkIuoFyP9ED-WR04sCk-uqgLqhxuiFiixSgd1HISJLf-8t9eLwAl9YK8eFDGim8g',
        word_format_1:
          'https://lh3.googleusercontent.com/F3cyP7pgDBUoRkl1er-B_KNWUDNcTmM8nQnuPQMK3HgsexJbyswFgFhXX-ukDppbcoT2QxGk5R4OXC3Z8ITxmiiGuvJRBfZVb66LCqNyO54d7cOjIRwtbAKMWRTpqEQ3e29XTYbNWN7cLrUJ6Q',
        word_format_2:
          'https://lh6.googleusercontent.com/ETJ-_Qvu9BFLyUfIaBsMXsUuUPNw3UM8PDZfvwMaUajKDDonkAUyuHQxrDy2RbwnjdtCWZE9M0kQJr4K6PvTP1V9rAKkMh0Zh94PVIMwIJr4kT3PQAm0kMylrdLSEt5OwgxVg2bo9EtkE3zvpA',
        customize_resume_1:
          'https://lh4.googleusercontent.com/2Ksj4uPVHOlMsXmSuXKdnha4JTjEmdP67EDcAIXTxpuxbsNFU0qzmln-Bq7F5kMu-KFzao8VC-P96yGGuEqCyjF6TiUZ-QG3NbHrJ20RY4ONVgkgr7ewVLdkMHDc0K6Y0OYqjDc_2l_qSgokaw',
        customize_resume_2:
          'https://lh3.googleusercontent.com/Hguy4OWevFUruiDXp6aKyI0-Epit00H_m2bPYSR2WyLEAAm0CBBd99OQFbzlIQrWr3lW4qYwFDAz-bXCXcMOBJvHihqJ0PT6A2u9p8aFw5vGgV49CpptvEyFMZpV3ThsDjrvL4kMkzz2aGLRAw',
        customize_resume_3:
          'https://lh4.googleusercontent.com/Bh7ycQnXN4NYlMN-eH1qo-9VMpqNAlJ3b5q3uAJ6OLRDv8cDsdkrniiYZsn5UQVzvMO6Q-s0fmNjcpB_V_xI5A2zCk-Qja6MuNw070_l8BkMmy2cFq0fLydEL_dKebLEE6jmJcRqbKz6mbJJ1w',
        customize_resume_4:
          'https://lh5.googleusercontent.com/HVmNXrjES7_hk4e_sQMlTDftWWHw7R71JiQp727zhyL9mR9azkm5F-gNIB3DP4MgLJD9Cve5b0wEJohjka1gpZaVkxEaWUnM0SgxtzQEFQSEsbXY6MIf5OmfovXgyH0zQFDY0PuDgxrVQTS3Yw',
        customize_resume_5:
          'https://lh3.googleusercontent.com/Hguy4OWevFUruiDXp6aKyI0-Epit00H_m2bPYSR2WyLEAAm0CBBd99OQFbzlIQrWr3lW4qYwFDAz-bXCXcMOBJvHihqJ0PT6A2u9p8aFw5vGgV49CpptvEyFMZpV3ThsDjrvL4kMkzz2aGLRAw',
        change_language:
          'https://lh4.googleusercontent.com/HGI3rocPR5A6Mvj2xJlt_PJPQ5jmMYyV9aeml-FrYd23vpMHpEA8S0oB_qbrub5hKl1e3q6ECKtWDV5f2-afAjNn7zcs7vtNUE4r08SFfhQZHmY1wT22mrQSXs7X_e9QVpmDJAvdY2SE3nkaAQ',
        change_font:
          'https://lh3.googleusercontent.com/Hguy4OWevFUruiDXp6aKyI0-Epit00H_m2bPYSR2WyLEAAm0CBBd99OQFbzlIQrWr3lW4qYwFDAz-bXCXcMOBJvHihqJ0PT6A2u9p8aFw5vGgV49CpptvEyFMZpV3ThsDjrvL4kMkzz2aGLRAw',
        change_section_block_1: '',
        change_section_block_2:
          'https://lh3.googleusercontent.com/_0g06ooSKS2LNc846SOlv6kAz3pUHupU5GPHOSV8RPS7roWmLVxtqY30GEO4xxxDNxshUnEPA1vJRiBacO03zDsBbmjAs-vMZyAf5biqLV_MXs2V-S7fe3vKB8VnK84qoDB-Ny9rzgyzEdM2cA',
        login_1:
          'https://lh6.googleusercontent.com/kLV5lOuNHEZ8chYs4vwDESo5pTyFaiXzWH_WlzMaNaKVJbySghdvaaXbYIke46y5GO514EDtJIAh2x2Yt6xb5btOXrPfVlhMqO7w-RFlJKHTM95BJgq3kqw4sITNdFFvJYbJx1m0bQeQJAjT6w',
        login_2:
          'https://lh5.googleusercontent.com/mR84mo06lXm1icwU4ny3xuwA-KYREVlmaErcOhfNYWBxHD45Kqd3RFOx1COzzucfpiobbUGofa8omIV26L-NokWXCoJaNzcpncn5Nwsy4a9V6g5djJJMzyMIoE7D37FUaFpB2hKfrBuQcCpoxg',
        login_3:
          'https://lh5.googleusercontent.com/R0CNXCVTrOsvASTLgsAUTPE2kRcBbjAeoKIGdaIuBxwMbFe5MEOLpZqI9vO_7fGmWss1Vx5ziQjuN1b81YyXHHakjNS9YuvK6HRzlCD9bEPbx1EhMbmwH-Y-gSbsmplw8wvaD9f7p-8apGg8Cw',
      };
    case 'elegantcv':
      return {
        delete_account_1:
          'https://lh5.googleusercontent.com/k5dns2iLwHjUGaWov87RSU522kYAAGSo2W_LvRJRTfJ9R4leEcB3pTfjziWKQB4sH5s8O4Ts2nko1ni9Tg84zUGrhXvVHeY_UYjL7lrDmcfVhxXjTYHTRZupKvqldw3JPaIllzif0Kh60FUjUw',
        delete_account_2:
          'https://lh4.googleusercontent.com/A2NVCBlCtdD_dY_LT_W89sqJbbaDJZNBBpzQaBd5YgJLyPsB6wjHqtC1LcCqvHiGNARg_H3Gl_uKx7Y3h4IO0ZfoKnln4DYQmrmhBa7R1VlbmE-hfni5XAzJB6HrH2hwD8150ZRqS-5uO7lJnQ',
        cancel_subscription:
          'https://lh6.googleusercontent.com/8m_wG_hCeXna7orwW0tKk5yqpV9iY1sxzXUA68jwPPvE7fqcVtUmSQRWtaYnlkyyNyTrs3mqUOIT6dWSroLAKQVb-bpukAkxDZO4JtzEZciNmvio6ih2HajsQJXGJIgk5cCYU5kB-HQB2QKzqw',
        change_resume_template_1:
          'https://lh4.googleusercontent.com/Ers0udvsCsWVujTAyyJaFF7klovxR-ham45D6wuBcPby2PTNWvAouYC1Mqq0N2RTiRDozMQZh75f5qodMtEc7w4MAnomp7u-P-PnPmaqu4mz8amM0iU4t6PJaOyTuUfyN4sKWRkdc994yCvv7g',
        change_resume_template_2:
          'https://lh4.googleusercontent.com/5Nin91Wvm0E7_Iy6tXnsujQcKximKCtCYXbeatv1aGqL3XuscvRpiJDZ-zYBTsOJ_-jtReZUzJuBEFp3CLIhmu8as9lud6SMNXHWMydcLxVNzFu1fGsv13aTTNAOtlysaxXPAkU-dfcjKqCbBg',
        add_photo_1:
          'https://lh6.googleusercontent.com/w2ChFETMxfVZdh-urXoS8eS9qjwWIeE3H47MWXKaV9USZmtqh3CNDJsop-SsuANHsP_joqd9Tn_m-JfXlBuRsZuq8DNMcZF_XMPuOeZzLF6EF4s6_Eg4Un_c2wg_OYRupVYDLxHrNrE9l15xcA',
        add_photo_2: '',
        duplicate_resume_1:
          'https://lh3.googleusercontent.com/Ru2WC7s6DuZgBHRGF7aDMXy6GnHjfOEDwo9_PNlZ095z4TKlM7KSdBNT3GAOjnI5a1kJfqcYwCwuMBUDVFgH6wjcbne0YSWlN2clUR2DY8BJzVhNpo9kIFmM1mxcp-0eHZy2aj23WnN2_NQh3Q',
        duplicate_resume_2:
          'https://lh3.googleusercontent.com/LUfxj3fvyRmbbkQ0-7eLqV61lJG26n0-GaEv_v_lF-Yi5MmwSRhRSn-dsPExFohOwhc7mSDCJHXgQr9pJMp7oLI41sOse00nrpFltun3Um2l5xdFsnounYGOLfpoDPuI6yFBPthmeNBBoXyOVQ',
        duplicate_resume_3:
          'https://lh3.googleusercontent.com/h0Jcl6338kWhoLZIUTMSduo4fPRLGvdl9ppIBQt3YqFioR-XR3y_EhJgJFWSqELy0N3sraUHzodQ0LxfMaRIsK8kwQMwaC_mldOKQCKl73Y4Y2E6gmddLrhJuwZ_mpi41YWzsGMd8ac4IQxrRA',
        access_cover_letter_1:
          'https://lh3.googleusercontent.com/tNcTovOocEBsgD8f2hOve57CoUURBcQxylAdbXuAXv7vUFRx7zKBe36E_l6k1S_yo5AT3HMGI_mbUrAIRUMzAezJwuppIGAj5jqoUH6pij0ZN_TXVGy_zyMxEZzZTqt9j0zZOCDmWNGO2tX0Mg',
        access_cover_letter_2:
          'https://lh6.googleusercontent.com/vvVtYy3I627vBunj7L-z77CB_KIgIZJAXUjF4d4VEClo1jXVy4L_kac6ghgSab1so4lBIYISoy_cEsIDe0b_Wn5F78ujWLyTaRCFK6LKI_vSb8FzwLRlsEQj_YqDTeXUCDRcfn4OwOiVhlqJsg',
        word_format_1:
          'https://lh3.googleusercontent.com/nlXJtsbeECfWqiqy_2Fi_iQShQcpnHVXDQbl-Vb3GaPDU1WTxpgD2O6VI9fKPtHOvpPl6f6yp0u72r4xnKqC4pG58y4n2VCrE1Ari0k4grTGRSOquX6UxyBnmSnrg7u-Jw6fcMk2q1u_iBgdDQ',
        word_format_2:
          'https://lh6.googleusercontent.com/DGOQBIcj9G7IQBr86eQyH-RXts9DledU5KfDerQp3oZfxk-PYMSCdawVFR55Q0uLvG91aiDvs1Va06Iy3K_hYkYysNoG7Lgu9t7xyED3gptNU70IlHMow7QPCY5CYdwITiytZiqpaUmsk1FNiw',
        customize_resume_1:
          'https://lh4.googleusercontent.com/Ers0udvsCsWVujTAyyJaFF7klovxR-ham45D6wuBcPby2PTNWvAouYC1Mqq0N2RTiRDozMQZh75f5qodMtEc7w4MAnomp7u-P-PnPmaqu4mz8amM0iU4t6PJaOyTuUfyN4sKWRkdc994yCvv7g',
        customize_resume_2: '',
        customize_resume_3:
          'https://lh5.googleusercontent.com/ezFA7BK6u0jNTWGvmg7n5cpG8Zga7m4R0IfKQVqMfHzpg1_OQv1e_CbG_aWgoQ7GkewfbA1oIzVBtWpXgmibL8rZVP3XV3Um4Zy-fpC3rk1RQhCwjTOVkpefhJjYDdtbOkZBMRvzbSOT1HHBpQ',
        customize_resume_4:
          'https://lh5.googleusercontent.com/q-SFn-eGH2Q3VeLmxZYfWixNz0rNo4U_-Jlfsr5nOJXdTryAaNACOQNmBOLs4e3yWFntyff97TdzCVpoUBu0pkc9F0CPcEQIWBqqRHrQVlGSxvK4KxWsN17sUDTBoX0L0j8njqFAiuuIdz82QQ',
        customize_resume_5:
          'https://lh4.googleusercontent.com/5Nin91Wvm0E7_Iy6tXnsujQcKximKCtCYXbeatv1aGqL3XuscvRpiJDZ-zYBTsOJ_-jtReZUzJuBEFp3CLIhmu8as9lud6SMNXHWMydcLxVNzFu1fGsv13aTTNAOtlysaxXPAkU-dfcjKqCbBg',
        change_language:
          'https://lh5.googleusercontent.com/83v37HC_ml-zTLSn_EIpN3zdcOsmux5v54ll9lL05kJmw2Axm0Cj1uUJBAlVLUkyDhn3eutyWX0Kbvgv4w7fN1N0U9xnSWX5Axv2JPIRK_s70RlcZpbbgbsan162Hfy71wDGZ57CicXWR06GPw',
        change_font:
          'https://lh4.googleusercontent.com/5Nin91Wvm0E7_Iy6tXnsujQcKximKCtCYXbeatv1aGqL3XuscvRpiJDZ-zYBTsOJ_-jtReZUzJuBEFp3CLIhmu8as9lud6SMNXHWMydcLxVNzFu1fGsv13aTTNAOtlysaxXPAkU-dfcjKqCbBg',
        change_section_block_1: '',
        change_section_block_2:
          'https://lh6.googleusercontent.com/AD4kWlMOaBrTZ4yrlfEYuzzYqfw0eXQVLsnTVQtw1iwMAUCOksfmLK6xM-1xdKClUbEIS8Nob2Z2VYzbezgAaz0xwdZxVEZnfv6R646W3gRskFpW-_BoTCOppVW3z5JzgzrhKUBrF26XHX1MIg',
        login_1:
          'https://lh6.googleusercontent.com/9sszw2U49JXA5sgNkG1I2cM2PMg_j8i-rlG-0-Y7V1XOxlnHxzS8DJ0Ac3LGfwc8U3yOSx9SNVbdf2FHwN8gj3KehHYeCVZUxbG0SPrP2dmjhSFIO_QwqgCn60RtvLql43AXrvIlrOy_MB-RvQ',
        login_2:
          'https://lh4.googleusercontent.com/bKWoGBVQggvnkhB-6voDLH8OuBjX27ep_4pRqEYwhK7ERvPU7DlgpcWPoF9Q4jIJmRnh80UtHtQP-YPGiWAFHpUAuxNyxj_soQ8PHr1RqReCQesPcd42goUl-qKL0XEp2Dd3pUSAHK9V3qx_1A',
        login_3:
          'https://lh4.googleusercontent.com/Pkg5de5jdMz0GNXQbKZ9Xmiqe6eS1uTLCx5s_RPs5wkSe9eo7UcRToLHbknjohDeMp3iXMESCaRCU-pY-hUeWo8-n7nQj_guiZjwjgOzgdu5IKQbM7cF_VnzR78PA4AGbH5XAyYC1DeqCyYGzg',
      };
    case 'mysmartcv':
      return {
        delete_account_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a886e2c7d3a10cbab3dd4/file-d9l0hoBeOr.png',
        delete_account_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a89b32c7d3a10cbab3dec/file-EtimCdxtTA.png',
        cancel_subscription:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5fb4eeac46e0fb0017fcd093/file-jKPNWABgbf.png',
        change_resume_template_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5fb4f5d34cedfd001610df7c/file-tfefQ6BLc8.png',
        change_resume_template_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a851204286306f8075614/file-QpJ9tBw46F.png',
        add_photo_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a85b304286306f8075617/file-Iibe0vJ9Jb.png',
        add_photo_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a851204286306f8075614/file-QpJ9tBw46F.png',
        duplicate_resume_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a83202c7d3a10cbab3da7/file-KLNCflNQw1.png',
        duplicate_resume_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a837a2c7d3a10cbab3dab/file-HgIYruHPcx.png',
        duplicate_resume_3:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a841f2c7d3a10cbab3dae/file-qKRiKSknVe.png',
        access_cover_letter_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a823e04286306f8075603/file-PWC2TS8mRN.png',
        access_cover_letter_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a82492c7d3a10cbab3da3/file-yHCUVUbVGt.png',
        word_format_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a802e04286306f80755fc/file-FIbbSHvfsQ.png',
        word_format_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a80f52c7d3a10cbab3d9c/file-hJjRC3p7qh.png',
        customize_resume_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5fb4f5d34cedfd001610df7c/file-tfefQ6BLc8.png',
        customize_resume_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a78352c7d3a10cbab3d7d/file-GEnKgudlZF.png',
        customize_resume_3:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a7e6704286306f80755f4/file-tEVtpNIbtY.png',
        customize_resume_4:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a7f0504286306f80755f6/file-UB3JL9RTpB.png',
        customize_resume_5:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5fdc424a27288b7f895d6ba4/file-LMXnEJzW16.png',
        change_language:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a78352c7d3a10cbab3d7d/file-GEnKgudlZF.png',
        change_font:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5fdc424a27288b7f895d6ba4/file-LMXnEJzW16.png',
        change_section_block_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a7f0504286306f80755f6/file-UB3JL9RTpB.png',
        change_section_block_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5fe97010f24ccf588e3feb2b/file-dNsJ27fhaN.gif',
        login_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a8a5704286306f807564d/file-t9A8hzOn0A.png',
        login_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a8a6104286306f807564e/file-UQhVYa8b1Q.png',
        login_3:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5fb4f404cff47e00160bb080/file-pgpSAEP2oH.png',
      };
    case 'resume-nation':
      return {
        delete_account_1:
          'https://lh6.googleusercontent.com/X8BqrDbccW19Dp1krmBnq0FZq7XiG7EBksOuszg2Uwxrfy0R0hpEobXBIerI7i5YSyag3GJDIdoxQCx2cKPnQqc-STC49WrIg8fWqnFx62ANdXkQKzC1CMBM0kIB6w7jKhPKqs6CWdzfwJk0B5dJJ5QQC4WpYC9-ZU-Uhqy-xJTrB79FCKoG8aeSsA',
        delete_account_2:
          'https://lh4.googleusercontent.com/1sQL0uTrpsdcV_PqKsRg5aobF8Whzvt0k0YtYinVJQn_pSjTfU3f27_Vzf4egNIaz0nt-3s9Yp8pN4QshwhDUa-QdlbOHai8HAOnMLRfaapB7cUWmM-d5Wmzsks2Nuc_bNqwNSGyGU-eWyuMiZKn3b5Vfhhnvjm_BEYH52TIc-AgHSSgzxuW__wwJg',
        cancel_subscription:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5fb4eeac46e0fb0017fcd093/file-jKPNWABgbf.png',
        change_resume_template_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/611e093af886c9486f8da50d/file-NnHYYJYMWH.png',
        change_resume_template_2:
          'https://lh3.googleusercontent.com/52r4JQMKWxf3e1kRSXgkyY3Ww10QRI8PHD9hkB0L1_Hb0fHEdmMKii0DOTStn9QsRj4iNscGXr-PzMEwOrFtyDT8b6NwIbcd3ydKrx14WKKO3qLXTw4Yt-EEwFf-fKYEdzR6Ne3AByzRvloFwBcHTwn7n8g9RuXSfo-MPKKw8sVui7HjQ8E0OfwMBQ',
        add_photo_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/63344cc7cf38bc37aecf445d/file-r2wzQkSO0z.png',
        add_photo_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a851204286306f8075614/file-QpJ9tBw46F.png',
        duplicate_resume_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a83202c7d3a10cbab3da7/file-KLNCflNQw1.png',
        duplicate_resume_2:
          'https://lh4.googleusercontent.com/DII_v-fjwn-ezOVaRA9IOejGzGOd4LDSV2_RGlAzILt7A2k8C5hFPS9pmSnbmlwRaVSgjQwb28GlKuaS2xi9d2YpfePA87Vmf7AcTCDW2U631XQ8NZErAwwu2sM5A9fPPHHtGP4rZYQITLf8O6xk5Nbyl-fc6S8OU3UT4_CSka-3byr3PVOFxby-Sg',
        duplicate_resume_3:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a841f2c7d3a10cbab3dae/file-qKRiKSknVe.png',
        access_cover_letter_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a823e04286306f8075603/file-PWC2TS8mRN.png',
        access_cover_letter_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a82492c7d3a10cbab3da3/file-yHCUVUbVGt.png',
        word_format_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a802e04286306f80755fc/file-FIbbSHvfsQ.png',
        word_format_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a80f52c7d3a10cbab3d9c/file-hJjRC3p7qh.png',
        customize_resume_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/611e093af886c9486f8da50d/file-NnHYYJYMWH.png',
        customize_resume_2:
          'https://lh3.googleusercontent.com/52r4JQMKWxf3e1kRSXgkyY3Ww10QRI8PHD9hkB0L1_Hb0fHEdmMKii0DOTStn9QsRj4iNscGXr-PzMEwOrFtyDT8b6NwIbcd3ydKrx14WKKO3qLXTw4Yt-EEwFf-fKYEdzR6Ne3AByzRvloFwBcHTwn7n8g9RuXSfo-MPKKw8sVui7HjQ8E0OfwMBQ',
        customize_resume_3:
          'https://lh5.googleusercontent.com/l8iE5ahUmyt3r503vqnwDcXlEiWehbJ8fchP8WwfzbGlctXUQ8afbkJyQmacmTG87dl41XeEHdMXwykngrezWtntWP6awcck4t6GqDcS4cLM2AwrAYcOb88nYauMEsiqz28rC1pdZVW_zmYEa6Wl7FwN1MR_XKHdt296IApASXzk7NhgqTii6lKs_A',
        customize_resume_4:
          'https://lh3.googleusercontent.com/J5gwQw17tYcQLSVya91cRibwPf4I9o2Ba1CTRQRsIon0BI7Jd1mr_RoAPnAyIHV_ggr6inzwI_fUKLOkgWVZjRRy6Src8uvVVpmls0uok-Uiczlxof6LFDuIQIULi9UmhuysdZmA7dLqGECUjy8SI9ji-ADmXxrHDW58uQYd-5i-BEVh5KRXBKU9ng',
        customize_resume_5:
          'https://lh3.googleusercontent.com/52r4JQMKWxf3e1kRSXgkyY3Ww10QRI8PHD9hkB0L1_Hb0fHEdmMKii0DOTStn9QsRj4iNscGXr-PzMEwOrFtyDT8b6NwIbcd3ydKrx14WKKO3qLXTw4Yt-EEwFf-fKYEdzR6Ne3AByzRvloFwBcHTwn7n8g9RuXSfo-MPKKw8sVui7HjQ8E0OfwMBQ',
        change_language:
          'https://lh6.googleusercontent.com/Me_rJWd9GCkzF0sEPdEQqDXhixcZJ1LECJYq3Owfs-QUgNJUfSCkfWU4LfZ7UAb9XZZbYuowEYmNDSIXAzSG7Y3Ss-BGe4LNQH4hgWwDBfl-ldsA7RneOPsBtUxhbWOK9889ZhKgCI83eP9fF9Y_3l-E9vuEmhJd0wkGxQh5-opbqEB9ZpSdn8zHJA',
        change_font:
          'https://lh3.googleusercontent.com/52r4JQMKWxf3e1kRSXgkyY3Ww10QRI8PHD9hkB0L1_Hb0fHEdmMKii0DOTStn9QsRj4iNscGXr-PzMEwOrFtyDT8b6NwIbcd3ydKrx14WKKO3qLXTw4Yt-EEwFf-fKYEdzR6Ne3AByzRvloFwBcHTwn7n8g9RuXSfo-MPKKw8sVui7HjQ8E0OfwMBQ',
        change_section_block_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a7f0504286306f80755f6/file-UB3JL9RTpB.png',
        change_section_block_2:
          'https://lh5.googleusercontent.com/3iwUuy7np5fISdXuez9gbdxCyr4sEAFYKkyCaYArkoYAwJjwf_JQjNY69cqK-cn752EJdUZD8HPuTLOXjb0_rufqXi4FrRb-o6tlKVTXAlc5tKlQ-HCZzO2977L5yy3I2EbGiwycNw4OB4uAaHccU9E61KjeWJ5cplKzX-zXkiBc49sYVu7m-LqSBQ',
        login_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a8a5704286306f807564d/file-t9A8hzOn0A.png',
        login_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a8a6104286306f807564e/file-UQhVYa8b1Q.png',
        login_3:
          'https://lh4.googleusercontent.com/FuGgIfu1h-yFrU88ayNDv4qkX6ohDKHIKGbYsV48co8yXqf4FGbyu1uVWVVXMYlCnlvSPqVdseaH0KzkYng0xeRYn5y2-Hv0xnlqjzLtaeC_fkfVUC6I7G5I-qvzb7-7B9o8V2NOuUpyzn5RMxTgMRRdjgCwUkssVlRi2NO_SVkpLRiIv7u6uYANeQ',
      };
    case 'resume-example':
      return {
        delete_account_1:
          'http://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a886e2c7d3a10cbab3dd4/file-d9l0hoBeOr.png',
        delete_account_2:
          'http://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a89b32c7d3a10cbab3dec/file-EtimCdxtTA.png',
        cancel_subscription:
          'http://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5fb4eeac46e0fb0017fcd093/file-jKPNWABgbf.png',
        change_resume_template_1:
          'http://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/611e093af886c9486f8da50d/file-NnHYYJYMWH.png',
        change_resume_template_2:
          'http://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a78352c7d3a10cbab3d7d/file-GEnKgudlZF.png',
        add_photo_1:
          'http://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a85b304286306f8075617/file-Iibe0vJ9Jb.png',
        add_photo_2:
          'http://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a851204286306f8075614/file-QpJ9tBw46F.png',
        duplicate_resume_1:
          'http://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a83202c7d3a10cbab3da7/file-KLNCflNQw1.png',
        duplicate_resume_2:
          'http://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a837a2c7d3a10cbab3dab/file-HgIYruHPcx.png',
        duplicate_resume_3:
          'http://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a841f2c7d3a10cbab3dae/file-qKRiKSknVe.png',
        access_cover_letter_1:
          'http://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a823e04286306f8075603/file-PWC2TS8mRN.png',
        access_cover_letter_2:
          'http://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a82492c7d3a10cbab3da3/file-yHCUVUbVGt.png',
        word_format_1:
          'http://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a802e04286306f80755fc/file-FIbbSHvfsQ.png',
        word_format_2:
          'http://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a80f52c7d3a10cbab3d9c/file-hJjRC3p7qh.png',
        customize_resume_1:
          'http://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/611e093af886c9486f8da50d/file-NnHYYJYMWH.png',
        customize_resume_2:
          'http://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a78352c7d3a10cbab3d7d/file-GEnKgudlZF.png',
        customize_resume_3:
          'http://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a7e6704286306f80755f4/file-tEVtpNIbtY.png',
        customize_resume_4:
          'http://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a7f0504286306f80755f6/file-UB3JL9RTpB.png',
        customize_resume_5:
          'http://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5fdc424a27288b7f895d6ba4/file-LMXnEJzW16.png',
        change_language:
          'http://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a78352c7d3a10cbab3d7d/file-GEnKgudlZF.png',
        change_font:
          'http://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5fdc424a27288b7f895d6ba4/file-LMXnEJzW16.png',
        change_section_block_1:
          'http://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a7f0504286306f80755f6/file-UB3JL9RTpB.png',
        change_section_block_2:
          'http://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5fe97010f24ccf588e3feb2b/file-dNsJ27fhaN.gif',
        login_1:
          'http://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a8a5704286306f807564d/file-t9A8hzOn0A.png',
        login_2:
          'http://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a8a6104286306f807564e/file-UQhVYa8b1Q.png',
        login_3:
          'http://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/614ac20700c03d67207593b6/file-kRh38ygGeP.png',
      };
    case 'cvdeboss':
      return {
        delete_account_1:
          'https://lh6.googleusercontent.com/cyxs8r1naVbHM2ICFipH5Z83d6ePL6nMnWa8T-4p50Eh8GMiXb7NJLjc3CSv7-6_t-LvWwZxjd7peywXr9Zzw5a7QiOvEwi6i_Gw2JDsueLWEYx2v-t_NB30eo2Qnt-mO-JN1V_LaNBNEc7rLg',
        delete_account_2:
          'https://lh3.googleusercontent.com/o5q0ND_JuiebdeQqZoHnbP6QP4cl8cMxxE7hTR-oLRoDhlwyTp3OGZwCg1DSrR9KTbXrQ0thNbEyxvoO2X9neIxGf9l-Oz7aPvlYhcVpi2PJY0LfeOzlrvU-96orgSQPXT8jDNU7JDxN9IBzLA',
        cancel_subscription:
          'https://lh4.googleusercontent.com/-i1q7N1uahZlErD4jmFGKgpyvB9En3uDW7V23_uqsS5G2I-4Yoe-sbNhFwCbvPu5LL99Zv8qnak6ryMt7JRKz2LTsC7Q0tlccri5fhMmxaAZ_m7lpVoeiJDZ9Y30Au1xixqS8dAqlpPMOMH3Vw',
        change_resume_template_1:
          'https://lh5.googleusercontent.com/FxvLXW5HJ_6t8qLwsJdfh5pFyOi42iAWpZqQKb8b2aRYEYQkREK1ItNEx-rSUaTULa-cY7pQyewGY15NvE42QzfvZQ84fp5suLoX27qyShuSCieN3bKeJ5len9J2Gt454S1jxKTZA_OSDvO7Yg',
        change_resume_template_2:
          'https://lh5.googleusercontent.com/rwGxJjnwr_siA7LKVrOCNMfm-NdS_dHlCeOMYProPsp_pC6HRaGDiLgXYaMtyTomTFkh4gRHRaQs5IUHAoLfwIpv6WIZ2t_wZ-VTa-iX6unl4xsuYzCKSgCabfXP0gwhY1uwD1AZjsQ-O5dyXw',
        add_photo_1:
          'https://lh5.googleusercontent.com/GnX3ed6RiFOi8RCZ7NPyPlIwJOGzpwHRQtCPT9prbbAwD2m9tEdbbvorzadGoKajuQmd5rHcxtBZcJKbh7eV3VKMdLCnYiPSc2myCWeT6t4ggCXNhRHi0lS9ek3ZaWfFmaySLph2ABPNiYnPew',
        add_photo_2: '',
        duplicate_resume_1:
          'https://lh3.googleusercontent.com/HHQIfdUrQFQSGkns4J8S8wDP_vyy2_ub90X_dCwzsjHcV8dseBuETML_eDX7tmFH7Vh27qijZY6h48ZAilKzW1f79LLiRJfM9xbjXHjO4CwtQY0LxP4Ym0bm2-le9tAUI7NptpxEkyDZ56OYzQ',
        duplicate_resume_2:
          'https://lh3.googleusercontent.com/_cmWUsQ8EdAUSUzFOCdMX10y4CGfxfCpbkfwP28IIB7QtEQQrpHW-7BMBFMMHzWLMAC1sJgFltDT0_r-JkiGxc0-aB0WCNt3bkoKvd0B6K11tnzJw-QvHmzbysEokn2mPP2G3RkiRK_aqkp3rQ',
        duplicate_resume_3:
          'https://lh5.googleusercontent.com/Aed6ktdUklN6v3Ujhsx43gHlt6plvH29jVkcOo_XKS0ki6yV6nJuEyyP-pjYog1s5Zp1tNeVKvv-fLX18T8e_cvFMUtbr5NkxnVDr4SpDsrWzLBLi_WjKbSjoI6aNsnr0W4M0pOg91cI5u0EsQ',
        access_cover_letter_1:
          'https://lh5.googleusercontent.com/TtccOsO_VUZiCZUaGL9XlhBWXyDWqXRWELtVfOgqSJcRqBnpMmy2NZN5xzT05GGC2H8sjmSxF5vthT2L-b9piR62m3R4Yrsx0niR2YjwMntcofpe3UPI6SkGoUqQZS1inSQr8tx7fWKd-CJhew',
        access_cover_letter_2:
          'https://lh5.googleusercontent.com/2MCwfvZgCgOl2S9ZlDCTv6WCsG3I-6mAD8Opj2qvlHZZE4SG-56xmm5PIHWMN4SVQcXpgYCy-HADozaMXALjxAWZm_fen5JqU2Kwb35dK4tXmsam8i1BrK9KPmIFsDypYPcCC6ivZ7Z1JMr8Ag',
        word_format_1:
          'https://lh5.googleusercontent.com/QMl8yjnUfISuNtRumM6M-2-g50hf41q3ftsE0XwczS4BgbY1GOSKX6nWorPxFqUHHVSeaWED1Au_URtKJMXEAic0-48x-Geo7JjX5tZ6d2k2M7ZUk08569clok-s16N4ifkgl5CVRnJpy2CXkA',
        word_format_2:
          'https://lh3.googleusercontent.com/tb-18idGgjwCEpYa7exRKpxDYjCl-cIHEuPlZ-YlCEOHksQZ_8b3_M-J8vUMHyENlyt599eloyK0qz4oeYz6Rx198AkwKAl_YYpvSqjAPu2gZwoHuVoPzPTAKFmYeAFOmHQ6IO2sUJBVV-AL1Q∏',
        customize_resume_1:
          'https://lh5.googleusercontent.com/FxvLXW5HJ_6t8qLwsJdfh5pFyOi42iAWpZqQKb8b2aRYEYQkREK1ItNEx-rSUaTULa-cY7pQyewGY15NvE42QzfvZQ84fp5suLoX27qyShuSCieN3bKeJ5len9J2Gt454S1jxKTZA_OSDvO7Yg',
        customize_resume_2:
          'https://lh5.googleusercontent.com/rwGxJjnwr_siA7LKVrOCNMfm-NdS_dHlCeOMYProPsp_pC6HRaGDiLgXYaMtyTomTFkh4gRHRaQs5IUHAoLfwIpv6WIZ2t_wZ-VTa-iX6unl4xsuYzCKSgCabfXP0gwhY1uwD1AZjsQ-O5dyXw',
        customize_resume_3:
          'https://lh4.googleusercontent.com/SYjRnQbrbM_N1mvIyTzc_GV9_nwc4RYjiTKKsmiCQrG0yUE-GDUHBsbtUXL2jwM_QV7XVpFVjq8bY5K3x03ev0xLDLrpT8SLER7S5eGb2p1Z-k_t_EkFmk9n1h4C5T-_QG80UbOE0PqkYGuVwA',
        customize_resume_4:
          'https://lh6.googleusercontent.com/PgVqyuFru5pKgMOm85G6F7hVmi7px4QiJwxK1TFIsC2-uWeGd0Y_jUN92Etwztzp-UiykWWDiJ2Yw_oIpD9s7C5dmKD_DGgiHJOVxG5i3_4qGe2QGohydtAo5aPJp-X814WolwIdZV26qkKFGw',
        customize_resume_5: '',
        change_language:
          'https://lh5.googleusercontent.com/yFxfdYpJLerKo6AnGNlCfTySX6cTmkRtL1Qj_ZmG5zNBBGCeVZgGUl4df-wN5z_M5R7z_5GahvfgrUSISubnxvkK4FrSFabf-8il9fAmgdMne6YYPKYtz_42vIEugXNH6m9dMm3BfNsYbgK5Mw',
        change_font:
          'https://lh3.googleusercontent.com/e29cbb7HXHFpVoLOmamT4KLlMqZ0JuE_XeOW8yT8Z6Q0rEJ1yfG9tjQEA6EAnpshML3o1vcS1IM9zchl9nDrrEhAlyJxJHEe5XOGH6FRsLYPA08b-RFdaCcfrnT6AWX2iQ0DbF4jzCVyN2Ki1A',
        change_section_block_1:
          'https://lh5.googleusercontent.com/rwGxJjnwr_siA7LKVrOCNMfm-NdS_dHlCeOMYProPsp_pC6HRaGDiLgXYaMtyTomTFkh4gRHRaQs5IUHAoLfwIpv6WIZ2t_wZ-VTa-iX6unl4xsuYzCKSgCabfXP0gwhY1uwD1AZjsQ-O5dyXw',
        change_section_block_2: '',
        login_1:
          'https://lh4.googleusercontent.com/rgdec986NzPkJ67M672YWz7T2tbIw4ukEYZe15vcjl2qsuRmfbOClT0EmG8WrGdF_Y3AiTh6m1fa0vJnTS8wZ42voTH8wbpriDA-yjN149eeXSpOWECqel5CD621wV3VHwAaiJRSMSQ_85EQMg',
        login_2:
          'https://lh5.googleusercontent.com/HdrnsKEeRRi3NkthJsJEZ8lBDupR3AX7kHREYg_Wr-3zS6V7J7SCUb7lk3eoxz0mWt1Y_HsCzoKkg_LftMYLO0WmB5VRbQHuumPzsMXCMkvHiy75kstpvkSFV774e4rk8n_gVEsOAh5ZNCT5sA',
        login_3:
          'https://lh5.googleusercontent.com/bizF3piOYSefSA8hivlkl50n3QcvfWMW4W9dUTyAUh22R84Gpl8kAWePXoUKbULx0j_4vvUbRNN7CRLUvnuGWP7ztA9g-J7mZ9VMykMdAXkHMQ1J7IVJgo1yccfEuJNYQlYpFj_2X2lqSFM6qA',
      };
    case 'modeles-cv':
      return {
        delete_account_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/612090206ffe270af2a9aea7/file-QUJpISgJ44.png',
        delete_account_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/61209008f886c9486f8dae93/file-Jk2qijNHWr.png',
        cancel_subscription:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/61208f88b55c2b04bf6e0772/file-EsX7aknAMr.png',
        change_resume_template_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/61208db86ffe270af2a9aea3/file-iEFdSx35I3.png',
        change_resume_template_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/61208a86f886c9486f8dae8b/file-fr6gmXbY6G.png',
        add_photo_1: '',
        add_photo_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/612092c26ffe270af2a9aeaa/file-nsWElDyyt9.png',
        duplicate_resume_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/61209154b37d837a3d0e5cdd/file-wEQaWqd8Vf.png',
        duplicate_resume_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/612091716ffe270af2a9aea9/file-XRQPvOZFOa.png',
        duplicate_resume_3:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6120927421ef206e5592d870/file-5WBpA7G28h.png',
        access_cover_letter_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6120909021ef206e5592d86d/file-7MjbvYgOJR.png',
        access_cover_letter_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/612090d621ef206e5592d86e/file-Cve4dumQGR.png',
        word_format_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/61208e98f886c9486f8dae90/file-vcpcs81aUZ.png',
        word_format_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/61208eaef886c9486f8dae91/file-kAdecLE3B3.png',
        customize_resume_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/61208db86ffe270af2a9aea3/file-iEFdSx35I3.png',
        customize_resume_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/61208dffb37d837a3d0e5cd9/file-12qYOFnMOY.png',
        customize_resume_3:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/61208e23b55c2b04bf6e0770/file-sSdWf0R4PY.png',
        customize_resume_4:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/61208e3a21ef206e5592d86a/file-OA81M4dW9m.png',
        customize_resume_5: '',
        change_language:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/61208a86f886c9486f8dae8b/file-fr6gmXbY6G.png',
        change_font:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/61208dffb37d837a3d0e5cd9/file-12qYOFnMOY.png',
        change_section_block_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/61208e3a21ef206e5592d86a/file-OA81M4dW9m.png',
        change_section_block_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5fe97010f24ccf588e3feb2b/file-dNsJ27fhaN.gif',
        login_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/612095526ffe270af2a9aead/file-Imzik8IcNQ.png',
        login_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/61209565b37d837a3d0e5ce2/file-Xp8ctT2NpP.png',
        login_3:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6125dae9f886c9486f8dbdc5/file-G0zZcqimpf.png',
      };
    case 'ejemplos-curriculum':
      return {
        delete_account_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6128bdf0f886c9486f8dcba8/file-gx19NXvR6p.png',
        delete_account_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6128be0612c07c18afdceb29/file-eWLLN4I0Th.png',
        cancel_subscription:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6128bd7900c03d6720751bb5/file-iGS1nquwl6.png',
        change_resume_template_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6128bba26c65aa15b87d4f35/file-FH2PSS8FhK.png',
        change_resume_template_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6128b52c6c65aa15b87d4f08/file-diYl3AdSbf.png',
        add_photo_1: '',
        add_photo_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6128c27c12c07c18afdceb47/file-EcG40QkHbS.png',
        duplicate_resume_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6128c1f12b380503dfdebd9b/file-3V0G6ojNAf.png',
        duplicate_resume_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6128c2106c65aa15b87d4f5c/file-eEz3mrdvIn.png',
        duplicate_resume_3:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6128c239f886c9486f8dcbcf/file-RMyl5Whmb0.png',
        access_cover_letter_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6128be676c65aa15b87d4f49/file-gVBxuvuAV3.png',
        access_cover_letter_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6128be942b380503dfdebd8f/file-M4qUt83Slf.png',
        word_format_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6128bd2800c03d6720751bb1/file-A1R4BdoEWH.png',
        word_format_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6128bd3200c03d6720751bb2/file-uLf7FdeVPJ.png',
        customize_resume_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6128bba26c65aa15b87d4f35/file-FH2PSS8FhK.png',
        customize_resume_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6128b52c6c65aa15b87d4f08/file-diYl3AdSbf.png',
        customize_resume_3:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6128bc32f886c9486f8dcb9d/file-gq0NophDy4.png',
        customize_resume_4:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6128bc5712c07c18afdceb1a/file-8d45XDSyPZ.png',
        customize_resume_5: '',
        change_language:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6128b558f886c9486f8dcb6d/file-ChHSDKqeET.png',
        change_font:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6128b52c6c65aa15b87d4f08/file-diYl3AdSbf.png',
        change_section_block_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6128bc5712c07c18afdceb1a/file-8d45XDSyPZ.png',
        change_section_block_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5fe97010f24ccf588e3feb2b/file-dNsJ27fhaN.gif',
        login_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6128c3ae6c65aa15b87d4f65/file-lN4gmBUrGg.png',
        login_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6128c42d00c03d6720751bd4/file-aNlwYLl5zI.png',
        login_3:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6128c3ce12c07c18afdceb52/file-IOSo8MSGuk.png',
      };
    case 'mau-cv':
      return {
        delete_account_1:
          'https://lh4.googleusercontent.com/tFS66NlxUCfceMtDdQo4yqk-fS7I8N8V-mbJJFY7X9B9O1aqS4hatPKw4YhYY0h1zs6lpGyzbiKnuiXFztMP8jsjtsUUG24c-UwqFg81jtbzbYQNRY2cOd-QJpuLtFCYK7b946s',
        delete_account_2:
          'https://lh3.googleusercontent.com/ldpq4Jfo9oOmG4ymAXe0e8RocCLNjb91pCawJz8jQPUTMBJdC-MBfFfDG8eR4DHYt6vCj5MfFCa4rM5XUxVBjQ-LrWtiqunxoy55Pu2xhFBy0LecEZzfaonDG7O6CXveOqOaiow',
        cancel_subscription:
          'https://lh4.googleusercontent.com/YTRGT30va_Ockj2HkJC6_T6hSiLp7ABokDj05uh1IjZMNFKpPjYdL7vSx3NcrRlMzewe5hM6H1psqsdsZMvp5D6QGy8GNDk5X0vwFRlhWhkRuc75VWsvomFbByQC-tp9REE2YHg',
        change_resume_template_1:
          'https://lh3.googleusercontent.com/pZDlo0F5ImSmyzaPedz5V_4NKrmL-4aG789QokLs1qvq3jJpcSsm3Y1P_5vB0HN9g1JbHb2ZUz9ur5JGe4FL8nj_h44qnl4jAukXLNAkA5VV0pQ7SIswlg6Rt3ad3YnMHGFq3V4',
        change_resume_template_2:
          'https://lh4.googleusercontent.com/6vVglAE9e5QwjZ_gXvqCUvP1MwQ1pDcAOX3VXOK7AttAkbVwkc3FwcLH_yxVtsmrAqMrK4uk-IVa_7AioRnRyDeHVBoD3WIUvXMb1ziY4kyDqoyaFGIey2fK-Lui_HVu6bSzE2U',
        add_photo_1:
          'https://lh5.googleusercontent.com/3VuD-DR2T8Zm_XxKQv0aBbrlQ1gMO0VeCYlcv9KAX2nH5B_PeaPdECWefkMJKCapm1LryrW45nIy5Nmwerul2eMQg-RuwilXJSnjZzokOETVT09IyvvLQPNSZUZxrmkXXL7rl4U',
        add_photo_2: '',
        duplicate_resume_1:
          'https://lh4.googleusercontent.com/H-Z2Z_zvIJFSqFZb7Y8IUxu-9pqmEY5T82fc5v_iSS-fiSD3HV94Whk4-djIYZXGDUVOP7GpCAx4RoORymQBCFy4FnAheogHuygyCVNtEr6jkimewW3cD6rjJBl45MywgD2dFt4',
        duplicate_resume_2:
          'https://lh4.googleusercontent.com/5PBZMJkH9QRULNZU_L5YWzI55gHu9KCv2-VRNfSJGS80zA55vhSs19nNudXZtSKbQ0kcL5ArpIMWIvz-LostSBMCrpce3I3Gqw7OxOc87AYMBqumBnNhCeNV3lzn-2nMSGQjwGo',
        duplicate_resume_3:
          'https://lh4.googleusercontent.com/6LxA2EawkaX2JkF7OviOzyf8h6bmlPe3jxcWBODEvoK_IEdh-VNkOTH3Wt5lywnDMAy8OpBJHkj7o7l4wIh3QQ0SLbO2cbdVnkRn-3O77EFRFLv15UcFc_PRGJ6AlOj8P0Qunsg',
        access_cover_letter_1:
          'https://lh3.googleusercontent.com/E32Rbq2GTzZDl8SaL5NkeQTk2-HqKsdRqog2WNSnOYB9rHSJbxI0lYVz0t-BgodsM_g7oJXiiYhxltdFaWuUZiEUSiJwr3RgPcruC66oxWKxyL870L9DDJ_qU1mMSGdZgA8mBnU',
        access_cover_letter_2:
          'https://lh3.googleusercontent.com/pOT7eaEEaLmp5HUzoeu54E23za3COLNWAvJW32gvhBZNTuT4M43aviN2ecDAIWtpMpD0qX7XOOZeOMtOeKx40cBuHXXPib7mLIsdUmFk1S1CGItjBBo-tmSShY9ogeZ4bhPnCtQ',
        word_format_1:
          'https://lh3.googleusercontent.com/cSNI1Ca1sym7w6N877mR0Kc9vJjsGP17BWj1Ug2imrmrzP1qdzHgwSdwj8_FytCp3J2GDR4ZDZMtjOOuzX89QMdqOvkC2tJ1jBuvGcS1l4WYvhhoRazkY9qKROevuNhGX3cX8l0',
        word_format_2:
          'https://lh5.googleusercontent.com/XcgSDlsObNFORNDW4Bwtxmv9oFD3zvBwSp7wcUY9bSZ5MJp8XmltlAf8G25HTm7Rl-pEyEsSjhdAv6_fQLA1njh5itcHL_mEFzpXW-aXTgie3VsILv1DFYDoGuTJEwrSTO-g3is',
        customize_resume_1:
          'https://lh5.googleusercontent.com/-n9mTKAIDjIfz7dtcHp_ykNh6zIt-wA8Nj2Unuz_ejft8W-YfDB6KFFfxQdlYMn81ZWGCSgX1FWGONul92_vUkTuCGpQZPEeGWd5zXcCUeE-6L9W6MHyzsfZ7GvwFrVYsia1zBw',
        customize_resume_2:
          'https://lh6.googleusercontent.com/3FgrGsbo4nMnhR6KEZ4851bA2iVRIE3v1C_hxjv5-w0mEnpabXkicpIxVVDNTkwUCTWf5jkUt-hRQCLbd1CrMZMp_gPeswI5evmQhgDZXznUFX6fntyM3h2ht5V_4Mup-eHsBkU',
        customize_resume_3:
          'https://lh4.googleusercontent.com/2Et9zWWxMZ9kbO8s6vcQQ_LLyr4QC7sb3iIT3pwCfogjSIlIuSr8gEstDL2h6WPY2S9pG4kVDAN8raiekuDPNq7-5jRxImySPDDVDzBl1UUNrHr0QphRA6n7h0kkh_Ue-LnB7z4',
        customize_resume_4:
          'https://lh6.googleusercontent.com/peppUgZ-Cw2WVX1fZgpv-_DwwZ9CkZj_7wzx168IFDVhaZjIUeK1thE9eQ5xi16Bzt1wDwLXS0gXO_SpmIgwaWKAPCEBV5H4qpI0420IpABRgDwKzGGr-UX9TFiqsiZx3D9XjfE',
        customize_resume_5:
          'https://lh4.googleusercontent.com/zPoqbdTS8Q1xILBCDES1MBvEFjheqzDE4v3mHC4UFdFkoIM8eRqSbm6qNHffM1YWPs0r_YZd22IwwAIuVJQ1YqUeuZvHpDnmrpjFY4I3tkTmUVmljc-iHAy7TgVuOd2f6EtwGHw',
        change_language:
          'https://lh6.googleusercontent.com/_SWQDoLfNSNdgxKOZEwpFGMmno4LZxzqvkK_IUlyuFmj23y871YgpRmSejCHfhoESHN7DaMQavmJvW2qmwgfv6CyS628uNVrK7h8Y_TndnSCD947zJ6Fhww4DZ9E94Kovo2WNbU',
        change_font:
          'https://lh5.googleusercontent.com/v2POg4wP9Xf5GhZs7s8glj_PptjNtMN8eNRqopbfHjmW-CiweriVurCK1AfZPA3NP95DYdfmPgrcpH8pJrkkApsr82lTQh5lGAeW7fpYajov8RTx-r1SScntTN-ox9IjTcDcNMQ',
        change_section_block_1: '',
        change_section_block_2:
          'https://lh3.googleusercontent.com/PNXZ8rllcCSeRsRnTpMEeRvFv72GATa3oPBfNAPto9liMVSZXZ4BYBh-kesyyCxQ6vtpSPaicduPqs4Vla2o3BP7iJr427pa5nNjFVfd2-4n7A1HmIUbBJ8oH4KX8TNUF3vJM8o',
        login_1:
          'https://lh3.googleusercontent.com/uHERBVniwBex2mqU9XjLi6lo0ybTNd1eWlsIuTc9SFlP74ZXYsdSPnSrn4DpJzInqDRop1aOIFvjqbkwUUqQJWE9zhJfIg20v66GH1JdYTBdM_xCd6h8P3-k-RfMz53n2EMzdOg',
        login_2:
          'https://lh4.googleusercontent.com/8IgQiTMAY-QPv36M2GuacFefkdQgtpxhhtC5AqFmz1Tv-Le_Y1_faNZGWS5l-vxSP3-kY3JK3om2uaQWuhNkEyciDpWx2us19qNRXuKb3C8IrH87Z4X8WQMBBu-bDFIIx6QniMM',
        login_3:
          'https://lh4.googleusercontent.com/-SK32d1b1o1e-3Nv4tis_L6hS42opOQwMP_f6NSSCHDr3bk-OVQhs1Ky-mZ0YQIGZKZ7QfrGtuBLTL8TX22Ss1SshKeiUnNTGJQMXJzo43Fy6mra0_49ZQZFiCFGGL23huVpg-w',
      };
    case 'cv-pohja':
      return {
        delete_account_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6203d759025ca67522c7966c/file-ZWzvdG8NYR.png',
        delete_account_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6203d781d86136157d9a2675/file-SXMEPJ7nyd.png',
        cancel_subscription:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6203d2ead86136157d9a264d/file-l2lqDT8MVS.png',
        change_resume_template_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6203ccc6d86136157d9a261b/file-PcSEVu1F6E.png',
        change_resume_template_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6204de3339e5d05141b6834f/file-SC6hGRFK5E.png',
        add_photo_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6203d8cbd86136157d9a268b/file-Erdlz409eu.png',
        add_photo_2: '',
        duplicate_resume_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6203d87368cd260cc2d3956e/file-tVaAXG2Zsx.png',
        duplicate_resume_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6203d87d68cd260cc2d39570/file-daiTKFh3JZ.png',
        duplicate_resume_3:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6203d88cd86136157d9a2689/file-C1FkTqOe5X.png',
        access_cover_letter_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6203d7bbd86136157d9a267b/file-poFGvoeGV3.png',
        access_cover_letter_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6203d7c168cd260cc2d39561/file-wHs3H1epyx.png',
        word_format_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6203d2892130e5169468573f/file-LADN5rmtJE.png',
        word_format_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6203d2b739e5d05141b67e1d/file-cQXYomVeZv.png',
        customize_resume_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6203ccc6d86136157d9a261b/file-PcSEVu1F6E.png',
        customize_resume_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6203ccdd68cd260cc2d394ec/file-bFL8UIdT7l.png',
        customize_resume_3:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6203cd08025ca67522c79609/file-l1ULKc62Ka.png',
        customize_resume_4:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6203cd1d025ca67522c7960b/file-GvCy2EJYz8.png',
        customize_resume_5:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6203d21768cd260cc2d39521/file-xZJoynILtw.png',
        change_language:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6203cb96025ca67522c795fd/file-u8n2WHnCuR.png',
        change_font:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/62050a2468cd260cc2d39b65/file-BEYgi9Vi1r.png',
        change_section_block_1: '',
        change_section_block_2:
          'https://lh6.googleusercontent.com/YAa8D870qXgdtues87AuItLJOaaBZsTbT5jPq3qPbBrN5S-c_T6emNfRJjndF52M2Dtb_7QpuZufnvfQrOZjOgBLU48zw_gYSppQR4BNj1FJYj8S1xOr-vwvzAfWRYgrjCc9qmk',
        login_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6204eb5668cd260cc2d39ad6/file-G30zZ9iUmp.png',
        login_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6204eb3268cd260cc2d39ad4/file-LI18jfh7rq.png',
        login_3:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6204eb02025ca67522c79b91/file-ROBOgFuVdH.png',
      };
    case 'cv-skabelon':
      return {
        delete_account_1:
          'https://lh6.googleusercontent.com/ERcV_oW1EyrAnNtbGRk1VppvyWgAWpGw9732Tpfh_1XsMfrP65apu3DOgwvtTQFvH_wkYiuv0e3vWlZYRzti0amgcgxUqyBVkLB3yOEbk9JcS7M7zzL5VtzMQijGJqtXmlP-REs',
        delete_account_2:
          'https://lh6.googleusercontent.com/yNSP0SsTah6IGLSETfjULYgyt-RXHfB6ec77lVrSqCG1kHDHlz1T3h01W5DKnJeR5TXJQcHdA548LWUdgfte0jyPtheVPDMfpRuh4CNxBmf_qzv9fGjJUrydR16LeSTzdv4jdRU',
        cancel_subscription:
          'https://lh3.googleusercontent.com/Fj9JMlpl2qRX5sYBQ75lOx0PA8msQJMZeGWSAx_Dv3QPUSLBRqH3khYMfIBxrDOURPqAy7FBz_TsqoIJjdZKjonDiTAC15l_qKAbZzusDUtVFUczjBFVC20cXEMdPR9RCO3Ft-A',
        change_resume_template_1:
          'https://lh5.googleusercontent.com/V0M_tSVZLbdu-0YEziccEd0PEylfCgqWL3glmZjIbI-0W_UnOu9pi7ffc5nnszYwR835e95MC1X4Ws2GylCHM12AwJuHf2xvc3o2MGpxtaIYlFr0WoZfiwJkOLmvZAZqu2zaX5s',
        change_resume_template_2:
          'https://lh5.googleusercontent.com/ugf2ll84Lp0PMCaRwc_6H6_xoVG3x-tM5KtFWANfrXfQrTbHRTv2yWVH6PyLcWI6rGJroAYzGBraaTlxjRzVBWPZ0Fq7ShhucA6GQ4zrAtcSmTvF6iRmkMJ76U2P6ZGiwwUBXP8',
        add_photo_1:
          'https://lh4.googleusercontent.com/fK5zYMsg-bFYQS-IOff6nqc-MxNiwTIzTYszkrVIBeSph7pIaASTuilwm5NnvMyY1_6iDKLEggE2UC7omSc8HWpT6ch4mzRqhrpVHiQU7jDkPjs_79L9o7SoHmvwaj1YsG2ljj0',
        add_photo_2: '',
        duplicate_resume_1:
          'https://lh5.googleusercontent.com/r3wDcZp6xXvpryuQkkBNn4ozhxqnwiph5graB7g0ketEDNldmim4ND3QuTuS_dD4kMvOhjS_28HdWtrwkdY6HhZe1L5GxHbtRnbO1Zle_tYVVXzbT3rG8GDpyEaciMBcjj_5BbQ',
        duplicate_resume_2:
          'https://lh5.googleusercontent.com/ECRueJbsIXwZTwnd_gATTyojXj_LHdEIf9bd0QyGOHfEEKlPhxj7DaBcBK-XRKNoxDfZlDxM4Qt2Pd2Z8Til_OdmsZ4SAKcGVG2A_FCGIF7W4a2U8GQ_DPSQIjKCn7mE2dYd1Zs',
        duplicate_resume_3:
          'https://lh6.googleusercontent.com/zQJm1ICaeHji3hL6KSTN2XoirTsZdVg4hSjbNaPGI6fxHLurAK6zjO1SkYS8QtTPx9AyCcYzPs8inNoSml2Zz3ppXiyfjihSXhUikpiTebBP26NMMrCSuLPfYGk5iSad_ChEzYQ',
        access_cover_letter_1:
          'https://lh3.googleusercontent.com/LGjpadGkFFKWlwlN_jz1C8Y9lzVDn81iTKdlaBhUYdb6Wp6bnZHOvP4VBbTg3rBQjifvKjcGRSo5zDXPj6brA1Pv12pY49UZjv6dmWfIIoWkFSCsIIG9ioqeyeoQS3P-Kz-fsxo',
        access_cover_letter_2:
          'https://lh6.googleusercontent.com/GIFQ0tcC7edc-d3elIKDkaSNF46jp2kCraZkvj9onHNAOPR7hrBCk_6VmLrMjgPnDqNzTaHmTGh37WLBRzGoFyYbJzuukVDWDbjkJhBK1PNNoZJmKlpt-nASRr1aWbGSoCJLfV0',
        word_format_1:
          'https://lh6.googleusercontent.com/6jvD4YEjlIlIjhk_CvFQMjDgNEZvMxQFuoesMTHX4vHGF_sxKAS7KnC3D-lILea1AGZ4AvMI2ojRDZ4qCUS77W7-OBtRLcK9U2ZKER1VBsJdDx_YGGc5OD4pLajk9qIvXpE9lnM',
        word_format_2:
          'https://lh5.googleusercontent.com/jzZUVeHE57eF_ticbKutjVR2myHaEvjL5dbmeaytSd4bU64OxH74wkWm--mDFiXTIL2WRkFIVIwnIHeVU7c7X28G4GqMXn6cTuNEPSvFcjTS6pjyC30p7iVVqJ8n0oZ2WisDUkY',
        customize_resume_1:
          'https://lh5.googleusercontent.com/V0M_tSVZLbdu-0YEziccEd0PEylfCgqWL3glmZjIbI-0W_UnOu9pi7ffc5nnszYwR835e95MC1X4Ws2GylCHM12AwJuHf2xvc3o2MGpxtaIYlFr0WoZfiwJkOLmvZAZqu2zaX5s',
        customize_resume_2:
          'https://lh5.googleusercontent.com/ugf2ll84Lp0PMCaRwc_6H6_xoVG3x-tM5KtFWANfrXfQrTbHRTv2yWVH6PyLcWI6rGJroAYzGBraaTlxjRzVBWPZ0Fq7ShhucA6GQ4zrAtcSmTvF6iRmkMJ76U2P6ZGiwwUBXP8',
        customize_resume_3:
          'https://lh4.googleusercontent.com/qLtAcKgera5gBChyJaqWJAlGKOGt6rTsS8bNL57dzeScu4ryi-AEDuNEr_R18pCW4g2l4gYmJ6F5vNmjmmmOeRNX3rZ3k4ND_cyrmzNPdTKIZ7odGR-lPgHzer0y2jRT8veORPU',
        customize_resume_4:
          'https://lh5.googleusercontent.com/TSnJ9lQHhz6A8WRjZdyajhbTIy2j-XgpQpymVTdPEjaC47pmO1zvpq5diUnJqEA9AVBBI6ai7cM4HJW0R0P-OkzyGNe0PqIucd5RjKVben3uU-zPf_Pm5GE00-Uy4KubWBCAxOk',
        customize_resume_5:
          'https://lh5.googleusercontent.com/ugf2ll84Lp0PMCaRwc_6H6_xoVG3x-tM5KtFWANfrXfQrTbHRTv2yWVH6PyLcWI6rGJroAYzGBraaTlxjRzVBWPZ0Fq7ShhucA6GQ4zrAtcSmTvF6iRmkMJ76U2P6ZGiwwUBXP8',
        change_language:
          'https://lh6.googleusercontent.com/-wNmD6gGTJ2KBNsCIkeTfYW46N7wY_XN6rV9Coixj8sq4nSC6XjmInQEwhj_dRva_UwntGq6yyYpqC_rUbxkeJZE5eDXzzOAwKF5sX0YKzbZouQR0lw9pwFhWOZXGvPejXnubJY',
        change_font:
          'https://lh5.googleusercontent.com/ugf2ll84Lp0PMCaRwc_6H6_xoVG3x-tM5KtFWANfrXfQrTbHRTv2yWVH6PyLcWI6rGJroAYzGBraaTlxjRzVBWPZ0Fq7ShhucA6GQ4zrAtcSmTvF6iRmkMJ76U2P6ZGiwwUBXP8',
        change_section_block_1: '',
        change_section_block_2:
          'https://lh4.googleusercontent.com/OUBWNAFQalt1h7325qnID1yS-Kr_0jqACe-xw4RcSZ0Rje3u583qPWUwjQDAtapV1_fBpiimdj2XPOXgCty1hY1XVsxzGxuQ1bb_1pnsMxAuhS7KBD31Yde0tjqx1UEfq6qUvrg',
        login_1:
          'https://lh6.googleusercontent.com/SGgeM2ke9c1KKxaXaYC0I_46SYNOe0tw4hwHLpMFCUYivVrHPUxNgD-0ybAwsSkxqF8G62p5-Nm_lm2LMW6od8-UL3HUf3qvQVEmr9-JIsF2gnrgnZIJ64U0aNeWZbmEqW1ilYw',
        login_2:
          'https://lh4.googleusercontent.com/aJ04Mk4CeC9cxVE8HqwBvo1Ll8cmeV6KiJRxqeBGyP9eL3XGY6J1DIWKR394qMVyyDDDodth8xHDv69IvKe_NGQHyWo3jKcvy2ukGSohrwYiUlCJaHhNn_LCzRf2mPmjNir1i0w',
        login_3:
          'https://lh6.googleusercontent.com/HeW7W4fLyyESAn-sg3vwjZJlqVMe3IsBpZienSCzprlPUcn1lRYu_JIoovOcasrYRqzPrOdgCGaXO-K2nXBPiZmk53rV7DOvYIIIbq-NzXQU808gtNRZUnHiqvOEirutdtcUEPY',
      };
    case 'cv-voorbeeld':
      return {
        delete_account_1:
          'https://lh4.googleusercontent.com/jOAkEC6wdyCLmDRgJU4fzrc6Ce0Fs_CA3ITFuR3eSyuJiltNh_F1P3h0qSCzVGBA5gI8ip8nV6FiQ-c0ldoB1x6OB-N03KQ5mO0zjvEyTUpr_sNH1kWKsYJVRjsfHUhFaEGDb0s',
        delete_account_2:
          'https://lh3.googleusercontent.com/S1T4OpRYvkTJEOA_ObsAvOrCYV8MKyJvZkscK6tZl6xtd3ZPWD_eg59sywz1QZtqMjeB8J_wLgKYyxm8Qe7ylof8u_ewd6lYAQeh-xWL95COOichfev4KixOro5FUmpppEi3qKk',
        cancel_subscription:
          'https://lh6.googleusercontent.com/Yzu_5f_9UhcS2Pb51Fd_njHYHao9WW1T2AwZiNU2yFZpJB8JIUD8lUagpmQWYjc0GeIKnEIm1ePBoy27EY93f_p570IZ-EweUtfy0Y0aDCylAnKDgmTGlKs4n8IyNWrdbzbEXvM',
        change_resume_template_1:
          'https://lh5.googleusercontent.com/gpmQhVbqCFBOT6h1Z2fu3B4asT9Yx4HiG4NmCi4DlVK82BNxzF9GouyPlS0pTh8yZ3PQuHqpFzAkwccAl-3sCEy4Vz7swW1CVLRVpzO-IHjwfpmpeP2gv-XAvdlL9Gs1FVwGVSw',
        change_resume_template_2:
          'https://lh4.googleusercontent.com/-VRhRQfvMklBglEf7G7KzeFjd1MTvjzx7IHEQwjud-Q5qwDAdKIo4dm5qoZfGZUnUGNpA2VeYHc1qKTEy-YV4bOdZwJrBKbmeaaGXFa9Hm1Qz1ZmkUSxa_X-oLod4_tBh5mlg5k',
        add_photo_1:
          'https://lh4.googleusercontent.com/Tr1ZF11zX1UnZCmK-biJqcT7qVxn1oikNeeAU_Y2p8tePKkWohWiB-19lapmAzLNJGR-0d2Ue1gt4nVCNwmJOcQwChuHhh54GQy4S7itzH0gq4Vz7BTv464Djk6UtYqo_T3mhSQ',
        add_photo_2: '',
        duplicate_resume_1:
          'https://lh4.googleusercontent.com/1C4X56XOAu2pvyUzbTUekQwgom8z39_uAZBD5bmm2es1uTtyo8z_ufFDV2OgZFdTwtqAJfgyIpsFWHm_YFemMs9n9jMug6leK4mRi51dyuQ6Vbs0CKh9xzK0K_30Y8ISfU09_oI',
        duplicate_resume_2:
          'https://lh4.googleusercontent.com/7XgBL-9NemuZh-mOIzrhozuNC0mgoNassxie3AC2i1h9Jwvm3Kg_Q32CpYwhpRZP_t0mEZ6UV6e1ebysb5y53LcKL86H-icDZ8apVR-w4owQYnPtGiY10cJI_IdiGw7kQ5lxp5Y',
        duplicate_resume_3:
          'https://lh4.googleusercontent.com/GCdwueePuqQOw3tCHWogb5Le2lHxYiFEsqINWhxoAjFpFmZBasMryPXVnjC2fqL80JaT0eJbP_VwxuThQXS276Gx-BI7tlvdO4zmNtmQnj1NX-JFovj_ejUB4b5z5wqsD1GsO5U',
        access_cover_letter_1:
          'https://lh4.googleusercontent.com/78wA3d2Bx3XraOlCMWA-ekiG2ofXki7JBPYPk0ovgXjBmQPgwQsEBjY8Tp9Yoq14SLD9OwJTf3jH2ITSHFMxgSWXRC_7EfMI_l0MILan4Vo5RlyxrLZXB3MjiT7XPtaQf35sRrM',
        access_cover_letter_2:
          'https://lh6.googleusercontent.com/VB0dOULzG8YPMhonnIZWULN6cGuJTvm_t0DnuSlk2GZDX-s0A0CZbWmkcxrWVvzdaPJulBEE15uNPe0g3TWYFGjTthIHauHu7QzCObRRZwXF2mbkDjkER7H0P4ujTn3ezj8Y-ac',
        word_format_1:
          'https://lh6.googleusercontent.com/NdzWnWMBLDena9NLMEI-7gXrY9pE1bA1orVdF5-6YfzlcVw6qtDCRwlyQtY7I5nQpV4sfNXAPnahcARSc0q2E3x4NxrEAQqeMLBcIthfRjs4P74v_Qtdz10hohw-bhsemLGeD1k',
        word_format_2:
          'https://lh3.googleusercontent.com/KaEhusDAKcgnAL8vgeGQ8KYT6Dib6sAhtnUMjFYC2QqljSLlk73ESVAGtUt5M0eZ2rya4poTfVCkRGL9nlF81tDR6w67rgHJjHZ0i14Ii3YCudh6k_G0x64BvBDlJDgbH5ZmY1g',
        customize_resume_1:
          'https://lh5.googleusercontent.com/gpmQhVbqCFBOT6h1Z2fu3B4asT9Yx4HiG4NmCi4DlVK82BNxzF9GouyPlS0pTh8yZ3PQuHqpFzAkwccAl-3sCEy4Vz7swW1CVLRVpzO-IHjwfpmpeP2gv-XAvdlL9Gs1FVwGVSw',
        customize_resume_2:
          'https://lh4.googleusercontent.com/-VRhRQfvMklBglEf7G7KzeFjd1MTvjzx7IHEQwjud-Q5qwDAdKIo4dm5qoZfGZUnUGNpA2VeYHc1qKTEy-YV4bOdZwJrBKbmeaaGXFa9Hm1Qz1ZmkUSxa_X-oLod4_tBh5mlg5k',
        customize_resume_3:
          'https://lh6.googleusercontent.com/pkOd3EaJBHo8oIQ_M8o7AL0enwexvJuDuwKjg28coCiGhYVP9rM0EO7EfH5basw-Ay83B_9-EHx0duPCnzHxbN432fNWbYtsg1R9xAEUCpIjDfChe39lkDcf3JY6Pd5M-eOwf2Y',
        customize_resume_4:
          'https://lh6.googleusercontent.com/s5BQVuthWXbozninKBOyShSISmsP3qvvuCyeyPq38yg67zFdVOLyQu0WT9EEZQUF2c2HsmjZrxKWmkrBNTZ8UnR_raPDdEifF7WqLmqMk-ts03YkGAu2iuBuDKtNHxdmp3Itryc',
        customize_resume_5:
          'https://lh4.googleusercontent.com/-VRhRQfvMklBglEf7G7KzeFjd1MTvjzx7IHEQwjud-Q5qwDAdKIo4dm5qoZfGZUnUGNpA2VeYHc1qKTEy-YV4bOdZwJrBKbmeaaGXFa9Hm1Qz1ZmkUSxa_X-oLod4_tBh5mlg5k',
        change_language:
          'https://lh5.googleusercontent.com/XElCt0GH7DObWP_uuKgBzS3offVBxWNJi5yaxhwlP1l3TyBzgkkteHbnm7iFLR5h0uu7y11pwG74YVKyTiNGjtO4xX7-nrmC2ZPQv4g2lHcO7JkbIkIGyYEjS0ugTWdVEH3C9L4',
        change_font:
          'https://lh4.googleusercontent.com/-VRhRQfvMklBglEf7G7KzeFjd1MTvjzx7IHEQwjud-Q5qwDAdKIo4dm5qoZfGZUnUGNpA2VeYHc1qKTEy-YV4bOdZwJrBKbmeaaGXFa9Hm1Qz1ZmkUSxa_X-oLod4_tBh5mlg5k',
        change_section_block_1: '',
        change_section_block_2:
          'https://lh6.googleusercontent.com/Nx4i0HnVC3HyOGghBnq24zL3qYbtKB0_EV93mauHRJ4hlF4CU-Ogtva9bvsAzcz0Az6W3TEiCIuPtVp5Qdbtly7UUY4uhVMECszxNFXwuQXm_6GOiB3am7WZuuNgpmg9rbwzfeE',
        login_1:
          'https://lh6.googleusercontent.com/pnAXNJogfRB3XKttBFsV89xpNz2GvlSQ9fPKD1xo1Wzx57Td-THpnZFTwI9Wm7x_LdbY44VbLgVRqGx11f7-AMIyNSbyDurOpF9xZjcmxfzwHglj-WV2YV_dOuJjRDNCaZP4OmA',
        login_2:
          'https://lh5.googleusercontent.com/BLmrP1jqi9sAT8gaj8fAt-0MpZMQDJugOgg3lolY4QOXzD525xW6coIiYdEMxmOx8vSMb871dyRIPLUWn5HNJB1O4QLqhllK7TQTGpPtTqlq709DrQY8Zq4pMcXEEWcj7CcxYD0',
        login_3:
          'https://lh4.googleusercontent.com/xtjAKRIseEiQ_zSO9kkOhMqojgaGxVI9rcqx9gfEschGTu2qezLC3t6-yn1GLKOfPIksxq13MQUfA8h24rkHhvdq4yMgRvgzhNFALsF2cutqnux140AXUTi2mhYTudhYVe1tSl4',
      };
    case 'cvminta':
      return {
        delete_account_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/618e56c5efc78d0553e59a4c/file-7zIhgTS9V0.png',
        delete_account_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/618e56e664e42a671b6365d7/file-vw6hcr3Ew1.png',
        cancel_subscription:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/618e3bde64e42a671b636570/file-q4NHEXdDro.png',
        change_resume_template_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/618e0fd8efc78d0553e59931/file-ApSgcAujrt.png',
        change_resume_template_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/618e0f85efc78d0553e59930/file-18brF76wi8.png',
        add_photo_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/618e58b3efc78d0553e59a5e/file-hVxpFkVYzu.png',
        add_photo_2: '',
        duplicate_resume_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/618e57a99ccf62287e5f5da9/file-ZM99VcRw5k.png',
        duplicate_resume_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/618e57d4efc78d0553e59a55/file-Ff6DFfmd6g.png',
        duplicate_resume_3:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/618e57f464e42a671b6365df/file-UQnxL4NzzL.png',
        access_cover_letter_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/618e102412c07c18afde739a/file-tNkU48IPST.png',
        access_cover_letter_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/618e575012c07c18afde74a4/file-2JK4QJ6LeG.png',
        word_format_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/618e3882efc78d0553e599d4/file-LNbMvM7BNx.png',
        word_format_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/618e38a264e42a671b636560/file-aMmzUsXh4T.png',
        customize_resume_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/618e0fd8efc78d0553e59931/file-ApSgcAujrt.png',
        customize_resume_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/618e0f85efc78d0553e59930/file-18brF76wi8.png',
        customize_resume_3:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/618e10fe9ccf62287e5f5cb6/file-p22vUzvR6e.png',
        customize_resume_4:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/618e11c82b380503dfe04458/file-cySicj5SHz.png',
        customize_resume_5:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/618e0f85efc78d0553e59930/file-18brF76wi8.png',
        change_language:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/618e0f85efc78d0553e59930/file-18brF76wi8.png',
        change_font:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/618e0f85efc78d0553e59930/file-18brF76wi8.png',
        change_section_block_1: '',
        change_section_block_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5fe97010f24ccf588e3feb2b/file-dNsJ27fhaN.gif',
        login_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/618e59feefc78d0553e59a66/file-HOAad2dLzC.png',
        login_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/618e5a2befc78d0553e59a68/file-1CHyEF6swq.png',
        login_3:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/619b2d34d3efbe495c3b164e/file-ZTlMtXcJg0.png',
      };
    case 'cv-wzor':
      return {
        delete_account_1:
          'https://lh5.googleusercontent.com/NHw5f7xgbGSSZHYhL7bW7gQ5n0ja6XzMJBuikAF6ZsTUPYDff6mx6VvydmDmRfFfHT849tot-PLYDG_y8ZakxXbUfU0f7yM8Z0FwMjdTxdOHF9MI94ZP2sgdDRAnwZOdUklV1C4',
        delete_account_2:
          'https://lh4.googleusercontent.com/zXfgFCHMMb5DimYLBi_yzSPjdLIXl3jMle5tGnUvW6heKAeMwqd13yaTyVzwR2EquU6ATxzw_K26kTafsGvtZ0jSV0py2KviyTyw3TOqb8PWpVfPKOB8GbJPldw5ZU6jXSVPKq4',
        cancel_subscription:
          'https://lh4.googleusercontent.com/5dRP-biGHBhMeI165-Hy_HYHkhtj6h1g_hco4V7nPv0qLH_1Kv020vInYch6eJO19fKdj0e2Tb2kCxLZjIjA0apCm610Wv4Drcka-DzI3DojGjBOkznwcrUBH91vYuoYDKvVjfw',
        change_resume_template_1:
          'https://lh6.googleusercontent.com/23Hv277UgZMyJYqubT8xZVsnGoDJQ9-WdV8zcIRj74Bsks7XapejG8bPOO-bGsFjrMk5WPXTq_EGfdF-VYyD_vhnSC78SoEG4niD4lcUeF6pxbjgeLNmoImZwl0GkPI0cWrrOdU',
        change_resume_template_2:
          'https://lh3.googleusercontent.com/Aadp5RiDhAK2Dq3JZRv1V2Ln1PkyS5gJmpzXcUkRYGNWX6AvfR0u7nZs-TeB2YZc3o3huloErnbRd9yBAeXyEPYWh-US1pKZefvubaTGQOHDliDMdgB1L6fBVu5Hws28qlEuHiE',
        add_photo_1:
          'https://lh6.googleusercontent.com/90lO_pcCILOIDjVrZeyK--W_gvR0XOLwJ7UAHqjwVpXk_lNvoOC3nKdKmBJnaDmx5nBIODrX70Sh_YhiB52DcNtRE0S7Q2HvcKlyw9I05MJWRIrR1lPwnlOGrY03LRmg0f9emzU',
        add_photo_2: '',
        duplicate_resume_1:
          'https://lh6.googleusercontent.com/pYHRI3R3i9yY384j-7P-Sq-o5PSXO-ZKJn3tSkBqJP_eGNvDVhaVlsUlx-Atd_k7o5dkDFcsEi4F-4O-vBdROAtOuQn9vdFoX4gQd32LsIl45MiDR5w9alnBED5poSk-MceLhfg',
        duplicate_resume_2:
          'https://lh3.googleusercontent.com/VJgA-lanVnkzZm84XYstFIvqbKjK8FamZRMWW-dw0ia0eVEWkQtFh2vDKLdTQH8omTRDD2Baaa6QznBPb-nfJq6FupDrgk0Ly55Qj0ADiyGFGp_g0qIjkD0Bf7jTWxX5U69bS-Q',
        duplicate_resume_3:
          'https://lh5.googleusercontent.com/DY_p8JkAOpYfKYbKb8aQBLwZp0xbPltwDS1UKAtAsOSAQKzdpksYGzzwyOtYbZbbe1-3V7VCGxG6zJJH57Y1Ww17xlpDsQc89Dj_KJkcoVpRKo-1lujR0_dYPY_dj_CxamodXxI',
        access_cover_letter_1:
          'https://lh6.googleusercontent.com/PrSExGyxjlnQNGSIPRZfyunJ-NhR7dUPesJ5mGGE5LsyKLMCdfXB4-QwdrzPhjwMxZU88gL2QRLke0NN1NW2y0mkjk7Ij8yiONvdRdaggrx5RuIpyGOXABrih7JXBsUKFaxCtlU',
        access_cover_letter_2:
          'https://lh6.googleusercontent.com/7O9_QIwIFFD8ih06K_W_CwbhptvTEwQ69aJf-67YZnVjF-bHOe22OJ4dOZhQpHYr19A7Wm-DTD19zrEnCP3pskB79_VEdTAFHZamxazta98Q7Mp1pZbfqdmB3H-9qcUVnc21gdo',
        word_format_1:
          'https://lh6.googleusercontent.com/CwFsl9cAli0NMowASy3bJFJXmFCrk15cAF5Ebtgr8Q5Ku97UuNitVpGjClBH-lyLUETHVvc2GZht-LlMgYk3vGbhjydW6PjPKcOY4b7idezoktvaqfgXlV2GHtHPKbERdwm6hwg',
        word_format_2:
          'https://lh5.googleusercontent.com/VQy1Mg6FqMaYRVHAZpF9AUBI6Y1x4d30GtTFGKoQe0gG-G_St_o4Hqf5xPxyMbs68FEycaV49Uua84EZm3wWcBDEBAIqSiOZWDOuEw4jnmHlpkZw2YAPUyhN4J1eEiWspIxBN4s',
        customize_resume_1:
          'https://lh3.googleusercontent.com/zqcGmB8tZbDTEIk9ANxcv_5SID6dHZlkT7kNcnklXj-fenapVBYvTOd28tHwmeVZFgruVsl700HtEzOuRSYuNodo-Cn7G1J6RGeElfXzpPkIROwDse2tL0iX8rF90fy5Jcnpp3w',
        customize_resume_2:
          'https://lh5.googleusercontent.com/OGRKXtqNBiqxxTPi55TqCm0lt1s7RPQOzRH8T6YIdm-gEcIx9KqjHjyQ7-btRdAqABnXZNtDIJKbgcKEja8efawww6GlS57Mf3l7dL5mup9FzAochQm53ny4EUijzQ6__ALUkBw',
        customize_resume_3:
          'https://lh4.googleusercontent.com/_KbuGQKU_A7LXJ1dvumxQi2GI3VFFm094mkiaw-sUNdeITMbjFQyxhDcsczGTWtvouWQB-RCaKu-1XSg8K41s679xw0zoU_CydoSeenNxkkT54Yb1wC466XUqdcpmiDx1OX6CYE',
        customize_resume_4:
          'https://lh3.googleusercontent.com/P4yVWANZnJZbzCRsEJDDvq5__YcD82sqZWNtDKOsAr0gZzm488BLrvlnW82csALw4B-myPfTomXCejpWZA70Ql_JnByuq4JA1l3-Xz2IAvQ6APgoG63ZGdX2OQeVsSU1xzGMF8s',
        customize_resume_5:
          'https://lh6.googleusercontent.com/MLuzsZJkT1mffNlbO8p9CXS65ntorNA5D103rv4REDYpQhUsgL4i3p0sUqNvGog3NbeVocFjgsEhEyOb05ifYLg4uSk7nLNBsUVTOAXVIU_rbiLVuaO2hEHBI5dqSUZFt5vJI9Q',
        change_language:
          'https://lh4.googleusercontent.com/viJrNuHUQb8Cd0tXnqOuvfOza9FfCKrW11TpxhbnbYpjwl1IKkkQuisP5rsmCVPg7NkB-0V_SRCs8Jx_0om108FME3vmzBf944PMeU-EngCzOZTMZfqu-F8QvXPKOy4DF7RB7TE',
        change_font:
          'https://lh4.googleusercontent.com/3BR7K-olzZ-1n-p1ErshMs_o-RVKv53EQCa_KmoVKRd7MmA7mj1HmPSj6863rJx_6u95ucsU4wC7AY7sELfvbQG1ome4BzArmdegChfHxgY-a620B-oH6TFd6MFYL3n1UUHXI0U',
        change_section_block_1: '',
        change_section_block_2:
          'https://lh6.googleusercontent.com/2qQecs7LFaik7NIuBMTxY54ENjpM2BSpAZ_MU8_GhR9SF8DOExMrkligBjC_zCVPUg8Ay9boFGoY-CeciYGXwEKcZJ5r5jQ2w2iNifRfs4CbRrrqze22g3YW8HWV_kWSaqObukE',
        login_1:
          'https://lh6.googleusercontent.com/v5-lNi9jo76NTzAebo16zIql2DboLwGuFwMkZEQOKBsg856d5BrT-CWljqR-Ul18ir5G7wuSnIaRbylIPMHcPnBdtH1MWxuTVdmEfANMJeQ0QSnLGhKz2S5YrPc0tue1VqgphNo',
        login_2:
          'https://lh4.googleusercontent.com/uRM1oHOj9vAe9C6veGtrDr9bnVF8KM-hLU7qm_SpW7mybT3zJCwvWPZzj0GfkW9rUoxa03LkLnjxC6lMhYISCXAw4uZ-UcpF6zkXMJdl9DsK-P8zSETkCL5uF1V7Hilj8kTLJOA',
        login_3:
          'https://lh5.googleusercontent.com/vonutMINhgDS-SH-iDqwtsY6DJJ317X06ACQAQXrty-jXeHqR1Iiq8PRJ2yCk3rL3wYcU6N8V2QaSExWK4R8m6BD2Q16t_2Pm2llqDdaurTaZQRBlE3YJiZbm_XzmhdNrQnIdNM',
      };
    case 'cveksempel':
      return {
        delete_account_1:
          'https://lh6.googleusercontent.com/PMauwXgZcFHwuXU_6zSVqeFKyrvQWMTkh96aNX6ov-B2-eeSZc1PbCeboQJcV8wBjT8gDdvoQazthlvPfCdLTuoWkhMfANrVfU_COuMM3j-jw1xPC7kpqqcm6ATCWa0G1Bjhvyg',
        delete_account_2:
          'https://lh6.googleusercontent.com/btBqqhRXmKrToyDais6n7kFv_iWQeAYPtg8yozXaUPlkrEcDR_QnlyXBjNaDR4YKeXS41Bcg9s6JqTrolX68FOLP451jS2kjvcJG1ezlLiLXnv7TLepajiDp5C5e-Sr3rW9EZZ0',
        cancel_subscription:
          'https://lh6.googleusercontent.com/RTiRf7skZrDG7FDaXUhpAKIKHPRQxqzqo2WbTcSpRjHy77NNmKId9KGuSX25g5zEJR8E2YqRCMAey1zN8uQ4QPH-dijzCkd3T4_r2EfBRS1I8ntC67qGb3QXiBNzNvdHj2Ivkxc',
        change_resume_template_1:
          'https://lh4.googleusercontent.com/XIjZCsXkRCtn0iS83lqP71vsZxurOKqRLdER8GNDGqzKqRDkKf3e7IZOWFXQFhAqeFeLMfWKxl6KUrFn4hi79DM0kfje6qViXfW8H4ZJ3Yya0og8jd0-ttV9FzZc9BusCRXli6c',
        change_resume_template_2:
          'https://lh5.googleusercontent.com/sfrW6PARikJmO6AEtCfZoFOSCHh5e_FoLRfwnRpwF7i6Cin8ZS30byFrfsejcGWaqNYJTL5WhS1kQ_r440U1DcsXN3l6Vo_ANR0E1RAAF-wfjadWTtOD0N5vdweW305jdHGlQcg',
        add_photo_1:
          'https://lh6.googleusercontent.com/woqUzVaBCiNhUEQjlOoT163mPuUwa3svndab5whudmQ0wQzfNZcgzQnxlQ59FahJKAiNGiDRUD78452wiZPz4OhyKjhBPxFUcVZ5uCciTF4xfkfJFlXCVA5XrseBEUOsxAK9FRA',
        add_photo_2: '',
        duplicate_resume_1:
          'https://lh5.googleusercontent.com/GM-LXTNutj5lpoj_uT_bBWIZJUYkSMIzz3XH0c8Wb0536K9tVJQx6MVmKlGT_jkS2uEzqtzNObFY7yxgmX89eTf4z2N7fVXoXxCgwZRmhA544eWNslybuz2RiPO3kbHD_CV5Xi8',
        duplicate_resume_2:
          'https://lh5.googleusercontent.com/_3_fdJTSKlcNQVmpYBTN-O_X7ozcLqn-swsy3HZtWgx6imctsVn6M7-27xNv8n1GAqpox2i2vAvJ1QtcbOV06BTbfdM2JyQNeKGmNfl3TDUskLHnlwL3tHlf86HK7fiy4vajNP0',
        duplicate_resume_3:
          'https://lh5.googleusercontent.com/qbuyKVZUArDKvwFWJuLIjjrbzdkzyc4gWjN2fLlE6XazWNCorpnofG4xrBsczxB-xD4Iw-HxtT2OWw9hQF8bqwV3ZV4dVKtwsTJGu1lmLVFIjaSkzQZOPja3DOpPJVWO3FU4neg',
        access_cover_letter_1:
          'https://lh3.googleusercontent.com/HNt-Qqm0kNzh4Zda8Dxrfg65JJ1oLNeMLDG0t9P5dJ2fIU51njDBcbX4UWWORCPy1APxz0WF9OfiXxk1q5Ft9_xm2s3RFSgC0qldTtwHYNgT5aCKpfn0-84mUe2ssGF6gt4wmYc',
        access_cover_letter_2:
          'https://lh5.googleusercontent.com/mnzZDyaexnv1G9qfGKpkuyhXsd2wpfgutoHR7fgXOWA-aB3irHAgV8cuiaGt-NfC8ftKdrAd2iqlHWQy67jzj4l4drbvniv0Jaf4bnHtR1HpviPb8RQXHwevsD6Or6UDlJETsVk',
        word_format_1:
          'https://lh5.googleusercontent.com/wTcl8COKf-idsBHyk1fscGbEuv8-9-WkLc2ijTRnthr10lpjm6Ih4dMwSREF7NoNY6fTj4rnm--G7QqZ1MBEeiimll5wJGcbZZSnatIsiYQuE5n44Bkm8WRwI1rZXyzQk05XbTI',
        word_format_2:
          'https://lh5.googleusercontent.com/vjupQLeWYCibsHzfJCPhKiNTaomd1vc-AtUdHX7n77fLsybCOBrGG_OAH0nm47X5TO0ID90_YLGf0foJRtPYWi1ALvktGwLMiPIoNWGxoFPvIlOccG-WSUt4DDXukrvVEZ167z0',
        customize_resume_1:
          'https://lh4.googleusercontent.com/XIjZCsXkRCtn0iS83lqP71vsZxurOKqRLdER8GNDGqzKqRDkKf3e7IZOWFXQFhAqeFeLMfWKxl6KUrFn4hi79DM0kfje6qViXfW8H4ZJ3Yya0og8jd0-ttV9FzZc9BusCRXli6c',
        customize_resume_2:
          'https://lh5.googleusercontent.com/sfrW6PARikJmO6AEtCfZoFOSCHh5e_FoLRfwnRpwF7i6Cin8ZS30byFrfsejcGWaqNYJTL5WhS1kQ_r440U1DcsXN3l6Vo_ANR0E1RAAF-wfjadWTtOD0N5vdweW305jdHGlQcg',
        customize_resume_3:
          'https://lh5.googleusercontent.com/yT3Uh5nPw-qtd8UJXCkh_g2kTLLCZc0Z88h5o4oW2tamaIEtnXmaDz9cbdJw2meCVm7Ur2s0D8puj2p47JkFVE8W54vSoFIa7TyMXWaZeiQ7xURK6gD5Jfknuft1rbEgVanjjK8',
        customize_resume_4:
          'https://lh6.googleusercontent.com/XQT43smGvRXmLHYF53-hUfGGBVdV7FxLXaZBLsYopcQABe6A5solKpBIIzDwjVxrYJBUK0uQ8cTvIbiw6Y_V90hr2OGGXtoJlTnejftHJzieSzrbxQWu0qU_pJYbEihLEwZ79P4',
        customize_resume_5:
          'https://lh5.googleusercontent.com/sfrW6PARikJmO6AEtCfZoFOSCHh5e_FoLRfwnRpwF7i6Cin8ZS30byFrfsejcGWaqNYJTL5WhS1kQ_r440U1DcsXN3l6Vo_ANR0E1RAAF-wfjadWTtOD0N5vdweW305jdHGlQcgx',
        change_language:
          'https://lh3.googleusercontent.com/0cJpg0BxRKYBRgUd302F8XKyQuDpw1mGh37WU0IIfUSQIq6lCd6JurhFsBHnVk5cqop1Vw6WmfyH6WKUIrPyOaL64ovdoPfETksvtu50HncQTMSES5hmJBQcaUgLgDJoibuFqzc',
        change_font:
          'https://lh5.googleusercontent.com/sfrW6PARikJmO6AEtCfZoFOSCHh5e_FoLRfwnRpwF7i6Cin8ZS30byFrfsejcGWaqNYJTL5WhS1kQ_r440U1DcsXN3l6Vo_ANR0E1RAAF-wfjadWTtOD0N5vdweW305jdHGlQcg',
        change_section_block_1: '',
        change_section_block_2:
          'https://lh3.googleusercontent.com/Z-u6AIcpAEnilmeKd9RDAMvGvDdlff0YZn6t9lWPXe-5H4ChK93a3cPZWvxZBp8EMqb6gN6_RkeJ5m8QGtZCPj9KQEmwq72InsITJWiEa7Cvo3kOXhwlmoUuHJpPZUEkMLmTScA',
        login_1:
          'https://lh4.googleusercontent.com/ggbmMoc-zGc8XSFjeJEbNMsrb1qH9rBs0F3XVTrKcuchc000GWD6urzZ-cUsKfcZqeeXqnr10h0nx4yd7vcs9PRsvok0gUku3btPGi2iQzIjgsRrCE1PsbBjqa6T41L9ko0_qzg',
        login_2:
          'https://lh3.googleusercontent.com/jDHKvsebXYzLrdwR884C7hcLP5TpY8mrVoYl_6CT13bdgqaNXumjyj_7n6315dtZySJz4jwf0S36_YJuewOjsQ80xYMu2_tly0ZOnvCe9zM28JDnVkOLJAbqAiCyMJ75h69wvww',
        login_3:
          'https://lh3.googleusercontent.com/WgC_s8iOy3p34hKBdSpNyN6ZZbZsF7noYmYLpSntScgth_YaifLOb972YNtyg2di8_PqUyRnr6_QTkGT2PJTEQ8sNDhvK3qw-11nP0aFzkHvB0DIm74OKBAEKFF9DEzt5EzDZl4',
      };
    case 'cvforma':
      return {
        delete_account_1:
          'https://lh5.googleusercontent.com/jGEV-3YWTOxCHBpP-pIq8HqoY1wVVgSr49VGtGCxlfUp4Tbbh4E1M66RtVanPmYImo2w9pn2AkES2VPfDeBsGANFyI-N5LUHUIjdZEVKchD6SalbgUAvGNcvsl-YeB32m6U2OfvE_sqYf3SeYg',
        delete_account_2:
          'https://lh5.googleusercontent.com/wSNCyFxDnJqT7R7n5STWjG-tUeg0hhg9m6O3v2bKzZ0lR7dbE8K3xaIktW7uG3U8C2AghHT_xlrA-BT7bCd2VTwWP0CvLytFSLm4W7b5ceqk8zQr6pcRjf2w2dr0ez8yjB1vDSmsots_51cOjQ',
        cancel_subscription:
          'https://lh3.googleusercontent.com/-FkInk_1KviBzcH11CP1Jm5omkskdc1ywjFFbAIxYauBgUDUvbS5LchSclF1VotI5_28ZUbyis_9KKd49mMhnAhYoTocEcOAD0jycmbcVCy0__Jr5LjF3GWa0TmieU59GmTYfamgZwe5o8vu_w',
        change_resume_template_1:
          'https://lh3.googleusercontent.com/toTCbJe3EPQ5wd4MZOPgu9LPA5qKqW7v9KOO6BpxgElfmv0ftrDQsW2Olx-82HTfA9RtbMXeU9uSTgYsee01CXTAFWj6J9YIt9A-V3bz4a9n7zKMld8nCZr0OTKVKAtFJlHKDXdWU4l_eLz-Cg',
        change_resume_template_2:
          'https://lh5.googleusercontent.com/H8w852q57TWGlLD-m7acra6NTlF8iewe3nIBurmYglVGg1wNjLHDTua4tDLlFliJU-T3rdwyP3cLPKkPffRIuvWd98twNbUwn8yM_kNkjNy5phj30CNTAXEo0M6PnaasDHbno4ksnpvZmXNyAA',
        add_photo_1:
          'https://lh4.googleusercontent.com/rjKPS_1as4LUbJiwM9vqPKO-SmszkHS1lDSFSwO4FxfjWcYe9bUQvdZBrtxPK_vOsJU1zJyn9NqNo0Dbu6T23_1n31FmFiFG7KhMbyRKpFV27TdWFxkn-9UHbhKFJxP6iBaK6GX0WUW7s9lzbg',
        add_photo_2: '',
        duplicate_resume_1:
          'https://lh3.googleusercontent.com/YyFo_lzaE0MVm0eg4gfdR3Ng7-goo0R7z6GbvtTAEpZBmOiaTnPp68e5j3n6g_sdXJPVe_NVAwr69y3q9DfQzQk3Y1bXvdgCROEnq0Ehh_eL8KfumiPlIwUDn55A0jNynGLOKIF8Rw6Ln7pGuA',
        duplicate_resume_2:
          'https://lh6.googleusercontent.com/GSwfOVClJZPCcdWAif0p4_5rLbGABJ3Z_LDqCRfyzmWkG_5a6keoNe2muPiIDDMl4y7hDmFzFgzFcyPLEkNqEWz32h5qTgEwRVckT8TX8xMTOmQKg8nUf9wGgtteubYzLXAo2kjNxwFAS2uCLg',
        duplicate_resume_3:
          'https://lh4.googleusercontent.com/8Z44r7Q4A74scqL8dKrB-Nlf218BFX1xjGVgtgs4J-e5ZyeW1sl2yOb_KzJv3ehvQWm1dqdrd-gjh7PH6YzkeaewBhAWgbmQyO_JUG7_6I3z19vIhb44vfVnvTQ_lJ1w68lU29TVLwSBE2mK6Q',
        access_cover_letter_1:
          'https://lh4.googleusercontent.com/f391AJpKEf0zsKs6zaGHkOGwNFRyZ62-BDuadTlpYZz6YXv0r3ikMv4XI_8WJReUxkZoUGmRd4Ep1RGWM2K9sCPBZ9VaVvAxzZ75FUnFLllEZfM55ppXHGBTxDA9SDue8tuasNwBLhOFfUsLNA',
        access_cover_letter_2:
          'https://lh6.googleusercontent.com/qWu1ucHncjDveWc8UzLvj6QRAZSYIqywga2KOro1Aicw1S9N2NzGbdY2vl0tq2-uKf0-O2Q1Je5_EBPAMuDD5IABLSSR26tq9wKNg1-h3DZbfNmbfw-zgVu_UbAedIKkK2-_abqcnA0rYcMV6w',
        word_format_1:
          'https://lh5.googleusercontent.com/23nEZjeZfCHFkr1m3ZVq_XAYXsC91OXNaCwD7AcwRMfScvUmDDAQ4kufHZK6Y56X_BdrMHI3rvjg0JHbCZeNB0AP-Xof4vA3SvJd3U3OtJ0JP36-GXLQinqSLyOCRYKGms-kCPZ3DAFwnHnLbQ',
        word_format_2:
          'https://lh5.googleusercontent.com/lOZkdzkYKJXLuxISLPNflOfyk7Vv6T48UFoDyaT_kmHZAlZCxB4NFSogYJ9oiupTE5VkVPSipWhCGeAQZL0Q4mFl1N6h6hlrlp6LaZFzN_BvwTxrCSbCoa2VPE_8F_6qepMMrkfzH1QbJkpjpg',
        customize_resume_1:
          'https://lh5.googleusercontent.com/MCW0SMegGVqyCYgkg98ihX2Bw60zBqM8ODZlhiVv4ZcaQoDJZTAGqTtP1cqKuCSvpig5-61Hyax0kgG3JKkk_vXeJrfHc-Zy7ciSXgatn0Tuvi8-UFtbA5QBLLCSFm7ErzSw3xVLKtrtIP5gng',
        customize_resume_2:
          'https://lh5.googleusercontent.com/H8w852q57TWGlLD-m7acra6NTlF8iewe3nIBurmYglVGg1wNjLHDTua4tDLlFliJU-T3rdwyP3cLPKkPffRIuvWd98twNbUwn8yM_kNkjNy5phj30CNTAXEo0M6PnaasDHbno4ksnpvZmXNyAA',
        customize_resume_3:
          'https://lh3.googleusercontent.com/lNuiJ6qXk0UqDsQudEbwZ_KQ-XNq5UdfMl2RVv1ugpkXZaOndBLOA2Uh4mJ58SbN4zVANJJaLjP_ZYEFTE4YcDzyea6zIWE-BVXTCUxrwAK8dBZAYjs1ci7397hIp_h59SRL5EgZiVpKWuk00Q',
        customize_resume_4:
          'https://lh6.googleusercontent.com/q-YtQzm4qbjYH5QOMxQYHPh7BVfP20r8OUBIM4E6gywWvmMRYTIZiZUPPJS08YZDph2CVFhSGcg481W_yarbgTPBZ3tJVg8_ck_fHV--QTbhI0u7Qukl-6hXcw-1zugby0wG3bKwRSSsE8KtCw',
        customize_resume_5:
          'https://lh5.googleusercontent.com/H8w852q57TWGlLD-m7acra6NTlF8iewe3nIBurmYglVGg1wNjLHDTua4tDLlFliJU-T3rdwyP3cLPKkPffRIuvWd98twNbUwn8yM_kNkjNy5phj30CNTAXEo0M6PnaasDHbno4ksnpvZmXNyAA',
        change_language:
          'https://lh5.googleusercontent.com/nrde5Hcp10EnyJTh31Vc0lmpsdf19QmFwOsVGTVFPwOy7Mznzr7Si1e85ZECVN0iKz89wxvG1rKEQKgD4A_TL1GJCDL62p6hxFBCYvfUBc5dOdEZ82k40jZ3HN4Q6-lkiTr94hdEZGumWWccLw',
        change_font:
          'https://lh5.googleusercontent.com/H8w852q57TWGlLD-m7acra6NTlF8iewe3nIBurmYglVGg1wNjLHDTua4tDLlFliJU-T3rdwyP3cLPKkPffRIuvWd98twNbUwn8yM_kNkjNy5phj30CNTAXEo0M6PnaasDHbno4ksnpvZmXNyAA',
        change_section_block_1: '',
        change_section_block_2:
          'https://lh3.googleusercontent.com/eO7dFF4UsxYzdYY90Js4e_0G2BvTQArz-EPEHpVpuFifn6ssRA4vOOQHGWEXLACjl9Y5FA2wcgquoFVyjyCH-7Hop1HRhGBpUYngea90u_fA4_hL6nNhmvR1jaGdvi6MahPZZZFVFOv7SopkHw',
        login_1:
          'https://lh6.googleusercontent.com/JQD9Cs6ywJZCI0a9ut3R5ll9oETXmxdV6IWaT4_ZjyieN54uGsajgqBDJxsz7TnRwm9TJw_LAoDzE50V5kVvOSudS29JGOnqh3g3b8btLB3gwByyAx0lOCeyxGcBOGYFdP9isQkHmLQtpBBXKg',
        login_2:
          'https://lh3.googleusercontent.com/lU9LPxg-Es3pIIEZ4iTlqXVmFDzksUhptkgKiOCRkT7w8ApXpkmJay2k74KPdTpqK5_D10Ko3R3n9CENCbRewVDg5Gw3KS1bKU_LUqjTQ1CGhcXpjt9Grlm8fitC3Zb6xSB1TRwXkB95OX1MCQ',
        login_3:
          'https://lh3.googleusercontent.com/-Cabmq7Cosvbn79uEhckYUp-4G0m0V9qxc53PTynwW0QdjHUbDzS-gDxfTRo7-S-lTfwXR-w8ESSONceqDOrmiqY117bg9z-iThObLUl9ROxqQSY-Ygs5H8kKZMpmBeibpQhKckfqbKsZnhkWg',
      };
    case 'cvmenarik':
      return {
        delete_account_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/618116ca12c07c18afde3756/file-GMUweF8HLQ.png',
        delete_account_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/618116dc0332cb5b9e9b9f01/file-gxs26BJdcV.png',
        cancel_subscription:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6181165e9ccf62287e5f2033/file-azqTp5pIya.png',
        change_resume_template_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6181134012c07c18afde3732/file-xlaXirCs0a.png',
        change_resume_template_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/618112989ccf62287e5f201d/file-73xgXe8tv4.png',
        add_photo_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/61811de6efc78d0553e55ccc/file-WxgA4ECheP.png',
        add_photo_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/61811d9befc78d0553e55cca/file-4mOf1bZryU.png',
        duplicate_resume_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6181194e12c07c18afde376c/file-7aKmimyruY.png',
        duplicate_resume_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/618119879ccf62287e5f204a/file-iDwJL4RDB3.png',
        duplicate_resume_3:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/618119990332cb5b9e9b9f12/file-PfUVs6Ypq0.png',
        access_cover_letter_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/618117e99ccf62287e5f203b/file-902MURYbYv.png',
        access_cover_letter_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/618117f512c07c18afde3764/file-2K6KApkDsE.png',
        word_format_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/618115a8efc78d0553e55c92/file-fiTfIWxGJ4.png',
        word_format_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/618115afefc78d0553e55c93/file-kDsuuILmTd.png',
        customize_resume_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6181134012c07c18afde3732/file-xlaXirCs0a.png',
        customize_resume_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/618112989ccf62287e5f201d/file-73xgXe8tv4.png',
        customize_resume_3:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/61811448efc78d0553e55c89/file-QyBVlNDIfx.png',
        customize_resume_4:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6181146512c07c18afde373c/file-WVcbucJUWv.png',
        customize_resume_5:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/618112989ccf62287e5f201d/file-73xgXe8tv4.png',
        change_language:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/618112989ccf62287e5f201d/file-73xgXe8tv4.png',
        change_font:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/618112989ccf62287e5f201d/file-73xgXe8tv4.png',
        change_section_block_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6181146512c07c18afde373c/file-WVcbucJUWv.png',
        change_section_block_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5fe97010f24ccf588e3feb2b/file-dNsJ27fhaN.gif',
        login_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/618122c112c07c18afde37a6/file-d8DmjCJGYQ.png',
        login_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/618122d60332cb5b9e9b9f54/file-yccb0KM0CT.png',
        login_3:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/61812335efc78d0553e55ced/file-U1Z8XqXOlX.png',
      };
    case 'cvvzor':
      return {
        delete_account_1:
          'https://lh6.googleusercontent.com/TN9omlDImgUosJuSrfJ9DXxTRko2RyLSAqCaKyrupPGgdQRVjYc6a2LuzjlAD47KYYOxMFmD7TdAGcT4pgzPeUzwovaFxNmed3f71Ujc_u1EmfhynpsIZL9LnpPQQxdI9GXSPi0',
        delete_account_2:
          'https://lh4.googleusercontent.com/kpbJoZqB0WyX89IuqsTB-HD7-e8rMQkWOkqKBVpc7wjjUbkAIoxhA9fjkJ6JINiaHHUOgrE-gi9IA8c954iwYdSRY5M7dsWUUhSjltMAFURfJmcA6N9W5zipGv4vKA1L6tIEY10',
        cancel_subscription:
          'https://lh4.googleusercontent.com/Ep0EIOSSr7_IKCxuq9wBGdFMFXZTLHzgR6CCgjzf6_Pf-FC3NH8QSUywdrb-6EYO6T1nl9MIzNIFx2Lg5yinUZKh5z8mTexWAKzlTbAFDEEPJJdeHVor3fetiyCmAjl5zdWQ3wg',
        change_resume_template_1:
          'https://lh4.googleusercontent.com/QGGQfYOkRi2S09bmSDhg2P5ucTmOk1ym5B1yUc2EKXh3B8IQw4A2QNOhIe-MOyN2k7vdVcDTdqyWN5pt4Kp2xN-IcSpoaXmyfiIDViUx1U31RDk7shvdM1oGwmGRTp77kJfX3EE',
        change_resume_template_2:
          'https://lh5.googleusercontent.com/kluyslMTcex5KhjQcsuf70pA1RT8EZcU26mYInSl1PXWvV9Kf5tp63sNK_epC5IitGnAlCzTUOsjmRjinPtA28reng4iF_jRwSaFW3n5Na4SjEvs6Fg4b3vn90yplmaqMH9ilOM',
        add_photo_1:
          'https://lh6.googleusercontent.com/k9Y-zzZZLbp2hEnpPLirxj-e9wFgxelMqAdNswsEkGO43VUPTim5kYcSlhZy8iN4c85QR9T7g3pTkzuTzo0h0KmCiG08AewaRQi5yxiTRO78HXGRxREAO7amDBCX3TryPkp2-lI',
        add_photo_2: '',
        duplicate_resume_1:
          'https://lh6.googleusercontent.com/HoxsaXbKTfATYeUZEIh9xN907W0bNz8nQvrDW9_VhN98G5nv05SHAFFyIpmKC6_XcjGcCCr58TJ2fadlu7ZheW4Qg-S8hNi--yCQnvKT89jWIlvPI8CTsnoeoAB2Wb88IO9rMNw',
        duplicate_resume_2:
          'https://lh6.googleusercontent.com/86iKfkWkNt5DhCo4_S4hiXNSZQbs7XvOWKWSVjfLn4iFKab885GdmZ_zBwZtJLkLiQRYYWkI4gOYGOPgBsC7mO5F1hkd-574UAvcV1WD1i0Zmr6UBBETjxHhN6noYJN7x37k7yg',
        duplicate_resume_3:
          'https://lh6.googleusercontent.com/VFxol_w-t9ywW0WjHONRUs3iH6ls4Tc4UvYB0e-YwBVKA7JyoXYq0Y85tZyZ0lTeZJXAjb0vCr2LJBHINdYWbCBI4YVLmrFFCCF06tAn5jkBI_gQ65Nu1KIy6dIBiToPJ8ofITE',
        access_cover_letter_1:
          'https://lh4.googleusercontent.com/SlnHu4m_cut1g_oBQC5gEgftRPMWpa39cIKOzSZgRtPUSM2sEPYBdMrXsDqjvRZ-nGcmXtzSZH3m0h78Xy33Ks-5I824Z2cBnuPxRz-aS_hHe9iOGSFEnRgmRc6ZFM44NK6pgZE',
        access_cover_letter_2:
          'https://lh3.googleusercontent.com/-XrU7ruPukwEn7x81c6AUnq8uZp79ie6CRAzIOdVarjIIWIXpo0M1BocT_6Hwh9Yzwz5MzP2NQQTwgIz4XQrtEaQW3itLeomBkJx8mes84-lq2e2hES_ondq7Xgjjn56LX8RvPU',
        word_format_1:
          'https://lh6.googleusercontent.com/0_t5rYCqa4hb1Hy2z9ylsEcT0SW0uzvCeCwKUKrBRU_X_Hve_Cm5c-BjWmxH-jymSMLuGAfU7uzOVWQK__GT6AomQKw1D06bis-d8u_Eox5hSwuRGMWleTD05-eELuwUuEt0nFI',
        word_format_2:
          'https://lh5.googleusercontent.com/fwWa8GTFbL-mFtu_Re-35KbDWJdFVLnV88VLiSOmX4cVuZoDQ-b8OMG0Tx3MAozYLwOnGUsLmYLEgtES6GIBrkjc5n8O659QEMizXnvnc6fJ86nrZwhKcvZUMXNMVwiWditPIqAx',
        customize_resume_1:
          'https://lh4.googleusercontent.com/QGGQfYOkRi2S09bmSDhg2P5ucTmOk1ym5B1yUc2EKXh3B8IQw4A2QNOhIe-MOyN2k7vdVcDTdqyWN5pt4Kp2xN-IcSpoaXmyfiIDViUx1U31RDk7shvdM1oGwmGRTp77kJfX3EE',
        customize_resume_2:
          'https://lh5.googleusercontent.com/kluyslMTcex5KhjQcsuf70pA1RT8EZcU26mYInSl1PXWvV9Kf5tp63sNK_epC5IitGnAlCzTUOsjmRjinPtA28reng4iF_jRwSaFW3n5Na4SjEvs6Fg4b3vn90yplmaqMH9ilOM',
        customize_resume_3:
          'https://lh4.googleusercontent.com/R9rRTBVO2p3iTxqUPgZQK1rNI3GPX2_qXdbvmg1Z5ry3lwD7rKWZM7IJ3NkjaNeMHVzOjelsQNIo8xm8XQ-BCL3eGD6K8-0tHtFbG4-OskKFOMOPLEgzLsQvvid3HmciDBpju4M',
        customize_resume_4:
          'https://lh5.googleusercontent.com/MTw1wPLcMdR31ksQjp71b8PUZzmTE8_IayCzNUlVubqw70xiTWvX0KxBymNGAqCvy19bztMFXoXT50DpGPMhnqt63Q2NgqyDlBVC6hP4nDFTOlflXtUSrGnIATGzydiBE1hUnAs',
        customize_resume_5:
          'https://lh5.googleusercontent.com/kluyslMTcex5KhjQcsuf70pA1RT8EZcU26mYInSl1PXWvV9Kf5tp63sNK_epC5IitGnAlCzTUOsjmRjinPtA28reng4iF_jRwSaFW3n5Na4SjEvs6Fg4b3vn90yplmaqMH9ilOM',
        change_language:
          'https://lh6.googleusercontent.com/xD2Ld1Z_Tpu6R_gU6KWOcmDp6sZm4Nk1xzbBt-jUypoSFGuCO9wX3wnJK-jLEwcEqHq30eVa4NV85NT0HTVBJ0gaSBkkusHfIiVwRwX9fBPP2JdZjsMhGadkGTgIzOjAdm53Yeg',
        change_font:
          'https://lh5.googleusercontent.com/kluyslMTcex5KhjQcsuf70pA1RT8EZcU26mYInSl1PXWvV9Kf5tp63sNK_epC5IitGnAlCzTUOsjmRjinPtA28reng4iF_jRwSaFW3n5Na4SjEvs6Fg4b3vn90yplmaqMH9ilOM',
        change_section_block_1: '',
        change_section_block_2:
          'https://lh3.googleusercontent.com/ZcXBMmDVvMON3UCdSGahku9d9VvV8178z3iLoBMebKbJxz7vzHNctsBOAXLhrwdgIbhc5oOL5IPtAFtJr9GxThhsWDqxtaCReloCkXcrPlxyQpS5yBSH9gpj75ta9fRSDVd0q3Q',
        login_1:
          'https://lh4.googleusercontent.com/1651xDhy6f0JxhaElE0_c43C56eWm8HBpVHP1nnNl4LLfsSEyhHETErpzDfaJjDgDjGB7rBpDsALctoGElUhPd5V-K68Vz2MlgDbduGWyqzIPi6W0HPmRxBA1GoPZk5qQxirx2w',
        login_2:
          'https://lh6.googleusercontent.com/CkWa7Mncl1jJs1cn1FFYu6Cpi2N_7r_QdLSom2q988KcjDhXs7GFmVEUFUrD75D_LEUq2ZyhXtmXOqmALktt_2slmQAgxW3YWsjSjgydsipqnDeZP76rd6twvWl8f5hn98daMEA',
        login_3:
          'https://lh5.googleusercontent.com/nEoYZA2iVH6AL5YoYW3i44acCEor5gPXy3VnSTPG1NgRqRwKK5DPXwLle3zp8iB2NGeNz537VKYXUZMmGLBHLQnRd93ncBQfwCy4kqHuyLMaGSIXwgojdoh5mUZkLI8qeU5iR9o',
      };
    case 'lebenslaufschreiben':
      return {
        delete_account_1:
          'https://lh4.googleusercontent.com/mOGVMEUDDH5oiJMnYmKjZtVPF3ZoZwO6rZoLu2R-OveDns2-nEnm7Q-4d9ycLgSqECerk3BhBKwb2GQx3D9Vpt8_ETdveeUIvG0r0ght7nh2h440pkBh4ncDW_3d',
        delete_account_2:
          'https://lh4.googleusercontent.com/IQAnbZCUz0lJQLgPUl-Hotx6YXKAjCsUV5vgr6SIJ23llH9nCwIfiFhQr2e1FoeF4zktrrz-ocf6n_lkkyNYKZ62sKk7VftXJjG8Fc6lal9RUIjYGgjSdwVDS69s',
        cancel_subscription:
          'https://lh6.googleusercontent.com/Yxh202eTwLw5M-7CfxtB0yBRW3zxWMjWnsyG86vCjVRMNmWPHpCY3CUellyvRipx0tQWY0nL4kNF9NsCOybrxFLyfcWl6Fy_3wb6oDT6saAnY8yoqPaa-xEmoNRD',
        change_resume_template_1:
          'https://lh6.googleusercontent.com/xaRkR3Use4Az3lbU_TtYRkGJK67b48V6xs2ufnXAGq4m4iZcHoo9AAJR6I8XpLWHXgs48cuslIYH4BmNbU4_T-_njBGrd5jsyjDwseZsMmWwZospEOGn0P_XP58i',
        change_resume_template_2:
          'https://lh4.googleusercontent.com/Gi-cUP3rqrr5_m_ejnnSQQKXcz_Th-rNXSOZXH0V2bc7uuPjciAKqao7WhyW4WVtY3r6VIkCr2RLDW4MHIur8usEh41dg4nJTjlaW1L8D3N-XKBahDjUmQcR4Ei3',
        add_photo_1:
          'https://lh4.googleusercontent.com/g6yWhkL3eTIybGvg7hl-zgZboTi_-R4e5gfEkNpWU_QjWwF3QFZ8MmPnYbzEQW_ETQBbMr8cP_aoA4smQb7tSN2fm98Z4d0kvBcALLVtBTtVqmwrmH8Mpp3eEiMd',
        add_photo_2: '',
        duplicate_resume_1:
          'https://lh3.googleusercontent.com/4zlV_MONPhSS4Ea1ehvSir2jCF3Y8a8mQ65YKVwMR6bHmXUf6vpOQym5Y9ug2uS6VFRc0lq5ClpBNZsvmqSDH2ZAC4RqEqackkalNqexBPbwQHMNEwKsEeI54lcJ',
        duplicate_resume_2:
          'https://lh3.googleusercontent.com/ZfNDeosmrlBOXUDQB7vuazBg9Smiy93PU-cw4I9cy4lcuoBYRtHc8W9IVeBOOk2ydlQmtJvwWPG1mL1w9HZw20bcIHN7-xCyjaC-g-dcaQT31f-TZq0TKiAEBjfA',
        duplicate_resume_3:
          'https://lh3.googleusercontent.com/_Bhy3pcgQCdaaDQ3nyy2R1zIQoUTDMcLVK8kvFwljMCYt-dJf01Qd-ujRxeIQaXosWhDGiKKuWghlQgeBDoQt67cxfaOr0I1l86VGbeXAERIYVYgG2_OUE8kgBMA',
        access_cover_letter_1:
          'https://lh3.googleusercontent.com/7WWzfG2YQ7V5orYlmoNKGISFZcY1Y9mq-CUim1Rf1RweJmJ249PQ4FxOS3a9tRk-AARF1yQtgM4ZgKnh99MIyxrQC6F57C7ABkA5z4bfrDSDlBzPvOPvu4pj-E_U',
        access_cover_letter_2:
          'https://lh6.googleusercontent.com/KKDSLhXWLPyQn5RSrHNEWOfq2TayryG-at-OK6w7WoOzirvU0pUUCkajL34Eov3XNyVx09WMsNodvrIIZiRbFST_2ZjlZDJVycJNkurwtFuqVwd_-Xk4hg1w4e1k',
        word_format_1:
          'https://lh4.googleusercontent.com/7dhsEGrrqGFwBDun4ukS-RVrTEThboeJKBvOog4uR3J93MPcvdFT1wPDsfDC5glHbuwO4pLrbIYGr3SqFHAk018JQRhwj957K9vuOoDWw1T4f5iBiVddRQdvDN3t',
        word_format_2:
          'https://lh6.googleusercontent.com/Qnt6xdLr2iSJALnlSvQq6kF-dWbxDEkSWQdxNgOIsKlzqgNn5NXXDVBjhRe2M22OhjfaBTCiN8aFueAVZswuSvf2FfAXCW6IK3OpGQj--TQUQjgdB-bR41YOJOVk',
        customize_resume_1:
          'https://lh4.googleusercontent.com/WFPKXgMuqRrAqJ8z_mNBY_d__vguybaIX5bcuF7kamncwDvqGN2GaNB9PgMTiQDKhbYPfFO614Mmx6dVKoA4jjped8UHd6uTVpY_bVRqIj5POx0Iw6uBe31ysNDs',
        customize_resume_2:
          'https://lh6.googleusercontent.com/wxyCvODV4zQBCOc0szayp0jwvctlgR_swdLoVsuScIMZjlt3hmd6BKUWjZwo7x_x-FV41V4cz_UIwzPsXKtiHNGRthH8GL_0vHT0vqVEHZQz9CG8nYjDpKu5XzH-',
        customize_resume_3:
          'https://lh5.googleusercontent.com/QO6JagzKoxqAHNbC_4lW1_Ig5m2AubDL5HdthHnzgihS3qf8vkIgfpVeWMlBFV1DOru3DLOfOA8TcFnGIhFq-bZtHNHmj5G8L_h7LERD38B8sKZfqh10sr5BhC6-',
        customize_resume_4:
          'https://lh6.googleusercontent.com/LMZK0bKo5La6iVNx0gJwLSkO550qh187OBkYlt0tprd1E_9_biUbuV0PLwKm5rfx_Yk2v1PVBu1mPt1pHYvtDleyW06-QgIdxlwwkA-On2OvmVox57LhGsmewf85',
        customize_resume_5:
          'https://lh6.googleusercontent.com/FzOw987jVfQQt09X7BzZ4rpXi2R8rLveBBCLo2TIvi2cNASqIAsh6nN6a_tjB2U_W072lQow9KpNCJ7U8ITS5D8bim_mXAfhs3AVLgryq0dZ9Pt-dj4f_aPArXJx',
        change_language:
          'https://lh6.googleusercontent.com/03v7wYMvwVNqIgLs3WOPcCVXmk1X0wNdXTmrOcpMpj5r3gS0eJuo_lByZmhkjfzu4B1Pz55Pqh7Z5VAhTo-J-uTm8Rse5bzq4GIUi5B929bVLT7OvvEl8gVKPlhg',
        change_font:
          'https://lh4.googleusercontent.com/G4D5Ncx3NpcYnGAr_w7tGY7g-g4kihn-Q1TGrTka6NFb3_8NM2mKXs3YrlRpkDg1fD13tWCBNkZpUG6smEIOeQUwGtJPoT-pq6rbK63WbjX8kL_CI-0uLDUAq_7X',
        change_section_block_1: '',
        change_section_block_2:
          'https://lh5.googleusercontent.com/Z26jazLNU1b-fpF7WgIGlvI27YziDpuj_vJ8LGkCZ1wRd9gNOIhZ2rPHLJS4ubc7OLU08HTBo9J3rPDe3mm1XC3Lh0Eq86Q1ssgL3hj9fiSuk_fi1yWSPDNd-Kab',
        login_1:
          'https://lh4.googleusercontent.com/_vKKuzGAf6ywuUPhvVb5mCldCsPO9U8hZNABdc8mkWRQqyobHt5vBLMMGAcK2eK-z6LqJf6NAd4K2AT5BasJ6khtsLt9BYMpVK476oa9GnlQj2Ed4KIGyY8Ns3la',
        login_2:
          'https://lh5.googleusercontent.com/8JTF_5uLW9yk4ArEw7fyTaQvJI_gMDqd7iYb-U1NOttBOvyT2r5xGnW4KAqU570JgMmfN2fDi4zPkHHF8zckuskzVFn0IicV0l_XF8oV09kUwZhEdNeqW2IL_U4D',
        login_3:
          'https://lh6.googleusercontent.com/Ul91PfyoK0CgSNZke6KIG1E72fmuoztj6wiRVunncbl9HNmy3ZQ1MDO-Ez5a8-BWk45M8_CKIcljJmQT5gO6ChLjWf-uG677x2oMAr2uKItKDR3GErZJXOkZ9mz6',
      };
    case 'modellocv':
      return {
        delete_account_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6209f6b739e5d05141b69507/file-iocj9tL2P0.png',
        delete_account_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6209f70fd86136157d9a3d48/file-g6ptsTn8tI.png',
        cancel_subscription:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6209f63fd86136157d9a3d41/file-3EDPcBTX8M.png',
        change_resume_template_1:
          'https://lh5.googleusercontent.com/Li7FrcBh4xSJ4LuoR7hMyfeAucdx1my9DykAiuFS9E48Xo_oyi3gXkiVXm4nq8Gg2bU1gPZiExx2lNwu9v5Uq0zOXhkk5VGBANRRXbQjHG2OpJdP6s1vu7vUkteYUPbWTc6xWkBi',
        change_resume_template_2:
          'https://lh5.googleusercontent.com/TZcX0utD8kBfi6tEA8zUmSCqZZDTg1VNk1nSLhseXCpX3J4CEqz6OmQ75TNjZFmYBRLmMYPSABHibVs0Ei81aM5RyAYYe18XVlpYh8FqYsjADN6JCdqzQpmGkAjxe7fBXZaQtonk',
        add_photo_1:
          'https://lh3.googleusercontent.com/F70uaqZ4JpH22u-8eV0vAT8w7k2i8ECMmr3vkHtxVUaJtm9E2a3bSQkmvQGIyk5PSpzcTxKXO72Pghi2aKYCyPayaq5iYdTB8iRgTDYzc1GvzJrhxxiMuF-E-cvBUbYIupf2NBlU',
        add_photo_2: '',
        duplicate_resume_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6209fa00025ca67522c7ad20/file-hGOlrHSPlz.png',
        duplicate_resume_2:
          'https://lh6.googleusercontent.com/eoQ9Lu8oa0telUhqVymvkWs-FYFyN34gdkkJmUeithRHhlh8lS1Nuoje4neYGUbatYFnPhfo-wiCdazxPeqSmg1AcL7amF19KlU3z7ZCLbACk9avdkWJ3P437Z0fHap_j6ZMD_w2',
        duplicate_resume_3:
          'https://lh5.googleusercontent.com/u63YhddNP3VWueR6YrJ-iy7Hp_0gu4V-TWuIOvvfC0DCpHn2DfMJaNuUKzjJG9MvyrpO_r9WDy_AmJ-vITWEOgZ75Yb-2aKtG8G5zvmNdHQlesIQMmuN0BjlrdZ9wXqZGv9-0Uj4',
        access_cover_letter_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6209f97939e5d05141b69511/file-JO7l5c903u.png',
        access_cover_letter_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6209f9a8025ca67522c7ad1f/file-OzC0NvLrHm.png',
        word_format_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6209f543d86136157d9a3d3f/file-JZ9xlXqJcS.png',
        word_format_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6209f587d86136157d9a3d40/file-VvhRd7gbU3.png',
        customize_resume_1:
          'https://lh4.googleusercontent.com/pGoqPnegw1ktXBDFPw5k9635oihiQ8t99hBXSFN3jieR9TykBgNGJP6WGX2hzvDIqSC9KAsFkwXIrlEdmG_NoR2n5ujDpdt0KgsZHSzP0DqtkLYthGPxMBQAacKRS1gYOnck0AGa',
        customize_resume_2:
          'https://lh5.googleusercontent.com/hg6WF-7QyUmJjAsWC0Qa-wZD8oGnQYYyiD4IEFWL1sqbfLWJFSMjYxu4Tic4urJ1TdMmWkfa9KhwoC5v5DWsgsNny6wf7WFDwmaIJuSeS298PEf9bPSwpaOAR5wMdov5ky2KiBcI',
        customize_resume_3:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6209f3f6025ca67522c7ad0c/file-iK5J10LEpm.png',
        customize_resume_4:
          'https://lh4.googleusercontent.com/d2osgBGYvzCENGNAfhtof0pAOhKISdz5wlEQQuOwVPiZ_FoVqV-XIFvXDIAzeuFgAXX1sJ7b6UU3TRYGRrtkRn4Kud3g1A0Rav_aQbyIY_B69Npxjs3zw_m05FLWryI5CNe92pXO',
        customize_resume_5:
          'https://lh5.googleusercontent.com/wDLz8yaj_JFwpGf6zeXLmLztsht-aZW1ZX0Bcf4GtNsjr-kzSckN-LNsV6u6fdMsYpI0FzjAelvcHBlprQ8DsaM06ihg-6d-RJF3ktOwrBf_nmi9ty6nHxoTzOZ5nGH6_0fiNrly',
        change_language:
          'https://lh4.googleusercontent.com/pYuY15vcCHa0uSU_ntXJaeDyTjYbQrIYzuK1VWWdeukuY_kNCR9VBYlmYRumR1BNLkQqqGLNx-LO5geCd55FlSzGt3_5nry-WwoxYpdMyJ7funWxr4j29gjZuq5h2mCBb_yFHrfL',
        change_font:
          'https://lh4.googleusercontent.com/km8J3k57WG2h6iBha81OqPJk9hTs4EI9lWl85vCYKehjABlJI5LQpziKKcdsO_Q-dZLJa1CE-hpY7G5X7W0JxcJVyfdcEUfAToMcbihgpEz822vxUd2v1RikfuZBewQ0b6i8GAKP',
        change_section_block_1: '',
        change_section_block_2:
          'https://lh6.googleusercontent.com/TsYjUgHHrWAr7VKry_5STR3oi8trtU2rfbY8S2Ym7RPSXYANXKuv1uyiAtVYyj_5d9i0jKKAwReHhtp7wmbzRr7r0dUhA9WZKgMWUxy6xPMcM3MxZCnPteHbe8aJPIs8zNM0gVH2',
        login_1:
          'https://lh4.googleusercontent.com/CMK90Gm08gyKp9U5FzSYX0KaXsZ6vcg3qrADNw7typc16BzFO7yTlFtd020sVIFEBZDVffQLzHGsEirwaBomxuRfJd7St6k75G8-0RO_1YzCxOjrCsJ4ulJAUzeWk4W9Q3vozWyn',
        login_2:
          'https://lh4.googleusercontent.com/MOS8Dzs-2uSxlG_N69Btrq-RivTJhBgvjH0hBYFsYZyYK1QBc_cH0agoZmsm_qj0qViZ36FRhdSTjO5HIV0ffnN5KHM06ozqbi_9LCkKEylLMdforHHTSDkWIcvhGfUJz2rHioWW',
        login_3:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6209fb8c025ca67522c7ad29/file-t4F009852Z.png',
      };
    case 'skriva-cv':
      return {
        delete_account_1:
          'https://lh3.googleusercontent.com/0VdNg0zcKbi4imEPP31L7t5Vlhi521jd6qVEOxNLeypgGVKUnmXK0wvNxVpm-naxOHTtrXoUckntEF5z72sec5uszEvwFJ1oqBoEF2qFbNkj5D1_9o8Ailif-fqaplqWkGhsp_c∏',
        delete_account_2:
          'https://lh3.googleusercontent.com/cqC68OK_UIiTs_aEf3aT6uS2D-Wlni9hnIkJJeUTnQCDT4azEIhfVsVb7NVlvvbwa11tLdb2jjirZWF_Kt4cjOmmCvy15k7WxPZeuYUjNQX2xQheMhlQDS9Ue_5iVzqLAUSIETw',
        cancel_subscription:
          'https://lh3.googleusercontent.com/nNNoj2NPGn9J7mcuRK-m0ZSWT82_nxKxYtyfAwqa0rQT3GX6r6l5zwcgnI3c9sySXmpKQkqILo2I4ECx2GqI_klQ4wqjE8zhEtnUNAVAauYCYOQChag9V-KemA4L5pr98slVB_E',
        change_resume_template_1:
          'https://lh5.googleusercontent.com/18oUmMbPJG9YxTv2qGD1d7jq0kf0blp8Jy7PFvOG18L3U8uYMy7yZTQzDKCJdWNqN3aba5GOrhI5D03FYHaYzxf6REvv68ZUP7J9I5dZ8wMb17HyzGYFbTGCfkmJ_5xuxZ_kYu8',
        change_resume_template_2:
          'https://lh4.googleusercontent.com/eaQ-c4ztqjUM1V8iYo8M_MllfyM7XJrNxUVvXWcCq30oBney9x2PQw4R9_3CDWDVj9oTBGZAI5U-I6MuXdop_raMf-d9O6AkvssM2UWjoQ1RodK8sjd80zaAKcYe8XYdPiXtlTc',
        add_photo_1:
          'https://lh5.googleusercontent.com/UTDfgmhE2eWGhrOal0wVW6y5rbLTRyzaZHStErYS19Sfq7PegIv1Dqma5F2blp08mqnOMQtc1cpH4VsC6pzsDqbHMPneYNWoKSMP-i_SzqJeCuAPEQzPZW-crJ5r_DTZva0fPH4',
        add_photo_2: '',
        duplicate_resume_1:
          'https://lh3.googleusercontent.com/uTMP-UGRbkJDZ_kdrxuc3iyOjdWtrBNS3Pr_jEtkYxBJiuOKgzKe4puXzgYlsIouTkvCyL8AprOcwwhphVbd3xsHNiyASad9_cdyJ58EvN8u1AIqmHM1-H_y_BRN7NpiyJM1fVc',
        duplicate_resume_2:
          'https://lh4.googleusercontent.com/lirdOnkZNLShGjMZnm0kehfJZSEZVzX6xRaXCOT2yE2NainhWDQnQmmjnyu8SPbKT7bHfb7uOw0KQbYMNLgpvbV1xAyHKGn6nvdryoFttqasIcOE-a8R4k4O8-iIUGwlTy0JV_Q',
        duplicate_resume_3:
          'https://lh5.googleusercontent.com/p7T4rKPe3tj1ylY9YbjopOh7_SAtjSivDHSRU0MGkGMuvp5lsSfqje1NPfuvsiiykvop-AXWicxzFy-PJ_05hSAVmC3PFpny1jD4ES-TFlCpbblRGDrmDayxojcM8QYL9KdzTp0',
        access_cover_letter_1:
          'https://lh6.googleusercontent.com/5UukpH93LlHETZEIWMUjrcwEEFCELl8xVYaY_8WhViCi6OwfCC4YhS8QBQc0LOpbD5FySAgjy6uEm4STXf4t_4CsA07AT_2K-jXu9ibN_fZcWzEoouq6CJAuBirgpuixQb4seTA',
        access_cover_letter_2:
          'https://lh3.googleusercontent.com/YR4I5LlKLWEsmACCsvA9ypyxaB_D33xQLPjSkcVyiexVtw0CCYDBakFM2jETKTVSNtmfvwQz4PHogjGlNHz6FGiNKD576i2H2nkDNnzuTzkV6Nx2cJD_6ND7gsF_jv3SBXEHqYw',
        word_format_1:
          'https://lh3.googleusercontent.com/ExB8VCoxe-uHUSPdjiUfoDeox_IEZW8OxWesaSxw6qKCl8XX6ZrvPdksOrlghrd9UaWOKoosBIv9uovwSR632YiRAvr5DYntaFshoocxSs7G6N6x3sDo_xYtUQlO16ZlU9V3Gts',
        word_format_2:
          'https://lh5.googleusercontent.com/EBNc12E8UiGix8qa3luAt1aSZHxgak1afiUBE9qmdvH-DtaJup_iSASvtPL51yb43iOPjQmRd5yKjVJv1qgxc8mTojKh3c7GKIl_M2So3rEIgvcX8-R9OyVXkbafBudsQF3vMA8',
        customize_resume_1:
          'https://lh6.googleusercontent.com/Vx4Id1ZhaAgtxBObyNE1d6VPpaYgRHBiio1PjiRzAMNRA5wT_Mh4Ye52ESYck1u7O9ED2Qjeyi6qbjASheKwezLSMT0KkVyoRD-MDIHNARX9dCGX07Z1MUvvIBg3RxI1o6nIbf4',
        customize_resume_2:
          'https://lh3.googleusercontent.com/6X2x5UdrEadamH73Qydi1M6XIqaZZtB2Ys9bZQFTMmiFWx7O_n9SSzu1chnIgcVp7IfG3ooori3Xe-NkbOYkpZjvGVlBNwBNk9mCkyTt4tO8lZv2zvknXce0Jljf3g1WsXYw_hw',
        customize_resume_3:
          'https://lh3.googleusercontent.com/Zj51E72lLQWaigpAmIQiN7Ckd-mTSUrrnziYrLnA-ceBz7hA3GaGBRa8dwIlcDvHhkmDRY-lTvpenR5NIhXHAvXqv1EAPu-OIXBPG_3IJH-PjN9SuDCSrwzUYdJNH0ayLf91YaQ',
        customize_resume_4:
          'https://lh5.googleusercontent.com/2ZUd_VfdXgV4i6P8X4lGpasxkeOxvVDir80Z-JGj9GT44z1kwWrOPBEGMUO8Ftm6APltw0QgKc_PbM-Jla7DsfHh1QixDCX9VLZ6uuKxRmA4b-vBideGYo9leg5idSiJGOGaQpY',
        customize_resume_5:
          'https://lh4.googleusercontent.com/eaQ-c4ztqjUM1V8iYo8M_MllfyM7XJrNxUVvXWcCq30oBney9x2PQw4R9_3CDWDVj9oTBGZAI5U-I6MuXdop_raMf-d9O6AkvssM2UWjoQ1RodK8sjd80zaAKcYe8XYdPiXtlTc',
        change_language:
          'https://lh4.googleusercontent.com/2dYJ-QMcwmvjhDgOsR79UlNWVsWnzqSG0KmoaJT_5nIZsDETXsgCiZ-7erasOk-1bSoLkvTL5lVRdE1YdvtGsFS2s_iJrN5u8VKGPFChDhhS-BqLvmB0yAL15Sg9UEuTpG-6lnQ',
        change_font:
          'https://lh3.googleusercontent.com/6X2x5UdrEadamH73Qydi1M6XIqaZZtB2Ys9bZQFTMmiFWx7O_n9SSzu1chnIgcVp7IfG3ooori3Xe-NkbOYkpZjvGVlBNwBNk9mCkyTt4tO8lZv2zvknXce0Jljf3g1WsXYw_hw',
        change_section_block_1: '',
        change_section_block_2:
          'https://lh4.googleusercontent.com/eZj9k0FGrT8ZxZByMSMjnKfyy3XBUHEG7faxV2yxxWRU0LQ3SMCWCGIpDgBUIoTBIlq0Ae-FJQ9z8TEFN3We_hsDNWjtwPRXnSbYvRjReeAqvhCBDn-sEI60OkG3cUqhaJAursI',
        login_1:
          'https://lh6.googleusercontent.com/oP-vhv7nZ_NdhrB-WoEtAEcJvWVLD5OcZIv7yw9EepPAwKnno5t2QuUSvipo5nVBtKd5yquFvZqi5eIpff4Ui-8F2nrvAab49IAbFMFS5b5qDNBANr3N_798a1Kkl7Qy3yXrKAQ',
        login_2:
          'https://lh3.googleusercontent.com/T2WD9iWOtgxN72C2IamHvTR_3G1Dlt46BLs-u6rO5-UwWl-1F9mtcWOeRJOCUURjMKXr4wk4wOU6F4YTUrZc93HyJlFoEXDyg5dy9qjLv2TeOFn8uPWd6Mnm3M-5HK0VgxzXZ00',
        login_3:
          'https://lh4.googleusercontent.com/KZ25XCASIdBb1Omm6KygOOWbyNhnincfMl5I6rW5oT8KWpjZq_NxYSwWKKEpLZZPeP0uOlEta_HF8fEBetRE6tov_Yr1ErAFcjmr66CO-h54WqeiiaN7-3N6K7BhHmPFFNVcSa8',
      };
    case 'cvhazirlama':
      return {
        delete_account_1:
          'https://lh6.googleusercontent.com/3fISbtveaTGRnkYWZ-q0e3eFLzQZqoJlsSSdGRHz25Ao2j4ZwF6vg91X7Eb6spKcEOEfE6_ulqIa_4oVvD9JxeVGKwZcxnbvSZqhjDZkVsIbHoR3AMRRLOezmwKoLnswShe-eIs',
        delete_account_2:
          'https://lh3.googleusercontent.com/XCHxR8ZLERSo5MI8eS89olzo7U4LGYhbR8sNCx95qOv7DoKdFEMWbctKju66_LCsncmtmerelI81yue89LxZyjspq-o6HotVZkQ74CSr5ubOKEVdIj37hBX7wW-PgqCkzx6ra-4',
        cancel_subscription:
          'https://lh6.googleusercontent.com/z79YmWW7HL6u3hEvXTcvXj034x_NGaUD0rnOLXrr4tMmyFSFGraV2lBAhNjzUIAWXUz5hnvtTCJgVVomtpoM8R7KdpwTHnQOgRGXX-8wNdeQuyh9lyBNa1vXdEzmdMAyVezOLhQ',
        change_resume_template_1:
          'https://lh6.googleusercontent.com/t9BA1Mf4gSc1Jhe5Nc-lHo6tDN59j56-ocz03x1KrlI3G6X_knMRHJAJ3eH2XRAudAJeSIDNla8x56vef3WzTQh4-c2864t5QK3fYeCVAUDm-V8yJdyP2EQxWXLpuEbGqvlyaiw',
        change_resume_template_2:
          'https://lh4.googleusercontent.com/TztIoOqf2nlmxLPSFWF3T5tjT6rw2E7oEsjDfDBiA3wQVCA65ZgyMEENQpqmiuimJJHHNxPFLRp0gqrXrNXly8BjqIHHMISzqhPcls_wxiU8om3ZlwuYpUz8dczdYIG2-QBYRf8',
        add_photo_1:
          'https://lh3.googleusercontent.com/v5VAyPmKUgg9kCt3kz9AUb3Rku6MLFMaLHZ0lIC_WpR-loqKPoB9TNQdQhnc9ugGT6QTOF3s4Eli1TzNhUTq7YKIWJ_5zFxmWjQ1_De75j1N3-c-KTE83QIL-qQFcZKf_sqerrc',
        add_photo_2: '',
        duplicate_resume_1:
          'https://lh3.googleusercontent.com/MbAtnMl67Z0n1fHBx_nyuTR1PHWnHAaYfbI1qfSiLW13S_kuhu5uA-LljzYkcCidqcQ8rpVLGmDbCl25TGSYKNFVt4_TBA44qr5ayWGNn41x82_9n0da-9-4ogAlmKXjGjsu-94',
        duplicate_resume_2:
          'https://lh3.googleusercontent.com/cIb9SJoC-UokSuEEFTa7O15n1KeZK1WDSUEBGo497_C6cfMQzxvNdX3tu3TjOXWmhwt0iTIUmAmlDFuufPiExLJd44IOAiEl61LoDb1eP8ryWtqYh4reVyHFAWSNt3ecCl_0CI0',
        duplicate_resume_3:
          'https://lh6.googleusercontent.com/Ppn31By3UKRkac3QtcCzGPpglafYsyIxUIOTb9OFZPbqsWmyxC-QNLJueMQY4rb-ts99mwo3MIMJqIEn2DruP9Q3U4sBvUQSftHqrXlcwg0YuifsXPZqtF8m_vUqhlcpqk_g-Dw',
        access_cover_letter_1:
          'https://lh4.googleusercontent.com/22TzMzJFqZV_hVY3SakDdISlTZH4QAw2w52oaT5PA-NrAG-nh9mIjGP8tgOunCI0bNxVzz_J32KyrLuo3-UP4w810_X910ut7oNCPgQho223hhv4PT4ZJWT1ey3w9u3dsVkjwbI',
        access_cover_letter_2:
          'https://lh3.googleusercontent.com/L1i0LrhoyyoSdPuim-xrgmGaY31OOZCYgnD5hYQdTvNPalvrm6ttoRHnh0-zzR6-1VZF0bjSKJ0wgDXWvYBtneGi7n3p5GOh4RSPWVh0xncecIIqwb_QgmR6JUlC-5xwRAsa2lk',
        word_format_1:
          'https://lh6.googleusercontent.com/djBlCrqw7GPP7alVMIZvMb_wlUCv1e_0mjWh5iLN08kEp2hhn2XOG_eYZvTlsKz9XeWqT0rCdSFiYBlyoNMF_KAiBk7YOPKngDnXVq9rpuVuKkhHhOkJnOpS0v9LRw1F2H52yik',
        word_format_2:
          'https://lh5.googleusercontent.com/8QwDJuyfPDI3kYZqCSk928uCjPqqNWnicMIXlLR-a1UafQ1U306OW7JEDPadGRm6rvQqz3THJDn2YdttzCr0MwKSBgU4YDTkJaRTsAK0QVxpp3JiFum2A1GAcfHu9wfqh7hiqaA',
        customize_resume_1:
          'https://lh6.googleusercontent.com/t9BA1Mf4gSc1Jhe5Nc-lHo6tDN59j56-ocz03x1KrlI3G6X_knMRHJAJ3eH2XRAudAJeSIDNla8x56vef3WzTQh4-c2864t5QK3fYeCVAUDm-V8yJdyP2EQxWXLpuEbGqvlyaiw',
        customize_resume_2:
          'https://lh4.googleusercontent.com/TztIoOqf2nlmxLPSFWF3T5tjT6rw2E7oEsjDfDBiA3wQVCA65ZgyMEENQpqmiuimJJHHNxPFLRp0gqrXrNXly8BjqIHHMISzqhPcls_wxiU8om3ZlwuYpUz8dczdYIG2-QBYRf8',
        customize_resume_3:
          'https://lh5.googleusercontent.com/yluKbYtCxvPExMkyakTnGmk6p-ctf2pJLKKq86brcHE84XcFnei3hrkW0C05sM5EjWfhiLClXBxQohH11azZKu0qOZ5lHJb6BveOh-4wPU3FPJp3lDdCfvwha6qnSEslkKc74L8',
        customize_resume_4:
          'https://lh3.googleusercontent.com/Uq_fogVL7KruLSsQS6IEijV-NPhJHxgVrb9z0jzzsAuHdiDnqQ1Zg-RfmxXLozH2J77lrTd89I5Y5MGISZ1bQ3vCeCSSwF1lGjS6_Y2zHYxAll9cYH5ldgqORy8xfNQ6m8de2iM',
        customize_resume_5:
          'https://lh4.googleusercontent.com/TztIoOqf2nlmxLPSFWF3T5tjT6rw2E7oEsjDfDBiA3wQVCA65ZgyMEENQpqmiuimJJHHNxPFLRp0gqrXrNXly8BjqIHHMISzqhPcls_wxiU8om3ZlwuYpUz8dczdYIG2-QBYRf8',
        change_language:
          'https://lh4.googleusercontent.com/wSDXr1BVSMN0k7oxvBFYzuX_Xoe7QYfehPONFT4EijxL6KAbtzpGGwIO_RMZm9OfrmTM_5-CwAQ6WMba7eHK9Sl47BVoYQmjI9efd-Vae_WoqfvFyP9u_c4jhiROSDNnt0-Xao8',
        change_font:
          'https://lh4.googleusercontent.com/TztIoOqf2nlmxLPSFWF3T5tjT6rw2E7oEsjDfDBiA3wQVCA65ZgyMEENQpqmiuimJJHHNxPFLRp0gqrXrNXly8BjqIHHMISzqhPcls_wxiU8om3ZlwuYpUz8dczdYIG2-QBYRf8',
        change_section_block_1: '',
        change_section_block_2:
          'https://lh6.googleusercontent.com/YAa8D870qXgdtues87AuItLJOaaBZsTbT5jPq3qPbBrN5S-c_T6emNfRJjndF52M2Dtb_7QpuZufnvfQrOZjOgBLU48zw_gYSppQR4BNj1FJYj8S1xOr-vwvzAfWRYgrjCc9qmk',
        login_1:
          'https://lh5.googleusercontent.com/dDXIhb6q8sXzRgIRGrBsuNu3CGABhysGKQgq3n9iqho9BcgbBrBvDSTdS1ic5Z8uYOSgvwFYu45POVk-qtxCzDWQ_gpGDsrSr5mimk09K9FWg_xAJu6PzjSHs-A6gX7wLcgTprc',
        login_2:
          'https://lh5.googleusercontent.com/sSxep2Gz1jOyPduFKBxXf26WMJSoNg3jnz_u9Ola60cFWvrpR1NuJVoBiW7HubdD0w3iwMA7h6pzteH0ba2JqM48V0nSDbfUrgmIyq_CudqwMWbcIkHTVDVDd6G2cdNIFXokI3Y',
        login_3:
          'https://lh4.googleusercontent.com/c4y-HAuwsorn-eqyCevr9Nuc0uz7737fpZO11Y0UhDSgyDyqu969GC8_smjh1JVNEhKHdFGxb7S2yYi-BAdLh0W9KRbxL_40hwK5KN39Duz1F0SlRfq0NGz5nS4HnvChA51xf_A',
      };
    case 'thai-resume':
      return {
        delete_account_1:
          'https://lh5.googleusercontent.com/LquoIXajx5CTBOUjSTvTRlWMa8EM8prsbX_z7jq7shqgcMfSeRHxfwGm0IYFNNDIg66cD-DaVZIyd5jzLm2q-0J2llmM73M0GtcAkWNa9MHv84uKe7PnCZSFnE0gL2twJaPWboA',
        delete_account_2:
          'https://lh3.googleusercontent.com/irP0K41nMjis2FRzq5pIM8AYNbyWqkoM9cNw6yG3TJ9tn8uAi-csOeUNMU-cZep5WV7aAtH4c-soCgHpbriNUiSJ7AVo58fyzyY7GipINHOVxf2WSvO1vw4JN00G_f3gtkJUVr8',
        cancel_subscription:
          'https://lh6.googleusercontent.com/efqgnfaLU0kROkhpW1IJPod4iI4Rl1UjxAjx-MiCyfbvITapGMF1weAaGy0jjJTv3BdinkdDgj6uxY5lFP8obYfqE9EbL3zCRAi38nDI07JnMNgXnf5VBQh___9l3c2zbyUVQHA',
        change_resume_template_1:
          'https://lh5.googleusercontent.com/O6u97nYQnSlQNNM0SLPf2uKXhocjl0KH5xbtnofaxI-COmHShXM49z86REHOBB0_V5-O7l4u1Yi1bXj_y2RkVmqRVeQuVdQXyVD0ZuUzYqeXmn_1Ph6mDyWpIBudHcW2z3ubxA4',
        change_resume_template_2:
          'https://lh6.googleusercontent.com/kH2paifUw0mKIk9tvFIR_SSerVGVX_8pUV9EVhIta9ayOCNdEroMWsALedGBzQ1gzEa44r_5wEc6esjrZM_m2hKiGXoRVGHk3qnsHob4BxGCQL0NKzYoTBD6yk8n7b_mKDyJO1o',
        add_photo_1:
          'https://lh5.googleusercontent.com/e0ySrvEow95Vjos3lKW6MGYl7xFCZM5MGtGMkt4ZU4p65OOZV8OXVJCc69MHEV5Sxxa63BwsmS64oExdEV3zyS5Aey4oovFF5emVWkWVgqTLVKjjRQ3R0B-9_t4fYDaNUmqf4R0',
        add_photo_2: '',
        duplicate_resume_1:
          'https://lh4.googleusercontent.com/4ugJMXKVK_BPD4lGk9NlwN-y4Bc_dcvJl1OCkNqgBScHb702Jiqujh2peX2EV718_F0s0Xv1aLBTSXH3XNojBDmLi4q9SdgJGg3LgfIKb5bk288c7OYTrx1hwr0_mMULAcnbKVk',
        duplicate_resume_2:
          'https://lh6.googleusercontent.com/lp68EJVy8aZKY6kNl2ypaIWWtrKxmPK4PvHxYUf9Pk_3GzpuvwpnMK1xtqACKHRQ9cPnhROQ_cKggUgVTleUlEdisxeViajTGnr7vXuxty2IccWIqUW0p3eIJ9a3OOfZILARCzM',
        duplicate_resume_3:
          'https://lh6.googleusercontent.com/xPCSpsn3LqwlFD93ZDsRDuRBufsAzzB9OaK9i1ILSyu0TR4KVMt0qfKft9Kc2gj71zQNS0QdXAV4Kb7PI1x5QEycrbpj7ujQrjhtvj-Ujv_Fa_4o2NwbWuOLu4p_WHrJ91ICdGo',
        access_cover_letter_1:
          'https://lh4.googleusercontent.com/ornZW2sbX-hmST6wSU2hB2hJJPudofAIqvJAqytm746r-o6jz0gBCJY1Q5jTD0FwlE93zeN0CUPD7wTeUPHU9b1poGBGUGaDLTwbJ6381zJwzNnJCGnVC0fMxkS_A9mBkXOv8QY',
        access_cover_letter_2:
          'https://lh6.googleusercontent.com/DoOURARCXD_jlXp--NS5wv0BQ-RuAaS1JS1CdnLV7iA9kzhwQQrKT7le0zDEVg0VPE2M7cxqNWRHph9AiDDWYJ66r6VXeqkKh78rf14_E3VD_MlQiJUuuDBzoNdKaBpW4YRcarM',
        word_format_1:
          'https://lh4.googleusercontent.com/f5Yamtf_lon-nIvljda18i6J3KeHpPrky5weCepfRB2smzl-xsAyK2mODn_sHbC9wj6w7pfhCex28D6e4TBJivFQCn4NXIrpKRv4zT9N7MgZsBw-V48_TsS0o1Ewu0QX12qMTfE',
        word_format_2:
          'https://lh3.googleusercontent.com/fviC6wcql21fPX64LUV7eL8BFvr48ennJ3ugWqwDtP6DBSVtqNDBwuc49I1i6DuLz15AbqIzOXtvN34GoWTIgR2OUDlot6_gsttMPFX32tQd_ZNi_M_nVasDFmPnYy07CQ8ggQY',
        customize_resume_1:
          'https://lh5.googleusercontent.com/O6u97nYQnSlQNNM0SLPf2uKXhocjl0KH5xbtnofaxI-COmHShXM49z86REHOBB0_V5-O7l4u1Yi1bXj_y2RkVmqRVeQuVdQXyVD0ZuUzYqeXmn_1Ph6mDyWpIBudHcW2z3ubxA4',
        customize_resume_2:
          'https://lh6.googleusercontent.com/kH2paifUw0mKIk9tvFIR_SSerVGVX_8pUV9EVhIta9ayOCNdEroMWsALedGBzQ1gzEa44r_5wEc6esjrZM_m2hKiGXoRVGHk3qnsHob4BxGCQL0NKzYoTBD6yk8n7b_mKDyJO1o',
        customize_resume_3:
          'https://lh3.googleusercontent.com/6VRzFOMuwrGZCqYdwAeow_2nzTlQgws98wcN-BWxJuOkk3bNmrKdlhC_nbMeompgqGA43eUO5IIHZtLr01lGaerDAFEoluJrAjDNzHPiQq1a4BR-wn7MpnnQ8aOlXFYoJoIFH9w',
        customize_resume_4:
          'https://lh5.googleusercontent.com/RvXS-S5Ntk0kiDm6q_gnlmi3Gt05h3XJgjhCnCEQiYK-tdklyDHQ8r6F8172APkOWSdvBeeViRjgYT-ZvJYD2fhyKhVjjqMuoZ0kVQ9IyopbJR03Mfy1YjBtDEaBToJuLXE_S_k',
        customize_resume_5:
          'https://lh6.googleusercontent.com/kH2paifUw0mKIk9tvFIR_SSerVGVX_8pUV9EVhIta9ayOCNdEroMWsALedGBzQ1gzEa44r_5wEc6esjrZM_m2hKiGXoRVGHk3qnsHob4BxGCQL0NKzYoTBD6yk8n7b_mKDyJO1o',
        change_language:
          'https://lh5.googleusercontent.com/rzPwHTLxK1ikQxcr3an53md_z2fL6vqfHRQ6QUEJUwPkgrmhw8ssMB06eIy0zHoO453lXpTOOxYK5oeaFDDmlWC3HvueYa-t3W0kbQsYmEuMQ3H5LFzLky5vg7h888_r0J4D5yY',
        change_font:
          'https://lh6.googleusercontent.com/kH2paifUw0mKIk9tvFIR_SSerVGVX_8pUV9EVhIta9ayOCNdEroMWsALedGBzQ1gzEa44r_5wEc6esjrZM_m2hKiGXoRVGHk3qnsHob4BxGCQL0NKzYoTBD6yk8n7b_mKDyJO1o',
        change_section_block_1: '',
        change_section_block_2:
          'https://lh6.googleusercontent.com/kiN0V84JGb3iPuO_N1jfc50QFk6wzY0RWmyDj8scy6wBLQ-A0HD8fY9P6TuXUW_BLCGBPpQ7ZQlSfJpNmI3sdRpKJllWJuhtxdoWd-Y20mkAmgom1JMPYsXkHpMz9eMvfHWPjz4',
        login_1:
          'https://lh3.googleusercontent.com/9hyoHW5Pr5kkd_2CpfG8xlb3Q_7rMnLxRIKxAozU08Z3wnx-MW_6OOsE4rW4vl8OVJrCq-bsQPe_NdaiY75iy3dY1N4JjKC5pOGKfX7xstQotOHkRQff1ewgjm8uecW4D2rCWZ0',
        login_2:
          'https://lh4.googleusercontent.com/W-32_gKMuMWraWWlpHQSRDY4Z90bqGYpMuX-am1wFZACFlH794kKrTUiH1VpvNQi99lkXLANVNe_eE12lTk1gSETITAF8GY7ZIZUzKYcLzoVuiHroIbaNBkXTYTa2Vj6EqHor1M',
        login_3:
          'https://lh4.googleusercontent.com/IYst2ADCnccVbiA5KtvXAHUEe33jy8eLH8W-mNUkHAvsx6yTo8FU-ie8pzn47eYDRE908258whlNbs0qM61gy9iufiqWbT-x58nfVy1NyyPkwbu3n7HL91cbUzLBP2adlhuVcW8',
      };
    case 'xn--mxabdxcg5aeo5c':
      return {
        delete_account_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/622de74d2ce7ed0fb0914c1c/file-LMxaTeTtZK.png',
        delete_account_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/622de797c1688a6d26a74b47/file-JB3P6zmaC1.png',
        cancel_subscription:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/622d7686ab585b230a89ff8b/file-h0hFSigsm8.png',
        change_resume_template_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/622df90aaf6f8700f6dae94e/file-WZn050xoJ4.png',
        change_resume_template_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/622df9172ce7ed0fb0914c3b/file-dgnLcEtY2p.png',
        add_photo_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/622df0242ce7ed0fb0914c2a/file-j7meog3PqZ.png',
        add_photo_2: '',
        duplicate_resume_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/622de9d8af6f8700f6dae92a/file-DhhimqC0Wu.png',
        duplicate_resume_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/622de9ec2ce7ed0fb0914c21/file-gvkTXo1W6P.png',
        duplicate_resume_3:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/622dea0bc1688a6d26a74b4b/file-R5PdwqK34d.png',
        access_cover_letter_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/622de824af6f8700f6dae925/file-B35ygKmpzn.png',
        access_cover_letter_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/622de82eaf6f8700f6dae926/file-Ll17AJhwuo.png',
        word_format_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/622d7657af6f8700f6dae85e/file-b89HN3lCAa.png',
        word_format_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/622d7662c1688a6d26a74a98/file-EUcHC7nm56.png',
        customize_resume_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/622d7158ab585b230a89ff85/file-EpgPMNXfbL.png',
        customize_resume_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/622d75bbab585b230a89ff88/file-my0hFGLyu2.png',
        customize_resume_3:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/622d75f7c1688a6d26a74a96/file-R0cj33wz0i.png',
        customize_resume_4:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/622d76082ce7ed0fb0914b6e/file-cIPv7RLuGd.png',
        customize_resume_5:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/622d7628c1e53608cf9e7d45/file-c3drg9tA6D.png',
        change_language:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/622d70ffc1688a6d26a74a8b/file-7fhep6uIgC.png',
        change_font:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/622d75bbab585b230a89ff88/file-my0hFGLyu2.png',
        change_section_block_1: '',
        change_section_block_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/622e0fb6ab585b230a8a0080/file-Jm9e21w7EM.png',
        login_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/622e0199ab585b230a8a0069/file-gN1qyJO40o.png',
        login_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/622e01edaf6f8700f6dae95c/file-Ar2ksdHXrR.png',
        login_3:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/622e01ffc1e53608cf9e7e33/file-FlpE4w8kQj.png',
      };
    case 'xn--e1aaaggwcwefd4n':
      return {
        delete_account_1:
          'https://lh6.googleusercontent.com/ks07RcwRy892IksKxk6nX8OOMQh9K3pai9LHzzrNurbIxzGBmOF4wwE672_LqThv9P-ZvbSzd7osKLQG9Xhj6KxxLlP0D8jPaiuTTJbL3oInMX5jBJRW3pvGOSsvEyNI9jwpEg4',
        delete_account_2:
          'https://lh3.googleusercontent.com/tLaPnY5YsXTbLLDHZ2fexgVbooRAmxO9vb9nkSfkonYcqGp-l48VwwjLam4xNGmAVndCyGsqDVaGBrdf-_HD2NCswWj5Yj81EePvTAunwC1aSV6OyenfPUeETFfhPxlvJLmAqHo',
        cancel_subscription:
          'https://lh3.googleusercontent.com/v-xkBzzhytt5bvAvJtiL7Ku9QIuaXdr1VGpG_kBDBtD_hf37V_KD--zH1UJElDzXILJvO8ONf7uQ8cO29XtXQ76qTzG_UgJPdSSFk2x8kawhDYChijLhnmkBKRaK5ChZLacN1kc',
        change_resume_template_1:
          'https://lh4.googleusercontent.com/Xejz9mdwhv9YzAFs_E6-B5EHxzectXE2WtA5gvn6zjB5ka4TJsRvUMkDkiQx8GcyiMIg5JqVAeXxszNikakzfrdIfaDHAiGupVb-uDX7G2-WpmiUV1cfYMRQobBgExe7STFZOhQ',
        change_resume_template_2:
          'https://lh4.googleusercontent.com/fINFXx9n1TSuGSOfpFaSJ5fKRuJywYkfddVq61CXLYL1wt-NHtyufE9Luklzy-gUAjIYeanX3YczY5992BiObqcU3s5IscbY6QEYAvK4f1LsNMw-K40olOZgyskskQa3OkYROus',
        add_photo_1:
          'https://lh5.googleusercontent.com/HuOAdqk1BbQ2_KrapCuOML1KLwzvBqvGdNJA7zBFCNHPUzqeOLk35sEcBQ5L28i_XTkHJLc4WzLmkFlbK1uJd_iUM1SY0TYNrwAKBqc4XPWwT8KJyNSreVdbK2I-0Ia4_hPCAis',
        add_photo_2: '',
        duplicate_resume_1:
          'https://lh6.googleusercontent.com/aM8ZzkAW8l0EqXAqyK6AM01b36HuVf7U9m5bVbGnSETSOqNJgOexiq13fkLh2z3PJbHgvj_rJTv8P8AHRlopTyR1i5yKG9XHqhQ11jrTPvhwmXHudzhTA9hRxEPXmm8g0n5h5R8',
        duplicate_resume_2:
          'https://lh4.googleusercontent.com/SMQxPA6OweFSQaohD3klNYirLpRLK0PwsHOEG0x9699gnnMQf53khn7l00dcmKQ_yxIP9JhSqfZALXFZEzM95t7sPpGJhwuSPwGi2vKWr0a2IoNrV-EaO6pT1IUwtnveLLjhBz4',
        duplicate_resume_3:
          'https://lh4.googleusercontent.com/F1O1QtZUSq2r75YMNagHx7oNKdcrsMtW-1Ra2t0v6WUBHLvqW4_TA9uL3wNX-ascZP1-UGQmSU7hoACNfdreVu-ZTC47nv5Zeg1PDEU1M9kvmYDnt0bmmXlckooAlddzoFepd1w',
        access_cover_letter_1:
          'https://lh3.googleusercontent.com/7OqvSzrogTHXhhtp1XxwCZv31vs_A9-7q2fOeVYo248Z5hxT7-GzhA047ORavDRwYakCwaQKnWiU0U8-B4hX-LM1n7_sLQtkDtBRyMoEhLme4OPkYxhzyG_HyjLCW_u4W_ryJhA',
        access_cover_letter_2:
          'https://lh4.googleusercontent.com/UPd0Y26GJMs7d917zVYRM7Aje_KW1PhtnPl9mKUgxpor4lSAsw0EV7l6qv6R0gkPBPvipaXIRftIhEhto_5cG_63PF87SEVVT4bfRDlSdlNakikiPGUC-zhnXYfMXPwX7e0bN9U',
        word_format_1:
          'https://lh4.googleusercontent.com/dS4vRxyTJJHR60xKt5xi95oH6B5xMvYXuwYyNxTVYohzdW1Hv4UWfDeV37SY8cknbCILpqGD32oYu_UdUijvrkzcztdvasko1wLRZwEmC484cp21hCeYXCUKB9b_cozcDh6brK0',
        word_format_2:
          'https://lh4.googleusercontent.com/PJj6aVJVBzPL6ynryAIZRQib3EIzk5T4I6H9NDLhE9l6K06q03vlI8u5q1hNtBwPdEYXQ-7opQ1zwm5_8gD7BnKYYygVnVblyvLmDyIb5jmvC61W2CaFRB88phBJDZiOYzr1rAo',
        customize_resume_1:
          'https://lh4.googleusercontent.com/Xejz9mdwhv9YzAFs_E6-B5EHxzectXE2WtA5gvn6zjB5ka4TJsRvUMkDkiQx8GcyiMIg5JqVAeXxszNikakzfrdIfaDHAiGupVb-uDX7G2-WpmiUV1cfYMRQobBgExe7STFZOhQ',
        customize_resume_2:
          'https://lh4.googleusercontent.com/fINFXx9n1TSuGSOfpFaSJ5fKRuJywYkfddVq61CXLYL1wt-NHtyufE9Luklzy-gUAjIYeanX3YczY5992BiObqcU3s5IscbY6QEYAvK4f1LsNMw-K40olOZgyskskQa3OkYROus',
        customize_resume_3:
          'https://lh5.googleusercontent.com/HDIhasLsJPNSV2Nhnqj639eamYGR635y7HI3-2O3eABOWEBwY5jauxBOLUDssaFQlyR4BjCSM_qIQPwGUE8cAp36H9aTyHkyrRk-DJskXRtNqXpU3PmbLKyhseRkaD_tpskkqxs',
        customize_resume_4:
          'https://lh3.googleusercontent.com/6K-Y3rhw2v8VRpXh5oKkKPcVlFB5s58v1N-HX-EY5OyCsXdzTfF5cPKzF1ow5dW8yMKhQzKkqt_zG3Zqk-zGcVeKwJ3y7XrSZ-RIWbnLLtadgTc8WfuWIQnAaN5BGGb7lwAx1E8',
        customize_resume_5:
          'https://lh4.googleusercontent.com/fINFXx9n1TSuGSOfpFaSJ5fKRuJywYkfddVq61CXLYL1wt-NHtyufE9Luklzy-gUAjIYeanX3YczY5992BiObqcU3s5IscbY6QEYAvK4f1LsNMw-K40olOZgyskskQa3OkYROus',
        change_language:
          'https://lh5.googleusercontent.com/IMSt7e0bRvhF4PZ6M9t4vEWYrvqqBGGAyaaBOMEjMAChEFVekaG6_d5fUcIoqZt9z5fht6X3KUe4S7rM7YgJH4lelEFeKWNMqYaqU7n-a4z9hDbEwkUcM30kEhv1Q6tNaofc-WM',
        change_font:
          'https://lh4.googleusercontent.com/fINFXx9n1TSuGSOfpFaSJ5fKRuJywYkfddVq61CXLYL1wt-NHtyufE9Luklzy-gUAjIYeanX3YczY5992BiObqcU3s5IscbY6QEYAvK4f1LsNMw-K40olOZgyskskQa3OkYROus',
        change_section_block_1: '',
        change_section_block_2:
          'https://lh5.googleusercontent.com/-UghUbDP9f_ZnSncv9Heen88Dca3A4YW8tDPb0kEgKSmAap4Uwify6TOdZ0seatQYI3VaLgVv8JuXEJfJA1Wh3leWHvghSo8h-663YfGuu6NHLLyBpP-7JpPoD8t_XtGfjAkfwc',
        login_1:
          'https://lh5.googleusercontent.com/n13ef3aEAY-KtgUYqe2XbekL50T3TJPwcW0HPB0-qQaWMYBGcx_G64c5Gzpp63TpmhPU1O-8weyLSlv8jZNsCiwi1IW44YDRl61hFAf0f9mrtyUgNt9RCkE1PyUhalU6R77KANI',
        login_2:
          'https://lh6.googleusercontent.com/D4m42CT4XgmlZxhD9DPLMjMxfyBWKcNi-ilkuOS_0bely7rwxi9HRurWWk-qG6QUad83x1MIwKOAuSiBLQObhVQYhtXqtEFKCB1N33xn86c1ZptpZEg3LK9ky7oC17C8M28-OoA',
        login_3:
          'https://lh5.googleusercontent.com/Odxf027dO1H7zjkAAQo_sowW8737Qc3c2t6PBSDNvddtVmbB3R00fuUjBO6rjyyH98qBtlg7lXx7YIuxtV_dldKPqigHKTXYPCq2D1V3LVhAMcsrtS1VOdpuNot6C0gVqzyOGVE',
      };
    case 'criarcv':
      return {
        delete_account_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/624d5712c30b077486ce895a/file-qCy13NnKVM.png',
        delete_account_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/624d5733c30b077486ce895d/file-2QCylU58SV.png',
        cancel_subscription:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/624d56a8c30b077486ce8959/file-uVBPGlBTJE.png',
        change_resume_template_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/624d2d4656ed31630f6edf82/file-K4KPZHbWQF.png',
        change_resume_template_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/624d2d9cc30b077486ce88a3/file-DYNMfX4tfZ.png',
        add_photo_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/624d5ac4c30b077486ce896b/file-ozil06yBlG.png',
        add_photo_2: '',
        duplicate_resume_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/624d596e309f633fb1e2ac54/file-1T7SmIqUz0.png',
        duplicate_resume_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/624d5a472351d45e88b637cc/file-mQmqBJ7Msv.png',
        duplicate_resume_3:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/624d5a5ba22f2f5e956adf28/file-L1JRMvXC1t.png',
        access_cover_letter_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/624d5916a22f2f5e956adf1f/file-3vsj33kocV.png',
        access_cover_letter_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/624d5921a22f2f5e956adf21/file-39Qma3xXUz.png',
        word_format_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/624d558856ed31630f6ee038/file-G7cRjxQNUW.png',
        word_format_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/624d5595c30b077486ce8950/file-268aFVcqMn.png',
        customize_resume_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/624d2d4656ed31630f6edf82/file-K4KPZHbWQF.png',
        customize_resume_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/624d2d9cc30b077486ce88a3/file-DYNMfX4tfZ.png',
        customize_resume_3:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/624d2ddcc30b077486ce88a4/file-77NKjozdE3.png',
        customize_resume_4:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/624d2df2c30b077486ce88a5/file-2ctVtiSjFD.png',
        customize_resume_5:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/624d2d9cc30b077486ce88a3/file-DYNMfX4tfZ.png',
        change_language:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/624d2ca556ed31630f6edf80/file-jeunhFraig.png',
        change_font:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/624d2d9cc30b077486ce88a3/file-DYNMfX4tfZ.png',
        change_section_block_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/624d5dfb56ed31630f6ee078/file-yjbVhZgalf.png',
        change_section_block_2: '',
        login_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/624d5c68a22f2f5e956adf3e/file-jHEpICeEQS.png',
        login_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/624d5c7b56ed31630f6ee06e/file-5v5l470ZsV.png',
        login_3:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/624d5c99a22f2f5e956adf40/file-DPyrnPl25r.png',
      };
    case 'cvparaugs':
      return {
        delete_account_1:
          'https://lh4.googleusercontent.com/k0x8TwkhiDtohM674n5xE2hRKgDps_OADGCPNX1Sr24jPztMeehXoadTAoKiVZXhaH-woSpr6saEJVycHSDr5o3alHlXFIGBbBqHypVlCpX57c_3fEtzvcwZ9X2wmfK9dgWU9Bk',
        delete_account_2:
          'https://lh6.googleusercontent.com/sMVQUugmOcvhYzJM922C6CV0yJTeGUZG5WRqWqW5yjJpKL3xwVVm4_7Fa2Od2lNxvCsrwXQhVcze-Q5mWPb7_wk1W4vp3p8HjvN5VwmVgJX9z8vdmv06SAT0l6kh1wyC2G-o4XA',
        cancel_subscription:
          'https://lh3.googleusercontent.com/8aZfEONY2uu84QaoTxNyswHONeD-2x_uSDonAMpc8mcgy_-WnHBcu8zust_4eTLcd2wT1FtwIIKwnxOLOd1YyyuC6YZk9DLJSQp96N6r0sMOPiFkJ_vv54hi41k8rxY4tq62kZY',
        change_resume_template_1:
          'https://lh4.googleusercontent.com/kpdJM8qh9jwV0dNy-TvnzYVeRs_3gsTWz_pDIXukT7BA569Urchc14fIDPdh7joVfnau8xs-yVb5D7925GkypyNX7qSIahDk2Kvabyo8doLNXQ4eTuXtoOS_cgYL0TkoZ4i4_cE',
        change_resume_template_2:
          'https://lh6.googleusercontent.com/86gP4vA9V8bf_IfIkdA9adLrs_Vw0cPoTZmbOUR-qycJ8fZVpkxm41GIl6kdbGy-kwwZ43eXVwZTZtXVL11xEOxbmgR90PJh_v3H4fCwhb6XI3xJ7IU95aZdBJMJl4-t5AJSPxA',
        add_photo_1:
          'https://lh3.googleusercontent.com/Q9Z_07TDg6vaj7kelhUFSpeGKYEhWpBMuITpaOt14O6fozoK4zhAWKmBmuhTtvKQn2NNdN0mVN8QckYszG3uRHYCBppJMiIzjgwgw6IQKjf3FA0ohvDuMZSecTxPsG6FESXA4gA',
        add_photo_2: '',
        duplicate_resume_1:
          'https://lh6.googleusercontent.com/IZ3MYn9nuzJnUkEuz_fKaSMcW-Oso2Ge_6yExNDNhDh8dwY0yzvujXMHO4a3I9iy0tfJPumnQe_ADbNWqSokWMQltpBK9_pTKrgQ6VA6sqxG5gfAayAJvb8w12kx94KNZxVtG9s',
        duplicate_resume_2:
          'https://lh4.googleusercontent.com/zzqa_AtcFyuWag2Mt0PCfbrtxiISGi86-gGD-T0WEtMJE7VsVPjQ6-HvIQY4bdHxE3rbBqkFnuRWQyTu2VXGvjDvxrWA0DBlBANitAOJYQje1ARkAb9MoFpzvcyK801-XYv_1Ik',
        duplicate_resume_3:
          'https://lh3.googleusercontent.com/Ef-Rj--Us7MBY0S40pRh2ArYSmUza0MS9kcyfENF-f9pbboePR22StiR4VeHtbYuJNoO-P6_u2ZMwPC0DXCbNR68KTtUs0RV-nV7ftC0WqbSrv8TXFWPTsUy5GiHihhnWT9sSgs',
        access_cover_letter_1:
          'https://lh3.googleusercontent.com/sq98V4Y61PfKV4fMs303AVjxZaWO7znwB5Wtjfj43u1U2xtqB2iQXuXEZI41iEzBWENTdhnTBpV_nY2Qz5FuVdOWzM6tG5jfKDfM3AJJcK6_HlyriikomorqcvL9h88AenWt9Gg',
        access_cover_letter_2:
          'https://lh5.googleusercontent.com/rIGmP60zUlIlApD60AhSmhLfjVH1NwtAngM-X_ZFby9KqU6ciTGeCdHSZNQS47y-j98F5qs5WNnXD0tfStr2jcPpNzlMkwcNp8pPKWVYpi91IyVZXecqfH0zSifCpyQxtShrBRc',
        word_format_1:
          'https://lh5.googleusercontent.com/o9G8oKNzRc26CRIvcjrUYTVYq6BQ3a-NuuEbhuNpJEpAlEMx7ArYCzG9ezGM6CIKDbXsS0mgeOkz_iimziRyPs020aBO1lbwRf7ijB-4GhoZb0zlw57IeL8I2O05t9A7pG5A6Mo',
        word_format_2:
          'https://lh4.googleusercontent.com/x5ocNac5yAa2AG5cWfexTXn6NYlovo4VZl2MmbXaXkFbWchr-q_YcJXJqET4SNLsdrafC-trB1gUVPVJHEcNmcVeAQAQxmpb9hgwfb3_hhaF7XKfH75p6FkMCaNOzbd-YyfQW6M',
        customize_resume_1:
          'https://lh4.googleusercontent.com/kpdJM8qh9jwV0dNy-TvnzYVeRs_3gsTWz_pDIXukT7BA569Urchc14fIDPdh7joVfnau8xs-yVb5D7925GkypyNX7qSIahDk2Kvabyo8doLNXQ4eTuXtoOS_cgYL0TkoZ4i4_cE',
        customize_resume_2:
          'https://lh6.googleusercontent.com/86gP4vA9V8bf_IfIkdA9adLrs_Vw0cPoTZmbOUR-qycJ8fZVpkxm41GIl6kdbGy-kwwZ43eXVwZTZtXVL11xEOxbmgR90PJh_v3H4fCwhb6XI3xJ7IU95aZdBJMJl4-t5AJSPxA',
        customize_resume_3:
          'https://lh6.googleusercontent.com/XpzU61cp7vnZtZS8fCyYPF1JuK93oBq5_aViwBDYQ362ZWZCEQE3hOZf5DTJJnj65Y5939Bm7kpD-WwtWKi9hBK0qeiFV7gQ1kyGS3H405s1SAljaOaxbxXEgWsflAdw8YSda2M',
        customize_resume_4:
          'https://lh3.googleusercontent.com/1-aXXtVTiXOXmSllCMlwb_uKPdX9Vmh-ymYbxr9AtgECktAS2Rw9QlIrT0pfy-h3lLNpQt6Iu3Ve5iQ1YaY08kqDMq_FMbJ7BpVWQQ-Poux7RFsO7pRtGKVdoeq1koRs5EdlYzc',
        customize_resume_5:
          'https://lh6.googleusercontent.com/86gP4vA9V8bf_IfIkdA9adLrs_Vw0cPoTZmbOUR-qycJ8fZVpkxm41GIl6kdbGy-kwwZ43eXVwZTZtXVL11xEOxbmgR90PJh_v3H4fCwhb6XI3xJ7IU95aZdBJMJl4-t5AJSPxA',
        change_language:
          'https://lh3.googleusercontent.com/G4yRbjbtEOukUK7frVH9lFY8M7wefO2fISzE2BT8xroEpOuAgMTzJsbHVWaB2cZUNjRgTXnQg95pxCXTOLh5YyvRNiMjkycw0-U9Zr4xCZCHmTXiIw35HPObrw4bVRbYHIvbs3A',
        change_font:
          'https://lh6.googleusercontent.com/86gP4vA9V8bf_IfIkdA9adLrs_Vw0cPoTZmbOUR-qycJ8fZVpkxm41GIl6kdbGy-kwwZ43eXVwZTZtXVL11xEOxbmgR90PJh_v3H4fCwhb6XI3xJ7IU95aZdBJMJl4-t5AJSPxA',
        change_section_block_1:
          'https://lh6.googleusercontent.com/drp0j3cnVFR7RZBWvUO5np41RFM40OCuzFGUslsO2bcC_ZXR0z233GpiBHn-QzNLu0zK8Z7WKERmWbJtPn7ZX1ovtvHrrcRMDt9_gKRxZHz0DpLeyHpieOJ1EI0-MzZrzxkq6fU',
        change_section_block_2: '',
        login_1:
          'https://lh6.googleusercontent.com/m1JoQvlbdroI4UWDkbqwO_p8ookQE-1LJ2YdBaE3buHccem28Uzng8GiZOMROs8tIK7TlVljYJKTSzGHe4QbTcYPhzV0AiYr3seXNWXep7DJHx3I45dYy-xHEQ_jizsiUJKcuKA',
        login_2:
          'https://lh6.googleusercontent.com/dD5bGAvGeDqIwT_2XAyORLwuF3U7BmtP4TZGkZTCR3_q58sR8TrdRZDsmO1tTcvV3AnJKHtjPrX18pVbPDXZrQH9UK_accIVnXCPDY7O0gQWyve95OSBqhaAQQ7oMAVO33PH76A',
        login_3:
          'https://lh3.googleusercontent.com/DCBkOe5fLSTERfiEudKpJbj0twhvy5HTcmNEcKx_rDsixEezdaUF4G6ZbvLsrlisXjod1Z0MGNnsO4QSphyJ9R5qUjaHG6oEk2hwLXZi9xK-ZmKhODdJ_S1noZNEzINXGIJb-6c',
      };
    case 'modelos-de-curriculo':
      return {
        delete_account_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6221f0fdab585b230a89d330/file-zUWk0rq9iS.png',
        delete_account_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6221f13eaf6f8700f6dabba1/file-goop0I1Mks.png',
        cancel_subscription:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6221f0d7ab585b230a89d32e/file-MKDDKrAKPb.png',
        change_resume_template_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6221f2b6af6f8700f6dabbae/file-0Mtrapu89E.png',
        change_resume_template_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6221eb86c1688a6d26a71da4/file-OELzpgeIVB.png',
        add_photo_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6221f26d2ce7ed0fb0911f7d/file-LyCKnQWkx8.png',
        add_photo_2: '',
        duplicate_resume_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6221f1f82ce7ed0fb0911f78/file-9hNz84cmjB.png',
        duplicate_resume_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6221f2022ce7ed0fb0911f79/file-hXD7MiF640.png',
        duplicate_resume_3:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6221f23faf6f8700f6dabbab/file-C3GBuh8nWx.png',
        access_cover_letter_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6221f184ab585b230a89d335/file-UXZIYZvZ6z.png',
        access_cover_letter_2: '',
        word_format_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6221f0a02ce7ed0fb0911f72/file-zkxJmz9DnA.png',
        word_format_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6221f0a6c1e53608cf9e5065/file-4cM9fkyqQj.png',
        customize_resume_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6221eb9f2ce7ed0fb0911f5f/file-O6qzYA9K50.png',
        customize_resume_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6221ebc4c1e53608cf9e504b/file-g2xojmBkt5.png',
        customize_resume_3:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6221ee2b2ce7ed0fb0911f6b/file-NpTXU57sDl.png',
        customize_resume_4:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6221ee5ac1e53608cf9e505c/file-EhueahGldu.png',
        customize_resume_5:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6221eea0af6f8700f6dabb9b/file-HWZ8jwShFw.png',
        change_language:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6221eb0dc1688a6d26a71d9e/file-4md8m7T6DO.png',
        change_font:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6221eb86c1688a6d26a71da4/file-OELzpgeIVB.png',
        change_section_block_1: '',
        change_section_block_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6221f52ec1688a6d26a71dd2/file-bY8GZsBP5a.png',
        login_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6221f398c1688a6d26a71dc7/file-roPWrZr0Ph.png',
        login_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6221f3cac1688a6d26a71dc9/file-ozxPzRqZcB.png',
        login_3:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/6221f4162ce7ed0fb0911f86/file-PGDnS6zQLi.png',
      };
    case 'creare-cv':
      return {
        delete_account_1:
          'https://lh5.googleusercontent.com/1s8jztE_IEndzHmHkUnQlad_Ne4xvynAlVoizXNDUXLZhKgmCzi_OZRx31SrqjTVqRIMVEK0pWqdVMeK5n7Tz_56JWoiEAGFyW4R8Uj_ABZsvCaxDr5iTYJ_d0x6Y9pKGXGgnOWP',
        delete_account_2:
          'https://lh4.googleusercontent.com/e4umsmG7gPmAFTnlKFpbD2IytOcBp4_Bs-FXqVBYTu0uM2L4_zPTZ2CI1AHyGv69dZSq4uEkMxeI6oW9rHITT0Fbf0LNkIdrNM7qkFuw7OFTl9-0-xtbzz9RvF9EoLCeS0iOSVo3',
        cancel_subscription:
          'https://lh5.googleusercontent.com/FhVGOzmr9bOuFap2R0K995N44Lz_fx_yRMCAiE5CXoQrd-TQ9HJAYJRM3YSAPPIOLNSRHCXRD5R8HRHJBBLVKA_1YHZP8BAFEZWEAVPFGFFHIPCUZEIKBXCVWA2DL08SFROFNXA_',
        change_resume_template_1:
          'https://lh6.googleusercontent.com/N70F4oPQcIaPplEsrdVInP6ldccfRlhcuar038pFedb-v4KIg4ztVqgjJCYSO-AAmSTXcspz7xNw_SF5RatrzutxznumrHkjGxWOsdTvJk5HlP9uQYDKfSdV0yVkoxAtdLp7Ut4y',
        change_resume_template_2:
          'https://lh6.googleusercontent.com/crCvjandeDt5L0mU_VFxyBCd8mRuIvTqBq-VQR9FfL-arFgVMsdwAo12xaW18bMxvME2idG5mhyu5sWaNJJPGLY97bkQ_9cret_StWsecbcDXfreBk3q3qpd9Rc1V1_e2H0lMs4Z',
        add_photo_1:
          'https://lh6.googleusercontent.com/M-cQv-I9A7W6m9snnCF20ohkIXnn3g1oKVFINnlT5D8r4CGM37veKFpDMGBO_g84R8lePvszH1qDYpdjZBmy30uAnToov0RLRaA4DoEsZDsPTMsKDaVgKVeHCXHUoxfygXrInGyL',
        add_photo_2: '',
        duplicate_resume_1:
          'https://lh3.googleusercontent.com/aI7EsirsBgGgE14hFu8vQ5vGw1G7OPGR4lFxvFOFT3gTc1Wzm2kWzi7I536V-hjh4jepXYzF0dMfNbiYykiyAunEpQ7BxqmpSUx65Ym12d-ai3pYX16I_dZvzodlLglB_emGh06Y',
        duplicate_resume_2:
          'https://lh6.googleusercontent.com/6kQ520hulxeShAletpXcmy-8XZi-JXw4oFUoyr9gxkLI1eOVq3xSo-sH71QQe-4pgjjPmNkmdcDA17yXXvU-eNTRI0d3dKAhuoZhA3g559oqy7mmaUBNq79TKX5XIbEGQGq8DZVU',
        duplicate_resume_3:
          'https://lh3.googleusercontent.com/EcNKj0_aLCKGKbdp6WpkAYxURz548aKY9gb3kg93Q2dQ69vNWJHk5UvYuSwPz6tJ3Cxgy1R8SXM14iboTBQN-DYGHS1FUnmcSfUWWz_lWjVqkX5l-1j4XyZr5I9ZpyBPQx-IqOG2',
        access_cover_letter_1:
          'https://lh4.googleusercontent.com/ycNIKVcN05c5okaK0MlzPp01SoCJdfAH4nAkACBLuRMsmEkc-ajSZUtwsyCrRPkITGLEaiKimRagYfbK7WWak5rfOjE5gbD_CSruS_AKqZQYvVdFzD1HlQoL8ATth_hIJ6KJL6nb',
        access_cover_letter_2:
          'https://lh3.googleusercontent.com/5eKng5N9cvmsmN8NjktohSMQNQIeu3G9cWp9BV3YkFoEKKZtIU8drZnMJ5Wwn8pV7_h5AXJ-Ogga7Rrrl4RCFqwISUBp28D3PNxbUBmh2rThbCL6c4CFlxmoaf-1_-7YdGaBtmaX',
        word_format_1:
          'https://lh3.googleusercontent.com/1fFzZWyvOmyluFW1Yj3ULWptujcKlsfNSP7iHP06N9DI_D7Xfwxg47FZ4LL2C8tmNo0CF4gL2RFWMvwesdBMq1_6F5Z5id3YY99VhdqtXVxfddOIYxsmGEaoc9LT9nV9YqTfHbmf',
        word_format_2:
          'https://lh6.googleusercontent.com/XmgJu-I75fjFls8mY5ixRm-PnudjDLWMbDPkNfzA_kTjHO1ZkbvPQsT36kDciNpDHWZNyT4pfzZMfkhL4Zvsm3m_4Mss280eD8-cCO-kRX4Ar7ODyoz3kXP7Srkn_isNIKh8rH5E',
        customize_resume_1:
          'https://lh6.googleusercontent.com/N70F4oPQcIaPplEsrdVInP6ldccfRlhcuar038pFedb-v4KIg4ztVqgjJCYSO-AAmSTXcspz7xNw_SF5RatrzutxznumrHkjGxWOsdTvJk5HlP9uQYDKfSdV0yVkoxAtdLp7Ut4y',
        customize_resume_2:
          'https://lh5.googleusercontent.com/biDa8stUmXoNOfXTCMiQHdVHhyLboGTNELoKJpTFT_Lgz-ZK9YaWlwvZzUWFZ6k3hozfhbxOZiyDqpIVm2dDiA_cwzwMr0x3FaciRjPVhDb5yH6BBFvOYstXTQjkcLOLgQEA97lI',
        customize_resume_3:
          'https://lh3.googleusercontent.com/VdPqwJ-wEsqsULPz7zP7udmKKwZUMBX4fnL4fKDxpW7oJ-EvRn1DaT2S4DCeoKSixr1ENvzOr_9sKW2w2sdOQNmIJ-SwR6uf-zkvf-Ocm-IQY9W2HAggfV8AtqZ-TAYR1VQpCaSG',
        customize_resume_4:
          'https://lh6.googleusercontent.com/u8NNveISpCxUjY4CAETirK7khaCDOa7o8lF8U1ORcvPMW1xb_qurUPE3VnAsrkuFwTNQxWfdZ3AB58AGI6eV98KJ7_PnJcWvy52wStHbRrzuZ04wWs2wBOjXxUxG9mVHKSOsKiPf',
        customize_resume_5:
          'https://lh5.googleusercontent.com/biDa8stUmXoNOfXTCMiQHdVHhyLboGTNELoKJpTFT_Lgz-ZK9YaWlwvZzUWFZ6k3hozfhbxOZiyDqpIVm2dDiA_cwzwMr0x3FaciRjPVhDb5yH6BBFvOYstXTQjkcLOLgQEA97lI',
        change_language:
          'https://lh6.googleusercontent.com/crCvjandeDt5L0mU_VFxyBCd8mRuIvTqBq-VQR9FfL-arFgVMsdwAo12xaW18bMxvME2idG5mhyu5sWaNJJPGLY97bkQ_9cret_StWsecbcDXfreBk3q3qpd9Rc1V1_e2H0lMs4Z',
        change_font:
          'https://lh6.googleusercontent.com/crCvjandeDt5L0mU_VFxyBCd8mRuIvTqBq-VQR9FfL-arFgVMsdwAo12xaW18bMxvME2idG5mhyu5sWaNJJPGLY97bkQ_9cret_StWsecbcDXfreBk3q3qpd9Rc1V1_e2H0lMs4Z',
        change_section_block_1: '',
        change_section_block_2:
          'https://lh6.googleusercontent.com/eC6Lb_Gzc7hSoUffkHtSXsiJekD46pW63r8PPwlT1vI20fZDeP6XYQyg9zwVRMG6olBd_u7ZirHiA3yjiDhDZgrF2WS4gcyp74E58X-ohpC0rNEZeJqQmDMo11UheLvTZ9pnUyl-',
        login_1:
          'https://lh4.googleusercontent.com/WDmhcn5NpZXtE2uCdaVoSKvc7fS7kdG0zd1REw50KJnL6GMU1kfrIwuYt_i7LCV0hPD6Pc7vVBJvtl39Ax_9IERF26v5v2BYkHqBoI4lCU5hUoVhjKVIYDIzfFG6Pn2gL9ET-4Td',
        login_2:
          'https://lh5.googleusercontent.com/CCzcNeWPsEAK0bqH_ni3pA4SIpf0WVqQ8qkiDo6tG4bVl9Rs5FAGJSdB2NLlC1WCkpMHsL7VXm5s-ryc6xFFD1RxBe9iYjiqg015VtGIF1bH30qltb7ANZcur63_974-TQ872oif',
        login_3:
          'https://lh3.googleusercontent.com/jv6YVQHB_dO0zNYZ9ryMfKH0DzCmUJAVZt13a9ZLOww2AIOubWU8o_8Uui1QvNR7_ROk3rhP5qBLy_DpHPTWXIOobD9e-gRc2JH27SaJ8JL_RQcxl-yvc02Fdjvhby8wRKLk8bzh',
      };
    case 'cv-pavyzdys':
      return {
        delete_account_1:
          'https://lh4.googleusercontent.com/Ce3lI7Lz55V6Mu24to9JErfSgt2wwStmpilfpQseRqr8fCuuTXugfeu4cruWtBOwLzLWQOjKGtWCZ_-mvlzcDFl-Yvz7HBW4xpjyTq1bZ-oqmMfQoZ6hvGYzHelfwe6EdqcOf7ACVLGUJnBfMQ',
        delete_account_2:
          'https://lh5.googleusercontent.com/c8ug5nckorANYUy--ZYYuN8NgFV87BISpQahGzPWE9D6OgrBWEGHqlkL0m9oYPATOoUsbxJd-W27GMBwUKqUoPWq2X7rduZ_HmePJMHi3kNXM_VUuiw9uGOiskmrQdPemyIITLt9Jxhc7Lq1Ww',
        cancel_subscription:
          'https://lh4.googleusercontent.com/grXryr6DHpQ_lfvTMVfjcKwLM7S2Wgw6kkbv-5Fk29VSQmHEtDBBiGqH4O54uFmmyJq3fFI93ehmTASFTzkFf2_54Y6UFMSu2XUxGnqW4CEukRAftJSz85PKZeiWD7pZ2D7IHACxFjEiVYLppg',
        change_resume_template_1:
          'https://lh6.googleusercontent.com/Nq50b_q8E2pYGcR6buQNyOC5EwohXDLL1kp6h1NPIOPuxr4Kl9BexrIYP1QABnx4k4pix_eR5zZ-n8RzYnMqwrT9XqJuBEdcuV0-zvq2lNminOba6HlDwnsmTZcpE5Tf__dTXQrINmRz90OfAw',
        change_resume_template_2:
          'https://lh4.googleusercontent.com/yUaHnqea9uY9KbLDafhNxqVRqsBURBQMFo2Q2O6Xye_BdArSLTC8baYXgvyUM68reVh6xlrZnFLLMnEGqqlqTiwr5JcJQoVnoLj67IjcCrcMxbJbr_PH86-MvEXF_VOflcTA2LDTrDcSVRPs8A',
        add_photo_1:
          'https://lh6.googleusercontent.com/pGwkN6U16NHLWthc5FFDCMALqL0r48QAmUFIurRoG11FAialXrknQxoDEysKFUYLqK1-pMSIAhaVf7Lrcx3rKWQUv-hT6r_zEuQxXqjVRBv9tBo4fDJfeVVm1SHMQkjTqNybD6Skfb1FnIR5Mg',
        add_photo_2: '',
        duplicate_resume_1:
          'https://lh4.googleusercontent.com/YAoQ7ZeaG21Gelcb3DqxfN9MPttH5Z5mQvC2F3tF0ZxncjDtIAyUaT0lvmUBxvZdMWM5CONEw0DluImGN6pCMBbUEnCp2BlZxJxTjPJnWx8V19tXxmpxzvZLTEoG4n-WDFW6ZDuZmryEoS5mgQ',
        duplicate_resume_2:
          'https://lh5.googleusercontent.com/FEf6lwJIv7a-dqBm8qSG0VXsZKXtiOUeqKNlO4nIwRrEA_2_gp1r3QRxyHmyqLSuMmF7dA7zWYVvogyKyJXkyPQUcUzfzMU3pRYONoWqCwWGpusQx0OtB1avzvJH7Ls44n4GFRsKjNNKFJ57UA',
        duplicate_resume_3:
          'https://lh3.googleusercontent.com/lwYmVeLEsJZpwT9h0iNaSpxUb-4ZvhpDreC6t4cix1rh5_5mxuCY0SUb0Ia5cK3ynLeBUiJYFSIUY1W_9ciD_QCHX5rs78yEymYXr2ryLgXnVOdBMCgBOxXtUrfQmW1k97kfgNM4ldt_G-iaNQ',
        access_cover_letter_1:
          'https://lh4.googleusercontent.com/XRdQLFKWokbC0xYtty3GScl0O-lS2Z03ftQYfMrbx2QWCC_BFf9rFAxEXJfEH8_XAfW5SeKIe6H-0OS0F4DXm9IVWI7a-ZzaLWC2AcdhcPTiwgi6bA6iDtH_uJCk8-RwHB6yzy2CYdIXwfgggQ',
        access_cover_letter_2: '',
        word_format_1:
          'https://lh5.googleusercontent.com/9bKGJ5weSsTj5vFEsG9bcazSP-sym4YQvC1WBbZ8p0xngNHFL2a6YCkSPy52oz2UFLoCqi1STpoykHMAZBp-yD7WAEV2GH-ebD3QVL_YP5SoeQMjvH-kef-cGXWZcvBLohBbP2egZgb1kFNmaw',
        word_format_2:
          'https://lh5.googleusercontent.com/CShSB9k28cPFRNBlf5ejoRatX5nQkcXyY0OsZ0arh_y0r8maXjvhv0J1nFl9RUh42AgXMxuP3z_y2E-l4H3aU7s65jbhXIGsfQmZks2e-Ub7WOWq4Rah3GS1rqWGSAEC5hgN3sjqCG-L4R5A_g',
        customize_resume_1:
          'https://lh6.googleusercontent.com/Nq50b_q8E2pYGcR6buQNyOC5EwohXDLL1kp6h1NPIOPuxr4Kl9BexrIYP1QABnx4k4pix_eR5zZ-n8RzYnMqwrT9XqJuBEdcuV0-zvq2lNminOba6HlDwnsmTZcpE5Tf__dTXQrINmRz90OfAw',
        customize_resume_2:
          'https://lh4.googleusercontent.com/yUaHnqea9uY9KbLDafhNxqVRqsBURBQMFo2Q2O6Xye_BdArSLTC8baYXgvyUM68reVh6xlrZnFLLMnEGqqlqTiwr5JcJQoVnoLj67IjcCrcMxbJbr_PH86-MvEXF_VOflcTA2LDTrDcSVRPs8A',
        customize_resume_3:
          'https://lh4.googleusercontent.com/B6vqr4ffAP-banKBWl1JIVmJH4RWOkIU6RAMJxSS0mWa46VA8GOzUReO70-Lb8LJUgKd33RhKyn8RXn-aoBxkJFLimIqILVZOb_p0m0heltpiKoA56j2jhhC1q1ktA5JgQJvxR4g7PCDk2P29A',
        customize_resume_4:
          'https://lh6.googleusercontent.com/gCCZcLuAJoCaz9Rn-g_bRWI_ihWnMhNcf1yMaYF-eJoA_woGK0kRaJXJnVSmOzlVNghl2oEJXtSfxvpqJJpNBSRL0OLjSXeKkSfmsIhV95dLTYfMY3yql0Wzo-wQaRX0-H_r4Bjq-SFdDlfAig',
        customize_resume_5:
          'https://lh4.googleusercontent.com/yUaHnqea9uY9KbLDafhNxqVRqsBURBQMFo2Q2O6Xye_BdArSLTC8baYXgvyUM68reVh6xlrZnFLLMnEGqqlqTiwr5JcJQoVnoLj67IjcCrcMxbJbr_PH86-MvEXF_VOflcTA2LDTrDcSVRPs8A',
        change_language:
          'https://lh6.googleusercontent.com/YZV0kP5oedtbm58Vd8S4fu6MtPKlZNUjXepL4whoL5QgH6X6D_9tD7sMUarpGprEM0Ro9lVfbXObrnAizehkSDbj-FXxj_O3-BQUKM5vPR1gMrWsPLQioSPnvH9axtmWZyhJKu_bLhMpTT7RDQ',
        change_font:
          'https://lh4.googleusercontent.com/yUaHnqea9uY9KbLDafhNxqVRqsBURBQMFo2Q2O6Xye_BdArSLTC8baYXgvyUM68reVh6xlrZnFLLMnEGqqlqTiwr5JcJQoVnoLj67IjcCrcMxbJbr_PH86-MvEXF_VOflcTA2LDTrDcSVRPs8A',
        change_section_block_1: '',
        change_section_block_2:
          'https://lh4.googleusercontent.com/DQYOqrTbGz3iHUh2_g80red9WTutm553DTOSJ6eVdbZiRROrKjjQPccOzTxsQ-8nCmwc-TCRan6Ff1AA39T1wR6LhactJt-xXSHFiZYYGMB-79ed13XzXT-3QmAtHCXZZx0jI9pJM9sohbouEw',
        login_1:
          'https://lh6.googleusercontent.com/DT7n75mApmYR83Nhelo_Fi1iEyCNUZ9wL-07CRC3lyFaW_7_kIJ35HduumZLnGKaHKWoo7Yts5NsBF-mq6sV2rhGje1ezp4iVb_1gJNYZ_fLUFCrWVCdXipGDDBVXxRT4v4QoGOMDIc0xMHvMw',
        login_2:
          'https://lh3.googleusercontent.com/9psadhyrqli49CARns2GKEdiiMdY6qlbrLugptXfbKxuGgh3wMEcYnxy8OIHln4WT9HkUdNMUCpPrCZjjQeaSyLdViJPbfKwJNtsIsWa2O3P-2eHwV73DiGmTzttV4baQpRu7tQMB8rawjL9NA',
        login_3:
          'https://lh4.googleusercontent.com/iLv1I9Kz72KvYn_gE41Y0YfDvlSTPDyLHurAN44EYRfs4fmtrvXXO1U3Tnfmcm_ruM40h0DGTDIKrGZlOLKm1D2z5ULspiLuJM7fHoWThdLqc4JTPJS2OBdDOg1A1_IiaZpkvUyQnXPhCCZHzQ',
      };
    case 'cv-hebrew':
      return {
        delete_account_1:
          'https://lh6.googleusercontent.com/8ygpq34oVHY9JcG7w3OrN7hThj3a7qutWYhUtZpDscHmIXS2FnPwUSpTYVucGivTiLjPFvB9eUqGzRixHZmRc8_Fy85nvZeCdJGFVF9e55AqmLWckVl-SGArcJKPKFNtZQX-TuAdhXklGtaHWg',
        delete_account_2:
          'https://lh5.googleusercontent.com/UYsDto4DmMtwk-KRvicL_qLZa3qU8uAZcEWJ2RrJT2QSTqXlSPk-lJo8XR3vP465YXOQ1aJCCGjzDxSovlVfoITnjTCL6Gzo1VBUaGe8qwyTzj-C1hiNB-CPYv472fHFKVGYTqYEYS7_wgeAWA',
        cancel_subscription:
          'https://lh6.googleusercontent.com/J7uvzrHF-xpAhNVXlbxaH3htp0-lYgIFVJ_SJbOE7vpCYSX3ub1-kswC-FmriHU9GyDZP7KV8VFYcsSfoI_XXKxw5BbNVkE6aPCz40KARIasNm-mTAJ3h3_DwAdqHoAZbQLFk4kimATcc0ow2g',
        change_resume_template_1:
          'https://lh4.googleusercontent.com/KC_8vLXeBKWqZ9pFoYjsrQrv6auZSRv-TRs0eNQuDrhztdaQCRElfV9Mqe82gC7iaDPcWElAhOy4klwdB1zCRh_BoxgsYXiZ9r316_ly8DO0KZDML2NA3BGNopqiLoYugQiOaVoI6hzwS_fwSw',
        change_resume_template_2:
          'https://lh5.googleusercontent.com/rJcpRwGBNgO4ATmUpCupCCbIFsK7TRAE12Rj-v_LeMgVJJQmDnP9mNqLQnT2lRVvDxoRp4ulRWfz80oG3mJ_w-0YWQNcOHx6c-HvfIoZrqKVbfzqZ7fa0EpW3MlHtBgJVGxXOHNrVmk3CONZnA',
        add_photo_1:
          'https://lh3.googleusercontent.com/PIoPuu-n_0R4lUMwsUbyDOYF0YpWGZkajtgn3zt02hf4zppVc4UGMcwM5Q4hzJFO3WQYLuYkVGiFSaEx45TRdBkb5wG0ISbIF6gAoh_AxVHItQMqaIDNfTf8K9m4VhNNYIfZesS3h28FujH1Cw',
        add_photo_2: '',
        duplicate_resume_1:
          'https://lh4.googleusercontent.com/zuZErJ9_uW7rft9HxVIkhtFRoNZmWUX5YqgdUHC2TN7HvDl84xgB-sBFvvaGdOzaHnicAdA00niTyGVzuJCpytdBlMnvvcWU6r8WltAKGBiophS8_jLqumLHgVXg3DqOChh2O6RVqQvOqK9U-w',
        duplicate_resume_2:
          'https://lh6.googleusercontent.com/ggWAFfTGGazsJiGXSmSOiOrPdHy7r2GgeLoIi-L7zkmlR0eErWz4DKv9jv-e9OddW9Fh9fgcf5RMh3-ML0WthrvBpgENZ8J2FyxDTzrv9Qmi-I3P-NU-3wLJRXrRBafE1mNp3kCFl544wG_cfg',
        duplicate_resume_3:
          'https://lh4.googleusercontent.com/t2saiXhHDlaASAsfa07H0kFSbYj_xOssCtWus0ZtQhGek2KrNqN6ztRpd58PaPUrfk_mNpi6TSAbl6zEHQAeSFPdg0tDwgSYF5EQZ4KemCriZUNaNrE1ATOwaK0UzhKaTPaIT21M58jo80P4Aw',
        access_cover_letter_1:
          'https://lh6.googleusercontent.com/buExAGRs92vcbrH13aFnka_9PldQyJIESFmuxA-OvNgOEmWteZFDqPobNRo9X8y1DzrYzct2xCVxkboE8En3Sxmb1a7UgHkjqgvWZ8NAtTM0xQ3l00Gc1WBPLymNjUcqQALbBw7ufGCSy7xz-w',
        access_cover_letter_2:
          'https://lh5.googleusercontent.com/q4T1y53O6dd6-6zo98toVHLey-beE-3WxpBPCtTCFX3a3NU1HhPm6dTVz0TxN6irphAyr_tzVjpPzD9xRihSiI5JnJLJUDIs5PX2kWttoTrXr_m-uK7eQeLYbb2lAlsnNmFQyMk7D9GFeglrnQ',
        word_format_1:
          'https://lh4.googleusercontent.com/YvOgjo2iVHm36rsSG7HnHVnVEbb2ypYnm_-YrXb60lJC33V3fC7W51iCv0upOPyN3ZIgQjZil6JmWNiv0303gogWTqhNuGizg4TorenyDEAffHq3iAQQ02d8MPHwWezLRTDmXmBK28ImoKnzJQ',
        word_format_2:
          'https://lh3.googleusercontent.com/omq93acDN8eMAKJtA_p2sVOUxnzvcW63lQp9G2DktxLtvFqwZbUinVu8o3qqdtYK4stlyvSqlwbZ-UY6_4epecGULyYaCrayYM8KBkR-S-FLfy1UpsuOC2EMAGGI-Yrnz_W2RipCEjS0Ayreiw',
        customize_resume_1:
          'https://lh4.googleusercontent.com/KC_8vLXeBKWqZ9pFoYjsrQrv6auZSRv-TRs0eNQuDrhztdaQCRElfV9Mqe82gC7iaDPcWElAhOy4klwdB1zCRh_BoxgsYXiZ9r316_ly8DO0KZDML2NA3BGNopqiLoYugQiOaVoI6hzwS_fwSw',
        customize_resume_2:
          'https://lh5.googleusercontent.com/rJcpRwGBNgO4ATmUpCupCCbIFsK7TRAE12Rj-v_LeMgVJJQmDnP9mNqLQnT2lRVvDxoRp4ulRWfz80oG3mJ_w-0YWQNcOHx6c-HvfIoZrqKVbfzqZ7fa0EpW3MlHtBgJVGxXOHNrVmk3CONZnA',
        customize_resume_3:
          'https://lh3.googleusercontent.com/dYq5n35FQenYqYrWChkt8NipEmoFWYI-dZfgmXnUBsjJhKvLKETZWFVAIsYBX3L9bISFHAGUKFNKqQ5gB2_nZYJFi3s5X3szBe9jLQYn1CKcTKCSzHe47WPg7VHqnpmIPkw8E6oytAe-d-Y21A',
        customize_resume_4:
          'https://lh4.googleusercontent.com/z1RK04tl8Jwi5D30mMeIswf9rR85ioT0tX-0aqQAhuUQSpVcNUjV6daQy9Q9yb6RNJXEUcj1Nl6P-i1Nrx5LeLHFJOCDZmuaVt9SEFHekq-dex52ud7w-F9Sppdj2xlvUfqtVJLX5QXZhrprcQ',
        customize_resume_5:
          'https://lh5.googleusercontent.com/rJcpRwGBNgO4ATmUpCupCCbIFsK7TRAE12Rj-v_LeMgVJJQmDnP9mNqLQnT2lRVvDxoRp4ulRWfz80oG3mJ_w-0YWQNcOHx6c-HvfIoZrqKVbfzqZ7fa0EpW3MlHtBgJVGxXOHNrVmk3CONZnA',
        change_language:
          'https://lh6.googleusercontent.com/Dd0QBnVRZiAryjXio5HKaUWV4KYxO4Xg3zzn37prPNHCwjJtyn3LTZqaxABMaRuZEjY3WcLPPkd7eucY9CkkbVHTZHfoP1llP8SWWqZtXEuvHlFQ5R_L9caKVEg3PzvIK0Ngfepl0ba2ix7dzw',
        change_font:
          'https://lh5.googleusercontent.com/rJcpRwGBNgO4ATmUpCupCCbIFsK7TRAE12Rj-v_LeMgVJJQmDnP9mNqLQnT2lRVvDxoRp4ulRWfz80oG3mJ_w-0YWQNcOHx6c-HvfIoZrqKVbfzqZ7fa0EpW3MlHtBgJVGxXOHNrVmk3CONZnA',
        change_section_block_1: '',
        change_section_block_2:
          'https://lh3.googleusercontent.com/eWGPINOGISgmzJwEuN3Q3OEhlEnaqq3VRZgOY0Mz_pPl6-da1ZmHxBlcImJiYb1EV_NUeG-Y6DfnRDhKJhtV_x-_3Gq4TyO26fc0g2zxNsrQXimxmQHiTtuFvSc5Dj0meYwHq2ozACTgIFBrdg',
        login_1:
          'https://lh4.googleusercontent.com/rxSfBuYXfCbfiptmMOe6pXV6N445S3xsmFZcVj5KcE-5NAODjqMyMaTMKbdLcUp9bvG4ARyyq0gapAEqEi3jRLjvoJtn4EyXOlGA7IQ6BOMs5gRCCcR3ph98EHGGnIVtk7y_CELixj-wYPmwnQ',
        login_2:
          'https://lh3.googleusercontent.com/mYSX8WIIpm9WgYyS4PJjpK0UDHhYl48mhQPq-gU_Kbr57siqkxJYOdzt_3joaKV1qsjpGj9XJrUWGhopeeHAOwvF__nNMEHAs3CCmY7GPfgev3ddeTplY7xpup4k8loRtYVw7Ug1O1TBYO1q_w',
        login_3:
          'https://lh5.googleusercontent.com/QL-2_xX2VJEnQUTAq4dE0luFcXf_grzNnX5ryAXnrkVc4p0sMFEzaIQZ3sH-cP3yvuhEQjGf1hXmdPrWxnKx1m1EtZ-YNFM3Em9riM2iZ-zS18oDNvW-uflKCe4tI2TgQhcxCPhqvabpb1MQxQ',
      };
    case 'contoh-resume':
      return {
        delete_account_1:
          'https://lh5.googleusercontent.com/nVlrSH5AkudAuLuJuTHTr5h088ZaijHbCn95XBQUOy9KJD-p8f2Mcy-pTtFcgFD9V_mQN6nC3lEnC0JlpDlihDvuEy0Hy789mc4FGd-RsksHHT1GJ5C2y6bNx52MUpXN6LoWRK24KIRbWG7Adg',
        delete_account_2:
          'https://lh3.googleusercontent.com/Gh57e8xxcpYJVPPhmA58wBAgOqksO6yhTTLoIfOPR1JyzihYuA-ZZgUHueiGgjMYpUzO66k1yG5St7t2WuQWf9_XOQvmOGqkq1UI9hPG8yBUHr-EyzCFUBL6Rm6oEwIpJP48XddixkvWnonnLg',
        cancel_subscription:
          'https://lh5.googleusercontent.com/9gox5vmVY0sj57CbT_oS6Qb7QTDr3CVtSp6QzUwrUAD9r4yHO-O6RIqm4x7luap-2G6FYvHht9U_7j8Z5X2H9T1irP8x1n8VcLkTPji7Sz8PHEbxEX74b4cqy4twhI_M-fGsWs3g6Qakz2Ek5Q',
        change_resume_template_1:
          'https://lh5.googleusercontent.com/mhLTFi2LrtU_xBHldnQox19GmPBKSY_DDmndLxhziNDOrHvpbehp1UAqJ-J2Yz5-rjpjjhXEdt7cShVdeEqYkyGSFalryRe45uIFq7Of2LXa_nlxciWJLGzWggDTfmC9x7epxwFp6-BD8IVsOg',
        change_resume_template_2:
          'https://lh3.googleusercontent.com/xMbnA6spba2Sn5WjUzEC3_iXfW-Dqe5Rgr0U4sAI8OXBh96IT5ALAe_qQ8rD_JnZOnhZfmznBBZJmdRNpUVL-3X5NpJmVs3Wxr7AuY-OLNLxyC4Fx2YnJwvttf4PxyLkBsUln4MiehVCpSERwQ',
        add_photo_1:
          'https://lh4.googleusercontent.com/T0BlP45NQhksVzFX2VZtb_39__fttoRwnRrtei7m7nmexcWHcrCebnSVzbgqvXu3XX-nShZDXiuVQoc-kD6FvVhzgQFcFQSlPBHLTEVj2WqD3A09JjRaKAWvn1JYddPHM8N8apx8qE08UEhwmw',
        add_photo_2: '',
        duplicate_resume_1:
          'https://lh6.googleusercontent.com/ircYLuaD-_KtMyfu54H12FgLNIgvO_9fxnoKQcoGGYWlSmb8aO1JRbK_3Kq9-tmwju3K4TcDITlKFR1L8zoMwMbIVLLn6YxYVWGmLBSDeAcEJqQTvs7G25Cn0DkKKOwzC7HiQfmwOk2VPn7a1A',
        duplicate_resume_2:
          'https://lh4.googleusercontent.com/rZ3yNgXZuIx8-paTlcAShzwVvr9UBNzG3lbv8bgpc565jCo0O_SYmH7rpmE6AC01MCmA9HMZP68MFckRQxmLK4EGdovYbCsZUbEeIqFPP6lRZDVmAwpx79Qzgr6e8P5s_Y_rhoDzpS9TlR5nvQ',
        duplicate_resume_3:
          'https://lh4.googleusercontent.com/Tg41jQxAB2xqpcLL4TKoGznvBR40jkEzb3Zx7FmJ-k09QwYKcnpn3hej01mwfyUXFrqO9PFzCKEo9ZAL_swdxkw2o2ZJB34g13J7k4XzBYnTUeRVfS9EG67P3VhdaUuuB8GrHPRxgis1XG0p2g',
        access_cover_letter_1:
          'https://lh4.googleusercontent.com/liBqWo5vy63QoGx4xUbTgzAc_SepdLn0jZHHmmvyL_3-FRj1FHPN_w6Pk9WTy0nH7wNzk3sNGf10I0F7qHPUDuOes6m9AXBA2N17BaubVBhtGN0eV_dckTfE9IKUOS5yWMbCl4QSlyTcTUbKhA',
        access_cover_letter_2:
          'https://lh3.googleusercontent.com/SZMiWdSQr2GVUII8uJBKQPN4WJNq0zBtEWL1h4W6i9U8Cg6zC4Gw6192va1uE727FIphQcRmFNDxI7adI8xVY6Wz6UPST5Dw6v1FptCYotLinwqoRKwk6oCVdNyRNSCyG9TuVnYk_Qmqsw6-jw',
        word_format_1:
          'https://lh3.googleusercontent.com/YlQJRhwNT4_APgm5t3DR73g4UZgfUTqXRJDxysVnj_WqGBinCSrvTj-JiPnRi31CJKuGfpNEJRDR88B761nuvxq-zTkAY7pjPTI56jX_DdVHreXtTutPrg3g0R5y4DFFfqaEC2_JtlBwKMIz-w',
        word_format_2:
          'https://lh3.googleusercontent.com/Mf0d2aK5y4dLv5LSbfs9p1kS8BdENRCqI76W6kFxVVY84Ns19g6SMQtx0Hnmgsu7-3FmIGOC6CYN0Ksgw0laf9OeSDiV2QEFDBpNmdCNFLiwvVSQrDUeMVJFFGW_hy8eEr63stUzOcLUAWWYxw',
        customize_resume_1:
          'https://lh5.googleusercontent.com/mhLTFi2LrtU_xBHldnQox19GmPBKSY_DDmndLxhziNDOrHvpbehp1UAqJ-J2Yz5-rjpjjhXEdt7cShVdeEqYkyGSFalryRe45uIFq7Of2LXa_nlxciWJLGzWggDTfmC9x7epxwFp6-BD8IVsOg',
        customize_resume_2:
          'https://lh3.googleusercontent.com/xMbnA6spba2Sn5WjUzEC3_iXfW-Dqe5Rgr0U4sAI8OXBh96IT5ALAe_qQ8rD_JnZOnhZfmznBBZJmdRNpUVL-3X5NpJmVs3Wxr7AuY-OLNLxyC4Fx2YnJwvttf4PxyLkBsUln4MiehVCpSERwQ',
        customize_resume_3:
          'https://lh3.googleusercontent.com/XQyM3-vz4zBdcKRkT10BT0RQYAQhkBQpeUll188KYWOaOb4ZAiwvLr70DhdGRb0DK6HVy-dWEYkC1u-JYvOKQQs7lRg413-uz267k-bBkclPBwcpP-fyirSCqJ8AvNP4BwDpur0o0xIQjRH55A',
        customize_resume_4:
          'https://lh3.googleusercontent.com/B_Zn0PNdx4ng_XJDT7e2QzjcxXaa_m8JMT8uf3lTO8zgiz9yufAqtmsILiofZd5CuS6Hn4xtEVz5GQVq6UEZ5jhsmRPpb5BNlmyUziwsdx32unAPhokViRUvgZGTPC1-6NPdV7joNAmYNoIkjw',
        customize_resume_5:
          'https://lh3.googleusercontent.com/xMbnA6spba2Sn5WjUzEC3_iXfW-Dqe5Rgr0U4sAI8OXBh96IT5ALAe_qQ8rD_JnZOnhZfmznBBZJmdRNpUVL-3X5NpJmVs3Wxr7AuY-OLNLxyC4Fx2YnJwvttf4PxyLkBsUln4MiehVCpSERwQ',
        change_language:
          'https://lh5.googleusercontent.com/JU067ajf1joURyRBWGQlF0ux4wqKCSiiP9U9V6WfN6WI8CJwEQmxzt4zV56r4xKNWjM57QD6V1ZCs0-UYmfKTegLAuEIx8_fnX4AGdiK2ldNUaLBYV-kP_9lpOHQbDT7EydvOA4KC4d3IymU9A',
        change_font:
          'https://lh3.googleusercontent.com/xMbnA6spba2Sn5WjUzEC3_iXfW-Dqe5Rgr0U4sAI8OXBh96IT5ALAe_qQ8rD_JnZOnhZfmznBBZJmdRNpUVL-3X5NpJmVs3Wxr7AuY-OLNLxyC4Fx2YnJwvttf4PxyLkBsUln4MiehVCpSERwQ',
        change_section_block_1: '',
        change_section_block_2:
          'https://lh6.googleusercontent.com/pt920sB_cRAaos_52_8vwD7-0e5dSc7DVjDBs34lU5665WA-eqxDDynJZ2Tv1D1IOWQV7q_UdXlGk0gn8IhzWV4hXzGELsBD4cbIWLMxBzJanBw8Ir8Pk-7c6ds2ikFrVJSzIkhqu-WoLqK5UQ',
        login_1:
          'https://lh4.googleusercontent.com/L74Q8zQ300jYR_87eIO0196-Vn8JTGfPYX2gGAYyFDDfvKeaPboxQdZnc5rIg0MV-dqcpZb4Fn2DAK1BjjYzU12prm9Gp6wmcFVhfjfmQsHnXb4Isyyy6TSCHdHGSlfMiJjmJ7vShd8Qj_cyIw',
        login_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/62b157fda713c738b2978383/file-MEFtoE63gk.png',
        login_3:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/62b155bfa713c738b297837d/file-s0Ah8vIDC8.png',
      };
    case 'cv-in-arabic':
      return {
        delete_account_1:
          'https://lh3.googleusercontent.com/MFqdSehkBIfffdE9EQ0MgwkxJnW4-uFFpvSsgiNe2o4g3nmmNkES7Ahjt1zx_r8Zn45ys4VpSa4eRyEcPFxwu25qEjLkqZZwzv3UhfDnycrvFxwkqR5POv3QVutQ2J33IuRyN0qO',
        delete_account_2:
          'https://lh5.googleusercontent.com/GCrY7M-5fn3yAVxGX4zCKHhnGK9FdJRPESxcbyO7LpBadLIPkerNA_R5eGjNarCvN1Blxwaoypbl8nULcPoio36qz1pBgyujgf4qYxudToZVpiwyQw741gZEU4OIB6Ogn-mgq6B4',
        cancel_subscription:
          'https://lh3.googleusercontent.com/i1VbNCmwwNo9SnL1-s5s1O1SsKABDgcQSuQE-jzPkIbtHInYAwDPylCkVTDWYnY_5l6ezD28tATYLVRnhQifN5D5zti1KY-FEPoZI4EoMHBJ3QniUFvxo4b-Xu-SKP6pE12ADiOJ',
        change_resume_template_1:
          'https://lh5.googleusercontent.com/Ur0f3Sm3cE_aHMvpK5w2nCAhwDyCnx4oli7BjG3OKG2xraOPezuXKIQOOA0l531WIUPFT8yaqt4gQ592TB6S9LYnL92Ihv1Nqn6w54m5B4iddWy6KLZwv96oDzq_tzhE52tTjgn3',
        change_resume_template_2:
          'https://lh5.googleusercontent.com/FrTZl3XnZyrcIfeWEOiVBPGNUid1HLapHrkMcAGs4-IF38wXl2ZhLHGXPOlFd8h6Ww_vuQ-AGLm2fGhF2YsGWQHuc3HVqNZ2rkxyaoAhqjXblzH0eGscsFu_GeCP_lP_glq9Kaxp',
        add_photo_1:
          'https://lh3.googleusercontent.com/llQ8gB29Lp8yuf0vqOFFnF6av2IDsoRH9dG3Cj3qwVN0TXANLu_2gPyc34oI6UCsZgNWfm8loGkE3Nzd9tzd3Np9AQRXHY1GLBT7C1G8ZCFK-GjGKOadaQi32OuKo2yugnvxMuLH',
        add_photo_2: '',
        duplicate_resume_1:
          'https://lh3.googleusercontent.com/b8ZU_CKEA0O9oWbtjIZ_3zJh480Ua9WMtlXQL4A-Y_GBeXVBogQxBN0ux_0Hka3ssAzpBLmznrNycG2rt8qqbZefwyFMI5UkTedf8GqZ8kJrMIgE2F_6hHRsIeSpaV56PJ5qgViU',
        duplicate_resume_2:
          'https://lh4.googleusercontent.com/pioNeXmjtbJY2IkqqOq3dIF5Xnx6aal0H4r-MbtVZ2nNJ17nHYPiXy4cz8xu4tGrS9STvdTot9aU88Jd38OPD39PsxncT-nSqPmBFxFOkGEeeB6-zfmgXcNQNORD6DRMKnmw9QsC',
        duplicate_resume_3:
          'https://lh4.googleusercontent.com/V-0Bd4rnmQwKd12sgPZ8h7SmZIcu-YqZxtswrphD-R7ZM0Iy4fs4S61nxk67KR8KKvASgOwsdE_qH1U33BVRrVh9VNE-x5N1Seugzsj0MrobImO8fnvHxzm8KqSS0XullaXcspK1',
        access_cover_letter_1: '',
        access_cover_letter_2:
          'https://lh5.googleusercontent.com/MB5PPAq2f7pCVES__wePYKJom08JiM24oFqlBop3W0ZmlRiMYtcq51T17bwrrx7hbPL9rwp_sr8v5jUAzj2WERwroU90_DaL8t_G5uv5I-2qZ7q1q4g_BJ7V9qAsa0TDB1M8y-7f',
        word_format_1:
          'https://lh5.googleusercontent.com/9e9HO-iIja3LcFKeZRBNu8RizhSHhlkK26giLKT0JW2IVPsbHDt7HizpVgtjleiqGmYmAhfasmN7b_qZXVnrNZ3LqBgjWNHo1zhrfptSjimDay0DBg7jP6XqT7U-420mjMY5jORI',
        word_format_2:
          'https://lh5.googleusercontent.com/l6J20gGCPYeIaBnwnb7Itc-KJrOfrrRVfrWXpVW2Qq9QD4fAVWgDD4zkBvBmDRfTlPxDtCwnDaObzEaNVUn6117TtolN2QyKEJTNFIupBZGhMkD-aEq8aO3YlRz2h_rkBiUIXMa6',
        customize_resume_1:
          'https://lh5.googleusercontent.com/Ur0f3Sm3cE_aHMvpK5w2nCAhwDyCnx4oli7BjG3OKG2xraOPezuXKIQOOA0l531WIUPFT8yaqt4gQ592TB6S9LYnL92Ihv1Nqn6w54m5B4iddWy6KLZwv96oDzq_tzhE52tTjgn3',
        customize_resume_2:
          'https://lh5.googleusercontent.com/FrTZl3XnZyrcIfeWEOiVBPGNUid1HLapHrkMcAGs4-IF38wXl2ZhLHGXPOlFd8h6Ww_vuQ-AGLm2fGhF2YsGWQHuc3HVqNZ2rkxyaoAhqjXblzH0eGscsFu_GeCP_lP_glq9Kaxp',
        customize_resume_3:
          'https://lh6.googleusercontent.com/PAgIhsJQolnndCMR6sLI8BoGiBmzkSBJogVyL-XE8BGSjrQ4rjvjCKtl2I7tG654ZAAoPc2_lXs-2tiG3Coy5gQpgxJIy2Iirw27-EdmiT2PCerys9k8GkzkPR6yaOACHHsDPETs',
        customize_resume_4:
          'https://lh4.googleusercontent.com/Y4y9BEXAM_4lAjBs9B3JHuicEWUU_O3IJxbDThw1roVgV2pucRDtjRYNpaR-9boGMDoZfcjqZbE25EZsmN6qYk3N6Sl513FRH4WmujDEb04KDf_u_y_3K4gw48-DtnMXfd86Evpx',
        customize_resume_5:
          'https://lh5.googleusercontent.com/FrTZl3XnZyrcIfeWEOiVBPGNUid1HLapHrkMcAGs4-IF38wXl2ZhLHGXPOlFd8h6Ww_vuQ-AGLm2fGhF2YsGWQHuc3HVqNZ2rkxyaoAhqjXblzH0eGscsFu_GeCP_lP_glq9Kaxp',
        change_language:
          'https://lh3.googleusercontent.com/efRHg2nDVUOC9Vlnz-sdXsxkz3spUI269v5dOfH6Iozu6NWaZc62BpDgQ4D9CNR68F50iKbpG9wQlJKuW10j4aZkUc8E5UnSnFlNfiF9gtkjHmUwcANh6FHVD4s9QKu4UiS8V3jn',
        change_font:
          'https://lh5.googleusercontent.com/FrTZl3XnZyrcIfeWEOiVBPGNUid1HLapHrkMcAGs4-IF38wXl2ZhLHGXPOlFd8h6Ww_vuQ-AGLm2fGhF2YsGWQHuc3HVqNZ2rkxyaoAhqjXblzH0eGscsFu_GeCP_lP_glq9Kaxp',
        change_section_block_1: '',
        change_section_block_2:
          'https://lh5.googleusercontent.com/-CAMEwUFGn_c6Om1jx3P46kQMoTgszEu1TnWsINahkwLt76EapXgN_oI5PA9znZ_WTLB6IL1hxxMgqCyydcH16UL7yRf0Bv-NcV062mrXNnB3TPqYDhi2u9DSl0-SSEGZ_0nsudm',
        login_1:
          'https://lh3.googleusercontent.com/lxotHwktQRjwIriyHLtiwUWl-fCWgPw4PRSLyxkA0A121OA8lsEZsOudXYN1Ht7hzsrls22zjUxN5l58jDuqmwPqWp-1kWJv0QAs5npadZabOoEKM4dsHBD9fOZGPLiFhlcMr7hR',
        login_2:
          'https://lh6.googleusercontent.com/1EikBisxJvcs9Ui4-cdobWumK0JeKohKGfSlFrT19m_KFh7oENeaZ6l3-ESn5gvj8Y6vume5WDr3tnG2lv0kYoGXHPkUo3hLPSg3fkGrHMMBGRkKacIDnoLu4yyIh35vqHYJOk9K',
        login_3:
          'https://lh6.googleusercontent.com/qL3UIP7YK_XT9UVs7yoqGWb-34-4mciSlKWaQnG4_Xawfiiww2qZCLuVrFtRpMjZjCaXaTvlU_Ee5SRbnPUGPPl8EP0Rl1MDRrchWjH2AzKdIesejU9vuunRoJLd9VrPycAHGLcz',
      };
    case 'cv-lite':
      return {
        delete_account_1:
          'https://lh6.googleusercontent.com/PXWP-FfZj9BzVJI06jPqVaBI1Yd0z7Rc0Rwq1EDMRoJSFU-nTRepEx7skvqOz2MMVnfX-b-mHFQmjChD12SWsMj2XAIuKC0pOOc70CHJL63pS8MvjUOFGZC34xZ2ZHg9-4mNW8lJV-Fw-x2X1gd0qm1c00td9H6AlDbyymBEXav-gQvo0usnUBZ3CofGRw',
        delete_account_2:
          'https://lh6.googleusercontent.com/ugaVEm4Z6DCIr9p4kX0nsobKysABgWcVtPB1kKij6e4-iWl_x67RFyZ831DhzXf6yYGHBe14tllXUZvfpc3xbHeHCiBnvn84-Rzx2AZvY9y0r6bL1eaAOWrmFRTvqvrlkO1iWXrJto8-tUY-MgmycImmFfBLAe6gP8u3wPpLpk2XFD-XsQnxVHQ5_K5Agg',
        cancel_subscription:
          'https://lh4.googleusercontent.com/nWNxTLMD6WEY7fBJwDMR-PJBetqJw_bXFG8iyVEmOgQwVOgnVFqrOferxEjbZCJg4eFqEWGktgVoc6wRa48cd7H0zlGCgstbRkTI5TKAwJosXJkZ9zJ7p9QT-3Bz8XJRFfUP-Ce8UYHjx1HiN4ikDdZ1JXE-dA-7NeR0pR9WN5WGAmk86xegG1DiBU9QdQ',
        change_resume_template_1:
          'https://lh3.googleusercontent.com/0LtR_sKf8eSySRqBcO8sQK4RWpr1QKUUETElTSYuYHrNEGzNWjKwOGlAlDfBqR50sRnnq6L0NGHMcDXyStN-zOj96O9lyNe69VMpsfjy5Ats4fyihhHKqM3cqUDaHnDodSqD9zDAffGQeAJYe1AHT8zvrFek7xz13u0_6CEDMtpy8vmsN_WGZLeYbb4rGw',
        change_resume_template_2:
          'https://lh5.googleusercontent.com/95XhjeTeAuxQ9XSoGkbIO_wxj5xo5S0htWED2susjeFD49WUm42AeT8771DlCv_-wbWq4WjrRUHLCW2C_2prMVq2j6mGeeyDja2MqLBJd3-0t3Con76Ke7H_RXQ7KblDGusERR27BRbZJbaAUhtVnSNnqy-DBPIU5ojnMSyPxE7hpFcB0MFkQ76gJ6cTvA',
        add_photo_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/63344cc7cf38bc37aecf445d/file-r2wzQkSO0z.png',
        add_photo_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a851204286306f8075614/file-QpJ9tBw46F.png',
        duplicate_resume_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a83202c7d3a10cbab3da7/file-KLNCflNQw1.png',
        duplicate_resume_2:
          'https://lh4.googleusercontent.com/DII_v-fjwn-ezOVaRA9IOejGzGOd4LDSV2_RGlAzILt7A2k8C5hFPS9pmSnbmlwRaVSgjQwb28GlKuaS2xi9d2YpfePA87Vmf7AcTCDW2U631XQ8NZErAwwu2sM5A9fPPHHtGP4rZYQITLf8O6xk5Nbyl-fc6S8OU3UT4_CSka-3byr3PVOFxby-Sg',
        duplicate_resume_3:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a841f2c7d3a10cbab3dae/file-qKRiKSknVe.png',
        access_cover_letter_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a823e04286306f8075603/file-PWC2TS8mRN.png',
        access_cover_letter_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a82492c7d3a10cbab3da3/file-yHCUVUbVGt.png',
        word_format_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a802e04286306f80755fc/file-FIbbSHvfsQ.png',
        word_format_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a80f52c7d3a10cbab3d9c/file-hJjRC3p7qh.png',
        customize_resume_1:
          'https://lh3.googleusercontent.com/KqHn-MBLjZID6mD69-Zr6D4MU0LhQcz8VLCGUZgwCD_ehFlGSHFziBCEEKlF1qsXvvDOb_VA1qcTeNCBhD5iek9D7vUOtpKVvNBlJWySuHKyuWjB58yLt0_9OU8A9QdfpFHAueQwTnT3IBUwtSBk1ChVurHkXAaZAFuHPj-jR1OLcSFd3_nXJdNfbvMHZQ',
        customize_resume_2:
          'https://lh5.googleusercontent.com/95XhjeTeAuxQ9XSoGkbIO_wxj5xo5S0htWED2susjeFD49WUm42AeT8771DlCv_-wbWq4WjrRUHLCW2C_2prMVq2j6mGeeyDja2MqLBJd3-0t3Con76Ke7H_RXQ7KblDGusERR27BRbZJbaAUhtVnSNnqy-DBPIU5ojnMSyPxE7hpFcB0MFkQ76gJ6cTvA',
        customize_resume_3:
          'https://lh5.googleusercontent.com/l8iE5ahUmyt3r503vqnwDcXlEiWehbJ8fchP8WwfzbGlctXUQ8afbkJyQmacmTG87dl41XeEHdMXwykngrezWtntWP6awcck4t6GqDcS4cLM2AwrAYcOb88nYauMEsiqz28rC1pdZVW_zmYEa6Wl7FwN1MR_XKHdt296IApASXzk7NhgqTii6lKs_A',
        customize_resume_4:
          'https://lh3.googleusercontent.com/J5gwQw17tYcQLSVya91cRibwPf4I9o2Ba1CTRQRsIon0BI7Jd1mr_RoAPnAyIHV_ggr6inzwI_fUKLOkgWVZjRRy6Src8uvVVpmls0uok-Uiczlxof6LFDuIQIULi9UmhuysdZmA7dLqGECUjy8SI9ji-ADmXxrHDW58uQYd-5i-BEVh5KRXBKU9ng',
        customize_resume_5:
          'https://lh3.googleusercontent.com/52r4JQMKWxf3e1kRSXgkyY3Ww10QRI8PHD9hkB0L1_Hb0fHEdmMKii0DOTStn9QsRj4iNscGXr-PzMEwOrFtyDT8b6NwIbcd3ydKrx14WKKO3qLXTw4Yt-EEwFf-fKYEdzR6Ne3AByzRvloFwBcHTwn7n8g9RuXSfo-MPKKw8sVui7HjQ8E0OfwMBQ',
        change_language:
          'https://lh5.googleusercontent.com/95XhjeTeAuxQ9XSoGkbIO_wxj5xo5S0htWED2susjeFD49WUm42AeT8771DlCv_-wbWq4WjrRUHLCW2C_2prMVq2j6mGeeyDja2MqLBJd3-0t3Con76Ke7H_RXQ7KblDGusERR27BRbZJbaAUhtVnSNnqy-DBPIU5ojnMSyPxE7hpFcB0MFkQ76gJ6cTvA',
        change_font:
          'https://lh3.googleusercontent.com/52r4JQMKWxf3e1kRSXgkyY3Ww10QRI8PHD9hkB0L1_Hb0fHEdmMKii0DOTStn9QsRj4iNscGXr-PzMEwOrFtyDT8b6NwIbcd3ydKrx14WKKO3qLXTw4Yt-EEwFf-fKYEdzR6Ne3AByzRvloFwBcHTwn7n8g9RuXSfo-MPKKw8sVui7HjQ8E0OfwMBQ',
        change_section_block_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a7f0504286306f80755f6/file-UB3JL9RTpB.png',
        change_section_block_2:
          'https://lh4.googleusercontent.com/IJlTBmehQwwTGBMIod5ERX4EjrvtcMpidWGDjm89AivJN8TziEhI_8eJxnfwFnc8pARDdbIBuUGVILcQSL_eSHj4vCk_N903agUubLThvXZnWse15fvead5yt68LmLx_ovMetw_YIQkrglVCAw6RlNaEFxS38PGiZA89PS_jQR8V_qHcqkXpCthoGN9R9g',
        login_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a8a5704286306f807564d/file-t9A8hzOn0A.png',
        login_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a8a6104286306f807564e/file-UQhVYa8b1Q.png',
        login_3:
          'https://lh6.googleusercontent.com/-KQnUqobxJFYtG4RDXgVIZZp6bJvAus6QyaPVFYdDQk1tMWKv9yzzAUX_Qy8fT5pgawmve1Y4vb6sD7SXP_632-hZek7ZrKNOPLykWMvkLXDe8D596m5nPl815XLHTcWcQqHk2Nwy2D3PExCVDXMa0O7pk3n8gz40qr3xR8wILGCCsVzrnlegRvX5rzM_w',
      };
    default:
      return {
        delete_account_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a886e2c7d3a10cbab3dd4/file-d9l0hoBeOr.png',
        delete_account_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a89b32c7d3a10cbab3dec/file-EtimCdxtTA.png',
        cancel_subscription:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5fb4eeac46e0fb0017fcd093/file-jKPNWABgbf.png',
        change_resume_template_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a7ce804286306f80755ed/file-pkZX9k1CJ1.png',
        change_resume_template_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a78352c7d3a10cbab3d7d/file-GEnKgudlZF.png',
        add_photo_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a85b304286306f8075617/file-Iibe0vJ9Jb.png',
        add_photo_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a851204286306f8075614/file-QpJ9tBw46F.png',
        duplicate_resume_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a83202c7d3a10cbab3da7/file-KLNCflNQw1.png',
        duplicate_resume_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a837a2c7d3a10cbab3dab/file-HgIYruHPcx.png',
        duplicate_resume_3:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a841f2c7d3a10cbab3dae/file-qKRiKSknVe.png',
        access_cover_letter_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a823e04286306f8075603/file-PWC2TS8mRN.png',
        access_cover_letter_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a82492c7d3a10cbab3da3/file-yHCUVUbVGt.png',
        word_format_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a802e04286306f80755fc/file-FIbbSHvfsQ.png',
        word_format_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a80f52c7d3a10cbab3d9c/file-hJjRC3p7qh.png',
        customize_resume_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a7ce804286306f80755ed/file-pkZX9k1CJ1.png',
        customize_resume_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a78352c7d3a10cbab3d7d/file-GEnKgudlZF.png',
        customize_resume_3:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a7e6704286306f80755f4/file-tEVtpNIbtY.png',
        customize_resume_4:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a7f0504286306f80755f6/file-UB3JL9RTpB.png',
        customize_resume_5:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5fdc424a27288b7f895d6ba4/file-LMXnEJzW16.png',
        change_language:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a78352c7d3a10cbab3d7d/file-GEnKgudlZF.png',
        change_font:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5fdc424a27288b7f895d6ba4/file-LMXnEJzW16.png',
        change_section_block_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a7f0504286306f80755f6/file-UB3JL9RTpB.png',
        change_section_block_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5fe97010f24ccf588e3feb2b/file-dNsJ27fhaN.gif',
        login_1:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a8a5704286306f807564d/file-t9A8hzOn0A.png',
        login_2:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a8a6104286306f807564e/file-UQhVYa8b1Q.png',
        login_3:
          'https://d33v4339jhl8k0.cloudfront.net/docs/assets/5f0ac4d404286306f8068e19/images/5f1a8ab32c7d3a10cbab3df8/file-wVaiE2J5Uc.png',
      };
  }
};
