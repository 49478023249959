import PropTypes from 'prop-types';
import { Fragment } from 'react';

const IconsExperiment = ({ iconV3 }) => {
  return <Fragment>{iconV3}</Fragment>;
};
IconsExperiment.propTypes = {
  iconControl: PropTypes.node,
  iconV1: PropTypes.node,
  iconV3: PropTypes.node,
};

export default IconsExperiment;
