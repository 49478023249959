export default {
  fairPink: {
    copy: '#303237',
    right: '#d1d3d4',
    main: '#f2e1d9',
  },
  lightGrey: {
    copy: '#5b5b5b',
    right: '#f3f3f3',
    main: '#d6d6d6',
  },
  charcoal: {
    copy: '#484848',
    right: '#eae9e6',
    main: '#484848',
  },
  sherpaBlue: {
    copy: '#065154',
    right: '#e4ebec',
    main: '#065154',
  },
  frenchPass: {
    copy: '#2a526b',
    right: '#dde9e9',
    main: '#b0d1d7',
  },
};
