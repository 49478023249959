import styled from 'styled-components';

const CardHeadText = styled.div`
  margin-bottom: 8px;
  color: #14141f;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
`;
export default CardHeadText;
