import React from 'react';
import styled, { css } from 'styled-components';

import View from '/imports/pdf/core/ui/atoms/View';

const StyledItemContainer = styled(View)`
  display: block;
  position: relative;
  ${({ theme: { convertPx, isRTL }, left }) => css`
    padding-left: ${convertPx(13.5)};
    ${left &&
    css`
      padding-left: 0;
      .decoration-range {
        display: none;
      }
    `}

    ${!left &&
    css`
      ${isRTL &&
      css`
        padding-left: 0;
        padding-right: ${convertPx(13.5)};
      `}
    `}
  `}
`;

const Decoration = styled(View)`
  position: absolute;
  ${({ theme, theme: { convertPx, isRTL, setGenderStyle, color } }) => css`
    width: ${convertPx(9)};
    height: ${convertPx(9)};
    border-radius: ${convertPx(5)};
    left: ${convertPx(-5)};
    z-index: 10;
    ${isRTL &&
    css`
      right: ${convertPx(-5)};
      left: 0;
    `}
    background: #414142;
    ${theme.accentTitleColors[color] &&
    css`
      background: ${theme.accentTitleColors[color]} !important;
      ${color === 'budapestGirlBlue' &&
      css`
        background: ${theme.accentTitleColors[color]};
      `}
    `}
    top: ${convertPx(5)};
  `}
`;

const StyledContainer = ({ children, left }) => (
  <StyledItemContainer left={left}>
    {children}
    <Decoration className="decoration-range" />
  </StyledItemContainer>
);

export default StyledContainer;
