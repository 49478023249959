import styled from 'styled-components';

import Flex from '/imports/core/ui/atoms/Flex';

const Container = styled(Flex)`
  max-width: 800px;
  padding: 0;
  margin: 0 auto;
  flex-direction: column;
  width: 100%;
`;

export default Container;
