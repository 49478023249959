import styled, { css } from 'styled-components';

import { cdnFile } from '/lib/helpers';

const RightImageMain = styled.div`
  &:hover {
    transform: scale(1.05);
    ${({ theme: { isRTL } }) =>
      isRTL &&
      `
        transform: scale(-1.05,1.05);
      `}
  }
  width: 45vw;
  max-width: 517px;
  background-position-x: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0 20px 100px 0 rgba(23, 31, 38, 0.2);
  -ms-transform: scale(1);
  transform: scale(1);
  transition: transform 0.2s;
  background-image: url(${cdnFile('landing/v2/budapest_exp_2_compressed.jpg')});
  ${({ theme }) =>
    theme.min('sm')`
    width: 32.3125vw;
  `};
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      transform: scale(-1, 1);
    `};
  ${({ $path }) =>
    $path &&
    css`
      background-image: url(${$path});
      border-radius: 10px;
      border: 1px solid #f0f0f0;
      cursor: pointer;
    `}
`;

export default RightImageMain;
