import React, { useEffect, useState } from 'react';
import useIntl from 'imports/core/api/useIntl';
import styled, { css } from 'styled-components';

import BasicSectionWrapper from '/imports/homepageV3/ui/atoms/BasicSectionWrapper';
import Flex from '/imports/core/ui/atoms/Flex';
import Link from '/components/Link';
import LocaleSelect from '/imports/core/ui/components/LocaleSelect';
import LoginIcon from '/imports/homepageV2/ui/atmos/LoginIcon';
import Logo from '/imports/homepageV3/ui/atoms/svg/Logo';
import useWindowSize from '/imports/core/api/useWindowSize';

const Header = ({ userIdentified, onClickLandingCTA }) => {
  const { width } = useWindowSize();
  const { t } = useIntl();
  return (
    <NavigationWrapper>
      <Section>
        <Container>
          <StyledFlex $justifyContent="space-between" $alignItems="flex-start" $fullWidth>
            <Flex $alignItems="center">
              <Logo sm={width < 479} />
              <LogoText>{'ResumizeMe'}</LogoText>
            </Flex>
            <LinkSection>
              <LocaleWrapper>
                <LocaleSelect />
              </LocaleWrapper>
              {!userIdentified && (
                <Link href="/login" passHref>
                  <LoginLink>{t('landing.login')}</LoginLink>
                </Link>
              )}
              <GetStartedLink onClick={onClickLandingCTA}>{t('resume_nation_landing_asset_1')}</GetStartedLink>
              {!userIdentified && (
                <LoginWrapper $alignItems="center" $justifyContent="center">
                  <Link href="/login" passHref>
                    <div>
                      <LoginIcon />
                    </div>
                  </Link>
                </LoginWrapper>
              )}
            </LinkSection>
          </StyledFlex>
        </Container>
      </Section>
    </NavigationWrapper>
  );
};

const LoginWrapper = styled(Flex)`
  width: 40px;
  height: 40px;
  display: none;
  margin-top: 7px;
  @media (max-width: 479px) {
    display: flex;
  }
`;

const NavigationWrapper = styled(BasicSectionWrapper)`
  display: block;
`;
const Section = styled.div`
  height: 88px;
  display: block;
  padding: 24px 32px;
  @media (max-width: 479px) {
    height: 80px;
    padding: 24px 24px;
    align-items: center;
  }
`;
const Container = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  padding: 0;
  @media (max-width: 479px) {
    height: 32px;
  }
`;
const StyledFlex = styled(Flex)`
  ${({ theme }) => theme.max('md')`
    padding: 0;
    align-items: center;
  `}
`;
const LogoText = styled.div`
  padding: 0;
  font-family: ${({ theme }) => theme.font.family.websiteBold};
  font-size: 20px;
  line-height: 1.09;
  letter-spacing: -0.44px;
  color: var(--text);
  margin-left: 12px;
  text-transform: capitalize;
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      margin-left: 0;
      margin-right: 12px;
    `}
  ${({ theme }) => theme.max('md')`
       display: none;
    `}
`;
const LinkSection = styled.div`
  padding: 0;
  height: 40px;
  display: flex;
  align-items: center;
  gap: 24px;
  @media (max-width: 479px) {
    height: 32px;
    gap: 0px;
  }
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      left: 80px;
      right: unset;
    `}
`;
const LoginLink = styled.div`
  max-width: 100%;
  display: inline-block;
  color: #0050c7;
  text-decoration: none;
  @media (max-width: 479px) {
    display: none;
  }
`;
const GetStartedLink = styled.a`
  cursor: pointer;
  max-width: 100%;
  display: flex;
  max-width: 183px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  grid-column-gap: 16px;
  border-radius: 8px;
  background-color: #f90;
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  text-decoration: none;
  &:hover {
    background-color: #fa8900 !important;
  }
  &:active {
    background-color: #ea7e00 !important;
  }
  @media (max-width: 479px) {
    display: none;
  }
`;
const LocaleWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 24px;
  @media (max-width: 767px) {
    margin-right: 12px;
  }
`;
export default Header;
