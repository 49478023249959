const InfoIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path
      fill="#0087C1"
      fillRule="evenodd"
      d="M12 1c6.075 0 11 4.925 11 11s-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1zm0 2c-4.97 0-9 4.03-9 9s4.03 9 9 9 9-4.03 9-9-4.03-9-9-9zm1.004 6.998v4h.999v2h-4v-2h1v-2h-1v-2h3zm-1.004-3c.553 0 1 .448 1 1 0 .553-.447 1-1 1-.552 0-1-.447-1-1 0-.552.448-1 1-1z"
    />
  </svg>
);

export default InfoIcon;
