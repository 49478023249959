import React, { useContext } from 'react';
import styled from 'styled-components';

import env from '/env';
import HamburgerMenu from '/imports/homepage/ui/components/HamburgerMenu';
import { headerLinks } from '/imports/homepage/api/constants';
import Link from '/components/Link';
import LocaleSelect from '/imports/core/ui/components/LocaleSelect';
import Logo from '/imports/core/ui/components/Logo';
import useIntl from '/imports/core/api/useIntl';
import { useResponsive } from '/imports/core/api/responsiveContext';

const Header = ({ userIdentified, breakpoint }) => {
  const { t } = useIntl();
  const { host } = useResponsive();
  const loginLink = (
    <HeaderLink $domain={host}>
      <Link href="/login" passHref>
        {t('landing.login')}
      </Link>
    </HeaderLink>
  );

  const getMenu = () => {
    switch (host) {
      case 'cvdeboss':
        return (
          <MenuWrapper>
            {breakpoint !== 'xs' && !userIdentified && loginLink}
            <HamburgerMenu>
              <LinksWrapper>
                {breakpoint === 'xs' && !userIdentified && loginLink}
                {headerLinks['cvdeboss']?.map((item, index) => (
                  <HeaderLink key={index}>
                    <a href={item.href}>{item.text}</a>
                  </HeaderLink>
                ))}
              </LinksWrapper>
            </HamburgerMenu>
          </MenuWrapper>
        );
      default:
        return (
          <MenuWrapper>
            <LocaleWrapper>
              <LocaleSelect />
            </LocaleWrapper>
            {!userIdentified && loginLink}
          </MenuWrapper>
        );
    }
  };

  return (
    <HeaderWrapper>
      <Logo path="/" width={130} height={22} />
      {getMenu()}
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 25px 20px 45px;
  margin-bottom: 30px;

  ${({ theme }) =>
    theme.min('sm')`
    padding: 30px 40px;
    `}
  ${({ theme }) =>
    theme.max('sm') &&
    `
  & > svg {
      width: 130px;
    }`}
  ${({ theme }) =>
    theme.max('lg')`
      margin-bottom: 0;
  `};
`;

const HeaderLink = styled.p`
  font-size: 15px;
  font-family: ${({ theme }) => theme.font.family.websiteBold};
  margin: 6px 12px 5px 12px;
  flex-shrink: 0;
  white-space: nowrap;

  a {
    border-bottom: solid 2px transparent;
    color: ${(p) => p.theme.colors.blueLighter};
    &:hover {
      border-bottom: solid 2px rgba(34, 119, 185, 0.3);
    }
  }

  ${({ theme }) =>
    theme.max('lg')`
      margin: 6px 0;
  `};

  ${({ theme, $domain }) =>
    $domain !== 'mysmartcv' &&
    $domain !== 'resumedone' &&
    theme.max('sm')`
    margin: 10px;
    font-size: 20px;
  `};
`;

const LinksWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  flex-wrap: wrap;
`;

const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const LocaleWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 25px;
`;

export default Header;
