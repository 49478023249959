import React from 'react';

const QuestionIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path
      fill="#9265D4"
      fillRule="evenodd"
      d="M12 2c5.523 0 10 4.477 10 10a9.959 9.959 0 01-1.258 4.86l.353 4.235-4.236-.353A9.959 9.959 0 0112 22C6.477 22 2 17.523 2 12S6.477 2 12 2zm0 2a8 8 0 104.115 14.862l.277-.166 2.513.21-.21-2.514.167-.277A8 8 0 0012 4zm0 10.998a1 1 0 110 2 1 1 0 010-2zM12 7a3 3 0 011.605 5.535c-.253.16-.284.181-.385.26-.164.13-.22.213-.22.205v1h-2v-1c0-.737.383-1.3.977-1.771.18-.142.233-.178.557-.383A1 1 0 1011 10H9a3 3 0 013-3z"
    />
  </svg>
);

export default QuestionIcon;
