import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Icon from '/imports/core/ui/atoms/ValidatedInputIcon';
import { inputStyle } from '/imports/core/ui/mixins';
import { isJobTrackEnable } from '/lib/helpers';
import useIntl from '/imports/core/api/useIntl';

function InputControlled({
  value,
  hideIcon,
  disabled,
  error,
  onChange,
  onBlur,
  onFocus,
  valid,
  placeholder,
  placeholderSlug,
  ...props
}) {
  const { t } = useIntl();

  const handleChange = (e) => onChange(e);
  const handleBlur = (e) => onBlur(e);
  const handleFocus = (e) => onFocus(e);

  const placeholderText = placeholderSlug && t(placeholderSlug) ? t(placeholderSlug) : placeholder;
  return (
    <Wrap>
      <Input
        {...props}
        placeholder={placeholderText}
        disabled={disabled}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
      <StyledIcon error={error} empty={!valid} hide={hideIcon} isJobTrack={isJobTrackEnable()} />
      {error && <Error>{error}</Error>}
    </Wrap>
  );
}

InputControlled.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  hideIcon: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  valid: PropTypes.bool,
  placeholder: PropTypes.string,
  placeholderSlug: PropTypes.string,
};

InputControlled.defaultProps = {
  value: '',
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  disabled: false,
  valid: false,
};

const Wrap = styled.div`
  position: relative;
  border-radius: ${(p) => p.theme.general.borderRadius};
  overflow: hidden;
  width: 100%;
`;

const Input = styled.input`
  ${inputStyle}
`;

const StyledIcon = styled(Icon)`
  ${(props) =>
    props.error &&
    css`
      transform: translateY(-17px);
    `}
`;

const Error = styled.small`
  color: red;
`;

export default InputControlled;
