import React, { useContext, createContext, useReducer } from 'react';

import Reducer from '/imports/job-tracking/api/reducers/general.reducer';

const GeneralContext = createContext();
export const Provider = GeneralContext.Provider;
export const GeneralConsumer = GeneralContext.Consumer;

const GeneralProvider = ({ children, fetchIntialData }) => {
  const [state, dispatch] = useReducer(Reducer, {
    layoutEmojiPicker: null,
    jobTrackEmojiPicker: null,
    isMobileCompanyDetail: false,
    reportJobModal: false,
    startTrackingModal: null,
    signUpModal: false,
  });
  return <Provider value={{ state, fetchIntialData, dispatch }}>{children}</Provider>;
};

export const withGeneral = () => useContext(GeneralContext);

export default GeneralProvider;
