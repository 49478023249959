import MarkDown from 'react-markdown';
import styled from 'styled-components';

const Content = styled(MarkDown)`
  margin: 36px 50px 0 50px;
  cursor: default;
  h2,
  h3 {
    display: block;
    font-family: ${({ theme }) => theme.font.family.websiteBold};
    font-size: 24px;
    color: #33334f;
    margin: 36px 0 0 0;
    & :first-child {
      margin-top: 0;
    }
  }
  h3 {
    font-size: 18px;
    color: rgb(80, 80, 107);
    margin: 30px 0 0 0;
  }
  p,
  a {
    font-family: ${({ theme }) => theme.font.family.websiteRegular};
    font-size: 14px;
    color: #7171a6;
    margin-bottom: 0;
    line-height: 1.5;
    & :first-child {
      margin-top: 0;
    }
  }
  a {
    color: #f37112;
    font-weight: 600;
    &:hover {
      border-bottom: solid 2px rgba(243, 112, 17, 0.3);
    }
  }
  li {
    font-family: ${({ theme }) => theme.font.family.websiteRegular};
    font-size: 14px;
    color: #7171a6;
    line-height: 1.5;
    margin-top: 2px;
    list-style-type: circle;
    list-style-position: outside;
  }
  ul {
    ${({ theme }) => (theme.isRTL ? `padding: 0 25px 0 0;` : `padding: 0 0 0 25px;`)}
    margin: 15px 0;
  }
  ${({ theme }) =>
    theme.max('sm')`
      margin: 0 30px;
      h2, h3 {
        margin-top: 16px;
      }
    `};
  ${({ theme }) =>
    theme.max('xs')`
      margin: 0 20px;
    `};
`;

export default Content;
