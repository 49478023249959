import env from '/env';

export const setDataRecord = async (data, token) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(`${env.BASE_API}/meta/leads-coaching`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const updateUser = async (data, token) => {
  const fetchOptions = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(`${env.BASE_API}/user`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};
