import React from 'react';
import PropTypes from 'prop-types';

const GlobeIcon = ({ isNewMobileDesign = false }) => {
  if (isNewMobileDesign)
    return (
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_5263_22808)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 2C4.68457 2 2 4.68457 2 8C2 11.3154 4.68457 14 8 14C11.3154 14 14 11.3154 14 8C14 4.68457 11.3154 2 8 2ZM0 8C0 3.58 3.58 0 8 0C12.42 0 16 3.58 16 8C16 12.42 12.42 16 8 16C3.58 16 0 12.42 0 8Z"
            fill="#4C4C55"
          />
          <path fillRule="evenodd" clipRule="evenodd" d="M15 9H1V7H15V9Z" fill="#4C4C55" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 8C4 5.07193 5.0721 2.01288 7.38553 0.211058L8.61447 1.78894C6.9279 3.10252 6 5.48577 6 8C6 10.5142 6.9279 12.8975 8.61447 14.2111L7.38553 15.7889C5.0721 13.9871 4 10.9281 4 8Z"
            fill="#4C4C55"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 8C12 10.9281 10.9279 13.9871 8.61447 15.7889L7.38553 14.2111C9.0721 12.8975 10 10.5142 10 8C10 5.48577 9.0721 3.10253 7.38553 1.78894L8.61447 0.211058C10.9279 2.01288 12 5.07193 12 8Z"
            fill="#4C4C55"
          />
        </g>
        <defs>
          <clipPath id="clip0_5263_22808">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <path
        fill="#282B32"
        fillRule="evenodd"
        d="M10 .833c5.063 0 9.167 4.104 9.167 9.167S15.063 19.167 10 19.167.833 15.063.833 10 4.937.833 10 .833zm2.052 13.237l-.364.033c-.548.042-1.112.064-1.688.064-.705 0-1.392-.033-2.052-.096C8.438 16.142 9.25 17.5 10 17.5s1.563-1.358 2.052-3.43zm-9.016-1.282l.076.184c.783 1.813 2.262 3.256 4.1 3.993-.446-.861-.795-1.934-1.03-3.146-1.213-.236-2.286-.585-3.146-1.03zm13.929 0c-.867.448-1.937.796-3.146 1.03-.236 1.213-.585 2.286-1.03 3.146 1.899-.76 3.414-2.276 4.176-4.176zM10 7.5c-.826 0-1.618.052-2.357.144C7.552 8.384 7.5 9.175 7.5 10s.052 1.617.144 2.356c.738.092 1.53.144 2.356.144.825 0 1.617-.052 2.356-.144.092-.739.144-1.53.144-2.356 0-.825-.052-1.617-.144-2.356-.74-.092-1.53-.144-2.356-.144zm-4.071.449l-.196.047C3.773 8.49 2.5 9.274 2.5 10c0 .75 1.357 1.563 3.43 2.052-.064-.66-.097-1.348-.097-2.052s.033-1.39.096-2.051zm8.143 0l.022.25c.048.584.073 1.186.073 1.801 0 .704-.033 1.391-.096 2.052C16.143 11.562 17.5 10.75 17.5 10c0-.75-1.357-1.562-3.428-2.051zm-6.86-4.913l-.184.076c-1.813.783-3.256 2.262-3.993 4.1.861-.446 1.934-.795 3.146-1.03.236-1.213.585-2.286 1.03-3.146zm5.576 0c.448.866.796 1.936 1.03 3.145 1.213.236 2.286.585 3.146 1.03-.76-1.899-2.276-3.414-4.176-4.176zM10 2.5c-.75 0-1.563 1.358-2.052 3.43.66-.064 1.347-.097 2.052-.097.705 0 1.392.033 2.053.096C11.563 3.858 10.75 2.5 10 2.5z"
      />
    </svg>
  );
};

export default GlobeIcon;

GlobeIcon.propTypes = {
  isNewMobileDesign: PropTypes.bool,
};
