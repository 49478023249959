import React, { useContext } from 'react';

const GeneralContext = React.createContext({
  currentResume: null,
});

GeneralContext.displayName = 'GeneralContext';

export const GeneralContextProvider = GeneralContext.Provider;
export const GeneralContextConsumer = GeneralContext.Consumer;
export const useGeneralDesign = () => useContext(GeneralContext);

export const withGeneralContext = (Component) => {
  function Wrapper(props) {
    const data = useGeneralDesign();
    return <Component {...props} {...data} />;
  }
  Wrapper.getInitialProps = async (ctx) => {
    const pageProps = Component.getInitialProps && (await Component.getInitialProps(ctx));
    return { ...pageProps };
  };
  return Wrapper;
};

export default withGeneralContext;
