import { useEffect } from 'react';
import CrossIcon from '../atoms/CrossIcon';
import CrossSwapIcon from '../atoms/CrossSwapIcon';
import { parseDraftText } from 'lib/helpers';
import styled, { css } from 'styled-components';

import Flex from '/imports/core/ui/atoms/Flex';
import Modal from '/imports/core/ui/atoms/Modal';
import useIntl from '/imports/core/api/useIntl';
import useTracking from '/imports/core/hooks/useTracking';

const modalStyles = {
  modalContainer: {
    display: 'flex',
    width: 'unset',
    bottom: 'unset',
    scrollY: 'auto',
  },
  modalBackdrop: { display: 'none' },
  modalBody: {
    width: '420px',
    maxHeight: '500px',
    backgroundColor: 'var(--light-values-white)',
    borderRadius: '12px',
    boxShadow: '0 12px 48px 0 rgba(20, 20, 31, 0.24)',
    flex: 'unset',
    overflow: 'visible',
  },
};

const PreAIEditModal = ({ open, setOpen, modalPosition, updateSelectedPreAiText, data, currentStep }) => {
  const { t } = useIntl();
  const { trackEvent } = useTracking();
  modalStyles.modalContainer = {
    ...modalStyles.modalContainer,
    top: modalPosition.top,
    left: modalPosition.left,
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    trackEvent('pre_ai_edit_popup_open');
  }, []);

  const handleClick = () => {
    trackEvent('pre_ai_edit_popup_old_applied', { step: currentStep });
    updateSelectedPreAiText(data.description);
  };

  return (
    <Modal
      onClose={() => handleClose()}
      styles={modalStyles}
      animation="empty"
      openStateBase
      open={open}
      timeout={0}
      className="custom"
      noClicker
    >
      <Wrapper>
        <ScrollWrapper fullWidth>
          <HeaderFlex fullWidth>
            <Flex justifyContent="space-between" alignItems="center" fullWidth>
              <HeadText>{t('generator_pre_ai_edit_text')}</HeadText>
              <CrossIconWrapper onClick={handleClose}>
                <CrossIcon />
              </CrossIconWrapper>
            </Flex>
            <Subtitle>{t('generator_pre_ai_edit_modal_swap')}</Subtitle>
          </HeaderFlex>
          <SuggestionsWrapper fullWidth onClick={handleClick}>
            <SuggestionWrapper alignItems="flex-start" fullWidth>
              <IconWrapper>
                <CrossSwapIcon />
              </IconWrapper>
              <SuggestionText
                dangerouslySetInnerHTML={{ __html: parseDraftText(data.description, true) ?? 'No data' }}
              />
            </SuggestionWrapper>
          </SuggestionsWrapper>
        </ScrollWrapper>
      </Wrapper>
    </Modal>
  );
};
export default PreAIEditModal;

const ScrollWrapper = styled(Flex)`
  flex-direction: column;
  overflow-y: auto;
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }
  ::-webkit-scrollbar {
    width: 4px;
    border-radius: 10px;
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${(p) => p.theme.colors.gray.regular};
  }
`;
const CrossIconWrapper = styled(Flex)`
  ${(p) =>
    p.topRight &&
    css`
      position: absolute;
      top: 0;
      right: 0;
    `}
  flex-shrink: 0;
  cursor: pointer;
`;
const Wrapper = styled(Flex)`
  flex-direction: column;
  padding: 16px 4px 16px 16px;
  gap: 12px;
  max-height: 500px;
`;
const HeaderFlex = styled(Flex)`
  flex-direction: column;
  gap: 4px;
`;
const HeadText = styled.span`
  color: #14141f;
  font-family: Gilroy SemiBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
`;
const Subtitle = styled.span`
  color: #a1a1a5;
  font-family: Gilroy SemiBold;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;
const SuggestionsWrapper = styled(Flex)`
  flex-direction: column;
  margin-top: 12px;
`;
const SuggestionWrapper = styled(Flex)`
  cursor: pointer;
  padding: 8px;
  gap: 8px;
  &:hover {
    background: #edf4ff;
    border-radius: 4px;
    path:first-child {
      fill: #1688fe;
    }

    g > path:nth-child(1),
    path:nth-child(2) {
      fill: white;
    }
  }
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      flex-direction: row-reverse;
    `}
`;
const IconWrapper = styled(Flex)`
  flex-shrink: 0;
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      transform: rotate(180deg);
    `}
`;
const SuggestionText = styled.span`
  padding-top: 0;
  color: #3a3a43;
  font-family: Gilroy Medium;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`;
