import React from 'react';
import styled from 'styled-components';

const CopyIcon = () => {
  return (
    <span>
      <SvgIcon
        width="17"
        height="17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="copy-icon"
        viewBox="0 0 25.1 25"
      >
        <path
          d="M2.6 6.3h15.2c.6 0 1 .4 1 1v15.2c0 .6-.4 1-1 1H2.6c-.6 0-1-.4-1-1V7.3c0-.5.4-1 1-1z"
          fill="none"
          stroke="#000"
          strokeWidth="2"
        />
        <path
          d="M7.4 2.5h15.2v15.2h-2v2h2c1.1 0 2-.9 2-2V2.5c0-1.1-.9-2-2-2H7.4c-1.1 0-2 .9-2 2v2h2v-2z"
          fillRule="evenodd"
          clipRule="evenodd"
        />
      </SvgIcon>
    </span>
  );
};

const SvgIcon = styled.svg`
  stroke: none;

  > path:first-of-type {
    fill: none !important;
  }
`;

export default CopyIcon;
