import React, { useEffect, useRef, useState } from 'react';
import Container from '../atmos/Container';
import Flex from 'imports/core/ui/atoms/Flex';
import { cdnFile, staticFile } from 'lib/helpers';
import { content_landing_slugs } from 'imports/homepageV2/api/constants';
import { replaceString } from 'imports/core/ui/helpers';
import styled, { css } from 'styled-components';

import useDomain from '/imports/core/hooks/useDomain';
import useIntl from '/imports/core/api/useIntl';
import { useResponsive } from '/imports/core/api/responsiveContext';
import useWindowSize from '/imports/core/api/useWindowSize';

const HomepageContentResumedone = ({ onClickLandingCTA, isNewMobileDesign = false }) => {
  const { width } = useWindowSize();
  const { t } = useIntl();
  const { host } = useResponsive();
  const slug = content_landing_slugs(host, t);
  const { getBrandName } = useDomain();
  const landing_content = replaceString(slug['resume_nation_landing_s1_p3'], {
    'Resume-Nation': getBrandName(),
  });
  const [backgroundHeight, setBackgroundHeight] = useState(430);
  const backgroundRef = useRef(null);

  useEffect(() => {
    const backgroundElement = backgroundRef.current;
    const computedStyle = getComputedStyle(backgroundElement);
    const imageUrl = computedStyle.backgroundImage.slice(4, -1).replace(/"/g, '');
    const img = new Image();
    img.src = imageUrl;

    img.addEventListener('load', () => {
      const imageAspectRatio = img.height / img.width;
      const newHeight = imageAspectRatio * (isNewMobileDesign ? width - 32 : width); //minus 32 (with padding)
      setBackgroundHeight(newHeight);
    });
  }, [width]);
  return (
    <Main $direction="column">
      <Container>
        <Wrapper $fullWidth>
          <LeftSection $image="img-context.jpg" $isNewMobileDesign={isNewMobileDesign}></LeftSection>
          <StyledFlex $direction="column" $isNewMobileDesign={isNewMobileDesign}>
            <Title $isNewMobileDesign={isNewMobileDesign}>
              {isNewMobileDesign ? (
                <TextV1
                  dangerouslySetInnerHTML={{
                    __html:
                      slug['resume_nation_landing_s1_p1'] + ` <span>${slug['resume_nation_landing_s1_p2']}</span>`,
                  }}
                  $isNewMobileDesign={isNewMobileDesign}
                />
              ) : (
                <>
                  <TextV1 dangerouslySetInnerHTML={{ __html: slug['resume_nation_landing_s1_p1'] }} />
                  <span>{slug['resume_nation_landing_s1_p2']}</span>
                </>
              )}
            </Title>
            <Text dangerouslySetInnerHTML={{ __html: landing_content }} $isNewMobileDesign={isNewMobileDesign} />
            <Button onClick={onClickLandingCTA} $isNewMobileDesign={isNewMobileDesign}>
              {slug['resume_nation_landing_asset_2']}
            </Button>
          </StyledFlex>
        </Wrapper>
      </Container>
      <StyledContainer $isNewMobileDesign={isNewMobileDesign}>
        <Wrapper $direction="row-reverse" $fullWidth>
          <LeftSection
            $image="img-builder.png"
            ref={backgroundRef}
            $small
            $height={backgroundHeight}
            $isNewMobileDesign={isNewMobileDesign}
          ></LeftSection>
          <StyledFlex $direction="column" $isReverse $isNewMobileDesign={isNewMobileDesign}>
            <Title
              dangerouslySetInnerHTML={{ __html: slug['resume_nation_landing_s2_p1'] }}
              $isNewMobileDesign={isNewMobileDesign}
            />
            <Text
              dangerouslySetInnerHTML={{ __html: slug['resume_nation_landing_s2_p2'] }}
              $isNewMobileDesign={isNewMobileDesign}
            />
          </StyledFlex>
        </Wrapper>
      </StyledContainer>
    </Main>
  );
};

export default HomepageContentResumedone;

const StyledContainer = styled(Container)`
  ${({ $isNewMobileDesign }) =>
    $isNewMobileDesign &&
    css`
      padding: 0 16px !important;
    `}
`;
const Button = styled.button`
  margin-top: 32px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 32px;
  border-radius: 32px;
  box-shadow: 0 8px 24px 0 var(--border-transparent);
  background-color: var(--primary);
  border: none;
  color: var(--light-values-white);
  font-size: 18px;
  line-height: 1.33;
  font-family: ${({ theme }) => theme.font.family.websiteBold};
  &:hover {
    background-color: var(--additional-colors-pink-old);
  }
  ${({ theme }) => theme.max('md')`
    padding: 9px 20px;
    font-size: 15px;
    line-height: 1.47;
  `}
  ${({ $isNewMobileDesign }) =>
    $isNewMobileDesign &&
    css`
      margin-top: 24px !important;
      height: 40px;
      padding: 16px;
      gap: 4px;
      align-self: stretch;
      border-radius: 28px;
      background: var(--Primary, #ff6f5b);
    `}
`;

const Text = styled.h2`
  margin: 0;
  padding-top: 32px;
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 19px;
  font-weight: 500;
  line-height: 1.47;
  color: var(--text);
  span {
    font-weight: bold;
  }
  ${({ theme }) => theme.max('md')`
    font-size: 15px;
  `}
  ${({ $isNewMobileDesign }) =>
    $isNewMobileDesign &&
    css`
      padding: 24px 0 0 !important;
      color: #14141f;
      font-size: 16px !important;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      span {
        font-family: Gilroy Bold;
        line-height: 20px;
      }
    `}
`;

const StyledFlex = styled(Flex)`
  padding-left: 40px;
  ${({ $isReverse }) =>
    $isReverse &&
    css`
      padding-left: 0;
      padding-right: 40px;
    `}
  ${({ theme: { isRTL }, $isReverse }) =>
    isRTL &&
    css`
      padding-right: ${$isReverse ? '0' : '40px'};
      padding-left: ${$isReverse ? '40px' : '0'};
    `}
    ${({ theme }) => theme.max('md')`
      padding: 48px 0;
    `}
  ${({ $isNewMobileDesign }) =>
    $isNewMobileDesign &&
    css`
      padding: 32px 0 24px !important;
    `}
`;

const TextV1 = styled.div`
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 50px;
  line-height: 1.2;
  letter-spacing: -0.25px;
  color: var(--text);
  span {
    color: var(--primary);
  }
  @media (min-width: 981px) and (max-width: 1100px) {
    font-size: 34px;
  }
  ${({ $isNewMobileDesign }) =>
    $isNewMobileDesign &&
    css`
      color: #14141f;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: 44px;
    `}
`;

const Title = styled.h2`
  margin: 0;
  padding: 0;
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 50px;
  line-height: 1.2;
  letter-spacing: -0.25px;
  color: var(--text);
  ${({ theme }) => theme.max('md')`
    font-size: 34px;
    line-height: 1.29;
  `}
  span {
    color: var(--primary);
  }
  @media (min-width: 981px) and (max-width: 1100px) {
    font-size: 34px;
  }
  ${({ $isNewMobileDesign }) =>
    $isNewMobileDesign &&
    css`
      font-family: Gilroy SemiBold;
      font-size: 32px !important;
      font-style: normal;
      font-weight: 400;
      line-height: 44px;
    `}
`;

const LeftSection = styled.div`
  ${({ $image }) =>
    $image &&
    css`
      background-image: url(${cdnFile(`resume-nation-landing/${$image}`)});
      ${$image === 'img-builder.png' &&
      css`
        @media (max-width: 980px) {
          background-image: url(${staticFile('img/resume-nation-landing-medium.png')});
          height: ${(p) => p.height}px !important;
          padding-top: 0 !important;
        }
      `}
    `}
  width: 620px;
  height: 620px;
  display: flex;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 16px;
  flex-shrink: 0;
  border-radius: 16px;
  box-shadow: 0 8px 24px 0 var(--border-transparent);
  border: solid 1px var(--border-transparent);
  ${({ $small }) =>
    $small &&
    css`
      height: 430px;
    `}
  ${({ theme }) => theme.max('md')`
    padding-top: 100%;
    border-radius: 0;
    height: 0;
    box-sizing: none;
    width: calc(100% + 40px);
    border: none;
  `}
  ${({ $isNewMobileDesign, $image }) =>
    $isNewMobileDesign &&
    css`
      width: 100% !important;
      background-size: contain;
      ${$image === 'img-builder.png' &&
      css`
        margin: 8px 0 32px;
      `}
    `}
`;

const Wrapper = styled(Flex)`
  gap: 40px;
  align-items: center;
  ${({ theme }) => theme.max('md')`
    flex-direction: column-reverse;
    gap: 0;
  `}
`;

const Main = styled(Flex)`
  margin-top: 200px;
  gap: 120px;
  ${({ theme }) => theme.max('md')`
    margin-top: 0;
    gap: 0;
  `}
`;
