import React from 'react';
import styled from 'styled-components';

const LockIcon = () => {
  return (
    <SvgIcon width="40" height="40" xmlns="http://www.w3.org/2000/svg">
      <path fill="none" d="M-1-1h42v42H-1z" />
      <g>
        <rect strokeWidth="1.5" stroke="#BFC5D6" rx="2.8" height="9.2" width="7.2" y="10.375" x="16.9" fill="#F2F5FA" />
        <rect strokeWidth="1.5" stroke="#BFC5D6" fill="#F2F5FA" rx="1" height="8" width="12" y="17.25" x="14.75" />
      </g>
    </SvgIcon>
  );
};

const SvgIcon = styled.svg`
  stroke: none;
`;

export default LockIcon;
