import styled from 'styled-components';

const ArticleTitle = styled.div`
  font-family: ${({ theme }) => theme.font.family.websiteBold};
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  font-weight: bold;
  color: #14141f;
  margin: 0;
`;

export default ArticleTitle;
