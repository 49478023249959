import React from 'react';

const UndoIcon = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="26" height="26" rx="13" fill="#1688FE" />
      <path
        d="M8.18755 13.359C8.18776 14.4765 8.60569 15.5564 9.36462 16.4006C10.1235 17.2448 11.1726 17.7965 12.3191 17.9546C13.4657 18.1128 14.6329 17.8666 15.6066 17.2613C16.5802 16.656 17.2949 15.7323 17.6196 14.6596C17.9442 13.5869 17.857 12.4372 17.3739 11.4215C16.8909 10.4058 16.0444 9.59234 14.9898 9.1303C13.9352 8.66827 12.7432 8.58869 11.6327 8.90619C10.5222 9.22369 9.56766 9.91695 8.94422 10.8588"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.9291 8V10.8574H11.8906"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UndoIcon;
