import React from 'react';
import InputCheckBox from 'imports/core/ui/atoms/InputCheckBox';
import Autosave from '/imports/core/ui/components/Autosave';

const InputCheckboxAutoSave = (props) => {
  const { className, name, label, value, isCoverLetter, variables, language } = props;

  return (
    <Autosave {...props}>
      {({ onChange }) => (
        <InputCheckBox
          language={language}
          className={className}
          name={name}
          onChange={onChange}
          checked={value}
          label={label}
          isCoverLetter={isCoverLetter}
          variables={variables}
        />
      )}
    </Autosave>
  );
};

export default InputCheckboxAutoSave;
