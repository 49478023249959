import styled, { css } from 'styled-components';

const Label = styled.label`
  display: block;
  line-height: 20px;
  margin-bottom: ${(p) => p.theme.spaces.unit};
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 13px;
  color: #858c98;

  ${(p) =>
    p.block &&
    css`
      display: block !important;
    `}

  ${(p) =>
    p.required &&
    css`
      &:after {
        content: '*';
        position: relative;
        color: ${(p) => p.theme.colors.danger};
        left: 5px;
      }
    `} 
  ${(p) =>
    p.bold &&
    css`
      font-weight: 500;
    `}
  ${(p) =>
    p.form &&
    css`
      text-transform: capitalize;
    `}
  > small {
    display: block;
    font-weight: 300;
  }
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
`;

export default Label;
