import React from 'react';
import Logo from 'imports/core/ui/components/Logo';
import { Push as BuilderPush } from 'components/Link';
import { getDomainName } from 'lib/helpers';
import { Push } from './Link';
import styled, { css } from 'styled-components';
import { useResponsive } from 'imports/core/api/responsiveContext';

import Flex from '/imports/core/ui/atoms/Flex';
import useIntl from '/imports/core/api/useIntl';

const Header = () => {
  const { t, locale } = useIntl();
  const { host } = useResponsive();
  const goToHome = () => {
    return BuilderPush(`https://${getDomainName(host)}`, locale);
  };
  const goToFaq = () => {
    return Push('/', locale);
  };

  return (
    <HeaderWrapper>
      <StyledFlex justifyContent="space-between" fullWidth>
        <HeaderFlex alignItems="center" onClick={() => goToHome()}>
          <Logo />
        </HeaderFlex>
        <MenuWrapper>
          <HelpText onClick={() => goToFaq()}>{t('header.help')}</HelpText>
        </MenuWrapper>
      </StyledFlex>
    </HeaderWrapper>
  );
};

const StyledFlex = styled(Flex)`
  ${({ theme }) => theme.max('md')`
    padding: 12px 8px;
    align-items: center;
  `}
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `};
`;

const HeaderFlex = styled(Flex)`
  align-self: center;
  cursor: pointer;
`;

const HelpText = styled.div`
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 15px;
  line-height: 24px;
  color: #14141f;
  cursor: pointer;
`;

const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  z-index: 1;
  ${({ theme }) => theme.max('md')`
    height: 64px;
    margin: 0;
  `}
`;

const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      left: 80px;
      right: unset;
    `}
`;

export default Header;
