const GreyMinusIcon = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.5" width="20" height="20" rx="10" fill="#F6F6F6" />
      <path d="M5.4541 10.5H14.545" stroke="#A1A1A5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default GreyMinusIcon;
