import styled from 'styled-components';

const BasicContainer = styled.div`
  with: 100%;
  margin: 0 32px;
  @media (max-width: 479px) {
    margin: 0 16px;
  }
`;
export default BasicContainer;
