import { isArabic } from 'styles/pdf-fonts/helpers';
import styled, { css } from 'styled-components';

import TextAtom from '/imports/pdf/core/ui/atoms/Text';

const Heading = styled(TextAtom)`
  font-family: ${({ theme: { headingFont } }) => headingFont()};
  font-size: ${({ theme }) => theme.convertPx(15)};
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  ${({ children }) =>
    !isArabic(children) &&
    css`
      letter-spacing: ${({ theme }) => theme.convertPx(3.75)};
    `};
  color: #58595b;
  text-transform: uppercase;
  page-break-inside: avoid;
  break-inside: avoid-page;
`;

export default Heading;
