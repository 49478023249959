import React, { PureComponent, Fragment } from 'react';
import PreviewMobileControls from 'imports/generator/ui/components/PreviewMobileControls';
import styled, { css } from 'styled-components';

import ButtonAtom from '/imports/core/ui/atoms/Button';
import ControlsModal from '/imports/generator/ui/components/ControlsModal';
import { PreviewSliderIcon } from '/imports/generator/ui/assets';

class SlidersMobileButton extends PureComponent {
  state = {
    modalOpened: false,
  };

  toggleModal = () => this.setState({ modalOpened: !this.state.modalOpened });

  render() {
    const { modalOpened } = this.state;
    return (
      <Fragment>
        <SlidersButton onClick={this.toggleModal}>
          <PreviewSliderIcon />
        </SlidersButton>
        <ControlsModal {...this.props} openStateBase open={modalOpened} onClose={this.toggleModal} noCancel>
          <PreviewMobileControls {...this.props} />
        </ControlsModal>
      </Fragment>
    );
  }
}

SlidersMobileButton.displayName = 'SlidersMobileButton';

const Button = styled(ButtonAtom)`
  position: relative;
  top: 2px;
  color: ${(p) => p.theme.colors.gray.light} !important;
  ${({ theme }) => theme.max('sm')`
    z-index: 99;
  `}
`;

const SlidersButton = styled((p) => <Button unstyled {...p} />)`
  color: white !important;
  font-size: 26px;
  position: absolute;
  top: 50%;
  z-index: 99;
  transform: translateY(-50%);
  left: calc(${(p) => p.theme.general.gridGap} * 2);
  ${({ theme: { max, isRTL, general } }) => max('md')`
    left: ${general.mobilePadding};
    display: flex;
    ${
      isRTL &&
      css`
        left: unset;
        right: ${general.mobilePadding};
      `
    }
  `}
`;

export default SlidersMobileButton;
