import React, { Fragment, useRef, useEffect, useState } from 'react';
import Flex from '/imports/core/ui/atoms/Flex';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import CurveArrow from '/imports/generator/ui/assets/CurveArrow.js';
import useIntl from '/imports/core/api/useIntl';
import useOutsideClick from '/imports/core/api/useOutsideClick';
import { withBlock } from '/imports/generator/context/blockresposition.context';
import useWindowSize from '/imports/core/api/useWindowSize';

const ToggleDeactiveModal = ({ toggleRef }) => {
  const deactiveToggleRef = useRef();
  const { t } = useIntl();
  const { width } = useWindowSize();
  const [wrapperTopPosition, setWrapperTopPosition] = useState(0);

  const {
    state: { isTooltipOpen },
    dispatch,
  } = withBlock();

  useEffect(() => {
    const handleDimensions = () => {
      const RespositionElement = toggleRef.current;
      if (RespositionElement) {
        const height = RespositionElement.offsetHeight;
        const top = RespositionElement.offsetTop;
        setWrapperTopPosition(top + height);
      }
    };

    handleDimensions();
  }, [width]);

  useOutsideClick(
    deactiveToggleRef,
    () => {
      if (isTooltipOpen) {
        dispatch({ type: 'UPDATE_STATE', name: 'isTooltipOpen', payload: false });
      }
    },
    [],
    [toggleRef],
  );

  return (
    <Fragment>
      {wrapperTopPosition > 0 && (
        <Fragment>
          <ToggleDeactiveMain fullWidth />
          <ToggleDeactiveOverlay ref={deactiveToggleRef} justifyContent="center" className="ai-deactive-modal">
            <ToggleDeactiveWrap topPosition={wrapperTopPosition} justifyContent="center" fullWidth>
              <ToggleDeactiveContent justifyContent="center" alignItems="center" fullWidth>
                <ToggleModalText justifyContent="center" alignItems="center">
                  {t('generator_ai_toggle_tooltip_text')}
                </ToggleModalText>
              </ToggleDeactiveContent>
              <SvgWrap topPosition={wrapperTopPosition} justifyContent="end" fullWidth>
                <CurveArrow />
              </SvgWrap>
            </ToggleDeactiveWrap>
          </ToggleDeactiveOverlay>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ToggleDeactiveModal;

ToggleDeactiveModal.propTypes = { toggleRef: PropTypes.any };

const ToggleDeactiveMain = styled(Flex)`
  height: 100vh;
  background: transparent;
  position: absolute;
  inset: 0;
  z-index: 1000;
`;

const SvgWrap = styled(Flex)`
  position: fixed;
  top: ${({ topPosition }) => `${topPosition + 5}px`};
  left: 0;

  svg {
    width: 415px;
    height: 118px;
  }

  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      left: auto;
      right: 0;
      transform: scaleX(-1);
      justify-content: flex-start;
    `}

  @media (max-width: 1950px) {
    svg {
      width: 380px;
      height: 118px;
    }
  }

  @media (max-width: 1800px) {
    svg {
      width: 345px;
      height: 112px;
    }
  }

  @media (max-width: 1700px) {
    svg {
      width: 320px;
      height: 112px;
    }
  }

  @media (max-width: 1350px) {
    svg {
      width: 315px;
      height: 112px;
    }
  }

  @media (max-width: 1150px) {
    svg {
      width: 285px;
      height: 104px;
    }
  }

  @media (max-width: 900px) {
    svg {
      width: 260px;
      height: 104px;
    }
  }
`;

const ToggleModalText = styled(Flex)`
  text-align: center;
  font-family: Gilroy Medium;
  font-size: 18px;
  line-height: 25px;
  color: #fff;

  @media (max-width: 1800px) {
    font-size: 15px;
    line-height: 22px;
  }

  @media (max-width: 1150px) {
    font-size: 10px;
    line-height: 15px;
  }
`;

const ToggleDeactiveWrap = styled(Flex)`
  position: relative;
  top: ${({ topPosition }) => `${topPosition + 73}px`};
`;

const ToggleDeactiveContent = styled(Flex)`
  max-width: 420px;
  height: 85px;
  border-radius: 100px;
  padding: 16px 24px;
  background: #1688fe;
  z-index: 1;
  position: fixed;

  @media (max-width: 1800px) {
    max-width: 355px;
    height: 76px;
  }

  @media (max-width: 1150px) {
    max-width: 250px;
    height: 60px;
  }
`;

const ToggleDeactiveOverlay = styled(Flex)`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  display: none;
`;
