import React from 'react';
import styled from 'styled-components';

const DocxIcon = () => {
  return (
    <SvgIcon width="21" height="22" xmlns="http://www.w3.org/2000/svg">
      <g fill="#000" fillRule="nonzero">
        <path d="M2.242 10a.895.895 0 00-.242.031v1.951c.045.015.12.016.186.018.49.014.814-.321.814-1.048.004-.631-.292-.96-.758-.952z" />
        <path d="M12.901 20.1c.029.002.058.005.087.005h6.61A1.41 1.41 0 0021 18.692V3.252a1.41 1.41 0 00-1.402-1.413h-6.61c-.03 0-.058.003-.087.004V0L0 1.735V20.22L12.901 22v-1.9zm0-11.165h6.969v4.776H12.9V8.935zm4.693-.404h-4.693V6.642h4.693v1.889zm2.004 10.767h-6.61c-.03 0-.058-.005-.087-.01v-5.173H20.2v4.577c0 .334-.27.606-.601.606zm-6.61-16.652h6.61c.331 0 .6.272.6.606V8.53h-2.203V6.238h-5.094V2.655c.029-.004.057-.009.087-.009zM2.855 12.238c-.261.232-.653.333-1.123.32a4.092 4.092 0 01-.604-.056V9.918c.194-.04.449-.066.721-.072.458-.012.76.073.998.265.26.206.424.542.424 1.026 0 .524-.176.884-.416 1.101zm1.903.422c-.78-.022-1.227-.652-1.227-1.437 0-.826.492-1.456 1.27-1.476.834-.02 1.3.632 1.3 1.43 0 .947-.552 1.505-1.343 1.483zm3.257-.47c.205.003.435-.038.57-.089l.105.536c-.127.059-.411.118-.777.108-1.014-.029-1.523-.668-1.523-1.495 0-.991.697-1.56 1.59-1.583.353-.009.624.056.747.116l-.141.545a1.475 1.475 0 00-.576-.103c-.533.008-.94.333-.94.987 0 .589.347.966.945.977zm2.838.592l-.297-.573c-.121-.22-.198-.382-.29-.563h-.01c-.066.179-.148.339-.247.553l-.266.552-.81-.022.91-1.535-.878-1.502.816-.02.28.558c.095.187.167.338.244.513h.01c.077-.199.14-.338.221-.52l.278-.576.863-.02-.941 1.55.992 1.628-.875-.023z" />
        <path d="M4.494 10c-.313.007-.494.437-.494 1.008 0 .574.187.984.497.992.319.008.503-.424.503-1.008 0-.54-.181-.999-.506-.992zM13.5 9a.5.5 0 110 1 .5.5 0 010-1zM15 9.899h4v1h-4zM13.5 10a.5.5 0 110 1 .5.5 0 010-1zM15 10.899h4v1h-4zM13.5 11a.5.5 0 110 1 .5.5 0 010-1zM15 11.899h4v1h-4zM13.5 12a.5.5 0 110 1 .5.5 0 010-1zM15 12.899h4v1h-4z" />
      </g>
    </SvgIcon>
  );
};

const SvgIcon = styled.svg`
  stroke: none;
`;

export default DocxIcon;
