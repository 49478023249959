import styled from 'styled-components';

const EmploymentIcon = ({ width = '22px', height = '22px', viewBox = '0 0 22 22' }) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
    <path d="M11 0c1.307 0 2.491.81 3.594 2.322 1.85-.29 3.26-.024 4.184.9.924.924 1.19 2.334.9 4.184C21.19 8.509 22 9.693 22 11s-.81 2.491-2.322 3.594c.29 1.85.024 3.26-.9 4.184-.924.924-2.334 1.19-4.184.9C13.491 21.19 12.307 22 11 22s-2.491-.81-3.594-2.322c-1.85.29-3.26.024-4.184-.9-.924-.924-1.19-2.334-.9-4.184C.81 13.491 0 12.307 0 11s.81-2.491 2.322-3.594c-.29-1.85-.024-3.26.9-4.184.924-.924 2.334-1.19 4.184-.9C8.509.81 9.693 0 11 0zm0 2c-.632 0-1.418.607-2.298 1.968a1 1 0 01-1.05.434c-1.584-.34-2.569-.213-3.016.234-.447.447-.573 1.432-.234 3.017a1 1 0 01-.434 1.049C2.607 9.582 2 10.368 2 11c0 .632.607 1.418 1.968 2.298a1 1 0 01.434 1.05c-.34 1.584-.213 2.569.234 3.016.447.447 1.432.573 3.017.234a1 1 0 011.049.434C9.582 19.393 10.368 20 11 20c.632 0 1.418-.607 2.298-1.968a1 1 0 011.05-.434c1.584.34 2.569.213 3.016-.234.447-.447.573-1.432.234-3.017a1 1 0 01.434-1.049C19.393 12.418 20 11.632 20 11c0-.632-.607-1.418-1.968-2.298a1 1 0 01-.434-1.05c.34-1.584.213-2.569-.234-3.016-.447-.447-1.432-.573-3.017-.234a1 1 0 01-1.049-.434C12.418 2.607 11.632 2 11 2zm3.293 5.293l1.414 1.414L10 14.414l-3.707-3.707 1.414-1.414L10 11.586l4.293-4.293z" />
  </SvgIcon>
);

const SvgIcon = styled.svg`
  stroke: none;
  fill: currentColor;
`;

EmploymentIcon.displayName = 'EmploymentIcon';

export default EmploymentIcon;
