import React from 'react';

const BrushIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.6668 6.19423C11.6668 5.87757 11.4557 5.66646 11.139 5.66646C9.0279 5.66646 7.97234 5.45534 7.23345 4.82201C6.49456 4.18868 6.389 3.02756 6.389 0.91645C6.389 0.599783 6.17789 0.388672 5.86123 0.388672C5.54456 0.388672 5.33345 0.599783 5.33345 0.91645C5.33345 3.02756 5.12234 4.08312 4.489 4.82201C3.85567 5.5609 2.69456 5.66646 0.583442 5.66646C0.266775 5.66646 0.0556641 5.87757 0.0556641 6.19423C0.0556641 6.5109 0.266775 6.72201 0.583442 6.72201C2.69456 6.72201 3.75011 6.93312 4.489 7.56646C5.22789 8.19979 5.33345 9.3609 5.33345 11.472C5.33345 11.7887 5.54456 11.9998 5.86123 11.9998C6.17789 11.9998 6.389 11.7887 6.389 11.472C6.389 9.3609 6.60012 8.30535 7.23345 7.56646C7.86678 6.82757 9.0279 6.72201 11.139 6.72201C11.4557 6.72201 11.6668 6.5109 11.6668 6.19423ZM11.139 3.55534C11.8779 3.55534 12.3001 3.6609 12.5112 3.87201C12.7223 4.08312 12.7223 4.39979 12.7223 5.13868C12.7223 5.45534 12.9335 5.66646 13.2501 5.66646C13.5668 5.66646 13.7779 5.45534 13.7779 5.13868C13.7779 4.39979 13.8835 3.97756 14.0946 3.76645C14.3057 3.55534 14.6223 3.55534 15.3612 3.55534C15.6779 3.55534 15.889 3.34423 15.889 3.02756C15.889 2.7109 15.6779 2.49979 15.3612 2.49979C14.6223 2.49979 14.2001 2.39423 13.989 2.18312C13.7779 1.97201 13.7779 1.65534 13.7779 0.91645C13.7779 0.599783 13.5668 0.388672 13.2501 0.388672C12.9335 0.388672 12.7223 0.599783 12.7223 0.91645C12.7223 1.65534 12.6168 2.07756 12.4057 2.28867C12.1946 2.49979 11.8779 2.49979 11.139 2.49979C10.8223 2.49979 10.6112 2.7109 10.6112 3.02756C10.6112 3.34423 10.8223 3.55534 11.139 3.55534Z"
        fill="white"
      />
      <path
        d="M13.9887 11.3668L10.6109 7.98899C10.5053 7.88343 10.3998 7.77788 10.1887 7.77788C9.44979 7.67232 8.7109 7.88343 8.07757 8.30565C7.54979 8.93899 7.33868 9.78343 7.44424 10.5223C7.44424 10.6279 7.44424 10.839 7.54979 10.9445L10.9276 14.3223C11.1387 14.5334 11.4554 14.5334 11.6665 14.3223L13.8831 12.1057C14.1998 11.8945 14.1998 11.5779 13.9887 11.3668ZM22.6443 20.0223L15.4665 12.8445C15.2554 12.6334 14.9387 12.6334 14.7276 12.8445L12.5109 15.0612C12.2998 15.2723 12.2998 15.589 12.5109 15.8001L19.6887 22.9779C20.5331 23.8223 21.7998 23.8223 22.6443 22.9779C23.4887 22.1334 23.4887 20.8668 22.6443 20.0223Z"
        fill="white"
      />
    </svg>
  );
};

export default BrushIcon;
