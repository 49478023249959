import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';

import BlockItem from '/imports/pdf/budapest/ui/atoms/BlockItem';
import Heading from '/imports/pdf/budapest/ui/atoms/Heading';
import useIntl from '/imports/core/api/useIntl';

const RightPlaceHolder = ({ language }) => {
  const { t } = useIntl();
  return (
    <Fragment>
      <StyledBlockItem first>
        <StyledHeading noMargin>{t('work_experience', language)}</StyledHeading>
      </StyledBlockItem>
      <StyledBlockItem>
        <StyledHeading noMargin>{t('education', language)}</StyledHeading>
      </StyledBlockItem>
      <StyledBlockItem>
        <StyledHeading>{t('skills', language)}</StyledHeading>
      </StyledBlockItem>
    </Fragment>
  );
};

const StyledBlockItem = styled(BlockItem)`
  margin-bottom: 0;
  border-bottom: none;
  padding: ${({ theme }) => theme.getPaddingMargin({ top: 26, bottom: 22, left: 24, right: 24 })};
  svg {
    margin-bottom: ${({ theme }) => theme.convertPx(56)};
    max-width: 100%;
  }
`;

const StyledHeading = styled(Heading)`
  ${({ noMargin, theme }) =>
    noMargin &&
    css`
      margin-bottom: ${theme.convertPx(10)};
    `}
`;

export default RightPlaceHolder;
