import ExtraCurricularIconV3 from './ExtraCurricularIconV3';
import IconsExperiment from './IconsExperiment';

const ExtraCurricularIconExperiment = (props) => {
  return <IconsExperiment iconV3={<ExtraCurricularIconV3 {...props} />} />;
};

ExtraCurricularIconExperiment.displayName = 'ExtraCurricularIconExperiment';

export default ExtraCurricularIconExperiment;
