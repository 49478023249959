import styled, { css } from 'styled-components';

const TitleWrapper = styled.div`
  span {
    &:nth-child(2) {
      font-size: 70px;
      letter-spacing: 0.04em;
      font-size: inherit;
      letter-spacing: normal;
    }
    &:first-child {
      font-family: ${({ theme }) => theme.font.family.websiteBold};
      color: #f37112;
      display: inline-block;
      padding: 0;
      line-height: 30px;
      position: relative;
    }
    &:last-child {
      font-family: ${({ theme }) => theme.font.family.websiteRegular};
      font-size: 50px;
      text-transform: capitalize;
    }
  }
`;

export default TitleWrapper;
