import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Autosave from '/imports/core/ui/components/Autosave';
import Checkbox from '/imports/core/ui/atoms/Checkbox';

class CheckboxAutosave extends PureComponent {
  render() {
    const checked = this.props.checked || false;
    return (
      <Autosave {...this.props}>
        {({ onChange }) => (
          <Checkbox {...this.props} onChange={() => onChange({ target: { value: !checked } })} checked={checked} />
        )}
      </Autosave>
    );
  }
}

export default CheckboxAutosave;

CheckboxAutosave.propTypes = {
  checked: PropTypes.bool,
};
