const CircleArrowLeft = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0625 19.125C15.0676 19.125 19.125 15.0676 19.125 10.0625C19.125 5.05742 15.0676 1 10.0625 1C5.05742 1 1 5.05742 1 10.0625C1 15.0676 5.05742 19.125 10.0625 19.125Z"
        fill="#edf4ff"
      />
      <path d="M14 9.75H6" stroke="#1688fe" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
      <path
        d="M8.75 12.5L6 9.75L8.75 7"
        stroke="#1688fe"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
};
export default CircleArrowLeft;
