import styled from 'styled-components';

const PreviewArrowIcon = (props) => (
  <SVG width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <path d="m15 1-5 5m5-5h-5m5 0v5M1 15l5-5m-5 5h5m-5 0v-5" stroke="#4F5E75" strokeWidth="2" strokeLinecap="round" />
  </SVG>
);

export default PreviewArrowIcon;

const SVG = styled.svg``;
