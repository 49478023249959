import React from 'react';

const Logo = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 33.23h29.538V48H6a6 6 0 0 1-6-6v-8.77z" fill="#FFC666" />
      <path d="M0 6a6 6 0 0 1 6-6h23.538v29.538H0V6z" fill="#FF6F5B" />
      <path d="M33.23 0C41.389 0 48 6.612 48 14.77c0 8.156-6.612 14.768-14.77 14.768V0z" fill="#855AEA" />
      <path
        d="M33.23 33.23c6.084 0 11.309 3.679 13.571 8.932C48.112 45.206 45.314 48 42 48h-8.77V33.23z"
        fill="#FF9CAE"
      />
    </svg>
  );
};

export default Logo;
