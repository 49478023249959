import React from 'react';

const LinkedInLogo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M20.4511 20.4494H16.8947V14.8799C16.8947 13.5519 16.871 11.8422 15.045 11.8422C13.1927 11.8422 12.9093 13.2892 12.9093 14.7833V20.449H9.35293V8.99597H12.7671V10.5612H12.8149C13.1565 9.97696 13.6503 9.49637 14.2435 9.17058C14.8367 8.84478 15.5071 8.68598 16.1834 8.71109C19.788 8.71109 20.4526 11.082 20.4526 14.1664L20.4511 20.4494ZM5.34014 7.43042C4.93196 7.43049 4.53292 7.30952 4.19348 7.08281C3.85405 6.85609 3.58947 6.53382 3.43319 6.15673C3.27692 5.77964 3.23598 5.36469 3.31554 4.96433C3.3951 4.56398 3.59159 4.1962 3.88017 3.90752C4.16875 3.61884 4.53645 3.42221 4.93678 3.34251C5.3371 3.2628 5.75208 3.3036 6.12922 3.45974C6.50636 3.61588 6.82873 3.88034 7.05557 4.2197C7.2824 4.55905 7.40352 4.95804 7.40359 5.36623C7.40364 5.63725 7.3503 5.90563 7.24663 6.15605C7.14296 6.40646 6.99098 6.634 6.79937 6.82568C6.60776 7.01735 6.38028 7.16941 6.1299 7.27318C5.87953 7.37694 5.61117 7.43037 5.34014 7.43042ZM7.11834 20.4494H3.55824V8.99597H7.11834V20.4494ZM22.2241 0.000154055H1.77116C1.30693 -0.00508471 0.859618 0.174193 0.527509 0.498592C0.1954 0.822991 0.0056656 1.26597 0 1.73019V22.268C0.00547169 22.7324 0.195084 23.1757 0.527182 23.5004C0.85928 23.8252 1.3067 24.0048 1.77116 23.9999H22.2241C22.6895 24.0057 23.1381 23.8266 23.4716 23.5019C23.805 23.1771 23.9959 22.7333 24.0023 22.268V1.72871C23.9957 1.26357 23.8047 0.820086 23.4712 0.495694C23.1378 0.171303 22.6892 -0.00598058 22.2241 0.000154055Z"
        fill="#0A66C2"
      />
    </svg>
  );
};

export default LinkedInLogo;
