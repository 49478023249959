import PropTypes from 'prop-types';

export const planType = PropTypes.shape({
  amount: PropTypes.number,
  billingCycle: PropTypes.number,
  id: PropTypes.string,
  isMonthlyly: PropTypes.bool,
  trialDays: PropTypes.number,
  type: PropTypes.string,
  currency: PropTypes.string,
  experimentPlan: PropTypes.string,
});
