import cloneDeep from 'lodash/cloneDeep';
import {
  deleteJob,
  dragFromColumnToColumn,
  dragFromColumnToColumnUpdate,
  dragFromSingleColumn,
  getColumnByOrder,
  getJobFromBoard,
  dragFromColumnToColumnByIndex,
  getJobsFromTotalBoards,
  getJobFromTotalBoards,
} from '/imports/job-tracking/api/draganddrop';

export const taskModalClose = (state) => {
  return { ...state, taskListActiveJobId: null };
};

export const setBoards = (state, action) => {
  return { ...state, boards: action.payload };
};

export const openModal = (state) => {
  return { ...state, boardAddModal: true };
};

export const closeModal = (state) => {
  return { ...state, boardAddModal: false };
};

export const addToBoards = (state, action) => {
  const boards = cloneDeep(state.boards);
  action.payload.columns.map((k) => (k.jobs = []));
  boards.unshift(action.payload);
  return { ...state, boards };
};

export const deleteBoard = (state, action) => {
  let boards = cloneDeep(state.boards);
  boards = boards.filter((k) => k.boardId !== action.payload);
  return { ...state, boards };
};

export const boardRename = (state, action) => {
  const boards = cloneDeep(state.boards);
  boards.find((k) => k.boardId === action.payload.boardId).boardName = action.payload.boardName;
  return { ...state, boards };
};

export const addToBoardsJob = (state, action) => {
  const boards = cloneDeep(state.boards);
  let board = boards.find((k) => k.boardId === action.payload.boardId);
  board.columns[action.payload.position[0]].jobs.push(action.payload);
  return { ...state, boards };
};

export const jobStartApplication = (state, action) => {
  const boards = cloneDeep(state.boards);
  let board = boards.find((k) => k.boardId === action.payload.boardId);
  let columns = board.columns;
  dragFromColumnToColumnUpdate(columns, 0, 1, action.payload.position[1]);
  return { ...state, boards };
};

export const boardColumnJobDrag = (state, { payload: { boardId, c1I, c2I, sI, dI, jobId } }) => {
  const boards = cloneDeep(state.boards);
  let board = boards.find((k) => k.boardId === boardId);
  let job = cloneDeep(getJobFromBoard(board, jobId));
  let columns = board.columns;
  if (c1I !== c2I) {
    dragFromColumnToColumn(columns, c1I, c2I, sI, dI);
    return { ...state, boards };
  } else dragFromSingleColumn(columns, c1I, c2I, sI, dI);
  return { ...state, boards };
};

export const boardColumnJobDelete = (state, action) => {
  const boards = cloneDeep(state.boards);
  let board = boards.find((k) => k.boardId === action.payload.boardId);
  let columns = board.columns;
  deleteJob(columns, action.payload.data);
  return { ...state, boards };
};

export const boardColumnJobPush = (state, action) => {
  const boards = cloneDeep(state.boards);
  let board = boards.find((k) => k.boardId === action.payload.boardId);
  let columns = board.columns;
  const column = getColumnByOrder(columns, action.payload.data.position[0]);
  column.jobs.push(action.payload.data);
  return { ...state, boards };
};

export const setIcon = (state, action) => {
  const boards = cloneDeep(state.boards);
  boards.find((k) => k.boardId === action.payload.boardId).icon = action.payload.icon;
  return { ...state, boards };
};

export const updateJob = (state, action) => {
  const boards = cloneDeep(state.boards);
  let board = boards.find((k) => k.boardId === action.payload.boardId);
  let column = getColumnByOrder(board.columns, action.payload.position[0]);
  column.jobs.splice(action.payload.position[1], 1, action.payload);
  return { ...state, boards };
};

export const JobDragByIndex = (state, { payload: { boardId, c1I, c2I, sI, dI, jobId } }) => {
  const boards = cloneDeep(state.boards);
  let board = boards.find((k) => k.boardId === boardId);
  let columns = board.columns;
  dragFromColumnToColumnByIndex(columns, c1I, c2I, sI, dI);
  return { ...state, boards };
};

export const setTask = (state, action) => {
  let data = cloneDeep(state);
  let job = getJobFromTotalBoards(data.boards, action.payload.jobId);
  job.totalTask = action.payload.tasks.length;
  data.tasks = [...action.payload.tasks];
  return { ...data };
};

export const flushTask = (state) => {
  state.tasks = [];
  return { ...state };
};

export const pushTask = (state, action) => {
  let data = cloneDeep(state);
  let jobs = getJobsFromTotalBoards(data.boards, action.payload.jobId);
  const tasks = action.payload.tasks;
  jobs.map((k) => {
    k.totalTask++;
    if (action.payload.isCompleted) k.completedTaskCount++;
    else k.pendingTaskCount++;
  });
  data.tasks = tasks;
  return { ...data };
};

export const deleteTask = (state, action) => {
  let data = cloneDeep(state);
  let jobs = getJobsFromTotalBoards(data.boards, action.payload.jobId);
  jobs.map((k) => k.totalTask--);
  data.tasks = data.tasks.filter((k) => k.taskId !== action.payload.taskId);
  return { ...data };
};

export const deleteMultipleTask = (state, action) => {
  let data = cloneDeep(state);
  action.payload.map((g) => {
    let jobs = getJobsFromTotalBoards(data.boards, g.linkedJobs);
    jobs.map((k) => {
      k.totalTask--;
      if (g.isCompleted) k.completedTaskCount--;
      else k.pendingTaskCount--;
    });
  });
  data.tasks = data.tasks.filter((k) => !action.payload.map((d) => d.taskId).includes(k.taskId));
  return { ...data };
};

export const updateTask = (state, action) => {
  let data = cloneDeep(state);
  let jobs = getJobFromTotalBoards(data.boards, action.payload.linkedJobs[0]);
  jobs.isCompleted = action.payload.isCompleted;
  if (action.payload.isCompleted) {
    jobs.completedTaskCount++;
    jobs.pendingTaskCount--;
  } else {
    jobs.pendingTaskCount++;
    jobs.completedTaskCount--;
  }
  data.tasks.splice(
    data.tasks.findIndex((x) => x.taskId === action.payload.taskId),
    1,
    action.payload,
  );
  return { ...data };
};

export const updateBoard = (state, action) => {
  const data = cloneDeep(state);
  data[action.name] = action.payload;
  return data;
};
