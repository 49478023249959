import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const LinkedinIcon = ({ width, height }) => {
  return (
    <SvgIcon viewBox="0 0 114 114" width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M57.098 113.196c30.982 0 56.098-25.116 56.098-56.098S88.08 1 57.098 1 1 26.116 1 57.098s25.116 56.098 56.098 56.098z"
        stroke="#A79D9D"
        strokeLinecap="round"
        strokeLinejoin="bevel"
        strokeDasharray="7 7"
      />
      <path
        d="M42.613 39C39.73 39 38 40.784 38 43.16c0 2.376 1.73 4.153 4.324 4.153 2.883 0 4.613-1.777 4.613-4.153 0-2.376-1.73-4.16-4.324-4.16zM38.23 51.757V76h8.49V51.757h-8.49zm15.495 0V76h8.49V62.749c0-3.945 2.734-4.512 3.555-4.512.82 0 3.016.848 3.016 4.512V76H77V62.749c0-7.609-3.285-10.992-7.393-10.992s-6.299 1.408-7.392 3.382v-3.382h-8.49z"
        fill="#A79D9D"
      />
    </SvgIcon>
  );
};

const SvgIcon = styled.svg`
  stroke: none;
`;

LinkedinIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

export default LinkedinIcon;
