import React from 'react';

const PreviewSliderIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24">
      <path
        fill="#FFF"
        d="M8 13c2.21 0 4 1.79 4 4s-1.79 4-4 4c-1.864 0-3.43-1.274-3.874-3H2v-2h2.126c.444-1.725 2.01-3 3.874-3zm0 2c-1.105 0-2 .895-2 2s.895 2 2 2 2-.895 2-2-.895-2-2-2zm14 1v2h-8v-2h8zM13 3c2.21 0 4 1.79 4 4s-1.79 4-4 4c-1.864 0-3.43-1.274-3.874-3H2V6h7.126C9.57 4.275 11.136 3 13 3zm0 2c-1.105 0-2 .895-2 2s.895 2 2 2 2-.895 2-2-.895-2-2-2zm9 1v2h-3V6h3z"
      />
    </svg>
  );
};

export default PreviewSliderIcon;
