import styled from 'styled-components';

const SummaryIcon = ({ width = '18px', height = '22px', viewBox = '0 0 18 22' }) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
    <path d="M9 0c1.16 0 2.252.363 3.004 1H13a2 2 0 012 2h1a2 2 0 012 2v15a2 2 0 01-2 2H2a2 2 0 01-2-2V5a2 2 0 012-2h1a2 2 0 012-2h.996C6.748.363 7.839 0 9 0zM3.268 5.001L2 5v15h14V5l-1.268.001A2 2 0 0113 6H5a2 2 0 01-1.732-.999zM11 13v2H5v-2h6zm2-4v2H5V9h8zM9 2c-.81 0-1.515.277-1.843.655l-.3.345H5v1h8V3h-1.858l-.299-.345C10.515 2.277 9.811 2 9 2z" />
  </SvgIcon>
);

const SvgIcon = styled.svg`
  stroke: none;
  fill: currentColor;
`;

SummaryIcon.displayName = 'SummaryIcon';

export default SummaryIcon;
