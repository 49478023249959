import React from 'react';
import styled from 'styled-components';

const RightArrowV2 = () => {
  return (
    <IconCont>
      <SvgIcon width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.9 0.200059C10.3418 -0.131312 10.9686 -0.0417689 11.3 0.400059L16.25 7.00006L11.3 13.6001C10.9686 14.0419 10.3418 14.1314 9.9 13.8001C9.45817 13.4687 9.36863 12.8419 9.7 12.4001L13 8.00006H1.5C0.947715 8.00006 0.5 7.55234 0.5 7.00006C0.5 6.44777 0.947715 6.00006 1.5 6.00006H13L9.7 1.60006C9.36863 1.15823 9.45817 0.53143 9.9 0.200059Z"
          fill="#FFFFFF"
        />
      </SvgIcon>
    </IconCont>
  );
};

const IconCont = styled.span`
  position: absolute;
`;

const SvgIcon = styled.svg`
  stroke: none;
  fill: currentColor;
`;

export default RightArrowV2;
