import React from 'react';
import styled from 'styled-components';

const PrevIcon = ({ color = '#fff' }) => {
  return (
    <SvgIcon width="10" height="16" xmlns="http://www.w3.org/2000/svg">
      <path fill="none" d="M-1-1h12v18H-1z" />
      <g>
        <path
          fill={color}
          d="M7.355 14.645A1 1 0 008.77 13.23l-1.415 1.415zM2.063 7.938l-.708-.708a1 1 0 000 1.415l.708-.707zM8.77 2.645A1 1 0 007.355 1.23L8.77 2.645zm0 10.585l-6-6-1.415 1.415 6 6L8.77 13.23zm-6-4.585l6-6L7.355 1.23l-6 6L2.77 8.645z"
        />
      </g>
    </SvgIcon>
  );
};

const SvgIcon = styled.svg`
  stroke: none;
`;

export default PrevIcon;
