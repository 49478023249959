import styled from 'styled-components';

const Title = styled.h3`
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 30px;
  text-align: center;
  color: #33334f;
  position: relative;
  margin: 40px 0 10px 0;
  @media (max-width: 375px) {
    font-size: 24px;
  }
`;

export default Title;
