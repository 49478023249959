import React from 'react';

const Quality9Icon = () => {
  return (
    <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M38.25 1.95833H12.75C10.4028 1.95833 8.5 3.82381 8.5 6.12499V35.2917C8.5 37.5928 10.4028 39.4583 12.75 39.4583H38.25C40.5972 39.4583 42.5 37.5928 42.5 35.2917V6.12499C42.5 3.82381 40.5972 1.95833 38.25 1.95833Z"
        fill="#ADFADA"
      />
      <path
        d="M37.283 6.13541H13.5361C12.3625 6.13541 11.4111 7.06815 11.4111 8.21874C11.4111 9.36933 12.3625 10.3021 13.5361 10.3021H37.283C38.4566 10.3021 39.408 9.36933 39.408 8.21874C39.408 7.06815 38.4566 6.13541 37.283 6.13541Z"
        fill="#26D496"
      />
      <path
        d="M31.2905 14.4687H13.5361C12.3625 14.4687 11.4111 15.4015 11.4111 16.5521C11.4111 17.7027 12.3625 18.6354 13.5361 18.6354H31.2905C32.4641 18.6354 33.4155 17.7027 33.4155 16.5521C33.4155 15.4015 32.4641 14.4687 31.2905 14.4687Z"
        fill="#26D496"
      />
      <path
        d="M40.375 1.95833H10.625C9.45139 1.95833 8.5 2.89107 8.5 4.04166V37.375C8.5 38.5256 9.45139 39.4583 10.625 39.4583H40.375C41.5486 39.4583 42.5 38.5256 42.5 37.375V4.04166C42.5 2.89107 41.5486 1.95833 40.375 1.95833Z"
        fill="#ADFADA"
      />
      <path
        d="M10.625 1.95833H25.5V39.4583H10.625C9.45625 39.4583 8.5 38.5208 8.5 37.375V4.04166C8.5 2.89583 9.45625 1.95833 10.625 1.95833Z"
        fill="#DDFFF1"
      />
      <path
        d="M25.5 6.13542H37.2831C38.4519 6.13542 39.4081 7.07292 39.4081 8.21876C39.4081 9.36459 38.4519 10.3021 37.2831 10.3021H25.5V6.13542Z"
        fill="#00A369"
      />
      <path
        d="M13.5361 6.13542H25.4999V10.3021H13.5361C12.3674 10.3021 11.4111 9.36459 11.4111 8.21876C11.4111 7.07292 12.3674 6.13542 13.5361 6.13542Z"
        fill="#26D496"
      />
      <path
        d="M13.5361 14.4688H25.4999V18.6354H13.5361C12.3674 18.6354 11.4111 17.6979 11.4111 16.5521C11.4111 15.4063 12.3674 14.4688 13.5361 14.4688Z"
        fill="#26D496"
      />
      <path
        d="M25.5 14.4688H31.28C32.4488 14.4688 33.405 15.4063 33.405 16.5521C33.405 17.6979 32.4488 18.6354 31.28 18.6354H25.5V14.4688Z"
        fill="#00A369"
      />
      <path
        d="M35.0414 33.9271C34.797 28.4687 30.632 24.9062 25.1283 24.9062C19.6245 24.9062 11.2308 29 13.8658 38.0521C10.1364 35.0208 5.76953 38.1979 5.76953 41.875C5.76953 45.5521 8.09641 47.6458 11.847 47.6458H39.217C42.5214 47.6458 46.187 44.4688 46.3039 39.7396C46.4208 35.0208 41.2145 30.8646 35.0414 33.9271Z"
        fill="#0050C7"
      />
      <path
        d="M33.9789 32.4167C33.7345 26.9583 29.5695 23.3958 24.0658 23.3958C18.562 23.3958 10.1683 27.4896 12.8033 36.5417C9.07391 33.5104 4.70703 36.6875 4.70703 40.3646C4.70703 44.0417 7.03391 46.1354 10.7845 46.1354H38.1545C41.4589 46.1354 45.1245 42.9583 45.2414 38.2292C45.3583 33.5104 40.152 29.3542 33.9789 32.4167Z"
        fill="#0866F5"
      />
      <path
        d="M25.5002 23.4896C25.0327 23.4375 24.5545 23.3958 24.0658 23.3958C18.7214 23.3958 10.1683 27.4896 12.8033 36.5417C9.07391 33.5104 4.70703 36.6875 4.70703 40.3646C4.70703 44.0417 7.03391 46.1354 10.7845 46.1354H25.5108V23.4896H25.5002Z"
        fill="#378EFF"
      />
    </svg>
  );
};
export default Quality9Icon;
