import React, { useState } from 'react';

import { useForm, Controller, Form } from 'react-hook-form';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { ERROR_MESSAGES } from '/imports/core/api/constants';
import { useResponsive } from '/imports/core/api/responsiveContext';
import { getRoute, isBlogDomain } from '/lib/helpers';
import { replaceString } from '/imports/core/ui/helpers';
import useIntl from '/imports/core/api/useIntl';
import env from '/env';

import ContactFormStatusMessage from '/imports/core/ui/components/ContactFormStatusMessage.js';
import FieldLabel from 'imports/generator/ui/atoms/FieldLabel';
import ValidatedInput from 'imports/core/ui/atoms/ValidatedInput';

const validateEmail = (value) => {
  if (!value) {
    return ERROR_MESSAGES.required;
  }
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(value)) {
    return ERROR_MESSAGES.email;
  }
  return true;
};

const LoginForm = ({ isPopup }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    getFieldState,
  } = useForm({
    mode: 'all',
  });
  const { t, locale } = useIntl();
  const { host } = useResponsive();

  const [emailNotFound, setEmailNotFound] = useState();
  const [error, setError] = useState(false);
  const [sending, setSending] = useState(false);
  const [success, setSuccess] = useState(false);

  const onSubmit = async (data) => {
    setSending(true);
    const fetchOptions = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await fetch(`${env.BASE_API}/auth/login`, {
        ...fetchOptions,
        body: JSON.stringify({
          email: data.email.toLowerCase(),
          origin: `${window.location.origin}${getRoute('', locale, false, host, isBlogDomain(host))}`,
          language: locale,
        }),
      });

      if (response.status === 404) {
        setEmailNotFound(true);
        setSuccess(false);
        setSending(false);
        return;
      }

      if (response.status > 399) {
        const errMsg = await response.text();
        throw new Error(errMsg);
      }

      setSuccess(true);
      setSending(false);
    } catch (e) {
      setError(e.message);
      setSending(false);
    }
  };

  const errorEmail = replaceString(t(ERROR_MESSAGES.emailNotFound), {
    '/onboard/start': getRoute('/onboard/start', locale, false, host, isBlogDomain(host)),
  });

  return (
    <MainWrapper>
      <Title>{t('signin')}</Title>
      <Info isPopup={isPopup}>{t('signin_heading')}</Info>
      <Forms isPopup={isPopup} control={control} onSubmit={handleSubmit(onSubmit)}>
        <InputWrap isPopup={isPopup}>
          {isPopup ? (
            <FieldLabel label={t('email_addres')} background="transparent" summary></FieldLabel>
          ) : (
            <Label>
              <span>{t('email_addres')}</span>
            </Label>
          )}
          <Controller
            name="email"
            control={control}
            defaultValue={''}
            rules={{ validate: validateEmail }}
            render={({ field }) => (
              <>
                <Input
                  {...field}
                  hideIcon
                  isPopup={isPopup}
                  isValid={getFieldState('email').invalid}
                  placeholder={t('contact.your_email_here')}
                  errorMessageCustomCss={emailNotFound ? errorEmail : t(errors?.email?.message)}
                  customErrorStyle={errorMessageCustomCss}
                  emailNotFound={emailNotFound}
                />
              </>
            )}
          />
        </InputWrap>
        <Button type="submit" disabled={sending || getFieldState('email').invalid}>
          {t('signin')}
        </Button>
      </Forms>
      {(success || !!error) && (
        <ContactFormStatusMessage error={error && !success} message={error || t('signin_success')} />
      )}
    </MainWrapper>
  );
};

LoginForm.prototype = {
  isPopup: PropTypes.bool,
};

const MainWrapper = styled.div`
  width: 100%;
  background-color: var(--light-values-white);
  border-radius: 3px;
  padding: 12px 0 44px 0;
  max-width: 812px;
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      text-align: right;
    `}
  ${(p) => p.theme.max('sm')`
    text-align: center;
  `}

  ${({ isPopup }) =>
    isPopup &&
    css`
      padding: 0;

      & > div:last-child {
        padding-top: 32px;
        padding-bottom: 0;
      }
    `}
`;

const Title = styled.div`
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 20px;
  font-weight: bold;
  color: #282b32;
`;

const Info = styled.div`
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 15px;
  line-height: 1.33;
  color: #7e8592;
  margin-bottom: 15px;

  ${({ isPopup }) =>
    isPopup &&
    css`
      margin-bottom: 32px;
    `}
`;

const Forms = styled(Form)`
  display: flex;
  width: 100%;
  ${(p) => p.theme.max('sm')`
    flex-direction: column;
  `}

  ${({ isPopup }) =>
    isPopup &&
    css`
      flex-direction: column;

      & > button {
        width: 100%;
        background-color: #1688fe;
        border: 1px solid #1688fe;

        ${(p) => p.theme.max('sm')`
        margin-top: 0;
      `}
      }

      & > div {
        width: 100%;
        margin-bottom: 32px;
      }
    `}
`;

const InputWrap = styled.div`
  position: relative;
  width: calc(100% - 328px);

  ${({ isPopup }) => {
    !isPopup &&
      css`
        &:focus-within {
          & > label {
            color: #2274b0;
          }
        }
      `;
  }}

  span {
    margin-top: 7px;
  }
  ${(p) => p.theme.max('sm')`
    width: 100%;
    text-align: left;
  `}

  ${({ isPopup }) =>
    isPopup &&
    css`
      position: relative;
      & > label {
        top: -3px;
        div {
          top: 8px;
        }
      }
    `}
`;

const Input = styled(ValidatedInput)`
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  padding: 14px 16px;
  color: #50575d;
  background: #f0f4f8;
  border-radius: 3px;
  letter-spacing: 0.05px;
  margin-top: 5px;
  border: none;
  ::placeholder {
    color: #b0b9c1;
  }

  ${({ isPopup }) =>
    isPopup &&
    css`
      color: #484870;
      border-radius: 3px;
      border: solid 2px #e6e6ff;
      background-color: var(--light-values-white);
    `}
`;

const Button = styled.button`
  margin-left: 16px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 71px 11px;
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 16px;
  font-weight: 600;
  border-color: #dbdfe2;
  color: #92a5b6;
  align-self: flex-end;
  cursor: pointer;
  touch-action: manipulation;
  border: 1px solid #0087c1;
  transition: all 0.2s;
  background-color: #0087c1;
  color: var(--light-values-white);
  &:disabled {
    background: #dbdfe2;
    border-color: #dbdfe2;
    cursor: default;
    color: #92a5b6;
  }
  ${(p) => p.theme.max('sm')`
    width: 100%;
    margin-top: 30px;
  `}
`;

const Label = styled.label`
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 14px;
  color: #7e8592;
  margin-bottom: 7px;
`;

const customInputStripeCss = css`
  border: 2px solid #ff2d2d;
`;

const errorMessageCustomCss = css`
  color: #ff2d2d;
  position: static;
  span {
    font-size: 11px;
  }
`;

export default LoginForm;
