import styled from 'styled-components';

const ImageBlock = styled.div`
  position: relative;
  overflow: hidden;
  width: 40.5%;
  border-radius: 16px;
  @media (max-width: 767px) {
    width: 60%;
  }
  @media (max-width: 479px) {
    width: auto;
  }
`;
export default ImageBlock;
