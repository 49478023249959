import React, { PureComponent } from 'react';
import Datepicker from './Datepicker';
import PropTypes from 'prop-types';

import Autosave from '/imports/core/ui/components/Autosave';

class DatepickerAutosave extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    labelSlug: PropTypes.string,
    language: PropTypes.string,
  };

  render() {
    const { className, name, placeholder, value, disabled, label, labelSlug, language } = this.props;
    return (
      <Autosave {...this.props}>
        {({ onChange }) => (
          <Datepicker
            language={language}
            className={className}
            onChange={onChange}
            placeholder={placeholder}
            name={name}
            defaultvalue={value || ''}
            disabled={disabled}
            label={label}
            labelSlug={labelSlug}
            {...this.props}
          />
        )}
      </Autosave>
    );
  }
}

DatepickerAutosave.displayName = 'DatepickerAutosave';

export default DatepickerAutosave;
