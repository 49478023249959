import React, { PureComponent } from 'react';
import 'croppie/croppie.css';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Button from '/imports/core/ui/atoms/Button';
import Flex from '/imports/core/ui/atoms/Flex';
import { rangeInputStyles } from '/imports/core/ui/mixins';
import SvgIcon from '/imports/core/ui/atoms/SvgIcon';
import { withIntl } from '/imports/core/api/useIntl';

@withIntl
class PhotoCrop extends PureComponent {
  static propTypes = {
    cropSize: PropTypes.number.isRequired,
    onChange: PropTypes.func,
    image: PropTypes.string,
    onSave: PropTypes.func,
    t: PropTypes.func,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    cropSize: 200,
  };

  state = {
    croppie: null,
  };

  componentDidMount() {
    const { cropSize } = this.props;
    // Have to require Croppie manually to prevent Next.js SSR builds
    // crashing due to Croppie's `window` requirement.
    if (typeof window !== 'undefined') {
      const Croppie = require('croppie');
      const croppie = new Croppie(document.getElementById('photo'), {
        viewport: {
          width: cropSize,
          height: cropSize,
        },
        boundary: {
          height: cropSize,
        },
        mouseWheelZoom: false,
        enableOrientation: true,
      });

      croppie.bind({ url: this.props.image }).then(() => {
        croppie.setZoom(0);
      });

      this.setState({ croppie });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.image !== this.props.image && this.state.croppie) {
      const { croppie } = this.state;
      croppie.bind({ url: this.props.image }).then(() => {
        croppie.setZoom(0);
      });
    }
  }

  componentWillUnmount() {
    if (this.state.croppie) this.state.croppie.destroy();
  }

  save = () => {
    const { onSave } = this.props;
    const { croppie } = this.state;

    if (croppie) {
      croppie
        .result({
          type: 'canvas',
          size: 'original',
        })
        .then((data) => {
          onSave(data);
        });
    }
  };

  onRotate = () => {
    const { croppie } = this.state;
    if (croppie) croppie.rotate(90);
  };

  onClick = () => {
    this._input.click();
  };

  getInput = (node) => {
    this._input = node;
  };

  render() {
    const { t, onChange } = this.props;
    return (
      <div>
        <Container>
          <div id="photo" />
        </Container>
        <Zoom>
          <Label>{t('generator.upload_zoom_image')}</Label>
          <Icons alignItems="center" justifyContent="space-between">
            <SvgIcon.SliderIconSmall width="16" height="16" viewBox="0 0 16 16" />
            <SvgIcon.SliderIconBig width="16" height="16" viewBox="0 0 16 16" style={{ transform: 'scale(1.5)' }} />
          </Icons>
        </Zoom>
        <Controls alignItems="center" justifyContent="space-between">
          <StyledFlex>
            <SecondaryButton onClick={this.onClick} outline>
              <SvgIcon.UpdatePhoto />
              {t('generator.upload_new_photo')}
            </SecondaryButton>
            <RotateButton onClick={this.onRotate} outline>
              <SvgIcon.Rotate />
              {t('generator.upload_rotate')}
            </RotateButton>
          </StyledFlex>
          <SaveButton onClick={this.save} disabled={this.props.loading}>
            {t('checkout.save')}
          </SaveButton>
        </Controls>
        <UploadInput
          ref={this.getInput}
          type="file"
          accept="image/jpeg, image/x-png, .jpg, .jpeg, .png"
          required
          onChange={onChange}
        />
      </div>
    );
  }
}

const UploadInput = styled.input`
  display: none;
`;

const Container = styled.div`
  .cr-slider-wrap {
    margin-top: 30px !important;
    ${({ theme: { isRTL } }) =>
      isRTL &&
      css`
        direction: rtl;
      `}
  }

  .cr-viewport {
    border: 2px solid black;
    box-shadow: 0 0 2000px 2000px rgba(247, 247, 252, 0.8);
  }

  ${rangeInputStyles}
`;

const Zoom = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -70px;
  pointer-events: none;
`;

const Controls = styled(Flex)`
  position: relative;
  margin-top: 30px;

  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
`;

const StyledFlex = styled(Flex)`
  ${({ theme }) => theme.max('xs')`
    align-items: stretch;
    width: 100%;
    justify-content: space-between;
  `}
`;

const SecondaryButton = styled(Button)`
  display: flex;
  align-items: center;
  border: solid 2px #e6e6ff;
  color: #7171a6;

  svg {
    margin-right: 4px;
    color: #7171a6;

    ${({ theme: { isRTL } }) =>
      isRTL &&
      css`
        margin-left: 4px;
        margin-right: 0;
      `}
  }

  &:hover svg {
    color: #016fe0;
  }

  ${({ theme }) =>
    theme.max('xs')`
    padding: 0.8em 0.7em 0.6em;
    font-size: 15px;
    width: 60%;
    justify-content: center;
    svg {
      margin-right: 9px;
      margin-top: -5px;
    }
  `}
`;

const RotateButton = styled(SecondaryButton)`
  margin-left: 10px;
  ${({ theme }) =>
    theme.max('xs')`
    width: 35%;
    margin: 0;
  `}

  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      margin-left: 0;
      margin-right: 10px;
    `}

    svg {
    ${({ theme: { isRTL } }) =>
      isRTL &&
      css`
        margin-left: 4px;
        margin-right: 0;
        transform: scaleX(-1);
      `}
  }
`;

const Icons = styled((p) => <Flex {...p} />)`
  padding: 0 115px;
  color: #7171a6;

  ${({ theme }) => theme.max('xs')`
    margin-left: 0;
    margin-top: 10px;
    width: 100%;
    padding: 0;
    margin-top: 0;
  `}
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
`;

const SaveButton = styled(Button)`
  font-family: ${({ theme }) => theme.font.family.websiteBold};
  font-weight: normal;
  z-index: 20;
  padding: 15px 30px;

  ${({ theme }) => theme.max('xs')`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    top: 65px;
  `}
`;

const Label = styled.p`
  text-align: center;
  font-size: 12px;
  color: #7171a6;
  margin-bottom: 0;
`;

export default PhotoCrop;
