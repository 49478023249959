import ReferencesIconV3 from './ReferencesIconV3';
import IconsExperiment from './IconsExperiment';

const ReferencesIconExperiment = (props) => {
  return <IconsExperiment iconV3={<ReferencesIconV3 {...props} />} />;
};

ReferencesIconExperiment.displayName = 'ReferencesIconExperiment';

export default ReferencesIconExperiment;
