import { getAllBoardJob, getTotalBoardJobs } from '/imports/job-tracking/api/helper';

//get Job From Board
export const getJobFromBoard = (board, jobId) => {
  const jobs = getTotalBoardJobs(board);
  return jobs.find((k) => k.jobId === jobId);
};

export const getJobFromTotalBoards = (boards, jobId) => {
  const jobs = getAllBoardJob(boards);
  return jobs.find((k) => k.jobId === jobId);
};

export const getJobsFromTotalBoards = (boards, jobs) => {
  const data = getAllBoardJob(boards);
  return data.filter((k) => jobs.includes(k.jobId));
};

//get column
export const getColumn = (array, id) => array.find((k) => k.column === id);

//get column by order
export const getColumnByOrder = (array, order) => array.find((k) => k.order === order);

//get job
export const getJob = (column, id) => column.jobs.find((k) => k.position[1] === id);

//get job from column
export const getJobFromColumn = (array, columnIndex, jobIndex) => getJob(getColumn(array, columnIndex), jobIndex);

//get job from column from order
export const getJobFromColumnOrder = (array, order, jobIndex) => getJob(getColumnByOrder(array, order), jobIndex);

//remove job from column
export const removeJobFromColumn = (column, sourceIndex) => {
  column.jobs = column.jobs.filter((k) => k.position[1] !== sourceIndex);
  column.jobs.filter((g) => g.position[1] > sourceIndex).map((l) => l.position[1]--);
};

//add job to column
export const addJobToColumn = (column, destinationIndex, data) => {
  column.jobs.filter((g) => g.position[1] >= destinationIndex).map((l) => l.position[1]++);
  data.position = [column.order, destinationIndex];
  column.jobs.push(data);
};

export const addJobToColumnLast = (column, data) => {
  data.position = [column.order, column.jobs.length];
  column.jobs.push(data);
};

//drag from one column to another (array, column1id, column2id, sourceIndex, destinationIndex)
export const dragFromColumnToColumn = (array, c1I, c2I, sI, dI) => {
  const data = getJobFromColumn(array, c1I, sI);
  removeJobFromColumn(getColumn(array, c1I), sI);
  addJobToColumn(getColumn(array, c2I), dI, data);
};

//drag from one column to another by index (array, column1id, column2id, sourceIndex, destinationIndex)
export const dragFromColumnToColumnByIndex = (array, sColumnIndex, dColumnIndex, sI, dI) => {
  const data = getJobFromColumnOrder(array, sColumnIndex, sI);
  removeJobFromColumn(getColumnByOrder(array, sColumnIndex), sI);
  addJobToColumn(getColumnByOrder(array, dColumnIndex), dI, data);
};

//drag data from single column
export const dragFromSingleColumn = (array, c1I, c2I, sI, dI) => {
  const column = getColumn(array, c2I);
  const isUpper = sI > dI;
  const object = getJob(column, sI);
  if (isUpper) column.jobs.filter((k) => k.position[1] >= dI && k.position[1] < sI).map((j) => j.position[1]++);
  else column.jobs.filter((k) => k.position[1] <= dI && k.position[1] > sI).map((j) => j.position[1]--);
  object.position[1] = dI;
};

//drag data from one column to another at the last position
export const dragFromColumnToColumnUpdate = (array, s1, d1, sI) => {
  const job = getJobFromColumnOrder(array, s1, sI);
  removeJobFromColumn(getColumnByOrder(array, s1), sI);
  addJobToColumnLast(getColumnByOrder(array, d1), job);
};

//reorder jobs
export const reOrderJobs = (array) => array.map((k) => k.jobs.map((j) => (j.position[0] = k.order)));

//drag columns
export const dragColumn = (array, from, to) => {
  const column = getColumnByOrder(array, from);
  const isRight = from < to;
  if (isRight) array.filter((k) => k.order > from && k.order <= to).map((j) => j.order--);
  else array.filter((k) => k.order < from && k.order >= to).map((j) => j.order++);
  column.order = to;
  reOrderJobs(array);
};

//delete job from column directly
export const deleteJob = (array, job) => {
  const column = getColumnByOrder(array, job.position[0]);
  removeJobFromColumn(column, job.position[1]);
};
