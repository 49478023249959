import React from 'react';

const AlertTriangleIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path
      fill="#E84C85"
      fillRule="evenodd"
      d="M12.004 2c1.418.002 2.729.758 3.432 1.978l7.006 11.975c.729 1.233.744 2.76.04 4.007-.704 1.249-2.02 2.024-3.47 2.038H4.974c-1.434-.011-2.753-.79-3.458-2.039-.704-1.248-.687-2.78.04-4.001l7.007-11.98c.712-1.226 2.024-1.98 3.441-1.978zm-.002 2c-.704 0-1.356.374-1.71.985L3.277 16.974c-.364.611-.372 1.378-.02 2.003.353.626 1.014 1.016 1.725 1.021h14.02c.726-.007 1.385-.395 1.738-1.02.352-.625.344-1.39-.023-2.01L13.707 4.981c-.35-.605-1-.981-1.705-.982zM12 15.998c.553 0 1 .448 1 1 0 .553-.447 1-1 1-.552 0-1-.447-1-1 0-.552.448-1 1-1zm1.004-8v7h-2.001v-7h2z"
    />
  </svg>
);

export default AlertTriangleIcon;
