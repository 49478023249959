import styled, { css } from 'styled-components';

export const FormGroup = styled.div`
  padding: 5px 0;
  margin-bottom: 10px;

  > label {
    margin-bottom: 12px;
  }
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
`;

export default FormGroup;
