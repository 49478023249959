import styled from 'styled-components';

const StyledH2 = styled.h2`
  margin: 0;
  color: #14141f;
  font-size: 40px;
  line-height: 47px;
  font-weight: 500;
  font-family: Gilroy;
  @media (max-width: 767px) {
    margin-top: 0px;
  }
  @media (max-width: 479px) {
    font-size: 24px;
    line-height: 32px;
  }
`;
export default StyledH2;
