import styled, { css } from 'styled-components';

import View from '/imports/pdf/core/ui/atoms/View';

const BlockItem = styled(View)`
  ${({ theme, dateView }) => css`
    margin-bottom: ${theme.convertPx(50)};
    ${dateView &&
    css`
      > div:first-child {
        margin-bottom: ${theme.convertPx(30)};
      }
    `}
  `}
  &:last-child {
    margin-bottom: 0;
  }
`;

export default BlockItem;
