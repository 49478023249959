import gql from 'graphql-tag';

import { resumeFull, coverLetterFull } from '/imports/generator/api/apollo/client/fragments';

export const GET_RESUME = gql`
  query ($resumeId: ID!) {
    getResume(resumeId: $resumeId) {
      ...ResumeFull
    }
  }
  ${resumeFull}
`;

export const GET_COVER_LETTER = gql`
  query ($id: ID!) {
    get(id: $id) {
      ...CoverLetterFull
    }
  }
  ${coverLetterFull}
`;

export const GET_JOBS = gql`
  query ($search: String!, $lang: String!) {
    getJobs(search: $search, lang: $lang) {
      title
    }
  }
`;

export const GET_SUGGESTIONS = gql`
  query ($job: String!, $type: String!, $lang: String!) {
    getSuggestions(job: $job, type: $type, lang: $lang) {
      _id
      text
    }
  }
`;

export const GET_SCHOOLS = gql`
  query ($search: String!) {
    getSchools(search: $search) {
      name
      city
    }
  }
`;

export const GET_EXPANDED_ITEM = gql`
  query isExpanded {
    expandedItem @client
  }
`;

// create queries file for PDF section and move the following query (update imports as well)

export const GET_SAVING_STATUS = gql`
  query isSaving {
    savingStatus @client
  }
`;
