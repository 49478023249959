import React from 'react';
import styled from 'styled-components';

const PublicationIcon = () => {
  return (
    <SvgIcon viewBox="0 0 20 18" xmlns="http://www.w3.org/2000/svg" width="20" height="22">
      <path
        d="M16 0v5h4v10a3 3 0 01-3 3H3a3 3 0 01-3-3V0h16zm-2 2H2v13a1 1 0 001 1h11.171A2.995 2.995 0 0114 15V2zm4 5h-2v8a1 1 0 002 0V7zm-6 5v2H4v-2h8zm0-3v2H4V9h8zM8 4v4H4V4h4zm4 1v2H9V5h3z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
};

PublicationIcon.displayName = 'RightToLeft';

const SvgIcon = styled.svg`
  stroke: none;
`;

export default PublicationIcon;
