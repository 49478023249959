import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useForm, Controller, Form } from 'react-hook-form';

import Button from '/imports/core/ui/atoms/Button';

import FieldLabel from '/imports/generator/ui/atoms/FieldLabel';
import Modal from '/imports/core/ui/atoms/Modal';
import useIntl from '/imports/core/api/useIntl';
import ValidatedInput from '/imports/core/ui/atoms/ValidatedInput';
import { validateEmail } from 'imports/core/api/validators';

const modalStyles = {
  modalBackdrop: {
    backgroundColor: 'rgba(40, 43, 50, 0.1)',
  },
};

function EmailDownloadModal({ toggleModal, sendEmail, open, email, isRTL }) {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    getFieldState,
  } = useForm({
    mode: 'all',
    defaultValues: { email },
  });
  const { t } = useIntl();
  const [signlePage, setSinglePage] = useState('true');

  const handleChangeExportType = (e) => {
    return setSinglePage(e.target.dataset.value);
  };

  const onSubmit = async (data) => {
    if (isValid) await sendEmail(data.email, signlePage, isRTL);
  };

  return (
    <StyledModal onClose={toggleModal} styles={modalStyles} openStateBase open={open} animation="fromBottom">
      <ContentWrapper>
        <Form onSubmit={handleSubmit(onSubmit)} control={control}>
          <Header>{t('header.export.confirm_email')}</Header>
          <FieldWrapper>
            <FieldLabel label={t('generator.heading.email')} background="#fff" />
            <Controller
              name="email"
              control={control}
              defaultValue={''}
              rules={{ validate: validateEmail }}
              render={({ field }) => (
                <ValidatedInput
                  id="email"
                  field={field}
                  isValid={getFieldState('email').invalid}
                  placeholder="you@example.com"
                  errorMessageCustomCss={t(errors?.email?.message)}
                  customErrorStyle={errorMessageCustomCss}
                />
              )}
            />

            <ExportWrapper>
              <ExportType active={signlePage === 'true'} data-value="true" onClick={handleChangeExportType}>
                {t('dashboard.export_1_page_pdf')}
              </ExportType>
              <ExportType active={signlePage === 'false'} data-value="false" onClick={handleChangeExportType}>
                {t('dashboard.export_a4_pdf')}
              </ExportType>
            </ExportWrapper>
          </FieldWrapper>
          <FooterWrapper>
            <DownloadLink type="submit">{t('header.send_pdf')}</DownloadLink>
          </FooterWrapper>
        </Form>
      </ContentWrapper>
    </StyledModal>
  );
}

export default EmailDownloadModal;

EmailDownloadModal.propTypes = {
  toggleModal: PropTypes.func,
  open: PropTypes.bool,
  sendEmail: PropTypes.func,
  email: PropTypes.string,
};

const ExportWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
`;
const ExportType = styled.div`
  padding: 15px;
  ${({ active }) =>
    active &&
    css`
      border: 1px solid;
      padding: 14px;
    `}
`;

const FieldWrapper = styled.div`
  position: relative;
`;

const StyledModal = styled(Modal)`
  top: auto;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`;

const DownloadLink = styled(Button)`
  padding: 13px;
  border-radius: 3px;
  background-color: #1688fe;
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 16px;
  font-weight: bold;
  color: var(--light-values-white);
  width: 100%;
  text-align: center;
`;

const FooterWrapper = styled.div`
  display: flex;
  margin-top: 26px;
`;

const Label = styled.div`
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #747baa;
  margin-top: 12px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  color: #282b32;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 31px;
`;

const ContentWrapper = styled.div`
  padding: 32px 20px 24px;
  background-color: #fff;
`;

const errorMessageCustomCss = css`
  color: #ff2d2d;
  position: static;
  span {
    font-size: 11px;
  }
`;
