import styled, { css } from 'styled-components';

import View from '/imports/pdf/core/ui/atoms/View';

const BlockNestedItem = styled(View)`
  ${({ theme: { convertPx, isRTL } }) => css`
    padding-left: ${convertPx(15)};
    padding-bottom: ${convertPx(15)};
    ${isRTL &&
    css`
      padding-left: 0;
      padding-right: ${convertPx(15)};
    `}
  `}
`;

export default BlockNestedItem;
