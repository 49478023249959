import React from 'react';
import styled from 'styled-components';

const TrashIcon = () => {
  return (
    <SvgIcon width="16" height="16" xmlns="http://www.w3.org/2000/svg">
      <g fill="#429FF0" fillRule="nonzero">
        <path d="M10.64 5.718l-1.267-.044-.274 7.228 1.266.044zM6.922 5.696H8.19v7.228H6.922zM6.012 12.902l-.274-7.229-1.267.045.274 7.228z" />
        <path d="M.012 2.683v1.221h1.321L2.381 15.38a.625.625 0 00.631.558h9.065a.625.625 0 00.632-.558l1.048-11.476h1.342V2.683H.012zm11.485 12.033H3.593L2.606 3.904h9.878l-.987 10.812zM9.616 0h-4.12c-.58 0-1.052.41-1.052.916v1.74h1.262V1.1h3.7v1.557h1.26V.916c0-.505-.47-.916-1.05-.916z" />
      </g>
    </SvgIcon>
  );
};

const SvgIcon = styled.svg`
  stroke: none;
`;

export default TrashIcon;
