import styled from 'styled-components';

import LinkAtom from '/imports/pdf/core/ui/atoms/Link';

const Link = styled(LinkAtom)`
  font-family: ${({ theme }) => theme.contentFont()};
  font-size: ${({ theme }) => theme.convertPx(12, true)};
  line-height: 1.75;
  color: #58595b;
`;

export default Link;
