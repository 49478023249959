import cloneDeep from 'lodash/cloneDeep';

export const setData = (state, action) => action.payload;

export const updateState = (state, action) => {
  const data = cloneDeep(state);
  data[action.name] = action.payload;
  return data;
};

export const openWishlistModal = (state, action) => {
  const data = cloneDeep(state);
  data.openWishlistModal = action.payload;
  return data;
};

export const closeWishlistModal = (state) => {
  return { ...state, openWishlistModal: null };
};

export const updateApplyState = (state, action) => {
  return { ...state, applyingState: action.payload };
};
