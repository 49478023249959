import React from 'react';
import styled from 'styled-components';
import { staticFile } from 'lib/helpers';

import BasicContainer from '/imports/homepageV3/ui/atoms/BasicContainer';
import BasicSectionWrapper from '/imports/homepageV3/ui/atoms/BasicSectionWrapper';
import CardHeadText from '/imports/homepageV3/ui/atoms/CardHeadText';
import CardParagraph from '/imports/homepageV3/ui/atoms/CardParagraph';
import { getCardIcons } from '/imports/homepageV3/ui/atoms/svg/getCardIcons';
import StyledImage from '/imports/homepageV3/ui/atoms/StyledImage';
import useIntl from '/imports/core/api/useIntl';

const Section4 = () => {
  const { t } = useIntl();
  const QUALITIES = [
    {
      icon: getCardIcons('resume-builder'),
      headtext: t(`resumizeme_landing_sec4_quality12_headtext`),
      paragraph: t(`resumizeme_landing_sec4_quality12_paragraph`),
    },
    {
      icon: getCardIcons('spelling'),
      headtext: t(`resumizeme_landing_sec4_quality13_headtext`),
      paragraph: t(`resumizeme_landing_sec4_quality13_paragraph`),
    },
    {
      icon: getCardIcons('auto-written-phrase-2'),
      headtext: t(`resumizeme_landing_sec4_quality14_headtext`),
      paragraph: t(`resumizeme_landing_sec4_quality14_paragraph`),
    },
    {
      icon: getCardIcons('export'),
      headtext: t(`resumizeme_landing_sec4_quality15_headtext`),
      paragraph: t(`resumizeme_landing_sec4_quality15_paragraph`),
    },
  ];
  return (
    <BasicSectionWrapper>
      <Section4Container>
        <Grid>
          {[0, 1].map((index) =>
            index === 0 ? (
              <CardWrapper key={index}>
                <IconWrapper>{QUALITIES[index].icon}</IconWrapper>
                <CardHeadText>{QUALITIES[index].headtext}</CardHeadText>
                <CardParagraph>{QUALITIES[index].paragraph}</CardParagraph>
              </CardWrapper>
            ) : (
              <SpecificCardWrapperPosition key={index}>
                <IconWrapper>{QUALITIES[index].icon}</IconWrapper>
                <CardHeadText>{QUALITIES[index].headtext}</CardHeadText>
                <CardParagraph>{QUALITIES[index].paragraph}</CardParagraph>
              </SpecificCardWrapperPosition>
            ),
          )}
          <MainBackground>
            <LeftImage
              src={staticFile('img/landingv3/left-1.png')}
              loading="lazy"
              sizes="(max-width: 479px) 87vw, (max-width: 767px) 44vw, (max-width: 991px) 33vw, 28vw"
              $srcset={`${staticFile('img/landingv3/left-1-p-500.png')} 410w, ${staticFile(
                'img/landingv3/left-1-p-800.png',
              )}  800w `}
              alt="Left-1"
            />
            <EllispesImage src={staticFile('img/landingv3/Group-29.svg')} loading="lazy" alt="Ellipses" />
          </MainBackground>
          {[2, 3].map((index) => (
            <CardWrapper key={index}>
              <IconWrapper>{QUALITIES[index].icon}</IconWrapper>
              <CardHeadText>{QUALITIES[index].headtext}</CardHeadText>
              <CardParagraph>{QUALITIES[index].paragraph}</CardParagraph>
            </CardWrapper>
          ))}
        </Grid>
      </Section4Container>
    </BasicSectionWrapper>
  );
};

const Section4Container = styled(BasicContainer)`
  margin-top: 45px;
  margin-bottom: 87px;
  @media (max-width: 991px) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  @media (max-width: 991px) {
    margin-top: 0;
    margin-bottom: 66px;
  }
  @media (max-width: 479px) {
    margin-top: 0;
    margin-bottom: 0;
  }
`;
const Grid = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 56px;
  grid-row-gap: 64px;
  grid-template-columns: 1fr 1.6fr 1fr;
  grid-template-rows: auto auto;
  @media (max-width: 767px) {
    grid-column-gap: 0;
    grid-row-gap: 0;
    display: flex;
    padding: 0;
    justify-content: space-around;
    flex-wrap: wrap;
    grid-template-columns: 1fr 1fr;
  }
`;
const CardWrapper = styled.div`
  @media (max-width: 767px) {
    width: 44%;
    order: -1;
  }
  @media (max-width: 479px) {
    width: auto;
    margin-bottom: 16px;
  }
`;
const SpecificCardWrapperPosition = styled(CardWrapper)`
  grid-column-end: 4;
  grid-column-start: 3;
  grid-row-end: 2;
  grid-row-start: 1;
`;
const IconWrapper = styled.div`
  max-height: 46px;
  max-width: 46px;
  margin-bottom: 24px;
`;
const MainBackground = styled.div`
  grid-column-start: span 1;
  grid-column-end: span 1;
  grid-row-start: span 2;
  grid-row-end: span 2;
  align-self: start;
  position: relative;
  border-radius: 16px;
  box-shadow: 0 0 40px 0 rgba(0, 67, 166, 0.12);
  @media (max-width: 991px) {
    position: relative;
    align-self: center;
  }
  @media (max-width: 767px) {
    width: 50%;
    position: relative;
    align-self: center;
  }
  @media (max-width: 479px) {
    width: auto;
  }
`;
const LeftImage = styled(StyledImage)`
  grid-column-start: span 1;
  grid-column-end: span 1;
  grid-row-start: span 1;
  grid-row-end: span 1;
`;
const EllispesImage = styled(StyledImage)`
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: -1;
  transform: scale(2);
  @media (max-width: 479px) {
    display: none;
  }
`;
export default Section4;
