import { useState, useEffect } from 'react';

const useMountedState = () => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  // A function that conditionally renders content based on the mounted state
  const renderIfMounted = (content) => (mounted ? content : null);

  return { mounted, renderIfMounted };
};

export default useMountedState;
