import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import LinkAtom from '/imports/pdf/budapest/ui/atoms/Link';
import TextAtom from '/imports/pdf/budapest/ui/atoms/Text';
import useIntl from '/imports/core/api/useIntl';
import View from '/imports/pdf/core/ui/atoms/View';
import EditRedirectionWrapper from 'imports/pdf/core/ui/components/EditRedirectionWrapper';
import { getClickToEditProps, formatUrl } from 'imports/pdf/core/api/helpers';

const ListItemDisplay = ({ title, src, type, language, left, value, translateTitle, itemId, fieldsName }) => {
  const { t } = useIntl();
  return (
    <Wrapper left={left}>
      {title && (
        <EditRedirectionWrapper
          {...getClickToEditProps(itemId, { title }, fieldsName)}
          hidden={fieldsName?.title === null}
        >
          <Text type={type} left={left} title={Number(!!title)}>
            {translateTitle ? t(title, language) : title}
          </Text>
        </EditRedirectionWrapper>
      )}
      {src && (
        <EditRedirectionWrapper {...getClickToEditProps(itemId, { src }, fieldsName)}>
          <Link
            href={formatUrl(src)}
            target="_blank"
            rel="noopener noreferrer"
            type={type}
            left={left}
            $noRedirection={typeof window !== 'undefined'}
          >
            {src}
          </Link>
        </EditRedirectionWrapper>
      )}
      {value && (
        <EditRedirectionWrapper {...getClickToEditProps(itemId, { value }, fieldsName)}>
          <Text type={type} left={left}>
            {value}
          </Text>
        </EditRedirectionWrapper>
      )}
    </Wrapper>
  );
};

ListItemDisplay.propTypes = {
  title: PropTypes.string,
  src: PropTypes.string,
  type: PropTypes.string,
  language: PropTypes.string,
  left: PropTypes.bool,
  value: PropTypes.string,
  translateTitle: PropTypes.bool,
};

const Wrapper = styled(View)`
  display: block;
  page-break-inside: avoid !important;
  break-inside: avoid !important;
`;

const Text = styled(TextAtom)`
  color: #808183;
  word-break: break-word;
  ${({ theme: { color, setGenderStyle, convertPx }, type, title, left }) => css`
    ${title &&
    css`
      color: #636466;
      font-weight: 600;
    `}
    ${left &&
    css`
      color: #e6e7e8;
      ${color &&
      color === 'budapestGirlBlue' &&
      css`
        color: #bcbec0;
      `}
    `}
    ${type === 'cover-letter' &&
    css`
      font-size: ${convertPx(13.5, true)};
      line-height: 1.18;
    `}
  `}
`;

const Link = styled(LinkAtom)`
  ${({ $noRedirection }) =>
    $noRedirection &&
    css`
      pointer-events: none;
    `}
  color: #808183;
  word-break: break-all;
  text-decoration: underline;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ left, theme: { color, setGenderStyle, convertPx }, type }) => css`
    ${left &&
    css`
      color: #e6e7e8;
      ${color &&
      color === 'budapestGirlBlue' &&
      css`
        color: #bcbec0;
      `}s
    `}
    ${type === 'cover-letter' &&
    css`
      font-size: ${convertPx(12, true)};
      text-decoration: none;
    `}
  `}
`;

export default ListItemDisplay;
