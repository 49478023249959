import React from 'react';
import styled from 'styled-components';

const UserDefaultIcon = () => {
  return (
    <SvgIcon width="512" height="512" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="256" cy="256" r="256" fill="#F1F4F9" />
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="512" height="512">
        <circle cx="256" cy="256" r="256" fill="white" />
      </mask>
      <g mask="url(#mask0)">
        <path
          d="M71.1111 429.253V512H256V91.1516C208.162 85.9797 173.468 133.603 174.545 153.859V220.445C153.859 236.606 174.114 262.896 182.949 266.344C185.018 293.754 200.62 312.673 208.162 318.707V352.323C207.127 381.802 180.148 390.465 166.788 391.111H127.354C102.012 392.146 79.2996 416.97 71.1111 429.253Z"
          fill="#CFD3DA"
        />
        <path
          d="M440.242 429.202V512H255.354V90.8953C303.192 85.7202 337.886 133.372 336.808 153.641V220.267C357.495 236.438 337.239 262.744 328.404 266.194C326.335 293.621 310.734 312.552 303.192 318.589V352.226C304.226 381.723 331.205 390.391 344.566 391.037H384C409.341 392.072 432.054 416.912 440.242 429.202Z"
          fill="#CFD3DA"
        />
      </g>
    </SvgIcon>
  );
};

const SvgIcon = styled.svg`
  stroke: none;
  max-width: 100%;
  height: auto;
`;

export default UserDefaultIcon;
