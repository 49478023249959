import React from 'react';
import styled, { css } from 'styled-components';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import Container from '/imports/core/ui/atoms/Container';
import { useAccount } from '/imports/core/api/accounts/accountContext';

const DashboardHeadingTabs = dynamic(() => import('/imports/dashboard/ui/components/DashboardHeadingTabs'), {
  ssr: false,
});

const DashboardSkeleton = () => {
  const {
    query: { page },
  } = useRouter();
  const user = useAccount();
  return (
    <Main>
      <ContainerWrapper>
        <DashboardHeadingTabs
          tabIndex={page === 'cover-letters' ? '1' : page === 'sites' ? '2' : '0'}
          handleTabClick={null}
          handleCreateClick={null}
          role={user?.currentUser?.role}
          freeAccessUntil={user?.currentUser?.freeAccessUntil}
        />
      </ContainerWrapper>
    </Main>
  );
};

export default DashboardSkeleton;

const Main = styled.div`
  padding: 65px 0;
  display: flex;
  background-color: rgb(240, 240, 242);
  flex-direction: column;
  min-height: calc((100vh - 75px));
  ${({ theme: { max, general } }) => css`
    ${max('md')`
      margin-top: ${general.headerHeight};
    `}
    ${max('sm')`
      padding: 40px 20px 48px;
      margin-bottom: 20px;
    `}
  `}
`;

const ContainerWrapper = styled(Container)`
  max-width: 1060px;
  margin: 0 auto;
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
`;
