import styled from 'styled-components';

const DescriptionImageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 991px) {
    justify-content: flex-start;
    gap: 64px;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    grid-row-gap: 64px;
  }
  @media (max-width: 479px) {
    gap: 32px;
  }
`;
export default DescriptionImageWrapper;
