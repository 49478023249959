import { css } from 'styled-components';

import { staticFile } from '/lib/helpers';

const Signature = css`
  @font-face {
    font-family: 'Adeline';
    src: url(${staticFile('fonts/Adeline-Regular.ttf')});
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Fengardom';
    src: url(${staticFile('fonts/fengardom-Regular.ttf')});
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Salt Lake';
    src: url(${staticFile('fonts/SaltLake-Regular.ttf')});
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Stone Soul';
    src: url(${staticFile('fonts/StoneSoul-Regular.ttf')});
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Stronger';
    src: url(${staticFile('fonts/Stronger-Regular.ttf')});
    font-weight: 400;
    font-style: normal;
  }
`;

export default Signature;
