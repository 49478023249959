import React from 'react';

const UpArrowLargeIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24">
      <path
        fill="#BFC5E0"
        fillRule="evenodd"
        d="M13 5.414L13 22 11 22 11 5.414 4.707 11.707 3.293 10.293 12 1.586 20.707 10.293 19.293 11.707z"
      />
    </svg>
  );
};

export default UpArrowLargeIcon;
