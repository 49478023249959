import React, { Fragment, useState } from 'react';
import qs from 'qs';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';

import Box from '/imports/core/ui/atoms/Box';
import { DashboardHeading } from '/imports/dashboard/ui/atoms';
import Flex from '/imports/core/ui/atoms/Flex';
import { isPaidUser, freeAccessForYear } from '/lib/helpers';
import { Push } from '/components/Link';
import Tabs from '/imports/dashboard/ui/components/Tabs';
import useIntl from '/imports/core/api/useIntl';

const DashboardHeadingTabs = ({ checkoutSeen, createCoverLetterMutation, role, freeAccessUntil, isCoverLetter }) => {
  const { t, locale } = useIntl();

  const [tabIndex, setTabIndex] = useState(isCoverLetter ? '1' : '0');
  const { asPath } = useRouter();
  if (typeof localStorage === 'undefined') return null;
  let showCoverLetters = isPaidUser(role) || localStorage.getItem('resumedone:checkout-seen');
  if (!showCoverLetters) showCoverLetters = freeAccessUntil ? freeAccessForYear(freeAccessUntil) : showCoverLetters;

  const handleTabClick = (currentTabIndex) => {
    if (currentTabIndex === tabIndex) return;
    setTabIndex(currentTabIndex);
    let path = '';
    switch (currentTabIndex) {
      case '1':
        path = '/cover-letters';
        break;
      default:
        path = '/resumes';
        break;
    }

    Push(path, locale, path, { shallow: true });
  };
  const goToCheckout = () => {
    const queryString = {
      from: asPath,
    };
    Push(`/checkout?${qs.stringify(queryString)}`, locale, '/checkout');
  };

  const handleCreateClick = () => {
    switch (tabIndex) {
      case '1':
        return createCoverLetter();
      default:
        return createResume();
    }
  };

  const createResume = () => {
    const queryString = {
      from: asPath,
    };
    Push(`/onboarding?${qs.stringify(queryString)}`, locale, '/resume/create');
  };

  const createCoverLetter = async () => {
    const paidUser = isPaidUser(role) || freeAccessForYear(freeAccessUntil);

    if (!paidUser && checkoutSeen) return goToCheckout();

    const { data } = await createCoverLetterMutation();

    if (data.create) {
      const queryString = {
        coverLetterId: data.create.id,
        view: 'edit',
        from: asPath,
      };

      Push(`/cover-letter?${qs.stringify(queryString)}`, locale, `/cover-letter/${data.create.id}/finish`);
    }
  };
  return (
    <Fragment>
      <HeadingFlex showCoverLetters={showCoverLetters}>
        <Box grow={1}>
          <DashboardHeadingCustom showCoverLetters={showCoverLetters}>{t('header.dashboard')}</DashboardHeadingCustom>
        </Box>
      </HeadingFlex>
      <Tabs
        showCoverLetters={showCoverLetters}
        currentTab={tabIndex}
        tabClick={handleTabClick}
        buttonClick={handleCreateClick}
        showWebsiteFeature={false}
      />
    </Fragment>
  );
};

const HeadingFlex = styled((p) => <Flex alignItems="center" {...p} />)`
  line-height: 34px;
  margin-bottom: 22px;
  ${({ theme }) => theme.max('sm')`
    flex-direction: column;
    margin-bottom: 16px;
    > div {
      width: 100%;
    }
  `}
`;

const DashboardHeadingCustom = styled(DashboardHeading)`
  color: #282b32;
  line-height: 42px;
  letter-spacing: -1px;
  font-family: TTCommons;
  margin-bottom: 0;
  margin: 0;
  ${({ theme, showCoverLetters, theme: { isRTL } }) => theme.max('sm')`
    text-align: left;
    ${
      isRTL &&
      css`
        text-align: unset;
      `
    }
    font-size: 32px;
    line-height: 32px;
    letter-spacing: -0.76px
    margin: 0 !important;
    ${
      !showCoverLetters &&
      css`
        text-align: center;
      `
    }
  `}
`;

export default DashboardHeadingTabs;
