import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import theme from 'styles/theme';
import PlusIcon2 from 'imports/generator/ui/assets/PlusIcon2';
const Chips = ({ children, selected, onClick, isCloudVariant, disabled = false }) => {
  return (
    <Wrapper $selected={selected} $disabled={disabled} onClick={onClick} $isCloudVariant={isCloudVariant}>
      <Text>{children}</Text>
      <IconWrapper>
        <PlusIcon2 />
      </IconWrapper>
    </Wrapper>
  );
};
Chips.propTypes = {
  children: PropTypes.node,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  isCloudVariant: PropTypes.bool,
};
export default Chips;

const IconWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.colors.gray.regular};
`;
const Wrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  border-radius: 100px;
  background-color: #f6f6f6;
  gap: 4px;
  cursor: pointer;
  color: ${theme.colors.gray.darker2};
  &:hover {
    color: ${theme.colors.blue2};
    background-color: #edf4ff;
    ${IconWrapper} {
      color: ${theme.colors.blue2};
    }
  }
  ${({ $isCloudVariant }) =>
    $isCloudVariant &&
    css`
      border: 1px solid #e3e3e4;
      background-color: white;
    `}
  ${({ $selected }) =>
    $selected &&
    css`
      color: ${theme.colors.blue2};
      background-color: #edf4ff;
      border: none;
      ${IconWrapper} {
        color: ${theme.colors.blue2};
        transform: rotate(45deg);
      }
    `}
  ${({ $disabled }) =>
    $disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`;
const Text = styled.span`
  font-size: 13px;
  font-family: Gilroy SemiBold;
`;
