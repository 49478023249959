import React from 'react';
import styled, { css } from 'styled-components';

import View from '/imports/pdf/core/ui/atoms/View';

const EmailIcon = () => {
  return (
    <IconCont>
      <SvgIcon viewBox="0 0 12.50 11.78">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M8.005 4.376l4.013-3.358v6.638l-4.013-3.28zM.659.537a.849.849 0 0 1 .572-.218h9.951c.223 0 .424.082.573.217L6.206 5.112.659.537zM.395 7.656V1.02l4.012 3.356-4.012 3.28zm5.811-1.745L7.644 4.7l4.109 3.39a.852.852 0 0 1-.571.217H1.231a.858.858 0 0 1-.574-.217L4.768 4.7l1.438 1.211z"
        />
      </SvgIcon>
    </IconCont>
  );
};

const IconCont = styled(View)`
  position: absolute;
  top: 0;
  ${({ theme: { convertPx, isRTL } }) => css`
    left: ${isRTL ? 'auto' : convertPx(-2)};
    background: var(--black);
    width: ${convertPx(20)};
    height: ${convertPx(20)};
    border-radius: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: ${convertPx(4)};
    margin-top: ${convertPx(4)};
  `}
`;

const SvgIcon = styled.svg`
  stroke: none;
  width: ${({ theme }) => theme.convertPx(14)};
  height: ${({ theme }) => theme.convertPx(15)};
`;

export default EmailIcon;
