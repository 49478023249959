import { useRouter } from 'next/router';

import { Experiment as MarvelExperiment, Variant as MarvelVariant } from '@marvelapp/react-ab-test';

export const RenderExpPowerSkillsTagsMobile = ({ isMobile, children }) => {
  const { query } = useRouter();
  const isActiveExp = ['skills', 'finish'].includes(query.step) && isMobile;
  if (!isActiveExp) return children;
  return (
    <MarvelExperiment name="exp_power_skill_tags_mobile">
      <MarvelVariant name="control">{children}</MarvelVariant>
      <MarvelVariant name="with_simulation">{children}</MarvelVariant>
    </MarvelExperiment>
  );
};
