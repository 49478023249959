import React from 'react';
import styled from 'styled-components';

const RightToLeftIcon = ({ width = 16, height = 16 }) => {
  return (
    <SvgIcon viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
      <g transform="translate(2 2)" stroke="#BFC5D7" fill="none" fillRule="evenodd">
        <rect
          strokeWidth="2.346"
          strokeLinecap="square"
          transform="matrix(1 0 0 -1 0 24.065)"
          y=".065"
          width="23.94"
          height="23.935"
          rx="1"
          stroke="currentColor"
        />
        <path
          d="M12.057 23.5V.482"
          strokeWidth="1.632"
          strokeLinecap="round"
          strokeDasharray="2.04,2.04"
          stroke="currentColor"
        />
        <g strokeLinecap="round" strokeWidth="1.428" stroke="currentColor">
          <path d="M5.196 12.254h13.417M7.778 9.681L5.052 12.16a.16.16 0 00-.052.117.16.16 0 00.052.117L7.778 15" />
        </g>
      </g>
    </SvgIcon>
  );
};

RightToLeftIcon.displayName = 'RightToLeft';

const SvgIcon = styled.svg`
  stroke: none;
`;

export default RightToLeftIcon;
