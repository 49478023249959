import {
  jobTrackEmojiPicker,
  layoutEmojiPicker,
  closeEmojiPicker,
  isMobileCompanyDetail,
  openReportModal,
  closeReportModal,
  startTrackingModal,
  closeTrackingModal,
  openSignUpModal,
  closeSignUpModal,
} from '/imports/job-tracking/api/library/general.lib';

const Reducer = (state, action) => {
  switch (action.type) {
    case 'LAYOUT_EMOJI_PICKER':
      return layoutEmojiPicker(state, action);
    case 'JOBTRACK_EMOJI_PICKER':
      return jobTrackEmojiPicker(state, action);
    case 'CLOSE_EMOJI_PICKER':
      return closeEmojiPicker(state);
    case 'IS_MOBILE_COMPANY_DETAIL':
      return isMobileCompanyDetail(state, action);
    case 'OPEN_REPORT_MODAL':
      return openReportModal(state, action);
    case 'CLOSE_REPORT_MODAL':
      return closeReportModal(state);
    case 'OPEN_START_TRACKING_MODAL':
      return startTrackingModal(state, action);
    case 'CLOSE_TRACKING_MODAL':
      return closeTrackingModal(state);
    case 'OPEN_SIGNUP_MODAL':
      return openSignUpModal(state);
    case 'CLOSE_SIGNUP_MODAL':
      return closeSignUpModal(state);
    default:
      state;
  }
};

export default Reducer;
