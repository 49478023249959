import React from 'react';

const DownArrowLargeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path
        fill="#BFC5E0"
        fillRule="evenodd"
        d="M11 18.586L11 3 13 3 13 18.586 19.293 12.293 20.707 13.707 12 22.414 3.293 13.707 4.707 12.293z"
      />
    </svg>
  );
};

export default DownArrowLargeIcon;
