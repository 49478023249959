import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { staticFile } from '/lib/helpers';
import { withIntl } from '/imports/core/api/useIntl';
import { withWindowSize } from '/imports/core/api/useWindowSize';

@withWindowSize
@withIntl
class PhotoDragAndDrop extends PureComponent {
  wrapperRef = React.createRef();
  containerRef = React.createRef();

  static propTypes = {
    width: PropTypes.number,
    onChange: PropTypes.func,
    t: PropTypes.func,
  };

  onChange = ({
    target: {
      validity,
      files: [file],
    },
  }) => {
    if (validity.valid) {
      this.setState({ value: file });
    }
  };
  componentDidMount() {
    this.setDinamicallyContainerSize();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.width !== this.props.width) {
      this.setDinamicallyContainerSize();
    }
  }
  setDinamicallyContainerSize() {
    const wrapper = this.wrapperRef.current;
    const container = this.containerRef.current;
    const width = wrapper.clientWidth;
    const height = wrapper.clientHeight;
    container.style.width = `${width + 1}px`;
    container.style.height = `${height + 1}px`;
  }

  render() {
    const { t } = this.props;
    return (
      <Wrapper ref={this.wrapperRef}>
        <Container ref={this.containerRef}>
          <Image />
          <Message>
            <span dangerouslySetInnerHTML={{ __html: t('generator.upload.info') }} />
          </Message>
          <Input type="file" accept="image/jpeg, image/x-png, .jpg, .jpeg, .png" onChange={this.props.onChange} />
        </Container>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const Container = styled.div`
  position: relative;
  text-align: center;
  cursor: pointer;
  color: ${(p) => p.theme.colors.gray.regular};
  padding: 35px 50px 50px;
  &:hover {
    color: #1688fe;
  }
  ${({ theme: { isJobTrack } }) =>
    !isJobTrack &&
    css`
      border-width: 3px;
      border-style: dashed;
      border-color: ${(p) => p.theme.colors.gray.regular};
      border-image: url(${staticFile('img/ui/crop/border-dash-rounded.svg')}) 3 / 1 / 0 round;
      transition: color 0.1s ease 0s;

      &::after {
        content: '';
        position: absolute;
        left: -3px;
        top: -3px;
        right: -3px;
        bottom: -3px;
        pointer-events: none;
        opacity: 0;
        border-width: 3px;
        border-style: dashed;
        border-color: #1688fe;
        border-image: url(${staticFile('img/ui/crop/border-dash-rounded-hover.svg')}) 3 / 1 / 0 round;
        transition: opacity 0.1s ease 0s;
      }
      &:hover::after {
        opacity: 1;
      }
    `}
  ${({ theme: { isJobTrack } }) =>
    isJobTrack &&
    css`
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg' %3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%23bac2d1' stroke-width='2' stroke-dasharray='3%2c7' stroke-dashoffset='2' stroke-linecap='square'/%3e%3c/svg%3e");
      border-radius: 6px;
      transition: color 0.1s ease 0s;

      &:hover {
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%231688fe' stroke-width='2' stroke-dasharray='3%2c7' stroke-dashoffset='8' stroke-linecap='round'/%3e%3c/svg%3e");
        color: #1688fe;
      }
    `}
`;

const Image = styled.div`
  display: block;
  width: 60px;
  height: 60px;
  background-image: url(${staticFile('img/ui/crop/upload-image.svg')});
  background-size: contain;
  margin: 0px auto 12px;
  background-repeat: no-repeat;
`;

const Message = styled.div``;

const Input = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  opacity: 0;
  cursor: pointer;
`;

export default PhotoDragAndDrop;
