import React from 'react';
import styled from 'styled-components';

import BasicContainer from '/imports/homepageV3/ui/atoms/BasicContainer';
import BasicSectionWrapper from '/imports/homepageV3/ui/atoms/BasicSectionWrapper';
import StyledH2 from '/imports/homepageV3/ui/atoms/StyledH2';
import StyledH4 from '/imports/homepageV3/ui/atoms/StyledH4';
import useIntl from '/imports/core/api/useIntl';

const Section7 = () => {
  const { t } = useIntl();
  return (
    <BasicSectionWrapper>
      <BasicContainer>
        <AdvanceWrapper>
          <AdvanceH2>
            {t('resumizeme_landing_sec7_title_part1')}
            <br />
            {t('resumizeme_landing_sec7_title_part2')}
          </AdvanceH2>
          <AdvancePlansWrapper>
            <AdvancePlanBlock>
              <AdvanceH4>{t('resumizeme_landing_sec7_adv1_title')}</AdvanceH4>
              <AdvanceParagraph>{t('resumizeme_landing_sec7_adv1_paragraph')}</AdvanceParagraph>
            </AdvancePlanBlock>
            <AdvancePlanBlock>
              <AdvanceH4>{t('resumizeme_landing_sec7_adv2_title')}</AdvanceH4>
              <AdvanceParagraph>{t('resumizeme_landing_sec7_adv2_paragraph')}</AdvanceParagraph>
            </AdvancePlanBlock>
            <AdvancePlanBlock>
              <AdvanceH4>{t('resumizeme_landing_sec7_adv3_title')}</AdvanceH4>
              <AdvanceParagraph>{t('resumizeme_landing_sec7_adv3_paragraph')}</AdvanceParagraph>
            </AdvancePlanBlock>
          </AdvancePlansWrapper>
        </AdvanceWrapper>
      </BasicContainer>
    </BasicSectionWrapper>
  );
};
const AdvanceWrapper = styled.div`
  text-align: center;
`;
const AdvanceH2 = styled(StyledH2)`
  margin-top: 0px;
  margin-bottom: 32px;
`;
const AdvanceH4 = styled(StyledH4)`
  margin-top: 0px;
  margin-bottom: 16px;
  color: #14141f;
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
`;
const AdvancePlansWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  grid-column-gap: 24px;
  @media (max-width: 767px) {
    align-items: center;
    flex-direction: column;
    justify-content: center;
    grid-row-gap: 0;
    > div:not(:last-child) {
      margin-bottom: 24px;
    }
  }
`;
const AdvancePlanBlock = styled.div`
  max-width: 447px;
  padding: 32px;
  border: 1px solid #e3e3e4;
  border-radius: 8px;
  @media (max-width: 767px) {
    padding: 32px 16px;
  }
`;
const AdvanceParagraph = styled.p`
  margin-bottom: 0px;
  color: #3a3a43;
  font-weight: 500;
`;
export default Section7;
