import styled from 'styled-components';

const SocialLinksIcon = ({ width = '20px', height = '20px', viewBox = '0 0 20 20' }) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
    <path d="M10 0c6.46 0 10 3.616 10 10 0 3.545-1.642 5-4 5-1.345 0-2.114-.433-2.536-1.396A5 5 0 1113 6V5h2v5c0 2.47.189 3 1 3 1.304 0 2-.617 2-3 0-5.29-2.653-8-8-8a8 8 0 104.692 14.48l1.173 1.62A10 10 0 119.999 0zm0 7a3 3 0 100 6 3 3 0 000-6z" />
  </SvgIcon>
);

const SvgIcon = styled.svg`
  stroke: none;
  fill: currentColor;
`;

SocialLinksIcon.displayName = 'SocialLinksIcon';

export default SocialLinksIcon;
