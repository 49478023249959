import React from 'react';
import Flex from 'imports/core/ui/atoms/Flex';
import { cdnFile, staticFile } from 'lib/helpers';
import { content_landing_slugs } from 'imports/homepageV2/api/constants';
import styled, { css } from 'styled-components';
import { useResponsive } from 'imports/core/api/responsiveContext';

import Container from '/imports/homepageV2/ui/atmos/Container';
import useIntl from '/imports/core/api/useIntl';

const LandingPageContent = ({ isNewMobileDesign = false }) => {
  const { t } = useIntl();
  const { host } = useResponsive();
  const slug = content_landing_slugs(host, t);
  return (
    <Main>
      <StyledContainer $isNewMobileDesign={isNewMobileDesign}>
        <Wrapper>
          <StyledFlex $isNewMobileDesign={isNewMobileDesign}>
            <ImgWrapper $image="img-fastBuilder@3x.png" />
            <ContentInner $direction="column">
              <Title $isNewMobileDesign={isNewMobileDesign}>{slug['resume_nation_landing_s3_p1']}</Title>
              <Content $isNewMobileDesign={isNewMobileDesign}>{slug['resume_nation_landing_s3_p2']}</Content>
            </ContentInner>
          </StyledFlex>
          <StyledFlex $direction="column" $isNewMobileDesign={isNewMobileDesign}>
            <ImgWrapper $image="img-autoWritten@3x.png" />
            <ContentInner $direction="column">
              <Title $isNewMobileDesign={isNewMobileDesign}>{slug['resume_nation_landing_s3_p3']}</Title>
              <Content $isNewMobileDesign={isNewMobileDesign}>{slug['resume_nation_landing_s3_p4']}</Content>
            </ContentInner>
          </StyledFlex>
          <StyledFlex $direction="column" $isNewMobileDesign={isNewMobileDesign}>
            <ImgWrapper $image="img-avoidMistakes@3x.png" />
            <ContentInner $direction="column">
              <Title $isNewMobileDesign={isNewMobileDesign}>{slug['resume_nation_landing_s3_p5']}</Title>
              <Content $isNewMobileDesign={isNewMobileDesign}>{slug['resume_nation_landing_s3_p6']}</Content>
            </ContentInner>
          </StyledFlex>
          <StyledFlex $direction="column" $isNewMobileDesign={isNewMobileDesign}>
            <ImgWrapper $image="img-exportFormat@3x.png" />
            <ContentInner $direction="column">
              <Title $isNewMobileDesign={isNewMobileDesign}>{slug['resume_nation_landing_s3_p7']}</Title>
              <Content $isNewMobileDesign={isNewMobileDesign}>{slug['resume_nation_landing_s3_p8']}</Content>
            </ContentInner>
          </StyledFlex>
        </Wrapper>
      </StyledContainer>
    </Main>
  );
};

export default LandingPageContent;

const StyledContainer = styled(Container)`
  background-color: var(--light-values-light-extra-v1);
  position: relative;
  ${({ $isNewMobileDesign }) =>
    $isNewMobileDesign &&
    css`
      padding: 0 16px !important;
      background: #f6f6f6;
    `}
`;

const Wrapper = styled(Flex)`
  flex-direction: row;
  gap: 40px;
  ${({ theme }) => theme.max('md')`
    flex-direction: column;
    background-color: var(--light-values-light-regular-v1);
    gap: 1px;
  `}
`;

const ContentInner = styled(Flex)`
  gap: 16px;
  ${({ theme }) => theme.max('md')`
    gap: 8px;
  `}
`;

const Content = styled.div`
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 15px;
  font-weight: 500;
  line-height: 1.47;
  color: var(--text);
  ${({ theme }) => theme.max('md')`
    font-size: 13px;
    line-height: 1.54;
    color: var(--dark-values-dark-regular-v1); 
  `}
  ${({ $isNewMobileDesign }) =>
    $isNewMobileDesign &&
    css`
      color: #4c4c55 !important;
      font-size: 14px !important;
      font-style: normal;
      font-weight: 400;
      line-height: 22px !important;
    `}
`;

const StyledFlex = styled(Flex)`
  gap: 16px;
  flex: 1;
  padding-right: 39px;
  min-height: 230px;
  border-right: 1px solid var(--light-values-light-regular-v1);
  flex-direction: column;
  background-color: var(--light-values-light-extra-v1);
  &:last-child {
    border-right: 0;
    padding-right: 0;
  }
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      &:last-child {
        border-right: 1px solid var(--light-values-light-regular-v1);
        padding-right: 39px;
      }
      &:first-child {
        border-right: 0;
        padding-right: 0;
      }
    `}
  ${({ theme }) => theme.max('md')`
    border-right: none;
    flex-direction: row;
    min-height: auto;
    padding: 32px 0;
    width: 100%;
    &:last-child {
      border-right: none;
      padding-right: 0;
    }
  `}
  ${({ $isNewMobileDesign }) =>
    $isNewMobileDesign &&
    css`
      background: #f6f6f6;
    `}
`;

const ImgWrapper = styled.div`
  ${({ $image }) =>
    $image &&
    css`
      background-image: url(${cdnFile(`resume-nation-landing/${$image}`)});
    `}
  height: 64px;
  width: 64px;
  background-size: contain;
  flex-shrink: 0;
`;

const Main = styled.div`
  margin-top: 80px;
  padding: 40px 80px;
  background-color: var(--light-values-light-extra-v1);
  ${({ theme }) => theme.max('md')`
    margin-top: 0;
    padding: 0;
  `}
`;

const Title = styled.div`
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 17px;
  font-weight: 600;
  line-height: 1.41;
  color: var(--text);
  ${({ theme }) => theme.max('md')`
    font-size: 15px;
    line-height: 1.47;
  `}
  ${({ $isNewMobileDesign }) =>
    $isNewMobileDesign &&
    css`
      color: #14141f;
      font-family: Gilroy Bold;
      font-size: 16px !important;
      font-style: normal;
      font-weight: 400;
      line-height: 20px !important;
    `}
`;
