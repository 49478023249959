import CoursesIconV3 from './CoursesIconV3';
import IconsExperiment from './IconsExperiment';

const CoursesIconExperiment = (props) => {
  return <IconsExperiment iconV3={<CoursesIconV3 {...props} />} />;
};

CoursesIconExperiment.displayName = 'CoursesIconExperiment';

export default CoursesIconExperiment;
