import React from 'react';
import styled from 'styled-components';

const LeftArrow = () => {
  return (
    <IconCont>
      <SvgIcon width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.6 13.7999C6.15817 14.1313 5.53137 14.0418 5.2 13.5999L0.25 6.99994L5.2 0.39994C5.53137 -0.0418873 6.15817 -0.13143 6.6 0.199941C7.04183 0.531312 7.13137 1.15811 6.8 1.59994L3.5 5.99994L15 5.99994C15.5523 5.99994 16 6.44766 16 6.99994C16 7.55223 15.5523 7.99994 15 7.99994L3.5 7.99994L6.8 12.3999C7.13137 12.8418 7.04183 13.4686 6.6 13.7999Z"
          fill="#4C4C55"
        />
      </SvgIcon>
    </IconCont>
  );
};

const IconCont = styled.span`
  padding-left: 2px;
  padding-right: 10px;
`;

const SvgIcon = styled.svg`
  stroke: none;
  fill: currentColor;
`;

export default LeftArrow;
