import AccessCoverLetter from '/imports/help/ui/pages/AccessCoverLetter';
import AddPhotoToResume from '/imports/help/ui/pages/AddPhotoToResume';
import CancelSubscription from '/imports/help/ui/pages/CancelSubscription';
import ChangeResumeFont from '/imports/help/ui/pages/ChangeResumeFont';
import ChangeResumeLanguage from '/imports/help/ui/pages/ChangeResumeLanguage';
import ChangeResumeSection from '/imports/help/ui/pages/ChangeResumeSection';
import ChangeTemplate from '/imports/help/ui/pages/ChangeTemplate';
import CreateMultipleVersion from '/imports/help/ui/pages/CreateMultipleVersion';
import CustomizeResume from '/imports/help/ui/pages/CustomizeResume';
import DeleteAccount from '/imports/help/ui/pages/DeleteAccount';
import DownloadWordFormat from '/imports/help/ui/pages/DownloadWordFormat';
import LoginToResumeDone from '/imports/help/ui/pages/LoginToResumeDone';
import PremiumSubscription from '/imports/help/ui/pages/PremiumSubscription';
import RefundPolicy from '/imports/help/ui/pages/RefundPolicy';

export const ARTICLE_SLUG_TO_COMPONENT_MAP = [
  {
    slug: [
      '13-how-do-i-change-my-resume-template-or-design',
      '34-how-do-i-change-my-resume-template-or-design',
      '518-cum-pot-schimba-modelul-sau-designul-cv-ului-meu',
      '88-comment-puis-je-modifier-mon-modele-ou-ma-conception-de-cv',
      '106-como-puedo-cambiar-la-plantilla-o-el-diseno-de-mi-curriculum',
      '126-bagaimana-cara-mengubah-template-atau-desain-resume-saya',
      '54-how-do-i-change-my-resume-template-or-design',
      '146-hogyan-valtoztathatom-meg-az-oneletrajzom-sablonjat-vagy-designjat',
      '165-cach-thay-doi-mau-hoac-thiet-ke-so-yeu-ly-lich-cua-ban',
      '203-wie-andere-ich-meine-lebenslaufvorlage-oder-mein-design',
      '189-jak-zmienic-styl-lub-szablon-mojego-zyciorysu',
      '223-hvordan-aendrer-jeg-min-cv-skabelon-eller-design',
      '242-miten-muutan-ansioluetteloni-mallia-tai-ulkoasua',
      '261-hvordan-endrer-jeg-mal-eller-design-pa-cv-en-min',
      '280-come-cambiare-il-modello-o-il-design-del-mio-curriculum',
      '299-hur-andrar-jag-min-cv-mall-eller-design',
      '318-hoe-kan-ik-mijn-cv-template-of-ontwerp-aanpassen',
      '337-ozgecmis-sablonumu-veya-tasarimimi-nasil-degistirebilirim',
      '356-jak-zmenim-sablonu-nebo-design-zivotopisu',
      '375-article',
      '394-article',
      '413-como-faco-para-alterar-o-modelo-ou-design-do-meu-curriculo',
      '454-article',
      '441-article',
      '473-ka-es-varu-nomainit-sava-cv-veidni-vai-dizainu',
      '500-como-faco-para-alterar-o-modelo-ou-design-do-meu-curriculo',
      '537-kaip-pakeisti-mano-gyvenimo-aprasymo-sablono-dizaina',
      '556-article',
      '575-bagaimanakah-saya-boleh-mengubah-templat-atau-reka-bentuk-resume-saya',
      '618-how-do-i-change-my-resume-template-or-design',
      '632-how-do-i-change-my-resume-template-or-design',
      '604-comment-puis-je-modifier-mon-modele-ou-ma-conception-de-cv',
      '651-article',
      '664-how-do-i-change-my-resume-template-or-design',
      '685-how-do-i-change-my-resume-template-or-design',
      '708-how-do-i-change-my-resume-template-or-design',
      '727-how-do-i-change-my-resume-template-or-design',
      '746-how-do-i-change-my-resume-template-or-design',
      '765-kuidas-muuta-oma-elulookirjelduse-malli-voi-kujundust',
      '784-kako-mogu-promijeniti-predlozak-ili-dizajn-zivotopisa',
      '803-jak-zmienic-szablon-lub-projekt-cv',
      '822-rezume-sablonumu-və-ya-dizaynimi-necə-dəyisdirə-bilərəm',
      '922-rezyumeniң-үlgisin-nemese-dizajnyn-қalaj-өzgertuge-bolady',
    ],
    component: ChangeTemplate,
  },
  {
    slug: [
      '14-how-to-add-a-photo-to-your-resume',
      '35-how-to-add-a-photo-to-your-resume',
      '517-cum-se-poate-adauga-o-fotografie-la-cv',
      '86-comment-ajouter-une-photo-a-votre-cv',
      '105-como-anadir-una-foto-a-mi-curriculum',
      '125-bagaimana-cara-menambahkan-foto-ke-resume-anda',
      '55-how-to-add-a-photo-to-your-resume',
      '145-hogyan-adjunk-hozza-fenykepet-az-oneletrajzhoz',
      '164-cach-them-anh-vao-so-yeu-ly-lich-cua-ban',
      '202-so-fugen-sie-ihrem-lebenslauf-ein-foto-hinzu',
      '188-jak-dodac-zdjecie-do-zyciorysu',
      '222-hvordan-du-tilfojer-et-billede-til-dit-cv',
      '241-miten-lisata-kuva-ansioluetteloon',
      '260-hvordan-legge-til-et-bilde-i-cv-en',
      '279-come-aggiungere-un-immagine-al-tuo-curriculum',
      '298-hur-du-lagger-till-en-bild-i-ditt-cv',
      '317-hoe-voeg-je-een-foto-toe-aan-je-cv',
      '336-ozgecmisime-nasil-fotograf-ekleyebilirim',
      '355-jak-pridat-fotografii-do-zivotopisu',
      '374-article',
      '393-article',
      '412-como-adicionar-uma-foto-ao-seu-curriculo',
      '453-article',
      '440-article',
      '472-ka-cv-iespejams-pievienot-fotografiju',
      '498-como-adicionar-uma-foto-ao-seu-curriculo',
      '536-kaip-i-savo-gyvenimo-aprasyma-prideti-nuotrauka',
      '555-article',
      '574-cara-untuk-menambah-foto-ke-resume-anda',
      '617-how-to-add-a-photo-to-your-resume',
      '631-how-to-add-a-photo-to-your-resume',
      '603-comment-ajouter-une-photo-a-votre-cv',
      '650-article',
      '667-how-to-add-a-photo-to-your-resume',
      '681-how-to-add-a-photo-to-your-resume',
      '707-how-to-add-a-photo-to-your-resume',
      '726-how-to-add-a-photo-to-your-resume',
      '745-how-to-add-a-photo-to-your-resume',
      '764-kuidas-lisada-foto-oma-elulookirjeldusele',
      '783-kako-dodati-fotografiju-svom-zivotopisu',
      '802-jak-dodac-zdjecie-do-cv',
      '821-rezumenizə-necə-səkil-əlavə-etmək-olar',
      '921-rezyumeңizge-suretterdi-қalaj-қosuғa-bolady',
    ],
    component: AddPhotoToResume,
  },
  {
    slug: [
      '15-how-do-i-duplicate-or-create-multiple-versions-of-my-resume',
      '36-how-do-i-duplicate-or-create-multiple-versions-of-my-resume',
      '516-cum-pot-duplica-sau-crea-mai-multe-versiuni-ale-cv-ului-meu',
      '85-comment-dupliquer-ou-creer-plusieurs-versions-de-mon-cv',
      '104-como-puedo-duplicar-o-crear-varias-versiones-de-mi-curriculum',
      '124-bagaimana-cara-menggandakan-atau-membuat-beberapa-versi-resume',
      '56-how-do-i-duplicate-or-create-multiple-versions-of-my-resume',
      '144-hogyan-masolhatom-vagy-keszithetek-tobb-verziot-az-oneletrajzombol',
      '163-cach-de-copy-hoac-tao-nhieu-ban-so-yeu-ly-lich',
      '201-wie-dupliziere-oder-erstelle-ich-mehrere-versionen-meines-lebenslaufs',
      '187-jak-powielic-lub-utworzyc-wiele-wersji-mojego-zyciorysu',
      '221-hvordan-dublikerer-jeg-eller-skaber-flere-versioner-af-mit-cv',
      '240-kuinka-kopioin-ansioluetteloni-tai-teen-siita-useita-eri-versioita',
      '259-hvordan-kan-jeg-kopiere-eller-lage-flere-versjoner-av-cv-en-min',
      '278-come-duplicare-o-creare-versioni-multiple-del-mio-curriculum',
      '297-hur-duplicerar-jag-eller-skapar-flera-versioner-av-mitt-cv',
      '316-hoe-dupliceer-of-maak-ik-meerdere-versies-van-mijn-cv',
      '335-ozgecmisimin-bir-ya-da-birden-fazla-kopyasini-nasil-olusturabilirim',
      '354-jak-mohu-duplikovat-nebo-vytvorit-vice-verzi-meho-zivotopisu',
      '373-article',
      '392-article',
      '411-como-faco-para-duplicar-ou-criar-varias-versoes-do-meu-curriculo',
      '452-article',
      '439-article',
      '471-ka-es-varu-nokopet-cv-vai-izveidot-vairakas-ta-versijas',
      '494-como-faco-para-duplicar-ou-criar-varias-versoes-do-meu-curriculo',
      '535-kaip-kopijuoti-arba-sukurti-kelias-savo-gyvenimo-aprasymo-versijas',
      '554-article',
      '573-bagaimanakah-saya-boleh-menduplikasi-atau-mencipta-beberapa-versi-resume-saya',
      '616-how-do-i-duplicate-or-create-multiple-versions-of-my-resume',
      '630-how-do-i-duplicate-or-create-multiple-versions-of-my-resume',
      '602-comment-dupliquer-ou-creer-plusieurs-versions-de-mon-cv',
      '649-article',
      '668-how-do-i-duplicate-or-create-multiple-versions-of-my-resume',
      '686-how-do-i-duplicate-or-create-multiple-versions-of-my-resume',
      '706-how-do-i-duplicate-or-create-multiple-versions-of-my-resume',
      '725-how-do-i-duplicate-or-create-multiple-versions-of-my-resume',
      '744-how-do-i-duplicate-or-create-multiple-versions-of-my-resume',
      '763-kuidas-dubleerida-voi-luua-mitu-versiooni-oma-elulookirjeldusest',
      '782-kako-mogu-duplicirati-ili-stvoriti-vise-verzija-svog-zivotopisa',
      '801-jak-powielic-lub-utworzyc-wiele-wersji-mojego-cv',
      '820-rezumemin-bir-necə-versiyasini-necə-təkrarlaya-və-ya-yarada-bilərəm',
      '920-rezyumeniң-nұsқasyn-қalaj-kөshiruge-nemese-zhasauғa-bolady',
    ],
    component: CreateMultipleVersion,
  },

  {
    slug: [
      '16-how-do-i-get-access-to-the-cover-letter-feature',
      '37-how-do-i-get-access-to-the-cover-letter-feature',
      '515-cum-pot-obtine-acces-la-scrisoarea-de-intentie',
      '84-comment-acceder-a-la-fonction-de-lettre-de-motivation',
      '103-como-puedo-acceder-a-la-funcion-de-carta-de-presentacion',
      '123-bagaimana-cara-mendapatkan-akses-ke-surat-pengantar',
      '58-how-do-i-get-access-to-the-cover-letter-feature',
      '143-hogyan-juthatok-hozza-a-kiserolevelhez',
      '162-cach-truy-cap-thu-xin-viec',
      '200-wie-erhalte-ich-zugang-zum-anschreiben',
      '186-gdzie-znajde-listy-motywacyjne',
      '220-hvordan-fa-jeg-adgang-til-folgebreve',
      '239-miten-paasen-kasiksi-saatekirjeeseeni',
      '258-hvordan-far-jeg-tilgang-til-folgebrevet-mitt',
      '277-come-creare-una-lettera-di-presentazione',
      '296-hur-far-jag-tillgang-till-personliga-brev',
      '315-hoe-krijg-ik-toegang-tot-de-sollicitatiebrief',
      '334-on-yazima-nasil-erisebilirim',
      '353-jak-ziskam-pristup-k-pruvodnimu-dopisu',
      '372-article',
      '391-article',
      '410-como-posso-ter-acesso-a-carta-de-apresentacao',
      '451-article',
      '438-article',
      '470-ka-es-varu-pieklut-pavadvestulei',
      '493-como-posso-ter-acesso-a-carta-de-apresentacao',
      '534-kaip-gauti-prieiga-prie-motyvacinio-laisko',
      '553-article',
      '572-bagaimanakah-saya-boleh-mengakses-surat-iringan',
      '615-how-do-i-get-access-to-the-cover-letter',
      '629-how-do-i-get-access-to-the-cover-letter',
      '601-comment-acceder-a-la-fonction-de-lettre-de-motivation',
      '648-article',
      '666-how-do-i-get-access-to-the-cover-letter-feature',
      '693-how-do-i-get-access-to-the-cover-letter-feature',
      '705-how-do-i-get-access-to-the-cover-letter',
      '724-how-do-i-get-access-to-the-cover-letter',
      '743-how-do-i-get-access-to-the-cover-letter',
      '762-kuidas-saan-juurdepaasu-kaaskirjale',
      '781-kako-mogu-dobiti-pristup-znacajci-propratnog-pisma',
      '800-jak-uzyskac-dostep-do-funkcji-listu-motywacyjnego',
      '819-musayiət-məktubu-funksiyasina-necə-daxil-ola-bilərəm',
      '919-қoldau-hat-funkciyasyna-қalaj-қol-zhetkizuge-bolady',
    ],
    component: AccessCoverLetter,
  },

  {
    slug: [
      '17-can-i-download-my-resume-in-word-format',
      '27-can-i-download-my-resume-in-word-format',
      '512-pot-sa-imi-descarc-cv-ul-in-format-word',
      '81-3-puis-je-telecharger-mon-cv-au-format-word-ou-pdf',
      '100-puedo-descargar-mi-curriculum-en-formato-word-o-pdf',
      '120-dapatkah-saya-mengunduh-resume-saya-dalam-format-word',
      '59-can-i-download-my-resume-in-word-format',
      '140-letolthetem-az-oneletrajzomat-word-formatumban',
      '159-toi-co-the-tai-xuong-so-yeu-ly-lich-cua-minh-o-dinh-dang-word-khong',
      '197-kann-ich-meinen-lebenslauf-im-word-format-herunterladen',
      '183-czy-moge-pobrac-moj-zyciorys-w-formacie-word',
      '217-kan-jeg-downloade-mit-cv-i-word-format',
      '236-voinko-ladata-ansioluetteloni-word-muotoon',
      '255-kan-jeg-laste-ned-cv-en-min-i-word-format',
      '274-posso-scaricare-il-mio-curriculum-in-formato-word',
      '293-kan-jag-ladda-ner-mitt-cv-i-word-format',
      '312-kan-ik-mijn-cv-in-word-downloaden',
      '331-ozgecmisimi-word-formatinda-indirebilir-miyim',
      '350-mohu-si-stahnout-svuj-zivotopis-ve-formatu-word',
      '369-word',
      '388-word',
      '407-posso-baixar-meu-curriculo-em-formato-word',
      '448-word',
      '435-word',
      '467-vai-es-varu-lejupieladet-savu-cv-word-formata',
      '490-posso-baixar-meu-curriculo-em-formato-word',
      '531-ar-galiu-parsisiusti-savo-gyvenimo-aprasyma-word-formatu',
      '550-article',
      '569-bolehkah-saya-memuat-turun-resume-saya-dalam-format-word',
      '612-can-i-download-my-resume-in-word-format',
      '626-can-i-download-my-resume-in-word-format',
      '598-puis-je-telecharger-mon-cv-au-format-word-ou-pdf',
      '645-word',
      '662-can-i-download-my-resume-in-word-format',
      '694-can-i-download-my-resume-in-word-format',
      '702-can-i-download-my-resume-in-word-format',
      '721-can-i-download-my-resume-in-word-format',
      '740-can-i-download-my-resume-in-word-format',
      '759-kas-ma-saan-oma-elulookirjelduse-wordi-formaadis-alla-laadida',
      '778-mogu-li-preuzeti-svoj-zivotopis-u-word-formatu',
      '797-czy-moge-pobrac-cv-w-formacie-word',
      '816-rezumemi-word-formatinda-endirə-bilərəm',
      '916-rezyumemdi-word-formatynda-zhүktep-aluғa-bolady',
    ],
    component: DownloadWordFormat,
  },

  {
    slug: [
      '18-how-can-i-customize-my-resume',
      '38-how-can-i-customize-my-resume',
      '511-cum-imi-pot-personaliza-cv-ul',
      '80-comment-personnaliser-mon-cv',
      '99-como-puedo-personalizar-mi-curriculum',
      '119-bagaimana-cara-mengubah-resume-saya',
      '53-how-can-i-customize-my-resume',
      '139-hogyan-szabhatom-testre-az-oneletrajzomat',
      '158-toi-co-the-tuy-chinh-so-yeu-ly-lich-cua-minh-bang-cach-nao',
      '196-wie-kann-ich-meinen-lebenslauf-anpassen',
      '182-jak-moge-dostosowac-moje-cv',
      '216-hvordan-kan-jeg-tilpasse-mit-cv',
      '235-miten-muokkaan-ansioluetteloani',
      '254-hvordan-kan-jeg-tilpasse-cv-en-min',
      '273-come-posso-personalizzare-il-mio-curriculum',
      '292-hur-kan-jag-anpassa-mitt-cv',
      '311-hoe-kan-ik-mijn-cv-personaliseren',
      '330-ozgecmisimi-nasil-ozellestirebilirim',
      '349-jak-mohu-prizpusobit-svuj-zivotopis',
      '368-article',
      '387-article',
      '406-2-como-personalizar-meu-curriculo',
      '447-article',
      '434-article',
      '466-ka-es-varu-formatet-savu-cv',
      '489-como-personalizar-meu-curriculo',
      '530-kaip-galiu-tinkinti-savo-gyvenimo-aprasyma',
      '549-article',
      '568-bagaimanakah-saya-boleh-menyesuaikan-resume-saya',
      '611-how-can-i-customize-my-resume',
      '625-how-can-i-customize-my-resume',
      '597-comment-personnaliser-mon-cv',
      '644-article',
      '665-how-can-i-customize-my-resume',
      '688-how-can-i-customize-my-resume',
      '701-how-can-i-customize-my-resume',
      '720-how-can-i-customize-my-resume',
      '739-how-can-i-customize-my-resume',
      '758-kuidas-ma-saan-oma-elulookirjeldust-kohandada',
      '777-kako-mogu-prilagoditi-svoj-zivotopis',
      '796-jak-moge-dostosowac-swoje-cv',
      '815-rezumemi-necə-fərdiləsdirə-bilərəm',
      '915-rezyumemdi-қalaj-baptauғa-bolady',
    ],
    component: CustomizeResume,
  },
  {
    slug: [
      '19-can-i-change-the-language-of-my-resume',
      '28-can-i-change-the-language-of-my-resume',
      '510-pot-schimba-limba-in-care-este-redactat-cv-ul-meu',
      '74-puis-je-changer-la-langue-de-mon-cv',
      '98-puedo-cambiar-el-idioma-de-mi-curriculum',
      '118-bisakah-saya-mengubah-bahasa-resume',
      '60-can-i-change-the-language-of-my-resume',
      '138-megvaltoztathatom-az-oneletrajzom-nyelvet',
      '157-toi-co-the-thay-doi-ngon-ngu-trong-so-yeu-ly-lich-cua-minh-khong',
      '195-kann-ich-die-sprache-meines-lebenslaufs-andern',
      '181-czy-moge-zmienic-jezyk-mojego-zyciorysu',
      '215-kan-jeg-aendre-sproget-pa-mit-cv',
      '234-voinko-vaihtaa-kielen-ansioluettelostani',
      '253-kan-jeg-endre-spraket-pa-cv-en-min',
      '272-posso-cambiare-la-lingua-del-mio-curriculum',
      '291-kan-jag-andra-spraket-i-mitt-cv',
      '310-kan-ik-de-taal-van-mijn-cv-aanpassen',
      '329-ozgecmisimin-dilini-degistirebilir-miyim',
      '348-mohu-zmenit-jazyk-meho-zivotopisu',
      '367-can-i-change-the-language-of-my-resume',
      '386-article',
      '405-posso-alterar-o-idioma-do-meu-curriculo',
      '427-article',
      '433-article',
      '465-vai-es-varu-mainit-cv-valodu',
      '487-posso-alterar-o-idioma-do-meu-curriculo',
      '529-ar-galiu-pakeisti-savo-gyvenimo-aprasymo-kalba',
      '548-article',
      '567-bolehkah-saya-menukar-bahasa-resume-saya',
      '610-can-i-change-the-language-of-my-resume',
      '624-can-i-change-the-language-of-my-resume',
      '596-puis-je-changer-la-langue-de-mon-cv',
      '643-article',
      '663-can-i-change-the-language-of-my-resume',
      '689-can-i-change-the-language-of-my-resume',
      '700-can-i-change-the-language-of-my-resume',
      '719-can-i-change-the-language-of-my-resume',
      '738-can-i-change-the-language-of-my-resume',
      '757-kas-ma-saan-oma-elulookirjelduse-keelt-muuta',
      '776-mogu-li-promijeniti-jezik-svog-zivotopisa',
      '795-czy-moge-zmienic-jezyk-mojego-cv',
      '814-rezumemin-dilini-dəyisə-bilərəm',
      '914-tүjindememniң-tilin-өzgerte-alamyn',
    ],
    component: ChangeResumeLanguage,
  },
  {
    slug: [
      '42-how-do-i-change-the-font-of-my-resume',
      '43-how-do-i-change-the-font-of-my-resume',
      '522-cum-pot-schimba-fontul-cv-ului',
      '92-comment-changer-la-police-de-mon-cv',
      '112-como-puedo-cambiar-el-tipo-de-letra-de-mi-curriculum',
      '132-bagaimana-cara-mengubah-font-resume-saya',
      '61-how-do-i-change-the-font-of-my-resume',
      '150-hogyan-valtoztathatom-meg-az-oneletrajzom-betutipusat',
      '169-cach-thay-doi-phong-chu-so-yeu-ly-lich-cua-toi',
      '207-wie-andere-ich-die-schriftart-meines-lebenslaufs',
      '193-jak-zmienic-czcionke-w-moim-zyciorysie',
      '227-hvordan-aendrer-jeg-skrifttypen-pa-mit-cv',
      '246-miten-vaihdan-ansioluetteloni-fonttia',
      '265-hvordan-endre-fonter-pa-cv-en',
      '284-come-cambiare-il-font-del-mio-curriculum',
      '303-hur-andrar-jag-typsnittet-i-mitt-cv',
      '322-hoe-pas-ik-het-lettertype-van-mijn-cv-aan',
      '341-ozgecmisimin-yazi-tipini-nasil-degistirebilirim',
      '360-jak-zmenim-pismo-sveho-zivotopisu',
      '398-article',
      '379-article',
      '417-como-altero-a-fonte-do-meu-curriculo',
      '458-article',
      '445-article',
      '477-ka-es-varu-nomainit-cv-burtu-fontu',
      '503-como-altero-a-fonte-do-meu-curriculo',
      '540-kaip-pakeisti-mano-gyvenimo-aprasymo-srifta',
      '560-article',
      '579-bagaimanakah-saya-boleh-mengubah-fon-resume-saya',
      '622-how-do-i-change-the-font-of-my-resume',
      '636-how-do-i-change-the-font-of-my-resume',
      '609-comment-changer-la-police-de-mon-cv',
      '655-article',
      '670-how-do-i-change-the-font-of-my-resume',
      '684-how-do-i-change-the-font-of-my-resume',
      '712-how-do-i-change-the-font-of-my-resume',
      '731-how-do-i-change-the-font-of-my-resume',
      '750-how-do-i-change-the-font-of-my-resume',
      '769-kuidas-muuta-oma-elulookirjelduse-kirjastiili',
      '788-kako-mogu-promijeniti-font-svog-zivotopisa',
      '807-jak-zmienic-czcionke-w-cv',
      '826-rezumemin-sriftini-necə-dəyisdirə-bilərəm',
      '926-rezyumeniң-қaripin-қalaj-өzgertuge-bolady',
    ],
    component: ChangeResumeFont,
  },
  {
    slug: [
      '44-can-i-change-the-columns-of-section-blocks',
      '45-how-do-i-change-the-columns-of-section-blocks',
      '523-cum-pot-schimba-coloanele-din-sectiuni',
      '91-comment-modifier-les-colonnes-des-blocs-de-section',
      '111-como-puedo-cambiar-las-columnas-de-los-bloques-de-seccion',
      '131-bagaimana-cara-mengubah-kolom-blok-bagian',
      '62-how-do-i-change-the-columns-of-section-blocks',
      '151-hogyan-valtoztathatom-meg-a-szakaszblokkok-oszlopait',
      '170-cach-thay-oi-cac-cot-cua-cac-khoi-thong-tin',
      '209-wie-andere-ich-die-spalten-von-abschnittsblocken',
      '194-jak-zmienic-kolumny-blokow-sekcji',
      '228-hvordan-aendrer-jeg-kolonnerne-i-hver-sektion',
      '247-kuinka-muutan-osioiden-sarakkeita',
      '266-hvordan-endrer-jeg-kolonner-under-seksjoner',
      '285-come-cambiare-le-colonne-dei-blocchi-delle-sezioni',
      '304-hur-andrar-jag-kolumnerna-i-sektionsblocken',
      '323-hoe-pas-ik-de-kolommen-van-bepaalde-onderdelen-aan',
      '342-bolum-bloklarinin-sutunlarini-nasil-degistirebilirim',
      '361-jak-zmenim-sloupce-bloku-sekci',
      '380-article',
      '399-article',
      '418-como-altero-as-colunas-dos-blocos-de-secao',
      '459-article',
      '446-article',
      '478-ka-es-varu-nomainit-sadalu-bloku-kolonnas',
      '504-como-altero-as-colunas-dos-blocos-de-secao',
      '541-kaip-pakeisti-skyriu-bloku-stulpelius',
      '561-article',
      '580-bagaimanakan-saya-boleh-mengubah-lajur-blok-bahagian',
      '623-how-do-i-change-the-columns-of-section-blocks',
      '637-how-do-i-change-the-columns-of-section-blocks',
      '608-comment-modifier-les-colonnes-des-blocs-de-section',
      '656-article',
      '669-how-do-i-change-the-columns-of-section-blocks',
      '683-how-do-i-change-the-columns-of-section-blocks',
      '713-how-do-i-change-the-columns-of-section-blocks',
      '732-how-do-i-change-the-columns-of-section-blocks',
      '751-how-do-i-change-the-columns-of-section-blocks',
      '770-kuidas-muuta-loikeplokkide-veergusid',
      '789-kako-mogu-promijeniti-stupce-blokova-sekcija',
      '808-jak-zmienic-kolumny-blokow-przekroju',
      '827-bolmə-bloklarinin-sutunlarini-necə-dəyisdirə-bilərəm',
      '927-bөlimniң-baғandaryn-bloktaryn-өzgerte-alamyn',
    ],
    component: ChangeResumeSection,
  },
  {
    slug: [
      '6-what-can-i-do-with-a-premium-subscription',
      '41-what-can-i-do-with-a-premium-subscription',
      '521-ce-pot-face-cu-un-abonament-premium',
      '90-que-puis-je-faire-avec-un-abonnement-premium',
      '110-que-puedo-hacer-con-una-suscripcion-premium',
      '130-apa-yang-dapat-saya-lakukan-dengan-langganan-premium',
      '63-what-can-i-do-with-a-premium-subscription',
      '149-mit-tehetek-premium-elofizetessel',
      '168-ban-co-the-lam-gi-voi-goi-tra-phi',
      '206-was-kann-ich-mit-einem-premium-abonnement-machen',
      '192-co-moge-robic-w-ramach-subskrypcji-premium',
      '226-hvad-kan-jeg-gore-med-et-praemium-abonnement',
      '245-mita-voin-tehda-premium-tilauksellani',
      '264-hva-kan-jeg-gjore-med-et-premium-abonnement',
      '283-cosa-possono-fare-con-un-abbonamento-premium',
      '302-vad-kan-jag-gora-med-en-premium-prenumeration',
      '321-welke-voordelen-bieden-een-premium-lidmaatschap',
      '340-premium-uyelikle-neler-yapabilirim',
      '359-co-mohu-delat-s-premiovym-predplatnym',
      '378-article',
      '397-article',
      '416-o-que-posso-fazer-com-uma-assinatura-premium',
      '457-article',
      '444-premium',
      '476-kadas-iespejas-ir-premium-abonementam',
      '502-o-que-posso-fazer-com-uma-assinatura-premium',
      '542-ka-galiu-daryti-su-premium-prenumerata',
      '559-article',
      '578-apakah-yang-boleh-saya-lakukan-dengan-langganan-premium',
      '621-what-can-i-do-with-a-premium-subscription',
      '635-what-can-i-do-with-a-premium-subscription',
      '607-que-puis-je-faire-avec-un-abonnement-premium',
      '654-article',
      '673-what-can-i-do-with-a-premium-subscription',
      '692-what-can-i-do-with-a-premium-subscription',
      '711-what-can-i-do-with-a-premium-subscription',
      '730-what-can-i-do-with-a-premium-subscription',
      '49-what-can-i-do-with-a-premium-subscription',
      '768-mida-ma-saan-teha-premium-tellimusega',
      '787-sto-mogu-uciniti-s-premium-pretplatom',
      '806-co-moge-zrobic-z-subskrypcja-premium',
      '825-premium-abunəlik-ilə-nə-edə-bilərəm',
      '925-premium-zhazylymmen-ne-istej-alamyn',
    ],
    component: PremiumSubscription,
  },

  {
    slug: [
      '7-how-do-i-log-in-to-resumedone-co',
      '29-how-do-i-log-in-to-mysmartcv-co',
      '520-cum-ma-conectez-la-creare-cv-com',
      '89-comment-me-connecter-a-modeles-cv',
      '109-como-ingreso-a-ejemplos-curriculum',
      '109-como-ingreso-a-cvexito',
      '109-como-ingreso-a-cvenminutos',
      '129-bagaimana-cara-login-ke-cvmenarik-com',
      '64-how-do-i-log-in-to-resume-example',
      '148-hogyan-tudok-bejelentkezni-a-cvminta-com-oldalra',
      '167-cach-dang-nhap-vao-mau-cv-com',
      '205-wie-melde-ich-mich-bei-lebenslaufschreiben-com-com-an',
      '191-jak-moge-zalogowac-sie-na-cv-wzor-com',
      '225-hvordan-logger-jeg-ind-pa-cv-skabelon-com',
      '244-miten-kirjaudun-cv-pohja-com-iin',
      '263-hvordan-logger-jeg-inn-pa-cveksempel-com',
      '282-come-accedo-a-modellocv-com',
      '301-hur-loggar-jag-in-pa-skriva-cv-com',
      '320-hoe-log-ik-in-op-cv-voorbeeld-com',
      '339-cvhazirlama-cool-a-nasil-giris-yapabilirim',
      '358-jak-se-prihlasim-na-cvvzor-com',
      '377-com',
      '396-thai-resume-com',
      '415-como-faco-o-login-no-modelos-de-curriculo-com',
      '456-cv-in-arabic-com',
      '443-com',
      '475-ka-var-pierakstities-cvparaugs-com',
      '499-como-faco-o-login-no-criar-cv-com',
      '499-como-faco-o-login-no-melhor-cv-com',
      '539-kaip-man-prisijungti-prie-cv-pavyzdys-com',
      '558-cv-hebrew-com',
      '577-bagaimanakah-saya-boleh-mendaftar-masuk-ke-contoh-resume-com',
      '620-how-do-i-log-in-to-elegantcv-app',
      '634-how-do-i-log-in-to-bestonlineresume',
      '606-comment-me-connecter-a-cvdeboss',
      '606-comment-me-connecter-a-atelierde-cv',
      '653-cvforma-com',
      '675-how-do-i-log-in-to-resume-nation',
      '682-how-do-i-log-in-to-cv-lite',
      '710-how-do-i-log-in-to-cv-toolspro',
      '729-how-do-i-log-in-to-resumizeme',
      '748-how-do-i-log-in-to-the-resumeranger',
      '7-how-do-i-log-in-to-resumetiger-app',
      '7-how-do-i-log-in-to-quantumresume-app',
      '7-how-do-i-log-in-to-dreamresume-app',
      '7-how-do-i-log-in-to-cvelevator-app',
      '767-kuidas-ma-cvkoostamine-sisse-logimine',
      '786-Kako-se-mogu-prijaviti-na-zivotopis-primjer',
      '805-jak-zalogowac-sie-do-kreatorcv',
      '824-cvyarat-necə-daxil-ola-bilərəm',
      '924-қalaj-men-zhүjege-kiru-to-kazakhstan-cv',
    ],
    component: LoginToResumeDone,
  },
  {
    slug: [
      '8-how-does-refund-policy-work',
      '40-how-does-refund-policy-work',
      '519-cum-functioneaza-politica-de-rambursare',
      '87-comment-fonctionne-la-politique-de-remboursement',
      '107-como-funciona-la-politica-de-reembolso',
      '127-bagaimana-cara-kerja-kebijakan-pengembalian-dana',
      '65-how-does-refund-policy-work',
      '147-hogyan-mukodik-a-visszateritesi-szabalyzat',
      '166-chinh-sach-hoan-tien',
      '204-wie-funktioniert-die-ruckerstattungsrichtlinie',
      '190-jak-dziala-polityka-zwrotow',
      '224-hvordan-virker-refusionspolitiken',
      '243-miten-hyvityskaytanto-toimii',
      '262-hvordan-var-policy-for-refusjon-fungerer',
      '281-come-funziona-la-politica-di-rimborso',
      '300-hur-fungerar-aterbetalningspolicyn',
      '319-hoe-werkt-het-terugbetalingsbeleid',
      '338-iade-politikasi-nasil-isler',
      '357-jak-funguji-zasady-vraceni-penez',
      '376-article',
      '395-article',
      '414-como-funciona-a-politica-de-reembolso',
      '455-article',
      '442-article',
      '474-ka-darbojas-atcelsanas-politika',
      '501-como-funciona-a-politica-de-reembolso',
      '538-kaip-veikia-pinigu-grazinimo-politika',
      '557-article',
      '576-cara-dasar-bayaran-balik-berfungsi',
      '619-how-does-refund-policy-work',
      '633-how-does-refund-policy-work',
      '605-comment-fonctionne-la-politique-de-remboursement',
      '652-article',
      '672-how-does-refund-policy-work',
      '691-how-does-refund-policy-work',
      '709-how-does-refund-policy-work',
      '728-how-does-refund-policy-work',
      '747-how-does-refund-policy-work',
      '766-kuidas-tagastamispoliitika-toimib',
      '785-kako-funkcioniraju-pravila-povrata-novca',
      '804-jak-dziala-polityka-zwrotow',
      '823-odənisin-geri-qaytarilma-siyasəti-necə-isləyir',
      '923-қajtaru-sayasaty-қalaj-zhұmys-istejdi',
    ],
    component: RefundPolicy,
  },
  {
    slug: [
      '9-how-to-delete-your-account-from-the-website',
      '30-how-to-delete-your-account-from-the-website',
      '514-cum-poate-fi-sters-un-cont-de-pe-site-ul-web',
      '83-comment-supprimer-votre-compte-du-site-web',
      '102-como-eliminar-mi-cuenta-del-sitio-web',
      '122-cara-menghapus-akun-anda-dari-situs-web',
      '66-how-to-delete-your-account-from-the-website',
      '142-hogyan-torolheti-fiokjat-a-webhelyen-keresztul',
      '161-cach-xoa-tai-khoan-cua-ban-khoi-website',
      '199-so-loschen-sie-ihr-konto-von-der-website',
      '185-jak-usunac-konto-przez-strone',
      '219-hvordan-du-sletter-din-konto-fra-hjemmesiden',
      '238-kuinka-poistat-tilisi-nettisivuiltamme',
      '257-hvordan-slette-kontoen-min-fra-nettsiden',
      '276-come-cancellare-il-tuo-account-dal-sito-web',
      '295-hur-man-raderar-sitt-konto-fran-webbsidan',
      '314-hoe-kun-je-je-account-van-de-website-verwijderen',
      '333-internet-sitesi-uzerinden-hesabimi-nasil-silebilirim',
      '352-jak-smazat-svuj-ucet-z-webu',
      '371--',
      '390-article',
      '409-como-excluir-sua-conta-do-site',
      '450-article',
      '437-article',
      '469-ka-es-varu-izdzest-savu-kontu-majaslapa',
      '492-como-excluir-sua-conta-do-site',
      '533-kaip-istrinti-savo-paskyra-is-jusu-svetaines',
      '552-article',
      '571-cara-untuk-memadamkan-akaun-anda-dari-laman-web',
      '614-how-to-delete-your-account-from-the-website',
      '628-how-to-delete-your-account-from-the-website',
      '600-comment-supprimer-votre-compte-du-site-web',
      '647-article',
      '674-how-to-delete-your-account-from-the-website',
      '687-how-to-delete-your-account-from-the-website',
      '704-how-to-delete-your-account-from-the-website',
      '723-how-to-delete-your-account-from-the-website',
      '742-how-to-delete-your-account-from-the-website',
      '761-kuidas-kustutada-oma-konto-veebisaidilt',
      '780-kako-izbrisati-svoj-racun-s-web-stranice',
      '799-jak-usunac-swoje-konto-z-serwisu',
      '818-hesabinizi-veb-saytdan-necə-silmək-olar',
      '918-tirkelgiңizdi-veb-sajttan-қalaj-zhoyuғa-bolady',
    ],
    component: DeleteAccount,
  },
  {
    slug: [
      '10-how-to-cancel-your-subscription-account-via-mail',
      '31-how-to-cancel-your-subscription-account-via-mail',
      '513-cum-poate-fi-anulat-abonamentul-prin-e-mail',
      '82-comment-annuler-votre-compte-dabonnement-via-mail',
      '101-como-cancelar-mi-cuenta-de-suscripcion-a-traves-de-correo-electronico',
      '121-cara-membatalkan-akun-langganan-melalui-mail',
      '67-how-to-cancel-your-subscription-account-via-mail',
      '141-az-elofizetesi-fiok-megszuntetese-e-mail-cim-megadasaval',
      '160-cach-huy-dang-ky-cua-ban-qua-mail',
      '198-so-kundigen-sie-ihr-abo-konto-per-mail',
      '184-jak-anulowac-subskrypcje-przez-maila',
      '218-hvordan-du-opsiger-dit-abonnement-via-email',
      '237-kuinka-peruutan-tilaukseni-sahkopostin-valityksella',
      '256-hvordan-avslutte-abonnementet-ditt-via-mail',
      '275-come-cancellare-l-abbonamento-dell-account-via-email',
      '294-hur-man-avslutar-sitt-prenumerationskonto-via-mejl',
      '313-hoe-kun-je-je-lidmaatschap-opzeggen-via-e-mail',
      '332-uyeligimi-e-posta-araciligiyla-nasil-iptal-edebilirim',
      '351-jak-zrusit-predplatne-a-ucet-prostrednictvim-posty',
      '370-article',
      '389-article',
      '408-como-cancelar-sua-conta-de-assinatura-via-mail',
      '449-article',
      '436-article',
      '468-vai-es-varu-lejupieladet-savu-cv-word-formata',
      '491-como-cancelar-sua-conta-de-assinatura-via-mail',
      '532-kaip-atsaukti-paskyros-prenumerata-el-pastu',
      '551-article',
      '570-cara-untuk-membatalkan-akaun-langganan-anda-melalui-mel',
      '613-how-to-cancel-your-subscription-account-via-mail',
      '627-how-to-cancel-your-subscription-account-via-mail',
      '599-comment-annuler-votre-compte-dabonnement-via-mail',
      '646-article',
      '671-how-to-cancel-your-subscription-account-via-mail',
      '690-how-to-cancel-your-subscription-account-via-mail',
      '703-how-to-cancel-your-subscription-account-via-mail',
      '722-how-to-cancel-your-subscription-account-via-mail',
      '741-how-to-cancel-your-subscription-account-via-mail',
      '760-kuidas-tuhistada-oma-tellimuse-konto-maili-kaudu',
      '779-kako-otkazati-svoj-pretplatnicki-racun-putem-poste',
      '798-jak-anulowac-konto-subskrypcyjne-za-posrednictwem-poczty',
      '817-abunə-hesabinizi-e-poct-vasitəsilə-necə-ləgv-edə-bilərsiniz',
      '917-zhazylymnan-poshta-arқyly-қalaj-bas-tartuғa-bolady',
    ],
    component: CancelSubscription,
  },
];
