import PersonalInfoIconV3 from './PersonalInfoIconV3';
import IconsExperiment from './IconsExperiment';

const PersonalInfoIconExperiment = (props) => {
  return <IconsExperiment iconV3={<PersonalInfoIconV3 {...props} />} />;
};

PersonalInfoIconExperiment.displayName = 'PersonalInfoIconExperiment';

export default PersonalInfoIconExperiment;
