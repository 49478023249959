import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { browserVersion, isSafari } from 'react-device-detect';
import styled, { css } from 'styled-components';

import Cover from '/imports/core/ui/atoms/Cover';
import { staticFile } from '/lib/helpers';

class Loading extends PureComponent {
  static propTypes = {
    withBackground: PropTypes.bool,
  };

  render() {
    const { withBackground = false } = this.props;
    const resources = [
      <source srcSet={staticFile('media/img/loader.webp')} type="image/webp" key={1} />,
      <img src={staticFile('media/img/loader.gif')} alt="loader" key={2} />,
    ];

    if (isSafari && parseInt(browserVersion) >= 14) {
      resources.reverse();
    }

    return (
      <LoadingCont withBackground={withBackground}>
        <Spinner>
          <picture>{resources}</picture>
        </Spinner>
      </LoadingCont>
    );
  }
}

Loading.displayName = 'Loading';

const LoadingCont = styled(Cover)`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ withBackground }) =>
    withBackground &&
    css`
      background-color: var(--light-values-white);
      position: fixed;
      z-index: 999999;
      pointer-events: none;
    `}
`;

const Spinner = styled.p`
  margin: 0;
  padding: 0;
  color: ${(props) => props.theme.colors.primary};
  picture {
    > img,
    source {
      width: 70px;
      height: 70px;
    }
  }
`;

export default Loading;
