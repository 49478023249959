import React from 'react';
import styled, { css } from 'styled-components';

import View from '/imports/pdf/core/ui/atoms/View';

const PhoneIcon = () => {
  return (
    <IconCont>
      <SvgIcon viewBox="0 0 12 12">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M11.994 8.485a.494.494 0 0 1-.167.447l-1.582 1.472a.89.89 0 0 1-.279.19 1.217 1.217 0 0 1-.565.111c-.151 0-.395-.024-.732-.072-.337-.049-.749-.168-1.237-.357-.487-.19-1.04-.474-1.659-.853-.618-.38-1.276-.9-1.974-1.562a12.483 12.483 0 0 1-1.38-1.473 10.34 10.34 0 0 1-.88-1.299 6.71 6.71 0 0 1-.499-1.082 5.391 5.391 0 0 1-.227-.837 2.623 2.623 0 0 1-.035-.775 1.03 1.03 0 0 1 .309-.58L2.669.331A.542.542 0 0 1 3.05.175a.46.46 0 0 1 .273.084.792.792 0 0 1 .202.206L4.798 2.73a.513.513 0 0 1 .059.39.68.68 0 0 1-.202.357l-.583.547a.198.198 0 0 0-.041.072.264.264 0 0 0-.018.084c.032.156.103.334.214.535.095.179.242.396.44.653.198.256.48.552.845.887.356.342.674.607.951.797.278.19.51.329.696.418.186.09.329.144.428.162l.149.028a.269.269 0 0 0 .077-.017.196.196 0 0 0 .077-.039l.678-.647a.756.756 0 0 1 .5-.178.65.65 0 0 1 .321.067h.012l2.295 1.271c.167.097.266.22.298.368z"
        />
      </SvgIcon>
    </IconCont>
  );
};

const IconCont = styled(View)`
  position: absolute;
  top: 0;
  ${({ theme: { convertPx, isRTL } }) => css`
    left: ${isRTL ? 'auto' : convertPx(-2)};
    background: var(--black);
    width: ${convertPx(20)};
    height: ${convertPx(20)};
    border-radius: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: ${convertPx(4)};
    margin-top: ${convertPx(4)};
  `}
`;

const SvgIcon = styled.svg`
  stroke: none;
  width: ${({ theme }) => theme.convertPx(12)};
  height: ${({ theme }) => theme.convertPx(12)};
`;

export default PhoneIcon;
