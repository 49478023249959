import styled from 'styled-components';

import Text from '/imports/core/ui/atoms/Text';

const CheckoutText = styled(Text)`
  font-family: ${(p) => p.theme.font.family.correctText};
  font-size: ${({ theme }) => theme.font.size.base};
  line-height: 1;
  color: #3d4047;
`;

CheckoutText.displayName = 'CheckoutText';

export default CheckoutText;
