import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import BlockControls from '/imports/generator/ui/components/BlockControls';
import ControlsModal from '/imports/generator/ui/components/ControlsModal';
import SvgIcon from '/imports/core/ui/atoms/SvgIcon';

class ControlsMobileButton extends PureComponent {
  static propTypes = {
    content: PropTypes.string,
    titleEditMode: PropTypes.bool,
  };

  state = {
    modalOpened: false,
  };

  toggleModal = () => this.setState({ modalOpened: !this.state.modalOpened });

  render() {
    const { modalOpened } = this.state;
    const { content } = this.props;
    return (
      <Fragment>
        <Button onClick={this.toggleModal}>
          <SvgIcon.More width="19px" height="6px" viewBox="0 0 17 4" />
        </Button>
        <ControlsModal
          onClose={this.toggleModal}
          content={content}
          {...this.props}
          noCancel
          openStateBase
          open={modalOpened}
        >
          <BlockControls {...this.props} onAfterAction={this.toggleModal} isMobile />
        </ControlsModal>
      </Fragment>
    );
  }
}

ControlsMobileButton.displayName = 'ControlsMobileButton';

const Button = styled.div`
  position: relative;
  width: 25px;
  height: 100%;
  display: flex;
  align-items: center;
  svg {
    transform: scale(1.3);
  }
`;

export default ControlsMobileButton;
