import React, { createContext, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { getStripeSCAEnabled } from '/imports/checkout/api/utils';

const StripeContext = createContext();

// Provider component that wraps your app and makes store object
// available to any child component that calls useStripe().
export function StripeProvider({ children, value }) {
  const stripe = useStripeProvider(value);
  return <StripeContext.Provider value={stripe}>{children}</StripeContext.Provider>;
}

StripeProvider.propTypes = {
  children: PropTypes.node,
  value: PropTypes.string,
};

// Hook for child components to get the context object
// and re-render when it changes.
const useStripe = () => useContext(StripeContext);

// Provider hook that creates store object and handles state
function useStripeProvider(publishableKey) {
  useEffect(() => {
    if (!getStripeSCAEnabled()) {
      if (window.Stripe) {
        window.Stripe.setPublishableKey(publishableKey);
      } else {
        const stripeScript = document.querySelector('#stripe-js');
        stripeScript.onload = () => {
          window.Stripe.setPublishableKey(publishableKey);
        };
      }
    }
  }, []);

  // Sends the credit card payment information to Stripe
  // and returns a tokenized card.
  const createToken = ({ number, month, year, cvc, name }) =>
    new Promise((resolve, reject) => {
      window.Stripe.createToken(
        {
          number: number.toString(),
          exp_month: parseInt(month, 10),
          exp_year: parseInt(year, 10),
          cvc: cvc.toString(),
          name,
        },
        (status, response) => {
          if (response.error) {
            return reject(response.error.message);
          }
          // Return token ID
          return resolve(response.id);
        },
      );
    });

  return {
    createToken,
  };
}

export default useStripe;
