import styled from 'styled-components';

const SkillsIcon = ({ width = '20px', height = '20px', viewBox = '0 0 20 20' }) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
    <path d="M18.485 1.515c1.761 1.76 1.236 5.063-.857 8.485 2.093 3.422 2.618 6.724.857 8.485-1.76 1.761-5.063 1.236-8.485-.857-3.422 2.093-6.724 2.618-8.485.857-1.751-1.75-1.241-5.027.823-8.429l.035-.055V10C.278 6.577-.247 3.275 1.514 1.515c1.76-1.76 5.062-1.236 8.484.856l.001.001.056-.034c3.402-2.064 6.678-2.574 8.43-.823zM16.367 11.83l-.124.159a24.867 24.867 0 01-2 2.254 24.753 24.753 0 01-2.413 2.123c2.35 1.266 4.38 1.566 5.241.705.861-.861.561-2.891-.704-5.241zm-12.735-.002l-.115.222c-1.167 2.256-1.422 4.187-.588 5.021.861.862 2.892.56 5.242-.705a24.637 24.637 0 01-2.414-2.123 24.751 24.751 0 01-2.125-2.415zM10 4.774l-.121.087c-.904.65-1.82 1.424-2.707 2.31a22.043 22.043 0 00-2.398 2.83c.668.942 1.471 1.901 2.398 2.827.926.927 1.885 1.73 2.828 2.398a22.077 22.077 0 002.828-2.398A22.043 22.043 0 0015.226 10a22.055 22.055 0 00-2.398-2.828A22.043 22.043 0 0010 4.774zM10 9a1 1 0 110 2 1 1 0 010-2zM2.929 2.929c-.862.861-.56 2.892.705 5.242.628-.819 1.34-1.63 2.123-2.414a24.752 24.752 0 012.414-2.124c-2.35-1.265-4.38-1.566-5.242-.704zm9.121.588l-.222.115c.819.63 1.63 1.341 2.415 2.125a24.752 24.752 0 012.124 2.414c1.265-2.35 1.566-4.38.704-5.242-.834-.834-2.765-.579-5.02.588z" />
  </SvgIcon>
);

const SvgIcon = styled.svg`
  stroke: none;
  fill: currentColor;
`;

SkillsIcon.displayName = 'SkillsIcon';

export default SkillsIcon;
