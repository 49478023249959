const cv_lite_slugs = (t) => {
  return {
    'landing.title': t('cvlite_landing'),
    'landing.description': t('cvlite_landing.description'),
    'landing.list_item_1': t('cvlite_landing.list_item1'),
    'landing.list_item_2': t('cvlite_landing.list_item2'),
    'landing.list_item_3': t('cvlite_landing.list_item3'),
    resume_nation_landing_s1_p1: t('cvlite_landing_s1_p1'),
    resume_nation_landing_s1_p3: t('cv_lite_landing_s1_p3'),
    resume_nation_landing_asset_2: t('resume_nation_landing_asset_2'),
    resume_nation_landing_s2_p1: t('cv_lite_landing_s2_p1'),
    resume_nation_landing_s2_p2: t('cv_lite_landing_s2_p2'),
    resume_nation_landing_s3_p1: t('cv_lite_landing_s3_p1'),
    resume_nation_landing_s3_p2: t('cv_lite_landing_s3_p2'),
    resume_nation_landing_s3_p3: t('cv_lite_landing_s3_p3'),
    resume_nation_landing_s3_p4: t('cv_lite_landing_s3_p4'),
    resume_nation_landing_s3_p5: t('cv_lite_landing_s3_p5'),
    resume_nation_landing_s3_p6: t('cv_lite_landing_s3_p6'),
    resume_nation_landing_s3_p7: t('cv_lite_landing_s3_p7'),
    resume_nation_landing_s3_p8: t('cv_lite_landing_s3_p8'),
    resume_nation_landing_s4_p1: t('cv_lite_landing_s4_p1'),
    resume_nation_landing_s4_p2: t('cv_lite_landing_s4_p2'),
    resume_nation_landing_s5_p1: t('cv_lite_landing_s5_p1'),
    resume_nation_landing_s5_p2: t('cv_lite_landing_s5_p2'),
    resume_nation_landing_s5_p3: t('cv_lite_landing_s5_p3'),
    resume_nation_landing_s5_p4: t('cv_lite_landing_s5_p4'),
    resume_nation_landing_s5_p5: t('cv_lite_landing_s5_p5'),
    resume_nation_landing_s5_p6: t('cv_lite_landing_s5_p6'),
    resume_nation_landing_s5_p7: t('cv_lite_landing_s5_p7'),
    resume_nation_landing_s5_p8: t('cv_lite_landing_s5_p8'),
    resume_nation_landing_s5_p9: t('cv_lite_landing_s5_p9'),
    resume_nation_landing_s5_p10: t('cv_lite_landing_s5_p10'),
    resume_nation_landing_s5_p11: t('cv_lite_landing_s5_p11'),
    resume_nation_landing_s5_p12: t('cv_lite_landing_s5_p12'),
    resume_nation_landing_s5_p13: t('cv_lite_landing_s5_p13'),
    resume_nation_landing_s5_p14: t('cv_lite_landing_s5_p14'),
    resume_nation_landing_s5_p15: t('cv_lite_landing_s5_p15'),
    resume_nation_landing_s5_p16: t('cv_lite_landing_s5_p16'),
    resume_nation_landing_s5_p17: t('cv_lite_landing_s5_p17'),
    resume_nation_landing_s5_p18: t('cv_lite_landing_s5_p18'),
    resume_nation_landing_s5_p19: t('cv_lite_landing_s5_p19'),
    resume_nation_landing_s5_p20: t('cv_lite_landing_s5_p20'),
    resume_nation_landing_s5_p21: t('cv_lite_landing_s5_p21'),
    resume_nation_landing_s5_p22: t('cv_lite_landing_s5_p22'),
    resume_nation_landing_s6_p1: t('cv_lite_landing_s6_p1'),
    resume_nation_landing_s6_p2: t('cv_lite_landing_s6_p2'),
  };
};

const default_slugs = (t) => {
  return {
    'landing.title': t('landing.title'),
    'landing.description': t('landing.description'),
    'landing.list_item_1': t('landing.list_item_1'),
    'landing.list_item_2': t('landing.list_item_2'),
    'landing.list_item_3': t('landing.list_item_3'),
    resume_nation_landing_s1_p1: t('resume_nation_landing_s1_p1'),
    resume_nation_landing_s1_p2: t('resume_nation_landing_s1_p2'),
    resume_nation_landing_s1_p3: t('resume_nation_landing_s1_p3'),
    resume_nation_landing_asset_2: t('resume_nation_landing_asset_2'),
    resume_nation_landing_s2_p1: t('resume_nation_landing_s2_p1'),
    resume_nation_landing_s2_p2: t('resume_nation_landing_s2_p2'),
    resume_nation_landing_s3_p1: t('resume_nation_landing_s3_p1'),
    resume_nation_landing_s3_p2: t('resume_nation_landing_s3_p2'),
    resume_nation_landing_s3_p3: t('resume_nation_landing_s3_p3'),
    resume_nation_landing_s3_p4: t('resume_nation_landing_s3_p4'),
    resume_nation_landing_s3_p5: t('resume_nation_landing_s3_p5'),
    resume_nation_landing_s3_p6: t('resume_nation_landing_s3_p6'),
    resume_nation_landing_s3_p7: t('resume_nation_landing_s3_p7'),
    resume_nation_landing_s3_p8: t('resume_nation_landing_s3_p8'),
    resume_nation_landing_s4_p1: t('resume_nation_landing_s4_p1'),
    resume_nation_landing_s4_p2: t('resume_nation_landing_s4_p2'),
    resume_nation_landing_s5_p1: t('resume_nation_landing_s5_p1'),
    resume_nation_landing_s5_p2: t('resume_nation_landing_s5_p2'),
    resume_nation_landing_s5_p3: t('resume_nation_landing_s5_p3'),
    resume_nation_landing_s5_p4: t('resume_nation_landing_s5_p4'),
    resume_nation_landing_s5_p5: t('resume_nation_landing_s5_p5'),
    resume_nation_landing_s5_p6: t('resume_nation_landing_s5_p6'),
    resume_nation_landing_s5_p7: t('resume_nation_landing_s5_p7'),
    resume_nation_landing_s5_p8: t('resume_nation_landing_s5_p8'),
    resume_nation_landing_s5_p9: t('resume_nation_landing_s5_p9'),
    resume_nation_landing_s5_p10: t('resume_nation_landing_s5_p10'),
    resume_nation_landing_s5_p11: t('resume_nation_landing_s5_p11'),
    resume_nation_landing_s5_p12: t('resume_nation_landing_s5_p12'),
    resume_nation_landing_s5_p13: t('resume_nation_landing_s5_p13'),
    resume_nation_landing_s5_p14: t('resume_nation_landing_s5_p14'),
    resume_nation_landing_s5_p15: t('resume_nation_landing_s5_p15'),
    resume_nation_landing_s5_p16: t('resume_nation_landing_s5_p16'),
    resume_nation_landing_s5_p17: t('resume_nation_landing_s5_p17'),
    resume_nation_landing_s5_p18: t('resume_nation_landing_s5_p18'),
    resume_nation_landing_s5_p19: t('resume_nation_landing_s5_p19'),
    resume_nation_landing_s5_p20: t('resume_nation_landing_s5_p20'),
    resume_nation_landing_s5_p21: t('resume_nation_landing_s5_p21'),
    resume_nation_landing_s5_p22: t('resume_nation_landing_s5_p22'),
    resume_nation_landing_s6_p1: t('resume_nation_landing_s6_p1'),
    resume_nation_landing_s6_p2: t('resume_nation_landing_s6_p2'),
  };
};

const cvtoolspro_slugs = (t) => {
  return {
    'landing.title': t('cvtoolspro_landing'),
    'landing.description': t('cvtoolspro_description'),
    'landing.list_item_1': t('cvtoolspro.list_item_1'),
    'landing.list_item_2': t('cvtoolspro.list_item_2'),
    'landing.list_item_3': t('cvtoolspro.list_item_3'),
    resume_nation_landing_s1_p1: t('cvtoolspro_s1_p1'),
    resume_nation_landing_s1_p2: t('cvtoolspro_s1_p2'),
    resume_nation_landing_s1_p3: t('cvtoolspro_s1_p3'),
    resume_nation_landing_asset_2: t('resume_nation_landing_asset_2'),
    resume_nation_landing_s2_p1: t('cvtoolspro_s2_p1'),
    resume_nation_landing_s2_p2: t('cvtoolspro_s2_p2'),
    resume_nation_landing_s3_p1: t('cvtoolspro_s3_p1'),
    resume_nation_landing_s3_p2: t('cvtoolspro_s3_p2'),
    resume_nation_landing_s3_p3: t('cvtoolspro_s3_p3'),
    resume_nation_landing_s3_p4: t('cvtoolspro_s3_p4'),
    resume_nation_landing_s3_p5: t('cvtoolspro_s3_p5'),
    resume_nation_landing_s3_p6: t('cvtoolspro_s3_p6'),
    resume_nation_landing_s3_p7: t('cvtoolspro_s3_p7'),
    resume_nation_landing_s3_p8: t('cvtoolspro_s3_p8'),
    resume_nation_landing_s4_p1: t('cvtoolspro_s4_p1'),
    resume_nation_landing_s4_p2: t('cvtoolspro_s4_p2'),
    resume_nation_landing_s5_p1: t('cvtoolspro_s5_p1'),
    resume_nation_landing_s5_p2: t('cvtoolspro_s5_p2'),
    resume_nation_landing_s5_p3: t('cvtoolspro_s5_p3'),
    resume_nation_landing_s5_p4: t('cvtoolspro_s5_p4'),
    resume_nation_landing_s5_p5: t('cvtoolspro_s5_p5'),
    resume_nation_landing_s5_p6: t('cvtoolspro_s5_p6'),
    resume_nation_landing_s5_p7: t('cvtoolspro_s5_p7'),
    resume_nation_landing_s5_p8: t('cvtoolspro_s5_p8'),
    resume_nation_landing_s5_p9: t('cvtoolspro_s5_p9'),
    resume_nation_landing_s5_p10: t('cvtoolspro_s5_p10'),
    resume_nation_landing_s5_p11: t('cvtoolspro_s5_p11'),
    resume_nation_landing_s5_p12: t('cvtoolspro_s5_p12'),
    resume_nation_landing_s5_p13: t('cvtoolspro_s5_p13'),
    resume_nation_landing_s5_p14: t('cvtoolspro_s5_p14'),
    resume_nation_landing_s5_p15: t('cvtoolspro_s5_p15'),
    resume_nation_landing_s5_p16: t('cvtoolspro_s5_p16'),
    resume_nation_landing_s5_p17: t('cvtoolspro_s5_p17'),
    resume_nation_landing_s5_p18: t('cvtoolspro_s5_p18'),
    resume_nation_landing_s5_p19: t('cvtoolspro_s5_p19'),
    resume_nation_landing_s5_p20: t('cvtoolspro_s5_p20'),
    resume_nation_landing_s5_p21: t('cvtoolspro_s5_p21'),
    resume_nation_landing_s5_p22: t('cvtoolspro_s5_p22'),
    resume_nation_landing_s6_p1: t('cvtoolspro_s6_p1'),
    resume_nation_landing_s6_p2: t('cvtoolspro_s6_p2'),
  };
};

export const content_landing_slugs = (host, t) => {
  switch (host) {
    case 'cv-lite':
      return cv_lite_slugs(t);
    case 'cvtoolspro':
      return cvtoolspro_slugs(t);
    default:
      return default_slugs(t);
  }
};
