import styled, { css } from 'styled-components';

// BlockSection is a group of related blocks.
// For example, a "Education" or "Employment" section.
const BlockSection = styled.div`
  ${({ theme: { convertPt, convertPx }, isDragDisabled, isBreakable, left }) => css`
    padding-top: ${convertPt(10)};
    padding-bottom: ${convertPt(10)};
    page-break-inside: avoid !important;
    break-inside: avoid !important;
    padding-left: ${convertPx(left ? 45 : 31)};
    padding-right: ${convertPx(left ? 31 : 45)};
    cursor: ${isDragDisabled ? 'unset' : 'move'}!important;
    z-index: 99;
    ${isBreakable &&
    css`
      break-inside: auto !important;
      page-break-inside: auto !important;
    `}
  `}
`;

export default BlockSection;
