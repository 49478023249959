import React from 'react';
import styled from 'styled-components';

const PdfIcon = (props) => {
  return (
    <SvgIcon width="18" height="23" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="#000" fillRule="nonzero">
        <path d="M13.136 10.017a.63.63 0 00.09-.165c-.048-.026-.112-.078-.46-.078a5.56 5.56 0 00-.708.043c.319.248.396.374.605.374.091 0 .353-.004.473-.175zM5.688 12.941c.058-.019.399-.176 1.033-1.362-.837.476-1.177.867-1.201 1.088-.005.036-.015.132.168.274z" />
        <path d="M17.804 15.466V5.543c0-.304-.036-.529-.215-.71L13.036.217a.735.735 0 00-.52-.218H1.159C.695 0 .196.363.196 1.16v14.306h17.608zM12.326 1.345a.2.2 0 01.343-.144l3.95 4.003c.127.128.038.348-.142.348h-4.15V1.345zM4.865 12.592c.07-.645.859-1.32 2.342-2.01A26.858 26.858 0 008.689 6.32c-.39-.861-.77-1.979-.494-2.634.098-.23.218-.406.444-.482.09-.03.315-.068.398-.068.197 0 .37.257.493.416.116.149.377.465-.146 2.697.528 1.104 1.275 2.228 1.991 2.999a7.516 7.516 0 011.314-.142c.613 0 .984.144 1.136.443.125.246.074.535-.153.856-.218.309-.518.472-.869.472-.475 0-1.03-.304-1.647-.906-1.11.235-2.407.655-3.455 1.12-.327.703-.64 1.27-.932 1.685-.4.57-.746.836-1.089.836a.623.623 0 01-.378-.13c-.408-.31-.463-.655-.437-.89zM5.956 18.185a.65.65 0 00-.232-.195.855.855 0 00-.396-.079h-.482v1.583h.589a.69.69 0 00.233-.04.585.585 0 00.366-.39 1.234 1.234 0 00.032-.635.658.658 0 00-.11-.244z" />
        <path d="M9.134 7.207c-.28.981-.649 2.04-1.046 3a19.362 19.362 0 012.542-.802 13.581 13.581 0 01-1.496-2.198zM.196 16.259v5.948c0 .4.477.793.963.793h15.682c.486 0 .963-.393.963-.793v-5.948H.196zm6.415 2.946a1.15 1.15 0 01-.283.414 1.275 1.275 0 01-.439.26c-.17.062-.36.093-.567.093h-.476v1.442h-.642v-3.996h1.134c.167 0 .333.027.497.081.164.055.311.136.442.244.13.109.235.24.315.393.08.154.12.326.12.518.001.203-.033.386-.1.55zm3.935.943c-.07.233-.157.428-.264.586a1.37 1.37 0 01-.362.371c-.133.09-.263.158-.387.204-.125.045-.24.074-.343.087a2.25 2.25 0 01-.23.018H7.468v-3.996h1.187c.332 0 .624.054.875.16.251.106.46.249.626.425.166.177.29.38.372.605.081.225.123.458.123.697 0 .329-.035.61-.105.843zm3.502-2.237h-1.814v1.258h1.648v.445h-1.648v1.8h-.653v-3.996h2.467v.493z" />
        <path d="M9.69 18.353a1.2 1.2 0 00-.457-.32c-.192-.081-.442-.122-.749-.122H8.11v3.025h.636c.436 0 .75-.14.942-.422.192-.282.289-.69.289-1.226 0-.166-.02-.33-.059-.493a1.06 1.06 0 00-.227-.442zM8.897 3.851c-.039.013-.52.697.037 1.276.372-.84-.02-1.281-.037-1.276z" />
      </g>
    </SvgIcon>
  );
};

const SvgIcon = styled.svg`
  stroke: none;
`;

export default PdfIcon;
