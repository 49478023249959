export const getLandingContent = (t) => {
  return `
${t('landing_page_line1')}

${t('landing_page_line2')}
${t('landing_page_line3')} 
${t('landing_page_line4')}

${t('landing_page_line5')}

${t('landing_page_line6')}

${t('landing_page_line7')}

## ${t('landing_page_line8')}

${t('landing_page_line9')}

${t('landing_page_line10')}

## ${t('landing_page_line11')}

${t('landing_page_line12')} 

${t('landing_page_line13')}  

${t('landing_page_line14')}

${t('landing_page_line15')}

${t('landing_page_line16')}  

${t('landing_page_line17')}  

${t('landing_page_line18')}

## ${t('landing_page_line19')}

${t('landing_page_line20')} 

## ${t('landing_page_line21')}

${t('landing_page_line22')}

${t('landing_page_line23')}

${t('landing_page_line24')}

## ${t('landing_page_line25')}

${t('landing_page_line26')}  

${t('landing_page_line27')}  

${t('landing_page_line28')}

## ${t('landing_page_line29')}

${t('landing_page_line30')} 
${t('landing_page_line31')} 

${t('landing_page_line32')}  

## ${t('landing_page_line33')}

${t('landing_page_line34')}  

${t('landing_page_line35')}  

## ${t('landing_page_line36')}

${t('landing_page_line37')}  
${t('landing_page_line38')}  
${t('landing_page_line39')}  

## ${t('landing_page_line40')}

${t('landing_page_line41')}
${t('landing_page_line42')}
${t('landing_page_line43')}
 
${t('landing_page_line44')}

${t('landing_page_line45')}

* ${t('landing_page_line46')}

* ${t('landing_page_line47')}

* ${t('landing_page_line48')}

${t('landing_page_line49')}
 
${t('landing_page_line50')}  
 
* ${t('landing_page_line51')}

${t('landing_page_line52')}

## ${t('landing_page_line53')}

${t('landing_page_line54')}

## ${t('landing_page_line55')}

${t('landing_page_line56')}  
${t('landing_page_line57')}
 
${t('landing_page_line58')} 
 
${t('landing_page_line59')}  
 
${t('landing_page_line60')}
 
${t('landing_page_line61')}
 
## ${t('landing_page_line62')}

${t('landing_page_line63')}  
 
${t('landing_page_line64')}  
 
${t('landing_page_line65')} 

## ${t('landing_page_line66')}

${t('landing_page_line67')}

## ${t('landing_page_line68')}

${t('landing_page_line69')}
 
${t('landing_page_line70')} 

## ${t('landing_page_line71')}
${t('landing_page_line72')}

${t('landing_page_line73')}
 
${t('landing_page_line74')}
 
${t('landing_page_line75')}  
${t('landing_page_line76')}
 
${t('landing_page_line77')}

## ${t('landing_page_line78')}
${t('landing_page_line79')} 
  `;
};

export const getModelesCVContent = () => {
  return `
Un processus simple pour être embauché. Vous trouverez ici des informations sur les outils de travail, des conseils, des astuces et des tendances. Tout est ici dans notre blog de recherche d'emploi.

Avec les conseils d'experts en recrutement, vous pouvez trouver le travail que vous aimez - rapidement ! L'emploi de vos rêves vous attend.

Maîtrisez votre recherche d'emploi en suivant nos instructions étape par étape.

Tous les articles de notre blog vous offrent des conseils pratiques qui accélèrent le processus d'embauche.
 
Nos experts ont élaboré ce guide complet sur tout, des CV, lettres de motivation et entretiens à la recherche du bon emploi, à la manière de postuler et au développement de carrière.

Quelle que soit l'étape à laquelle vous vous trouvez dans votre carrière, nous avons ce qu'il vous faut.

Toutes les meilleures astuces, conseils et trucs dont vous aurez besoin pour trouver l'emploi de vos rêves sont ici.


## Donnez-vous confiance

Du début à la fin de votre processus de candidature, nous vous guidons et vous montrons comment apporter même de petits ajustements à votre candidature peut augmenter vos chances de réussite. Afin de vous donner la confiance dont vous avez besoin pour réussir

Que ce soit comment faire un CV qui vous fera remarquer, ou quelles questions vous devriez poser lors d'entretiens d'embauche, comment réussir l'entretien, toutes ces choses que nous avons préparées pour vous pour vous donner la confiance dont vous avez besoin pour montrer au gestionnaire d'embauche que vous êtes la personne recherchée pour le travail.

## L'AMOUR d'aller au travail

Nous voulons que vous trouviez l'emploi de vos rêves et AIMEZ travailler tous les jours.

Notre mission est de vous aider à trouver votre emploi idéal. Un travail si spécial que vous aurez envie de sauter du lit avec enthousiasme pour aller travailler. Nous voulons que vous soyez ravi de votre nouveau travail et que vous ne vous contentez pas d'un pis-aller. Vous méritez un bon travail et nous voulons vous aider à trouver le meilleur travail possible.

Notre priorité absolue est de vous aider à trouver un emploi qui vous mènera là où vous voulez aller. Pas n'importe quel travail, mais un travail de rêve.

Votre travail peut avoir un impact énorme sur votre qualité de vie. Nous voulons donc que vous trouviez un travail qui vous passionne et que vous ayez hâte de travailler. C'est pourquoi nous avons créé ces articles de recherche d'emploi pour vous accompagner dans votre recherche d'emploi.

Les bons emplois peuvent vous rendre heureux, vous donner un nouveau souffle, vous donner de la satisfaction au travail, de l'estime de soi ou de la positivité. Alors qu'il existe des emplois toxiques, cela peut avoir l'effet inverse.

Si vous n'aimez pas votre travail, changez-le. Nous sommes là pour vous aider avec cela. Quelle que soit l'étape à laquelle vous vous trouvez actuellement dans votre recherche d'emploi, nous pouvons vous offrir une certaine valeur pour augmenter vos chances de trouver l'emploi de vos rêves.

Nos experts RH et nos blogueurs en recrutement ont créé des centaines d'articles de recherche d'emploi qui donneront un coup de fouet à votre candidature et vous donneront l'attention que vous méritez.

## Nos experts en recrutement ont une riche expérience.

En conséquence, vous pouvez dire que chaque écrivain, qu'il s'agisse d'un expert en carrière ou d'un rédacteur de blog de recherche d'emploi, se soucie de votre carrière. Ils ont l'expérience nécessaire et savent exactement ce que les demandeurs d'emploi recherchent en termes de conseil, d'orientation ou d'encouragement. 

## Si vous voulez vous améliorer, vous êtes au bon endroit

Si vous n'avez aucune connaissance approfondie du recrutement, vous devrez envoyer des centaines de candidatures avant de pouvoir découvrir ce que les recruteurs recherchent réellement auprès des candidats et à quoi ressemble leur candidat idéal.

Heureusement, notre équipe d'experts en RH a des décennies d'expérience en tant que gestionnaires d'embauche. Nous avons préparé une équipe d'experts qui ont déjà recherché des milliers de candidatures et connaissent tous les secrets du recrutement et exactement ce que vous devez faire pour obtenir les résultats.

Par conséquent, ils vous fourniront de superbes joyaux de recherche d'emploi qui rendront votre recherche d'emploi beaucoup plus fluide. Avoir une excellente ressource pour apprendre des stratégies de recherche d'emploi et des sujets que vous n'avez peut-être pas envisagés auparavant augmentera votre recherche d'emploi.

## Des opportunités vous attendent

Il existe toujours d'innombrables opportunités pour les demandeurs d'emploi. Quelqu'un progresse toujours dans sa carrière et quitte son emploi. Qu'il s'agisse d'une progression de carrière ou d'un départ à la retraite, un déménagement, des opportunités se présentent chaque jour. Il vous suffit de savoir où chercher et comment aborder votre candidature sous un angle gagnant.

Qu'il s'agisse de créer une lettre de motivation plus convaincante ou un CV qui attire l'attention que vous méritez ; nous avons quelque chose de prêt pour vous.

Parce que, si vous n'obtenez pas les réponses que vous méritez, faire la même chose encore et encore, ne fera que perdre votre temps et votre énergie. Trouver une formule de recherche d'emploi prend beaucoup plus de temps lorsque l'on est seul. Laissez notre équipe d'experts RH vous guider et vous montrer les méthodes les plus efficaces.

## Vous êtes prêt

Vous ne pouvez qu'acquérir de l'expérience, pas la perdre, n'est-ce pas ?

Donc, en ce moment même, vous êtes le plus expérimenté et donc le mieux préparé que vous ayez jamais été !

Vous êtes prêt ! Alors qu'est-ce que vous attendez ? Laissez-nous vous montrer comment présenter votre expérience et votre potentiel à un employeur afin qu'il puisse voir et comprendre votre vraie valeur. Nous avons des conseils qui changent la vie et qui peuvent avoir un réel impact sur votre carrière.
  
## Saviez-vous ce que vous valez vraiment ?

Augmentez votre salaire avec nos experts. Des études montrent que la loyauté est rarement récompensée au travail de nos jours, vous devez donc prendre l'initiative et chercher un autre emploi si vous recherchez une augmentation de salaire. Des études ont montré que les personnes qui quittent les entreprises sont récompensées par un meilleur salaire que les employés qui restent dans leur emploi en attendant une augmentation de salaire de fidélité.

La bonne nouvelle, c'est que si vous maîtrisez l'art de la recherche d'emploi en lisant nos articles, vous n'aurez plus jamais à attendre une autre augmentation de salaire.
  
## Les faits

Vous devriez lire ce blog si vous souhaitez découvrir des faits de recrutement pour réussir votre recherche d'emploi.
La recherche, les statistiques et les mises à jour juridiques dominent nos publications, qui sont construites autour de preuves factuelles et de recherches. Certains blogs ne parlent que d'opinions. Rassurez-vous, notre blog contient des faits statistiques tirés des dernières enquêtes sur l'emploi.
C'est la ressource ultime pour la recherche d'emploi.

## Pourquoi notre recherche d'emploi peut vous aider

Bienvenue sur notre blog. L'emploi de vos rêves n'attend que vous.
Vous avez la passion et nous avons la direction pour vous guider.
Il n'y a aucun autre endroit comme celui-ci qui fournit des conseils aussi solides dans tous les secteurs à autant de demandeurs d'emploi.
 
La capacité de nos contributeurs à raconter des histoires intrigantes en utilisant des sujets d'actualité sur les dernières nouvelles et développements du marché de l'emploi fait de notre blog une lecture incontournable pour tout demandeur d'emploi. Sur la base des dernières enquêtes et statistiques sur l'emploi, nous trouvons les informations dont vous avez besoin dans un format pratique.

Quoi que ce soit : 

* Comment rédiger le CV parfait :
[https://modeles-cv.fr/cv-parfait/](https://modeles-cv.fr/cv-parfait/)

* Les compétences professionnelles les plus importantes:
[https://modeles-cv.fr/competences-professionnelles/](https://modeles-cv.fr/competences-professionnelles/)

* Comment envoyer votre CV à l'emploi de vos rêves pour augmenter vos chances
[https://modeles-cv.fr/candidature-spontanee/](https://modeles-cv.fr/candidature-spontanee/)

Nous avons les informations dont vous avez besoin pour réussir. Des informations détaillées peuvent être trouvées dans chaque article. Vous ne trouverez peut-être pas ce site Web pratique pour une lecture facile, mais si vous préférez l'expertise de l'industrie sans qu'elle soit diluée, c'est l'endroit où aller.

Adoptant une approche unique de la recherche d'emploi, nous basons nos articles sur des statistiques et des articles des dernières enquêtes sur l'emploi, des statistiques et des analyses du secteur.
   
* Ne vous laissez pas submerger et stresser

Il n'est pas nécessaire de se sentir dépassé pendant la recherche d'emploi. Ici, sur Resume-Example.com, notre équipe a créé des ressources très utiles qui expliquent tout, de la rédaction d'un CV ou d'une lettre de motivation à la négociation d'une nouvelle offre d'emploi. Éliminez le stress de la recherche d'emploi en parcourant nos conseils de carrière.

## Conseils de CV gratuits de professionnels des RH

Vous ne devriez pas insister sur la façon de rédiger un CV efficace. Nous vous proposons un guide de rédaction de CV complet pour vous aider à mettre en valeur vos capacités. Consultez nos modèles de CV [ici](https://modeles-cv.fr/modeles/) et nos [guides de rédaction de CV](https://modeles-cv.fr/exemples-cv/).

## Donnez-vous une longueur d'avance

La recherche d'emploi est un processus très compétitif. Il y a parfois des centaines d'autres candidats. Souvent, vous constatez que de nombreuses personnes sont dans une situation très similaire à la vôtre. Ils peuvent avoir exactement les mêmes qualifications, la même expérience. Ils ont peut-être même travaillé dans les mêmes entreprises que vous. Ou ils ont fréquenté exactement la même école, collège ou université.

Cela donne aux responsables RH un véritable casse-tête, avec tant de candidats qui se mélangent, il est difficile de les distinguer dans une pile de CV.
 
Vous ne pouvez pas laisser cela au hasard dans l'espoir d'être choisi.

Le meilleur candidat obtient rarement le poste. C'est toujours la candidature la mieux préparée qui obtient le poste.

Vous devez vous donner une longueur d'avance dans chaque section du processus de candidature.

La bonne nouvelle, c'est que **vous êtes au bon endroit**.
 
## Vous pouvez vous tenir la tête et les épaules au-dessus du reste

Les premières impressions comptent, et la première impression est souvent la réception de votre candidature sous la forme d'un CV et d'une lettre de motivation. Beaucoup de demandeurs d'emploi sont très bons dans leur travail, mais beaucoup sont négligés simplement parce qu'ils ne savent pas comment se vendre ou se différencier de la concurrence.

Vous faites peut-être votre travail avec succès depuis des années, mais vous avez peut-être raté de nombreuses opportunités en ne traduisant pas votre succès sur votre CV. Il existe de nombreux trucs et astuces que nos experts en RH ont appris au fil des années qui peuvent vous donner un avantage sur les autres candidats et vous mettre la tête et les épaules au-dessus des autres. Laissez-nous partager nos secrets pour vous aider à vous démarquer et à vous présenter comme le candidat gagnant.

Nos experts sympathiques dynamisent votre recherche d'emploi avec de nouvelles approches et des stratégies professionnelles et ciblées pour votre recherche d'emploi.
 
## Atteignez votre véritable potentiel

N'oubliez pas que vous limiter à une seule stratégie limite vos options et votre avenir. Obtenez une stratégie de recherche d'emploi revitalisée en suivant nos conseils. Le potentiel ne peut être réalisé que si vous êtes préparé pour les opportunités en premier lieu. Vous ne connaîtrez votre véritable potentiel que si vous êtes prêt et préparé tout au long du processus de candidature

## Avez-vous besoin d'une nouvelle stratégie de recherche d'emploi ?

Si vous adoptez la même approche fatiguée de la recherche d'emploi, vous pourriez avoir désespérément besoin de nouvelles stratégies. Il est temps de faire preuve d'un peu de créativité et de découvrir les différentes méthodes qui fonctionnent encore et encore.

Vous avez peut-être de nombreuses compétences, mais si vous ne les présentez pas dans une mise en forme professionnelle, vous n'obtiendrez pas autant de résultats positifs.
 
## Ayez confiance en votre recherche d'emploi
Entrer dans l'entretien armé d'informations sur le fonctionnement du processus de candidature peut vous donner la confiance nécessaire pour mettre en valeur vos capacités.

La recherche d'emploi peut être épuisante. Vous vous retrouvez à vivre une gamme d'émotions tout au long de votre recherche. Si vous n'adoptez pas la bonne approche, vous pourriez vous retrouver découragé et démotivé.

De l'excitation à la déception, vous vivrez sans aucun doute une gamme d'émotions. Les hauts et les bas de la recherche d'emploi peuvent vous coûter cher si vous ne faites pas attention. Cela peut être une expérience très négative si vous ne suivez pas les bons conseils. Envoyer un nombre incalculable des mêmes candidatures génériques peut être comme se cogner la tête contre un mur de briques tout en étant ignoré et en ne recevant aucun retour ; ce n'est pas un endroit sain.

Ce sera une expérience beaucoup plus heureuse si vous suivez les conseils d'experts de notre équipe et vivez plus de hauts et moins de bas.

Nous voulons que vous ressentiez toutes les émotions positives : l'excitation, le bonheur, le plaisir et les célébrations qui peuvent accompagner la recherche d'emploi plutôt que les bas d'une recherche d'emploi malavisée : rejet, déception et désespoir.

Avec notre équipe d'experts derrière vous, vous pouvez vous sentir confiant et heureux dans votre recherche d'emploi.

## Faites passer votre recherche d'emploi au niveau supérieur
Si vous êtes prêt à franchir une nouvelle étape dans votre carrière, consultez nos blogs de recherche d'emploi pour vous aider à réussir.
  `;
};

export const getElegantCVContent = (t) => {
  return `
    
## ${t('elegant_cv_s1_p1')}
## ${t('elegant_cv_s1_p2')}
${t('elegant_cv_s1_p3')} 

${t('elegant_cv_s2_p1')} 

* ${t('elegant_cv_s2_p2')} 
* ${t('elegant_cv_s2_p3')}  
* ${t('elegant_cv_s2_p4')} 
* ${t('elegant_cv_s2_p5')} 
* ${t('elegant_cv_s2_p6')} 
* ${t('elegant_cv_s2_p7')} 
* ${t('elegant_cv_s2_p8')} 
* ${t('elegant_cv_s2_p9')} 
* ${t('elegant_cv_s2_p10')} 
 
${t('elegant_cv_s2_p11')} 

## ${t('elegant_cv_s3_p1')} 
${t('elegant_cv_s3_p2')}  

* ${t('elegant_cv_s3_p3')} 
* ${t('elegant_cv_s3_p4')} 
* ${t('elegant_cv_s3_p5')} 
* ${t('elegant_cv_s3_p6')} 
* ${t('elegant_cv_s3_p7')} 
* ${t('elegant_cv_s3_p8')} 
* ${t('elegant_cv_s3_p9')} 

${t('elegant_cv_s3_p10')} 
 
## ${t('elegant_cv_s4_p1')} 
${t('elegant_cv_s4_p2')} 

${t('elegant_cv_s4_p3')} 
${t('elegant_cv_s4_p4')} 

${t('elegant_cv_s4_p5')} 

${t('elegant_cv_s4_p6')} 

${t('elegant_cv_s4_p7')} 

${t('elegant_cv_s4_p8')} 

${t('elegant_cv_s4_p9')} 

## ${t('elegant_cv_s5_p1')}

${t('elegant_cv_s5_p2')} 

${t('elegant_cv_s5_p5')} 

${t('elegant_cv_s5_p3')} 

${t('elegant_cv_s5_p6')} 

${t('elegant_cv_s5_p4')} 

## ${t('elegant_cv_s6_p1')} 
${t('elegant_cv_s6_p2')} 
 
${t('elegant_cv_s6_p3')} 

${t('elegant_cv_s6_p4')} 

* ${t('elegant_cv_s6_p5')} 
* ${t('elegant_cv_s6_p6')} 
* ${t('elegant_cv_s6_p7')} 
* ${t('elegant_cv_s6_p8')} 

## ${t('elegant_cv_s7_p1')}  

${t('elegant_cv_s7_p2')} 

${t('elegant_cv_s7_p3')} 

${t('elegant_cv_s7_p4')} 

## ${t('elegant_cv_s8_p1')} 
 
${t('elegant_cv_s8_p2')} 
 
${t('elegant_cv_s8_p3')} 

${t('elegant_cv_s8_p4')} 

## ${t('elegant_cv_s8_p5')} 

${t('elegant_cv_s8_p6')} 

${t('elegant_cv_s8_p7')}

## ${t('elegant_cv_s9_p1')} 
 
${t('elegant_cv_s9_p2')} 

${t('elegant_cv_s9_p3')} 

## ${t('elegant_cv_s10_p1')} 

${t('elegant_cv_s10_p2')}

## ${t('elegant_cv_s11_p1')}
${t('elegant_cv_s11_p2')}

${t('elegant_cv_s11_p3')}

${t('elegant_cv_s11_p4')}
## ${t('elegant_cv_s12_p1')}
${t('elegant_cv_s12_p2')}

## ${t('elegant_cv_s13_p1')}
 
${t('elegant_cv_s13_p2')}
${t('elegant_cv_s13_p3')}
 
${t('elegant_cv_s13_p4')}


${t('elegant_cv_s13_p5')}

## ${t('elegant_cv_s14_p1')}

${t('elegant_cv_s14_p2')}
${t('elegant_cv_s14_p3')}
${t('elegant_cv_s14_p4')}
${t('elegant_cv_s14_p5')}

${t('elegant_cv_s14_p6')}


## ${t('elegant_cv_s15_p1')}


${t('elegant_cv_s15_p2')}

${t('elegant_cv_s15_p3')}

${t('elegant_cv_s15_p4')}

${t('elegant_cv_s15_p5')}

${t('elegant_cv_s15_p6')}

${t('elegant_cv_s15_p7')}

${t('elegant_cv_s15_p8')}

${t('elegant_cv_s15_p9')}

${t('elegant_cv_s15_p10')}

${t('elegant_cv_s15_p11')}

${t('elegant_cv_s15_p12')}

${t('elegant_cv_s15_p13')}

${t('elegant_cv_s15_p14')}

${t('elegant_cv_s15_p15')}

${t('elegant_cv_s15_p16')}

${t('elegant_cv_s15_p17')}

${t('elegant_cv_s15_p18')}

${t('elegant_cv_s15_p19')}

${t('elegant_cv_s15_p20')}
 
${t('elegant_cv_s15_p21')}

${t('elegant_cv_s15_p22')}

${t('elegant_cv_s15_p23')}

${t('elegant_cv_s15_p24')}

${t('elegant_cv_s15_p25')}

${t('elegant_cv_s15_p26')}

${t('elegant_cv_s15_p27')}

${t('elegant_cv_s15_p28')}  

${t('elegant_cv_s15_p29')}
`;
};

export const getBrillantCvContent = () => {
  return `
## Land your Dream Job Interview with our Brilliant CV Builder!
## Leap up the career ladder with the BEST CV builder

Job hunting is easy with Brilliant CV! 
Quick, simple and straightforward - we will guide you through each section. In easy, logical steps.

* Exceptional CV templates
* First impressions last - make a brilliant first impression with your CV
* No more difficulties with formatting
* A great CV template for every job
* Show the hiring manager your great attitude with a perfect CV
* Get to that all-important interview stage
* Make your relevant achievements sound even better! (Our online CV software will help you write your CV for you!)
* Save time - write your CV in minutes!
* Best online CV builder

## Focus on improving your Virtual CV for more replies and more interviews

**Recruiters rank this more important than professional qualifications and experience!**
Employers say it doesn’t always matter if you have the right professional qualifications and experience. According to a recent survey, analysts discovered almost three-quarters of recruiters surveyed ranked attitude as more important than both qualifications and experience! So even if you don’t have all the qualifications, relevant skills and experience, if you can show you have a great attitude, you are still in with a great shot of getting the job. What better way is there of showing them you have a great attitude than having a Brilliant CV?

## Employers are overwhelmed with ordinary CVs
Employers are overwhelmed with applications with some job adverts attracting over a hundred applicants. But they are often underwhelmed by average CVs. Hiring managers only scan each application for just a few seconds. So you have to gain the recruiters attention immediately. For your job search to be effective, you have to impress your potential employer with a brilliant virtual CV template complete with the right mix of skills, design, professional CV summary and personal statement.

The good news is that there's no need to search any further, you’re in the right place. Whether you’re tired of formatting your CV or need help to bolster your personal profile, we can help.

## Job hunters say searching for jobs on your own is 'time-consuming’, ‘frustrating’ and ‘soul-destroying'.

Talk to anybody about job searching and they will say it’s a hard task. For example, job seekers have to contend with rejection, no replies to their applications and ATS scanners that reject your application for little things such as having the wrong font type, or incorrect font size!

When having the wrong font size can determine your success in your job search, you certainly need a little help eliminating some of the hundreds of tiny errors that could have your CV rejected.

## Designing your own Virtual CV is full of hazards
Writing and designing your virtual CV can be overwhelming and a recipe for disaster. That’s why people end up spending days, weeks or even months perfecting them before presenting them to a company.

**Problems and common mistakes with CV designs that lead to rejection include:**

* Too much white space
* Cluttered paragraphs
* Not good CV formatting
* Not enough bullet points
* Contact details not complete
* Wrong font size 
* Sections in the wrong place
* Unconvincing personal statement
* Not listing required skills
* And many, many more reasons…..

##We make hundreds of CV design decisions for you
There are literally hundreds of decisions to make when designing a CV. Does the title go at the top or the side? Where is best to put your contact details? How do you fit it all on one or two pages of your CV? Where do you put your soft skills? Where do you put employer history? Where do you put the education section? And so on……

With our job-winning templates, we are making hundreds of decisions for you, so you don’t have to - which makes it super-easy! 

## Don’t just settle for a good CV, create a Brilliant CV!
Most employers are desperate for candidates with the right skills, but often the candidates with the right skills don’t get the recruiters attention purely because they don’t have a strong enough CV. When employers have hundreds of CVs to look through, you have to make it easy for them to pick you by using an eye-catching CV. Using our Brilliant CVs, you have proven templates that thousands of candidates have already successfully used to land a job.

## Write a CV that gives you the confidence to apply for the best jobs
Using a Brilliant CV will give you the confidence to apply for the best positions because you know it has already impressed another company HR manager, so it will only be a matter of time before you get a reply!

## Get your CV ready today, so when the right job comes along, you’re ready!
Chances are, when the perfect job comes up, there’ll be hundreds of other applicants. By the time you’ve updated your CV, they might have already filled the interview slots from the early CVs, despite what the closing date says. Studies show that the earlier you send in your CV, the better chance you have of landing a new job. So you need to have your curriculum vitae up-to-date and ready to go. So as soon as that perfect job ad appears, you can send your virtual CV in and be near the top of the pile. 

## We will help you market yourself more effectively….and cram a boatload of confidence into your Virtual CV
You need to show you’re the best person for the job however, you only have seconds to make a positive first impression when the recruiter is scanning your CV. 

Take a leaf out of our career experts book. Your CV is more than just a history of your work experience. It’s like a personal marketing document. 

When it comes to CV writing, most people struggle to talk about themselves and are too modest about their skills and accomplishments. 
But that is exactly what you need to do, as you’re up against hundreds of other candidates. Blending in isn’t an option.

## We will help you write a Brilliant CV summary
Don’t worry, you can count on our HR experts to provide you with the exact phrases you need to sell yourself. So if you don’t like blowing your own trumpet, our online software will do it for you! It auto-generates suggested CV phrases to make you sound like an amazing candidate.

## The CV Format Puzzle 
There are countless different sections when you write a CV. One missing piece and it's a fail. Too much white space, it might get passed on, but too cluttered is also a fail. There are numerous elements to a perfect CV that recruiters know inside and out. You might not notice it, but if a small piece is missing or not quite right, they will notice it. Hiring managers spend months scanning through thousands of CVs - and they know an incomplete CV from a mile off.

There are many sections to consider: work history section, skills section, contact details, personal statement, interests section, education section, relevant courses, key responsibilities, soft skills, hard skills, personal profile and job title…..and many more! You forget one section, and you already aren’t their favourite. 

It’s so easy with our online CV generator which includes all the key pre-designed sections. 

* Impressive personal statements
* Strong CV objective
* Powerful CV summary
* A job description that sells your skills
* And all the other key areas to make an excellent and professional CV!

So you can focus on writing and all you have to do is complete the information such as your contact details and work experience, and the online CV generator will do the rest! 

## Say goodbye to endless re-editing and reformatting 
By the time you’ve written each section of your CV, you probably want to chop, change and edit so everything fits. This has a knock-on effect on the rest of the design. Suddenly, you need to re-design the whole CV layout. That is why our fantastic, dynamic online CV maker is the perfect solution to help you write your virtual CV. It takes away the headache of re-formatting when you’re editing your CV.

It is a working example of how to make a great CV.

We make it really easy by using examples of real-world CVs that have successfully impressed employers. 

## Our templates provide brilliant CV examples that are used by thousands of successful candidates!

If you don’t know what a functional CV, chronological CV or reverse chronological order CV is, then take note. These are all examples of the common types of CV formats recruiters know like the back of their hand. These formats are all made easy with our online CV generator. 

If you want to spend more time applying for jobs, and less time learning about good CV formats, then you’ve come to the right place. 

Our templates incorporate these formats into their design so a prospective employer will know you are a professional candidate and are privy to these layouts and designs.
When writing your own CV, to give your job application more credibility, it is best to use these layouts that are included with all Brilliant CVs.

## Accelerate Writing Your CV With Our Fast Online CV Generator
CV writing is not something you need to spend days on anymore. Just quickly gather the keywords and key skills from the job advert, job title and job description. Our CV writing software will do the rest by adding phrases to help you market yourself more effectively. 
Based on hundreds of job titles, our powerful database contains thousands of the best marketing phrases used on successful CVs to make sure you have a winning CV.

Our online CV generator will give you a helping hand so you don’t have to write a CV completely on your own. Our templates will showcase your career in a positive light.

## Write a Winning Cover Letter To Accompany Your Virtual CV
A cover letter is a perfect opportunity to communicate directly with the employer and tell them your back story. Your CV objective is to sell yourself, but it doesn’t tell the whole story about your career or allow you to talk directly to the recruiter. A cover letter allows you to introduce yourself to the employer and gives you an extra chance to show them you’re the best candidate for the job. A cover letter also demonstrates your strong communication and professional writing skills.

Even if job applications don’t specifically ask for them, the majority of employers surveyed said they expect a cover letter to be sent with a curriculum vitae. A cover letter introduces you and compliments your CV. In your covering letter, you can also refer to employment gaps and elaborate on your ambitions or reasons you desire to work for the employer. You can also show some passion and enthusiasm in a cover letter that you can’t show on your CV.

## Take Your CV to the Next Level
If you really want to make strides in your career and move forward in leaps and bounds, then you need to use winning examples that are already proven to work. That is why our CV creator templates will be a priceless upgrade in your job search.

## Maximise Your Job Search
One of the things that a company will look for is your compatibility with the job role. 

HR experts recommend that you tailor your CV to each position so you include the keywords and skills relevant to the jobs you are applying for. A key example of this is that the ATS (Applicant Tracking Software) scanner is more likely to pass your application through to the next round if the skills on your CV match the skills on the job advert.

Our curriculum vitae builder templates make it super easy for you to tailor your CV as the formatting is pre-organised for you so you don’t need to worry about re-designing and you can save multiple versions of your CV.
This is just one example of why you need our Brilliant CVs.

## Brilliant CV Templates for Every Career You Can Think of
No matter what career you are in: an IT programmer, Nurse, Manager, Student, Delivery Driver, Shop Worker, CEO….or **any other profession!**
We have a template to suit your job along with suggested power words and power phrases that are appropriate for your profession. So you don’t even have to write your whole CV, we will do some of it for you!
All you have to do is select your favourite phrases that coincide with your role and it’s done.

## Leave the competition behind by writing a Brilliant CV

**Beginner Plan :** You can create as many CVs as you need during the next 14 days, and you can choose from several CV templates. The first month will only cost you $1.45, followed by $29.99 /mo
 
**Most Selected Option:** With the most selected plan, you will receive a 14-day subscription that comes with an integrated spell checker including all the templates to write your perfect CV. 
Initially $1.95, then $29.99/month after 14 days.

**Advanced Plan:** A regular savings plan has the potential to save you money.
And has multiple benefits to help you write a CV. The plan costs $9.99 a month, then $89.99 a year.
  
  `;
};

export const getEjemplosCurriculumContent = () => {
  return `
Un proceso sencillo para ser contratado. Aquí encontrarás información sobre herramientas de trabajo, consejos, trucos y tendencias. Todo esto en un mismo lugar: nuestro blog de búsqueda de empleo.

Con el asesoramiento de expertos en selección de personal, podrás encontrar el trabajo que te gusta rápidamente. El empleo de tus sueños te está esperando. 

Así que domina tu búsqueda de empleo siguiendo nuestras instrucciones paso a paso. 
Todos los artículos de nuestro blog te ofrecen consejos prácticos que agilizarán el proceso de contratación. 

Nuestros expertos han elaborado esta guía completa que abarca desde los currículums, las cartas de presentación y las entrevistas, hasta la búsqueda del trabajo adecuado, la presentación de candidaturas y el desarrollo de la carrera profesional.

No importa en qué etapa de tu carrera te encuentres, todo lo tenemos cubierto.

Los mejores trucos y consejos que necesitarás para encontrar el trabajo de tus sueños están aquí.

## Date confianza a ti mismo

Desde el inicio del proceso de solicitud de empleo hasta el final, te guiamos y mostramos cómo hacer pequeños ajustes en tu solicitud puede aumentar tus posibilidades de éxito. Te damos la confianza que necesitas para triunfar

Tanto si se trata de cómo hacer un currículum que llame la atención, qué preguntas hacer en las entrevistas de trabajo, cómo superar la entrevista... Todo esto lo tenemos preparado para darte la confianza que necesitas para demostrar al responsable de contratación que eres el candidato adecuado para el trabajo.

## Ama ir a trabajar

Queremos que encuentres el trabajo de tus sueños y que te encante ir a trabajar cada día. 

Nuestra misión es ayudarte a encontrar tu trabajo perfecto. Uno tan especial que quieras saltar de la cama emocionado por ir a trabajar. Queremos que estés encantado con tu nuevo empleo y que no te conformes con lo segundo. Te mereces un buen trabajo y queremos ayudarte a encontrar el mejor que sea posible. 

Nuestra máxima prioridad es ayudarte a encontrar un trabajo que te lleve a donde quieres ir. No cualquier empleo, sino el empleo de tus sueños. 

Tu trabajo puede tener un gran impacto en tu calidad de vida. Por eso queremos que encuentres un empleo que te ilusione al punto de que ansíes ir cada día.

Es precisamente por esta razón que hemos creado estos artículos de búsqueda de empleo para ayudarte a conseguir tu próxima oportunidad laboral.

Y es que los trabajos adecuados pueden hacerte sentir feliz, darte un nuevo impulso en tu vida, darte satisfacción laboral, autoestima o positividad. En cambio, hay empleos tóxicos que pueden ocasionar el efecto contrario.  

Así que si no te gusta tu trabajo, cámbialo. Estamos aquí para ayudarte a hacerlo. 

Independientemente de la fase en la que te encuentres en tu búsqueda de empleo, podemos brindarte recursos de valor para aumentar tus posibilidades de encontrar el trabajo de tus sueños.  

Nuestros profesionales en recursos humanos y blogueros de selección de personal han creado cientos de artículos sobre búsqueda de empleo que darán un impulso a tu candidatura y te darán la atención que mereces.

## Nuestros expertos en contratación tienen una gran experiencia

Por ello, se nota que cada redactor, ya sea un experto en carreras profesionales o un escritor de blogs de búsqueda de empleo, se preocupa por tu carrera. Tienen la experiencia necesaria y son conscientes de lo que buscan los solicitantes de empleo en términos de consejo, orientación y estímulo.  

## Si quieres superarte, estás en el lugar adecuado

Si no tienes conocimientos profundos sobre contratación, tendrás que enviar cientos de solicitudes antes de saber qué buscan realmente los reclutadores en los solicitantes y cómo es su candidato ideal.

Afortunadamente, nuestro equipo de expertos en RR. HH. tiene décadas de experiencia como gestores de contratación. Hemos preparado un equipo de expertos que ya han buscado miles de solicitudes y conocen todos los secretos de la contratación y exactamente lo que hay que hacer para obtener los resultados.

Por lo tanto, te proporcionarán consejos increíbles para la búsqueda de empleo que harán que el proceso sea mucho más fácil, porque contar con un gran recurso del cual aprender sobre estrategias de búsqueda de empleo y temas que quizás no hayas considerado antes, mejorará la efectividad de tu proceso.

## Las oportunidades te están esperando

Siempre hay innumerables oportunidades para los que buscan trabajo, siempre hay alguien que avanza en su carrera y deja su empleo, ya sea para evolucionar profesionalmente, para jubilarse o para cambiar de área. Lo cierto es que las oportunidades surgen todos los días, solo hay que saber dónde buscar y cómo enfocar la solicitud desde un ángulo ganador.  

Bien sea creando una carta de presentación más convincente o un currículum que consiga la atención que mereces, tenemos algo preparado para ti.  

Porque si no estás recibiendo las respuestas que te mereces, hacer lo mismo una y otra vez solo te hará perder tiempo y energía. Y es que encontrar una fórmula para la búsqueda de empleo lleva mucho más tiempo cuando lo haces por tu cuenta. Así que deja que nuestro equipo de expertos en recursos humanos te guíe a través de los métodos más eficaces.

## Estás preparado

Solo se puede ganar experiencia, no perderla, ¿verdad? 

Ahora mismo, en este preciso momento, eres el más experimentado y, por lo tanto, estás tan preparado como jamás lo has estado. 

Estás preparado. Entonces, ¿qué estás esperando? 

Permítenos mostrarte cómo presentar tu experiencia y potencial a un empleador para que pueda ver y entender tu verdadero valor. Tenemos consejos que te cambiarán la vida y que pueden tener un impacto real en tu carrera.
  
## ¿Sabes lo que realmente vales? 

Aumenta tu salario con nuestros expertos. 

Los estudios demuestran que la lealtad rara vez se recompensa en el trabajo hoy en día, por eso, tienes que tomar la iniciativa y buscar otro empleo si buscas un aumento de sueldo; porque se ha comprobado que las personas que abandonan las empresas son recompensadas con un mejor salario que los empleados que permanecen en su puesto de trabajo a la espera de un aumento salarial por fidelidad.  

La buena noticia es que, si dominas el arte de la búsqueda de empleo leyendo nuestros artículos, no tendrás que volver a esperar otro aumento de sueldo.
  
## Los hechos

Debes leer este blog si estás interesado en conocer datos de contratación para tener éxito en tu búsqueda de empleo.  

La investigación, las estadísticas y las actualizaciones legales dominan nuestros posts que se basan en pruebas e investigaciones objetivas. Algunos blogs solo hablan de opiniones, pero ten la seguridad de que nuestra web contiene datos estadísticos procedentes de las últimas encuestas de empleo. 
Este es el recurso definitivo para buscar trabajo.  

## Por qué nuestra búsqueda de empleo puede ayudarte

Bienvenido a nuestro blog. El trabajo de tus sueños está ahí afuera esperándote. Tú tienes la pasión y nosotros la dirección para guiarte.

No hay otro lugar como este que ofrezca una orientación tan sólida en todos los sectores a tantos solicitantes de empleo.
 
La capacidad de nuestros colaboradores para contar historias intrigantes utilizando temas de actualidad sobre las últimas noticias y desarrollos del mercado de trabajo, hace de nuestro blog una lectura obligada para cualquier persona que busque trabajo.

Basándonos en las últimas encuestas y estadísticas sobre empleo, encontramos la información que necesitas y la convertimos en un formato sencillo.

Sobre todo, si se trata de alguno de los siguientes temas: 

* Cómo escribir el currículum perfecto:
[https://ejemplos-curriculum.com/curriculum-perfecto/](https://ejemplos-curriculum.com/curriculum-perfecto/)

* Què habilidades poner en tu currículum:
[https://ejemplos-curriculum.com/habilidades-para-el-curriculum/](https://ejemplos-curriculum.com/habilidades-para-el-curriculum/)

* Cómo enviar tu currículum al trabajo de tus sueños para aumentar tus posibilidades
[https://ejemplos-curriculum.com/email-para-enviar-tu-cv/](https://ejemplos-curriculum.com/email-para-enviar-tu-cv/)

Tenemos la información que necesitas para tener éxito. 

En cada post encontrarás información detallada, y aunque es posible que este sitio web no te resulte cómodo para una lectura fácil, si prefieres los conocimientos del sector sin que se diluyan, este es el lugar al que debes acudir.
 
Con un enfoque único de la búsqueda de empleo, basamos nuestros contenidos en estadísticas y artículos de las últimas encuestas de empleo, estadísticas del sector y análisis.
   
* No te agobies ni te estreses

No es necesario sentirse abrumado durante la búsqueda de empleo. En Ejemplos-Curriculum, nuestro equipo ha creado recursos realmente útiles que explican desde cómo redactar un currículum o una carta de presentación, hasta cómo negociar una nueva oferta de trabajo. Así que elimina parte del estrés de la búsqueda de empleo consultando nuestros consejos profesionales.

## Consejos gratuitos de profesionales de RR. HH. sobre el currículum

No debes estresarte por cómo escribir un currículum eficaz. Te ofrecemos una completa guía de redacción de currículos que te ayudará a mostrar tus habilidades. Consulta nuestras plantillas de currículum [aquí](https://ejemplos-curriculum.com/plantillas-de-curriculum/) y nuestras [guías de redacción de currículum](https://ejemplos-curriculum.com/cv/).

## Date ventaja a ti mismo

La búsqueda de empleo es un proceso muy competitivo, a veces hay cientos de candidatos. A menudo encontrarás que muchas personas están en una situación muy similar a la tuya, ya que quizás tengan exactamente las mismas cualificaciones y experiencia, incluso que hayan trabajado en las mismas empresas que tú o que hayan asistido exactamente a la misma escuela, colegio o universidad.  

Esto supone un verdadero dolor de cabeza para los responsables de recursos humanos, porque con tantos candidatos mezclados, es difícil distinguirlos en un montón de currículums.
 
No puedes dejarlo al azar con la esperanza de que te elijan. 
 
El mejor candidato rara vez consigue el trabajo. Siempre es la solicitud mejor preparada la que consigue el puesto.  
 
Tienes que darte ventaja en cada sección del proceso de solicitud de empleo.
 
La buena noticia es que **estás en el lugar adecuado**.
 
## Puedes destacar por encima de los demás

La primera impresión cuenta, y esta suele ser la recepción de tu solicitud de empleo en forma de currículum y carta de presentación. Muchos solicitantes de empleo son muy buenos en su trabajo, pero pasan desapercibidos simplemente porque no saben cómo venderse o diferenciarse de la competencia.  
 
Tal vez llevas años haciendo tu trabajo con éxito, pero quizás has perdido muchas oportunidades por no plasmar tus logros en tu currículum. 

Hay muchos trucos y consejos que nuestros expertos en RR. HH. han aprendido a lo largo de los años y que pueden darte una ventaja sobre otros candidatos y hacer que destaques por encima del resto. Permítenos compartir nuestros secretos para ayudarte a destacar y presentarte como el candidato ganador.  
 
Nuestros expertos dinamizarán tu búsqueda de empleo con nuevos enfoques y estrategias profesionales y específicas.
 
## Alcanza tu verdadero potencial

Recuerda que limitarte a una sola estrategia reduce tus opciones y limita tu futuro. Consigue una estrategia de búsqueda de empleo revitalizada siguiendo nuestros consejos. 

Ten en cuenta que el potencial solo se puede aprovechar si estás preparado para las oportunidades en primer lugar. Solo conocerás tu verdadero potencial si estás listo y preparado durante todo el proceso de solicitud. 

## ¿Necesitas una nueva estrategia de búsqueda de empleo? 

Si estás adoptando el mismo enfoque agotador en la búsqueda de empleo, es posible que necesites desesperadamente nuevas estrategias. Es hora de ser un poco creativo y conocer los diferentes métodos que funcionan una y otra vez.
 
Puede que tengas un montón de habilidades, pero si no las presentas en un formato profesional, no vas a obtener resultados positivos.
 
## Siéntete seguro en tu búsqueda de empleo
Ir a la entrevista con información sobre cómo funciona el proceso de solicitud de empleo puede darte la confianza necesaria para mostrar tus capacidades.

La búsqueda de empleo puede ser agotadora, de hecho, puedes experimentar una serie de emociones complejas a lo largo del proceso. Si no adoptas el enfoque adecuado, puedes terminar desanimado y desmotivado.
 
Puedes pasar desde el entusiasmo hasta la decepción. Los altibajos de la búsqueda de empleo pueden pasarte factura si no tienes cuidado. Lamentablemente, puede ser una experiencia muy negativa si no sigues los consejos adecuados. 

Enviar innumerables solicitudes genéricas puede ser como golpearse la cabeza contra una pared de ladrillos mientras se le ignora y no se recibe ninguna respuesta: ese no es un lugar saludable.
 
Será una experiencia mucho más satisfactoria si sigues los consejos de los expertos de nuestro equipo y experimentas los altos en lugar de los bajos.

Queremos que sientas todas las emociones positivas: el entusiasmo, la felicidad, el placer y las celebraciones que pueden acompañar a la búsqueda de empleo, en lugar de los puntos bajos de un proceso equivocado: el rechazo, la decepción y la desesperación.
 
Con nuestro equipo de expertos junto a ti, puedes sentirte seguro y feliz en tu búsqueda de empleo.

## Lleva tu búsqueda de empleo al siguiente nivel
Si estás preparado para dar el siguiente paso en tu carrera, consulta nuestros blogs de búsqueda de empleo para ayudarte a tener éxito.
  `;
};

export const getCvMintaContent = () => {
  return `
Egyszerűsítsük annak a folyamatát, hogy felvegyenek álmaid állásba. Itt mindent megtalálsz, legyen szó tippekről, trükkökről, a legújabb trendekről. Minden egy helyen, a blogunkon. 

Valódi, szakértői tanácsok karrier tanácsadóktól, hogy biztosan megtaláld azt a munkát, ami nemcsak munka lesz, hanem hivatás - mindezt gyorsan és egyszerűen! Álmaid munkája már vár. 
Kövesd a tanácsainkat, és legyél az álláskeresés mestere, hogy megtaláld. 

Minden cikk, amelyet megtalálsz a blogunkban, valódi tanácsokat ad arra vonatkozóan, hogyan tudod meggyorsítani a felvételi eljárást.  

A szakértőink összekészítettek egy átfogó útmutatót az önéletrajztól a kísérőlevél megírásáig, és interjútechnikákig, ahhoz, hogy megtaláld a tökéletes állást, és akár fejlődhess is benne. 

Nem számít, milyen szinten vagy a karrieredben, ránk számíthatsz. 

Minden tipp és trükk, amelyre szükséged lehet álmaid állásának megtalálásához, egy helyen.

## Gyűjts egy kis magabiztosságot!

A legelső lépéstől kezdve végigkísérünk az interjúkon, és megmutatjuk, hogy akár a legkisebb plusszal is jelentősen megnövelheted az esélyeidet a sikerre. Megadjuk azt az önbizalmat, amire szükséged van. 

Legyen szó önéletrajz megírásáról, vagy, hogy milyen kérdéseket érdemes feltenni egy interjún és hogyan viselkedj közben, mindent megtalálsz nálunk. Ezzel a tudással felvértezve valódi magabiztosságra tehetsz szert, így meg tudod mutatni a HR-es számára, hogy te vagy az, akit keres!

## Amikor SZERETSZ bejárni dolgozni

Olyan munkát szeretnénk neked találni, amelyet szeretni fogsz, és akár álmaid munkahelye is egyben. 
 
A mi feladatunk, hogy megtaláljuk a számodra tökéletes állást. Egy olyat, ami miatt szívesen kelsz ki reggel az ágyból. Célunk, hogy ne ragadj bele a második legjobb választásba, hanem tényleg a tökéletes munkát találd meg. Megérdemled, és mi ebben segítünk. 
 
A mi prioritásunk az, hogy olyan helyet találj, ahol szeretnél is dolgozni. Ne elégedj meg akármilyen munkával, törekedj az álommunka megszerzésére. 

A munkád jelentősen meghatározza az életminőségedet. Ezért fontos igazán, hogy egy izgalmas, kihívásokkal teli munkahelyet találj. Ennek megszerzéséhez készítettük el a cikkeinket, mert így tudunk támogatni a munkakeresésben. 

A tökéletes munkahely feltölt energiával, új perspektívát ad az élethez, növeli az elégedettséget, énképet és a pozitivitást. A mérgező állások pedig sajnos pont az ellenkező hatást váltják ki.

Ha nem szereted a munkádat, válts. Ebben is segítünk. Nem számít, hogy az álláskeresés melyik szakaszán vagy, mindenben tudunk támogatni és növelni az esélyét az álomállás megszerzésének. 

A HR szakértőink és tanácsadóink több száz álláskereséssel kapcsolatos cikket készítettek, amelyek felturbózzák a jelentkezésedet, és rád irányítják azt a figyelmet, amelyet megérdemelsz. 

## Ha még jobb akarsz lenni, akkor a legjobb helyen vagy

Amennyiben nincs még semmilyen tapasztalatod az álláskeresés területén, akkor bizony több száz önéletrajzot kell elküldened, mire kitapasztalod, mit keresnek a HR-sek igazán, és milyen számukra az ideális jelölt. 

Szerencsére a HR szakértőkből álló csapatunk több évtizedes tapasztalattal rendelkezik már a területről. Egy olyan csapatot állítottunk össze, akik meg több ezer jelentkezést átnéztek, és minden titkot tudnak arról, hogy mit kell tenned a siker érdekében. 

Így olyan szuper álláskeresői kincsekkel tudnak segíteni, amelyekkel maga az álláskeresés sokkal könnyebbé válik. A megbízható forrás elengedhetetlen ha olyan álláskeresési stratégiák elsajátításáról van szó, amelyekre eddig nem is gondoltál. 
  
## A lehetőségek rád várnak 

Rengeteg lehetőség áll az álláskeresők rendelkezésére. Mindig vannak, akik előrelépnek a karrierükben, vagy elhagyják a jelenlegi munkahelyüket. Legyen szó előrelépésről, nyugdíjról, karrierváltásról - számtalan lehetőség áll előttünk nap mind nap. Csak tudnod kell, hogy kell keresni azokat, és hogyan tudsz olyan jelentkezést adni, amellyel biztos a siker, és a lehetőség jövőképpé válik. 

Akár egy meggyőző kísérőlevél, vagy a figyelemfelkeltő önéletrajz megírása a cél, mi tudunk segíteni. 

Ha azzal, hogy mindent ugyanúgy csinálni újra és újra, nem kapod meg azokat a jól megérdemelt pozitív válaszokat, amelyeket keresel, akkor csak pazarlod az idődet és az energiádat. A sikeres jelentkezés titkát ugyanis egyedül sokkal nehezebb megfejteni. Engedd meg, hogy a mi HR szakértőkből álló csapatunk megmutassa, hogyan kell igazán hatékonyan csinálni. 

## Készen állsz

Végülis, a tapasztalatot csak szerezni lehet, veszíteni nem, ugye?

Ebben a pillanatban a lehető legtapasztaltabb és legfelkészültebb vagy, mint előtte bármikor. 

Készen állsz! Akkor mégis mire vársz? Engedd meg, hogy megmutassuk, hogyan tudod a képességeidet és készségeidet, a benned rejlő potenciált a leendő munkaadódnak prezentálni. Van néhány igazán életmentő ötletünk, amelyek óriási hatással lesznek a karrieredre. 

## Tisztában vagy az értékeddel?

Növeld a bérigényedet a szakértőink segítségével. A kutatások szerint a munkahelyhez való hűség napjainkban elég ritkán van megjutalmazva, úgyhogy neked kell az első lépést megtenned, ha szeretnéd növelni a fizetésedet, méghozzá azzal, hogy új munkahelyet keresel. A felmérések arra is rávilágítottak, hogy azok, akik otthagyják a munkahelyüket, magasabb fizetést kapnak, mint akik ott maradnak, és várják, hogy majd megjutalmazzák őket fizetésemeléssel. 

A jó hír viszont, hogy a cikkeink olvasásával könnyedén elsajátítod az álláskeresés fortélyait, így nem kell várnod az újabb fizetésemelésre. 
  
## A tények

Akkor olvasd ezt a blogot, ha érdekelnek a HR-es belsős titkok, amelyek segítenek az álláskeresésben. 

Cikkeink leginkább a legújabb kutatásokat, statisztikát, jogi újításokat mutatják be, amelyek tényeken és felméréseken alapulnak. Néhány blog csak véleményekről ír. A mi cikkeink azonban tényeken alapulnak. 

Ez adja a valódi segítséget az álláskeresésben.
  
## Amiben segíteni tud az oldalunk

Üdv a blogunkban. Az álommunkád már vár rád. Benned megvan a szenvedély, mi pedig megadjuk az térképet ahhoz, hogy megtaláld. Nincs még egy olyan hely, ahol ilyen informatív, kutatásokon alapuló útmutatót találsz, legyen szó bármilyen szakmáról.

A blogot vezető szakértők képessége, hogy élvezetesen írnak, miközben bemutatják a legújabb álláskeresési trendeket és újításokat, így minden munkakereső számára kihagyhatatlan blogot vezetünk. A legújabb felméréseken és statisztikán alapul minden cikkünk, hogy biztosan megtaláld azt, amit keresel.
 
Amiről szó van:

* Hogyan írj tökéletes önéletrajzot: 
[https://resume-example.com/perfecting-a-resume/](https://resume-example.com/perfecting-a-resume/)

* Top 10 Képesség, amelyet minden cégnek tudnia kellene:
[https://resume-example.com/teamwork-skills/](https://resume-example.com/teamwork-skills/)

* Hogy küldd el a jelentkezésedet úgy, hogy biztos legyen a siker: 
[https://ejemplos-curriculum.com/email-para-enviar-tu-cv/](https://ejemplos-curriculum.com/email-para-enviar-tu-cv/)

Mindent megtalálsz, amire szükséged lehet. Részletes információt találsz minden egyes blogposztban. Lehet, hogy nem lesz könnyű a cikkek olvasása, de ha valódi szakmai anyagra vágysz, akkor itt a helyed.

Egyedülálló megközelítési módot használunk az álláskeresésben, és a cikkeink tartalmi részét statisztikák és felmérések adják, kiegészítve a szakmára, iparágra jellemző adatokkal és elemzésekkel.
   
* Ne stresszelj

Nem kell úgy érezned, hogy minden egyszerre szakad rád munkakeresés közben. Kollégáink, a CVminta.hu oldalon igazán hasznos anyagokat készítettek, amelyek mindent elmagyaráznak az önéletrajzírástól, kísérőlevél megírásától egészen az új állás megtárgyalásáig. A karrier tanácsaink között keresgélve biztosan érzed majd, hogy levesszük a válladról a terhet

## Ingyenes önéletrajz tippek HR szakértőktől

Nem lenne szabad az önéletrajz megírása miatt stresszelned. Átfogó önéletrajz útmutatót ajánlunk ahhoz, hogy meg tudd mutatni a képességeidet. Nézz szét önéletrajz sablonjaink között, vagy az önéletrajz útmutatók között.

## Merj egyedi lenni, add hozzá azt a kis pluszt

Az álláskeresés egy valódi versenypálya. Sok esetben több száz másik jelentkező van. Gyakran találkozhatsz azzal, hogy mások hasonló helyzetben vannak, mint te magad. Talán még ugyanolyan tapasztalattal és képességekkel is rendelkeznek, mint te. Lehet, hogy ugyanannál a cégnél dolgoztatok, vagy ugyanazon az egyetemen, főiskolán végeztetek. 
 
Ez igazi fejtörést okoz a HR menedzsereknek, hiszen ennyi egyforma jelentkező közül nagyon nehéz azt a néhány kiemelkedő jelöltet kiválasztani. 
 
Nem ülhetsz tétlenül, hogy most hátha téged választanak. 
 
A legkevesebb esetben kapja meg a legjobb jelölt a munkát. Mindig a legfelkészültebb jelentkező kapja meg a pozíciót. 
 
Egyedinek kell lenned ahhoz, tovább tudj jutni a következő körben. 
 
A jó hír, **hogy a legjobb helyen vagy ehhez!**.

Az első benyomás a legfontosabb, és ez az első benyomás általában önéletrajz és kísérőlevél formájában jelenik meg az álláskeresésben. Számtalan álláskereső nagyon jó a jelenlegi munkájában, de észre sem veszik őket a HR-sek, mert nem tudják eladni magukat, a tudásukat, és nem tudják, hogyan különböztessék meg magukat a többi jelölttől. 
 
Lehet, hogy kiemelkedő munkát végeztél évekig, de nem kommunikáltad mindezt az önéletrajzodban, ezért maradtál le rengeteg lehetőségről. Ezeket a trükköket és tippeket a HR szakértők évek alatt tanulták meg, és segíthet megadni azt a pluszt, amellyel előnyre tehetsz szert. Engedd, hogy megosszuk veled a titkokat, és segítsünk abban, hogy nyertes jelöltté válhass. 
 
A szakértőink a friss hozzáállásukkal és a profi, célzott stratégiával és technikával segítik az álláskeresésedet. 
 
## Találd meg a benned rejlő potenciált

Ne felejtsd el, hogy korlátozod magad, ha csak egyetlen stratégiát használsz, kevesebb opció van előtted. Legyen több stratégiád a tippjeink segítségével. A potenciál csak akkor kerülhet az előtérbe, ha felkészültél az előtted álló lehetőségekre. Akkor ismered meg a benned rejlő végtelen potenciált, ha készen állsz a jelentkezés teljes folyamatára. 
 
## Szükséged van új álláskeresési stratégiára? 

Ha már belefáradtál abba, hogy mindig ugyanazt a stratégiát kövesd a munkakeresés közben, akkor valószínűleg szükséged van egy kis újításra. Ideje kreatívnak lenni és elsajátítani különböző stratégiákat, amelyekkel időt és energiát is spórolhatsz. 
 
Lehet, hogy minden képességgel rendelkezel, de nem tudod szakszerűen megjeleníteni azokat, és ezért nem kapsz pozitív visszajelzést. 

## Legyél magabiztos az álláskeresés során 

Úgy elmenni interjúra, hogy pontosan tudod, hogyan néz ki egy felvételi eljárás, kellő magabiztosságot nyújt ahhoz, hogy meg tudd mutatni a képességeidet. 
 
Az álláskeresés fárasztó és kimerítő munka. Valószínűleg rengeteg érzelem kavarog benned. Ha azonban nem a megfelelő hozzáállást sajátítod el, akkor demotiválttá és bizonytalanná fogsz válni. 
 
Az izgalomtól a csalódottságig minden érzelemmel szembesülsz majd a munkakeresés során. Mélységeket és magasságokat élsz meg, és ezek fogják irányítani az életedet, ha nem vigyázol. Nagyon negatív élménnyé válhat a folyamat, ha nem követed a megfelelő tippeket. Ugyanazt a sematikus jelentkezést küldeni mindenhova olyan, mintha a fejedet vernéd a falba - nem egészséges és nem vezet eredményre. 
 
Akkor lesz pozitívabb az élmény, ha megfogadod a szakértők tanácsát, így többször láthatod az álláskeresés pozitív oldalát.  Azt szeretnénk, hogy többször érezd a pozitív érzelmeket: izgalom, öröm, ünneplés, nem pedig a negatívakat, vagyis a visszautasítást, kétségbe esést és a csalódottságot. 
 
## Emeld a szintet az álláskeresésben

Ha készen állsz a következő lépésre, akkor nézz szét a cikkeink között, és készülj fel a sikerre.
  `;
};

export const getResumeNationContent = (t) => {
  return `
  ## Only the Top Resumes Get Selected. Be one of the Best and Land Your Dream Job Interview withI  Resume-Nation
  Apply for your job with our proven job-winning resume templates using the high-quality designs employers expect.
  Fast, simple and easy to use. Create yours in minutes. Use the best resume templates. Try now….

  ## Get hired faster with the BEST resume and cover letter builder. Create yours now, in minutes…
  Our online resume builder can quickly build you a professional resume - in minutes! It’s the easiest way to raise your job applications to the next level. So you’ll get more engagement from employers when applying for jobs.

  ## Fast online resume builder
  It’s easy to quickly edit your resume and save multiple versions for different applications. Change and update anytime without worrying about messing up the formatting. 

  ## Auto-written phrases
  No more staring at a blank page. Our database of resume sentences will write industry-specific suggestions to demonstrate your suitability for the job.

  ## Avoid costly spelling mistakes
  Avoid any silly mistakes that will spoil your application. Our built-in spell checker will make your resume perfect and error-free. 

  ## Export to any format you choose
  Save multiple versions in MS Word or PDF-friendly formats that employers prefer

  ## Make your resume stand out with the best online resume builder
  Chasing that dream job can feel daunting. We get you one step towards your goal faster. Get yourself ahead and land more interviews with our HR-approved templates successfully used by thousands of candidates. Simply add your personal details. Fill in the boxes. Choose the industry-specific text suited to your job. Export your new professional resume in your preferred format.

  ## Your career can take off like a rocket!....with a Resume-Nation resume in your hand
  Designed by HR experts, our professional resume templates use recruiter-friendly resume formats. As a result, you can make more of an impact, and expect more responses.

  ## All-in-one resume maker tool
  We have packed so many features into our resume builder: visually pleasing designs, auto-written phrases, integrated auto-formatting, spell checker and instinctive designs. Everything you need for a perfect resume.

  ## Recruiter-friendly designs
  Our resumes contain fresh, recruiter-friendly phrases and designs. Chosen by HR experts so your resume stands out.

  ## Be the best version of yourself 
  Show employers the best version of yourself by using our professional resume templates designed by HR experts.

  ## Respond quickly to job ads
  Our fast, easy-to-use online editor means you can tailor your resume so you can apply quickly.

  ##Just fill in the boxes
  Simply enter your info in the boxes and our resume builder wizard will do the rest. Your professional resume can be completed in minutes.

  ## Designed by HR experts, approved by recruiters
  Our HR team has designed the most eye-catching, professional designs pre-approved by recruiters.

  ## Resume templates proven to work
  Our experts have carefully selected the templates that are consistently performing in the job market. 

  ## Online database of job phrases
  Our library of auto-written phrases means your resume will be easier to write. No more staring at a blank page.

  ## Secure and private
  Your information is safe with our secure website. Your data is protected by our SSL state-of-the-art encrypted website.

  ## Resumes for any career
  Whatever career you’re in, our professional templates can make you shine. 

  ### Creating a winning resume is the quickest and easiest way to get more job interviews. Get more job offers with Resume-Nation’s online resume builder. Try now…

`;
};
