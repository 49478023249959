import ImageWrapper from '../atoms/ImageWrapper';
import { getArticlesImages } from 'imports/help/api/articlesImages';
import { StyledText } from '../atoms/Typography';
import { useResponsive } from 'imports/core/api/responsiveContext';

import ArticlePageWrapper from '/imports/help/ui/components/ArticlePageWrapper';
import useIntl from '/imports/core/api/useIntl';

const AccessCoverLetter = () => {
  const { t } = useIntl();
  const { host } = useResponsive();
  const images = getArticlesImages(host);
  return (
    <ArticlePageWrapper title={'help.access_cover_letter'}>
      <StyledText>{t('help.access_cover_letter.premium_cover_letters')}</StyledText>
      <ImageWrapper src={images.access_cover_letter_1} />
      <ImageWrapper src={images.access_cover_letter_2} />
    </ArticlePageWrapper>
  );
};

export default AccessCoverLetter;
