import styled, { css } from 'styled-components';

import Flex from '/imports/core/ui/atoms/Flex';

export const Button = styled(Flex).withConfig({
  shouldForwardProp: (prop) =>
    // prevent these style props to render on the DOM
    !['fixed', 'borderOnly'].includes(prop),
})`
  padding: 14px;
  gap: 13px;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--primary);
  border: 1px solid var(--primary);
  color: var(--light-values-white);
  font-size: 18px;
  line-height: 120%;
  cursor: pointer;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  padding: 16px 24px;
  &:hover {
    background-color: var(--light-values-white);
    color: var(--primary);
    svg > path {
      fill: var(--primary);
    }
  }
  ${({ fixed }) =>
    fixed &&
    css`
      pointer-events: none;
    `}
  ${({ borderOnly }) =>
    borderOnly &&
    css`
      border-radius: 8px;
      border: 1px solid var(--primary);
      opacity: 0.5;
      background-color: var(--light-values-white);
      color: var(--primary);
      font-family: Sora;
      font-weight: 300;
      line-height: 140%;
    `}
`;
