import React from 'react';
import styled, { css } from 'styled-components';

import { DashboardButton } from '/imports/dashboard/ui/atoms';
import Flex from '/imports/core/ui/atoms/Flex';
import useIntl from '/imports/core/api/useIntl';

const Tabs = ({ showCoverLetters, currentTab, tabClick, buttonClick, showWebsiteFeature }) => {
  const { t } = useIntl();

  const dashboardTabs = [
    { tabIndex: '0', text: t('resume') },
    { tabIndex: '1', text: t('cover_letter'), hide: !showCoverLetters },
    { tabIndex: '2', text: t('website'), hide: !showWebsiteFeature },
  ];

  return (
    <TabFlex alignItems="center">
      <TabsWrapper showCoverLetters={showCoverLetters} showWebsiteFeature={showWebsiteFeature}>
        {dashboardTabs.map((item, index) => {
          if (item.hide) return null;
          return (
            <TabWrapper key={index} onClick={() => tabClick(item.tabIndex)}>
              <TabTitle active={currentTab === item.tabIndex}>{item.text}</TabTitle>
              <Tab active={currentTab === item.tabIndex} />
            </TabWrapper>
          );
        })}
      </TabsWrapper>
      <DashboardButton cta onClick={buttonClick}>
        {t('dashboard.create_new')}
      </DashboardButton>
    </TabFlex>
  );
};

Tabs.displayName = 'Tabs';

const TabFlex = styled(Flex)`
  margin-bottom: 54px;
  ${({ theme }) => theme.max('sm')`
    flex-direction: column;
    margin-bottom: 26px;
    button {
    }
  `}
`;

const TabsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(
    ${({ showCoverLetters, showWebsiteFeature }) =>
      showCoverLetters && showWebsiteFeature ? 3 : showCoverLetters || showWebsiteFeature ? 2 : 1},
    1fr
  );
  grid-gap: 2px;
  align-items: flex-end;
  width: calc(100% - 180px);
  margin-right: 20px;
  ${({ theme }) => theme.max('sm')`
    width: 100%;
    margin-right: 0;
  `};
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      margin-right: 0;
      margin-left: 20px;
    `}
`;

const TabWrapper = styled.div`
  cursor: pointer;
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
`;

const Tab = styled.div`
  padding: 2.5px;
  background: #cad1d9;
  border-radius: 2.5px;
  ${({ active }) =>
    active &&
    css`
      background: #0087c1;
      ${({ theme }) => theme.max('sm')`
        background: #2291eb;
      `}
    `}
`;

const TabTitle = styled.div`
  font-family: TTCommons;
  font-size: 22px;
  font-weight: 500;
  line-height: normal;
  color: #282b32;
  margin-bottom: 10px;
  text-transform: capitalize;
  ${({ active }) =>
    active &&
    css`
      color: #0087c1;
    `}
  ${({ theme }) => theme.max('sm')`
    font-size: 16px;
    font-weight: 500;
    letter-spacing: normal;
    margin-bottom: 8px;
    ${({ active }) =>
      active &&
      css`
        color: #2291eb;
      `}
  `}
`;

export default Tabs;
