import { useState, useEffect, useMemo } from 'react';
import LocaleSelect from 'imports/onboarding/ui/atoms/OnboardingStartLanguageLocalSelect';
import useTracking from 'imports/core/hooks/useTracking';
import { ALL_LANGUAGES } from 'imports/generator/api/constants';
import { getPopupv2DesignVariant, isPopUpV2Active } from 'lib/helpers';
import { prepareLanguageLabelBeforeTranslate } from 'imports/core/ui/components/LocaleSelect';
import styled, { css } from 'styled-components';
import useIntl from '/imports/core/api/useIntl';
import Flex from 'imports/core/ui/atoms/Flex';
import CloseIcon from '/imports/resume-options/ui/assest/CloseIcon';
export default function ToogleExpPopUpDesign({ hidePopup }) {
  const { locale, t, resumeLocale, setResumeLocale } = useIntl();

  const { trackEvent } = useTracking();
  const [toogleLocale, setToogleLocale] = useState('en');
  const left = useMemo(() => ALL_LANGUAGES.find((language) => language.value === locale, [locale]));
  const right = useMemo(() => ALL_LANGUAGES.find((language) => language.value === toogleLocale, [toogleLocale]));
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const isToogleExpPopUpDesignV2 = isPopUpV2Active(locale);
  const popupV2DesignVariant = getPopupv2DesignVariant() == 'column';
  const handleClick = (value) => {
    setResumeLocale(value);
    trackEvent('resume_language_selected', {
      resume_locale: value,
      resume_popup: false,
    });
    setButtonDisabled(true);
    hidePopup(value);
  };

  // bg_resume_massonny
  useEffect(() => {
    if (resumeLocale && locale && resumeLocale != locale) {
      setToogleLocale(resumeLocale);
    }
  }, [resumeLocale]);

  const getResumeLanguageLabel = (label) => {
    const languageLabel = t(`language.${prepareLanguageLabelBeforeTranslate(label)}`);
    return t(`resume.language`).replace(/\{[^}]+\}/g, languageLabel);
  };

  return (
    <>
      <Main withBackground={isToogleExpPopUpDesignV2}>
        {isToogleExpPopUpDesignV2 && <Overlay />}
        <Container modal={isToogleExpPopUpDesignV2}>
          <CloseIconWrapper onClick={hidePopup}>
            <CloseIcon fill={'#14141F'} show={isToogleExpPopUpDesignV2} />
          </CloseIconWrapper>
          <HeaderWrapper $justifyContent={'center'} $alignItems={'center'} $direction="column">
            <Title>{t('onboarding.select.language')}</Title>
            <Subtitle>{t('onboarding.select.language.later')}</Subtitle>
          </HeaderWrapper>

          <ButtonWrapper>
            <Button onClick={() => handleClick(left?.value)} disabled={buttonDisabled}>
              <Flag src={`/static/img/flag/${left?.value}.svg`} />
              <Text>{getResumeLanguageLabel(left?.title)}</Text>
            </Button>
            <Button onClick={() => handleClick(right?.value)} disabled={buttonDisabled}>
              <Flag src={`/static/img/flag/${right?.value}.svg`} />
              <Text>{getResumeLanguageLabel(right?.title)}</Text>
            </Button>
          </ButtonWrapper>
          <LocaleSelect afterSelect={hidePopup} />
        </Container>
      </Main>
    </>
  );
}

const HeaderWrapper = styled(Flex)`
  gap: 8px;
`;
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 2;
`;
const Title = styled.p`
  font-family: 'GILROY SEMIBOLD';
  font-size: 34px;
  margin: 0;
  @media only screen and (max-width: 768px) {
    font-size: 24px;
  }
  margin-top: 4px;
  text-align: center;
  color: #14141f;
`;

const Flag = styled.img`
  width: 22px;
`;
const Text = styled.span`
  display: -webkit-box;
  font-size: 15px;
  color: #000;
  -webkit-box-orient: vertical;
`;

const Button = styled.button`
  border: 1px solid #c4c4c7;
  width: 200px;
  height: 100px;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  gap: 10px;
  @media only screen and (max-width: 768px) {
    width: 147px;
  }
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px 16px 0px 16px;
  cursor: pointer;
  &:hover,
  &:focus {
    border: 1px solid #1688fe;
    background-color: #edf4ff;
  }
`;
const Subtitle = styled.p`
  font-family: 'GILROY MEDIUM';
  font-size: 17px;
  line-height: 24px;
  margin: 0;
  text-align: center;
  color: #14141f;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
  flex-direction: row;
  margin-top: 24px;
  margin-bottom: 16px;
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
`;

const Container = styled.div.withConfig({
  shouldForwardProp: (prop) => !['modal'].includes(prop),
})`
  max-width: 567px;
  position: relative;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${({ modal }) =>
    modal &&
    `
        z-index:25;
        border-radius: 8px;
        box-shadow: 0px 6px 24px 0px #14141F1F;
        background-color: #fff;
        max-width: 655px;
        padding: 30px 16px;
        @media (min-width: 768px ){
            width: 655px;   
        }
        marging:10px;


    `}
`;

const CloseIconWrapper = styled.span`
  top: 15px;
  right: 15px;
  cursor: pointer;
  height: 16px;
  width: 16px;
  position: absolute;
`;

const Main = styled.main.withConfig({
  shouldForwardProp: (prop) => !['withBackground'].includes(prop),
})`
  display: flex;
  flex-flow: column nowrap;
  min-height: 100vh;
  padding: 76px 16px 0;
  overflow: hidden;
  position: relative;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    padding: 0 16px;
  }
  ${({ withBackground }) =>
    withBackground &&
    `
    background: url('/static/img/bg_resume_massonny.png');
  `}
`;
