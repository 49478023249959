import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const PhoneIcon = ({ references }) => (
  <SvgIcon references={references ? 1 : 0} width="19" height="24" viewBox="0 0 19 24">
    <StyledPath
      fillRule="evenodd"
      d="M18.037 21.231c-.37.359-.739.715-1.109 1.069l-4.358-5.602c.365-.355.729-.704 1.092-1.057.361-.345.601-.596.932-.53a.728.728 0 0 1 .301.12c.113.078.222.232.33.371.159.202.318.408.477.613.712.918 1.423 1.828 2.135 2.744.153.195.306.392.453.585.096.123.203.244.257.364.058.134.079.27.083.399.008.367-.24.592-.593.924zm-1.966 1.739c-.304.145-.6.22-.899.27-.925.163-1.865.009-2.761-.285-.247-.081-.477-.194-.726-.284-.198-.075-.391-.176-.585-.274-.43-.231-.857-.485-1.29-.776-.412-.279-.804-.599-1.223-.924-.353-.274-.683-.612-1.035-.921-.125-.123-.254-.245-.381-.371-.189-.201-.386-.402-.582-.605-.206-.23-.418-.467-.627-.696a8.677 8.677 0 0 0-.177-.213c-.189-.223-.374-.446-.565-.669-.045-.062-.093-.118-.14-.182l-.536-.69c-.048-.064-.096-.122-.143-.188-.193-.264-.387-.526-.58-.794l-.492-.722-.3-.458c-.238-.434-.531-.829-.749-1.257-.07-.133-.145-.26-.217-.391-.209-.426-.441-.836-.62-1.255-.229-.536-.461-1.05-.628-1.578-.243-.768-.448-1.498-.557-2.25C.091 6.282.095 5.141.427 4.002c.09-.31.214-.61.385-.916.063-.111.124-.229.22-.33 1.491 1.912 2.976 3.819 4.463 5.731-.701.659-.206 1.598.181 2.357.16.31.388.61.565.932.076.115.155.229.231.346.123.178.246.357.374.538l.431.589c.054.065.102.127.153.194.106.131.209.267.315.399l.107.137c.148.177.295.349.445.521l.416.48c.105.112.207.227.311.338.339.313.636.689.965.985.662.576 1.462 1.482 2.197.771 1.448 1.862 2.896 3.726 4.348 5.588a1.51 1.51 0 0 1-.463.308zM7.007 7.027l-1.121 1.08C4.403 6.193 2.913 4.286 1.429 2.379c.371-.359.743-.72 1.116-1.077.349-.332.575-.577.897-.513.12.024.239.064.356.155.097.078.193.221.283.336l.499.644c.731.936 1.462 1.873 2.19 2.812.157.203.313.405.474.606.077.106.18.214.234.323.08.159.105.305.108.458.008.354-.239.578-.579.904z"
    />
  </SvgIcon>
);

PhoneIcon.propTypes = {
  references: PropTypes.bool,
};

const StyledPath = styled.path`
  fill: ${({ theme: { rigaColor } }) => rigaColor('#929292')};
`;

const SvgIcon = styled.svg`
  stroke: none;
  ${({ theme: { convertPx, isRTL }, references }) => css`
    width: ${convertPx(19)};
    height: ${convertPx(24)};
    ${references &&
    css`
      width: ${convertPx(12)};
      height: ${convertPx(15)};
      margin-right: ${convertPx(isRTL ? 0 : 8)};
      margin-left: ${convertPx(isRTL ? 8 : 0)};
      path {
        fill: #333e50;
      }
    `}
  `}
`;

PhoneIcon.propTypes = {
  color: PropTypes.string,
};

export default PhoneIcon;
