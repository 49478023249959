import React from 'react';
import styled from 'styled-components';

const Logo = ({ width = 101, height = 11 }) => {
  return (
    <SvgIcon width={width} height={height} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.822 11V8.214H4.53L6.154 11h3.178L7.106 7.192a3.857 3.857 0 001.246-2.828A3.872 3.872 0 004.488.5H.064V11h2.758zM4.53 5.834H2.822v-3.01H4.53c.84 0 1.512.672 1.512 1.512S5.37 5.834 4.53 5.834zM17.214 11V8.746h-3.766V6.814h3.332V4.49h-3.332V2.782h3.766V.5H10.69V11h6.524zm5.04 0a3.215 3.215 0 003.234-3.22c0-.77-.252-1.47-.7-2.03a3.218 3.218 0 00-2.534-1.246.783.783 0 01-.784-.784c0-.434.35-.798.784-.798.434 0 .798.364.798.798h2.436A3.215 3.215 0 0022.254.5a3.215 3.215 0 00-3.22 3.234 3.203 3.203 0 003.22 3.22c.434 0 .798.364.798.798a.795.795 0 01-.798.784.786.786 0 01-.784-.798h-2.45c0 1.848 1.442 3.262 3.234 3.262zm9.324 0a4.135 4.135 0 004.144-4.144V.5H32.95v6.314c0 .756-.616 1.372-1.372 1.372a1.374 1.374 0 01-1.372-1.372V.5h-2.772v6.356A4.117 4.117 0 0031.578 11zm9.016 0V5.624l2.758 2.492 2.744-2.506V11h2.772V.5h-1.246l-4.27 3.892L39.068.5h-1.246V11h2.772zm16.912 0V8.746H53.74V6.814h3.332V4.49H53.74V2.782h3.766V.5h-6.524V11h6.524zm5.866 0a5.252 5.252 0 005.25-5.25A5.252 5.252 0 0063.372.5H59.48V11h3.892zm.014-2.744h-1.162v-4.97h1.162a2.495 2.495 0 012.492 2.478 2.498 2.498 0 01-2.492 2.492zM75.552 11a5.24 5.24 0 005.25-5.236C80.802 2.866 78.45.5 75.552.5s-5.236 2.366-5.236 5.264A5.228 5.228 0 0075.552 11zm0-2.744c-1.372 0-2.492-1.134-2.492-2.506a2.5 2.5 0 012.492-2.506 2.503 2.503 0 012.506 2.506 2.512 2.512 0 01-2.506 2.506zM85.478 11V6.03L90.434 11h1.134V.5H88.81v4.956L83.84.5h-1.12V11h2.758zm14.728 0V8.746H96.44V6.814h3.332V4.49H96.44V2.782h3.766V.5h-6.524V11h6.524z"
        fill="#000F3D"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
};

const SvgIcon = styled.svg`
  stroke: none;
`;

export default Logo;
