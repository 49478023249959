import gql from 'graphql-tag';

export const paymentPlans = gql`
  fragment PaymentPlans on Plan {
    id
    type
    currency
    experiment
    amount
    billingCycle
    trialDays
    trialAmount
    isMonthly
  }
`;

export const Subscription = gql`
  fragment Subscription on Subscription {
    id
    customerId
    planId
    createdAt
    updatedAt
    cancelledAt
    dueSince
    isDeleted
    isActive
    isDeleted
    isCancelled
    isMonthly
    isYearly
    billingPeriodUnit
    currencyCode
    status
    userId
    podioId
  }
`;
