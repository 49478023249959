import React, { PureComponent } from 'react';
import ImageBlobReduce from 'image-blob-reduce';
import PhotoDragAndDrop from './PhotoDragAndDrop';
import PropTypes from 'prop-types';

import PhotoCrop from '/imports/core/ui/atoms/PhotoCrop.js';

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

class PhotoEdit extends PureComponent {
  static propTypes = {
    onSave: PropTypes.func,
    cropSize: PropTypes.number,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    cropSize: 200,
  };

  state = {
    image: null,
  };

  onChange = async ({
    target: {
      files: [file],
    },
  }) => {
    const { cropSize } = this.props;
    const readAndCropImage = async (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = async (event) => {
          const image = new Image();
          image.src = event.target.result;

          image.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const maxSize = cropSize + 280;

            let width = image.width;
            let height = image.height;

            if (width > height) {
              if (width > maxSize) {
                height *= maxSize / width;
                width = maxSize;
              }
            } else {
              if (height > maxSize) {
                width *= maxSize / height;
                height = maxSize;
              }
            }

            canvas.width = width;
            canvas.height = height;

            ctx.drawImage(image, 0, 0, width, height);
            canvas.toBlob(resolve, file.type);
          };
        };

        reader.onerror = reject;

        reader.readAsDataURL(file);
      });
    };

    try {
      const croppedBlob = await readAndCropImage(file);
      const croppedBase64 = await toBase64(croppedBlob);
      this.setState({ image: croppedBase64 });
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    return !this.state.image ? (
      <PhotoDragAndDrop onChange={this.onChange} />
    ) : (
      <PhotoCrop
        onChange={this.onChange}
        onSave={this.props.onSave}
        image={this.state.image}
        loading={this.props.loading}
        cropSize={this.props.cropSize}
      />
    );
  }
}

export default PhotoEdit;
