import React from 'react';
import styled, { css } from 'styled-components';

import Container from '/imports/core/ui/atoms/Container';
import DashboardHeading from '/imports/dashboard/ui/atoms/DashboardHeading';
import useIntl from '/imports/core/api/useIntl';

const AccountPageWrapper = ({ children, isSkeleton, isNew }) => {
  const { t } = useIntl();
  return (
    <Section>
      <StyledContainer isSkeleton={isSkeleton}>
        {!isNew && <SettingsHeading>{t('account')}</SettingsHeading>}
        {children}
      </StyledContainer>
    </Section>
  );
};

export default AccountPageWrapper;

const Section = styled.section`
  padding: 60px 32px 80px;
  background-color: #f0f0f2;
  min-height: calc(100vh - 64px);
  ${({ theme }) => theme.max('sm')`
    padding: 40px 0px 48px;
  `}
  ${({ isNew }) =>
    isNew &&
    css`
      padding: 0;
      padding-top: 23px;
    `}
  ${({ theme }) =>
    theme.isJobTrack &&
    css`
      background-color: var(--light-values-white);
      padding-top: 32px;
      padding-bottom: 0;
      ${({ theme }) => theme.max('sm')`
       padding: 40px 0px 0px;
      `}
    `}
`;

const StyledContainer = styled((p) => <Container {...p} />)`
  max-width: 736px;
  ${({ isNew }) =>
    isNew &&
    css`
      margin-left: 75px;
    `}
  ${({ theme: { isRTL }, isSkeleton }) =>
    isSkeleton &&
    isRTL &&
    css`
      .react-loading-skeleton {
        animation-direction: reverse;
      }
    `}
  ${({ theme }) =>
    theme.isJobTrack &&
    css`
      width: 90%;
      max-width: 90%;
      margin: 0 auto;
      @media (min-width: 768px) {
        width: 100%;
        max-width: 652px;
      }
      @media (min-width: 992px) {
        max-width: 876px;
      }
      @media (min-width: 1200px) {
        max-width: 912px;
      }
      @media (min-width: 1440px) {
        max-width: 800px;
      }
      @media (min-width: 1920px) {
        max-width: 1200px;
      }
    `}
`;

const SettingsHeading = styled(DashboardHeading)`
  && {
    color: black;
    margin-bottom: 40px;
    padding-bottom: 30px;
    letter-spacing: 1.7px;
    border-bottom: 1px solid #cad1d9;

    ${(p) => p.theme.max('sm')`
      margin-bottom: 20px !important;
      padding-bottom: 20px;
    `}
  }
`;
