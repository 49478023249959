import React, { PureComponent } from 'react';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import styled, { css } from 'styled-components';
import { withRouter } from 'next/router';

import { AccountProvider } from '/imports/core/api/accounts/accountContext';
import AccountSkeleton from '/imports/dashboard/ui/skeleton/AccountSkeleton';
import AdminBar from '/imports/core/ui/components/AdminBar';
import { billingClient } from '/lib/clients';
import { BillingProvider } from '/imports/checkout/api/useBilling';
import { CURRENT_USER } from '/imports/core/api/apollo/client/queries';
import Error from '/components/Error';
import Footer from '/imports/core/ui/components/Footer';
import Header from '/imports/dashboard/ui/components/Header';
// import Footer from '/imports/core/ui/components/Footer'; //remove this comonent if new dashboard apply
import { isJobTrackEnable, removeExperiment } from '/lib/helpers';
import LayoutSkeleton from '/imports/job-tracking/ui/skeleton/layoutSkeleton';
import { withResponsiveContext } from '/imports/core/api/responsiveContext';
import AgentUsers from '/imports/job-tracking/ui/components/AgentUsers';
import { getIsAgent } from '/imports/generator/api/helpers';

const DashboardAccountDynamic = dynamic(() => import('/imports/dashboard/ui/pages/AccountPage'), {
  ssr: false,
  loading: () => <AccountSkeleton />,
});

@withResponsiveContext
@withRouter
class Account extends PureComponent {
  static propTypes = {
    currentUser: PropTypes.object,
    router: PropTypes.object,
  };

  componentDidMount = () => {
    removeExperiment('resume_thumbnail_preview_experiment');
  };
  renderAccountLayout() {
    const {
      query: { page },
    } = this.props.router;
    if (isJobTrackEnable()) {
      return (
        <Main>
          <DashboardAccountDynamic {...this.props} isNew />
        </Main>
      );
    } else {
      return (
        <Main showfooter>
          <Header showDashboard={page === 'account'} />
          <DashboardAccountDynamic {...this.props} />
          <Footer />
        </Main>
      );
    }
  }
  render() {
    const isAgent = getIsAgent();
    return (
      <Query query={CURRENT_USER}>
        {({ data, loading, error, refetch }) => {
          if (loading) return isJobTrackEnable() ? <LayoutSkeleton /> : <AccountSkeleton />;
          if (error && error.networkError.statusCode !== 400) return <Error />;
          return (
            <AccountProvider
              value={{
                currentUser: data ? data.userInfo : null,
                userLoading: loading,
                refetch,
              }}
            >
              <BillingProvider onlyForSpecial={true} value={billingClient} loadingComponent="account">
                {this.renderAccountLayout()}
                {data?.userInfo && data.userInfo?.role === 'admin' && <AdminBar />}
                {isAgent && <AgentUsers />}
              </BillingProvider>
            </AccountProvider>
          );
        }}
      </Query>
    );
  }
}
Account.displayName = 'Account';

const Main = styled.main`
  min-height: 100vh;
  width: 100%;
  background-color: #f0f0f2;
  padding: 0;
  ${({ theme }) => theme.max('md')`
    margin-top: ${theme.general.headerHeight};
  `}
  ${({ theme }) =>
    theme.isJobTrack &&
    css`
      background-color: var(--light-values-white);
      ${theme.max('md')`
         margin-top: 0;
      `}
    `}
`;

export default Account;
