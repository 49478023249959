import { getArticlesImages } from 'imports/help/api/articlesImages';
import { useResponsive } from 'imports/core/api/responsiveContext';

import ArticlePageWrapper from '/imports/help/ui/components/ArticlePageWrapper';
import ImageWrapper from '/imports/help/ui/atoms/ImageWrapper';
import { StyledText, StyledListContainer } from '/imports/help/ui/atoms/Typography';
import useIntl from '/imports/core/api/useIntl';

const ChangeTemplate = () => {
  const { t } = useIntl();

  const { host } = useResponsive();
  const images = getArticlesImages(host);
  return (
    <ArticlePageWrapper title={'help.change_template'}>
      <StyledText>{t('help.change_template.main_content')}</StyledText>
      <ImageWrapper src={images.change_resume_template_1} />
      <ImageWrapper src={images.change_resume_template_2} />
      <StyledText>{t('help.change_template.customize_list_title')}</StyledText>
      <StyledListContainer>
        <li>{t('help.change_template.template')}</li>
        <li>{t('help.change_template.theme')}</li>
        <li>{t('help.change_template.photo')}</li>
        <li>{t('help.change_template.language')}</li>
        <li>{t('help.change_template.font')}</li>
      </StyledListContainer>
    </ArticlePageWrapper>
  );
};

export default ChangeTemplate;
