import React from 'react';
import Flex from 'imports/core/ui/atoms/Flex';
import styled from 'styled-components';
import { staticFile } from 'lib/helpers';

import BasicContainer from '/imports/homepageV3/ui/atoms/BasicContainer';
import BasicSectionWrapper from '/imports/homepageV3/ui/atoms/BasicSectionWrapper';
import IconRight from '/imports/homepageV3/ui/atoms/svg/IconRight';
import PrimaryBtn from '/imports/homepageV3/ui/atoms/PrimaryBtn';
import StyledH2 from '/imports/homepageV3/ui/atoms/StyledH2';
import useIntl from '/imports/core/api/useIntl';

const Section6 = ({ userIdentified, onClickLandingCTA }) => {
  const { t } = useIntl();
  return (
    <BasicSectionWrapper>
      <BasicContainer>
        <CtaWrapper>
          <CtaFlexCenter>
            <CtaTextWrapper $justifyContent="flex-start" $direction="column" $alignItems="center">
              <CtaH2>{t('resumizeme_landing_sec6_title')}</CtaH2>
              <CtaText>{t('resumizeme_landing_sec6_text')}</CtaText>
            </CtaTextWrapper>
            <PrimaryBtn onClick={onClickLandingCTA}>
              <div>{!userIdentified ? t('landing.cta') : t('landing.my_resumes')}</div>
              <IconRight />
            </PrimaryBtn>
          </CtaFlexCenter>
        </CtaWrapper>
      </BasicContainer>
    </BasicSectionWrapper>
  );
};

const CtaWrapper = styled.div`
  background-image: url(${staticFile('img/landingv3/Union.svg')});
  background-repeat: no-repeat;
  position: relative;
  border-radius: 16px;
  padding: 48px 45px;
  background-color: #0050c7;
  color: #fff;
  @media (max-width: 991px) {
    padding: 48px 37px;
  }
  @media (max-width: 767px) {
    padding: 32px 28px;
  }
  @media (max-width: 479px) {
    padding: 32px 24px;
  }
`;
const CtaFlexCenter = styled.div`
  display: flex;
  max-width: 960px;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  text-align: center;
  > div:not(:last-child) {
    margin-bottom: 24px;
  }
`;
const CtaTextWrapper = styled(Flex)`
  > h2 {
    margin-bottom: 8px;
  }
`;
const CtaH2 = styled(StyledH2)`
  margin: 0;
  color: #fff;
  @media (max-width: 479px) {
    font-size: 32px;
    line-height: 44px;
  }
`;
const CtaText = styled.div`
  font-size: 18px;
`;
export default Section6;
