import useIntl from '/imports/core/api/useIntl';
import Input from '/imports/core/ui/atoms/Input';

const AddressInputAutoSuggestion = (props) => {
  const { t } = useIntl();
  const placeholder = t('generator_address_placeholder');

  return <Input {...props} placeholder={placeholder} />;
};

export default AddressInputAutoSuggestion;
