import React from 'react';
import styled from 'styled-components';
import StyledH2 from '../atoms/StyledH2';

import BasicContainer from '/imports/homepageV3/ui/atoms/BasicContainer';
import BasicSectionWrapper from '/imports/homepageV3/ui/atoms/BasicSectionWrapper';
import CardHeadText from '/imports/homepageV3/ui/atoms/CardHeadText';
import CardParagraph from '/imports/homepageV3/ui/atoms/CardParagraph';
import {
  Quality1Icon,
  Quality2Icon,
  Quality3Icon,
  Quality4Icon,
  Quality5Icon,
  Quality6Icon,
  Quality7Icon,
  Quality8Icon,
  Quality9Icon,
  Quality10Icon,
  Quality11Icon,
  Quality12Icon,
} from '/imports/homepageV3/ui/atoms/svg';
import useIntl from '/imports/core/api/useIntl';

const Section5 = () => {
  const { t } = useIntl();
  const QUALITIES = [
    {
      icon: <Quality1Icon />,
      headtext: t(`resumizeme_landing_sec5_quality1_headtext`),
      paragraph: t(`resumizeme_landing_sec5_quality1_paragraph`),
    },
    {
      icon: <Quality2Icon />,
      headtext: t(`resumizeme_landing_sec5_quality2_headtext`),
      paragraph: t(`resumizeme_landing_sec5_quality2_paragraph`),
    },
    {
      icon: <Quality3Icon />,
      headtext: t(`resumizeme_landing_sec5_quality3_headtext`),
      paragraph: t(`resumizeme_landing_sec5_quality3_paragraph`),
    },
    {
      icon: <Quality4Icon />,
      headtext: t(`resumizeme_landing_sec5_quality4_headtext`),
      paragraph: t(`resumizeme_landing_sec5_quality4_paragraph`),
    },
    {
      icon: <Quality5Icon />,
      headtext: t(`resumizeme_landing_sec5_quality5_headtext`),
      paragraph: t(`resumizeme_landing_sec5_quality5_paragraph`),
    },
    {
      icon: <Quality6Icon />,
      headtext: t(`resumizeme_landing_sec5_quality6_headtext`),
      paragraph: t(`resumizeme_landing_sec5_quality6_paragraph`),
    },
    {
      icon: <Quality7Icon />,
      headtext: t(`resumizeme_landing_sec5_quality7_headtext`),
      paragraph: t(`resumizeme_landing_sec5_quality7_paragraph`),
    },
    {
      icon: <Quality8Icon />,
      headtext: t(`resumizeme_landing_sec5_quality8_headtext`),
      paragraph: t(`resumizeme_landing_sec5_quality8_paragraph`),
    },
    {
      icon: <Quality9Icon />,
      headtext: t(`resumizeme_landing_sec5_quality9_headtext`),
      paragraph: t(`resumizeme_landing_sec5_quality9_paragraph`),
    },
    {
      icon: <Quality10Icon />,
      headtext: t(`resumizeme_landing_sec5_quality10_headtext`),
      paragraph: t(`resumizeme_landing_sec5_quality10_paragraph`),
    },
    {
      icon: <Quality11Icon />,
      headtext: t(`resumizeme_landing_sec5_quality11_headtext`),
      paragraph: t(`resumizeme_landing_sec5_quality11_paragraph`),
    },
    {
      icon: <Quality12Icon />,
      headtext: t(`resumizeme_landing_sec5_quality12_headtext`),
      paragraph: t(`resumizeme_landing_sec5_quality12_paragraph`),
    },
  ];
  return (
    <BasicSectionWrapper>
      <BasicContainer>
        <Standout>
          <StandoutHead>
            <StyledH2>{t('resumizeme_landing_sec5_headtitle')}</StyledH2>
            <StandoutParagraph>{t('resumizeme_landing_sec5_headtext')}</StandoutParagraph>
          </StandoutHead>
          <StandoutQualities>
            {QUALITIES.map((quality, index) => (
              <StandoutQuality key={index}>
                <StandoutQualityIconWrapper>{quality.icon}</StandoutQualityIconWrapper>
                <StandoutQualityDescriptionWrapper>
                  <CardHeadText>{quality.headtext}</CardHeadText>
                  <CardParagraph>{quality.paragraph}</CardParagraph>
                </StandoutQualityDescriptionWrapper>
              </StandoutQuality>
            ))}
          </StandoutQualities>
        </Standout>
      </BasicContainer>
    </BasicSectionWrapper>
  );
};

const Standout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const StandoutHead = styled.div`
  max-width: 960px;
  margin-right: auto;
  margin-bottom: 64px;
  margin-left: auto;
  text-align: center;
`;
const StandoutQualities = styled.div`
  display: grid;
  margin-bottom: 48px;
  grid-auto-columns: 1fr;
  grid-column-gap: 48px;
  grid-row-gap: 72px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto auto;
  @media (max-width: 991px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 767px) {
    grid-row-gap: 0;
    grid-column-gap: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    > div:not(:last-child) {
      margin-bottom: 48px;
      width: 100%;
    }
    grid-template-columns: 1fr;
  }
`;
const StandoutQuality = styled.div`
  display: flex;
  align-items: flex-start;
  > div:not(:last-child) {
    margin-right: 24px;
  }
  grid-column-start: span 1;
  grid-column-end: span 1;
  grid-row-start: span 1;
  grid-row-end: span 1;
`;
const StandoutQualityIconWrapper = styled.div`
  max-width: 46px;
  max-height: 46px;
`;
const StandoutQualityDescriptionWrapper = styled.div`
  flex: 1;
`;
const StandoutParagraph = styled.p`
  margin-bottom: 0px;
  color: #595964;
  font-weight: 500;
  @media (max-width: 479px) {
    font-size: 14px;
    line-height: 22px;
  }
`;
export default Section5;
