const CrossSwapIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0625 19.125C15.0676 19.125 19.125 15.0676 19.125 10.0625C19.125 5.05742 15.0676 1 10.0625 1C5.05742 1 1 5.05742 1 10.0625C1 15.0676 5.05742 19.125 10.0625 19.125Z"
        fill="#EDF4FF"
      />
      <g clipPath="url(#clip0_8239_51637)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.5 12.0625H7C6.6895 12.0625 6.4375 12.3145 6.4375 12.625C6.4375 12.9355 6.6895 13.1875 7 13.1875H14.5C14.8105 13.1875 15.0625 12.9355 15.0625 12.625C15.0625 12.3145 14.8105 12.0625 14.5 12.0625ZM5.5 7.9375H13C13.3105 7.9375 13.5625 7.6855 13.5625 7.375C13.5625 7.0645 13.3105 6.8125 13 6.8125H5.5C5.1895 6.8125 4.9375 7.0645 4.9375 7.375C4.9375 7.6855 5.1895 7.9375 5.5 7.9375Z"
          fill="#1688FE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.2273 11.1473L13.7041 12.6248L12.2273 14.1023C12.0076 14.3213 12.0076 14.6783 12.2273 14.8973C12.4463 15.1171 12.8033 15.1171 13.0223 14.8973L14.8973 13.0223C15.1171 12.8026 15.1171 12.4471 14.8973 12.2273L13.0223 10.3523C12.8033 10.1326 12.4463 10.1326 12.2273 10.3523C12.0076 10.5713 12.0076 10.9283 12.2273 11.1473ZM7.77231 8.85231L6.29556 7.37481L7.77231 5.89731C7.99206 5.67831 7.99206 5.32131 7.77231 5.10231C7.55331 4.88256 7.19631 4.88256 6.97731 5.10231L5.10231 6.97731C4.88256 7.19706 4.88256 7.55256 5.10231 7.77231L6.97731 9.64731C7.19631 9.86706 7.55331 9.86706 7.77231 9.64731C7.99206 9.42831 7.99206 9.07131 7.77231 8.85231Z"
          fill="#1688FE"
        />
      </g>
      <defs>
        <clipPath id="clip0_8239_51637">
          <rect width="12" height="12" fill="white" transform="translate(4 4)" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default CrossSwapIcon;
