import styled from 'styled-components';

const LanguagesIcon = ({ width = '22px', height = '22px', viewBox = '0 0 22 22' }) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
    <path d="M11 0c6.075 0 11 4.925 11 11s-4.925 11-11 11S0 17.075 0 11 4.925 0 11 0zm2.825 13.844l-.167.018c-.862.09-1.754.138-2.658.138-.98 0-1.924-.042-2.82-.123C8.632 17.457 9.875 20 11 20c1.127 0 2.374-2.558 2.825-6.156zm-6.17 5.514l-.037-.072c-.746-1.47-1.262-3.444-1.489-5.675-1.605-.28-2.982-.702-4.026-1.245l.032.195a9.014 9.014 0 005.52 6.797zm12.266-7.161l-.035.017c-1.055.544-2.439.998-4.007 1.313-.225 2.302-.758 4.335-1.534 5.831a9.012 9.012 0 005.576-7.161zM11 2c-1.406 0-3 3.984-3 9 0 .287.005.57.015.85.939.098 1.941.15 2.985.15 1.028 0 2.036-.072 2.985-.195.01-.265.015-.534.015-.805 0-5.016-1.594-9-3-9zm-3.345.642l-.158.065a9.009 9.009 0 00-5.431 7.2c.289.372 1.1.85 2.342 1.24.488.154 1.023.289 1.597.403A34.843 34.843 0 016 11c0-3.328.606-6.333 1.655-8.358zm6.69 0l.037.072C15.408 4.737 16 7.71 16 11c0 .152-.001.303-.004.454 1.978-.439 3.462-1.066 3.933-1.594a9.01 9.01 0 00-5.584-7.218z" />
  </SvgIcon>
);

const SvgIcon = styled.svg`
  stroke: none;
  fill: currentColor;
`;

LanguagesIcon.displayName = 'LanguagesIcon';

export default LanguagesIcon;
