import styled from 'styled-components';

const OutlinedDeleteIcon = ({ width = '16', height = '16', ...rest }) => {
  return (
    <SvgIcon {...rest} width={width} height={height} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        d="M10 .667c.736 0 1.333.597 1.333 1.333v.667h2c.737 0 1.334.597 1.334 1.333v1.333c0 .737-.597 1.334-1.334 1.334h-.054L12.667 14c0 .736-.597 1.333-1.334 1.333H4.667c-.737 0-1.334-.597-1.331-1.278l-.617-7.388h-.052a1.333 1.333 0 01-1.334-1.334V4c0-.736.597-1.333 1.334-1.333h2V2c0-.736.597-1.333 1.333-1.333h4zm1.941 6H4.057L4.667 14h6.666l.003-.055.605-7.278zM13.333 4H2.667v1.333h10.666V4zM10 2H6v.667h4V2z"
      />
    </SvgIcon>
  );
};

const SvgIcon = styled.svg`
  stroke: none;
  fill: currentColor;
`;

export default OutlinedDeleteIcon;
