import React from 'react';

const GlassDoor = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" viewBox="0 0 23 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0823 16.6296H22.362C22.4315 16.6296 22.4888 16.5683 22.4888 16.4926V7.34176C22.4867 5.10515 21.7466 3.2856 20.2481 2.01397C18.8701 0.84455 16.8011 0.139221 14.035 0.000199529C13.9655 -0.00388934 13.9083 0.0553987 13.9083 0.128998V3.58C13.9083 3.64747 13.9594 3.70267 14.0228 3.7088C16.4495 3.87849 18.2956 4.66151 18.2956 7.38878L13.0823 7.39083C13.0128 7.39083 12.9556 7.45216 12.9556 7.5278V16.4947C12.9556 16.5683 13.0128 16.6296 13.0823 16.6296ZM9.40439 7.37243H0.126755C0.0572441 7.37243 0 7.43376 0 7.50736V16.4722C0 16.5478 0.0572441 16.6092 0.126755 16.6092H5.34006C5.34006 19.3385 3.49394 20.1215 1.06719 20.2912C1.0352 20.2938 1.00545 20.3087 0.984125 20.3326C0.962801 20.3566 0.951547 20.3879 0.952706 20.42V23.873C0.952706 23.9446 1.01199 24.0039 1.07946 23.9998C3.84558 23.8608 5.91455 23.1555 7.29249 21.986C8.79106 20.7144 9.53115 18.8949 9.53319 16.6582V7.50736C9.53319 7.43171 9.47595 7.37038 9.40644 7.37038L9.40439 7.37243Z"
        fill="#00A264"
      />
    </svg>
  );
};

export default GlassDoor;
