import styled, { css } from 'styled-components';

import Container from '/imports/core/ui/atoms/Container';

const CheckoutContainer = styled(Container)`
  max-width: 1140px;

  ${({ isFullScreen }) =>
    isFullScreen &&
    css`
      width: 100% !important;
      max-width: 100%;
      padding-left: 40px;
    `}

  ${(p) =>
    p.page !== 'payment' &&
    css`
      ${({ theme: { larger, medium, smaller } }) => css`
        ${larger`
          max-width: 935px;
        `}
        ${medium`
          max-width: 867px;
       `}
        ${smaller`
          max-width: 622px;
        `}
      `}
    `}

  ${(p) =>
    p.page === 'payment' &&
    css`
      ${({ theme: { larger, medium } }) => css`
        ${larger`
            max-width: 801px;
          `}
        ${medium`
            max-width: 554px;
          `}
      `}
    `}
`;

CheckoutContainer.displayName = 'CheckoutContainer';

export default CheckoutContainer;
