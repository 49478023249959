import React from 'react';

const Quality5Icon = () => {
  return (
    <svg width="50" height="48" viewBox="0 0 50 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M25.4688 38.38L35.7604 46.84V38.38H25.4688Z" fill="#0866F5" />
      <path
        d="M41.5209 40H10.4793C8.07301 40 6.09385 38.34 5.96885 36.21L4.40635 10.19C4.27093 8.01 6.13551 6.15 8.59385 5.99L42.8334 3.78C45.5418 3.61 47.8126 5.57 47.6772 7.98L46.0313 36.2C45.9063 38.33 43.9272 39.99 41.5209 39.99V40Z"
        fill="#0866F5"
      />
      <path
        d="M39.4374 38.31H8.39577C5.98952 38.31 4.01035 36.65 3.88535 34.52L2.32285 8.49C2.18743 6.31 4.05202 4.45 6.51035 4.29L40.7499 2.09C43.4583 1.92 45.7291 3.88 45.5937 6.29L43.9479 34.51C43.8229 36.64 41.8437 38.3 39.4374 38.3V38.31Z"
        fill="#83F8C7"
      />
      <path
        d="M19.2189 3.47001V38.31H39.4272C41.8335 38.31 43.8127 36.64 43.9377 34.52L45.5835 6.30001C45.7189 3.90001 43.4585 1.93001 40.7397 2.10001L19.2085 3.48001L19.2189 3.47001Z"
        fill="#5DDFA9"
      />
      <path
        d="M12.7708 11H19.2188V15H12.7708C11.625 15 10.6875 14.1 10.6875 13C10.6875 11.9 11.625 11 12.7708 11Z"
        fill="#378EFF"
      />
      <path
        d="M19.2188 11H38.1875C39.3333 11 40.2708 11.9 40.2708 13C40.2708 14.1 39.3333 15 38.1875 15H19.2188V11Z"
        fill="#0866F5"
      />
      <path
        d="M12.7708 18.4H19.2188V22.4H12.7708C11.625 22.4 10.6875 21.5 10.6875 20.4C10.6875 19.3 11.625 18.4 12.7708 18.4Z"
        fill="#378EFF"
      />
      <path
        d="M19.2188 18.4H29C30.1458 18.4 31.0833 19.3 31.0833 20.4C31.0833 21.5 30.1458 22.4 29 22.4H19.2188V18.4Z"
        fill="#0866F5"
      />
      <path
        d="M12.7708 25.8H19.2188V29.8H12.7708C11.625 29.8 10.6875 28.9 10.6875 27.8C10.6875 26.7 11.625 25.8 12.7708 25.8Z"
        fill="#378EFF"
      />
      <path
        d="M19.2188 25.8H33.6667C34.8125 25.8 35.75 26.7 35.75 27.8C35.75 28.9 34.8125 29.8 33.6667 29.8H19.2188V25.8Z"
        fill="#0866F5"
      />
      <path d="M23.3853 36.69L33.6769 45.15V36.69H23.3853Z" fill="#5DDFA9" />
    </svg>
  );
};
export default Quality5Icon;
