import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import Router from 'next/router';
import { getJobTrackRoute } from 'lib/helpers';
import styled, { css } from 'styled-components';

import Container from '/imports/core/ui/atoms/Container';
import Footer from '/imports/core/ui/components/Footer';
import Header from '/imports/dashboard/ui/components/Header';
import { Push } from '/components/Link';
import { replaceString } from '/imports/core/ui/helpers';
import { staticFile, getRoute, isBlogDomain } from '/lib/helpers';
import useDomain from '/imports/core/hooks/useDomain';
import useIntl from '/imports/core/api/useIntl';
import { useResponsive } from '/imports/core/api/responsiveContext';
import useTracking from '/imports/core/hooks/useTracking';

const NotFound = ({ userIdentified }) => {
  const { t, locale } = useIntl();
  const { trackEvent } = useTracking();
  const { getBrandName } = useDomain();
  const { host } = useResponsive();
  const { href, as } = getJobTrackRoute(host, 'resumes');
  const not_found = replaceString(t('not_found.info'), { Resumedone: getBrandName() });

  useEffect(() => {
    trackEvent('404_view');
  }, []);
  const clickHandler = () => {
    userIdentified ? Push(href, locale, as) : Router.push(getRoute('/', locale, false, host, isBlogDomain(host)));
  };
  return (
    <Fragment>
      <Header />
      <Main>
        <ContainerComp>
          <ContentSection>
            <StyledImage src={staticFile('img/404.png')} />
            <StyledText heading>{t('not_found.title')}</StyledText>
            <StyledText>{not_found}</StyledText>
            <DashboardCTA onClick={clickHandler}>
              {userIdentified ? t('payment.success_go_to_dashboard') : t('not_found.go_to_home')}
            </DashboardCTA>
          </ContentSection>
        </ContainerComp>
      </Main>
      <Footer />
    </Fragment>
  );
};

const Main = styled.main`
  display: flex;
  align-items: center;
  min-height: calc(100vh - ${(p) => p.theme.general.headerHeight} - 40px);
  background-color: #f0f0f2;
  ${({ theme }) => theme.max('md')`
    margin-top: ${theme.general.headerHeight};
  `}
`;

const ContainerComp = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  ${(p) => p.theme.max('xs')`
    padding: 0;
  `}
`;

const ContentSection = styled.div`
  width: 100%;
  max-width: 812px;
  margin: 0;
  background: white;
  border-radius: ${(p) => p.theme.general.borderRadius};
  box-shadow:
    0 15px 35px rgba(50, 50, 93, 0.1),
    0 5px 15px rgba(0, 0, 0, 0.07);
  padding: 48px 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &::after {
    content: '';
    clear: both;
    display: block;
  }
`;

const StyledText = styled.p`
  font-family: TTCommons;
  font-size: 19px;
  margin: 0;
  color: ${(p) => p.theme.colors.black};
  ${(p) =>
    p.heading &&
    css`
      font-size: 30px;
      font-weight: 600;
    `}
`;

const DashboardCTA = styled.button`
  font-family: TTCommons;
  margin-top: 30px;
  padding: 12px 24px;
  border-radius: 3px;
  background-color: #2396f3;
  font-size: 18px;
  font-weight: 500;
  color: white;
`;

const StyledImage = styled.img`
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
  max-width: 630px;
  height: auto;
`;

NotFound.propTypes = {
  userIdentified: PropTypes.bool,
};

export default NotFound;
