import styled, { css } from 'styled-components';

const SectionDescription = styled.p`
  ${({ theme: { min } }) => css`
    margin: 0px auto;
    max-width: none;
    letter-spacing: 0.2px;
    font-weight: normal;
    max-width: 80%;
    font-size: 17px;
    font-family: ${({ theme }) => theme.font.family.websiteMedium};
    line-height: 1.3;
    color: #f37112;
    ${min('sm')`
      max-width: none;
    `}
  `}
`;

export default SectionDescription;
