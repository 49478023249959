import React from 'react';

const PrinterIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path
      fill="#0099DA"
      fillRule="evenodd"
      d="M18 2v5h2a2 2 0 012 2v8a2 2 0 01-2 2h-2v3H6v-3H4a2 2 0 01-2-2V9a2 2 0 012-2h2V2h12zm-2 15H8v3h8v-3zm4-8H4v8h2v-2h12v2h2V9zm-2 1a1 1 0 110 2 1 1 0 010-2zm-2-6H8v3h8V4z"
    />
  </svg>
);

export default PrinterIcon;
