import { darken } from 'polished';
import styled, { css } from 'styled-components';

import { hiddenMixin } from '/imports/core/ui/helpers';
import { linkStyle } from '/imports/core/ui/mixins';
import { staticFile } from '/lib/helpers';

const Button = styled.button.withConfig({
  shouldForwardProp: (prop) =>
    //prevent these props to render on the DOM
    !['huge', 'unstyled', 'link', 'default', 'outline', 'black', 'cta', 'rounded', 'upgrade'].includes(prop),
})`
  background: #1688fe;
  color: #fff;
  border: ${({ theme }) => theme.general.borderWidth} solid #1688fe;
  width: ${(p) => (p.fullWidth ? '100%' : 'auto')};
  font-size: ${(p) => p.theme.font.size.base};
  font-weight: 500;
  padding: 0.7em 1.7em;
  cursor: pointer;
  will-change: box-shadow;
  border-radius: ${(p) => p.theme.general.borderRadius};

  &:hover {
    background: ${darken(0.1, '#1688fe')};
    border-color: ${darken(0.1, '#1688fe')};
  }

  &:disabled {
    border-color: ${(p) => p.theme.colors.gray.light} !important;
    background-color: ${(p) => p.theme.colors.gray.light} !important;
    color: #fff !important;
  }

  ${(p) =>
    p.huge &&
    css`
      font-size: 15px;
      font-weight: bold;
      padding: 1em 6em;
    `}
  ${(p) =>
    p.unstyled &&
    css`
      background: transparent;
      border: 0;
      margin: 0;
      color: ${(p) => p.theme.colors.black};
      border-radius: 0;
      padding: 0;
      &:hover {
        box-shadow: none;
        background: transparent;
      }
    `}
  ${(p) =>
    p.link &&
    css`
      background: transparent;
      border: 0;
      margin: 0;
      padding: 0;
      ${linkStyle}
      &:hover {
        box-shadow: none;
        background: transparent;
      }
    `}
  ${(p) =>
    p.default &&
    css`
      background: transparent;
      border: transparent;
      font-weight: normal;
      &:hover {
        box-shadow: none;
        background: transparent;
      }
    `}
  ${(p) =>
    p.outline &&
    css`
      background: transparent;
      color: ${(p) => p.theme.colors.black};
      border-color: ${(p) => p.theme.colors.gray.light};
      &:hover {
        background: transparent;
        color: ${darken(0.1, '#1688fe')};
        border-color: ${darken(0.1, '#1688fe')};
      }
    `};
  ${(p) =>
    p.disabled &&
    css`
      background: ${(p) => p.theme.colors.gray.light} !important;
      border-color: ${(p) => p.theme.colors.gray.light} !important;
      &:hover {
        box-shadow: none;
        cursor: not-allowed;
        background: ${(p) => p.theme.colors.gray.light} !important;
      }
    `}
  ${(p) =>
    p.black &&
    css`
      background-color: ${(p) => p.theme.colors.black};
      border-color: ${(p) => p.theme.colors.black};
      color: white;
    `};
  ${(p) =>
    p.cta &&
    css`
      background-color: ${(p) => p.theme.colors.cta};
      border-color: ${(p) => p.theme.colors.cta};
    `};
  ${(p) =>
    p.rounded &&
    css`
      z-index: 2;
      width: 160px;
      border-radius: 23.5px;
      padding: 12px 25px;
      font-size: 13px;
      font-weight: 600;
      line-height: 23px;
      letter-spacing: 0.06em;
      text-align: center;
      position: relative;
      background-color: #2096f3;
      color: #fff;
      font-family: ${({ theme }) => theme.font.family.websiteRegular};
    `};
  ${(p) =>
    p.upgrade &&
    css`
      z-index: 2;
      border-radius: 23.5px;
      padding: 12px 25px;
      font-size: 13px;
      font-weight: 600;
      line-height: 23px;
      letter-spacing: 0.06em;
      text-align: center;
      position: relative;
      padding-left: 70px;
      border: 1px solid #0087c1;
      background: #0087c1;
      color: #fff;
      font-family: ${({ theme }) => theme.font.family.websiteRegular};
      transition: all 0.2s;
      &:after {
        content: '';
        position: absolute;
        top: 11px;
        left: 28px;
        width: 29px;
        height: 25px;
        background: url(${staticFile('img/star.svg')}) 50% / cover no-repeat;
      }
      &:hover {
        border-color: #1a5885;
        background: #1a5885;
        color: #fff;
      }
    `};
  ${hiddenMixin}
`;

export default Button;
