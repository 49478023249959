import React from 'react';
import { cdnFile } from 'lib/helpers';
import { content_landing_slugs } from 'imports/homepageV2/api/constants';
import styled, { css } from 'styled-components';

import Container from '/imports/homepageV2/ui/atmos/Container';
import Flex from '/imports/core/ui/atoms/Flex';
import { replaceString } from '/imports/core/ui/helpers';
import RightArrow from '/imports/homepageV2/ui/atmos/RightArrow';
import useDomain from '/imports/core/hooks/useDomain';
import useIntl from '/imports/core/api/useIntl';
import { useResponsive } from '/imports/core/api/responsiveContext';

const BuilderBlock = ({ onClickLandingCTA, isNewMobileDesign = false }) => {
  const intl = useIntl();
  const { getBrandName } = useDomain();
  const { host } = useResponsive();
  const slug = content_landing_slugs(host, intl.t);
  const landing_content = replaceString(slug['resume_nation_landing_s6_p2'], {
    'Resume-Nation': getBrandName(),
  });
  return (
    <BoxContainer $isNewMobileDesign={isNewMobileDesign}>
      <StyledContainer $isNewMobileDesign={isNewMobileDesign}>
        <Wrapper $direction="column" $isNewMobileDesign={isNewMobileDesign}>
          <Title
            dangerouslySetInnerHTML={{ __html: slug['resume_nation_landing_s6_p1'] }}
            $isNewMobileDesign={isNewMobileDesign}
          />
          <SubTitle $isNewMobileDesign={isNewMobileDesign}>{landing_content}</SubTitle>
          <TryNow onClick={onClickLandingCTA} $isNewMobileDesign={isNewMobileDesign}>
            {intl.t('resume_nation_landing_asset_2')}
            <RightArrow />
          </TryNow>
        </Wrapper>
      </StyledContainer>
    </BoxContainer>
  );
};

export default BuilderBlock;

const TryNow = styled.button`
  gap: 20px;
  margin-top: 32px;
  width: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 28px 20px 32px;
  border-radius: 32px;
  box-shadow: 0 8px 24px 0 var(--border-transparent);
  background-color: var(--primary);
  border: none;
  cursor: pointer;
  font-family: ${({ theme }) => theme.font.family.websiteBold};
  font-size: 18px;
  line-height: 1.33;
  color: var(--light-values-white);
  &:hover {
    background-color: var(--additional-colors-pink-old);
  }

  ${({ theme }) => theme.max('md')`
    margin-bottom:0;
    padding: 16px 28px 16px 32px;
    width: unset;
  `}
  ${({ isNewMobileDesign }) =>
    isNewMobileDesign &&
    css`
      margin-top: 16px !important;
      height: 60px;
      padding: 263px 24px;
      gap: 8px;
      align-self: stretch;
      border-radius: 30px;
      width: 100% !important;
    `}
    ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `}
`;

const SubTitle = styled.div`
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 19px;
  font-weight: 500;
  line-height: 1.47;
  text-align: center;
  color: var(--text);
  margin-top: 32px;
  ${({ theme }) => theme.max('md')`
      margin-top: 24px;
      font-size: 15px;
  `}
  ${({ isNewMobileDesign }) =>
    isNewMobileDesign &&
    css`
      color: #14141f;
      font-size: 16px !important;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin-top: 16px !important;
    `}
`;

const Title = styled.h2`
  margin: 0;
  flex-grow: 0;
  font-size: 50px;
  font-weight: 600;
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  line-height: 1.2;
  letter-spacing: -0.25px;
  color: var(--text);
  text-align: center;
  span {
    color: var(--primary);
  }
  ${({ theme }) => theme.max('md')`
      font-size: 34px;
      line-height: 1.29;
  `}
  ${({ isNewMobileDesign }) =>
    isNewMobileDesign &&
    css`
      color: #14141f;
      align-self: stretch;
      font-family: Gilroy Bold;
      font-size: 32px !important;
      font-style: normal;
      font-weight: 400;
      line-height: 44px !important;
    `}
`;

const Wrapper = styled(Flex)`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  max-width: 672px;
  padding: 80px 0;
  ${({ theme }) => theme.max('md')`
    padding: 48px 0;
  `}
  ${({ isNewMobileDesign }) =>
    isNewMobileDesign &&
    css`
      padding: 16px !important;
    `}
`;

const BoxContainer = styled(Container)`
  ${({ isNewMobileDesign }) =>
    isNewMobileDesign &&
    css`
      padding: 32px 16px;
    `}
`;
const StyledContainer = styled(Flex)`
  width: 100%;
  background-image: url(${cdnFile('resume-nation-landing/bg-tryNow.png')});
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 16px;
  border: solid 1px var(--border-transparent);
  ${({ theme }) => theme.max('md')`
      height: auto;
      border-radius: 0;
      border: none;
    `}
  ${({ isNewMobileDesign }) =>
    isNewMobileDesign &&
    css`
      background-image: none;
      border-radius: 8px !important;
      border: 1px solid #e3e3e4 !important;
    `}
`;
