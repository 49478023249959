import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { setContext } from 'apollo-link-context';

import { cookieParser } from '/lib/helpers';
import env from '/env';

// Creates an HTTP link to connect to a microservice GQL endpoint.
const createHttpLink = (uri) =>
  new HttpLink({
    uri,
    credentials: 'same-origin',
  });

// Creates an auth link to set authorization header.
const createAuthLink = () =>
  setContext((_, { headers }) => {
    // get the authentication token from cookies
    const { token } = cookieParser();
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

// Creates an error link to log formatted error messages.
const createErrorLink = () =>
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.error(`[GraphQL error]: Message: ${message}, Path: ${path}`),
      );
    }
    if (networkError) {
      console.error(`[${networkError.name}]: Status: ${networkError.statusCode}: Message: ${networkError.message}`);
    }
  });

// Billing client connects to the Billing microservice.
export const billingClient = new ApolloClient({
  link: ApolloLink.from([createErrorLink(), createAuthLink(), createHttpLink(`${env.BILLING_ENDPOINT}/graphql`)]),
  cache: new InMemoryCache(),
});

// Blog client
export const blogClient = new ApolloClient({
  link: ApolloLink.from([createErrorLink(), createAuthLink(), createHttpLink(`${env.BLOG_ENDPOINT}/graphql`)]),
  cache: new InMemoryCache(),
});
