import Flex from 'imports/core/ui/atoms/Flex';
import styled from 'styled-components';

import ArticleTitle from '/imports/help/ui/atoms/ArticleTitle';
import { Divider } from '/imports/help/ui/atoms/Divider';
import HelpFeedback from '/imports/help/ui/components/HelpFeedback';
import useIntl from '/imports/core/api/useIntl';

export const ArticlePageWrapper = (props) => {
  const { children, title, translated = false } = props;
  const { t } = useIntl();
  return (
    <Wrapper>
      <ArticleTitle>{translated ? title : t(title)}</ArticleTitle>
      <Divider />
      <ContentWrapper>
        <ArticleContentWrapper>{children}</ArticleContentWrapper>
        <HelpFeedback />
      </ContentWrapper>
    </Wrapper>
  );
};
const ContentWrapper = styled.div`
  padding: 0 8px;
  > div:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const ArticleContentWrapper = styled.div``;
const Wrapper = styled(Flex)`
  * {
    font-family: ${({ theme }) => theme.font.family.websiteMedium};
  }
  flex-direction: column;
  > *:not(:last-child) {
    margin-bottom: 24px;
  }
`;

export default ArticlePageWrapper;
