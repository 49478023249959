import React from 'react';

const CheckIcon = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="26" height="26" rx="13" fill="#008B5D" />
      <path
        d="M10.5235 14.7971L9.45647 13.7676C9.26972 13.5874 9.26972 13.3029 9.45647 13.1227C9.65328 12.9328 9.97953 12.9328 10.1763 13.1227L11.2176 14.1274L10.5235 14.7971ZM11.2436 15.4919L11.9377 14.8222L12.6318 15.4919L11.9377 16.1616L11.2436 15.4919ZM13.3519 14.7971L12.6578 14.1274L15.8204 11.076C16.0172 10.8861 16.3435 10.8861 16.5403 11.076C16.7271 11.2562 16.7271 11.5407 16.5403 11.7209L13.3519 14.7971Z"
        fill="black"
        stroke="white"
      />
    </svg>
  );
};

export default CheckIcon;
