import React from 'react';

const Logo = ({ sm }) => {
  return sm ? (
    <svg width="38" height="32" viewBox="0 0 38 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 32H0V10.6687C0 4.77529 4.77529 0 10.6687 0H26.6687V21.3313L16 32Z"
        fill="url(#paint0_linear_1224_6092)"
      />
      <path d="M16 21.3313H26.6687C26.6687 27.3977 22.851 32 16 32V21.3313Z" fill="#378EFF" />
      <path
        d="M26.6666 8.8889H13.3333C10.8787 8.8889 8.88885 10.8787 8.88885 13.3333V13.3333C8.88885 15.7879 10.8787 17.7778 13.3333 17.7778H26.6666V8.8889Z"
        fill="#14141F"
      />
      <path
        d="M26.6666 7.1111H32.8888C35.3434 7.1111 37.3333 9.10095 37.3333 11.5555V11.5555C37.3333 14.0101 35.3434 16 32.8888 16H26.6666V7.1111Z"
        fill="#5DDFA9"
      />
      <path
        d="M26.6666 7.1111H13.3333C10.8787 7.1111 8.88885 9.10095 8.88885 11.5555V11.5555C8.88885 14.0101 10.8787 16 13.3333 16H26.6666V7.1111Z"
        fill="#37B982"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1224_6092"
          x1="2.80463"
          y1="3.28031"
          x2="22.3691"
          y2="26.9097"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0866F5" />
          <stop offset="1" stopColor="#0043A6" />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg width="47" height="40" viewBox="0 0 47 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 40H0V13.3359C0 5.96911 5.96911 0 13.3359 0H33.3359V26.6641L20 40Z"
        fill="url(#paint0_linear_1224_6092)"
      />
      <path d="M20 26.6641H33.3359C33.3359 34.2471 28.5637 40 20 40V26.6641Z" fill="#378EFF" />
      <path
        d="M33.3334 11.1111H16.1111C13.3497 11.1111 11.1112 13.3497 11.1112 16.1111V17.2222C11.1112 19.9837 13.3497 22.2222 16.1112 22.2222H33.3334V11.1111Z"
        fill="#14141F"
      />
      <path
        d="M33.3334 8.88887H41.6667C44.4281 8.88887 46.6667 11.1274 46.6667 13.8889V15C46.6667 17.7614 44.4281 20 41.6667 20H33.3334V8.88887Z"
        fill="#5DDFA9"
      />
      <path
        d="M33.3334 8.88887H16.1111C13.3497 8.88887 11.1112 11.1274 11.1112 13.8889V15C11.1112 17.7614 13.3497 20 16.1112 20H33.3334V8.88887Z"
        fill="#37B982"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1224_6092"
          x1="3.50579"
          y1="4.10039"
          x2="27.9614"
          y2="33.6371"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0866F5" />
          <stop offset="1" stopColor="#0043A6" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default Logo;
