import Heading from './Heading';
import styled, { css } from 'styled-components';

const SubHeader = styled(Heading)`
  ${({ theme: { contentFont, convertPx, color, colors }, upper, small }) => css`
    font-family: ${contentFont('bold')};
    font-size: ${convertPx(small ? 11 : 12, true)};
    line-height: ${({ lineHeight }) => convertPx(lineHeight ?? 20)};
    margin-bottom: 0;
    text-transform: ${upper ? 'uppercase' : 'none'};
    color: #636466;
    z-index: 1;
    ${color &&
    color !== 'black' &&
    css`
      color: ${colors[color]};
    `}
  `}
`;

export default SubHeader;
