const CloseIcon = ({ fill = '#7171A6' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M14.2755 1.72388C14.0254 1.47392 13.6863 1.3335 13.3328 1.3335C12.9792 1.3335 12.6402 1.47392 12.3901 1.72388L7.99946 6.11454L3.60879 1.72388C3.35732 1.481 3.02052 1.34661 2.67092 1.34965C2.32133 1.35268 1.98691 1.49291 1.7397 1.74012C1.49249 1.98733 1.35226 2.32175 1.34923 2.67134C1.34619 3.02094 1.48058 3.35774 1.72346 3.60921L6.11412 7.99988L1.72346 12.3905C1.59611 12.5135 1.49453 12.6607 1.42466 12.8233C1.35478 12.986 1.318 13.161 1.31646 13.338C1.31492 13.515 1.34865 13.6906 1.41569 13.8545C1.48274 14.0183 1.58174 14.1672 1.70693 14.2924C1.83212 14.4176 1.98099 14.5166 2.14485 14.5836C2.30871 14.6507 2.48429 14.6844 2.66133 14.6829C2.83836 14.6813 3.01332 14.6446 3.176 14.5747C3.33867 14.5048 3.48579 14.4032 3.60879 14.2759L7.99946 9.88521L12.3901 14.2759C12.6416 14.5188 12.9784 14.6531 13.328 14.6501C13.6776 14.6471 14.012 14.5068 14.2592 14.2596C14.5064 14.0124 14.6467 13.678 14.6497 13.3284C14.6527 12.9788 14.5183 12.642 14.2755 12.3905L9.88479 7.99988L14.2755 3.60921C14.5254 3.35917 14.6658 3.0201 14.6658 2.66654C14.6658 2.31299 14.5254 1.97391 14.2755 1.72388Z"
        fill={fill}
      />
    </svg>
  );
};

export default CloseIcon;
