import styled from 'styled-components';

const SubTitle = styled.h3`
  font-family: ${({ theme }) => theme.font.family.websiteRegular};
  font-size: 18px;
  color: #7171a6;
  line-height: 1.39;
  position: relative;
  text-align: center;
  margin: 0 auto;
  ${({ theme }) => theme.max('xs')`
    padding: 0 20px;
    @media (max-width: 375px) {
      font-size: 14px;
      padding: 0 35px;
    }
    @media (max-width: 375px) {
      font-size: 14px;
      padding: 0 28px;
    }
  `}
`;
export default SubTitle;
