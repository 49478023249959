import styled, { css } from 'styled-components';

import Flex from '/imports/core/ui/atoms/Flex';

const ButtonPrimary = styled(Flex)`
  position: relative;
  cursor: pointer;
  background-color: var(--light-values-white);
  border-radius: 4px;
  padding: 0 9px;
  height: 32px;
  border: none;
  display: flex;
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 13px;
  color: var(--dark-values-dark-regular);
  line-height: 1.54;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
  ${({ padded }) =>
    padded &&
    css`
      padding: 0;
    `}
  ${({ active }) =>
    active &&
    css`
      background-color: var(--light-values-light-extra);
      color: var(--dark-values-black);
    `}
  span {
    font-size: 15px;
    display: flex;
    min-width: 25px;
    max-width: 25px;
  }
  &:hover {
    background-color: var(--light-values-light-extra);
    color: var(--dark-values-dark-regular);
  }
  ${({ pageTab }) =>
    pageTab &&
    css`
      margin-top: 2px;
    `}
`;

export default ButtonPrimary;
