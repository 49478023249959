import React from 'react';

const Quality10Icon = () => {
  return (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M45.8333 26.6875C45.8333 37.1875 33.1979 45.8333 25 45.8333V4.16667L45.8333 10.3021V26.6875Z"
        fill="#5DDFA9"
      />
      <path
        d="M4.1665 26.6875C4.1665 37.1875 16.8019 45.8333 24.9998 45.8333V4.16667L4.1665 10.3021V26.6875Z"
        fill="#83F8C7"
      />
      <path
        d="M33.333 20.7188H16.6663C15.5157 20.7188 14.583 21.6515 14.583 22.8021V37.3854C14.583 38.536 15.5157 39.4688 16.6663 39.4688H33.333C34.4836 39.4688 35.4163 38.536 35.4163 37.3854V22.8021C35.4163 21.6515 34.4836 20.7188 33.333 20.7188Z"
        fill="#0866F5"
      />
      <path
        d="M16.6663 20.7188H24.9997V39.4687H16.6663C15.5205 39.4687 14.583 38.5312 14.583 37.3854V22.8021C14.583 21.6562 15.5205 20.7188 16.6663 20.7188Z"
        fill="#378EFF"
      />
      <path
        d="M27.0832 28.0104C27.0832 26.8598 26.1504 25.9271 24.9998 25.9271C23.8492 25.9271 22.9165 26.8598 22.9165 28.0104V32.1771C22.9165 33.3277 23.8492 34.2604 24.9998 34.2604C26.1504 34.2604 27.0832 33.3277 27.0832 32.1771V28.0104Z"
        fill="white"
      />
      <path
        d="M24.9998 25.9271C23.854 25.9271 22.9165 26.8646 22.9165 28.0104V32.1771C22.9165 33.3229 23.854 34.2604 24.9998 34.2604V25.9271Z"
        fill="#EFF5FF"
      />
      <path
        d="M16.6665 20.7188H20.8332V18.875C20.8332 16.5729 22.6978 14.7083 24.9998 14.7083C27.3019 14.7083 29.1665 16.5729 29.1665 18.875V20.7188H33.3332V18.875C33.3332 14.2813 29.5936 10.5417 24.9998 10.5417C20.4061 10.5417 16.6665 14.2813 16.6665 18.875V20.7188Z"
        fill="#0050C7"
      />
      <path
        d="M24.9998 10.5312C20.4061 10.5312 16.6665 14.2708 16.6665 18.8646V20.7083H20.8332V18.8646C20.8332 16.5625 22.6978 14.6979 24.9998 14.6979V10.5312Z"
        fill="#0050C7"
      />
    </svg>
  );
};
export default Quality10Icon;
