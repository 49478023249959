import React from 'react';

const Quality12Icon = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_971_6759)">
        <rect x="4" y="2" width="33" height="44" rx="4" fill="#378EFF" />
        <g filter="url(#filter0_d_971_6759)">
          <rect x="8" y="11" width="14" height="2" rx="1" fill="#C8E0FF" />
          <rect x="8" y="15" width="24" height="2" rx="1" fill="#C8E0FF" />
          <rect x="8" y="19" width="8" height="2" rx="1" fill="#C8E0FF" />
        </g>
        <g filter="url(#filter1_d_971_6759)">
          <rect x="8" y="28" width="14" height="2" rx="1" fill="#C8E0FF" />
          <rect x="8" y="32" width="24" height="2" rx="1" fill="#C8E0FF" />
          <rect x="8" y="36" width="8" height="2" rx="1" fill="#C8E0FF" />
        </g>
        <g filter="url(#filter2_d_971_6759)">
          <path
            d="M44.8894 22.1039C44.8894 29.8932 38.5749 36.2078 30.7855 36.2078C22.9962 36.2078 16.6816 29.8932 16.6816 22.1039C16.6816 14.3145 22.9962 8 30.7855 8C38.5749 8 44.8894 14.3145 44.8894 22.1039Z"
            fill="url(#paint0_linear_971_6759)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30.7855 35.2078C38.0226 35.2078 43.8894 29.341 43.8894 22.1039C43.8894 14.8668 38.0226 9 30.7855 9C23.5484 9 17.6816 14.8668 17.6816 22.1039C17.6816 29.341 23.5484 35.2078 30.7855 35.2078ZM30.7855 36.2078C38.5749 36.2078 44.8894 29.8932 44.8894 22.1039C44.8894 14.3145 38.5749 8 30.7855 8C22.9962 8 16.6816 14.3145 16.6816 22.1039C16.6816 29.8932 22.9962 36.2078 30.7855 36.2078Z"
            fill="white"
          />
          <path
            d="M37.8374 33.8571L39.8731 32.6818L44.0744 39.9586C44.3506 40.4369 44.1867 41.0485 43.7084 41.3246L43.4047 41.5C42.9264 41.7761 42.3148 41.6122 42.0387 41.1339L37.8374 33.8571Z"
            fill="white"
          />
        </g>
        <g filter="url(#filter3_d_971_6759)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39.166 15.9078C39.9916 16.6416 40.0659 17.9058 39.3321 18.7313L30.9743 28.1339C30.6179 28.5349 30.1153 28.7758 29.5795 28.8027C29.0438 28.8295 28.5197 28.64 28.125 28.2767L22.3789 22.9878C21.5662 22.2397 21.5138 20.9745 22.2619 20.1618C23.0099 19.3491 24.2752 19.2967 25.0879 20.0447L29.3366 23.9555L36.3425 16.0739C37.0763 15.2483 38.3405 15.174 39.166 15.9078Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_971_6759"
          x="6"
          y="11"
          width="28"
          height="14"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_971_6759" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_971_6759" result="shape" />
        </filter>
        <filter
          id="filter1_d_971_6759"
          x="6"
          y="28"
          width="28"
          height="14"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_971_6759" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_971_6759" result="shape" />
        </filter>
        <filter
          id="filter2_d_971_6759"
          x="12.6816"
          y="8"
          width="36.208"
          height="41.6341"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_971_6759" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_971_6759" result="shape" />
        </filter>
        <filter
          id="filter3_d_971_6759"
          x="17.7334"
          y="15.4026"
          width="26.104"
          height="21.4026"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_971_6759" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_971_6759" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_971_6759"
          x1="21.5873"
          y1="10.4528"
          x2="40.5969"
          y2="36.2078"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.15335" stopColor="#99EBCD" />
          <stop offset="0.809612" stopColor="#99EBCD" stopOpacity="0.3" />
        </linearGradient>
        <clipPath id="clip0_971_6759">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default Quality12Icon;
