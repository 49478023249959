export const onboardingContent = {
  mysmartcv: {
    id: {
      title: 'Pilih Gaya Resume anda',
      subTitle: 'Mulai dengan memilih salah satu template resume tersukses kami',
    },
    es: {
      title: 'Elije tu estilo de currículum',
      subTitle: 'Comienza eligiendo una de nuestras plantillas de currículum más exitosas',
    },
  },
};
