import styled, { css } from 'styled-components';

import { buildResponsiveWidthMixin } from '/styles/mixins';
import { flexChildMixin, hiddenMixin } from '/imports/core/ui/helpers';

const getAlignment = (align) => {
  if (align === 'center') {
    return 'center';
  } else if (align === 'right' || align === 'bottom') {
    return 'flex-end';
  }
  return 'flex-start';
};

const Box = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    // prevent these style props to render on the DOM
    !['alignX', 'alignY', 'padded', 'exact', 'withHeader'].includes(prop),
})`
  display: block;

  a {
    text-decoration: none;
  }

  ${(p) =>
    p.width &&
    css`
      width: ${p.width};
    `}
  ${flexChildMixin}
  ${buildResponsiveWidthMixin}
  ${(p) =>
    p.padded &&
    css`
      padding-left: ${p.theme.general.gridGap};
      padding-right: ${p.theme.general.gridGap};
    `}
  ${(p) =>
    p.exact &&
    css`
      flex-grow: 0;
      flex-shrink: 0;
    `}
  ${(p) =>
    (p.alignX || p.alignY) &&
    css`
      display: flex;
      flex-direction: column;
      align-items: ${getAlignment(p.alignX)};
      justify-content: ${getAlignment(p.alignY)};
    `}
  ${hiddenMixin}
  ${({ theme }) =>
    theme.isJobTrack &&
    css`
      display: flex;
      width: 100%;
    `}
`;

export default Box;
