import styled from 'styled-components';

import LinkAtom from '/imports/pdf/core/ui/atoms/Link';

const Link = styled(LinkAtom)`
  font-family: ${({ theme }) => theme.contentFont()};
  color: #666766;
  font-size: ${({ theme }) => theme.convertPx(11.5, true)};
  line-height: 1.3;
  text-decoration: none;
`;

export default Link;
