const LOGOS = {
  'resume-builder': (
    <svg width="48" height="45" viewBox="0 0 48 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32.8899 7.35938H10.9399C9.83537 7.35938 8.93994 8.19884 8.93994 9.23438V38.6344C8.93994 39.6699 9.83537 40.5094 10.9399 40.5094H32.8899C33.9945 40.5094 34.8899 39.6699 34.8899 38.6344V9.23438C34.8899 8.19884 33.9945 7.35938 32.8899 7.35938Z"
        fill="#ADFADA"
      />
      <path
        d="M14.1099 3.55313H4.10986C3.00529 3.55313 2.10986 4.3926 2.10986 5.42813V14.8031C2.10986 15.8387 3.00529 16.6781 4.10986 16.6781H14.1099C15.2144 16.6781 16.1099 15.8387 16.1099 14.8031V5.42813C16.1099 4.3926 15.2144 3.55313 14.1099 3.55313Z"
        fill="#37B982"
      />
      <path
        d="M9.10986 13.8375C11.319 13.8375 13.1099 12.1586 13.1099 10.0875C13.1099 8.01643 11.319 6.33749 9.10986 6.33749C6.90072 6.33749 5.10986 8.01643 5.10986 10.0875C5.10986 12.1586 6.90072 13.8375 9.10986 13.8375Z"
        fill="#006F4A"
      />
      <path
        d="M9.10988 10.0969C9.83988 10.0969 10.4299 9.54375 10.4299 8.85937C10.4299 8.175 9.83988 7.62187 9.10988 7.62187C8.37988 7.62187 7.78988 8.175 7.78988 8.85937C7.78988 9.54375 8.37988 10.0969 9.10988 10.0969ZM11.2699 12.0844L10.9499 11.2969C10.7199 10.7437 10.1599 10.3781 9.51988 10.3781H8.70988C8.07988 10.3781 7.50988 10.7437 7.27988 11.2969L6.95988 12.0844C6.86988 12.3094 7.03988 12.5437 7.29988 12.5437H10.9299C11.1799 12.5437 11.3599 12.3094 11.2699 12.0844Z"
        fill="white"
      />
      <path
        d="M17.1099 24.3188H4.10986C3.00529 24.3188 2.10986 25.1582 2.10986 26.1938V36.6281C2.10986 37.6637 3.00529 38.5031 4.10986 38.5031H17.1099C18.2144 38.5031 19.1099 37.6637 19.1099 36.6281V26.1938C19.1099 25.1582 18.2144 24.3188 17.1099 24.3188Z"
        fill="#0866F5"
      />
      <path
        d="M14.8898 26.7281H6.33984C5.78756 26.7281 5.33984 27.1479 5.33984 27.6656C5.33984 28.1834 5.78756 28.6031 6.33984 28.6031H14.8898C15.4421 28.6031 15.8898 28.1834 15.8898 27.6656C15.8898 27.1479 15.4421 26.7281 14.8898 26.7281Z"
        fill="#EFF5FF"
      />
      <path
        d="M14.8898 30.4781H6.33984C5.78756 30.4781 5.33984 30.8979 5.33984 31.4156C5.33984 31.9334 5.78756 32.3531 6.33984 32.3531H14.8898C15.4421 32.3531 15.8898 31.9334 15.8898 31.4156C15.8898 30.8979 15.4421 30.4781 14.8898 30.4781Z"
        fill="#EFF5FF"
      />
      <path
        d="M14.8898 34.2281H6.33984C5.78756 34.2281 5.33984 34.6479 5.33984 35.1656C5.33984 35.6834 5.78756 36.1031 6.33984 36.1031H14.8898C15.4421 36.1031 15.8898 35.6834 15.8898 35.1656C15.8898 34.6479 15.4421 34.2281 14.8898 34.2281Z"
        fill="#EFF5FF"
      />
      <path
        d="M34.8901 43.3594C40.9653 43.3594 45.8901 38.7423 45.8901 33.0469C45.8901 27.3514 40.9653 22.7344 34.8901 22.7344C28.815 22.7344 23.8901 27.3514 23.8901 33.0469C23.8901 38.7423 28.815 43.3594 34.8901 43.3594Z"
        fill="#5DDFA9"
      />
      <path
        d="M45.8901 33.0469C45.8901 38.7469 40.9701 43.3594 34.8901 43.3594V22.7344C40.9701 22.7344 45.8901 27.3469 45.8901 33.0469Z"
        fill="#37B982"
      />
      <path
        d="M34.8904 33.0469V24.1219C36.3904 24.1219 37.8704 24.4594 39.2104 25.0969C40.5504 25.7344 41.7104 26.6625 42.5904 27.8062L34.8804 33.0562L34.8904 33.0469Z"
        fill="#006F4A"
      />
      <path
        d="M34.8901 26.7188C34.6101 26.7188 34.3901 26.5125 34.3901 26.25V24.375C34.3901 24.1125 34.6101 23.9062 34.8901 23.9062C35.1701 23.9062 35.3901 24.1125 35.3901 24.375V26.25C35.3901 26.5125 35.1701 26.7188 34.8901 26.7188Z"
        fill="#DDFFF1"
      />
      <path
        d="M34.8901 42.1969C34.6101 42.1969 34.3901 41.9906 34.3901 41.7281V39.8531C34.3901 39.5906 34.6101 39.3844 34.8901 39.3844C35.1701 39.3844 35.3901 39.5906 35.3901 39.8531V41.7281C35.3901 41.9906 35.1701 42.1969 34.8901 42.1969Z"
        fill="#DDFFF1"
      />
      <path
        d="M34.8901 42.1969V39.3844C35.1701 39.3844 35.3901 39.5906 35.3901 39.8531V41.7281C35.3901 41.9906 35.1701 42.1969 34.8901 42.1969Z"
        fill="#ADFADA"
      />
      <path
        d="M28.1304 33.0469C28.1304 33.3094 27.9104 33.5156 27.6304 33.5156H25.6304C25.3504 33.5156 25.1304 33.3094 25.1304 33.0469C25.1304 32.7844 25.3504 32.5781 25.6304 32.5781H27.6304C27.9104 32.5781 28.1304 32.7844 28.1304 33.0469Z"
        fill="#DDFFF1"
      />
      <path
        d="M30.1103 28.575C29.9103 28.7625 29.6003 28.7625 29.4003 28.575L27.9903 27.2531C27.7903 27.0656 27.7903 26.775 27.9903 26.5875C28.1903 26.4 28.5003 26.4 28.7003 26.5875L30.1103 27.9094C30.3103 28.0969 30.3103 28.3875 30.1103 28.575Z"
        fill="#DDFFF1"
      />
      <path
        d="M30.1103 37.5281C30.3103 37.7156 30.3103 38.0063 30.1103 38.1938L28.7003 39.5156C28.5003 39.7031 28.1903 39.7031 27.9903 39.5156C27.7903 39.3281 27.7903 39.0375 27.9903 38.85L29.4003 37.5281C29.6003 37.3406 29.9103 37.3406 30.1103 37.5281Z"
        fill="#DDFFF1"
      />
      <path
        d="M34.8901 26.7188V23.9062C35.1701 23.9062 35.3901 24.1125 35.3901 24.375V26.25C35.3901 26.5125 35.1701 26.7188 34.8901 26.7188Z"
        fill="#ADFADA"
      />
      <path
        d="M44.6401 33.0469C44.6401 33.3094 44.4201 33.5156 44.1401 33.5156H42.1401C41.8601 33.5156 41.6401 33.3094 41.6401 33.0469C41.6401 32.7844 41.8601 32.5781 42.1401 32.5781H44.1401C44.4201 32.5781 44.6401 32.7844 44.6401 33.0469Z"
        fill="#ADFADA"
      />
      <path
        d="M41.7803 39.5156C41.5803 39.7031 41.2703 39.7031 41.0703 39.5156L39.6603 38.1938C39.4603 38.0063 39.4603 37.7156 39.6603 37.5281C39.8603 37.3406 40.1703 37.3406 40.3703 37.5281L41.7803 38.85C41.9803 39.0375 41.9803 39.3281 41.7803 39.5156Z"
        fill="#ADFADA"
      />
      <path
        d="M41.7803 26.5875C41.9803 26.775 41.9803 27.0656 41.7803 27.2531L40.3703 28.575C40.1703 28.7625 39.8603 28.7625 39.6603 28.575C39.4603 28.3875 39.4603 28.0969 39.6603 27.9094L41.0703 26.5875C41.2703 26.4 41.5803 26.4 41.7803 26.5875Z"
        fill="#ADFADA"
      />
      <path
        d="M42.1501 1.64062H25.8901C24.7856 1.64062 23.8901 2.48009 23.8901 3.51562V17.6063C23.8901 18.6418 24.7856 19.4813 25.8901 19.4813H42.1501C43.2547 19.4813 44.1501 18.6418 44.1501 17.6063V3.51562C44.1501 2.48009 43.2547 1.64062 42.1501 1.64062Z"
        fill="#0866F5"
      />
      <path
        d="M40.83 3.99374H27.21C26.6577 3.99374 26.21 4.41348 26.21 4.93124C26.21 5.44901 26.6577 5.86874 27.21 5.86874H40.83C41.3822 5.86874 41.83 5.44901 41.83 4.93124C41.83 4.41348 41.3822 3.99374 40.83 3.99374Z"
        fill="#EFF5FF"
      />
      <path
        d="M35.62 7.74374H27.21C26.6577 7.74374 26.21 8.16348 26.21 8.68124C26.21 9.19901 26.6577 9.61874 27.21 9.61874H35.62C36.1722 9.61874 36.62 9.19901 36.62 8.68124C36.62 8.16348 36.1722 7.74374 35.62 7.74374Z"
        fill="#EFF5FF"
      />
      <path
        d="M35.62 15.2437H27.21C26.6577 15.2437 26.21 15.6635 26.21 16.1812C26.21 16.699 26.6577 17.1187 27.21 17.1187H35.62C36.1722 17.1187 36.62 16.699 36.62 16.1812C36.62 15.6635 36.1722 15.2437 35.62 15.2437Z"
        fill="#EFF5FF"
      />
      <path
        d="M38.23 11.4937H27.21C26.6577 11.4937 26.21 11.9135 26.21 12.4312C26.21 12.949 26.6577 13.3687 27.21 13.3687H38.23C38.7822 13.3687 39.23 12.949 39.23 12.4312C39.23 11.9135 38.7822 11.4937 38.23 11.4937Z"
        fill="#EFF5FF"
      />
    </svg>
  ),
  spelling: (
    <svg width="50" height="45" viewBox="0 0 50 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M35.4375 4.21875H8.42708C7.27649 4.21875 6.34375 5.05822 6.34375 6.09375V41.7188C6.34375 42.7543 7.27649 43.5938 8.42708 43.5938H35.4375C36.5881 43.5938 37.5208 42.7543 37.5208 41.7188V6.09375C37.5208 5.05822 36.5881 4.21875 35.4375 4.21875Z"
        fill="#0050C7"
      />
      <path
        d="M32.3125 1.40625H5.30208C4.15149 1.40625 3.21875 2.24572 3.21875 3.28125V38.9062C3.21875 39.9418 4.15149 40.7812 5.30208 40.7812H32.3125C33.4631 40.7812 34.3958 39.9418 34.3958 38.9062V3.28125C34.3958 2.24572 33.4631 1.40625 32.3125 1.40625Z"
        fill="#0866F5"
      />
      <path
        d="M32.3125 1.40625H5.30208C4.15149 1.40625 3.21875 2.24572 3.21875 3.28125V38.9062C3.21875 39.9418 4.15149 40.7812 5.30208 40.7812H32.3125C33.4631 40.7812 34.3958 39.9418 34.3958 38.9062V3.28125C34.3958 2.24572 33.4631 1.40625 32.3125 1.40625Z"
        fill="#0866F5"
      />
      <path
        d="M8.38542 5.39999H11.5104V7.27499H8.38542C7.8125 7.27499 7.34375 6.85312 7.34375 6.33749C7.34375 5.82187 7.8125 5.39999 8.38542 5.39999Z"
        fill="#C8E0FF"
      />
      <path
        d="M11.5103 5.39999H29.2186C29.7915 5.39999 30.2603 5.82187 30.2603 6.33749C30.2603 6.85312 29.7915 7.27499 29.2186 7.27499H11.5103V5.39999Z"
        fill="white"
      />
      <path
        d="M8.38542 10.5562H11.5104V12.4312H8.38542C7.8125 12.4312 7.34375 12.0094 7.34375 11.4937C7.34375 10.9781 7.8125 10.5562 8.38542 10.5562Z"
        fill="#C8E0FF"
      />
      <path
        d="M11.5103 10.5562H20.8853C21.4582 10.5562 21.9269 10.9781 21.9269 11.4937C21.9269 12.0094 21.4582 12.4312 20.8853 12.4312H11.5103V10.5562Z"
        fill="white"
      />
      <path
        d="M8.38542 19.9312H11.5104V21.8062H8.38542C7.8125 21.8062 7.34375 21.3844 7.34375 20.8687C7.34375 20.3531 7.8125 19.9312 8.38542 19.9312Z"
        fill="#C8E0FF"
      />
      <path
        d="M11.5103 19.9312H15.6769C16.2498 19.9312 16.7186 20.3531 16.7186 20.8687C16.7186 21.3844 16.2498 21.8062 15.6769 21.8062H11.5103V19.9312Z"
        fill="white"
      />
      <path
        d="M8.38542 15.2437H11.5104V17.1187H8.38542C7.8125 17.1187 7.34375 16.6969 7.34375 16.1812C7.34375 15.6656 7.8125 15.2437 8.38542 15.2437Z"
        fill="#C8E0FF"
      />
      <path
        d="M11.5103 15.2437H16.7186C17.2915 15.2437 17.7603 15.6656 17.7603 16.1812C17.7603 16.6969 17.2915 17.1187 16.7186 17.1187H11.5103V15.2437Z"
        fill="white"
      />
      <path
        d="M8.38542 33.9844H11.5104V35.8594H8.38542C7.8125 35.8594 7.34375 35.4375 7.34375 34.9219C7.34375 34.4062 7.8125 33.9844 8.38542 33.9844Z"
        fill="#C8E0FF"
      />
      <path
        d="M11.5103 33.9844H25.0519C25.6248 33.9844 26.0936 34.4062 26.0936 34.9219C26.0936 35.4375 25.6248 35.8594 25.0519 35.8594H11.5103V33.9844Z"
        fill="white"
      />
      <path
        d="M30.3021 32.4844C36.6303 32.4844 41.7604 27.8673 41.7604 22.1719C41.7604 16.4764 36.6303 11.8594 30.3021 11.8594C23.9738 11.8594 18.8438 16.4764 18.8438 22.1719C18.8438 27.8673 23.9738 32.4844 30.3021 32.4844Z"
        fill="#5DDFA9"
      />
      <path
        d="M34.3853 12.5531V31.7906C38.6873 30.3094 41.7498 26.5688 41.7498 22.1719C41.7498 17.775 38.6873 14.0344 34.3853 12.5531Z"
        fill="#37B982"
      />
      <path
        d="M40.2856 26.9436C39.472 26.2114 38.1529 26.2114 37.3393 26.9436C36.5258 27.6758 36.5258 28.863 37.3394 29.5952L43.2319 34.8985C44.0455 35.6308 45.3646 35.6308 46.1782 34.8985C46.9918 34.1663 46.9918 32.9791 46.1782 32.2469L40.2856 26.9436Z"
        fill="#37B982"
      />
      <path
        d="M38.6353 22.1719C38.6353 19.3687 36.9269 16.9312 34.3957 15.6375V18.8812L34.8957 18.3844C35.2811 18 35.9478 17.9719 36.3644 18.3281C36.7603 18.6562 36.8123 19.2 36.4894 19.5844C36.4686 19.6031 35.5103 20.7562 34.3853 22.1062V28.6875C36.9165 27.4031 38.6248 24.9562 38.6248 22.1531L38.6353 22.1719Z"
        fill="#83F8C7"
      />
      <path
        d="M36.4897 19.5937C36.8022 19.2094 36.7606 18.6656 36.3647 18.3375C35.9377 17.9906 35.2814 18.0094 34.896 18.3937L34.396 18.8906V22.125C35.521 20.7656 36.4793 19.6125 36.5002 19.6031L36.4897 19.5937Z"
        fill="#0050C7"
      />
      <path
        d="M30.7811 26.4562C30.2498 27.1031 29.1457 27.1312 28.5728 26.5219L24.7394 22.4719C23.9165 21.6094 25.2603 20.4094 26.2082 21.15L29.4998 23.6812L34.3748 18.8906V15.6469C33.1665 15.0281 31.7707 14.6812 30.2811 14.6812C25.6769 14.6812 21.9478 18.0375 21.9478 22.1812C21.9478 26.325 25.6769 29.6812 30.2811 29.6812C31.7707 29.6812 33.1665 29.325 34.3748 28.7156V22.1344C32.7498 24.0844 30.7811 26.4469 30.7707 26.475L30.7811 26.4562Z"
        fill="#DDFFF1"
      />
      <path
        d="M29.5107 23.6719L26.219 21.1406C25.2711 20.4 23.9169 21.6 24.7502 22.4625L28.5836 26.5125C29.1565 27.1312 30.2607 27.1031 30.7919 26.4469C30.8127 26.4281 32.7815 24.0562 34.3961 22.1062V18.8719L29.5211 23.6625L29.5107 23.6719Z"
        fill="#0866F5"
      />
    </svg>
  ),
  'auto-written-phrase-2': (
    <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M43.4998 2.54187H17.5998C16.4676 2.54187 15.5498 3.47881 15.5498 4.63458V43.3242C15.5498 44.4799 16.4676 45.4169 17.5998 45.4169H43.4998C44.632 45.4169 45.5498 44.4799 45.5498 43.3242V4.63458C45.5498 3.47881 44.632 2.54187 43.4998 2.54187Z"
        fill="#0866F5"
      />
      <path
        d="M32.7653 6.76656H6.64223C5.78406 6.76656 5.08838 7.47673 5.08838 8.35277V24.0265C5.08838 24.9025 5.78406 25.6127 6.64223 25.6127H32.7653C33.6235 25.6127 34.3191 24.9025 34.3191 24.0265V8.35277C34.3191 7.47673 33.6235 6.76656 32.7653 6.76656Z"
        fill="#006F4A"
      />
      <path
        d="M31.2267 5.19604H5.10365C4.24549 5.19604 3.5498 5.90622 3.5498 6.78226V22.456C3.5498 23.332 4.24549 24.0422 5.10365 24.0422H31.2267C32.0849 24.0422 32.7806 23.332 32.7806 22.456V6.78226C32.7806 5.90622 32.0849 5.19604 31.2267 5.19604Z"
        fill="#5DDFA9"
      />
      <path
        d="M12.7808 5.19604H31.2038C32.0731 5.19604 32.7808 5.91848 32.7808 6.80582V22.4403C32.7808 23.3276 32.0731 24.05 31.2038 24.05H12.7808V5.19604Z"
        fill="#37B982"
      />
      <path
        d="M6.60376 8.43915H12.7807V11.5802H6.60376C5.7653 11.5802 5.08838 10.8891 5.08838 10.0332V9.9861C5.08838 9.13017 5.7653 8.43915 6.60376 8.43915Z"
        fill="#DDFFF1"
      />
      <path
        d="M12.7808 8.43915H29.05C29.8961 8.43915 30.5885 9.14588 30.5885 10.0097C30.5885 10.8734 29.8961 11.5802 29.05 11.5802H12.7808V8.43915Z"
        fill="#83F8C7"
      />
      <path
        d="M6.60376 14.7209H12.7807V17.8619H6.60376C5.7653 17.8619 5.08838 17.1709 5.08838 16.315V16.2678C5.08838 15.4119 5.7653 14.7209 6.60376 14.7209Z"
        fill="#DDFFF1"
      />
      <path
        d="M12.7808 14.7212H25.2731C26.0192 14.7212 26.6269 15.3416 26.6269 16.1033V16.4723C26.6269 17.234 26.0192 17.8544 25.2731 17.8544H12.7808V14.7133V14.7212Z"
        fill="#83F8C7"
      />
      <path
        d="M41.73 33.6875H19.73C19.1777 33.6875 18.73 34.1445 18.73 34.7083C18.73 35.2721 19.1777 35.7292 19.73 35.7292H41.73C42.2823 35.7292 42.73 35.2721 42.73 34.7083C42.73 34.1445 42.2823 33.6875 41.73 33.6875Z"
        fill="white"
      />
      <path
        d="M41.73 28.4812H19.73C19.1777 28.4812 18.73 28.9383 18.73 29.5021C18.73 30.0659 19.1777 30.5229 19.73 30.5229H41.73C42.2823 30.5229 42.73 30.0659 42.73 29.5021C42.73 28.9383 42.2823 28.4812 41.73 28.4812Z"
        fill="white"
      />
      <path
        d="M35.86 38.7917H19.6C19.1195 38.7917 18.73 39.1893 18.73 39.6798V39.9452C18.73 40.4357 19.1195 40.8334 19.6 40.8334H35.86C36.3405 40.8334 36.73 40.4357 36.73 39.9452V39.6798C36.73 39.1893 36.3405 38.7917 35.86 38.7917Z"
        fill="white"
      />
    </svg>
  ),
  export: (
    <svg width="51" height="48" viewBox="0 0 51 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M37.209 8.52H11.709C10.5354 8.52 9.58398 9.41543 9.58398 10.52V42.52C9.58398 43.6246 10.5354 44.52 11.709 44.52H37.209C38.3826 44.52 39.334 43.6246 39.334 42.52V10.52C39.334 9.41543 38.3826 8.52 37.209 8.52Z"
        fill="#37B982"
      />
      <path
        d="M34.0215 5.52H8.52148C7.34788 5.52 6.39648 6.41543 6.39648 7.52V39.52C6.39648 40.6246 7.34788 41.52 8.52148 41.52H34.0215C35.1951 41.52 36.1465 40.6246 36.1465 39.52V7.52C36.1465 6.41543 35.1951 5.52 34.0215 5.52Z"
        fill="#5DDFA9"
      />
      <path
        d="M14.8965 1.52H4.27148C3.09788 1.52 2.14648 2.41543 2.14648 3.52V31.52C2.14648 32.6246 3.09788 33.52 4.27148 33.52H14.8965C16.0701 33.52 17.0215 32.6246 17.0215 31.52V3.52C17.0215 2.41543 16.0701 1.52 14.8965 1.52Z"
        fill="#0866F5"
      />
      <path
        d="M9.58398 12.49C11.9312 12.49 13.834 10.6991 13.834 8.49001C13.834 6.28087 11.9312 4.49001 9.58398 4.49001C7.23677 4.49001 5.33398 6.28087 5.33398 8.49001C5.33398 10.6991 7.23677 12.49 9.58398 12.49Z"
        fill="#F9F8FF"
      />
      <path
        d="M9.57341 8.51001C10.349 8.51001 10.9759 7.92001 10.9759 7.19001C10.9759 6.46001 10.349 5.87001 9.57341 5.87001C8.79779 5.87001 8.17091 6.46001 8.17091 7.19001C8.17091 7.92001 8.79779 8.51001 9.57341 8.51001ZM11.8684 10.63L11.5284 9.79001C11.284 9.20001 10.689 8.81001 10.009 8.81001H9.14841C8.47904 8.81001 7.87341 9.20001 7.62904 9.79001L7.28904 10.63C7.19341 10.87 7.37404 11.12 7.65029 11.12H11.5072C11.7728 11.12 11.964 10.87 11.8684 10.63Z"
        fill="#0866F5"
      />
      <path
        d="M31.4292 8.81H20.8042C20.2174 8.81 19.7417 9.25771 19.7417 9.81C19.7417 10.3623 20.2174 10.81 20.8042 10.81H31.4292C32.016 10.81 32.4917 10.3623 32.4917 9.81C32.4917 9.25771 32.016 8.81 31.4292 8.81Z"
        fill="white"
      />
      <path
        d="M13.1325 14.81H6.02441C5.43761 14.81 4.96191 15.2577 4.96191 15.81C4.96191 16.3623 5.43761 16.81 6.02441 16.81H13.1325C13.7193 16.81 14.195 16.3623 14.195 15.81C14.195 15.2577 13.7193 14.81 13.1325 14.81Z"
        fill="white"
      />
      <path
        d="M13.1325 18.81H6.02441C5.43761 18.81 4.96191 19.2577 4.96191 19.81C4.96191 20.3623 5.43761 20.81 6.02441 20.81H13.1325C13.7193 20.81 14.195 20.3623 14.195 19.81C14.195 19.2577 13.7193 18.81 13.1325 18.81Z"
        fill="white"
      />
      <path
        d="M13.1325 22.81H6.02441C5.43761 22.81 4.96191 23.2577 4.96191 23.81C4.96191 24.3623 5.43761 24.81 6.02441 24.81H13.1325C13.7193 24.81 14.195 24.3623 14.195 23.81C14.195 23.2577 13.7193 22.81 13.1325 22.81Z"
        fill="white"
      />
      <path
        d="M27.1792 12.81H20.8042C20.2174 12.81 19.7417 13.2577 19.7417 13.81C19.7417 14.3623 20.2174 14.81 20.8042 14.81H27.1792C27.766 14.81 28.2417 14.3623 28.2417 13.81C28.2417 13.2577 27.766 12.81 27.1792 12.81Z"
        fill="white"
      />
      <path
        d="M27.1792 20.81H20.8042C20.2174 20.81 19.7417 21.2577 19.7417 21.81C19.7417 22.3623 20.2174 22.81 20.8042 22.81H27.1792C27.766 22.81 28.2417 22.3623 28.2417 21.81C28.2417 21.2577 27.766 20.81 27.1792 20.81Z"
        fill="white"
      />
      <path
        d="M29.3042 16.81H20.8042C20.2174 16.81 19.7417 17.2577 19.7417 17.81C19.7417 18.3623 20.2174 18.81 20.8042 18.81H29.3042C29.891 18.81 30.3667 18.3623 30.3667 17.81C30.3667 17.2577 29.891 16.81 29.3042 16.81Z"
        fill="white"
      />
      <path
        d="M44.8182 26H27.4318C26.3649 26 25.5 26.814 25.5 27.8182V44.1818C25.5 45.186 26.3649 46 27.4318 46H44.8182C45.8851 46 46.75 45.186 46.75 44.1818V27.8182C46.75 26.814 45.8851 26 44.8182 26Z"
        fill="#0866F5"
      />
      <path
        d="M32.9375 26H41.3699C42.4324 26 43.3017 26.8182 43.3017 27.8182V44.1818C43.3017 45.1818 42.4324 46 41.3699 46H32.9375V26Z"
        fill="#0866F5"
      />
      <path
        d="M37.4067 29.5409V31.4313C32.7844 32.2665 28.9366 37.1407 31.7922 41.2405C32.607 42.2731 33.7766 42.7969 34.8818 42.9943C35.027 43.0171 35.3416 42.9791 35.4142 42.7362C35.5029 42.4097 35.2609 42.2655 35.148 42.1971C31.9778 40.3294 34.1719 36.4954 37.4067 36.9737V38.9325C37.4067 38.9856 37.4147 39.0312 37.4309 39.0767V29.3966C37.4147 29.4422 37.4067 29.4877 37.4067 29.5409Z"
        fill="#EFF5FF"
      />
      <path
        d="M38.3825 39.3197L43.392 34.7264C43.6824 34.4606 43.6824 34.0203 43.392 33.747L38.3825 29.1537C38.0599 28.8576 37.5436 29.017 37.4307 29.3966V39.0767C37.5436 39.4563 38.0599 39.6158 38.3825 39.3197Z"
        fill="white"
      />
    </svg>
  ),
};

export const getCardIcons = (logoName = 'resume-builder') => LOGOS[logoName];
