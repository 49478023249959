import LeftArrowV2 from 'imports/generator/ui/assets/LeftArrowV2';
import RightArrowV2 from 'imports/generator/ui/assets/RightArrowV2';

import LeftArrow from '/imports/generator/ui/assets/LeftArrow';
import RightArrow from '/imports/generator/ui/assets/RightArrow';

const Arrow = () => null;

Arrow.Left = LeftArrow;
Arrow.Right = RightArrow;
Arrow.RightV2 = RightArrowV2;
Arrow.LeftV2 = LeftArrowV2;

export default Arrow;
