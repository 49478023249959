import React from 'react';
import styled from 'styled-components';

const PencilIcon = ({ width = 16, height = 16 }) => {
  return (
    <SvgIcon width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        d="M16.437 2c.655 0 1.283.261 1.741.723l3.101 3.101a2.462 2.462 0 010 3.482L9.958 20.624c-.699.805-1.69 1.3-2.823 1.378H2v-1l.003-4.215c.085-1.054.576-2.035 1.323-2.694l11.37-11.368A2.456 2.456 0 0116.436 2zm-4.135 5.945L4.696 15.55c-.405.358-.656.86-.696 1.318v3.135l3.064.002c.534-.038 1.031-.287 1.43-.743l7.562-7.563-3.754-3.754zM16.437 4a.456.456 0 00-.323.135L13.716 6.53l3.754 3.754 2.395-2.393a.462.462 0 000-.653L16.76 4.135A.456.456 0 0016.437 4z"
      />
    </SvgIcon>
  );
};

const SvgIcon = styled.svg`
  stroke: none;
`;

export default PencilIcon;
