import React from 'react';

const NoteTextIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path
      fill="#00B384"
      fillRule="evenodd"
      d="M20 2a2 2 0 012 2v13.414L17.414 22H4a2 2 0 01-2-2V4a2 2 0 012-2h16zm0 2H4v16l11-.001V17a2 2 0 012-2h3V4zm-.415 13H17v2.584L19.585 17zM13 15v2H7v-2h6zm4-4v2H7v-2h10zm0-4v2H7V7h10z"
    />
  </svg>
);

export default NoteTextIcon;
