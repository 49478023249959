import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Text from '/imports/pdf/budapest/ui/atoms/Text';
import View from '/imports/pdf/core/ui/atoms/View';
import EditRedirectionWrapper from 'imports/pdf/core/ui/components/EditRedirectionWrapper';
import { getClickToEditProps } from 'imports/pdf/core/api/helpers';

const ReferenceDisplay = ({ title, email, phone, company, left, itemId, fieldsName }) => (
  <ReferenceWrapper>
    <EditRedirectionWrapper {...getClickToEditProps(itemId, { title }, fieldsName)}>
      <Title light={left}>{title}</Title>
    </EditRedirectionWrapper>

    {company && (
      <EditRedirectionWrapper {...getClickToEditProps(itemId, { company }, fieldsName)}>
        <Text light={left}>{company}</Text>
      </EditRedirectionWrapper>
    )}
    <LinksCont>
      {phone && (
        <EditRedirectionWrapper {...getClickToEditProps(itemId, { phone }, fieldsName)}>
          <RefLink light={left}>
            <StyledText light={left}>
              <StyledSpan>T: </StyledSpan>
              {phone}
            </StyledText>
          </RefLink>
        </EditRedirectionWrapper>
      )}
      {email && (
        <EditRedirectionWrapper {...getClickToEditProps(itemId, { email }, fieldsName)}>
          <RefLink light={left}>
            <StyledText light={left}>
              <StyledSpan>E: </StyledSpan>
              {email}
            </StyledText>
          </RefLink>
        </EditRedirectionWrapper>
      )}
    </LinksCont>
  </ReferenceWrapper>
);

ReferenceDisplay.propTypes = {
  title: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  company: PropTypes.string,
  left: PropTypes.bool,
};

const StyledSpan = styled.span`
  white-space: nowrap;
  margin-right: ${(theme) => (theme.isRTL ? '0' : '2px')};
  margin-left: ${(theme) => (theme.isRTL ? '2px' : '0')};
`;

const StyledText = styled(Text)`
  display: flex;
`;

const ReferenceWrapper = styled(View)`
  page-break-inside: avoid !important;
  break-inside: avoid !important;
`;

const LinksCont = styled(View)`
  margin-top: ${({ theme }) => theme.convertPx(4)};
`;

const Title = styled(Text)`
  font-size: ${({ theme }) => theme.convertPx(13.5, true)};
  text-transform: uppercase;
`;

const RefLink = styled(Text)`
  span {
    font-weight: 600;
  }
`;

export default ReferenceDisplay;
