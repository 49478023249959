import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import SkillLevel from './SkillLevel';
import { withIntl } from 'imports/core/api/useIntl';

import Autosave from '/imports/core/ui/components/Autosave';
import { withGeneralContext } from '/imports/core/api/generalContext';

@withIntl
@withGeneralContext
class SkillLevelAutosave extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    value: PropTypes.string,
    designV2: PropTypes.bool,
    language: PropTypes.string,
  };
  render() {
    const { className, value, designV2, language } = this.props;
    return (
      <Autosave {...this.props}>
        {({ onChange }) => (
          <SkillLevel
            language={language}
            className={className}
            onChange={onChange}
            value={value || ''}
            designV2={designV2}
          />
        )}
      </Autosave>
    );
  }
}

export default SkillLevelAutosave;
