import React from 'react';

const PdfIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_8006_4063)">
        <path
          d="M3.32617 20.0234V21.393C3.32617 21.645 3.53024 21.8495 3.78269 21.8495H20.2175C20.4699 21.8495 20.674 21.645 20.674 21.393V20.0234H3.32617Z"
          fill="#1688FE"
        />
        <path
          d="M20.674 9.98004V6.78439H15.1957C14.9433 6.78439 14.7392 6.57987 14.7392 6.32787V0.849609H3.78269C3.53024 0.849609 3.32617 1.05413 3.32617 1.30613V9.98004H20.674Z"
          fill="#1688FE"
        />
        <path d="M15.6523 1.57422V5.87191H19.95L15.6523 1.57422Z" fill="#1688FE" />
        <path
          d="M8.82439 14.0254H8.57422V14.8494H8.75089C8.89652 14.8494 9.01202 14.8083 9.09785 14.7262C9.18368 14.6444 9.22659 14.5317 9.22659 14.3883C9.22613 14.1464 9.09237 14.0254 8.82439 14.0254Z"
          fill="#1688FE"
        />
        <path
          d="M12.0282 14.0254H11.7461V16.0332H11.962C12.2565 16.0332 12.472 15.9473 12.6094 15.7766C12.7468 15.6059 12.8153 15.3447 12.8153 14.9932C12.8153 14.6645 12.7504 14.4212 12.6203 14.2623C12.4902 14.1044 12.293 14.0254 12.0282 14.0254Z"
          fill="#1688FE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.521739 10.8926H23.4783C23.7663 10.8926 24 11.0971 24 11.3491V18.6534C24 18.9054 23.7663 19.11 23.4783 19.11H0.521739C0.233739 19.11 0 18.9054 0 18.6534V11.3491C0 11.0971 0.233739 10.8926 0.521739 10.8926Z"
          fill="#1688FE"
        />
        <path
          d="M22.5009 16.3148H21.7508L21.3383 15.6172L20.9257 16.3148H20.1719L20.9632 14.9759L20.2019 13.6895H20.9557L21.3383 14.3383L21.7208 13.6895H22.4747L21.7133 14.9796L22.5009 16.3148Z"
          fill="white"
        />
        <path
          d="M18.9998 16.3706C18.6047 16.3706 18.2772 16.2406 18.0172 15.9806C17.7596 15.7181 17.6309 15.3918 17.6309 15.0017C17.6309 14.6117 17.7596 14.2866 18.0172 14.0266C18.2772 13.7641 18.6047 13.6328 18.9998 13.6328C19.2373 13.6328 19.4573 13.6891 19.6599 13.8016C19.8624 13.9141 20.0211 14.0654 20.1362 14.2554L19.5548 14.5892C19.5048 14.4967 19.4298 14.4242 19.3298 14.3717C19.2323 14.3166 19.1223 14.2891 18.9998 14.2891C18.7898 14.2891 18.6197 14.3554 18.4897 14.4879C18.3622 14.6179 18.2984 14.7892 18.2984 15.0017C18.2984 15.2143 18.3622 15.3868 18.4897 15.5193C18.6197 15.6493 18.7898 15.7143 18.9998 15.7143C19.1223 15.7143 19.2323 15.6881 19.3298 15.6356C19.4298 15.5805 19.5048 15.5055 19.5548 15.4105L20.1362 15.7481C20.0211 15.9406 19.8624 16.0931 19.6599 16.2056C19.4598 16.3156 19.2398 16.3706 18.9998 16.3706Z"
          fill="white"
        />
        <path
          d="M17.0195 15.9768C16.7545 16.2394 16.4294 16.3706 16.0444 16.3706C15.6593 16.3706 15.3331 16.2394 15.0655 15.9768C14.8005 15.7118 14.668 15.3868 14.668 15.0017C14.668 14.6167 14.8005 14.2929 15.0655 14.0304C15.3331 13.7653 15.6593 13.6328 16.0444 13.6328C16.4294 13.6328 16.7545 13.7653 17.0195 14.0304C17.287 14.2929 17.4208 14.6167 17.4208 15.0017C17.4208 15.3868 17.287 15.7118 17.0195 15.9768ZM15.5381 15.5155C15.6731 15.6481 15.8419 15.7143 16.0444 15.7143C16.2469 15.7143 16.4157 15.6481 16.5507 15.5155C16.6857 15.3805 16.7532 15.2093 16.7532 15.0017C16.7532 14.7942 16.6857 14.6242 16.5507 14.4917C16.4157 14.3566 16.2469 14.2891 16.0444 14.2891C15.8419 14.2891 15.6731 14.3566 15.5381 14.4917C15.4031 14.6242 15.3356 14.7942 15.3356 15.0017C15.3356 15.2093 15.4031 15.3805 15.5381 15.5155Z"
          fill="white"
        />
        <path
          d="M13.1822 13.6895C13.5447 13.6895 13.8472 13.8157 14.0898 14.0682C14.3348 14.3183 14.4573 14.6296 14.4573 15.0021C14.4573 15.3747 14.3348 15.6872 14.0898 15.9397C13.8472 16.1898 13.5447 16.3148 13.1822 16.3148H12.1133V13.6895H13.1822ZM13.1822 15.6659C13.3647 15.6659 13.5135 15.6059 13.6285 15.4859C13.7435 15.3634 13.801 15.2021 13.801 15.0021C13.801 14.8021 13.7435 14.6421 13.6285 14.5221C13.5135 14.3995 13.3647 14.3383 13.1822 14.3383H12.8034V15.6659H13.1822Z"
          fill="white"
        />
        <path
          d="M10.906 14.7055V15.2981H10.3059V15.8982H9.71335V15.2981H9.11328V14.7055H9.71335V14.1055H10.3059V14.7055H10.906Z"
          fill="white"
        />
        <path
          d="M7.9877 14.3383H7.04633V14.7658H7.96894V15.4147H7.04633V16.3148H6.375V13.6895H7.9877V14.3383Z"
          fill="white"
        />
        <path
          d="M4.78763 13.6895C5.15017 13.6895 5.45271 13.8157 5.69524 14.0682C5.94027 14.3183 6.06278 14.6296 6.06278 15.0021C6.06278 15.3747 5.94027 15.6872 5.69524 15.9397C5.45271 16.1898 5.15017 16.3148 4.78763 16.3148H3.71875V13.6895H4.78763ZM4.78763 15.6659C4.97015 15.6659 5.11892 15.6059 5.23393 15.4859C5.34895 15.3634 5.40645 15.2021 5.40645 15.0021C5.40645 14.8021 5.34895 14.6421 5.23393 14.5221C5.11892 14.3995 4.97015 14.3383 4.78763 14.3383H4.40883V15.6659H4.78763Z"
          fill="white"
        />
        <path
          d="M2.52012 13.6895C2.78516 13.6895 3.00768 13.7757 3.1877 13.9482C3.36773 14.1208 3.45774 14.3408 3.45774 14.6083C3.45774 14.8758 3.36773 15.0959 3.1877 15.2684C3.00768 15.4409 2.78516 15.5272 2.52012 15.5272H2.17133V16.3148H1.5V13.6895H2.52012ZM2.52012 14.8896C2.59513 14.8896 2.65764 14.8633 2.70765 14.8108C2.76015 14.7583 2.78641 14.6908 2.78641 14.6083C2.78641 14.5258 2.76015 14.4595 2.70765 14.4095C2.65764 14.357 2.59513 14.3308 2.52012 14.3308H2.17133V14.8896H2.52012Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_8006_4063">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PdfIcon;
