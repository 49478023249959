import React from 'react';
import styled from 'styled-components';

const EditIcon = () => {
  return (
    <SvgIcon width="16" height="17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)" fill="#BFC5D6">
        <path d="M13.956 16.662H1.675A1.675 1.675 0 010 14.988V2.707a1.675 1.675 0 011.675-1.675H9.49a.558.558 0 110 1.116H1.675a.558.558 0 00-.559.559v12.28a.558.558 0 00.559.559h12.28a.558.558 0 00.559-.558V7.172a.558.558 0 111.116 0v7.816a1.674 1.674 0 01-1.674 1.674z" />
        <path d="M4.466 12.755a.556.556 0 01-.397-.162.558.558 0 01-.161-.508l.614-2.981a.558.558 0 01.15-.285l7.514-7.502a2.234 2.234 0 113.16 3.16l-7.503 7.502a.585.585 0 01-.285.15l-2.98.626h-.112zm1.116-3.266l-.407 1.987 1.998-.396 7.38-7.39a1.117 1.117 0 00-1.58-1.58l-7.39 7.379z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" transform="translate(0 .662)" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

const SvgIcon = styled.svg`
  stroke: none;
`;

export default EditIcon;
