import ImageWrapper from '../atoms/ImageWrapper';
import { getArticlesImages } from 'imports/help/api/articlesImages';
import { StyledText } from '../atoms/Typography';
import { useResponsive } from 'imports/core/api/responsiveContext';

import ArticlePageWrapper from '/imports/help/ui/components/ArticlePageWrapper';
import useIntl from '/imports/core/api/useIntl';

const ChangeResumeLanguage = () => {
  const { t } = useIntl();
  const { host } = useResponsive();
  const images = getArticlesImages(host);
  return (
    <ArticlePageWrapper title="help.change_resume_language">
      <StyledText>{t('help.change_resume_language.change_resume_language')}</StyledText>
      <StyledText>{t('help.change_resume_language.category_translation')}</StyledText>
      <ImageWrapper src={images.change_language} />
    </ArticlePageWrapper>
  );
};

export default ChangeResumeLanguage;
