import React from 'react';
import styled from 'styled-components';

const SimpleArrow = () => {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" width="23" height="45" viewBox="0 0 23 45">
      <g fill="none" fillRule="evenodd">
        <g fill="#FFF" fillRule="nonzero">
          <g>
            <g>
              <path
                d="M22.519 22.262c-.039-.552-.255-1.074-.614-1.484L4.655.998C4.082.341 3.214.048 2.376.232 1.538.415.86 1.045.594 1.886c-.265.84-.076 1.762.497 2.419l15.812 18.127L1.091 40.558c-.573.657-.762 1.58-.497 2.42.265.84.944 1.47 1.782 1.654.838.183 1.706-.11 2.279-.767l17.25-19.78c.435-.497.657-1.155.614-1.823z"
                transform="translate(-1408 -358) translate(1408 358)"
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

const SvgIcon = styled.svg`
  stroke: none;
  transform: translateX(20%);
  width: 23px;
  height: 45px;
`;

export default SimpleArrow;
