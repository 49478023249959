import LanguagesIconV3 from './LanguagesIconV3';
import IconsExperiment from './IconsExperiment';

const LanguagesIconExperiment = (props) => {
  return <IconsExperiment iconV3={<LanguagesIconV3 {...props} />} />;
};

LanguagesIconExperiment.displayName = 'LanguagesIconExperiment';

export default LanguagesIconExperiment;
