import React, { useContext, createContext, useReducer } from 'react';
import PropTypes from 'prop-types';

import Reducer from '/imports/generator/api/reducer/blockresposition.reducer';

const BlockReposition = createContext();
export const Provider = BlockReposition.Provider;
export const BlockConsumer = BlockReposition.Consumer;

const initialState = {
  isOpen: false,
  isClosed: false,
  isTooltipClose: false,
  isTooltipOpen: false,
  toggle: {
    active: true,
    deActive: false,
  },
  prevtoggleState: 'active',
  isSummaryLoading: false,
  isTriggerAPI: false,
  isDataSelected: false,
  isSummaryData: null,
};

const BlockRespositionProvider = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, { ...initialState });
  const handleUpdateState = (name, payload) => {
    dispatch({
      type: 'UPDATE_STATE',
      name,
      payload,
    });
  };
  return (
    <Provider
      value={{
        state: state,
        dispatch: dispatch,
        handleUpdateState,
      }}
    >
      {children}
    </Provider>
  );
};

export const withBlock = () => useContext(BlockReposition);

export const withBlockResposition = (WrappedComponent) => {
  return class extends React.Component {
    render() {
      return (
        <BlockReposition.Consumer>
          {(context) => <WrappedComponent {...this.props} context={context} />}
        </BlockReposition.Consumer>
      );
    }
  };
};

export default BlockRespositionProvider;

BlockRespositionProvider.propTypes = { children: PropTypes.node };
