import React from 'react';
import styled from 'styled-components';

const UserIcon = ({ width = 20, height = 22 }) => {
  return (
    <SvgIcon width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g stroke="#BFC5D6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none" fillRule="evenodd">
        <circle cx="12" cy="6" r="5" />
        <path d="M2 23v-4a4 4 0 014-4h12a4 4 0 014 4v4" />
      </g>
    </SvgIcon>
  );
};

const SvgIcon = styled.svg`
  stroke: none;
`;

export default UserIcon;
