import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';

import Box from '/imports/core/ui/atoms/Box';
import Flex from '/imports/core/ui/atoms/Flex';
import LocaleSelect from '/imports/core/ui/components/LocaleSelect';
import LogoAtom from '/imports/core/ui/components/Logo';

class OnboardingHeader extends PureComponent {
  render() {
    return (
      <HeaderContainer>
        <LogoWrapper>
          <Logo noLink />
        </LogoWrapper>
        <LocaleWrapper>
          <LocaleSelect />
        </LocaleWrapper>
      </HeaderContainer>
    );
  }
}

OnboardingHeader.displayName = 'OnboardingHeader';

const Logo = styled(LogoAtom)`
  ${(p) => p.theme.max('sm')`
    font-size: 13px;
  `}
`;

const HeaderContainer = styled((p) => <Flex {...p} justifyContent="space-between" alignItems={'center'} />)`
  position: fixed;
  padding-left: 24px;
  height: 75px;
  background-color: transparent;
  z-index: 1;

  & {
    width: 100%;
  }
  ${({ theme }) => theme.max('md')`
    height: 64px;
  `}

  ${({ theme }) => theme.max('sm')`
    background-color: ${theme.colors.gray.light2};
    position: relative;
    height: 64px;
  `}

  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      flex-direction: row-reverse;
      padding-right: 24px;
    `}
`;

const LogoWrapper = styled(Box)`
  margin-top: 12px;
`;

const LocaleWrapper = styled.div`
  margin-right: 32px;
`;

export default OnboardingHeader;
