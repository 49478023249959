import styled from 'styled-components';

const HobbyIcon = ({ width = '16px', height = '22px', viewBox = '0 0 16 22' }) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
    <path d="M16 0v11.994c.009 1.522-.84 2.004-3.209 2.714-1.388.416-1.791.644-1.791.792 0 .768.07 1.283.22 1.882.024.091.148.574.18.721.066.31.1.591.1.897 0 1.66-1.562 3-3.5 3s-3.5-1.34-3.5-3c0-.306.034-.588.1-.897.038-.176.209-.836.18-.72.15-.6.22-1.115.22-1.883 0-.149-.402-.377-1.787-.792C.848 13.998 0 13.518 0 12V0h16zm-2 10H2v2c0 .149.402.377 1.787.792C6.152 13.502 7 13.982 7 15.5c0 .94-.092 1.617-.28 2.368.018-.072-.137.526-.164.654A2.19 2.19 0 006.5 19c0 .482.604 1 1.5 1s1.5-.518 1.5-1a2.19 2.19 0 00-.056-.478 31.272 31.272 0 00-.164-.654A9.093 9.093 0 019 15.5c0-1.517.85-1.998 3.213-2.707h.004C13.6 12.377 14.001 12.15 14 12v-2zM4 2H2v6h12V2h-4v3H8V2H6v4H4V2z" />
  </SvgIcon>
);

const SvgIcon = styled.svg`
  stroke: none;
  fill: currentColor;
`;

HobbyIcon.displayName = 'HobbyIcon';

export default HobbyIcon;
