import { css } from 'styled-components';
import { darken } from 'polished';

export const inputStyle = css`
  position: relative;
  display: block;
  border: 0;
  background-color: ${(p) => p.theme.colors.gray.lighter};
  padding: 13px 16px;
  line-height: 24px;
  width: 100%;
  overflow: hidden;
  font-size: 19px;
  border-radius: ${(p) => p.theme.general.borderRadius};
  color: ${(p) => p.theme.colors.black};
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: ${(p) => p.theme.colors.gray.regular};
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: ${(p) => p.theme.colors.gray.regular};
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: ${(p) => p.theme.colors.gray.regular};
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: ${(p) => p.theme.colors.gray.regular};
  }
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
`;

// We need this mixin to override few css from above inputStyle mixin
export const overrideInputStyleMixin = css`
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #50575d;
  border-radius: 3px;
  box-shadow: 0 3px 4px -3px rgba(15, 15, 15, 0.12);
  border: solid 1px #e3e9ee;
  background-color: var(--light-values-white);
`;

export const overrideV2InputStyleMixin = css`
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 15px;
  line-height: 1.47;
  color: var(--text);
`;

export const linkStyle = css`
  color: ${(p) => p.theme.colors.primary};
  font-weight: 300;

  &:hover {
    color: ${(p) => darken(0.2, p.theme.colors.primary)};
    text-decoration: none;
  }
`;

export const rangeInputStyles = css`
  input[type='range'] {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    width: 300px;
  }
  input[type='range']:focus {
    outline: none;
  }

  input[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    box-shadow: none;
    background: #e6e6ff;
    border-radius: 1px;
    border: none;
  }

  input[type='range']::-webkit-slider-thumb {
    box-shadow:
      0 3px 8px -5px rgba(15, 15, 15, 0.05),
      0 7px 8px -5px rgba(15, 15, 15, 0.1);
    border: solid 2px #e6e6ff;
    width: 14px;
    height: 14px;
    border-radius: 2px;
    background: var(--light-values-white);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -5.5px;
  }

  input[type='range']::-moz-range-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    box-shadow: none;
    background: #e6e6ff;
    border-radius: 1px;
    border: none;
  }
  input[type='range']::-moz-range-thumb {
    box-shadow:
      0 3px 8px -5px rgba(15, 15, 15, 0.05),
      0 7px 8px -5px rgba(15, 15, 15, 0.1);
    border: solid 2px #e6e6ff;
    width: 14px;
    height: 14px;
    border-radius: 2px;
    background: var(--light-values-white);
    cursor: pointer;
  }
  input[type='range']::-ms-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
  }
  input[type='range']::-ms-fill-lower {
    background: #2a6495;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow:
      1px 1px 1px var(--black),
      0px 0px 1px #0d0d0d;
  }
  input[type='range']::-ms-fill-upper {
    background: #3071a9;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow:
      1px 1px 1px var(--black),
      0px 0px 1px #0d0d0d;
  }
  input[type='range']::-ms-thumb {
    box-shadow:
      1px 1px 1px var(--black),
      0px 0px 1px #0d0d0d;
    border: 1px solid var(--black);
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: var(--light-values-white);
    cursor: pointer;
  }
`;
