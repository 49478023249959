import React, { Fragment } from 'react';
import styled from 'styled-components';

import BlockItem from '/imports/pdf/budapest/ui/atoms/BlockItem';
import Heading from '/imports/pdf/budapest/ui/atoms/Heading';
import useIntl from '/imports/core/api/useIntl';

const LeftPlaceHolder = ({ language }) => {
  const { t } = useIntl();
  return (
    <Fragment>
      <StyledBlockItem summary={1} left>
        <Heading light>{t('about_me', language)}</Heading>
      </StyledBlockItem>
      <StyledBlockItem left>
        <Heading light>{t('websites_social_links', language)}</Heading>
      </StyledBlockItem>
      <StyledBlockItem left>
        <Heading light>{t('languages', language)}</Heading>
      </StyledBlockItem>
    </Fragment>
  );
};

const StyledBlockItem = styled(BlockItem)`
  margin-bottom: 0;
  border-bottom: none;
  padding: ${({ theme }) => theme.getPaddingMargin({ top: 26, bottom: 34, left: 36, right: 36 })};
  svg {
    margin-bottom: ${({ theme }) => theme.convertPx(56)};
    max-width: 100%;
  }
  &:after {
    background-color: transparent;
  }
`;

export default LeftPlaceHolder;
