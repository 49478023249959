import React, { PureComponent } from 'react';

import TextareaEditorAutosave from '/imports/core/ui/atoms/TextareaEditorAutosave';

class TextareaEditorWithData extends PureComponent {
  state = {
    jobChecked: false,
  };

  /*componentDidUpdate(prevProps, prevState) {
    // TODO: uncomment this when we have optimized query for suggestion service
    // const needUpdateForCustomValue =
    //   this.props.needUpdate && !prevProps.getSuggestions.loading && !this.props.getSuggestions.loading;
    if (!this.state.jobChecked) {
      if (
        prevProps.getSuggestions.loading !== this.props.getSuggestions.loading &&
        !this.props.getSuggestions.loading
      ) {
        const { getSuggestions } = this.props.getSuggestions;
        if (getSuggestions && getSuggestions.length === 0) {
          this.props.onDataSelect('');
        }
        this.setState({ jobChecked: true });
      }
    }
  }*/

  render() {
    // const areas = (getJobs && getJobs.map(job => job.title)) || [];
    // const help = (getSuggestions && getSuggestions.map(({ _id: id, text }) => ({ id, text }))) || [];
    return <TextareaEditorAutosave areas={[]} help={[]} {...this.props} hideSearch />;
  }
}

TextareaEditorWithData.displayName = 'TextareaEditorWithData';

export default TextareaEditorWithData;
