import React from 'react';
import styled from 'styled-components';

const LeftArrow = () => {
  return (
    <IconCont>
      <SvgIcon width="16" height="11" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M.218 4.95L4.979.218a.747.747 0 011.052 0c.29.289.29.757 0 1.046l-3.49 3.471h12.715c.41 0 .744.331.744.74 0 .408-.333.74-.744.74H2.54l3.491 3.47c.29.29.29.758 0 1.047a.744.744 0 01-1.052 0L.218 5.997a.737.737 0 010-1.046z"
          fillRule="nonzero"
        />
      </SvgIcon>
    </IconCont>
  );
};

const IconCont = styled.span`
  padding-left: 2px;
  padding-right: 10px;
`;

const SvgIcon = styled.svg`
  stroke: none;
  fill: currentColor;
`;

export default LeftArrow;
