import React from 'react';
import styled from 'styled-components';

import Heading from '/imports/core/ui/atoms/Heading';

const BlockTitle = styled((p) => <Heading as="h3" {...p} />)`
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  color: #262b33;
  text-transform: capitalize;
  margin-bottom: 10px;
`;

BlockTitle.displayName = 'BlockTitle';

export default BlockTitle;
