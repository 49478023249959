import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '/imports/core/ui/atoms/Button';
import Modal from '/imports/core/ui/atoms/Modal';
import { withIntl } from '/imports/core/api/useIntl';

const modalStyle = {
  modalBackdrop: {
    backgroundColor: 'rgba(40, 43, 50, 0.1)',
  },
};

@withIntl
class ControlsModal extends PureComponent {
  static propTypes = {
    children: PropTypes.element,
    noCancel: PropTypes.bool,
    onClose: PropTypes.func,
  };

  render() {
    const { children, noCancel, onClose, t } = this.props;

    return (
      <ControlsModalCont {...this.props} styles={modalStyle} animation="fromBottom">
        {children}
        {!noCancel && (
          <Button onClick={onClose} unstyled>
            {t('cancel')}
          </Button>
        )}
      </ControlsModalCont>
    );
  }
}

ControlsModal.displayName = 'ControlsModal';

const ControlsModalCont = styled(Modal)`
  background: ${(p) => p.theme.colors.black};
  top: auto;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  button {
    color: white;
    margin: 0 0 ${(p) => p.theme.general.mobilePadding} ${(p) => p.theme.general.mobilePadding};
    &:first-of-type {
      margin-top: ${(p) => p.theme.general.mobilePadding};
    }
    svg {
      margin-right: 10px;
      margin-left: -2px;
    }
  }
`;

export default ControlsModal;
