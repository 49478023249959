import withUseFormContext from 'imports/core/hooks/withUseFormContext';
import AddressInputAutoSuggestion from './AddressInputAutoSuggestion';
import Autosave from '/imports/core/ui/components/Autosave';

const AddressInputAutoSave = (props) => {
  return (
    <Autosave {...props}>
      {({ onChange }) => (
        <AddressInputAutoSuggestion onChange={onChange} defaultvalue={props?.value || ''} {...props} />
      )}
    </Autosave>
  );
};

export default withUseFormContext(AddressInputAutoSave);
