import React from 'react';
import styled from 'styled-components';

const MoveDown = () => {
  return (
    <SvgIcon width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.237 0h3.797v4.61h1.898L8 9.22 4.068 4.61h2.17V0zM0 9.492h3.254v2.983h9.492V9.492H16v5.966H0V9.492z"
        fill="#429FF0"
      />
    </SvgIcon>
  );
};

const SvgIcon = styled.svg`
  stroke: none;
`;

export default MoveDown;
