import { isArabic } from 'styles/pdf-fonts/helpers';
import styled, { css } from 'styled-components';

import Text from '/imports/pdf/core/ui/atoms/Text';

const Heading = styled(Text)`
  display: inline-block;
  ${({ theme: { convertPx, isRTL, headingFont }, children }) => css`
    width: calc(100% - ${convertPx(41)});
    font-size: ${convertPx(16)};
    font-family: ${headingFont('bold')};
    text-align: ${isRTL ? 'right' : 'left'};
    letter-spacing: ${isArabic(children) ? 'normal' : convertPx(3.5)};
    color: #1c2946;
    line-height: ${convertPx(20)};
    text-transform: uppercase;
    vertical-align: middle;
    page-break-inside: avoid;
    break-inside: avoid-page;
  `}
`;

export default Heading;
