import styled from 'styled-components';

const CustomIcon = ({ width = '24px', height = '24px', viewBox = '0 0 24 24' }) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
    <path d="M22 2v2h-1v10a2 2 0 01-2 2h-6v1.434l4.514 2.709-1.028 1.714L13 19.767V22h-2v-2.234l-3.486 2.091-1.028-1.714L11 17.433V16H5a2 2 0 01-2-2V4H2V2h20zm-3 2H5v10h14V4zm-9 4v4H8V8h2zm3-2v6h-2V6h2zm3 3v3h-2V9h2z" />
  </SvgIcon>
);

const SvgIcon = styled.svg`
  stroke: none;
  fill: currentColor;
`;

CustomIcon.displayName = 'CustomIcon';

export default CustomIcon;
