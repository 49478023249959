import { PureComponent } from 'react';
import compose from 'lodash.flowright';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getActiveRepositingVar, getPreviewBlocksHeight } from 'lib/helpers';
import { graphql } from 'react-apollo';
import { withResponsiveContext } from 'imports/core/api/responsiveContext';

import { blockDetailImmutableUpdate } from '/imports/generator/api/apollo/client/helpers';
import { COVER_LETTER_TEMPLATES, TEMPLATES } from '/imports/generator/api/constants';
import { DEFAULT_TEMPLATE_FONTS } from '/imports/pdf/core/api/constants';
import DropdownAutosave from '/imports/core/ui/atoms/DropdownAutosave';
import FieldLabel from '/imports/generator/ui/atoms/FieldLabel';
import { UPDATE_COVER_LETTER_DETAIL, UPDATE_RESUME_DETAIL } from '/imports/generator/api/apollo/client/mutations';
import { withIntl } from '/imports/core/api/useIntl';
import { withTracking } from 'imports/core/hooks/useTracking';

@withIntl
@withTracking
@compose(
  graphql(UPDATE_RESUME_DETAIL, { name: 'updateResumeDetail' }),
  graphql(UPDATE_COVER_LETTER_DETAIL, { name: 'updateCoverLetterDetail' }),
)
@withResponsiveContext
class TemplateSelectorDropdown extends PureComponent {
  static propTypes = {
    options: PropTypes.array,
    source: PropTypes.object,
    hideLabel: PropTypes.bool,
    isCoverLetter: PropTypes.bool,
  };

  templateSwitch = (e) => {
    const {
      target: { value: newTemplate, version },
    } = e;
    const {
      updateImmue,
      source: {
        id: docId,
        settings: { template, headingFont, contentFont, templateVersion },
      },
      updateResumeDetail,
      updateCoverLetterDetail,
      isCoverLetter,
    } = this.props;
    this.props.trackEvent('switch_template', {
      template: `${newTemplate}${version > 1 ? `-v${version}` : ''}`,
    });
    if (!isCoverLetter) {
      //update template version in case of version update
      updateResumeDetail({
        variables: {
          docId,
          path: 'settings.templateVersion',
          value: `${(version && parseInt(version)) || 1}`,
        },
      });
      updateImmue && blockDetailImmutableUpdate(updateImmue)(docId, 'settings.templateVersion')(version);
    }
    if (newTemplate === template) return;
    const templates = isCoverLetter ? COVER_LETTER_TEMPLATES : TEMPLATES;
    const templateObject = templates.find((c) => c.id === newTemplate);
    //use default-fonts for each template if in current template still uses default-fonts (user not change fonts yet)
    if (
      DEFAULT_TEMPLATE_FONTS[template]?.headingFont === headingFont &&
      DEFAULT_TEMPLATE_FONTS[template]?.contentFont === contentFont &&
      !isCoverLetter
    ) {
      if (headingFont !== DEFAULT_TEMPLATE_FONTS[newTemplate]?.headingFont) {
        updateResumeDetail({
          variables: {
            docId,
            path: 'settings.headingFont',
            value: DEFAULT_TEMPLATE_FONTS[newTemplate]?.headingFont,
          },
        });
        blockDetailImmutableUpdate(updateImmue)(docId, 'settings.headingFont')(
          DEFAULT_TEMPLATE_FONTS[newTemplate]?.headingFont,
        );
      }

      if (contentFont !== DEFAULT_TEMPLATE_FONTS[newTemplate]?.contentFont) {
        updateResumeDetail({
          variables: {
            docId,
            path: 'settings.contentFont',
            value: DEFAULT_TEMPLATE_FONTS[newTemplate]?.contentFont,
          },
        });
        blockDetailImmutableUpdate(updateImmue)(docId, 'settings.contentFont')(
          DEFAULT_TEMPLATE_FONTS[newTemplate]?.contentFont,
        );
      }
    }
    if (templateObject && templateObject.defaultColor) {
      const mutation = isCoverLetter ? updateCoverLetterDetail : updateResumeDetail;
      const options = {
        variables: {
          docId,
          path: 'settings.color',
          value: templateObject.defaultColor,
        },
      };
      if (isCoverLetter) {
        options.context = {
          client: 'coverLetter',
        };
      }
      mutation(options);
    }
  };

  render() {
    const {
      hideLabel,
      dark,
      preview,
      stripe,
      needUpdate,
      options,
      isCoverLetter,
      source: {
        settings: { template, templateVersion },
        id,
        blocks,
      },
      t,
      updateImmue,
    } = this.props;

    const customBlocks =
      blocks && blocks.length > 0 ? blocks.map((b) => (b.type === 'CUSTOM' ? b.id : null)).filter((b) => b) : [];
    const customSkillsBlocks =
      blocks && blocks.length > 0
        ? blocks.map((b) => (b.type === 'CUSTOM_SKILLS_CATEGORY' ? b.id : null)).filter((b) => b)
        : [];
    let obj = {
      docId: id,
      path: `settings.template`,
      needUpdate: needUpdate,
    };
    const activeRepositionVariant = getActiveRepositingVar();
    const isMobile = ['xs', 'sm'].includes(this.props.breakpoint);
    if (activeRepositionVariant === 'with_complete_repositioning' && !isMobile) {
      obj = {
        ...obj,
        heights: getPreviewBlocksHeight(customBlocks, customSkillsBlocks),
        variant: 'with_complete_repositioning',
      };
    }
    let templateValue = template;
    if (templateVersion > 1) {
      templateValue = `${template}-v${templateVersion}`;
    }

    const MUTATION = isCoverLetter ? UPDATE_COVER_LETTER_DETAIL : UPDATE_RESUME_DETAIL;
    return (
      <Wrap language={this.props.locale}>
        {!hideLabel && <FieldLabel summary label={t('generator.settings.template')} />}
        <DropdownAutosave
          dark={dark}
          mutation={MUTATION}
          variables={obj}
          expandTitle={t('generator.settings.template')}
          name="template"
          docType="resume"
          value={templateValue}
          options={options}
          stripe={stripe}
          preview={preview}
          isCoverLetter={isCoverLetter}
          optimisticResponse={blockDetailImmutableUpdate(updateImmue)(id, 'settings.template')}
          onChangeCallback={this.templateSwitch}
          updateImmue={updateImmue}
        />
      </Wrap>
    );
  }
}

const Wrap = styled.div`
  position: relative;
`;

export default TemplateSelectorDropdown;
