import Dropdown from '/imports/core/ui/atoms/DropdownCustom';
import PropTypes from 'prop-types';
import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';

import Autosave from '/imports/core/ui/components/Autosave';
import { useFormContext, Controller } from 'react-hook-form';
import React from 'react';
import { degreeForFR, degreeForES, degreeArabicOptions } from '/imports/generator/api/constants';

const DropdownCustomAutosave = (props) => {
  const { options, dark, stripe, value, preview, customLabel, placeholder, lang } = props;
  let dropdownOption = options;

  switch (lang) {
    case 'fr':
      dropdownOption = [...dropdownOption, ...degreeForFR];
      break;
    case 'es':
      dropdownOption = [...dropdownOption, ...degreeForES];
      break;
    case 'ar':
      dropdownOption = [...dropdownOption, ...degreeArabicOptions];
      break;
  }

  const { control } = useFormContext();
  return (
    <Autosave {...props}>
      {({ onChange }) => (
        <Controller
          name="message"
          control={control}
          render={({ field }) => (
            <Dropdown
              {...props}
              field={field}
              onChange={onChange}
              options={dropdownOption}
              customLabel={customLabel}
              defaultSelected={value || ''}
              dark={dark}
              preview={preview}
              stripe={stripe}
              placeholder={placeholder}
            />
          )}
        />
      )}
    </Autosave>
  );
};

DropdownCustomAutosave.propTypes = {
  options: PropTypes.array,
  dark: PropTypes.bool,
  stripe: PropTypes.bool,
  value: PropTypes.string,
  preview: PropTypes.any,
  customLabel: PropTypes.string,
  placeholder: PropTypes.string,
};
export default DropdownCustomAutosave;
