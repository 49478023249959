import styled from 'styled-components';

const InternshipsIconV3 = ({ width = '24px', height = '24px', viewBox = '0 0 24 24' }) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
    <path d="M12.6328 18H11.3691V22.7506H12.6328V18Z" fill="#2D2F32" />
    <path d="M13.3855 17.3022V17.9955H12.6312H11.3676H10.6133V17.3022V16.6094H13.3855V17.3022Z" fill="#282B32" />
    <path
      d="M14.1559 12.45V13.5232H12.8832V12.45C12.8832 12.3971 12.8622 12.3481 12.8247 12.3111C12.7878 12.2741 12.7388 12.2526 12.6863 12.2526H11.3117C11.2592 12.2526 11.2102 12.2741 11.1732 12.3111C11.1357 12.3481 11.1147 12.3971 11.1147 12.45V13.5232H9.84212V12.45C9.84212 12.0592 9.99758 11.6878 10.2735 11.4113C10.5494 11.1354 10.9213 10.9805 11.3117 10.9805H12.6863C13.0767 10.9805 13.4486 11.1354 13.7245 11.4113C14.0004 11.6878 14.1559 12.0592 14.1559 12.45ZM11.3672 17.9999V22.7505H7.01095C6.42563 22.7505 5.94727 22.2717 5.94727 21.6863V17.3066H10.6129V17.9999H11.3672ZM18.0507 17.3066V21.6863C18.0507 22.2717 17.5719 22.7505 16.987 22.7505H12.6308V17.9999H13.3851V17.3066H18.0507Z"
      opacity="0.7"
    />
    <path
      d="M18.4526 14.3383V17.3029H18.0507H13.3851V16.6101H10.6129V17.3029H5.9473H5.54492V14.3383C5.54492 13.8879 5.91381 13.5195 6.36418 13.5195H9.84216H11.1148H12.8833H14.1559H17.6339C18.0842 13.5195 18.4526 13.8879 18.4526 14.3383Z"
      opacity="0.5"
    />
    <path
      d="M15.1205 8.37109H16.7635V8.6895C16.7635 9.08238 16.4426 9.40279 16.0502 9.40279H7.94962C7.55723 9.40279 7.23633 9.08238 7.23633 8.6895V8.37109H8.87935H15.1205Z"
      fill="#E0E1E2"
    />
    <path
      d="M15.1205 8.36779H8.87935H7.23633V4.67188L11.9999 6.47035L16.7635 4.67188V8.36779H15.1205Z"
      fill="#F4F9FF"
    />
    <path
      d="M18.9159 3.86464L16.7636 4.6774L12 6.47587L7.23635 4.6774L5.08398 3.86464L12 1.25391L18.9159 3.86464Z"
      opacity="0.5"
    />
    <path d="M11.9999 6.7257C11.9699 6.7257 11.94 6.72035 11.9116 6.70961L7.14801 4.91113L4.99559 4.09837C4.89832 4.06163 4.83398 3.96851 4.83398 3.86454C4.83398 3.76057 4.89837 3.66745 4.99564 3.63076L11.9116 1.02003C11.9685 0.998533 12.0313 0.998533 12.0881 1.02003L19.0041 3.63076C19.1013 3.66745 19.1657 3.76057 19.1657 3.86454C19.1657 3.96856 19.1013 4.06163 19.0041 4.09837L12.0881 6.70961C12.0597 6.72035 12.0298 6.7257 11.9999 6.7257ZM7.32455 4.44347L11.9999 6.20865L18.2083 3.86459L11.9999 1.52093L5.79145 3.86459L7.32455 4.44347Z" />
    <path d="M16.0502 9.64934H7.94955C7.41845 9.64934 6.98633 9.21722 6.98633 8.68612V4.6718C6.98633 4.53379 7.09825 4.42188 7.23625 4.42188C7.37426 4.42188 7.48618 4.53379 7.48618 4.6718V8.68612C7.48618 8.9416 7.69407 9.14949 7.94955 9.14949H16.0502C16.3056 9.14949 16.5135 8.9416 16.5135 8.68612V4.6718C16.5135 4.53379 16.6254 4.42188 16.7635 4.42188C16.9015 4.42188 17.0134 4.53379 17.0134 4.6718V8.68612C17.0134 9.21722 16.5813 9.64934 16.0502 9.64934Z" />
    <path d="M8.88079 8.62272C8.74278 8.62272 8.63086 8.51105 8.63086 8.37304V6.64055C8.63086 6.50254 8.74278 6.39062 8.88079 6.39062C9.0188 6.39062 9.13071 6.50254 9.13071 6.64055V8.37254C9.13071 8.51055 9.0188 8.62272 8.88079 8.62272ZM15.122 8.62272C14.9839 8.62272 14.872 8.51105 14.872 8.37304V6.64055C14.872 6.50254 14.9839 6.39062 15.122 6.39062C15.26 6.39062 15.3719 6.50254 15.3719 6.64055V8.37254C15.3719 8.51055 15.26 8.62272 15.122 8.62272Z" />
    <path d="M16.7625 8.62095H7.23531C7.0973 8.62095 6.98539 8.50903 6.98539 8.37102C6.98539 8.23301 7.0973 8.12109 7.23531 8.12109H16.7625C16.9006 8.12109 17.0124 8.23301 17.0124 8.37102C17.0124 8.50903 16.9006 8.62095 16.7625 8.62095ZM16.987 22.9992H7.01088C6.28654 22.9992 5.69727 22.4097 5.69727 21.6851V17.3054C5.69727 17.1673 5.80918 17.0555 5.94719 17.0555C6.0852 17.0555 6.19712 17.1673 6.19712 17.3054V21.6851C6.19712 22.1341 6.56216 22.4994 7.01088 22.4994H16.987C17.4357 22.4994 17.8007 22.1341 17.8007 21.6851V17.3054C17.8007 17.1673 17.9126 17.0555 18.0506 17.0555C18.1887 17.0555 18.3006 17.1673 18.3006 17.3054V21.6851C18.3006 22.4097 17.7113 22.9992 16.987 22.9992ZM12.8832 13.7719C12.7451 13.7719 12.6332 13.6601 12.6332 13.522V12.5013H11.3646V13.5221C11.3646 13.6601 11.2527 13.772 11.1147 13.772C10.9767 13.772 10.8647 13.6601 10.8647 13.5221V12.4488C10.8647 12.329 10.912 12.2164 10.9977 12.1319C11.0814 12.0482 11.1933 12.0015 11.3116 12.0015H12.6862C12.8046 12.0015 12.9165 12.0483 13.0015 12.1333C13.0859 12.2165 13.1331 12.329 13.1331 12.4489V13.5221C13.1331 13.6601 13.0212 13.7719 12.8832 13.7719Z" />
    <path d="M14.1555 13.7731C14.0174 13.7731 13.9055 13.6612 13.9055 13.5231V12.45C13.9055 12.1256 13.7783 11.8193 13.5471 11.5878C13.3167 11.3573 13.0108 11.2303 12.6859 11.2303H11.3113C10.9864 11.2303 10.6805 11.3573 10.4498 11.588C10.2189 11.8193 10.0917 12.1255 10.0917 12.45V13.5232C10.0917 13.6613 9.97973 13.7731 9.84172 13.7731C9.70371 13.7731 9.5918 13.6613 9.5918 13.5232V12.45C9.5918 11.9922 9.77094 11.5606 10.0962 11.2347C10.4214 10.9095 10.8529 10.7305 11.3113 10.7305H12.6859C13.1443 10.7305 13.5758 10.9095 13.9008 11.2346C14.2263 11.5606 14.4054 11.9922 14.4054 12.45V13.5232C14.4054 13.6612 14.2935 13.7731 14.1555 13.7731Z" />
    <path d="M18.4526 17.5528H13.385C13.247 17.5528 13.1351 17.4409 13.1351 17.3028C13.1351 17.1648 13.247 17.0529 13.385 17.0529H18.2026V14.3382C18.2026 14.0246 17.9475 13.7694 17.6338 13.7694H6.36411C6.05015 13.7694 5.79478 14.0245 5.79478 14.3382V17.0529H10.6129C10.7509 17.0529 10.8628 17.1648 10.8628 17.3028C10.8628 17.4409 10.7509 17.5528 10.6129 17.5528H5.54485C5.40684 17.5528 5.29492 17.4409 5.29492 17.3028V14.3382C5.29492 13.7489 5.77458 13.2695 6.36411 13.2695H17.6338C18.2231 13.2695 18.7025 13.7489 18.7025 14.3382V17.3028C18.7025 17.4409 18.5906 17.5528 18.4526 17.5528Z" />
    <path d="M13.3854 18.2453H10.6132C10.4752 18.2453 10.3633 18.1335 10.3633 17.9954V16.6093C10.3633 16.4712 10.4752 16.3594 10.6132 16.3594H13.3854C13.5235 16.3594 13.6353 16.4712 13.6353 16.6093V17.9954C13.6353 18.1335 13.5235 18.2453 13.3854 18.2453ZM10.8631 17.7455H13.1355V16.8592H10.8631V17.7455Z" />
    <path d="M11.3668 22.9985C11.2288 22.9985 11.1169 22.8866 11.1169 22.7486V17.998C11.1169 17.8599 11.2288 17.748 11.3668 17.748C11.5048 17.748 11.6167 17.8599 11.6167 17.998V22.7486C11.6167 22.8866 11.5048 22.9985 11.3668 22.9985ZM12.6304 22.9985C12.4924 22.9985 12.3805 22.8866 12.3805 22.7486V17.998C12.3805 17.8599 12.4924 17.748 12.6304 17.748C12.7685 17.748 12.8803 17.8599 12.8803 17.998V22.7486C12.8803 22.8866 12.7684 22.9985 12.6304 22.9985ZM7.99227 21.1326C7.85426 21.1326 7.74235 21.0207 7.74235 20.8826V19.2136C7.74235 19.0756 7.85426 18.9637 7.99227 18.9637C8.13028 18.9637 8.2422 19.0756 8.2422 19.2136V20.8826C8.2422 21.0207 8.13028 21.1326 7.99227 21.1326ZM9.32788 21.1326C9.18987 21.1326 9.07795 21.0207 9.07795 20.8826V19.2136C9.07795 19.0756 9.18987 18.9637 9.32788 18.9637C9.46589 18.9637 9.57781 19.0756 9.57781 19.2136V20.8826C9.57781 21.0207 9.46589 21.1326 9.32788 21.1326ZM14.6693 21.1326C14.5313 21.1326 14.4194 21.0207 14.4194 20.8826V19.2136C14.4194 19.0756 14.5313 18.9637 14.6693 18.9637C14.8074 18.9637 14.9192 19.0756 14.9192 19.2136V20.8826C14.9192 21.0207 14.8073 21.1326 14.6693 21.1326ZM16.0049 21.1326C15.8669 21.1326 15.755 21.0207 15.755 20.8826V19.2136C15.755 19.0756 15.8669 18.9637 16.0049 18.9637C16.143 18.9637 16.2548 19.0756 16.2548 19.2136V20.8826C16.2548 21.0207 16.143 21.1326 16.0049 21.1326ZM10.739 15.3163H7.83782C7.69981 15.3163 7.58789 15.2044 7.58789 15.0663C7.58789 14.9283 7.69981 14.8164 7.83782 14.8164H10.739C10.877 14.8164 10.9889 14.9283 10.9889 15.0663C10.9889 15.2044 10.877 15.3163 10.739 15.3163ZM16.1594 15.3163H13.2582C13.1202 15.3163 13.0083 15.2044 13.0083 15.0663C13.0083 14.9283 13.1202 14.8164 13.2582 14.8164H16.1594C16.2974 14.8164 16.4093 14.9283 16.4093 15.0663C16.4093 15.2044 16.2974 15.3163 16.1594 15.3163Z" />
  </SvgIcon>
);

const SvgIcon = styled.svg`
  stroke: none;
  fill: currentColor;
`;

InternshipsIconV3.displayName = 'InternshipsIconV3';

export default InternshipsIconV3;
