import React from 'react';

const AiIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group">
        <path
          id="Vector"
          d="M21.1041 8.83723C21.5841 7.42973 21.4566 5.86073 20.7001 4.55073C19.3071 2.13773 16.2631 1.26873 13.8121 2.53323C12.8331 1.41423 11.4106 0.740234 9.89707 0.740234C7.11157 0.740234 4.83707 2.94273 4.70707 5.69673C3.24807 5.98473 1.95307 6.88023 1.19657 8.19023C-0.195925 10.6027 0.573575 13.6737 2.89357 15.1637C2.41357 16.5712 2.54107 18.1402 3.29757 19.4502C4.26057 21.1177 6.01307 22.0507 7.81257 22.0507C8.61607 22.0507 9.42607 21.8542 10.1826 21.4642C11.1616 22.5852 12.5856 23.2607 14.1001 23.2607C16.8856 23.2607 19.1601 21.0582 19.2901 18.3042C20.7491 18.0162 22.0441 17.1207 22.8006 15.8107C24.1931 13.3982 23.4241 10.3272 21.1041 8.83723ZM18.9676 5.55073C19.3636 6.23623 19.4781 7.03723 19.3236 7.79473L15.7576 5.73573L10.4536 8.79823V6.76723L14.5921 4.37823C16.1231 3.49473 18.0851 4.02123 18.9676 5.55073ZM13.5441 11.1082V12.8927L11.9986 13.7852L10.4531 12.8932V11.1087L11.9986 10.2162L13.5441 11.1082ZM6.69507 5.94373C6.69507 4.17773 8.13158 2.74073 9.89758 2.74073C10.6896 2.74073 11.4406 3.04223 12.0196 3.55473L8.45357 5.61373V11.7387L6.69407 10.7232V5.94373H6.69507ZM2.93007 9.19073C3.32557 8.50523 3.96257 8.00523 4.69557 7.76023V11.8772L10.0001 14.9402L8.24107 15.9557L4.10207 13.5657C2.57257 12.6827 2.04707 10.7202 2.93007 9.19073ZM5.03107 18.4507C4.63507 17.7652 4.52057 16.9642 4.67507 16.2067L8.24107 18.2657L13.5451 15.2032V17.2342L9.40658 19.6232C7.87608 20.5072 5.91407 19.9802 5.03107 18.4507ZM17.3041 18.0582C17.3041 19.8242 15.8676 21.2612 14.1016 21.2612C13.3096 21.2612 12.5586 20.9597 11.9796 20.4472L15.5456 18.3882V12.2632L17.3051 13.2787V18.0582H17.3041ZM21.0691 14.8112C20.6736 15.4967 20.0366 15.9967 19.3036 16.2417V12.1247L13.9991 9.06173L15.7581 8.04623L19.8971 10.4362C21.4266 11.3192 21.9521 13.2817 21.0691 14.8112Z"
          fill="#1688FE"
        />
      </g>
    </svg>
  );
};

export default AiIcon;
