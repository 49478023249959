export const HELP_FOOTER_LINKS = () => {
  return [
    { href: '/terms-and-conditions', text: '', slug: 'footer.terms_conditions' },
    { href: '/privacy-policy', text: '', slug: 'footer.privacy_policy' },
    { href: '/', text: '', slug: 'header.help' },
    { href: '/contact-us', text: '', slug: 'header.contact_us' },
  ];
};

export const HELP_ARTICLES = () => {
  return [
    {
      title: {
        text: 'Most popular articles',
        slug: 'help.most_popular_articles',
      },
      articles: [
        {
          href: '10-how-to-cancel-your-subscription-account-via-mail',
          text: 'How to cancel your subscription account via Mail?',
          slug: 'help.cancel_subscription',
        },
        {
          href: '8-how-does-refund-policy-work',
          text: 'How does Refund Policy work?',
          slug: 'help.refund_policy',
        },
        {
          href: '6-what-can-i-do-with-a-premium-subscription',
          text: 'What can I do with a premium subscription?',
          slug: 'help.premium_subscription',
        },
        {
          href: '17-can-i-download-my-resume-in-word-format',
          text: 'Can I download my resume in Word format?',
          slug: 'help.download_word_format',
        },
        {
          href: '9-how-to-delete-your-account-from-the-website',
          text: 'How to delete your account from the website?',
          slug: 'help.delete_account',
        },
        {
          href: '7-how-do-i-log-in-to-resumedone-co',
          text: 'How do I log in to Resumedone.co?',
          slug: 'help.login_to_resumedone',
        },
        {
          href: '13-how-do-i-change-my-resume-template-or-design',
          text: 'How do I change my resume template or design?',
          slug: 'help.change_template',
        },
        {
          href: '18-how-can-i-customize-my-resume',
          text: 'How can I customize my resume?',
          slug: 'help.customize_resume',
        },
        {
          href: '16-how-do-i-get-access-to-the-cover-letter-feature',
          text: 'How do I get access to the cover letter feature?',
          slug: 'help.access_cover_letter',
        },
        {
          href: '19-can-i-change-the-language-of-my-resume',
          text: 'Can I change the language of my resume?',
          slug: 'help.change_resume_language',
        },
      ],
    },
    {
      title: {
        text: 'Resumes & Cover letters',
        slug: 'help.resumes_and_cover_letters',
      },
      articles: [
        {
          href: '13-how-do-i-change-my-resume-template-or-design',
          text: 'How do I change my resume template or design?',
          slug: 'help.change_template',
        },
        {
          href: '14-how-to-add-a-photo-to-your-resume',
          text: 'How to add a photo to your resume?',
          slug: 'help.add_photo',
        },
        {
          href: '15-how-do-i-duplicate-or-create-multiple-versions-of-my-resume',
          text: 'How do I duplicate or create multiple versions of my resume?',
          slug: 'help.create_multiple_version',
        },
        {
          href: '16-how-do-i-get-access-to-the-cover-letter-feature',
          text: 'How do I get access to the cover letter feature?',
          slug: 'help.access_cover_letter',
        },
        {
          href: '17-can-i-download-my-resume-in-word-format',
          text: 'Can I download my resume in Word format?',
          slug: 'help.download_word_format',
        },
        {
          href: '18-how-can-i-customize-my-resume',
          text: 'How can I customize my resume?',
          slug: 'help.customize_resume',
        },
        {
          href: '19-can-i-change-the-language-of-my-resume',
          text: 'Can I change the language of my resume?',
          slug: 'help.change_resume_language',
        },
        {
          href: '42-how-do-i-change-the-font-of-my-resume',
          text: 'How do I change the font of my resume?',
          slug: 'help.change_resume_font',
        },
        {
          href: '44-can-i-change-the-columns-of-section-blocks',
          text: 'How do I change the columns of section blocks?',
          slug: 'help.change_resume_section',
        },
      ],
    },
    {
      title: {
        text: 'Billings & Accounts',
        slug: 'help.billing_and_accounts',
      },
      articles: [
        {
          href: '6-what-can-i-do-with-a-premium-subscription',
          text: 'What can I do with a premium subscription?',
          slug: 'help.premium_subscription',
        },
        {
          href: '7-how-do-i-log-in-to-resumedone-co',
          text: 'How do I log in to Resumedone.co?',
          slug: 'help.login_to_resumedone',
        },
        {
          href: '8-how-does-refund-policy-work',
          text: 'How does Refund Policy work?',
          slug: 'help.refund_policy',
        },
        {
          href: '9-how-to-delete-your-account-from-the-website',
          text: 'How to delete your account from the website?',
          slug: 'help.delete_account',
        },
        {
          href: '10-how-to-cancel-your-subscription-account-via-mail',
          text: 'How to cancel your subscription account via Mail?',
          slug: 'help.cancel_subscription',
        },
      ],
    },
  ];
};

export const ARTICLE_SLUG_TO_HREF_MAP = [
  {
    href: '13-how-do-i-change-my-resume-template-or-design',
    slug: 'help.change_template',
  },
  {
    href: '14-how-to-add-a-photo-to-your-resume',
    slug: 'help.add_photo',
  },
  {
    href: '15-how-do-i-duplicate-or-create-multiple-versions-of-my-resume',
    slug: 'help.create_multiple_version',
  },

  {
    href: '16-how-do-i-get-access-to-the-cover-letter-feature',
    slug: 'help.access_cover_letter',
  },

  {
    href: '17-can-i-download-my-resume-in-word-format',
    slug: 'help.download_word_format',
  },

  {
    href: '18-how-can-i-customize-my-resume',
    slug: 'help.customize_resume',
  },
  {
    href: '19-can-i-change-the-language-of-my-resume',
    slug: 'help.change_resume_language',
  },
  {
    href: '42-how-do-i-change-the-font-of-my-resume',
    slug: 'help.change_resume_font',
  },
  {
    href: '44-can-i-change-the-columns-of-section-blocks',
    slug: 'help.change_resume_section',
  },
  {
    href: '6-what-can-i-do-with-a-premium-subscription',
    slug: 'help.premium_subscription',
  },
  {
    href: '7-how-do-i-log-in-to-resumedone-co',
    slug: 'help.login_to_resumedone',
  },
  {
    href: '8-how-does-refund-policy-work',
    slug: 'help.refund_policy',
  },
  {
    href: '9-how-to-delete-your-account-from-the-website',
    slug: 'help.delete_account',
  },
  {
    href: '10-how-to-cancel-your-subscription-account-via-mail',
    slug: 'help.cancel_subscription',
  },
];

export const ARTICLES_AIRTABLE_SLUG = [
  {
    href: '13-how-do-i-change-my-resume-template-or-design',
    title: 'help.change_template',
    texts: [
      'help.change_template.main_content',
      'help.change_template.customize_list_title',
      'help.change_template.template',
      'help.change_template.theme',
      'help.change_template.photo',
      'help.change_template.language',
      'help.change_template.font',
    ],
  },
  {
    href: '14-how-to-add-a-photo-to-your-resume',
    title: 'help.add_photo',

    texts: [
      'help.resume_photo.upload_resume_photo',
      'help.resume_photo.ideal_resume_photo',
      'help.resume_photo.resume_photo_guidelines',
    ],
  },
  {
    href: '15-how-do-i-duplicate-or-create-multiple-versions-of-my-resume',
    title: 'help.create_multiple_version',
    texts: [
      'help.create_multiple_version.duplicate_resume',
      'help.create_multiple_version.name_your_resume',
      'help.create_multiple_version.dashboard_resume_names',
    ],
  },

  {
    href: '16-how-do-i-get-access-to-the-cover-letter-feature',
    title: 'help.access_cover_letter',
    texts: ['help.access_cover_letter.premium_cover_letters'],
  },

  {
    href: '17-can-i-download-my-resume-in-word-format',
    title: 'help.download_word_format',
    texts: ['help.download_word_format.export_resume_options'],
  },

  {
    href: '18-how-can-i-customize-my-resume',
    title: 'help.customize_resume',
    texts: [
      'help.customize_resume.list_item_1',
      'help.customize_resume.list_item_2',
      'help.customize_resume.layout_customization',
      'help.customize_resume.theme_and_color',
      'help.customize_resume.change_template',
      'help.customize_resume.add_remove_photo',
      'help.customize_resume.reorder_sections',
      'help.customize_resume.column_switcher',
      'help.customize_resume.change_fonts',
      'help.customize_resume.title_font',
      'help.customize_resume.body_font',
    ],
  },
  {
    href: '19-can-i-change-the-language-of-my-resume',
    title: 'help.change_resume_language',
    texts: ['help.change_resume_language.change_resume_language', 'help.change_resume_language.category_translation'],
  },
  {
    href: '42-how-do-i-change-the-font-of-my-resume',
    title: 'help.change_resume_font',
    texts: [
      'help.change_resume_font.change_resume_fonts',
      'help.change_resume_font.dropdown_description',
      'help.change_resume_font.title_font',
      'help.change_resume_font.body_font',
      'help.change_resume_font.note',
    ],
  },
  {
    href: '44-can-i-change-the-columns-of-section-blocks',
    title: 'help.change_resume_section',
    texts: ['help.change_resume_section.adjust_columns', 'help.change_resume_section.drag_drop_columns'],
  },
  {
    href: '6-what-can-i-do-with-a-premium-subscription',
    title: 'help.premium_subscription',
    texts: [
      'help.premium_subscription.premium_account_benefits',
      'help.premium_subscription.premium_features_title',
      'help.premium_subscription.unlimited_cv_and_cover',
      'help.premium_subscription.unlimited_pdf_download',
      'help.premium_subscription.unlimited_docx_download',
      'help.premium_subscription.unlimited_devices',
      'help.premium_subscription.flexible_subscription_plans',
    ],
  },

  {
    href: '7-how-do-i-log-in-to-resumedone-co',
    title: 'help.login_to_resumedone',
    texts: [
      'help.login_to_resumedone.resume_editing_access',
      'help.login_to_resumedone.logging_and_troubleshooting',
      'help.login_to_resumedone.step1',
      'help.login_to_resumedone.step2',
      'help.login_to_resumedone.step3',
      'help.login_to_resumedone.pro_tips',
      'help.login_to_resumedone.login_troubleshoot_tips',
      'help.login_to_resumedone.check_spam',
      'help.login_to_resumedone.link_expires',
    ],
  },
  {
    href: '8-how-does-refund-policy-work',
    title: 'help.refund_policy',
    texts: ['help.refund_policy.description'],
  },
  {
    href: '9-how-to-delete-your-account-from-the-website',
    title: 'help.delete_account',
    texts: ['help.delete_account.delete_account', 'help.delete_account.downgrade_account'],
  },
  {
    href: '10-how-to-cancel-your-subscription-account-via-mail',
    title: 'help.cancel_subscription',
    texts: ['help.cancel_subscription.description'],
  },
];

export const FAQ_DOMAINS_LIST = [
  'cvyarat',
  'kreatorcv',
  'cvkoostamine',
  'theresumeranger',
  'cvtoolspro',
  'resumizeme',
  'cv-lite',
  'contoh-resume',
  'cv-hebrew',
  'cv-pavyzdys',
  'creare-cv',
  'modelos-de-curriculo',
  'cvparaugs',
  'cv-in-arabic',
  'criarcv',
  'resume-nation',
  'cveksempel',
  'cvforma',
  'cvhazirlama',
  'cvminta',
  'cv-pohja',
  'cv-skabelon',
  'cv-voorbeeld',
  'cv-wzor',
  'cvmenarik',
  'cvvzor',
  'xn--mxabdxcg5aeo5c',
  'xn--e1aaaggwcwefd4n',
  'lebenslaufschreiben',
  'mau-cv',
  'modellocv',
  'skriva-cv',
  'cvdeboss',
  'mysmartcv',
  'modeles-cv',
  'resume-example',
  'ejemplos-curriculum',
  'bestonlineresume',
  'elegantcv',
  'thai-resume',
  'resumedone',
  'kazakhstan-cv',
  'verycoolcv',
  'resumetiger',
  'quantumresume',
  'cvelevator',
  'melhorcv',
  'atelierdecv',
  'cvexito',
  'cvenminutos',
  'dreamresume',
];

export const ARTICLE_COUNT = 14;
