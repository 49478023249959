import styled from 'styled-components';

import Container from '/imports/homepageV2/ui/atmos/Container';

const BasicSectionWrapper = styled(Container)`
  display: block;
  padding: 0;
  ${({ theme }) => theme.max('sm')`
    padding : inherit;
  `}
  ${({ theme }) => theme.max('lg')`
   padding : inherit;
  `}
  ${({ theme }) => theme.max('md')`
  padding : inherit;
 `}
`;
export default BasicSectionWrapper;
