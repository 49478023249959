import styled from 'styled-components';

const CoursesIcon = ({ width = '20px', height = '20px', viewBox = '0 0 20 20' }) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
    <path d="M18 0a2 2 0 012 2v13.414L15.414 20H2a2 2 0 01-2-2V2a2 2 0 012-2h16zm0 2H2v16l11-.001V15a2 2 0 012-2h3V2zm-.415 13H15v2.584L17.585 15zM11 13v2H5v-2h6zm4-4v2H5V9h10zm0-4v2H5V5h10z" />
  </SvgIcon>
);

const SvgIcon = styled.svg`
  stroke: none;
  fill: currentColor;
`;

CoursesIcon.displayName = 'CoursesIcon';

export default CoursesIcon;
