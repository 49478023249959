import React, { PureComponent } from 'react';
import compose from 'lodash.flowright';
import onClickOutside from 'react-onclickoutside';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import styled, { css } from 'styled-components';

import { blockDetailImmutableUpdate } from '/imports/generator/api/apollo/client/helpers';
// import {
//   updateAfterDetailSave,
//   updateDetailsOptimisticResponse,
// } from '/imports/generator/api/apollo/client/helpers';
import Radio from '/imports/core/ui/atoms/Radio';
import { TEMPLATES, COVER_LETTER_TEMPLATES } from '/imports/generator/api/constants';
import { UPDATE_RESUME_DETAIL, UPDATE_COVER_LETTER_DETAIL } from '/imports/generator/api/apollo/client/mutations';
import { withIntl } from '/imports/core/api/useIntl';
import { withTracking } from 'imports/core/hooks/useTracking';

@withIntl
@compose(
  graphql(UPDATE_RESUME_DETAIL, { name: 'updateResumeDetail' }),
  graphql(UPDATE_COVER_LETTER_DETAIL, { name: 'updateCoverLetterDetail' }),
)
@withTracking
@onClickOutside
class ColorDropdown extends PureComponent {
  static propTypes = {
    id: PropTypes.string,
    selected: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    template: PropTypes.string,
    updateResumeDetail: PropTypes.func,
    updateCoverLetterDetail: PropTypes.func,
    isCoverLetter: PropTypes.bool,
  };

  state = {
    expanded: false,
  };

  accentsHidden = () => {
    const { template, isCoverLetter } = this.props;
    const templates = isCoverLetter ? COVER_LETTER_TEMPLATES : TEMPLATES;
    const templateData = templates.find((t) => t.id === template);

    return templateData.accentsHidden;
  };

  toggleExpanded = () => {
    if (!this.accentsHidden()) {
      this.setState((st) => ({ expanded: !st.expanded }));
    }
  };

  onChange = (e) => {
    const { id, updateResumeDetail, updateCoverLetterDetail, isCoverLetter, updateImmue } = this.props;
    const mutation = isCoverLetter ? updateCoverLetterDetail : updateResumeDetail;
    this.props.trackEvent('template_color_changed', {
      color: e.target.value,
    });
    const options = {
      variables: {
        docId: id,
        path: 'settings.color',
        value: e.target.value,
      },
      context: {},
    };
    if (isCoverLetter) options.context.client = 'coverLetter';
    blockDetailImmutableUpdate(updateImmue)(id, 'settings.color')(e.target.value);
    this.toggleExpanded();
    mutation(options);
  };

  handleClickOutside = (evt) => {
    const { expanded } = this.state;
    if (expanded) return this.setState({ expanded: false });
  };

  render() {
    const { expanded } = this.state;
    const { selected, template, isCoverLetter, t } = this.props;
    const templates = isCoverLetter ? COVER_LETTER_TEMPLATES : TEMPLATES;
    const { colors } = templates.find((t) => t.id === template) || {};
    return (
      <ColorDropdownCont>
        <Selected onClick={this.toggleExpanded}>
          {t('preview.color')} <Circle color={selected} isHidden={this.accentsHidden()} data-testid="color-circle" />
        </Selected>
        <DropdownMenu expanded={expanded}>
          {colors.map((color) => (
            <ColorRadio
              key={color}
              name="color"
              color={color}
              value={color}
              onChange={this.onChange}
              iconColor={'white'}
              checked={selected === color}
            />
          ))}
        </DropdownMenu>
      </ColorDropdownCont>
    );
  }
}

const Circle = styled.span`
  flex-shrink: 0;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-left: 7px;
  border: 2px solid #384861;
  border-radius: 50%;
  background-color: ${(p) => (!p.isHidden ? p.theme.colors[p.color] : p.theme.colors.gray.light)};
  cursor: pointer;
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      margin-left: 0;
      margin-right: 7px;
    `}
`;

const Selected = styled.p`
  margin: 0;
  font-size: 16px;
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-weight: normal;
  color: var(--light-values-white);
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const ColorDropdownCont = styled.div`
  position: relative;
  display: inline-block;
  width: 75px;
  margin-right: 36px;
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      margin-left: 36px;
      margin-right: 0;
    `}
`;

const DropdownMenu = styled.div`
  position: absolute;
  left: 0;
  top: 100%;
  background: ${(p) => p.theme.colors.black};
  width: 180px;
  border-radius: ${(p) => p.theme.general.borderRadius};
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: #151b25;
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  padding: 10px;
  padding-right: 0;
  transform-origin: top;
  transform: scaleY(0);
  opacity: 0;
  z-index: ${(p) => p.theme.zIndex.popover};

  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      right: 0;
    `}

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(p) => p.theme.colors.gray.regular};
    border: 4px solid transparent;
  }

  ${(p) =>
    p.expanded &&
    css`
      transform: scaleY(1);
      opacity: 1;
    `}
`;

const ColorRadio = styled(Radio)`
  width: 42px;
  height: 42px;
  background: ${(p) => p.theme.colors[p.color]};
  border: 0;
  margin-bottom: 4px;
  border: 1px solid #384861;
  margin-right: 8px;

  ${({ theme: { isRTL } }) =>
    !isRTL &&
    css`
      &:not(:last-of-type) {
        margin-right: 8px;
      }
    `}

  ${(p) =>
    p.color === 'black' &&
    css`
      border: 1px solid ${(p) => p.theme.colors.gray.regular};
    `}

  ${({ checked, color }) =>
    checked &&
    css`
      border: 2px solid #384861;
      ${color === 'black' &&
      css`
        border: 2px solid ${(p) => p.theme.colors.gray.regular};
      `};
    `}
`;

export default ColorDropdown;
