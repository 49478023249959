import React from 'react';
import styled from 'styled-components';

const DashIcon = () => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.9 3.5">
    <path d="M1.8 1.9h11.7" fill="none" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </SvgIcon>
);

const SvgIcon = styled.svg`
  stroke: none;
`;

export default DashIcon;
