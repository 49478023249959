import React from 'react';
import PropTypes from 'prop-types';

const GiftIcon = ({ color = '#1688fe' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="80" height="70" viewBox="0 0 80 70">
    <g fill="none">
      <path
        fill="#000"
        d="M78.182 7.273H66.125c.424-2.04.02-4.163-1.121-5.904C64.202.456 63.032-.047 61.818 0c-5.736.846-11.091 3.377-15.386 7.273H34.005C29.582 3.313 24.067.778 18.182 0c-1.214-.047-2.384.456-3.186 1.369-1.141 1.741-1.545 3.865-1.121 5.904H1.818c-.482 0-.945.19-1.286.532C.192 8.146 0 8.609 0 9.091v14.545c0 .483.191.945.532 1.286.341.341.804.533 1.286.533h1.818v42.727c0 .482.192.945.533 1.286.34.34.803.532 1.286.532h69.09c.483 0 .945-.191 1.286-.532.341-.341.533-.804.533-1.286V25.455h1.818c.482 0 .945-.192 1.286-.533.34-.34.532-.803.532-1.286V9.091c0-.482-.191-.945-.532-1.286-.341-.341-.804-.533-1.286-.532z"
      />
      <path
        fill={color}
        d="M49.71 27.987l-1.862-.62c-.457-.153-.957-.118-1.388.097-.431.216-.76.594-.911 1.052l-.196.582-2.286-6.1-.614-1.635c.843-.305 1.62-.764 2.295-1.353.631.314 1.468.723 2.453 1.185l2.51 6.792zM32.96 21.35c1.009-.446 1.87-.842 2.523-1.15.62.499 1.317.891 2.064 1.163l-.613 1.634v.001l-2.287 6.1-.196-.582c-.152-.457-.48-.835-.911-1.05-.432-.216-.93-.252-1.388-.099l-1.774.591 2.581-6.607zM39.898 25.455L40.102 25.455 43.636 34.876 43.636 66.364 36.364 66.364 36.364 34.876z"
      />
      <path
        fill="#FFF"
        d="M39.898 25.455L36.364 34.876 36.364 66.364 38.182 66.364 38.182 34.876 39.949 25.455z"
        opacity=".2"
      />
      <path
        fill="#000"
        d="M40.051 25.455L41.818 34.876 41.818 66.364 43.636 66.364 43.636 34.876 40.102 25.455z"
        opacity=".2"
      />
      <path
        fill="#FFF"
        d="M3.636 10.91H14.36c.102.6.164 1.208.186 1.817l-.016 1.105c-.2 2.688.07 5.39.796 7.986H3.636V10.91zM7.273 25.455H27.45l-1.872 4.792c-.257.657-.11 1.403.375 1.915.487.511 1.225.695 1.894.471l3.729-1.243 1.15 3.449v31.525H7.273v-40.91zM72.727 66.364H47.273V34.839l1.15-3.449 3.729 1.243c.663.222 1.395.043 1.882-.46.486-.501.642-1.238.4-1.894l-1.782-4.824h20.075v40.909zM76.364 21.818h-11.69c.727-2.596.997-5.298.797-7.986l-.016-1.105c.022-.61.084-1.217.186-1.818h10.723v10.91z"
      />
      <path
        fill={color}
        d="M62.248 3.741c.673.783.181 4.045-.082 5.797-.187 1.054-.303 2.12-.348 3.19l.018 1.178c.04 2.065.13 6.364-1.112 7.632-1.065 1.075-8.89-2.121-13.899-4.565.628-1.667.594-3.51-.092-5.153 4.95-4.584 11.486-8.184 15.085-8.184.152-.02.305.017.43.105z"
      />
      <circle cx="40" cy="14.545" r="3.636" fill={color} />
      <path
        fill="#000"
        d="M40.75 11.06c1.305.628 1.982 2.094 1.613 3.494-.37 1.4-1.68 2.342-3.126 2.244-1.445-.098-2.617-1.208-2.794-2.645-.034.129-.06.26-.08.392 0 2.009 1.629 3.637 3.637 3.637s3.636-1.628 3.636-3.637c-.017-1.697-1.221-3.151-2.886-3.484z"
        opacity=".2"
      />
      <path
        fill={color}
        d="M17.752 3.741c.125-.088.278-.126.43-.105 5.764 1.006 11.077 3.771 15.207 7.916-.832 1.79-.882 3.845-.136 5.672-5.164 2.364-12.932 5.373-13.977 4.314-1.242-1.268-1.153-5.567-1.112-7.632l.018-1.179c-.045-1.07-.161-2.135-.348-3.189-.263-1.752-.755-5.014-.082-5.797z"
      />
      <path
        fill="#FFF"
        d="M61.818 12.724L61.822 12.698 61.818 12.713zM61.818 8.25c.145-.01.292.007.43.052.043.025.045.09.078.124.245-1.772.47-4.047-.078-4.685-.125-.088-.278-.126-.43-.105-3.6 0-10.135 3.6-15.085 8.184.051.127.075.266.12.396 4.717-2.19 9.783-3.531 14.965-3.965z"
        opacity=".2"
      />
      <path
        fill="#000"
        d="M60.724 17.034c-4.565-.162-9.094-.882-13.485-2.143-.037.71-.177 1.411-.414 2.082 5.01 2.444 12.834 5.64 13.9 4.565 1.163-1.189 1.157-5.018 1.118-7.197-.028 1.027-.223 2.245-1.119 2.693z"
        opacity=".2"
      />
      <path
        fill="#FFF"
        d="M17.752 8.302c.139-.045.285-.063.43-.051 5.197.376 10.286 1.666 15.034 3.811.063-.168.1-.348.173-.51-4.13-4.145-9.443-6.91-15.207-7.916-.152-.02-.305.017-.43.105-.548.638-.323 2.913-.078 4.685.033-.034.035-.1.078-.124z"
        opacity=".2"
      />
      <path
        fill="#000"
        d="M19.276 17.034c-.896-.449-1.09-1.668-1.12-2.695-.038 2.178-.044 6.01 1.12 7.199 1.045 1.06 8.813-1.95 13.977-4.314-.272-.705-.433-1.448-.478-2.202-4.404 1.202-8.936 1.878-13.5 2.012z"
        opacity=".2"
      />
      <path
        fill="#FFF"
        d="M18.178 12.698L18.182 12.724 18.182 12.713zM61.818 5.882c.148-.018.298.01.43.078.163.19.262.427.28.676.196-.971.098-1.979-.28-2.895-.125-.088-.278-.126-.43-.105-3.6 0-10.135 3.6-15.085 8.184.023.058.032.123.054.182 4.423-3.26 9.59-5.364 15.031-6.12zM61.818 12.727L61.822 12.695 61.818 12.72z"
        opacity=".2"
      />
      <path
        fill="#000"
        d="M60.724 19.347c-1.048.795-8.617-1.514-13.632-3.34-.07.328-.159.65-.267.966 5.009 2.444 12.834 5.64 13.9 4.565.984-1.994 1.375-4.228 1.127-6.437-.02 1.596-.21 3.541-1.128 4.246z"
        opacity=".2"
      />
      <path
        fill="#FFF"
        d="M17.752 5.96c.132-.068.282-.096.43-.078 5.46.659 10.665 2.69 15.129 5.903.031-.076.045-.159.078-.233-4.13-4.145-9.443-6.91-15.207-7.916-.152-.02-.305.017-.43.105-.378.916-.476 1.924-.28 2.895.018-.25.117-.486.28-.676z"
        opacity=".2"
      />
      <path
        fill="#000"
        d="M19.276 19.347c-.918-.705-1.107-2.654-1.128-4.25-.249 2.21.143 4.446 1.128 6.44 1.045 1.06 8.813-1.95 13.977-4.313-.125-.333-.227-.673-.305-1.02-5.16 1.761-12.646 3.925-13.672 3.143z"
        opacity=".2"
      />
      <path fill="#FFF" d="M18.178 12.695L18.182 12.727 18.182 12.72z" opacity=".2" />
      <circle cx="38.795" cy="12.999" r="1" fill="#FFF" opacity=".2" />
      <path
        fill="#000"
        d="M31.577 31.39l-3.729 1.243c-.67.224-1.407.04-1.894-.471-.486-.512-.632-1.258-.375-1.915l1.872-4.792H7.273v7.272h24.75l-.446-1.337zM52.652 25.455l1.782 4.824c.242.656.086 1.393-.4 1.895-.487.502-1.219.68-1.882.46l-3.729-1.244-.446 1.337h24.75v-7.272H52.652z"
        opacity=".2"
      />
    </g>
  </svg>
);

export default GiftIcon;

GiftIcon.propTypes = {
  color: PropTypes.string,
};
