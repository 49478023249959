import React from 'react';
import styled from 'styled-components';

const DeleteIcon = () => {
  return (
    <span>
      <SvgIcon width="17" height="19" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.3 25.1">
        <path d="M4.1 2.1h14v2.2h-14V2.1z" />
        <path d="M9 .6h4.2v2.2H9V.6z" />
        <path
          d="M18.1 5.8h-14l1 18.8h12l1-18.8zm-6 2.8h-2v13h2v-13zm2 0h2l-.5 13h-1.5v-13zm-6 0h-2l.5 13h1.5v-13z"
          fillRule="evenodd"
          clipRule="evenodd"
        />
      </SvgIcon>
    </span>
  );
};

const SvgIcon = styled.svg`
  stroke: none;
`;

export default DeleteIcon;
