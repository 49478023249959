import React from 'react';
import styled from 'styled-components';
import { getDomainName, isBlogDomain } from 'lib/helpers';
import {
  StyledListContainer,
  StyledListItem,
  StyledText,
  TextWithLinkWrapper,
  SubsectionTitle,
} from '../atoms/Typography';
import { useResponsive } from 'imports/core/api/responsiveContext';

import ArticlePageWrapper from '/imports/help/ui/components/ArticlePageWrapper';
import useIntl from '/imports/core/api/useIntl';

const PremiumSubscription = () => {
  const { t, locale } = useIntl();
  const { host } = useResponsive();
  const blog = isBlogDomain(host);
  const getURL = () => {
    return blog
      ? `https://${getDomainName(host)}/builder/${locale}/checkout`
      : `https://${getDomainName(host)}/${locale}/checkout`;
  };

  React.useEffect(() => {
    const goToCheckoutPage = () => {
      window.open(getURL(), '_blank');
    };
    const pricing = document.getElementById('pricing');
    pricing?.addEventListener('click', goToCheckoutPage);
    return () => {
      pricing?.removeEventListener('click', goToCheckoutPage);
    };
  }, []);

  return (
    <ArticlePageWrapper title="help.premium_subscription">
      <StyledText>{t('help.premium_subscription.premium_account_benefits')}</StyledText>
      <SubsectionTitle>{t('help.premium_subscription.premium_features_title')}</SubsectionTitle>
      <StyledListContainer>
        <StyledListItem>{t('help.premium_subscription.unlimited_cv_and_cover')}</StyledListItem>
        <StyledListItem>{t('help.premium_subscription.unlimited_pdf_download')}</StyledListItem>
        <StyledListItem>{t('help.premium_subscription.unlimited_docx_download')}</StyledListItem>
        <StyledListItem>{t('help.premium_subscription.unlimited_devices')}</StyledListItem>
      </StyledListContainer>
      <TextWithLinkWrapper
        dangerouslySetInnerHTML={{ __html: t('help.premium_subscription.flexible_subscription_plans') }}
      />
    </ArticlePageWrapper>
  );
};

export default PremiumSubscription;
