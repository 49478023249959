import CustomIconV3 from './CustomIconV3';
import IconsExperiment from './IconsExperiment';

const CustomIconExperiment = (props) => {
  return <IconsExperiment iconV3={<CustomIconV3 {...props} />} />;
};

CustomIconExperiment.displayName = 'CustomIconExperiment';

export default CustomIconExperiment;
