import VehicleIconV3 from './VehicleIconV3';
import IconsExperiment from './IconsExperiment';

const VehicleIconExperiment = (props) => {
  return <IconsExperiment iconV3={<VehicleIconV3 {...props} />} />;
};

VehicleIconExperiment.displayName = 'VehicleIconExperiment';

export default VehicleIconExperiment;
