import styled, { css } from 'styled-components';

import View from '/imports/pdf/core/ui/atoms/View';

const BlockItem = styled(View)`
  display: block;
  ${({ summary, theme: { convertPx } }) => css`
    padding-top: ${convertPx(26)};
    border-top: ${convertPx(1)} solid var(--black);
    ${summary &&
    css`
      padding-top: 0;
      border-top-color: transparent;
    `}
  `}
`;

export default BlockItem;
