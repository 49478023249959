import { ERROR_MESSAGES } from '/imports/core/api/constants';

export const emailValidatorPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const validateEmail = (value) => {
  if (!value) {
    return ERROR_MESSAGES.required;
  }
  const emailRegex = emailValidatorPattern;
  if (!emailRegex.test(value)) {
    return ERROR_MESSAGES.email;
  }
  return true;
};
