import Heading from './Heading';
import styled, { css } from 'styled-components';

const SubHeading = styled(Heading)`
  ${({ theme: { contentFont, convertPx, isRTL } }) => css`
    font-family: ${contentFont('semiBold')};
    font-weight: 700;
    font-size: ${convertPx(10, true)};
    position: relative;
    padding-left: ${convertPx(isRTL ? 0 : 10)};
    padding-right: ${convertPx(isRTL ? 10 : 0)};
    letter-spacing: ${convertPx(2)};
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  `}
`;

export default SubHeading;
