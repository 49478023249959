import React from 'react';

const Quality1Icon = () => {
  return (
    <svg width="48" height="50" viewBox="0 0 48 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M41 22.9896H7C4.79086 22.9896 3 24.8551 3 27.1562V43.4375C3 45.7387 4.79086 47.6042 7 47.6042H41C43.2091 47.6042 45 45.7387 45 43.4375V27.1562C45 24.8551 43.2091 22.9896 41 22.9896Z"
        fill="#69AAFF"
      />
      <path
        d="M41 14.6458H7C4.79086 14.6458 3 16.5113 3 18.8125V41.7292C3 44.0303 4.79086 45.8958 7 45.8958H41C43.2091 45.8958 45 44.0303 45 41.7292V18.8125C45 16.5113 43.2091 14.6458 41 14.6458Z"
        fill="#C8E0FF"
      />
      <path
        d="M45 28.8229C40.58 28.8229 38.66 32.3958 38.54 34.4375C32.73 32.8229 28.07 37.6354 29.2 41.8958C26.72 39.8229 20.43 41.6146 20.43 44.4375C18.62 41.8958 15.31 42.0313 12.79 44.2083C12.79 39.9375 10.34 39.9271 10.21 39.9479C12.86 36 7.53 30.6042 3 33.625V41.7292C3 42.0208 3.03 42.3021 3.08 42.5729C3.47 44.4063 4.53 45.8958 7 45.8958H41C43.21 45.8958 45 44.0313 45 41.7292V28.8229Z"
        fill="white"
      />
      <path
        d="M20.9802 34.0833L15.3102 41.4271C14.9302 41.9167 14.1602 41.6354 14.1602 41.0104V35.1667C14.1602 33.375 14.6502 31.625 15.5602 30.1042L20.9702 21.1771V34.0937L20.9802 34.0833Z"
        fill="#37B982"
      />
      <path
        d="M27.02 34.3021L32.69 41.6458C33.07 42.1354 33.84 41.8542 33.84 41.2292V35.3854C33.84 33.5938 33.35 31.8438 32.44 30.3229L27.03 21.3958V34.3125L27.02 34.3021Z"
        fill="#37B982"
      />
      <path
        d="M18.61 10.7396C16.86 14.9792 16.37 19.75 17.38 24.4062L19.97 36.3229C20.07 37.2917 20.86 38.0208 21.8 38.0208H23.99V10.7396H18.61Z"
        fill="#0866F5"
      />
      <path
        d="M29.36 10.7396H23.98V38.0312H26.17C27.11 38.0312 27.89 37.3021 28 36.3333L30.59 24.4167C31.6 19.7604 31.12 14.9792 29.36 10.75V10.7396Z"
        fill="#0050C7"
      />
      <path d="M23.9899 2.95833C21.6299 5.14583 19.8199 7.8125 18.6099 10.7396H23.9899V2.95833Z" fill="#5DDFA9" />
      <path d="M23.9902 2.95833V10.7396H29.3702C28.1602 7.82291 26.3502 5.14583 23.9902 2.95833Z" fill="#37B982" />
      <path
        d="M24.2 26.5208H23.79C23.2378 26.5208 22.79 26.9872 22.79 27.5625V40.5729C22.79 41.1482 23.2378 41.6146 23.79 41.6146H24.2C24.7523 41.6146 25.2 41.1482 25.2 40.5729V27.5625C25.2 26.9872 24.7523 26.5208 24.2 26.5208Z"
        fill="#37B982"
      />
      <path
        d="M24 26.5208H24.21C24.76 26.5208 25.21 26.9896 25.21 27.5625V40.5729C25.21 41.1458 24.76 41.6146 24.21 41.6146H24V26.5208Z"
        fill="#008B5D"
      />
      <path
        d="M23.9998 20.8542C26.3415 20.8542 28.2398 18.8768 28.2398 16.4375C28.2398 13.9982 26.3415 12.0208 23.9998 12.0208C21.6581 12.0208 19.7598 13.9982 19.7598 16.4375C19.7598 18.8768 21.6581 20.8542 23.9998 20.8542Z"
        fill="#008B5D"
      />
      <path
        d="M23.9999 19.7083C25.734 19.7083 27.1399 18.2439 27.1399 16.4375C27.1399 14.6311 25.734 13.1667 23.9999 13.1667C22.2657 13.1667 20.8599 14.6311 20.8599 16.4375C20.8599 18.2439 22.2657 19.7083 23.9999 19.7083Z"
        fill="white"
      />
    </svg>
  );
};
export default Quality1Icon;
