import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import InputStripe from '/imports/core/ui/atoms/InputStripe';
import { inputStyle } from '/imports/core/ui/mixins';
import { getInputName } from '/imports/generator/api/helpers';

class InputCont extends PureComponent {
  static propTypes = {
    error: PropTypes.string,
    customInputStripeCss: PropTypes.string,
    customInputStripeErrorCss: PropTypes.string,
    defaultvalue: PropTypes.string,
    borderActive: PropTypes.string,
  };

  render() {
    const { error, customInputStripeCss, customInputStripeErrorCss, defaultvalue, borderActive, variables, name } =
      this.props;
    const inputName = getInputName(name, variables);
    return (
      <Wrap>
        <Textarea defaultValue={defaultvalue} {...this.props} name={inputName} />
        {!borderActive && (
          <InputStripe
            error={error}
            customInputStripeCss={customInputStripeCss}
            customInputStripeErrorCss={customInputStripeErrorCss}
          />
        )}
      </Wrap>
    );
  }
}

const Wrap = styled.div`
  position: relative;
  border-radius: ${(p) => p.theme.general.borderRadius};
  overflow: hidden;
`;

const Textarea = styled.textarea`
  display: block;
  box-shadow: none;
  resize: none;
  ${inputStyle}

  ${({ borderActive, error }) =>
    borderActive &&
    error &&
    css`
      border: solid 2px #ff2d2d !important;
    `}

  &:focus {
    & ~ div {
      visibility: visible;
      transform: rotateY(0);
    }

    ${({ borderActive }) =>
      borderActive &&
      css`
        border: solid 2px #1688fe !important;
      `}
  }
`;

export default InputCont;
