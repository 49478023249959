import styled from 'styled-components';

import Flex from '/imports/core/ui/atoms/Flex';

const TextBlock = styled(Flex)`
  margin-top: auto;
  margin-bottom: auto;
  width: 51.5%;
  gap: 32px;
  @media (max-width: 767px) {
    width: auto;
  }
  @media (max-width: 479px) {
    alignitems: stretch;
  }
`;
export default TextBlock;
