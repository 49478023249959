import React from 'react';

const Quality7Icon = () => {
  return (
    <svg width="48" height="51" viewBox="0 0 48 51" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32.5698 4.25H4.56982C3.46525 4.25 2.56982 5.20139 2.56982 6.375V44.625C2.56982 45.7986 3.46525 46.75 4.56982 46.75H32.5698C33.6744 46.75 34.5698 45.7986 34.5698 44.625V6.375C34.5698 5.20139 33.6744 4.25 32.5698 4.25Z"
        fill="#5DDFA9"
      />
      <path
        d="M17.0897 13.3769H8.69971C7.59514 13.3769 6.69971 14.3283 6.69971 15.5019C6.69971 16.6755 7.59514 17.6269 8.69971 17.6269H17.0897C18.1943 17.6269 19.0897 16.6755 19.0897 15.5019C19.0897 14.3283 18.1943 13.3769 17.0897 13.3769Z"
        fill="white"
      />
      <path
        d="M12.0297 21.8769H8.69971C7.59514 21.8769 6.69971 22.8283 6.69971 24.0019C6.69971 25.1755 7.59514 26.1269 8.69971 26.1269H12.0297C13.1343 26.1269 14.0297 25.1755 14.0297 24.0019C14.0297 22.8283 13.1343 21.8769 12.0297 21.8769Z"
        fill="white"
      />
      <path
        d="M15.4597 30.3769H8.69971C7.59514 30.3769 6.69971 31.3283 6.69971 32.5019C6.69971 33.6755 7.59514 34.6269 8.69971 34.6269H15.4597C16.5643 34.6269 17.4597 33.6755 17.4597 32.5019C17.4597 31.3283 16.5643 30.3769 15.4597 30.3769Z"
        fill="white"
      />
      <path
        d="M32.5702 37.2513C39.5621 37.2513 45.2302 31.2289 45.2302 23.8C45.2302 16.3711 39.5621 10.3488 32.5702 10.3488C25.5782 10.3488 19.9102 16.3711 19.9102 23.8C19.9102 31.2289 25.5782 37.2513 32.5702 37.2513Z"
        fill="#0866F5"
      />
      <path
        d="M32.57 10.3594C32.57 10.3594 32.55 10.3594 32.54 10.3594V37.2513C32.54 37.2513 32.56 37.2513 32.57 37.2513C39.56 37.2513 45.23 31.2269 45.23 23.8C45.23 16.3731 39.56 10.3488 32.57 10.3488V10.3594Z"
        fill="#0050C7"
      />
      <path
        d="M32.5701 34.17C37.9604 34.17 42.3301 29.5272 42.3301 23.8C42.3301 18.0728 37.9604 13.43 32.5701 13.43C27.1798 13.43 22.8101 18.0728 22.8101 23.8C22.8101 29.5272 27.1798 34.17 32.5701 34.17Z"
        fill="#83F8C7"
      />
      <path
        d="M32.57 13.43C32.57 13.43 32.54 13.43 32.52 13.43V34.17C32.52 34.17 32.55 34.17 32.57 34.17C37.96 34.17 42.33 29.5269 42.33 23.8C42.33 18.0731 37.96 13.43 32.57 13.43Z"
        fill="#5DDFA9"
      />
      <path
        d="M25.7802 21.7494L31.5002 25.0112L38.3302 18.2856C38.6102 18.0094 39.0502 18.0306 39.3002 18.3281C39.5202 18.5831 39.5402 18.955 39.3702 19.2312L33.3402 28.9531C32.7702 30.0156 31.3202 30.175 30.5702 29.24L25.1002 22.6525C24.6902 22.185 25.2602 21.4412 25.7702 21.7494H25.7802Z"
        fill="#0866F5"
      />
    </svg>
  );
};
export default Quality7Icon;
