import React, { useRef, Fragment } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import styled, { css } from 'styled-components';

import Button from '/imports/core/ui/atoms/Button';
import { cdnFile } from '/lib/helpers';
import env from '/env';
import { homepageMainSection } from '/imports/homepage/api/constants';
import { LANDING_CONTENT } from '/imports/landing/api/constants';
import { RightArrow } from '/imports/landing/ui/assets';
import useIntl from '/imports/core/api/useIntl';
import { useResponsive } from '/imports/core/api/responsiveContext';
import useWindowSize from '/imports/core/api/useWindowSize';

const SectionList = ({ isHomepage = false }) => {
  const { width } = useWindowSize();
  const { host } = useResponsive();
  const { t, locale } = useIntl();
  const isSlider = width < 768;
  const getList = () => {
    switch (host) {
      case 'mysmartcv':
        return locale === 'en' || !isHomepage || locale === 'ar'
          ? [
              t(LANDING_CONTENT.mysmartcv?.steps[0]),
              t(LANDING_CONTENT.mysmartcv?.steps[1]),
              t(LANDING_CONTENT.mysmartcv?.steps[2]),
            ]
          : [
              homepageMainSection['mysmartcv']?.[locale]?.steps[0],
              homepageMainSection['mysmartcv']?.[locale]?.steps[1],
              homepageMainSection['mysmartcv']?.[locale]?.steps[2],
            ];
      case 'cvdeboss':
      default:
        return [t('landing.list_item_1'), t('landing.list_item_2'), t('landing.list_item_3')];
    }
  };

  const [listItem1, listItem2, listItem3] = getList();

  const content = [
    {
      item: (
        <p>
          1. <span dangerouslySetInnerHTML={{ __html: listItem1 }} />
        </p>
      ),
      afterContent: cdnFile('male-browsing.png'),
    },
    {
      item: (
        <p>
          2. <span dangerouslySetInnerHTML={{ __html: listItem2 }} />
        </p>
      ),
      afterContent: cdnFile('woman-standing.png'),
    },
    {
      item: (
        <p>
          3. <span dangerouslySetInnerHTML={{ __html: listItem3 }} />
        </p>
      ),
      afterContent: cdnFile('woman-sitting.png'),
    },
  ];

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    focusOnSelect: true,
  };
  const sliderEl = useRef(null);

  const next = () => {
    sliderEl.current.slickNext();
  };

  const previous = () => {
    sliderEl.current.slickPrev();
  };

  const getSectionItem = () => {
    return content.map((item, i) => (
      <SectionItem key={i} $afterContent={item.afterContent} $isMySmartCV={host === 'mysmartcv'}>
        {item.item}
      </SectionItem>
    ));
  };
  return (
    <Container $isSliderContainer={isSlider}>
      {isSlider ? (
        <Fragment>
          <LeftButton onClick={previous}>
            <RightArrow />
          </LeftButton>
          <RightButton onClick={next}>
            <RightArrow />
          </RightButton>
          <Slider {...settings} ref={sliderEl}>
            {getSectionItem()}
          </Slider>
        </Fragment>
      ) : (
        getSectionItem()
      )}
    </Container>
  );
};

const Container = styled.ul`
  counter-reset: a;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin: 0;
  padding: 35px 20px 0;
  flex-direction: column;
  margin: 0 auto;

  ${({ theme }) =>
    theme.min('sm')`
      align-items: flex-start;
      flex-direction: row;
      padding: 35px 40px 0;
    `}

  ${({ $isSliderContainer }) =>
    $isSliderContainer &&
    css`
      width: 100vw;
      position: relative;
      padding: 0;
      margin-bottom: 0;

      .slick-list {
        width: 100vw;
      }
    `}

  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
`;

const SectionItem = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: auto;
  max-width: none;
  min-height: 0;
  margin: 10px 0;
  padding: 0 0 20px;
  background-color: transparent;
  min-height: 275px;
  ${({ theme }) =>
    theme.min('sm')`
      padding: 0 0 0 17px;
    `}

  p {
    margin: 0;
    color: var(--black);
    line-height: 22px;
    letter-spacing: 0.2px;
    text-align: center;

    font-size: 14px;
    font-family: ${({ theme }) => theme.font.family.websiteSemiBold};

    span {
      span {
        font-weight: inherit;
        color: #f37112;
      }
    }
  }
  &::after {
    content: '';
    position: absolute;
    top: 20px;
    left: 50%;
    width: 100%;
    max-width: 285px;
    height: 230px;
    background: 50% / contain no-repeat;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  &::after {
    width: 125%;
    height: 200px;
    top: 50px;
    background-size: contain;
    background-image: url(${({ $afterContent }) => $afterContent});
  }
  ${({ $isMySmartCV }) =>
    $isMySmartCV &&
    css`
      ${({ theme }) =>
        theme.min('sm')`
          width: 33%;
      `}
    `}
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
`;

const ArrowButton = styled(Button)`
  position: absolute;
  background: transparent;
  border: none;
  top: 42%;
  z-index: 2;

  &:hover {
    background: transparent;
    border: none;
  }

  g {
    stroke: #000f3d;
  }
`;

const RightButton = styled(ArrowButton)`
  right: 0;
`;

const LeftButton = styled(ArrowButton)`
  left: 0;
  div {
    transform: scaleX(-1);
  }
`;

export default SectionList;
