import React from 'react';
import styled, { css } from 'styled-components';

import Address from '/imports/core/ui/components/Address';
import { getRoute, getFAQRedirectionUrl, isBlogDomain } from '/lib/helpers';
import Link from '/components/Link';
import useDomain from '/imports/core/hooks/useDomain';
import useIntl from '/imports/core/api/useIntl';
import { useResponsive } from '/imports/core/api/responsiveContext';

const Footer = () => {
  const { t, locale } = useIntl();
  const { getBrandName } = useDomain();
  const { host } = useResponsive();
  return (
    <FooterWrapper>
      <FooterInner>
        <FooterItem>
          <FooterList>
            <FooterListItem>
              <a
                href={getRoute('/terms-and-conditions', locale, false, host, isBlogDomain(host))}
                target="_blank"
                rel="noreferrer"
              >
                {t('footer.terms_conditions')}
              </a>
            </FooterListItem>
            <FooterListItem>
              <a
                href={getRoute('/privacy-policy', locale, false, host, isBlogDomain(host))}
                target="_blank"
                rel="noreferrer"
              >
                {t('footer.privacy_policy')}
              </a>
            </FooterListItem>
            <FooterListItem>
              <a href={getFAQRedirectionUrl(host, locale)} target="_blank" rel="noreferrer">
                {t('faq')}
              </a>
            </FooterListItem>
            <FooterListItem>
              <Link href="/contact-us">{t('header.contact_us')}</Link>
            </FooterListItem>
            {host === 'lebenslaufschreiben' && (
              <FooterListItem>
                <Link href="/legal-notice">Impressum</Link>
              </FooterListItem>
            )}
          </FooterList>
        </FooterItem>
        <FooterItem>
          <FooterText>
            © {new Date().getFullYear()} {getBrandName()}. {t('footer.rights')}
          </FooterText>
          <Address forDashboard />
        </FooterItem>
      </FooterInner>
    </FooterWrapper>
  );
};

const FooterWrapper = styled.div`
  position: relative;
  padding: 34px 30px 28px;
  background-color: #fff;

  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
`;

const FooterInner = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: -10px -25px;

  @media (max-width: 1093px) {
    justify-content: center;
    gap: 20px;
  }
  @media (max-width: 836px) {
    flex-direction: column;
  }
`;

const FooterItem = styled.div`
  padding: 10px 25px;
`;

const FooterList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -10px -25px;
  @media (max-width: 836px) {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
`;

const FooterListItem = styled.div`
  padding: 10px 25px;
  a,
  div {
    display: block;
    margin: -7px 0 -8px;
    color: #7e8592;
    font-family: ${({ theme }) => theme.font.family.websiteMedium};
    font-size: 13px;
    letter-spacing: 0.36px;
    text-align: center;
    transition: color 0.2s;
    text-decoration: none;
  }
  div {
    cursor: pointer;
  }
  a:hover {
    color: #2274b0;
  }
`;

const FooterText = styled.div`
  display: block;
  padding: 0 0 5px;
  color: #7e8592;
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 13px;
  letter-spacing: 0.36px;
  ${({ theme: { isRTL, max } }) =>
    isRTL &&
    max('md')`
    text-align: right;
  `}

  @media (max-width: 1093px) {
    text-align: center;
  }
`;

export default Footer;
