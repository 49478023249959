import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const EmailIcon = ({ references }) => (
  <SvgIcon references={references ? 1 : 0} width="24" height="19" viewBox="0 0 24 19">
    <StyledPath
      fillRule="evenodd"
      d="M12.36 13.142L.861 2.402V.215h23.006v2.187L12.36 13.142zM.861 16.266V4.707L6.892 10.1.861 16.266zM23.866 4.707v11.56L17.833 10.1l6.033-5.393zM12.361 15.469l4.394-4.209 6.789 7.017H1.184l6.788-7.017 4.389 4.209z"
    />
  </SvgIcon>
);

EmailIcon.propTypes = {
  references: PropTypes.bool,
};

const StyledPath = styled.path`
  fill: ${({ theme: { rigaColor } }) => rigaColor('#929292')};
`;

const SvgIcon = styled.svg`
  stroke: none;
  ${({ theme: { convertPx, isRTL }, references }) => css`
    width: ${convertPx(24)};
    height: ${convertPx(19)};
    ${references &&
    css`
      width: ${convertPx(14)};
      height: ${convertPx(11)};
      margin-right: ${convertPx(isRTL ? 0 : 8)};
      margin-left: ${convertPx(isRTL ? 8 : 0)};
      path {
        fill: #333e50;
      }
    `}
  `}
`;

export default EmailIcon;
