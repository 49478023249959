import RelatedSkillsContainer from './RelatedSkillsContainer';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import Modal from '/imports/core/ui/atoms/Modal';
const RelatedSkillsModal = ({ onClose, resume, updateImmue, relatedSkills, isOnRightSide, jobTitleToShow }) => {
  return (
    <Modal styles={isOnRightSide ? modalStylesRightSide : modalStyles} onClose={onClose} noClicker>
      <Wrapper $isOnRightSide={isOnRightSide}>
        <RelatedSkillsContainer
          onClose={onClose}
          resume={resume}
          updateImmue={updateImmue}
          isOnRightSide={isOnRightSide}
          relatedSkills={relatedSkills}
          jobTitleToShow={jobTitleToShow}
        />
      </Wrapper>
    </Modal>
  );
};
RelatedSkillsModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  resume: PropTypes.object,
  updateImmue: PropTypes.func,
  relatedSkills: PropTypes.array,
  isOnRightSide: PropTypes.bool,
  jobTitleToShow: PropTypes.string,
};
export default RelatedSkillsModal;
const modalStyles = {
  modalBackdrop: {
    backgroundColor: '#5d636dcc',
  },
  modalBody: {
    overflow: 'auto',
    margin: '10px',
  },
};
const modalStylesRightSide = {
  modalContainer: {
    display: 'flex',
    width: 'unset',
    bottom: 'unset',
    borderRadius: '12px',
    overflow: 'hidden',
    boxShadow: '0 6px 24px 0 #14141F1F',
    top: '75px',
    left: '46%',
    marginRight: '10px',
  },
  modalBackdrop: {
    display: 'none',
  },
  modalBody: {
    width: '100%',
    maxWidth: '570px',
    overflow: 'auto',
  },
};
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  ${({ $isOnRightSide }) =>
    $isOnRightSide &&
    css`
      @media (max-width: 800px) {
        display: none;
      }
    `}
`;
