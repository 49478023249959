import React from 'react';

const ClearCloseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16">
    <g fill="#282B32" fillRule="evenodd">
      <path d="M-1.6 7.2H18.4V9.2H-1.6z" transform="rotate(45 8.4 8.2)" />
      <path d="M-1.6 7.2H18.4V9.2H-1.6z" transform="scale(-1 1) rotate(45 0 -12.08)" />
    </g>
  </svg>
);

export default ClearCloseIcon;
