import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import { rgba } from 'polished';
import styled, { css } from 'styled-components';

class Modal extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    onExited: PropTypes.func,
    headerText: PropTypes.string,
    footer: PropTypes.node,
    className: PropTypes.string,
    animation: PropTypes.string,
    fullScreen: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    isLanguage: PropTypes.bool,
    open: PropTypes.bool,
    timeout: PropTypes.number,
    noClicker: PropTypes.bool,
    disableOutsideClick: PropTypes.func,
    styles: PropTypes.object,
    openStateBase: PropTypes.bool,
    animationDuration: PropTypes.string,
  };

  handleOutsideClick = (e) => {
    if (this.props.disableOutsideClick) return;
    this.props.onClose(e);
  };

  render() {
    const {
      className,
      fullScreen,
      animation,
      styles = {},
      isLanguage = false,
      openStateBase = false,
      open = false,
      timeout = 600,
      animationDuration = '300ms',
      noClicker = false,
      onExited,
    } = this.props;
    const inConst = openStateBase ? open : true;
    return ReactDOM.createPortal(
      <Fragment>
        <CSSTransition appear in={inConst} unmountOnExit classNames="modal" timeout={timeout} onExited={onExited}>
          <ModalContainer
            className={className}
            animation={animation}
            animationDuration={animationDuration}
            fullScreen={fullScreen}
            style={styles.modalContainer}
            $isLanguage={isLanguage}
            {...(styles?.modalBackdrop?.zIndex && { zIndex: styles.modalBackdrop.zIndex + 1 })}
          >
            {!noClicker && <Clicker onClick={this.handleOutsideClick} />}
            <ModalBody
              style={styles.modalBody}
              $isLanguage={isLanguage}
              {...(styles?.modalBody?.flexGrow && { flexGrow: styles?.modalBody?.flexGrow })}
            >
              {this.props.children}
            </ModalBody>
          </ModalContainer>
        </CSSTransition>
        {inConst && (
          <ModalBackdrop
            style={styles.modalBackdrop}
            {...(styles?.modalBackdrop?.zIndex && { zIndex: styles.modalBackdrop.zIndex })}
          />
        )}
      </Fragment>,
      document.body,
    );
  }
}

const animations = {
  fromRight: css`
    &.modal-enter {
      transform: translate(100%, 0);
    }
    &.modal-enter-active {
      transform: translate(0, 0);
      transition: all ${({ animationDuration }) => animationDuration} ease-out;
    }
    &.modal-exit {
      transform: translate(0, 0);
    }
    &.modal-exit-active {
      transform: translate(100%, 0);
      transition: all ${({ animationDuration }) => animationDuration} ease-in;
    }
  `,
  fromRightLanguage: css`
    &.modal-enter {
      transform: translate(100%, 0);
    }
    &.modal-enter-active {
      transform: translate(0, 0);
      transition: all ${({ animationDuration }) => animationDuration} ease-out;
    }
    &.modal-exit {
      transform: translate(0, 0);
    }
    &.modal-exit-active {
      transform: translate(100%, 0);
      transition: all ${({ animationDuration }) => animationDuration} ease-in;
    }
  `,
  fromBottom: css`
    &.modal-enter {
      transform: translate(0, 100%);
    }
    &.modal-enter-active {
      transform: translate(0, 0);
      transition: all ${({ animationDuration }) => animationDuration} ease-out;
    }
    &.modal-exit {
      transform: translate(0, 0);
    }
    &.modal-exit-active {
      transform: translate(0, 100%);
      transition: all ${({ animationDuration }) => animationDuration} ease-in;
    }
  `,
  fadeIn: css`
    &.modal-appear {
      opacity: 0;
    }
    &.modal-appear-active {
      opacity: 1;
      transition: all ${({ animationDuration }) => animationDuration} ease-out;
    }
    &.modal-exit {
      opacity: 1;
    }
    &.modal-exit-active {
      opacity: 0;
      transition: all ${({ animationDuration }) => animationDuration} ease-in;
    }
  `,
  fadeInLanguage: css`
    &.modal-enter {
      opacity: 0;
    }
    &.modal-enter-done {
      > section {
        bottom: 0 !important;
      }
    }
    &.modal-enter-active {
      opacity: 1;
      transition: all ${({ animationDuration }) => animationDuration} ease-out;
    }
    &.modal-exit {
      opacity: 1;
    }
    &.modal-exit-active {
      opacity: 0;
      transition: all ${({ animationDuration }) => animationDuration} ease-in;
    }
  `,
  empty: css`
    &.modal-appear {
    }
    &.modal-appear-active {
    }
    &.modal-exit {
    }
    &.modal-exit-active {
    }
  `,
};

const ModalBackdrop = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    // prevent these style props to render to the DOM
    !['zIndex'].includes(prop),
})`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: ${(p) => p.theme.zIndex.modalBg};
  ${({ zIndex }) =>
    zIndex &&
    css`
      z-index: ${(zIndex) => zIndex} !important;
    `}
  background: ${(p) => rgba(p.theme.colors.black, 0.1)};
`;

const Clicker = styled(ModalBackdrop)`
  background: transparent;
`;

const ModalContainer = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    // prevent these style props to render to the DOM
    !['zIndex', 'isLanguage', 'fullScreen', 'animationDuration'].includes(prop),
})`
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  left: 0;
  bottom: 0;
  top: 0;
  z-index: ${(p) => p.theme.zIndex.modal};
  ${({ zIndex }) =>
    zIndex &&
    css`
      z-index: ${(zIndex) => zIndex} !important;
    `}
  ${(p) => !p.$isLanguage && (animations[p.animation] || animations.fromRight)}
  ${(p) =>
    p.fullScreen &&
    css`
      width: 100vw;
      height: 100vh;
    `}
  
  ${({ $isLanguage, theme }) =>
    $isLanguage &&
    css`
      ${theme.max('sm')`
        ${animations.fadeInLanguage}
      `}
    `}
`;

const ModalBody = styled.section`
  position: relative;
  flex-grow: ${(p) => (p.flexGrow ? p.flexGrow : 1)};
  z-index: ${(p) => p.theme.zIndex.modal + 1};
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  ${({ $isLanguage, theme }) =>
    $isLanguage &&
    css`
      ${theme.max('sm')`
        position: absolute !important;
        bottom: -500px;
        margin-top: 0 !important;
        border-radius: 20px 20px 0 0 !important;
        max-width: none !important;
        transition: all 0.3s;
      `}
    `}
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: ${(p) => p.theme.colors.gray.regular2};
  }
  ::-webkit-scrollbar {
    width: 10px;
    border-radius: 10px;
    background-color: ${(p) => p.theme.colors.gray.regular2};
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${(p) => p.theme.colors.gray.regular};
    height: 100px;
  }
`;

export default Modal;
