import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { AddIcon } from '/imports/dashboard/ui/assets';
import Box from '/imports/core/ui/atoms/Box';
import Flex from '/imports/core/ui/atoms/Flex';
import { withIntl } from '/imports/core/api/useIntl';

@withIntl
class AddNewResume extends PureComponent {
  static propTypes = {
    createNew: PropTypes.func.isRequired,
    t: PropTypes.func,
    isResume: PropTypes.bool,
  };

  render() {
    const { createNew, t, isResume } = this.props;

    return (
      <ItemCont>
        <Flex direction="column" alignItems="stretch">
          <Box width={isResume ? '55%' : '60%'} exact>
            <AddContent onClick={createNew} $isResume={isResume}>
              <ContentInner>
                <span>
                  <AddIcon />
                </span>
                <p>{t('dashboard.create_new')}</p>
              </ContentInner>
            </AddContent>
          </Box>
        </Flex>
      </ItemCont>
    );
  }
}

AddNewResume.displayName = 'AddNewResume';

const ItemCont = styled.div`
  width: 50%;
  padding: 15px;
  position: relative;
  min-width: 300px;
  max-width: 550px;

  > div {
    align-items: stretch;
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
    padding: 15px;
  }
`;

const AddContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: white;
  cursor: pointer;
  padding-top: 141.702128%;
  border: 1px solid #dfe5eb;
  border-radius: 4px;
  margin-bottom: 60px;
  padding-bottom: ${({ $isResume }) => ($isResume ? '0' : '20px')};
  &:hover {
    box-shadow: 0 23px 23px 0 rgba(28, 29, 50, 0.16);

    p {
      color: #2274b0;
    }

    svg path {
      fill: #2274b0;
    }
  }
`;

const ContentInner = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    margin: -7px 0;
    color: #d0d7de;
    font-size: 13px;
    line-height: 23px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    font-family: ${({ theme }) => theme.font.family.websiteRegular};
  }

  span {
    margin-bottom: 15px;
  }
`;

export default AddNewResume;
