import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Flex from '/imports/core/ui/atoms/Flex';

function ErrorMessage({ title, children }) {
  return (
    <Container>
      <Flex direction="column">
        <Title>{title}</Title>
        {children}
      </Flex>
    </Container>
  );
}

ErrorMessage.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};

ErrorMessage.defaultProps = {
  title: 'Error',
};

const Container = styled.div`
  background-color: hsl(359, 97%, 95%);
  border: 1px solid hsl(359, 97%, 90%);
  color: hsl(359, 97%, 50%);
  border-radius: 4px;
  padding: 1em 1.5em;
  margin: 1em 0;
`;

const Title = styled.h1`
  font-size: 1em;
  color: hsl(359, 75%, 45%);
  margin: 0;
`;

export default ErrorMessage;
