import React, { useEffect, useRef, useState } from 'react';
import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import { debounce } from 'lodash';
import styled, { css } from 'styled-components';

import Container from '/imports/homepageV2/ui/atmos/Container';
import { content_landing_slugs } from '/imports/homepageV2/api/constants';
import CTA from '/imports/homepageV2/ui/components/CTA.js';
import {
  DISABLE_UPLOAD_RESUME_EXP,
  LANDING_TITLE,
  staticFile,
  HIDE_UPLOAD_RESUME,
  without_upload,
  DISABLE_UPLOAD_RESUME_EXP_MOBILE,
  without_upload_mobile,
  DISABLE_UPLOAD_RESUME_EXP_CONTROL_ON_DESK,
} from '/lib/helpers';
import Flex from '/imports/core/ui/atoms/Flex';
import useIntl from '/imports/core/api/useIntl';
import { useResponsive } from '/imports/core/api/responsiveContext';

const SectionHeading = ({
  onClickLandingCTA,
  userIdentified,
  variant,
  isMobileV2 = false,
  isMobileV3 = false,
  isNewMobileDesign = false,
}) => {
  const [maxWidth, setMaxWidth] = useState(0);
  const { t, locale = 'en' } = useIntl();
  const isNewDesign = locale === 'en';
  const { host, isMobile, breakpoint } = useResponsive();
  const ref = useRef(null);
  const slug = content_landing_slugs(host, t);
  const titleRender = ['cv-lite', 'cvtoolspro'];
  const uploadResumeExp = marvelEmitter.getActiveVariant('exp_landing_upload_resume');
  const [width, setWidth] = useState();
  const isDisableWithOutUpload =
    (DISABLE_UPLOAD_RESUME_EXP_MOBILE.includes(locale) && isMobile && !without_upload_mobile.includes(locale)) ||
    (DISABLE_UPLOAD_RESUME_EXP.includes(locale) && !isMobile && !without_upload.includes(locale));
  const disableOnDesk = DISABLE_UPLOAD_RESUME_EXP_CONTROL_ON_DESK.includes(locale) && !isMobile;

  const isLongTranslation =
    locale === 'de' ||
    locale === 'et' ||
    (locale === 'de' && host === 'lebenslaufschreiben') ||
    (locale === 'et' && host === 'cvkoostamine');
  useEffect(() => {
    const elemWidth = document.getElementsByTagName('h1')[0].offsetWidth;
    setWidth(elemWidth);
    const resizeObserver = new ResizeObserver(
      debounce((entries) => {
        for (let entry of entries) {
          setMaxWidth(
            isLongTranslation ? Math.round(entry?.contentRect?.width) + 75 : Math.round(entry?.contentRect?.width),
          );
        }
      }, 100),
    );

    if (!isNewMobileDesign && ref?.current) {
      resizeObserver.observe(ref?.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [ref]);

  const renderTitle = () => {
    const titles = LANDING_TITLE.filter((item) => item.locale == locale)
      .map((item) => item.variant)
      .shift();
    let title;
    if (locale === 'en')
      title = `The AI that makes your CV so convincing that even your mother will think you're qualified !`;
    else
      titles?.length &&
        titles.forEach((item) => {
          const variant = marvelEmitter.getActiveVariant('exp_h1_title_landing');
          switch (variant) {
            case 'control':
              title = t('ai_headline_landing_text2');
              break;
            case item.name:
              title = item.value;
              break;
          }
        });

    return title || t('ai_headline_landing_text2');
  };

  return (
    <Wrapper
      direction="column"
      $fullWidth
      variant={variant}
      $isNewMobileDesign={isNewMobileDesign}
      $isMobileV2={isMobileV2}
      $isMobileV3={isMobileV3}
    >
      <StyledContainer $isNewMobileDesign={isNewMobileDesign}>
        <SectionTitle
          dangerouslySetInnerHTML={{ __html: renderTitle() }}
          ref={ref}
          $isNewMobileDesign={isNewMobileDesign}
          $isMobileV2={isMobileV2}
        />
        <SectionDescription
          $maxWidth={width}
          $isNewMobileDesign={isNewMobileDesign}
          $isMobileV2={isMobileV2}
          $isMobile={isMobile}
        >
          {!isNewDesign ? slug['landing.description'] : t('landing_desc_text')}
        </SectionDescription>
        {!isMobileV3 && (
          <>
            <CTA
              onClick={onClickLandingCTA}
              userIdentified={userIdentified}
              ref={null}
              isHomepage
              breakpoint={breakpoint}
              isNewDesign={isNewDesign}
              maxWidth={maxWidth}
              isNewMobileDesign={isNewMobileDesign}
              isMobileV3={isMobileV3}
              showUploadCTA={
                (uploadResumeExp === 'with_upload' || isDisableWithOutUpload) &&
                !HIDE_UPLOAD_RESUME.includes(locale) &&
                !disableOnDesk
              }
              isLongTranslation={isLongTranslation}
            />
          </>
        )}

        {isNewMobileDesign && (
          <ResumePreviewContainer $isMobileV2={isMobileV2} onClick={onClickLandingCTA}>
            {isMobileV3 && (
              <CTA
                onClick={onClickLandingCTA}
                userIdentified={userIdentified}
                isHomepage
                breakpoint={breakpoint}
                isNewDesign={isNewDesign}
                maxWidth={maxWidth}
                isNewMobileDesign={isNewMobileDesign}
                isMobileV3={isMobileV3}
              />
            )}
          </ResumePreviewContainer>
        )}
      </StyledContainer>
    </Wrapper>
  );
};

const ResumePreviewContainer = styled(Flex)`
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 32px;
  width: 305px;
  height: 307px;
  flex-shrink: 0;
  background-image: url(${staticFile('img/resume_sample_cut.jpeg')});
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 16px 16px 0 0;
  box-shadow: 0px 4px 16px rgba(20, 20, 31, 0.08);
  ${({ $isMobileV2 }) =>
    $isMobileV2 &&
    css`
      background-image: url(${staticFile('img/resume_sample.jpeg')});
      height: 436px;
      border-radius: 16px;
    `}
`;
const StyledContainer = styled(Container)`
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      align-items: end;
    `}
  ${({ $isNewMobileDesign }) =>
    $isNewMobileDesign &&
    css`
      padding: 0 16px !important;
    `}
`;

const Wrapper = styled(Flex)`
  padding-bottom: 140px;
  position: relative;
  ${({ theme }) => theme.max('md')`
    padding: 64px 0;
  `}
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      width: 350px;
      margin: auto;
    `}

  ${({ $isNewMobileDesign, $isMobileV2 }) =>
    $isNewMobileDesign &&
    css`
      padding: ${$isMobileV2 ? '32px 0 40px' : '32px 0 0'} !important;
      background-color: ${$isMobileV2 ? 'white' : '#EDF4FF'} !important;
    `}
    
    ${({ $isMobileV2, $isMobileV3 }) =>
    $isMobileV2 &&
    css`
      &:after {
        content: '';
        padding: 0;
        top: 0;
        position: absolute;
        z-index: -1;
        width: 100%;
        height: ${$isMobileV3 ? '446px' : '506px'};
        border-radius: 0px 0px 100px 0px;
        background: #0062c7;
      }
    `}
`;

const SectionDescription = styled.p`
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 17px;
  line-height: 1.41;
  color: var(--text);
  margin: 0;
  font-weight: 500;
  margin-top: 32px;
  width: 100%;
  text-align: center;
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      margin-top: 24px;
    `}

  ${({ $isNewMobileDesign, $isMobileV2 }) =>
    $isNewMobileDesign &&
    css`
      margin-top: 16px !important;
      align-self: stretch !important;
      color: ${$isMobileV2 ? '#FFF' : '#14141f'};
      font-family: Gilroy Medium;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      padding: 0 20px;
      max-width: unset !important;
    `}
  ${({ $maxWidth, $isMobile }) =>
    $maxWidth &&
    !$isMobile &&
    css`
      max-width: ${$maxWidth}px;
    `}
`;

const SectionTitle = styled.h1`
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 70px;
  line-height: 1.07;
  letter-spacing: -0.75px;
  font-weight: 600;
  color: var(--text);
  margin: 0;
  text-align: center;
  span {
    color: var(--primary);
  }

  ${({ theme }) => theme.max('md')`
    font-size: 50px;
    line-height: 1.2;
    letter-spacing: -0.25px;
  `}
  ${({ $isNewMobileDesign, $isMobileV2 }) =>
    $isNewMobileDesign &&
    css`
      color: ${$isMobileV2 ? '#fff' : '#14141f'} !important;

      text-align: center !important;
      font-family: Gilroy Bold !important;
      font-size: 32px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: 44px !important;
      width: 100%;
    `}
`;

export default SectionHeading;
