import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { CARD_DETAILS_FIELD_STATES } from '/imports/checkout/api/constants';

const PaymentValidationContext = React.createContext();

PaymentValidationContext.displayName = 'PaymentValidationContext';

export function PaymentValidationProvider({ children }) {
  const [fieldsState, setFieldsState] = useState({
    card: CARD_DETAILS_FIELD_STATES.EMPTY,
    cvc: CARD_DETAILS_FIELD_STATES.EMPTY,
    expiry: CARD_DETAILS_FIELD_STATES.EMPTY,
    name: CARD_DETAILS_FIELD_STATES.EMPTY,
  });

  const value = {
    fieldsState,
    setFieldsState,
  };

  return <PaymentValidationContext.Provider value={value}>{children}</PaymentValidationContext.Provider>;
}

PaymentValidationProvider.propTypes = {
  children: PropTypes.node,
};

export const withPaymentValidation = (Component) =>
  class Wrapper extends React.Component {
    static async getInitialProps(ctx) {
      const pageProps = Component.getInitialProps && (await Component.getInitialProps(ctx));
      return { ...pageProps };
    }

    render() {
      return (
        <PaymentValidationContext.Consumer>
          {(state) => <Component {...this.props} {...state} />}
        </PaymentValidationContext.Consumer>
      );
    }
  };

export default PaymentValidationContext;
