import React from 'react';

const PhoneIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
      <path
        id="phone"
        d="M1536,3371.42a0.787,0.787,0,0,1-.25.72l-2.39,2.38a1.443,1.443,0,0,1-.43.31,1.673,1.673,0,0,1-.51.16,0.661,0.661,0,0,0-.11.01,1.552,1.552,0,0,1-.23.01,8.1,8.1,0,0,1-1.11-.12,8.358,8.358,0,0,1-1.87-.58,14.984,14.984,0,0,1-2.51-1.37,23.967,23.967,0,0,1-5.08-4.91,17.056,17.056,0,0,1-1.34-2.1,10.675,10.675,0,0,1-.75-1.75,9.031,9.031,0,0,1-.35-1.35,4.615,4.615,0,0,1-.07-0.89c0.01-.22.02-0.34,0.02-0.36a1.821,1.821,0,0,1,.16-0.52,1.528,1.528,0,0,1,.31-0.42l2.39-2.4a0.788,0.788,0,0,1,.58-0.25,0.648,0.648,0,0,1,.41.13,1.259,1.259,0,0,1,.31.34l1.93,3.66a0.853,0.853,0,0,1,.09.63,1.133,1.133,0,0,1-.31.58l-0.88.88a0.222,0.222,0,0,0-.06.12,0.392,0.392,0,0,0-.03.13,3.77,3.77,0,0,0,.32.87,8.266,8.266,0,0,0,.67,1.05,12.2,12.2,0,0,0,1.28,1.44,11.456,11.456,0,0,0,1.44,1.28,7.722,7.722,0,0,0,1.05.68,3.159,3.159,0,0,0,.65.26l0.23,0.05a0.363,0.363,0,0,0,.11-0.03,0.326,0.326,0,0,0,.12-0.06l1.03-1.05a1.1,1.1,0,0,1,.75-0.29,0.961,0.961,0,0,1,.49.11h0.02l3.47,2.06A0.84,0.84,0,0,1,1536,3371.42Z"
        transform="translate(-1519 -3358)"
      />
    </svg>
  );
};

export default PhoneIcon;
