const CrossIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.7071 14.7071C27.0976 14.3166 27.0976 13.6834 26.7071 13.2929C26.3166 12.9024 25.6834 12.9024 25.2929 13.2929L20 18.5858L14.7071 13.2929C14.3166 12.9024 13.6834 12.9024 13.2929 13.2929C12.9024 13.6834 12.9024 14.3166 13.2929 14.7071L18.5858 20L13.2929 25.2929C12.9024 25.6834 12.9024 26.3166 13.2929 26.7071C13.6834 27.0977 14.3166 27.0977 14.7071 26.7071L20 21.4142L25.2929 26.7071C25.6834 27.0976 26.3166 27.0976 26.7071 26.7071C27.0976 26.3166 27.0976 25.6834 26.7071 25.2929L21.4142 20L26.7071 14.7071Z"
        fill="#4C4C55"
      />
    </svg>
  );
};
export default CrossIcon;
