import React from 'react';
import styled from 'styled-components';

const DownloadIcon = () => {
  return (
    <span>
      <SvgIcon width="17" height="17" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.8 24.9">
        <path d="M24.4 17.9v5.6c-.1.5-.6.9-1.1.9H1.5c-.6 0-1.1-.4-1.1-1v-5.5c0-.6.5-1.1 1.1-1.1.3 0 .6.1.8.3.2.2.3.5.3.8v3.4c0 .6.4 1 1 1h17.5c.6 0 1-.4 1-1v-3.4c0-.6.5-1.1 1.1-1.1.3 0 .6.1.8.3.3.2.4.5.4.8z" />
        <path d="M18.9 12.7l-5.6 5.5c-.2.2-.5.3-.7.4h-.1-.2-.1c-.3 0-.5-.2-.7-.4l-5.6-5.5c-.3-.3-.4-.6-.4-.9 0-.3.1-.7.4-.9.5-.5 1.4-.5 1.9 0l3.3 3.3.1-12.4c0-.7.6-1.3 1.3-1.3.4 0 .7.1.9.4.2.2.4.6.4.9l-.1 12.4 3.3-3.3c.5-.5 1.4-.5 1.9 0 .6.4.6 1.2 0 1.8z" />
      </SvgIcon>
    </span>
  );
};

const SvgIcon = styled.svg`
  stroke: none;
`;

export default DownloadIcon;
