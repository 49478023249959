import React from 'react';

const CircleRightIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24">
      <path
        fill="#1688FE"
        fillRule="evenodd"
        d="M12.957 1c6.075 0 11 4.925 11 11s-4.925 11-11 11-11-4.925-11-11 4.925-11 11-11zm0 2c-4.97 0-9 4.03-9 9s4.03 9 9 9 9-4.03 9-9-4.03-9-9-9zm3.293 5.293l1.414 1.414-6.707 6.707-3.707-3.707 1.414-1.414 2.293 2.293 5.293-5.293z"
      />
    </svg>
  );
};

export default CircleRightIcon;
