import styled, { css } from 'styled-components';

const SectionTitle = styled.h1`
  ${({ theme: { min } }) => css`
    margin: -16px 0 23px;
    font-weight: 400;
    font-size: 50px;
    line-height: 1.25;
    font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
    font-weight: normal;
    letter-spacing: normal;
    color: #0099da;
    ${min('sm')`
    font-size: 50px;
  `}
    @media (max-width: 1500px) {
      margin: 0 0 16px;
      line-height: 1.25;
    }
  `}
`;

export default SectionTitle;
