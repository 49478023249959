import React, { PureComponent } from 'react';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import TextareaEditorWithData from '/imports/generator/ui/atoms/TextareaEditorWithData';

class TextareaEditorWithDataState extends PureComponent {
  static propTypes = {
    placeholder: PropTypes.string,
    lastJob: PropTypes.string,
  };

  state = {
    search: '',
    area: this.props.lastJob || '',
    loading: true,
  };

  componentDidMount() {
    this.setState({ loading: false });
  }

  onChange = debounce((search) => {
    this.setState({ search });
  }, 500);

  onSelect = (area) => {
    this.setState({ area });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.lang !== this.props.lang) {
      this.setState({ search: '', area: '' });
    }
  }

  render() {
    const { search, area, loading } = this.state;
    const { placeholder } = this.props;

    if (loading) return <Loading>{placeholder}</Loading>;

    return (
      <TextareaEditorWithData
        {...this.props}
        onDataChange={this.onChange}
        onDataSelect={this.onSelect}
        search={search}
        area={area}
      />
    );
  }
}

TextareaEditorWithDataState.displayName = 'TextareaEditorWithDataState';

const Loading = styled.div`
  position: relative;
  height: 300px;
  background: ${(p) => p.theme.colors.gray.lighter};
  border: solid 1px #e1e5e8;
  padding: 15px 50% 15px 15px;
  line-height: 1.7;
  color: ${(p) => p.theme.colors.gray.regular};
  font-size: 12px;
  &:after {
    content: 'Loading...';
    font-size: 12px;
    padding: 15px;
    color: ${(p) => p.theme.colors.gray.regular};
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 245px;
    background: white;
  }
`;

export default TextareaEditorWithDataState;
