import React from 'react';
import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import styled from 'styled-components';

import { homepageMainSection } from '/imports/homepage/api/constants';
import { LANDING_CONTENT } from '/imports/landing/api/constants';
import { TitleWrapper, SectionTitle, SectionDescription } from '/imports/landing/ui/atoms';
import useIntl from '/imports/core/api/useIntl';
import { useResponsive } from '/imports/core/api/responsiveContext';

const SectionHeading = ({ page }) => {
  const { t, locale = 'en' } = useIntl();
  const { host } = useResponsive();

  const getHeadingElegantEN = () => (
    <TitleWrapper>
      Use The <span>Best</span> <br /> <span>Online CV Builder Elegant CV App </span>
    </TitleWrapper>
  );

  const getHeadingES = () => (
    <TitleWrapper>
      Crea un <span>Currículum</span> Profesional <br /> <span>Rápido y Fácil</span>
    </TitleWrapper>
  );

  const getHeadingID = () => (
    <TitleWrapper>
      Coba yang <span>Terbaik</span> <br /> <span>Pembuat Resume Online</span>
    </TitleWrapper>
  );

  const renderTitle = () => {
    switch (locale) {
      case 'en':
        if (marvelEmitter.getActiveVariant('exp_old_landing_text') === 'ai_text')
          return (
            <TitleWrapper>
              Build Your <span>Perfect Resume</span> <br /> with <span>AI-Powered</span> Tools
            </TitleWrapper>
          );
        else
          return (
            <TitleWrapper>
              Try The <span>Best</span> <br /> <span>Resume Builder Online</span>
            </TitleWrapper>
          );
      case 'es':
        return getHeadingES();
      case 'id':
        return getHeadingID();
      default:
        return <TitleWrapper dangerouslySetInnerHTML={{ __html: t('landing.title') }} />;
    }
  };

  const getTitle = () => {
    if (locale === 'en' && host === 'elegantcv') return getHeadingElegantEN();
    return renderTitle();
  };

  const getDescription = () => {
    if (locale === 'en' && host === 'elegantcv') return "You Can Reach Your Goals With Our Professional CV's";
    if (marvelEmitter.getActiveVariant('exp_old_landing_text') === 'ai_text') return t('landing_desc_text');
    else return t('landing.description');
  };

  return (
    <Container>
      <SectionTitle>{getTitle()}</SectionTitle>
      <SectionDescription>{getDescription()}</SectionDescription>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 0 20px;
  text-align: center;

  ${({ theme }) =>
    theme.min('sm')`
      padding: 0 40px;
    `}
`;

export default SectionHeading;
