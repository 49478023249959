import ImageWrapper from '../atoms/ImageWrapper';
import styled from 'styled-components';
import { getArticlesImages } from 'imports/help/api/articlesImages';
import { StyledText, StyledTextWithSpan } from '../atoms/Typography';
import { useResponsive } from 'imports/core/api/responsiveContext';

import ArticlePageWrapper from '/imports/help/ui/components/ArticlePageWrapper';
import useIntl from '/imports/core/api/useIntl';

const ChangeResumeFont = () => {
  const { t } = useIntl();
  const { host } = useResponsive();
  const images = getArticlesImages(host);
  return (
    <ArticlePageWrapper title="help.change_resume_font">
      <StyledText>{t('help.change_resume_font.change_resume_fonts')}</StyledText>
      <ImageWrapper src={images.change_font} />
      <StyledTextWithSpan noMargin>{t('help.change_resume_font.dropdown_description')}</StyledTextWithSpan>
      <StyledListContainer>
        <StyledListItem>
          <StyledTextWithSpan
            marginBottom={12}
            dangerouslySetInnerHTML={{ __html: t('help.change_resume_font.title_font') }}
          />
        </StyledListItem>
        <StyledListItem>
          <StyledTextWithSpan
            marginBottom={12}
            dangerouslySetInnerHTML={{ __html: t('help.change_resume_font.body_font') }}
          />
        </StyledListItem>
      </StyledListContainer>
      <StyledTextWithSpan>{t('help.change_resume_font.note')}</StyledTextWithSpan>
    </ArticlePageWrapper>
  );
};

const StyledListContainer = styled.ol`
  > li::marker {
    font-family: ${({ theme }) => theme.font.family.websiteMedium};
    color: #33334f;
  }
  margin: 0;
  padding-left: 24px;
`;

const StyledListItem = styled.li``;

export default ChangeResumeFont;
