import React, { Fragment } from 'react';
import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import BlockMobileControls from '/imports/generator/ui/components/BlockMobileControls';
import { FIXED_SUMMARY_TEMPLATES } from '/imports/generator/api/constants';
import { OutlinedDeleteIcon, UpArrow, LeftToRightIcon, RightToLeftIcon } from '/imports/generator/ui/assets';
import { PencilIcon } from '/imports/core/ui/assets';
import { SPLIT_SKILLS_BLOCK } from '/imports/pdf/core/api/constants';
import useIntl from '/imports/core/api/useIntl';
import useTracking from '/imports/core/hooks/useTracking';
import { getBlockTranslationType } from 'imports/generator/api/helpers';
import { handleSelect } from 'imports/core/ui/helpers';

let mainBlock = ['PROFESSIONAL_SUMMARY', 'SOCIAL_LINKS', 'SKILLS', 'EMPLOYMENT', 'EDUCATION'];
const BlockControls = (props) => {
  const { t } = useIntl();
  const {
    block: { type },
    resume,
    titleEditMode,
  } = props;

  const { trackEvent } = useTracking();
  const isProfessionaSummary = type === 'PROFESSIONAL_SUMMARY';
  const isRenameSectionTitle =
    marvelEmitter.getActiveVariant('exp_rename_section_title') === 'with_rename_section_title';

  const toggleEditMode = () => {
    const { toggleEditMode, onAfterAction } = props;
    toggleEditMode();
    if (onAfterAction) onAfterAction();
  };

  const moveUp = () => {
    const { moveUp, onAfterAction } = props;
    moveUp();
    if (onAfterAction) onAfterAction();
  };

  const moveDown = () => {
    const { moveDown, onAfterAction } = props;
    moveDown();
    if (onAfterAction) onAfterAction();
  };

  const moveLeft = () => {
    const {
      moveLeft,
      onAfterAction,
      resume: {
        settings: { template },
      },
    } = props;
    moveLeft();
    trackEvent('column_change', { block: type, direction: 'left', template });
    if (onAfterAction) onAfterAction();
  };

  const moveRight = () => {
    const {
      moveRight,
      onAfterAction,
      resume: {
        settings: { template },
      },
    } = props;

    moveRight();
    trackEvent('column_change', {
      block: type,
      direction: 'right',
      template,
    });
    if (onAfterAction) onAfterAction();
  };

  const remove = () => {
    const { remove, onAfterAction } = props;
    remove();
    if (onAfterAction) onAfterAction();
  };

  const {
    block: { id, required, fixedPosition, position },
    resume: {
      blocks,
      settings: { template },
    },
    isMobile = false,
    onAfterAction,
  } = props;

  const hideControls =
    (required && fixedPosition) || (required && isProfessionaSummary && FIXED_SUMMARY_TEMPLATES.includes(template));
  const currentColumn = blocks.filter((b) => b.position[0] === position[0]);
  let CustomBlocks = ['CUSTOM', ...SPLIT_SKILLS_BLOCK, 'CUSTOM_SKILLS_CATEGORY'];
  const isCustom = CustomBlocks.includes(type) || isRenameSectionTitle;
  const prevBlocks = currentColumn.filter((b) => b.position[1] < position[1]);
  const prevBlock = prevBlocks[prevBlocks.length - 1];
  if (hideControls) return null;

  const showUp = position[1] > 0 && prevBlocks.length && !prevBlock.fixedPosition;
  const showDown = position[1] + 1 < currentColumn.length;
  const showRight = position[0] === 0;
  const showLeft = position[0] === 1;
  const hideControlsExp = true;
  if (isMobile)
    return (
      <BlockMobileControls
        isCustom={isCustom}
        showUp={showUp}
        showDown={showDown}
        showLeft={showLeft}
        showRight={showRight}
        toggleEditMode={toggleEditMode}
        moveUp={moveUp}
        moveDown={moveDown}
        moveLeft={moveLeft}
        moveRight={moveRight}
        remove={remove}
        onAfterAction={onAfterAction}
        t={t}
        required={required}
        isMainBlock={mainBlock.includes(type)}
      />
    );
  return (
    <Fragment>
      {isCustom && (
        <Button
          edit
          unstyled
          onClick={() => {
            toggleEditMode();
            setTimeout(() => {
              handleSelect(document.activeElement);
            }, 200);
          }}
          data-is-editing={titleEditMode}
          id={`custom-title-edit-${id}`}
          isRenameSectionTitle={isRenameSectionTitle}
        >
          <PencilIcon />
        </Button>
      )}
      {!hideControlsExp && (
        <Fragment>
          {!!showUp && (
            <Button strokeHover unstyled onClick={moveUp}>
              <UpArrow />
            </Button>
          )}
          {showDown && (
            <Button strokeHover unstyled onClick={moveDown}>
              <DownArrowCont>
                <UpArrow />
              </DownArrowCont>
            </Button>
          )}
          {showRight && (
            <MainWrapper>
              <Wrapper>
                <Button strokeHover unstyled onClick={moveRight}>
                  <LeftToRightIcon />
                </Button>
              </Wrapper>
            </MainWrapper>
          )}
          {showLeft && (
            <MainWrapper>
              <Wrapper>
                <Button unstyled onClick={moveLeft}>
                  <RightToLeftIcon />
                </Button>
              </Wrapper>
            </MainWrapper>
          )}
        </Fragment>
      )}

      {!required && !mainBlock.includes(type) && (
        <Button delete unstyled onClick={remove} isRenameSectionTitle={isRenameSectionTitle}>
          <OutlinedDeleteIcon />
        </Button>
      )}
    </Fragment>
  );
};

BlockControls.propTypes = {
  toggleEditMode: PropTypes.func,
  moveUp: PropTypes.func,
  moveDown: PropTypes.func,
  moveRight: PropTypes.func,
  moveLeft: PropTypes.func,
  remove: PropTypes.func,
  onAfterAction: PropTypes.func,
  block: PropTypes.object,
  resume: PropTypes.object,
  isMobile: PropTypes.bool,
  titleEditMode: PropTypes.bool,
};

const DeleteWrapper = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  &:hover {
    svg {
      path {
        fill: var(--text);
      }
    }
  }
`;
const Button = styled.div`
  display: inline;
  cursor: pointer;
  width: max-content;
  height: max-content;
  margin-right: 10px;
  display: flex;
  svg {
    transform: scale(1.3);
    :hover {
      color: #1688fe;
    }
  }
  ${({ edit }) =>
    edit &&
    css`
      svg {
        fill: rgb(191, 197, 214);
      }

      &:hover svg path {
        fill: #1688fe;
      }
    `}

  ${({ delete: Delete }) =>
    Delete &&
    css`
      svg:hover rect {
        fill: #1688fe;
      }
    `}

  ${({ strokeHover }) =>
    strokeHover &&
    css`
      svg {
        path {
          stroke: rgb(191, 197, 214);
        }
      }

      :hover svg path,
      :hover svg rect {
        stroke: #1688fe;
      }
    `}
  ${({ isRenameSectionTitle }) =>
    isRenameSectionTitle &&
    css`
      svg {
        opacity: 0;
        fill: #bfc5d6;
      }

      &:hover svg {
        opacity: 1;
        path {
          fill: #1688fe;
        }
      }
    `}
  
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      svg {
        margin-left: 0 !important;
        margin-right: 10px;
      }
    `}
`;

const DownArrowCont = styled.span`
  svg {
    transform: scale(-1.3);
    margin-left: 10px;
    margin-top: 7px;
  }
`;

const MainWrapper = styled.div`
  position: relative;
`;

const Wrapper = styled.div`
  position: relative;
`;

const IconWrapper = styled.div`
  display: flex;
`;

const SvgWrapper = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  &:hover {
    svg {
      path {
        fill: var(--text);
      }
    }
  }
`;
export default BlockControls;
