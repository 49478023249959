import gql from 'graphql-tag';

import { Subscription } from '/imports/checkout/api/apollo/client/fragments';

export const GET_SUBSCRIPTION = gql`
  query {
    getSubscription {
      ...Subscription
    }
  }
  ${Subscription}
`;
