export const footerLinks = (domain, language) => {
  switch (domain) {
    case 'mysmartcv':
      return [
        { href: '/resume-template', text: 'Resume template', show: ['en'] },
        { href: '/template-cv', text: 'CV template', show: ['en'] },
        { href: '/sample-cv', text: 'CV sample', show: ['en'] },
        { href: '/cv-caregiver', text: 'CV Caregiver', show: ['en'] },
        { href: '/cv-student', text: 'Student CV', show: ['en'] },
        { href: '/cv-spanish', text: 'Spanish CV', show: ['en'] },
        { href: '/cv-english', text: 'English CV', show: ['en'] },
        { href: '/terms-and-conditions', text: '', show: ['en', 'ar', 'id', 'es'], slug: 'footer.terms_conditions' },
        { href: '/privacy-policy', text: '', show: ['en', 'ar', 'id', 'es'], slug: 'footer.privacy_policy' },
        { href: '/contact-us', text: '', show: ['en', 'ar', 'id', 'es'], slug: 'header.contact_us' },
      ].filter((l) => l.show.includes(language));
    case 'resumedone':
    default:
      return [
        { href: '/terms-and-conditions', text: '', slug: 'footer.terms_conditions' },
        { href: '/privacy-policy', text: '', slug: 'footer.privacy_policy' },
        { href: '/contact-us', text: '', slug: 'header.contact_us' },
        { href: '/checkout', text: '', slug: 'home_pricing' },
        { href: '/cancel-subscription', text: '', slug: 'contact_cancel_subscription_title' },
      ];
  }
};

export const headerLinks = {
  cvdeboss: [
    { href: '/template-cv', text: 'Template de CV' },
    { href: '/modele-cv', text: 'Modèle de CV' },
    { href: '/exemple-cv', text: 'Exemple de CV' },
    { href: '/cv-aide-soignante', text: 'CV Aide Soignante' },
    { href: '/cv-restauration', text: 'CV Restauration' },
    { href: '/cv-etudiant', text: 'CV Etudiant' },
    { href: '/cv-espagnol', text: 'CV Espagnol' },
    { href: '/cv-anglais', text: 'CV Anglais' },
  ],
};

export const homepageMainSection = {
  mysmartcv: {
    id: {
      headline: 'Jadikan Resume Anda Di Tumpukan <span>Paling Atas</span>',
      subText: 'Hanya 2% resume yang berhasil melewati babak pertama. Gunakan pembuat resume online terbaik.',
      CTAButtonText: 'Buat CV Terbaik Saya',
      steps: [
        'Pilih <span>Template Teratas</span> Anda',
        'Pilih Teks yang <span>Telah Dibuat Otomatis</span>',
        'Kami Membantu anda <span>Setiap Langkah</span>',
      ],
    },
    es: {
      headline: 'Lleva <span>tu currículum</span> <br /> <span> a lo más alto de la cumbre </span>',
      subText: 'Solo el 2% de los currículums pasan de la primera ronda. Usa el mejor creador de currículums en línea.',
      CTAButtonText: 'Crear Mi Mejor CV',
      steps: [
        'Selecciona tu<span> plantilla ganadora</span>',
        'Elije <span>el texto generado automáticamente </span>',
        'Te ayudamos <span>en cada paso del camino</span>',
      ],
    },
  },
  cvdeboss: {
    default: {
      headline: 'Créez facilement <span>votre CV</span> <br /> <span> en ligne</span>',
    },
    'template-cv': {
      headline: 'Template de CV: <span>le plus facile </span> <br /> <span> pour bien démarrer</span>',
    },
    'modele-cv': {
      headline: '<span>Modèle de CV:</span> <br /> <span> comment le choisir?</span>',
    },
    'exemple-cv': {
      headline: 'Bien choisir son <span>exemple de CV</span> <br /> <span> en ligne</span>',
    },
    'cv-aide-soignante': {
      headline: 'CV d’aide-soignant(e): nos conseils pour un <span>CV parfait</span>',
    },
    'cv-restauration': {
      headline: '<span>CV restauration:</span> <br /> <span> idées et conseils pratiques</span>',
    },
    'cv-etudiant': {
      headline: 'Comment réussir votre <span>CV étudiant?</span>',
    },
    'cv-espagnol': {
      headline: 'Rédigez facilement votre <span>CV espagnol</span>',
    },
    'cv-anglais': {
      headline: '<span>CV anglais:</span> <br /> <span>toutes les règles à connaître</span>',
    },
  },
};

export const homepageMeta = {
  cvdeboss: {
    'template-cv': {
      title: 'Templates de CV à télécharger et imprimer – Word et PDF',
      description:
        'Templates de CV design et modernes à personnaliser en ligne. Téléchargement rapide et facile en format Word et PDF.',
    },
    'modele-cv': {
      title: 'Modèles de CV personnalisables à télécharger – Word et PDF',
      description:
        'Besoin d’un CV? Profitez d’un large choix de modèles de CV en ligne. Modernes et personnalisables, à télécharger au format Word ou PDF.',
    },
    'exemple-cv': {
      title: 'Les meilleurs exemples de CV en ligne – Format Word et PDF',
      description:
        'Un large choix d’exemples de CV en ligne. Efficaces, modernes et simples à personnaliser. Téléchargement Word et PDF en un clic.',
    },
    'cv-aide-soignante': {
      title: 'CV aide-soignant(e) en ligne | Exemples et modèles',
      description:
        'Rédigez votre CV d’aide-soignant(e) - Conseils, exemples et modèles pour profils expérimentés et débutants. Téléchargement Word et PDF.',
    },
    'cv-restauration': {
      title: 'CV Restauration – Hôtellerie | Exemples à télécharger',
      description:
        'Modèles de CV restauration - hôtellerie en ligne. Serveur, cuisinier, employé polyvalent… Découvrez tous les exemples adaptés à votre métier !',
    },
    'cv-etudiant': {
      title: 'Réussir son CV étudiant | Guide & Outil de création en ligne',
      description:
        'Rédigez simplement votre CV étudiant grâce à nos modèles et templates design à personnaliser. Customisation et téléchargement en ligne.',
    },
    'cv-espagnol': {
      title: 'CV Espagnol | Conseils de rédaction et modèles',
      description:
        'Votre CV espagnol en quelques clics. Différences avec le CV français, conseils et mentions obligatoires. Modèles faciles à personnaliser et à télécharger.',
    },
    'cv-anglais': {
      title: 'CV Anglais | Modèles et templates en téléchargement',
      description:
        'Comment rédiger un CV anglais : rubriques obligatoires, équivalents anglophones, modèles en ligne à personnaliser et à télécharger.',
    },
  },
  mysmartcv: {
    en: {
      title: 'My Smart CV - Easy Online CV Maker',
      description:
        'MySmartCV: Best and Easiest CV and Cover Letter Builder Online. Make a CV in minutes thanks to our professional templates',
    },
    id: {
      title: 'Pembuat CV Onlie',
      description: 'Buat Dengan My Smart CV',
    },
    es: {
      title: 'Creador de CV Online',
      description: 'Crea con My Smart CV',
    },
    'sample-cv': {
      en: {
        description:
          'You will need the best resume example that will serve as your basis in making an effective CV. Here are the reasons and things that you shouldconsider.',
      },
      id: {
        description:
          'Anda akan membutuhkan contoh resume terbaik yang akan menjadi dasar dalam membuat CV yang efektif. Berikut alasan dan hal yang harus anda pertimbangkan.',
      },
      es: {
        description:
          'Necesitarás el mejor ejemplo de currículum que te sirva de base para hacer un currículum eficaz. Aquí están las razones y las cosas que debes considerar.',
      },
    },
    'cv-caregiver': {
      en: {
        description:
          'Do you want to up your game in creating a perfect caregiver CV? You will be guided with different effective tips to land that dream job you always aim for.',
      },
      id: {
        description:
          'Apakah anda ingin meningkatkan kemampuan dalam membuat CV pengasuh yang sempurna? Anda akan dibimbing dengan berbagai tips yang efektif dan berbeda untuk mendapatkan pekerjaan impian yang selalu anda inginkan',
      },
      es: {
        description:
          '¿Quieres mejorar tu habilidad para crear un perfecto CV de cuidador/a? Se te guiará con diferentes consejos efectivos para conseguir el trabajo de tus sueños al que siempre aspiras.',
      },
    },
    'cv-spanish': {
      en: {
        description:
          'Are you not sure how to start your Spanish CV? Here are some of the details you should include in completing an effective resume that could get you hired.',
      },
      id: {
        description:
          'Apakah anda tidak yakin bagaimana memulai CV bahasa Spanyol anda? Berikut adalah beberapa rincian yang harus anda sertakan dalam menyelesaikan resume efektif yang dapat membuat lamaran anda diterima.',
      },
      es: {
        description:
          '¿No estás seguro de cómo empezar tu CV en español? Estos son algunos de los detalles que debes incluir al completar un currículum eficaz que podría hacer que te contraten.',
      },
    },
  },
};

export const homepageContent = {
  cvdeboss: {
    default: [
      `
Faire son CV n’a jamais été aussi facile ! Avec notre service numérique, vous **disposez d’un outil puissant et d’une grande bibliothèque de modèles pour élaborer votre CV en ligne en seulement quelques minutes**. 

Découvrez les fonctionnalités de notre outil et la richesse de nos modèles de CV, et donnez-vous les moyens d’une candidature parfaite pour votre prochain job.
  
## Les avantages du CV en ligne
  
Faire un CV sur le web présente de très nombreux avantages par rapport aux méthodes traditionnelles. Profitez de notre générateur de CV pour :
  
* **Changer de design d’un simple clic**
* Exporter facilement votre CV sous différents formats
* Générer et sauvegarder plusieurs types de CV selon l’objectif de votre candidature
* Modifier très facilement vos informations et expériences
* Partager un PDF sans difficulté sur diverses plateformes ou sites
* Avoir toujours des modèles et des templates efficaces – et qui vous ressemblent !

L’adaptabilité d’un CV numérique est bien sûr le point fort essentiel : un nouveau diplôme ou une nouvelle formation, une mission à ajouter à votre expérience, un ordre de rubriques à changer, l’envie de nouvelles couleurs, une nouvelle photo ? En quelques secondes, sans aucun outil particulier, vous modifiez ce que vous souhaitez.
`,

      `
## Un outil puissant, un résultat unique

Créer son CV exige de votre part un vrai travail de réflexion sur votre parcours professionnel et des efforts pour synthétiser vos différentes expériences sur un support unique. Il est donc important de se concentrer sur cet aspect et de ne pas avoir à gérer des problèmes techniques, des soucis de mise en page, ou de création graphique.
  
C’est pourquoi nous avons créé notre outil de création de CV en ligne : pour vous offrir les moyens de **vous libérer des aspects techniques du CV afin que vous puissiez vous consacrer à l’essentiel**, au fond plus qu’à la forme, c’est à dire mettre en valeur votre profil et votre parcours.
  
Notre service vous permet ainsi de **créer le CV parfait pour augmenter considérablement vos chances de décrocher un emploi, un stage, une nouvelle mission ou de postuler à une formation**.
`,
      `
## Des ressources infinies pour trouver le bon modèle de CV

Le CV passe-partout, très standardisé et plutôt banal est inefficace et n’est plus du tout d’actualité. Aujourd’hui, pour sortir du lot, il vaut mieux avoir un CV spécifique, adapté et original. Non seulement il aidera à distinguer votre candidature de celles des autres, mais il vous permettra de mettre en relief, d’un seul coup d’œil, vos compétences et vos atouts.

De plus, d’un métier à un autre, le CV ne peut et ne doit pas être le même. C’est pourquoi vous trouverez chez nous un très grand nombre de modèles de CV adaptés spécifiquement à un métier ou une filière professionnelle.

Enfin, votre CV en ligne peut aussi facilement jouer la carte de l’originalité et de la modernité. Vous pouvez varier à l’infini les couleurs, les graphismes et **concocter le CV en ligne** design de vos rêves, pour mieux séduire les recruteurs.
`,
      `
## Un outil polyvalent pour faire votre CV

Que vous ayez besoin d’un CV en ligne Word ou d’un CV en ligne PDF, ou encore de l’éditer dans un autre format, notre service de création de CV en ligne vous permet très facilement de choisir votre format de fichier. Vous avez ainsi la possibilité de créer de manière très simple votre CV en ligne gratuit.

Avec notre service de création de CV en ligne, non seulement vous gagnez du temps, mais vous disposez aussi de conseils de rédaction, de suggestions avec du contenu pré-rédigé et modifiable.

Vous n’avez **pas besoin d’un outil à télécharger, ni d’un logiciel à installer sur votre ordinateur**. Vous pouvez modifier votre document autant de fois que vous le souhaitez, depuis n’importe où, à partir du moment où vous disposez d’une connexion internet !

La création de votre curriculum vitae ne sera plus une source de frustration et d’anxiété en utilisant notre créateur de CV. Vous gagnez du temps et vous êtes assuré d’obtenir un résultat professionnel attrayant : mettez en valeur votre parcours et multipliez vos chances de décrocher l’emploi de vos rêves !
`,
    ],
    'template-cv': [
      `
Rédiger son CV suppose de la méthode, mais surtout de partir sur une base qualitative. Un template de CV est donc l’idéal pour bien commencer !

C’est le meilleur moyen de démarrer son CV sans perdre de temps, sans hésiter sur les rubriques et sans faire d’erreurs de mise en page. **Découvrez comment nos templates de CV peuvent vous faire gagner un temps précieux - et comment choisir celui qui épatera les recruteurs !**

## Qu’est-ce qu’un template de CV ?

Un template de CV est ni plus ni moins qu’un exemple sur lequel on peut se baser pour construire son propre CV. C'est donc **une trame dans laquelle il vous suffira de modifier le contenu**.

Vous pourrez bien sûr changer la photo et inclure la vôtre, modifiez les données personnelles et également le contenu des différentes rubriques : formations, expériences, compétences, intérêts. **Nos templates de CV sont facilement modifiables** et vous pouvez y puiser des tournures de phrases pour mieux rédiger votre contenu, ou vous inspirer de nos en-têtes de section pour mieux mettre en avant votre profil.
`,
      `
## Le template de CV : le travail simplifié

Élaborer un bon CV est crucial pour trouver un travail rapidement. Et cette étape de construction du CV n'est pas une chose facile : elle est même source de stress et consomme beaucoup de temps et d’énergie. 

Alors comment faire pour que rédiger son CV soit plus simple et plus efficace ?

Partir d’un template est déjà le début de la bonne réponse. Choisir un template de [CV en ligne](/) parmi une sélection de modèles de qualité vous assurera une vraie longueur d’avance sur les autres candidats !

Voici pourquoi travailler à partir d’un template de CV vous simplifie la tâche :

* Vous partez d’un **modèle éprouvé et déjà expérimenté auprès des recruteurs**.
* Vous n’avez pas à imaginer le design de votre CV vous-même.
* Vous n’avez qu’à suivre la trame du CV et saisir vos informations.
* Vous pouvez **faire n’importe quelles modifications** (couleurs, tailles de police, ordre des blocs) d’un simple clic.
* Vous n’avez **rien à ajouter** : toutes les sections nécessaires pour faire un CV efficace sont là.
`,
      `
## Comment choisir votre template de CV ?
Avec quel template de CV démarrer ? Voici quelques conseils qui devraient vous aider dans votre choix.

### Questionnez vos goûts et votre personnalité

**Votre CV doit refléter ce que vous êtes**. Il est donc important qu’il vous plaise et vous corresponde. N’hésitez donc pas à aller vers les couleurs que vous aimez et choisissez un style qui vous ressemble. 

Que vous choisissiez un template de CV simple ou au design plus original, il est important que vous soyez fier de votre CV et de l’image qu’il véhicule de vous. Attention toutefois à ce que votre CV ne devienne pas trop fantaisiste : cela reste un document professionnel qui ne peut pas trop faire dans le fantasque !

### Adaptez le contenu à votre branche professionnelle

Chaque secteur d’activité possède ses codes et ses prérequis. Il est souvent impossible de ne pas s’y conformer. Ainsi, dans le monde des développeurs informatiques, il sera essentiel de passer en revue de manière synthétique vos compétences dans tel ou tel langage. Les modèles correspondant à ce genre de métiers comportent donc de telles sections. 

Selon votre activité professionnelle, il peut donc être important que vous choisissiez un CV spécifiquement conçu pour celle-ci.

### Choisissez une structure adaptée à votre expérience

Si vous débutez dans votre activité professionnelle et que vous venez juste de finir vos études, choisissez un type de CV qui fait la part belle à la formation. Ce sera nécessaire pour mettre en évidence la logique de vos études, votre diplôme, vos formations annexes et votre projet.

En revanche, si vos études sont déjà loin, penchez pour un CV qui vous permettra de détailler votre parcours, vos missions et vos responsabilités. Préciser le champ de celles-ci en veillant à définir en quelques chiffres clés les résultats que vous avez obtenus. Une section consacrée à la formation professionnelle, et aux compétences acquises au cours de votre carrière, sera aussi nécessaire.
`,
    ],
    'modele-cv': [
      `
Créer un CV qui soit beau et efficace n’est pas simple. Et tout le monde n’a pas une âme de graphiste ou des compétences en design. C’est pourquoi **utiliser un modèle de CV est certainement le meilleur moyen de disposer rapidement d’un document personnalisé** pour vos candidatures. 

Grâce à notre bibliothèque de [CVs en ligne](/), profitez d’un large choix de modèles, dans tous les styles et pour tous les profils. Il vous suffira juste d’un peu de méthode pour choisir le plus approprié à votre profil, votre expérience et votre métier.

## Pourquoi utiliser un modèle de CV ?

Pour créer votre CV, vous pouvez bien sûr partir d’une page blanche. Mais vous risquez de vous heurter à de nombreuses questions : comment trouver une police lisible et impactante, et des coloris assortis, aligner correctement les blocs de texte... Un vrai casse tête ! Utilisez un modèle en ligne et bénéficiez d’une foule d’avantages :

* Un **gain de temps** : concevoir le design et la structure d’un CV prend du temps. Avec un modèle de CV, vous pouvez consacrer ce temps gagné à travailler le contenu de votre CV plutôt que sa forme.
* Un **design moderne** : nos modèles de CV design sont sans cesse améliorés et renouvelés pour suivre les tendances. 
* Un **grand choix de coloris et mises en page** : vous n’êtes pas inspiré ? Faites défiler nos modèles de CV et, parmi les nombreuses propositions, vous en trouverez forcément un (ou plusieurs !) qui vous plaira.
* Un **document facile à personnaliser** : utiliser un modèle de CV ne signifie pas un CV banal ou commun. Vous pouvez très facilement le personnaliser en modifiant les couleurs, la taille de la police ou encore l’ordre des rubriques par exemple.

Grâce à nos modèles, **augmentez vos chances de capter l’attention et de décrocher un entretien** ! En bénéficiant de notre expertise et de notre expérience, créez un CV qui vous aidera à franchir les obstacles de sélection et augmentera vos chances de trouver un emploi.

`,
      `
## Les critères pour sélectionner un modèle de CV

Avec un choix très vaste de modèles de CV, il n’est pas toujours facile d’en sélectionner un. Si vous êtes un peu perdu et ne savez pas par où commencer, voici quelques considérations à prendre en compte pour faire une sélection. Ensuite, laissez parler votre goût et vos envies !

### 1. Selon le type de poste visé

Le poste pour lequel vous souhaitez candidater peut influencer le modèle de CV à choisir. En effet, un poste de créatif tolère plus facilement les fantaisies graphiques, et vous pourrez plus facilement opter pour un design original. En revanche, un cadre s’orientera plutôt vers un CV sobre.

### 2. Selon votre niveau d’expérience

Il est évident que **si vous débutez dans le monde professionnel, il vaudra mieux opter pour un design mettant en valeur diplôme et formations**.

Pour les personnes qui ont déjà un parcours bien rempli, il faudra plutôt chercher des modèles qui donnent la préférence au parcours professionnel. Même si la plupart des CV ont des rubriques modifiables et interchangeables, cela peut se traduire par des blocs qui offrent plus d’espace pour expliciter vos missions et les postes occupés.

Ainsi, les CV avec deux colonnes contiennent plus d’information, et sont souvent parfaits pour des profils avec de nombreuses années d’expérience.

### 3. Selon votre secteur professionnel

De plus en plus de modèles de CV sont orientés plus ou moins spécifiquement vers certains métiers. Cela peut être dû à des illustrations ou des icônes particulières, ainsi que des rubriques spécifiques qui peuvent être nécessaires selon le métier ou la filière professionnelle.

Vous trouverez ainsi des **CV spécialisés pour la restauration, les métiers de la finance ou le monde médical par exemple**.

### 4. Selon votre personnalité !

Même si tous les autres critères tendent à rapprocher votre CV d’un modèle type selon votre métier ou votre secteur, le curriculum vitae reste un espace pour exprimer votre personnalité et vos spécificités.

Alors n’hésitez pas à choisir un CV qui vous permet de vous exprimer et de vous mettre en valeur. Cela peut passer par une couleur dominante, une disposition particulière, une typographie originale ou encore des graphismes inédits. Sans tomber dans l’originalité à tout prix, **vous trouverez facilement de quoi exprimer votre personnalité au sein de notre catalogue**.
`,
    ],
    'exemple-cv': [
      `
Il n’est jamais simple de partir d’une page blanche pour créer un CV. Heureusement, vous trouverez toujours chez nous un exemple de CV qui saura vous inspirer et vous offrir un canevas pour vous guider.

**Utiliser un exemple vous fera gagner du temps et vous évitera surement des erreurs.** Comment choisir le mieux adapté à votre profil, votre métier et votre personnalité ?

## Trouvez l’exemple de CV qu’il vous faut

Que vous recherchiez un exemple de CV simple ou un exemple de [CV en ligne](/), le grand choix que nous vous proposons vous permettra de trouver celui qu’il vous faut ! Pour cela, il vous suffit de : 

1. Parcourir nos propositions, 
2. Choisir vos coloris et styles favoris,
3. Consulter les différentes rubriques du CV,
4. Imaginer votre future candidature !
`,
      `
## Pourquoi utiliser un exemple de CV ?
En parcourant nos exemples de CV, vous trouverez de nouvelles idées, mais pas seulement ! Les arguments en faveur de l’utilisation d’exemples de CV :

* **Des idées à foison** : c’est le premier avantage des exemples de CV. Vous y trouverez des idées de disposition et de graphismes, vous y verrez l’emploi de certaines couleurs auxquelles vous n’avez pas pensé et vous découvrirez de nouvelles manières de mettre en avant vos qualités.
* **Un grand choix de design** : pour quelqu’un qui n’est pas graphiste, la partie design d’un CV est la plus difficile à concevoir. Elle prend du temps et nécessite des outils. Avec un exemple de CV vous pouvez choisir la forme de CV de votre choix très facilement.
* **Un gain de temps** : pourquoi partir d’une page blanche quand un exemple de CV vous permettra juste de modifier vos informations personnelles, remplir votre expérience et votre formation et faire quelques ajustements ?
* **Un taux d’erreur réduit** : lorsque l’on fait son CV soi-même, il est facile de se tromper sur le style, la disposition des paragraphes, les titres ou même la mise en page. Si vous choisissez un exemple de CV, il vous sera plus aisé d’éviter ces erreurs... Qui peuvent vous coûter cher dans votre recherche d’emploi.
* **Un document conçu pour attirer l’œil des recruteurs** : tous nos exemples de CV ont été imaginés pour maximiser vos chances de décrocher un entretien - et un emploi. En les utilisant, vous mettez toutes les chances de votre côté grâce à notre expertise et à notre expérience.
`,
      `
## Comment choisir le meilleur exemple de CV ?

Lorsque le choix est très grand, il n’est pas toujours facile d’arrêter sa sélection. Chacun cherche **un exemple de CV original qui permettra de se démarquer et de susciter le coup de cœur**. Cv de Boss vous propose 3 critères pour arrêter votre choix.

* En fonction de votre métier : vous trouverez un grand nombre d’exemples ou de modèles de CV déjà adaptés à un métier type. 

Une fois votre métier indiqué, vous aurez un choix plus facile dans une sélection restreinte de CVs, déjà prêts et optimisés pour mettre en avant votre expérience. Et vous serez sûr de mettre dans le mille !

* En fonction de votre personnalité : si vous avez envie de gaieté et de fantaisie sur votre CV, allez vers les plus colorés et les plus originaux. Si au contraire, **vous voulez mettre en avant votre rigueur et votre sérieux, des CV monochromes avec une présentation plus classique seront mieux adaptés**. 

À vous de voir ce qui correspond à votre caractère ou à l’image que vous souhaitez véhiculer. La grande diversité des CV proposés vous assure de trouver celui qui colle parfaitement à ce que vous êtes.

* Aller vers celui qui vous semble le plus simple à personnaliser : tous les exemples de CV proposés sont modifiables. Mais moins de travail à effectuer sur la forme, c’est plus de temps à consacrer au fond !

Si vous avez donc du mal à choisir, **privilégiez les CV qui semblent correspondre à ce que vous souhaitez rédiger et montrer de votre parcours**. Vous vous y sentirez plus à l’aise et perdrez moins de temps à le compléter.
`,
    ],
    'cv-aide-soignante': [
      `
Si **vous travaillez dans le milieu médical et que vous visez un poste d’aide-soignant(e)**, votre première arme pour le décrocher sera d’avoir un CV pertinent et bien réalisé. 

Rédiger un bon CV d’aide-soignant(e) n’est pas difficile en soi, mais il faut éviter certaines erreurs et se doter des bons outils. Suivez nos conseils pour mettre toutes les chances de votre côté !
  
## Choisir un bon modèle de CV d’aide-soignant(e)

Il existe des centaines de types de CV mais tous ne conviendront pas pour un profil d’aide-soignant(e). Comme tous les métiers, celui-ci a ses spécificités qu’il faut pouvoir mettre en évidence pour convaincre un recruteur.
  
Pour choisir votre modèle de CV d’aide-soignant(e), vous devez avant tout vous appuyez sur 2 caractéristiques principales : son design et sa structure. La première rassemble tout l’aspect formel qui fait le charme d’un CV comme les couleurs, la disposition des blocs, les icônes utilisées ou les graphismes employés. La deuxième comprend l’ordre des éléments du CV, leur disposition et leur importance relative.

### Son design 

Comme le sérieux et la rigueur sont indispensables pour ce type de poste, il vaudra mieux **se diriger vers des CV sobres et éviter les couleurs trop voyantes. Privilégiez aussi les designs graphiques par blocs ou en colonnes pour bien séparer les informations**.

Enfin, **la police de caractères devra être claire**, peu alambiquée et devra être lisible même avec des petites tailles (si vous avez beaucoup d’informations).

### Sa structure 

Dans ce type de poste, c’est l’expérience professionnelle qui prime. Il vaudra donc mieux afficher en premier cette partie sauf si vous êtes tout juste diplômé(e).

Renseignez clairement les postes que vous avez occupés avec les missions qui vous étaient confiées. **Soyez toujours précis(e) concernant les établissements où vous avez exercé et les services dans lesquels vous interveniez**.

Bien sûr, une rubrique formation devra être aussi complète que possible en faisant la part belle aux formations diplômantes et à toutes les formations continues que vous avez pu suivre dans le cadre de votre parcours professionnel si vous n'êtes plus débutant(e).

Enfin, ne négligez pas la rubrique des compétences en allant au-delà de celles qui sont purement techniques. **Indiquez brièvement vos qualités naturelles comme la faculté de travail en équipe, l’aisance dans la communication** ou divers points positifs qui peuvent être intéressant pour ce genre de métier.
`,
      `
## Comment rédiger un CV d’aide-soignant(e) parfait

### Privilégiez un texte aéré

Même s’il faut être complet, un CV d’aide-soignant(e) trop dense n’est jamais facile à lire. Alors, essayez d’aller à l'essentiel et soyez concise. Parlez uniquement de ce qui fait sens par rapport au poste et n'hésitez pas à négliger des expériences qui n’ont pas de rapport avec le métier d’aide-soignante. 

Enfin, utilisez des listes à puces pour apporter de la clarté à votre texte et pensez à choisir une typographie légère, lisible et claire. 

### Choisissez un ordre antichronologique

Il vous faudra mettre en premier votre expérience la plus récente. C'est celle qui doit totalement justifier votre candidature, soit par similarité, soit par suite logique de déroulement de carrière. 

### Personnalisez votre CV d’aide-soignant(e) en fonction de l'annonce 

Si vous répondez à une offre d’emploi, **repérez les mots clés de celle-ci comme les qualités demandées et essayez de revoir le contenu de votre CV pour mieux répondre à ceux-ci**. Il ne s’agit pas de transformer son parcours et de « tricher », mais de trouver des expressions et des tournures de phrases qui montreront vos aptitudes pour le poste.

### Détaillez les tâches de vos précédents postes

Le métier d’aide-soignant(e) est très concret et regorge de diverses tâches. Pour rassurer un recruteur, **allez dans le détail des tâches qui vous étaient confiées dans vos différents postes**. Il y verra certainement votre polyvalence mais aussi votre aptitude à être immédiatement opérationnel(le). 

### Faites-vous relire

Il est toujours difficile de se corriger soi-même et les correcteurs orthographiques ne voient pas toujours tout. **Choisissez une personne de votre entourage qui a un peu d’expérience avec la rédaction de CV et demandez-lui de jeter un œil neuf sur votre réalisation**.

Elle pourra très certainement détecter une ou deux coquilles, vous conseiller sur une meilleure expression, vous demander un détail complémentaire et finalement, vous rassurer sur la qualité de votre CV d’aide-soignant(e) !
`,
    ],
    'cv-restauration': [
      `
Les milieux de la restauration et de l’hôtellerie sont de très gros pourvoyeurs d’emplois et ces secteurs attirent chaque année de nouveaux candidats. 

Pour se démarquer et mettre toutes les chances de votre côté, mieux vaut avoir un CV pour la restauration et l’hôtellerie qui soit pertinent et efficace. Cela vous aidera à mettre en valeur votre profil et décrocher un emploi rapidement. Découvrez **toutes nos idées et conseils pour bâtir un CV gagnant** !

## CV restauration et hôtellerie : quel modèle choisir ?

Parmi tous les types de CV que vous pouvez trouver, certains ne conviendront pas du tout pour mettre en valeur un profil dans les métiers de la restauration. Comme tous les secteurs d’activité, celui-ci a ses particularités.

Afin de sélectionner votre modèle de CV de restauration et d'hôtellerie, vous devrez d’abord être attentif à deux paramètres importants : son design et sa structure. 

### Les meilleurs designs 

**Il est possible de se pencher vers des designs très créatifs si vous êtes chef, cuisinier, barman ou barista**. Cela peut permettre de souligner votre intérêt pour les choses esthétiques et soignées, avec quelques touches de couleurs et des illustrations appropriées. Attention toutefois à ne pas trop surcharger, le design de votre CV ne doit pas prendre le pas sur le fond et sur les informations que vous souhaitez transmettre sur votre personne.

**Le personnel de service, d’accueil ou de management pourra être plus attiré par des CV plus sobres et classiques, sans pour autant qu’il soit interdit d’y apporter une touche de modernité ou de fantaisie.**

Dans tous les cas, il faudra prendre soin de choisir une police de caractères claire et lisible. Elle doit être moderne, avec une épaisseur des traits assez modérée et il faut bannir les polices cursives ou trop originales. 

### La structure d’un CV pour la restauration et l’hôtellerie 

Pour ces secteurs d’activité, **même si la formation initiale est importante, ce sont les expériences (qui peuvent être nombreuses) qui sont déterminantes**. Il sera donc judicieux d’afficher cette partie en premier sur un CV de restauration.

Vous pourrez faire le choix d’un affichage sur deux colonnes si vous avez déjà un parcours assez fourni. Néanmoins, soyez assez synthétique et privilégiez les expériences les plus importantes pour un développement détaillé.

La rubrique consacrée à la formation initiale ou professionnelle tout au long de votre parcours ne devra pas être négligée pour autant. Soyez précis sur vos formations diplômantes et sur les éventuelles formations continues suivies. 

**Une rubrique consacrée à vos compétences doit renseigner le recruteur sur vos savoir-faire et votre savoir-être**. Mettez en évidence des qualités appréciées dans cet univers de travail comme :

* Le goût du travail en équipe, 
* La résistance au stress,
* La rigueur, 
* La ponctualité.
`,
      `
## Des idées pour rédiger un CV pour la restauration

### Assurez-vous que votre texte soit lisible

Même s’il est souhaitable d’être complet, un CV trop dense n’est jamais agréable pour un recruteur. **Inutile de mentionner tous vos extras et l’ensemble des établissements dans lesquels vous avez travaillé.**

Il ne faut donc pas hésiter à aller à l'essentiel. Et si vous avez vraiment accumulé trop de postes, peut être est-il judicieux de faire une annexe avec la liste complète, mais de **garder seulement les 5 postes les plus marquants sur votre CV de restauration ou d’hôtellerie.** 

Enfin, pour une meilleure clarté du texte, pensez à sauter des lignes, à utiliser le gras pour mettre en évidence les titres ou un nom d’établissement et à utiliser les listes à puces pour ordonner votre propos. 

### Commencez par le poste le plus récent

Ce sont vos expériences les plus récentes qui compteront le plus aux yeux du recruteur. Ce sont celles qui doivent l’éclairer sur la justesse de votre candidature, et votre capacité à tenir le poste. 

Soignez donc ces expériences en veillant à donner des informations complètes, et vous pourrez être plus synthétiques pour celles qui sont beaucoup plus anciennes.

### Listez les tâches que vous maîtrisez

Les métiers de la restauration et de l’hôtellerie sont assez concrets et les recruteurs veulent savoir ce que vous êtes capables de faire. Même s’ils poseront la question en entretien, autant faire savoir dès le curriculum vitae quelles sont les tâches que vous maîtrisez et que vous pouvez prendre en charge dans votre nouveau poste, sans besoin d’apprentissage. 
`,
    ],
    'cv-etudiant': [
      `
**Vous êtes étudiant et vous apprêtez à entrer dans la vie active ? Vous cherchez un job saisonnier ou en parallèle de vos études ?** 

Vous avez alors besoin d’un bon CV étudiant pour vous ouvrir les portes du monde professionnel. Suivez nos conseils pour élaborer un CV efficace et accrocheur !

## Quel design pour votre CV étudiant ?

Tous nos modèles de CV possèdent des designs modernes, clairs et qui pourront mettre en valeur votre profil. Pour choisir le bon design, tenez compte de ces recommandations :

* **Un peu de couleur… Mais pas trop** : un modèle aux couleurs brillantes ou pastel peut vous faire passer pour un candidat immature. Privilégiez des couleurs sobres et bien dosée pour votre CV.
* **Un design adapté à l’entreprise ciblée** : optez pour du classique si vous souhaitez travailler dans la finance ou l’industrie. En revanche, le monde des start-up ou de la communication autorisent plus de fantaisies et des designs plus graphiques.
* **Un modèle de CV avec photo** : choisissez une bonne photo de vous (n’hésitez pas à investir un peu dans un portrait professionnel) et privilégiez un modèle de CV étudiant comportant une photo.
`,
      `
## Quelle structure de CV privilégier ?

Lorsqu’on est étudiant, c’est vraiment **la formation qu’il faut mettre en avant, ainsi que votre objectif professionnel**.

Choisissez donc un modèle où la première rubrique permet de clairement afficher votre objectif ou le poste que vous visez.

Ensuite, devra venir une rubrique complète consacrée à votre formation en mettant l’accent sur la logique de votre cursus et en mentionnant de manière distincte, les stages et toutes les expériences qui ont pu jalonner votre parcours.

Enfin, **prévoyez un emplacement pour vos compétences**. Elles peuvent être techniques (langages informatiques, maîtrise de logiciels, process particuliers), linguistiques (langues parlées) ou de l’ordre de qualités moins formelles mais tout de même acquises (travail en équipe, gestion de projet…).

**Terminez votre CV en parlant un peu de vos activités en dehors des études et du travail**, en soulignant ce qu’elles vous apportent et comment elles peuvent éclairer votre profil. Par exemple, le sport d’équipe est souvent perçu comme un signe positif d’aptitude au travail en équipe. Et si vous avez été capitaine de votre équipe, c'est peut-être parce qu’on a décelé du leadership en vous ?
`,
      `
## Comment bien rédiger votre CV étudiant ?

Au-delà de la structure et du design de votre CV, vous devez apporter un grand soin à la rédaction de votre curriculum vitae. Il va traduire ce que vous êtes, et doit vous permettre de vous mettre en évidence de manière nette par rapport à d’autres candidats. 

Voici quelques règles à suivre pour rédiger un CV étudiant qui va vous faire décrocher des entretiens !

* **Personnalisez votre CV** : les recruteurs lisent des centaines de CV étudiants qui se ressemblent et les mêmes mots qui sont employés. Trouvez donc un peu d’originalité dans votre rédaction et surtout **modifiez votre CV pour chaque candidature**. Chaque poste a ses spécificités et nécessite une réécriture de votre CV.
* **Travaillez votre cursus** : c'est la partie la plus importante de votre CV. Ne soyez donc pas vague sur les dates, les libellés de vos diplômes, les options suivies et les mentions. Toutes ces informations sont essentielles pour que le recruteur comprenne comment vous avez été formé, et ce que vous pouvez apporter à sa structure.
* **Ne négligez pas les compétences** : trop de CV étudiants mentionnent seulement une langue avec la mention ”lu, écrit, parlé”, et des aptitudes sur Office. **Soyez spécifique en précisant votre niveau de maîtrise des langues** (avec pourquoi pas des scores à des tests type TOEIC ou TOEFL). Trouvez aussi des outils que vous avez pu utiliser dans le cadre de stages (des machines propres à une industrie, des logiciels spécialisés ou encore la connaissance de certaines normes). Les compétences doivent éclairer le recruteur sur ce que vous savez déjà faire de manière pratique dans votre métier.
* **Demandez à quelqu’un de vous relire** : vous aurez beau vous concentrer, vous ne trouverez peut-être pas la dernière petite coquille sur votre CV étudiant. Vous n’aurez pas non plus un œil neuf pour critiquer votre CV. Appuyez-vous sur quelqu’un d’objectif et pourquoi pas de plus expérimenté pour vous relire et vous corriger si nécessaire.
`,
    ],
    'cv-espagnol': [
      `
Le monde hispanophone est très vaste et offre de nombreuses opportunités de travail. Alors, si vous aussi, vous souhaitez tenter votre chance en Espagne, en Amérique du Sud ou au Mexique, suivez **nos conseils pour bâtir un CV espagnol absolument en phase avec les us et coutumes de ces pays**.

## Les règles à connaitre pour faire un CV espagnol

Au niveau longueur, il n'y a pas trop de formalisme pour un CV espagnol et **un format de deux pages est couramment admis. Mais si vous êtes débutant, il vaudra mieux s’en tenir à une page seulement**. 

Ensuite, un CV espagnol doit comporter la mention « Curriculum Vitae » bien apparente, en titre.

Contrairement à la France, ou la photo est quasiment systématique, le CV espagnol ne l’exige pas, à moins qu’elle soit spécifiquement demandée sur l’annonce.

À titre de références, il est d’usage de mentionner les noms et numéros de téléphone des anciens employeurs.

Enfin, **les recruteurs hispanophones (notamment en Espagne) sont très attentifs aux activités extra-professionnelles (bénévolat, associations, activités culturelles ou sportives)**. 

Il ne faut donc pas hésiter à détailler cette partie qui peut vous mettre en valeur. Ils sont aussi sensibles aux mises en page claires et aux designs soignés. Soyez donc pertinent dans votre choix de modèle de CV espagnol !
`,
      `
## Quelles rubriques dans un CV espagnol ?

Un CV espagnol reste assez proche d’un CV français, même s’il y a des différences en termes de contenu. Néanmoins, sa structure particulière suivra une trame qui ne devrait pas trop vous dérouter. 

### Les informations personnelles 

C’est une rubrique à part entière et qui doit s’appeler Datos personales. Elle doit bien sûr comporter le nom et le prénom, l’adresse, un numéro de téléphone et une adresse mail. **Il faudra aussi mentionner le lieu et la date de naissance**.

### L’objectif professionnel

Cette mention est loin d'être obligatoire dans un CV espagnol, mais de plus en plus de candidats l’utilisent. Elle peut vous permettre de clairement afficher vos objectifs et sera appréciée pour une candidature spontanée. 

### La formation initiale

Sur un CV espagnol, elle vient avant l’expérience professionnelle. Elle doit comporter de manière précise les études suivies, avec mention des diplômes obtenus, des dates de début et de fin, ainsi que les matières ou options suivies.

Il sera aussi possible de faire état de résultats ou de notes obtenues. Les Espagnols ont tendance à détailler de manière abondante cette rubrique, notamment dans le cas des jeunes diplômés.

### L’expérience professionnelle

C’est la rubrique la plus importante du CV espagnol et elle doit comprendre aussi bien les stages que les jobs étudiants en plus des autres postes occupés.

Comme pour la partie formation, elle doit être détaillée et précise sur les titres des emplois occupés et leur contenu. Il faudra aussi pouvoir mentionner des dates en évitant les trous dans la chronologie. 

Enfin, pour les entreprises françaises, parfois méconnues à l’international, il sera judicieux d’expliciter en quels mots la nature de leur activité.

### Les langues étrangères

Il est utile de prévoir une rubrique spécifique pour vos connaissances en langues étrangères. Le niveau de langue sur un CV espagnol devra être mentionné avec cette terminologie (valable pour toutes les langues que vous pratiquez) : 

* Nativo : langue maternelle
* Bilingüe : bilingue
* Bueno : courant
* Intermedio : intermédiaire
* Principiante : débutant

**Si vous possédez des certifications officielles (DELE, BRIGHT Español, TOEIC pour l’anglais, etc), mentionnez-les également en précisant le résultat obtenu.**

### Les compétences particulières

Vous pouvez ici mentionner toutes les compétences avérées que vous possédez dans votre champ d’activité professionnel. 

Cela peut aller de la maîtrise d’outils informatiques grand public comme à celle de logiciels spécifiques, mais vous pouvez aussi évoquer la maîtrise de technologies particulières (certifications professionnelles particulières ou autres connaissances concrètes).

### Les activités extra-professionnelles

C’est une rubrique importante en Espagne et vous la retrouverez sur n’importe quel exemple de CV espagnol, sous la mention *Actividades extracurriculares*.

Vous regroupez ici toutes les activités exercées pendant votre temps libre. Cela peut comprendre des loisirs, comme des activités sportives mais aussi des actions caritatives, une activité sociale ou politique, ou encore la conduite d'activités commerciales annexes.

Ces mentions doivent servir à illustrer vos capacités et votre implication. N’hésitez pas à préciser vos responsabilités, à donner des exemples éventuellement chiffrés et à expliquer ce que vous faites. Les recruteurs espagnols prennent du temps pour analyser ces éléments, et en font un critère important pour comprendre la personnalité du candidat.
`,
    ],
    'cv-anglais': [
      `
Si vous souhaitez postuler à un poste à l’international, il y a des chances que vous ayez un jour à rédiger un CV anglais ou américain à destination d’entreprises de culture anglo-saxonne. 

Par rapport au CV français, il y a des règles à connaitre, quelques petites différences essentielles et des pièges à éviter.

## Comment est structuré un CV anglais ?

Un CV anglais doit suivre une structure particulière, et il répond à des codes différents de ceux du CV français. Il doit notamment être rédigé dans un certain ordre, et comporter des informations obligatoires. Cette structure recommandée vous est présentée ci-dessous, afin de vous aider à rédiger de manière précise votre CV anglais – et aller décrocher un poste ou un stage à l’international !

### Les informations personnelles 

Elles doivent comporter :
* le prénom et le nom, 
* l'adresse, 
* le numéro de téléphone, 
* une adresse mail,
* la nationalité.

**Ne faites pas figurer le sexe, l’âge ou le statut marital, qui peuvent être considérés comme des éléments discriminatoires. La photo est facultative, et rarement utilisée** contrairement à la France.

### L’objectif professionnel

Cette rubrique permet de mettre en évidence votre projet professionnel ou le type de poste que vous recherchez. Cette mention doit être claire et est souvent encadrée.

### L’expérience professionnelle

C’est la rubrique la plus importante du CV anglais et elle doit comporter les stages ainsi que les emplois occupés. 

Pour chacune de vos expériences, **précisez votre activité, vos réalisations (si possible chiffrées) et employez des verbes d’action conjugués au passé (prétérit)**. Présentez-les dans l’ordre antichronologique et évitez les incohérences de dates.

### La formation initiale

**Mentionnez uniquement les diplômes obtenus après le baccalauréatMentionnez uniquement les diplômes obtenus après le baccalauréat**, et dans un ordre antichronologique. Comme les diplômes sont relativement différents en France, il faudra être prudent dans les traductions qui peuvent aboutir à des confusions.

### Les compétences particulières

Sélectionnez uniquement des compétences particulières et significatives pour le poste, ou vos compétences en bureautique et logiciels, ainsi que votre niveau en langues.

Le niveau de langue sur un CV anglais doit être mentionné avec les niveaux suivants (par ordre décroissant) : 

* Bilingual 
* Fluent 
* Working knowledge 
* Conversational 

### Divers

Cette rubrique permettra d’enrichir votre profil avec diverses activités que vous pouvez avoir. Vous êtes capitaine de votre équipe de sport, président d’une association, ou vous vous occupez d’une organisation caritative ? Mentionnez cette partie de loisirs (ou passe-temps non essentiels) pour décrire votre personnalité. Dire que vous aimez lire ou aller au cinéma n’aura pas beaucoup de pertinence, par exemple si vous ne postulez pas à un poste en rapport avec ces activités.

### Les références

**C’est une rubrique importante dans les pays anglo-saxons**. Vous pouvez indiquer directement des personnes à contacter (ainsi que leur poste), ou mentionner que leurs coordonnées seront communiquées sur demande. 
`,
      `
## Les autres particularités du CV anglais

Un CV anglais comporte un format standard d’une page. Il est néanmoins courant de voir des CV anglais sur deux pages lorsque la personne a une expérience très importante.

Le CV anglais doit être rédigé au passé et sans pronom personnel. Ainsi on ne dira pas “I designed a software” mais au contraire “Designed a software”. Il faudra aussi veiller à ne pas utiliser l'anglais britannique pour l'anglais américain et vice-versa. N'hésitez pas à vous faire corriger par une personne du pays ou ayant vécu suffisamment longtemps dans un environnement professionnel anglo-saxon.

**Évitez de faire de la traduction littérale de votre CV français.** Un CV anglais requiert souvent un vrai travail. Traduire votre CV ou utiliser un outil de traduction vous conduira à des contresens et à un CV de mauvaise qualité. Utilisez plutôt un modèle de CV anglais ou un exemple de CV anglais pour vous en servir comme trame et guide de travail.

Soyez concrets sur vos expériences en décrivant vos actions, en mentionnant des chiffres et en précisant des exemples. Les anglo-saxons sont assez peu friands des phrases à rallonge et des expériences évasives. Ils veulent **prendre connaissance de faits** qui décrivent l'étendue de vos diverses responsabilités et mesurer votre action personnelle.

`,
    ],
  },
  mysmartcv: {
    en: [
      `
Creating your CV that gets you one step closer to your dream job has never been so easy My Smart CV! With our online CV maker, you have a powerful digital tool and a large library of templates to build your CV for free in just a few minutes.

See for yourself and discover the functionalities of My Smart CV maker online. Create your resume now and get ready to stand out and land your next job.

## The Advantages of Using an Online CV Maker from My Smart CV

Let's admit it. Writing a resume can take much of your time and effort. With the online CV maker tool from My Smart CV, you can take advantage of the following:

* Choose from existing models and templates
* Customize the design and layout according to your preference
* Modify the content by filling up your information and experiences
* Get feedback from our content optimizer
* Generate several types of CV according to the objective of your application
* Easily download and export your CV in different file formats
* Get access to pre-written resume samples

Creating a resume no longer needs to be a tedious and intimidating task. With the help of an online CV builder, you can easily highlight your skills, accomplishments, and experiences the right way.

No need to worry about using any of our templates because you can make the design uniquely yours. Do you want to add a new section for your awards? Do you think it is best to change the order of the sections? Maybe you want to change the colors or tweak the layout to make it more visually pleasing. In just a few clicks, you can easily modify what you want.
`,
      `
## A Powerful Tool, A Unique Result
The purpose of your resume is to introduce your qualifications to recruiters and employers alike. Your skills, education, experiences, and achievements are what will get you the job. Therefore, creating a CV requires real work on your part.

You need to take a look at your professional career and carefully decide which of your abilities are relevant to the job you are seeking. In order to focus on this aspect, it is very important that you do not have to deal with technical problems, layout concerns, or even graphic design.

This is the reason why we have created our [online CV creation tool](/en) to offer you the means to free yourself from the technical aspects of the CV, letting you focus on what matters most. Devote yourself to the essentials and pack more substance into your resume. Make sure that your profile and your background highlight why you are a good fit for the job.

Our service allows you to create the perfect CV to considerably increase your chances of landing a job, an internship, a new assignment, or applying for a training course.
`,
      `
## Endless Resources to Find the Right Resume Template

With My Smart CV, we offer you the easiest way to find the right template for your resume.

Today, using a generic, standardized resume template can be ineffective in getting the attention of the employer. To stand out from the crowd, it is better to have a unique CV that is tailored to match the requirements of a specific job posting. Not only will it help set your application apart from those of others, but it will allow you to highlight why you can personally add value to the company.

Moreover, when applying for different jobs, keep in mind that your CV should not be the same. Thankfully, with the free online CV creator from My Smart CV, you can find a huge number of CV models that are adapted for different trade sectors and industries.
`,
      `
## A Versatile Tool to Make Your CV

Most employers would ask applicants to submit CV in Word or PDF formats. Others may have a different requirement. Whether you need your CV to be in Word, PDF, or even in another file format, you can use our online CV creation service anytime. Just choose your preferred file format to easily create and edit your free online CV.

With our online creation service, not only do you save time, but you also have writing tips and suggestions with pre-written and editable content.

The best part of it? You don't need a tool to download or software to install on your computer. You can edit your document as many times as you want, from anywhere, as long as you have an internet connection!
`,
      `
## Create Your CV Now with My Smart CV

If you need to create a resume now, here is a summary of why our online CV builder is your best option in the market.

**Guaranteed professional.** Our resume templates have been developed and reviewed by experts. No matter what industry or position you are applying for, we can generate a template for you. Moreover, you are assured of an up-to-date design that is both eye-catching and readable.

Our website is loaded with resume writing tips and suggestions to help you build the perfect CV. You can also get new ideas from our pre-written samples, making it easier for you to improve the content of your resume.

**Hassle-free and a time-saver.** With our online CV builder, you have the option to just fill in the existing template with your personal background and information. You definitely don't need to start from scratch!

In addition, you can save time on formatting and editing when you use our free templates. No need to stress over the layout and other technical details. We make sure that our platform is simple and easy to use.

**Easy customization.** Your online CV can be very modern, sleek, and original. Choose whether you want to insert a photo or not. You can vary the colors, graphics, and design of your CV in just a few clicks. Personalize as much as you want to make it stand out.

Creating your resume will no longer be a source of frustration and anxiety by using My Smart CV/s resume builder. You save time and you are guaranteed to obtain an attractive professional result. Highlight your qualifications and increase your chances of landing the job of your dreams!
`,
    ],
    id: [
      `
Buatlah CV anda yang akan membawa anda selangkah lebih dekat menuju pekerjaan impian anda, tidak pernah semudah ini! My Smart CV! Dengan pembuat CV online kami, anda memiliki alat digital yang luar biasa dan banyak template untuk membuat CV anda secara gratis hanya dalam beberapa menit.

Lihat sendiri dan temukan fungsi My Smart CV online. Buat resume anda sekarang dan bersiaplah untuk tampil menonjol dan dapatkan pekerjaan anda berikutnya.

## Keuntungan Menggunakan Pembuat CV Online dari My Smart CV

Mari kita akui. Membuat resume akan menghabiskan banyak waktu dan tenaga anda. Dengan alat pembuat CV online dari My Smart CV, anda bisa mendapatkan manfaat berikut ini:

* Pilih dari model dan template yang ada
* Sesuaikan desain dan tata letak sesuai dengan pilihan anda
* Ubah konten dengan mengisi informasi dan pengalaman anda
* Dapatkan umpan balik dari pengoptimal konten kami
* Buat beberapa jenis CV sesuai dengan tujuan lamaran anda
* Unduh dan ekspor CV dengan mudah dalam berbagai format file
* Dapatkan akses ke ‘’contoh resume’’ yang telah dibuat sebelumnya

Membuat resume tidak lagi menjadi hal yang membosankan dan mengintimidasi. Dengan bantuan pembuat CV online, anda dapat dengan mudah menyoroti keterampilan, pencapaian, dan pengalaman anda dengan benar.

Tidak perlu khawatir menggunakan salah satu template kami, karena anda dapat membuat desainnya secara unik sendiri. Apakah anda ingin menambahkan baris baru pada bagian penghargaan anda? Menurut anda, apakah lebih baik mengubah urutan bagian CV? Mungkin anda ingin mengubah warna atau tata letak agar lebih menarik secara tampilan. Hanya dengan beberapa klik, anda dapat dengan mudah mengubah apa yang anda inginkan.
`,
      `
## Alat Kuat Dengan Hasil Yang Unik

Tujuan resume adalah untuk memperkenalkan kualifikasi anda kepada perekrut dan pemberi kerja. Keterampilan, pendidikan, pengalaman, dan prestasi anda adalah yang akan memberi anda pekerjaan tersebut. Oleh karena itu, membuat CV membutuhkan kerja nyata dari anda.

Anda perlu melihat karir profesional anda dan dengan hati-hati memutuskan kemampuan mana yang relevan dengan pekerjaan yang anda cari. Untuk fokus pada aspek ini, sangat penting bahwa anda tidak harus berurusan dengan masalah teknis, masalah tata letak, atau bahkan desain grafis.

Inilah alasan mengapa kami membuat alat pembuat CV online ini: untuk menawarkan anda sarana untuk membebaskan diri anda dari aspek teknis CV, membiarkan anda fokus pada hal yang paling penting. Mendedikasikan diri anda pada hal-hal penting dan memasukkan lebih banyak materi ke dalam resume anda. Pastikan bahwa profil dan latar belakang anda menyebutkan mengapa anda cocok untuk pekerjaan itu.

Layanan kami memungkinkan anda membuat CV yang sempurna untuk lebih meningkatkan peluang anda mendapatkan pekerjaan, magang, tugas baru, atau melamar kursus pelatihan.
`,
      `
## Sumber Daya Tak Terbatas untuk Menemukan template Resume yang Tepat

Dengan My Smart CV, kami menawarkan cara termudah untuk menemukan template yang tepat untuk resume anda.

Saat ini, menggunakan template resume standar yang umum mungkin tidak efektif untuk menarik perhatian pemberi kerja. Agar lebih menonjol, lebih baik memiliki CV unik yang disesuaikan dengan persyaratan pekerjaan tertentu. Tidak hanya akan membantu membedakan lamaran anda dari yang lain, tetapi juga memungkinkan anda untuk menyoroti mengapa anda secara pribadi dapat menambah nilai bagi perusahaan.

Apalagi saat melamar pekerjaan yang berbeda, perlu diingat bahwa CV anda tidak boleh sama. Untungnya, dengan pembuat CV online gratis dari My Smart CV, anda dapat menemukan banyak sekali model CV yang disesuaikan untuk berbagai sektor perdagangan dan industri.
`,
      `
## Alat Serbaguna untuk Membuat CV anda

Sebagian besar perusahaan akan meminta pelamar untuk mengirimkan CV dalam format Word atau PDF. Orang lain mungkin memiliki persyaratan yang berbeda. Apakah anda membutuhkan CV anda dalam Word, PDF, atau bahkan dalam format file lain, anda dapat menggunakan layanan pembuatan CV online kami kapan saja. Cukup pilih format file yang anda inginkan untuk membuat dan mengedit CV online gratis anda dengan mudah.

Dengan layanan kreasi online kami, anda tidak hanya menghemat waktu, tetapi anda juga memiliki tip dan saran menulis dengan konten yang telah ditulis sebelumnya dan dapat diedit.

Bagian terbaiknya? Anda tidak memerlukan alat lain untuk mengunduh atau perangkat lunak untuk dipasang di komputer anda. Anda dapat mengedit dokumen anda sebanyak yang anda inginkan, dari mana saja, selama anda memiliki koneksi internet!
`,
      `
## Buat CV anda Sekarang dengan My Smart CV

Jika anda perlu membuat resume sekarang, berikut adalah ringkasan mengapa pembuat CV online kami adalah pilihan terbaik anda di pasar.

**Dijamin profesional.** template resume kami telah dikembangkan dan ditinjau oleh para ahli. Apa pun industri atau posisi yang anda lamar, kami dapat membuatkan template untuk anda. Selain itu, anda dijamin akan mendapatkan desain terbaru yang menarik dan dapat dibaca.

Situs web kami menyediakan tip dan saran penulisan resume untuk membantu anda membangun CV yang sempurna. Anda juga bisa mendapatkan ide-ide baru dari contoh CV pra-tertulis kami, sehingga memudahkan anda untuk meningkatkan konten resume anda.

**Bebas repot dan hemat waktu.** Dengan pembuat CV online kami, anda memiliki opsi untuk hanya mengisi template yang ada dengan latar belakang dan informasi pribadi anda. Anda pasti tidak perlu memulai dari awal!

Selain itu, anda dapat menghemat waktu dalam memformat dan mengedit saat menggunakan template gratis kami. Tidak perlu khawatir dengan tata letak dan detail teknis lainnya. Kami memastikan bahwa platform kami sederhana dan mudah digunakan.

**Menyesuaikn dengan mudah.** CV online anda bisa sangat modern, ramping, dan asli. Pilih apakah anda ingin menyisipkan foto atau tidak. Anda dapat memvariasikan warna, grafik, dan desain CV anda hanya dengan beberapa klik. Personalisasi sebanyak yang anda ingin membuatnya menonjol.

Membuat resume anda tidak akan lagi menjadi sumber frustrasi dan kecemasan dengan menggunakan pembuat resume Smart CV / s. anda menghemat waktu dan dijamin mendapatkan hasil profesional yang menarik. Soroti kualifikasi anda dan tingkatkan peluang anda untuk mendapatkan pekerjaan impian anda!
`,
    ],
    es: [
      `
Crear un CV que te acerque mucho más al trabajo de tus sueños nunca ha sido tan fácil, con nuestro creador de CV en línea, ¡My Smart CV! tienes una poderosa herramienta digital y una gran biblioteca de plantillas para crear tu CV gratis en solo unos minutos.

Compruébelo tu mismo y descubre todas las funcionalidades del creador en línea My Smart CV. Crea tu currículum ahora y prepárate para destacar y conseguir tu próximo trabajo.

## Las Ventajas de Usar un Creador de CV en línea My Smart CV

Admitámoslo. Escribir un currículum puede llevar mucho tiempo y esfuerzo. Con la herramienta de creación de CV en línea de My Smart CV, puedes aprovechar lo siguiente:

* Elije entre modelos y plantillas existentes
* Personaliza el diseño y la distribución según tus preferencias.
* Modifica el contenido completando tu información y experiencias.
* Obtén comentarios de nuestro optimizador de contenido.
* Generar varios tipos de CV según el objetivo de tu aplicación.
* Descarga y exporta fácilmente tu CV en diferentes formatos de archivo.
* Obtén acceso a ejemplos de currículums escritos previamente

Crear un currículum ya no tiene por qué ser una tarea tediosa e intimidante. Con la ayuda de un creador de CV en línea, puede resaltar fácilmente tus habilidades, logros y experiencias de la manera correcta.

No hay necesidad que te preocupes por usar ninguna de nuestras plantillas porque puedes hacer que el diseño sea exclusivamente tuyo. ¿Quieres agregar una nueva sección para tus triunfos? ¿Crees que es mejor cambiar el orden de las secciones? Tal vez deseas cambiar los colores o modificar el diseño para que sea más agradable a la vista. Con solo unos pocos clics, puedes modificar fácilmente lo que desees.
`,
      `
## Una Herramienta poderosa, un resultado único

El propósito de tu currículum es presentar tus calificaciones tanto a los reclutadores como a los empleadores. Tus habilidades, educación, experiencias y logros son los que te darán el trabajo. Por lo tanto, crear un CV requiere un trabajo real de tu parte.

Necesitas analizar tu carrera profesional y decidir cuidadosamente cuáles de tus habilidades son relevantes para el trabajo que estás buscando. Para concentrarse en este aspecto, es muy importante que no tengas que lidiar con problemas técnicos, preocupaciones de diseño o incluso diseño gráfico.

Esta es la razón por la que hemos creado nuestra [herramienta de creación de CV en línea](/es): ofrecerte los medios para liberarte de los aspectos técnicos del CV, permitiéndote centrarte en lo que es más importante. Dedícate a lo esencial y agrega más contenido a tu currículum. Asegúrate de que tu perfil y tu experiencia destaquen por qué eres una buena opción para el trabajo.

Nuestro servicio te permite crear el CV perfecto para aumentar considerablemente tus posibilidades de conseguir un trabajo, un intercambio, una nueva asignación o solicitar un curso de entrenamiento.
`,
      `
## Recursos Ilimitados para Encontrar la Plantilla de Currículum adecuada

Con My Smart CV, te ofrecemos la forma más fácil de encontrar la mejor plantilla para tu currículum.

Hoy en día, el uso de una plantilla de currículum genérica y estandarizada puede resultar ineficaz para llamar la atención del empleador. Para destacar entre la multitud, es mejor tener un CV único que se adapte a los requisitos de un puesto de trabajo específico. No solo ayudará a diferenciar su aplicación de las de otros, sino que también te permitirá resaltar por qué puede agregar valor personalmente a la empresa.

Además, al postularte para diferentes trabajos, ten en cuenta que tu CV no debe ser el mismo. Afortunadamente, con el creador de CV en línea gratuito de My Smart CV, puedes encontrar una gran cantidad de modelos de CV que se adaptan a diferentes sectores e industrias comerciales.
`,
      `
## Una Herramienta Versátil para hacer tu CV

La mayoría de los empleadores pedirían a los postulantes que envíen su CV en formato Word o PDF. Otros pueden tener un requisito diferente. Ya sea que necesites que tu CV esté en Word, PDF o incluso en otro formato de archivo, puedes utilizar nuestro servicio de creación de CV en línea en cualquier momento. Simplemente elije tu formato de archivo preferido para crear y editar fácilmente tu CV en línea gratuito.

Con nuestro servicio de creación en línea, no solo ahorras tiempo, sino que también te da consejos y sugerencias de escritura con contenido editable y prescrito.

¿Cuál es la mejor parte? No necesitas una herramienta para descargar o un software para instalar en tu computadora. Puedes editar tu documento tantas veces como desees, desde cualquier lugar, ¡siempre que tengas una conexión a Internet!
`,
      `
## Crea Tu CV Ahora con My Smart CV

Si necesitas crear un currículum ahora, aquí hay un resumen de por qué nuestro creador de CV en línea es tu mejor opción en el mercado.

**Profesionalizmo garantizado.** Nuestras plantillas de currículum han sido desarrolladas y revisadas por expertos. Independientemente del sector o puesto al que te postules, podemos generar una plantilla para ti. Además, ten la seguridad de un diseño actualizado que llama la atención y es legible.

Nuestro sitio web está repleto de consejos y sugerencias para ayudarlo a redactar un currículum vitae perfecto. También puedes obtener nuevas ideas de nuestros ejemplos escritos previamente, lo que le facilitará la mejora del contenido de tu currículum.

**Sin complicaciones y ahorrador tiempo.** Con nuestro creador de CV en línea, tienes la opción de completar la plantilla existente con tu información y antecedentes personales. ¡Definitivamente no necesitas empezar desde cero!

Además, puedes ahorrar tiempo en formatear y editar cuando usas nuestras plantillas gratuitas. No es necesario preocuparse por el diseño y otros detalles técnicos. Nosotros nos aseguramos de que nuestra plataforma sea simple y fácil de usar.

**Fácil de Personalizar.** Tu CV en línea puede ser muy moderno, elegante y original. Elije si deseas insertar una foto o no. Puedes variar los colores, los gráficos y el diseño de tu CV con unos pocos clics. Personaliza tanto como desees para que destaque.

La creación de tu currículum ya no será un problema de frustración y ansiedad ya que al usar el creador de currículums de My Smart CV / s, ahorras tiempo y tienes la garantía de obtener un resultado profesional atractivo. ¡Resalta tus calificaciones y aumenta tus posibilidades de conseguir el trabajo de tus sueños!
`,
    ],
    ar: [
      `
إنشاء سيرتك الذاتية الاحترافية التي تجعلك على بعد خطوة واحدة من وظيفة أحلامك لم يكن أبدًا بمثل هذه السهولة مع My Smart CV! مع مُنشئ السيرة الذاتية عبر الإنترنت الذي نقدمه لك، ستكون بين يديك أداة رقمية قوية ومكتبة ضخمة من القوالب لتصميم سيرتك الذاتية مجانًا في خلال دقائق معدودة.

شاهد بنفسك واكتشف القدرات الوظيفية لمُنشئ السيرة الذاتية عبر الإنترنت My Smart CV. أنشئ سيرتك الذاتية الآن واستعد للتميز والحصول على وظيفتك التالية.

## مزايا استخدام مُنشئ السيرة الذاتية عبر الإنترنت من My Smart CV

دعونا نعترف بذلك. يمكن أن تستغرق كتابة السيرة الذاتية الكثير من وقتك وجهدك. باستخدام أداة إنشاء السيرة الذاتية عبر الإنترنت من My Smart CV، سوف يمكنك الاستفادة مما يلي:

* الاختيار من بين النماذج والقوالب الجاهزة.
* تخصيص التصميم والتنسيق وفقًا لتفضيلاتك.
* تعديل المحتوى عن طريق تعبئة بياناتك والخبرات التي لديك.
* احصل على الملاحظات والآراء من مُحسِّن المحتوى الخاص بنا.
* إنشاء عدة أنواع من السير الذاتية بحسب الغرض من طلبك.
* سهولة تنزيل وتصدير سيرتك الذاتية بصيغ مختلفة للملفات.
* إمكانية الوصول إلى نماذج السيرة الذاتية المكتوبة مسبقًا.
  
لم تعد عملية إنشاء سيرة ذاتية تحتاج إلى أن تكون مهمة مرهقة ومخيفة. بمساعدة مُنشئ السيرة الذاتية عبر الإنترنت، يمكنك بسهولة إبراز مهاراتك وإنجازاتك وخبراتك بالطريقة الصحيحة.
 
لا داعي للقلق بشأن استخدام أيٍّ من القوالب الجاهزة المتوفرة لدينا لأنك تستطيع جعل التصميم فريدًا ويحمل طابعك الخاص. هل تريد إضافة قسم جديد يتضمن الجوائز التي حصلت عليها؟ هل ترى أنه من الأفضل تغيير ترتيب الأقسام؟ ربما تريد تغيير الألوان أو تعديل التنسيق لجعله أكثر أناقة وممتعًا بصريًا. بنقرات قليلة فقط يمكنك بسهولة إجراء كل التعديلات التي تريدها.

  `,
      `
## أداة قوية، نتيجة فريدة
 
الغرض من سيرتك الذاتية هو عرض مؤهلاتك على جهات التوظيف وأصحاب العمل على حدٍ سواء. مهاراتك وتعليمك وخبراتك وإنجازاتك هي ما ستجلب لك الوظيفة. لذلك يتطلب إنشاء سيرة ذاتية الكثير من العمل من جانبك.

عليك أن تلقي نظرة على مسيرتك المهنية وأن تحدد بعناية أي من قدراتك وإمكانياتك هي التي لها صلة بالوظيفة التي تسعى للحصول عليها. من أجل التركيز على هذا الجانب، من المهم جدًا ألا تضطر إلى التعامل مع المشكلات التقنية أو مشكلات التنسيق أو حتى تصميم الجرافيك.

هذا هو السبب الذي ابتكرنا من أجله [أداة إنشاء السيرة الذاتية عبر الإنترنت](/ar) لنقدم لك الطريقة التي ترفع بها عن كاهلك عبء الأمور التقنية للسيرة الذاتية، مما يتيح لك التركيز على ما هو أكثر أهمية. كرّس وقتك وجهدك للأساسيات وأضف المزيد من المحتوى في سيرتك الذاتية. تأكد من أن ملفك الشخصي وخلفيتك يبرزان الأسباب التي تجعلك الشخص المناسب للفوز بالوظيفة.

تتيح لك خدمتنا إنشاء السيرة الذاتية المثالية لزيادة فرصك بشكل كبير في الحصول على وظيفة أو فترة تدريب أو تكليف جديدة أو التقديم للحصول على دورة تدريبية.
  `,
      `
## مكتبة هائلة من الموارد للعثور على القالب المناسب لسيرتك الذاتية
 
مع My Smart CV نحن نقدم لك أسهل طريقة لإيجاد القالب المناسب لسيرتك الذاتية.

في الوقت الحالي يمكن أن يكون استخدام قالب عام وموحَّد للسيرة ذاتية أمرًا غير فعال في جذب انتباه صاحب العمل. للتميز عن الآخرين من الأفضل أن تكون لديك سيرة ذاتية فريدة واستثنائية ومصممة خصيصًا لتتناسب مع متطلبات وظيفة شاغرة معينة. لن يساعد ذلك فقط في تمييز تقديمك للوظيفة عن تقديمات الآخرين، ولكنه سيسمح لك أيضًا بتسليط الضوء على السبب الذي يجعلك شخصيًا تمثل إضافة للشركة.

وبالإضافة إلى ذلك فعند التقديم لوظائف مختلفة، ضع في اعتبارك أنه لا ينبغي عليك استخدام نفس السيرة الذاتية في كل مرة. لحسن الحظ، مع مُنشئ السيرة الذاتية المجاني عبر الإنترنت من My Smart CV يمكنك العثور على عدد كبير من نماذج السير الذاتية التي يتم تعديلها لتتناسب مع القطاعات التجارية والمجالات المختلفة.

  `,
      `
## أداة مرنة ومتعددة الاستخدامات لتصميم سيرتك الذاتية

معظم أصحاب العمل سيطلبون من المتقدمين أن يقدموا السيرة الذاتية بصيغة Word أو PDF. بينما قد يكون لدى البعض الآخر منهم متطلبات مختلفة. سواء كنت تريد أن تكون سيرتك الذاتية بصيغة Word أو PDF أو حتى بصيغة أخرى، يمكنك في أي وقت استخدام خدمتنا لإنشاء السيرة الذاتية عبر الإنترنت. كل ما عليك القيام به هو اختيار صيغة الملف المفضلة لديك لإنشاء وتحرير سيرتك الذاتية المجانية عبر الإنترنت بمنتهى السهولة.

من خلال خدمة إنشاء السيرة الذاتية عبر الإنترنت، فأنت لا توفر الوقت فحسب، ولكنك ستجد أيضًا نصائح واقتراحات للكتابة مع محتوى مكتوب مسبقًا وقابل للتعديل.

ولكن أتعرف ما هي أفضل مزايا هذه الطريقة؟ هي أنك لا تحتاج إلى تنزيل أدوات أو تثبيت برامج على جهاز الكمبيوتر لديك. يمكنك تعديل المستند الخاص بك عدة مرات بقدر ما تريد، ومن أي مكان طالما أن لديك اتصال بالإنترنت!

`,
      `
## أنشئ سيرتك الذاتية الآن مع My Smart CV

إذا كنت بحاجة إلى إنشاء سيرة ذاتية الآن، فإليك نبذة موجزة حول الأسباب التي تجعل من الأداة التي نقدمها لإنشاء السيرة الذاتية عبر الإنترنت هي أفضل خيار يمكنك الحصول عليه مقارنةً بأية أدوات أخرى في السوق.

**احترافية مضمونة**
تم تطوير قوالب السيرة الذاتية لدينا ومراجعتها من قبل خبراء متخصصين. بغض النظر عن المجال أو الوظيفة التي تتقدم لها، يمكننا دائمًا توفير قالب مناسب خصيصًا لك. بالإضافة إلى ذلك فأنت على ثقة من وجود تصميم حديث وملفت للنظر وسهل القراءة

موقعنا يزخر بنصائح واقتراحات لكتابة السيرة الذاتية من أجل مساعدتك في عمل السيرة الذاتية المثالية. يمكنك أيضًا الحصول على أفكار جديدة من النماذج والعينات المكتوبة مسبقًا التي نقدمها لك، مما يسهل عليك تحسين محتوى سيرتك الذاتية.

**بدون متاعب أو إضاعة للوقت**.
باستخدام أداة إنشاء السيرة الذاتية عبر الإنترنت التي نقدمها لك، سيكون لديك خيار تعبئة القالب الجاهز ببياناتك وتفاصيل خلفيتك الشخصية. بالتأكيد أنت لست بحاجة للبدء من نقطة الصفر!

بالإضافة إلى ذلك، يمكنك توفير الوقت المهدر في التنسيق والتحرير عند استخدام قوالبنا المجانية. لا داعي للتوتر وبذل الجهد في إعداد التصميم والتفاصيل التقنية الأخرى. نحن نحرص على أن تكون منصتنا بسيطة وسهلة الاستخدام.

**سهولة التعديل والتخصيص**.
يمكن أن تكون سيرتك الذاتية التي تحصل عليها عبر الإنترنت عصرية للغاية وأنيقة ومبتكرة. اختر ما إذا كنت تريد إدراج صورة أم لا. يمكنك تغيير الألوان والرسومات وتصميم سيرتك الذاتية ببضع نقرات فقط. قم بإضفاء طابعك الشخصي بقدر ما تريد أن تجعلها متميزة.

لن تكون عملية إنشاء سيرتك الذاتية مصدرًا للإحباط والقلق بعد الآن عند استخدام مُنشئ السيرة الذاتية My Smart CV. سوف توفر الوقت وتضمن الحصول على نتيجة احترافية جذابة. الآن يمكنك تسليط الضوء على مؤهلاتك وزيادة فرصك في الفوز بوظيفة أحلامك!

`,
    ],
    'resume-template': {
      en: [
        `
## How to Choose the Best Resume Template?

Creating an impressive resume that stands out from all the others is made easy with My Smart CV. You don’tneed graphic designing skills to develop an excellent CV that can land you a job. All you need is to choose the best resume template and start personalizing it to your own liking. 

You will see many choices of resume templates in our online library with different styles for all kinds of profiles. So how do you choose the most appropriate template for your profile, profession, experience, and desired job? Keep reading on how to choose the best resume template here at [My Smart CV](/).

Why Should You Choose a Resume Template?

It’s going to be a real headache to come up with an impressive resume template from scratch. You need to match the colors, choose a readable font, learn how to align the blocks of text, or add in little details. In short, you’re going to need a creative eye and necessary graphic design skills. 

That’s why it’s going to be better to choose a resume template rather than going through the headache of making one from scratch. Here are some benefits if you choose a resume template here at My Smart CV. 

* **Using a resume template can save you time:** When designing a resume template, it will take the time it can take hours or even days in the making. Rather than wasting time on designing and unleashing your graphic designing skills, focus on how to improve your content.
* **Our resume templates have a modern design:** If you want your resume to stand out, you need choices of modern template designs. We have many modern design resume templates that are continuously improved to your liking.
* **We have many choices in colors and layouts:** The more choices there are, the easier it is to find a perfect resume template that suits you best. Since it is suggested to choose a resume template differently depending on the profession, you won’t have a hard time finding from the many choices available at My Smart CV. 
* **Easy to personalize resume template:** Our resume template is easy to edit so you can personalize the contents. The colors, font size, font type, headings can be easily customized.
* **Increase your chances of landing a job:** The professional-looking resume templates we have will increase your chances of landing a job. Your smart resume will give that impression to recruiting managers that you are an expert and experienced person that will make them want to hire you.   
`,
        `
## What Are the Criteria in Selecting an Impressive CV Template?

With the many selections of resume templates, it’s no walk in the park when choosing. If you’re not quite sure how to select which resume template suits your needs best, here are some criteria you should consider.

1 . **Select depending on the position and company:** If you’reapplying for a marketing position for an event planning company, you might want to express your creativity on your resume by choosing a more original design with more graphic features. For an executive job, you might want to choose a classic modern designwith more sober colors.
 
2 . **Select depending on your level of experience:** If you’re a college student or a new graduate about to enter the workforce, you may want to opt for a template that emphasizes your diploma and training. For more experienced professionals, highlight your work experience and skills. Also, it is suggested that you choose a template that has two columns so you can fit everything you need to place on the CV.

3 . **Select depending on your profession:** There are many CVs to choose from depending on your career. It can be the finance, healthcare, food industry, or law firms. These templates have specific icons, illustrations, or headings that are designed for a particular profession.
 
4 . **Select according to your personality:** The CV is considered a great way to express your personality. So it’s always best to choose a template you think will represent who you are. Of course, on top of the three criteria mentioned above. You always have the freedom to add details that expresses yourself. It can be a specific layout, a dominant color, or even a new graphic design. 
`,
      ],
      id: [
        `
## Bagaimana Cara Memilih Template Resume Terbaik?

Membuat resume yang mengesankan yang menonjol dari yang lainnya menjadi mudah dengan adanya My Smart CV. Anda tidak perlu keahlian desain grafis untuk menghasilkan CV bermutu yang dapat memberi anda pekerjaan. Yang anda butuhkan hanyalah memilih template resume terbaik dan mulai mengubahnya sesuai keinginan Anda.

Anda akan melihat banyak pilihan template resume di perpustakaan online kami dengan ragam berbeda untuk semua jenis profil. Jadi, bagaimana anda memilih template yang sesuai untuk profil, profesi, pengalaman, dan pekerjaan yang anda inginkan? Teruslah membaca tentang cara memilih templat resume terbaik di sini di MySmartCV.

Mengapa Anda Harus Memilih Template Resume?

Menghasilkan template resume yang mengesankan dari awal akan sangat menyusahkan. Anda perlu mencocokkan warna, memilih font yang dapat dibaca, mempelajari cara menyelaraskan blok teks, atau menambahkan sedikit detail. Singkatnya, anda akan membutuhkan kreatifitas dan keterampilan desain grafis yang dibutuhkan.

Itulah mengapa akan lebih baik memilih template resume daripada repot-repot membuatnya dari awal. Berikut adalah beberapa keuntungan jika anda memilih templat resume di sini di MySmartCV.

* **Menggunakan template resume dapat menghemat waktu anda:** Mendesain template resume membutuhkan waktu berjam-jam atau bahkan berhari-hari dalam pembuatannya. Daripada membuang waktu untuk merancang dan melepaskan keterampilan mendesain grafis, fokuslah pada cara meningkatkan konten Anda.
* **Templat resume kami memiliki desain modern:** Jika anda ingin resume yang menonjol, anda memerlukan pilihan desain template yang kekinian. Kami memiliki banyak template resume desain terbaru yang terus ditingkatkan sesuai keinginan anda.
* **Kami memiliki banyak pilihan warna dan tata letak:** Semakin banyak pilihan, semakin mudah menemukan template resume sempurna yang paling cocok untuk anda. Karena disarankan untuk memilih template resume yang berbeda tergantung pada profesinya, anda tidak akan kesulitan menemukan banyak pilihan yang tersedia di My Smart CV.
* **Mudah untuk mengubah template resume: ** Template resume kami mudah diedit sehingga dapat dimodifikasi isinya. Warna, ukuran font, jenis font, heading dapat dengan mudah diubah dan disesuaikan.
* **Meningkatkan peluang anda untuk mendapatkan pekerjaan:** Templat resume profesional yang kami miliki akan meningkatkan peluang anda untuk mendapatkan pekerjaan. Resume pintar anda akan memberi kesan kepada manajer perekrutan bahwa anda adalah orang yang ahli dan berpengalaman yang akan membuat mereka ingin mempekerjakan anda.
`,
        `
## Apa Kriteria dalam Memilih Template CV yang Mengesankan?

Dengan banyaknya pilihan template resume, tidaklah mudah saat menentukan pilihan. Jika anda tidak yakin bagaimana memilih template resume yang paling sesuai dengan kebutuhan anda, berikut adalah beberapa kriteria yang harus dipertimbangkan.

1 . **Pilih berdasarkan posisi dan perusahaan:** Jika anda melamar posisi pemasaran untuk perusahaan bidang perencanaan acara, anda bisa menunjukkan kreativitas anda pada resume dengan memilih desain yang lebih orisinal dengan lebih banyak fitur grafis. Untuk pekerjaan eksekutif, anda mungkin bisa memilih desain modern klasik dengan warna yang lebih sederhana.
 
2 . **Pilih berdasarkan tingkat pengalaman anda:** Jika anda seorang mahasiswa atau lulusan baru yang akan memasuki dunia kerja, anda dapat memilih template yang menekankan diploma dan pelatihan anda. Untuk ahli yang lebih berpengalaman, tonjolkan pengalaman dan keterampilan kerja anda. Selain itu, disarankan agar anda memilih template dengan dua kolom sehingga dapat memasukkan semua yang anda butuhkan untuk ditempatkan di CV.

3 . **Pilih tergantung pada profesi anda:** Ada banyak CV yang dapat dipilih tergantung pada karir anda. Bisa berupa keuangan, perawatan kesehatan, industri makanan, atau firma hukum. Template ini memiliki ikon, ilustrasi, atau bagian atas khusus yang dirancang untuk profesi tertentu.
 
4 . **Pilih sesuai dengan kepribadian anda:** CV dianggap sebagai cara yang bagus untuk mengekspresikan kepribadian anda. Jadi, memilih template yang menurut anda akan mewakili siapa diri anda adalah pilihan yang baik. Tentu saja, di atas tiga kriteria yang disebutkan di atas. anda tetap memiliki kebebasan untuk menambahkan detail yang mengekspresikan diri anda. Bisa berupa tata letak tertentu, warna dominan, atau bahkan desain grafis baru.
`,
      ],
      es: [
        `
## ¿Cómo elegir la mejor plantilla de currículum?

Crear un currículum impresionante que se destaque de todos los demás es fácil con My Smart CV. No necesitas habilidades de diseño gráfico para desarrollar un excelente CV que pueda conseguirte un trabajo. Todo lo que necesitas es elegir la mejor plantilla de currículum y comenzar a personalizarla a tu gusto.
 
Verás muchas opciones de plantillas de currículum en nuestra biblioteca en línea con diferentes estilos para todo tipo de perfiles. Entonces, ¿cómo elegir la plantilla más adecuada para tu perfil, profesión, experiencia y trabajo deseado? Sigue leyendo sobre cómo elegir la mejor plantilla de currículum aquí en My Smart CV.

¿Por qué deberías elegir una plantilla de currículum?

Va a ser un verdadero dolor de cabeza crear una plantilla de currículum impresionante desde cero. Debes hacer coincidir los colores, elegir una fuente legible, aprender a alinear los bloques de texto o agregar pequeños detalles. En resumen, necesitarás un ojo creativo y las habilidades de diseño gráfico necesarias.
 
Es por eso que será mejor elegir una plantilla de currículum en lugar de pasar por el dolor de cabeza de crear una desde cero. Aquí hay algunos beneficios si eliges una plantilla de currículum aquí en My Smart CV.

* **Usar una plantilla de currículum puede ahorrarte tiempo:** cuando diseñes una plantilla de currículum, te tomará un tiempo que puede ser horas o incluso días. En lugar de perder el tiempo diseñando y desatando tus habilidades de diseño gráfico, concéntrese en cómo mejorar tu contenido.
* **Nuestras plantillas de currículum tienen un diseño moderno:** si deseas que tu currículum se destaque, necesitas opciones de diseños de plantillas modernas. Tenemos muchas plantillas de currículum de diseño modernas que se mejoran continuamente a tu gusto.
* **Tenemos muchas opciones en colores y diseños: ** cuantas más opciones haya, más fácil será encontrar una plantilla de currículum perfecta que se adapte mejor a tus necesidades. Dado que se sugiere elegir una plantilla de currículum de manera diferente según la profesión, no tendrás dificultades para encontrar entre las muchas opciones disponibles en My Smart CV. 
* **Plantilla de currículum fácil de personalizar:** Nuestra plantilla de currículum es fácil de editar para que puedas personalizar el contenido. Los colores, el tamaño de fuente, el tipo de fuente y los títulos se pueden personalizar fácilmente.
* **Aumenta tus posibilidades de conseguir un trabajo:** las plantillas de CV de aspecto profesional que tenemos aumentarán tus posibilidades de conseguir un trabajo. Tu currículum inteligente dará la impresión a los gerentes de contratación de que tu eres una persona experta y experimentada que hará que quieran contratarte.   
`,
        `
## ¿Cuáles son los criterios para seleccionar una plantilla de CV impresionante?

Con las muchas selecciones de plantillas de currículum, no es fácil elegir. Si no estás seguro de cómo seleccionar qué plantilla de currículum se adapta mejor a tus necesidades, aquí hay algunos criterios que debes considerar.

1 . **Selecciona según el puesto y la empresa:** si solicitan un puesto de marketing para una empresa de planificación de eventos, es posible que desees expresar tu creatividad en tu currículum eligiendo un diseño más original con más características gráficas. Para un trabajo ejecutivo, es posible que desees elegir un diseño moderno clásico con colores más sobrios.
 
2 . **Selecciona según tu nivel de experiencia:** si eres un estudiante universitario o un recién graduado a punto de ingresar al ambiente laboral, es posible que desees optar por una plantilla que enfatice tu diploma y capacitación. Para profesionales más experimentados, resalta tu experiencia laboral y habilidades. Además, se sugiere que elijas una plantilla que tenga dos columnas para que puedas ajustar todo lo que necesitas colocar en el CV.

3 . **Selecciona en función de tu profesión:** hay muchos CV para elegir según tu carrera. Puede ser la industria financiera, sanitaria, alimentaria o bufetes de abogados. Estas plantillas tienen íconos, ilustraciones o encabezados específicos diseñados para una profesión en particular.
 
4 . **Selecciona según tu personalidad:** El CV se considera una excelente manera de expresar tu personalidad. Por lo tanto, siempre es mejor elegir una plantilla que crea que representará quién eres tú. Por supuesto, además de los tres criterios mencionados anteriormente. Tu siempre tienes la libertad de agregar detalles que expresen lo que tú mismo eres. Puede ser un diseño específico, un color dominante o incluso un nuevo diseño gráfico. 
`,
      ],
      ar: [
        `
## كيف تختار أفضل قالب للسيرة الذاتية؟

إنشاء سيرة ذاتية رائعة ومميزة عن غيرها من السيّر الذاتية أصبح أمرًا سهلًا بفضل استخدام My Smart CV. لست بحاجة إلى إجادة مهارات تصميم الجرافيك لعمل سيرة ذاتية متقنة يمكن أن تجعلك تفوز بالوظيفة التي تحلم بها. كل ما تحتاجه هو اختيار أفضل قالب للسيرة الذاتية والبدء في إضفاء الطابع الشخصي عليه وتعديله حسب رغبتك.

سترى العديد من الخيارات لقوالب السيرة الذاتية في مكتبتنا الإلكترونية من مختلف الطرز والأنماط لجميع أنواع الملفات الشخصية. إذن كيف تختار القالب المناسب الأكثر ملائمة لملفك الشخصي وكذلك لمهنتك وخبراتك والوظيفة التي تريدها؟ استمر في القراءة لمعرفة كيفية اختيار أفضل قالب للسيرة الذاتية هنا في [My Smart CV](/ar).

لماذا يجب عليك اختيار قالب للسيرة الذاتية؟

سيكون من الصعب والمرهق للغاية أن تقوم بإنشاء قالب سيرة ذاتية مبهر ومثير للإعجاب من نقطة الصفر. لأنك سوف تحتاج إلى مطابقة الألوان، واختيار خط سهل القراءة، وأن تتعلم كيفية محاذاة كتل النص، أو إضافة تفاصيل صغيرة. باختصار، سوف تكون بحاجة إلى عين إبداعية وإجادة مهارات تصميم الجرافيك اللازمة.

هذا هو السبب الذي يجعل من الأفضل بالنسبة لك اختيار قالب سيرة ذاتية جاهز بدلًا من مواجهة مشكلة إنشاء قالب من الصفر. فيما يلي بعض المزايا التي ستحصل عليها إذا اخترت أحد قوالب السيرة الذاتية الجاهزة الموجودة هنا في My Smart CV.

* **استخدام قالب للسيرة الذاتية يمكن أن يكون موفرًا للوقت:**
عند تصميم قالب سيرة ذاتية، سيستغرق الأمر وقتًا قد يصل إلى ساعات أو حتى أيام في عمله. بدلًا من إضاعة الوقت في تصميم القالب وإطلاق العنان لمهاراتك في تصميم الجرافيك، ركز على كيفية تحسين المحتوى الموجود في سيرتك الذاتية نفسها.
* **تتميز قوالب السيرة الذاتية لدينا بتصميمات عصرية:**
إذا كنت تريد أن تجعل سيرتك الذاتية مميزة عن غيرها، فأنت بحاجة إلى خيارات متنوعة من تصميمات القوالب العصرية. لدينا العديد من قوالب السيرة الذاتية ذات التصميم الحديث والراقي والتي يتم تحسينها باستمرار حسب رغبتك.
* **لدينا العديد من الخيارات في الألوان والمخططات:**
كلما زادت الاختيارات وتنوعت، كان من الأسهل العثور على قالب سيرة ذاتية مثالي يناسبك بشكل أفضل. نظرًا لأنه يُنصح باختيار قالب سيرة ذاتية بشكل مختلف وفقًا لطبيعة المهنة التي تتخصص فيها، فلن تجد صعوبة في العثور على ما يلائمك من بين الخيارات العديدة المتاحة في My Smart CV.
* **قالب سيرة ذاتية سهل التعديل والتخصيص:**
قالب السيرة الذاتية لدينا سهل التحرير حتى تتمكن من تخصيص المحتويات وإضفاء طابعك الشخصي عليه. يمكن تعديل وتخصيص الألوان وحجم الخط ونوع الخط والعناوين بكل سهولة.
* **زيادة فرصك في الحصول على وظيفة:**
قوالب السيرة الذاتية ذات المظهر الاحترافي التي نقدمها ستزيد من فرصك في الحصول على وظيفة. ستعطي سيرتك الذاتية الذكية هذا الانطباع لمديري التوظيف بأنك شخص محترف وذو خبرة وستجعلهم يرغبون في توظيفك.
`,
        `
## ما هي المعايير المتبعة عند اختيار قالب جذاب للسيرة الذاتية؟

مع وجود كل هذا العدد الهائل من الخيارات المتنوعة لنماذج السيرة الذاتية، لن يكون الأمر بالسهولة التي تتصورها عند الاختيار من بينها. إذا لم تكن متأكدًا تمامًا من كيفية تحديد نموذج السيرة الذاتية الذي يناسب احتياجاتك بشكل أفضل، فإليك بعض المعايير التي ينبغي أخذها في الاعتبار:

1. **حدد خيارك بناءً على الوظيفة والشركة:**
إذا كنت تقدم طلبًا للحصول على منصب تسويقي لشركة تنظيم للمناسبات، فقد ترغب في التعبير عن إبداعك في سيرتك الذاتية عن طريق اختيار تصميم أكثر أصالة مع المزيد من ميزات رسوم الجرافيك. في المقابل إذا كنت بصدد التقدم لوظيفة تنفيذية، فقد ترغب في اختيار تصميم كلاسيكي حديث بألوان أكثر رصانة.

2. ** حدد خيارك بناءً على مستوى خبرتك:**
إذا كنت طالبًا جامعيًا أو خريجًا جديدًا على وشك الانضمام إلى سوق العمل، فقد ترغب في اختيار نموذج يبرز شهادتك وتدريبك. أما بالنسبة للمهنيين الأكثر خبرة، فسوف تريد إبراز خبراتك ومهاراتك في العمل. كذلك يُنصح باختيار قالب يحتوي على عمودين حتى تتمكن من احتواء كل ما تحتاج إلى وضعه في السيرة الذاتية.

3. ** حدد خيارك بناءً على مهنتك وتخصصك:**
هناك العديد من السير الذاتية للاختيار من بينها اعتمادًا على مسيرتك المهنية. يمكن أن تكون المحاسبة المالية أو الرعاية الصحية أو صناعة الأغذية أو المحاماة. تحتوي هذه القوالب على أنواع مخصصة من الأيقونات أو الرسوم التوضيحية أو العناوين والتي تم تصميمها خصيصًا لكل مهنة معينة.

4. ** حدد خيارك وفقًا لطبيعة شخصيتك:**
تعتبر السيرة الذاتية طريقة رائعة للتعبير عن شخصيتك. لذلك من الأفضل دائمًا اختيار نموذج تعتقد أنه سيمثل هويتك. هذا بالطبع بالإضافة إلى المعايير الثلاثة المذكورة أعلاه. لديك دائمًا حرية إضافة التفاصيل التي تعبر عن نفسك. يمكن أن يشمل القالب المختار تخطيطًا محددًا أو يسوده لونًا بعينه أو حتى أن يكون تصميمًا رسوميًا جديدًا.

        `,
      ],
    },
    'template-cv': {
      en: [
        `
## CV Template:the easiest to start well(for MySmartCV)
Coming up with an outstanding CV is the first step to land your dream job. If you have no idea how to make one, that’s okay. That’s what resume templates are for. You don’tneed to spend hours or a couple of days thinking about the best chronological order, the right format, heading statement, graphic details, or relevant information to place on your CV.

Here at My Smart CV, all you need to do is pick the resume template you like most. Just fill out the information asked, upload a professional photo, and you can start applying to jobs. If you want to learn how efficient resume templates are, just keep reading this post. 

### What Is a CV Template?

A CV template is a ready-madecurriculum vitae format where you only need to input the contents required. It is a ready-to-use CV frame that can be customized and already contains different sections for easy reference. 

It’s usually readily divided into the pertinent sections that should be found in a CV, such as academic background, work experience, training attended, affiliations, and so on. In short, if you intend to have an exceptional CV without breaking a sweat, a resume template is your best option.

Here at My Smart CV, we have different kinds of resume templates you can choose from. There are classic templates to modern designs. Regardless of your profession, you’ll find the perfect fit for you. Making a resume from scratch won’t be easy. That is why we have made the work easyfor you. 

### CV Template: Your Work Made Easy

At one point in your life, you have tried making a CV from scratch. How long did it take you? 3-5 hours on the design alone? Add up the time where you change your mind on the color scheme and chronological order. Then another 1-2 hours on the content and proofreading?

It will probably take up to 8 hours of your time to come up with an excellent CV. But it can be a source of stress, and it will be draining. Why put yourself through all that when you can make your CV in less than an hour?

Your work is made easy, and you can focus more on the quality of your content. After all, you’re trying to impress the recruiters with your excellent portfolio. That’s what you have to focus on. Here are the benefits of a CV resume and how it can make your work easier for you:

* These resume templates are tested and proven by recruiters.
* You save time designing and coming up with matching graphics.
* All you have to do is follow the format of the CV and enter your important information. 
* You can modify the details like colors, fonts, and sections with just a click. 
* All the necessary sections are there; you don’t need to add anything. 

### How to Choose the CV Template for You?

There are many CV template designs; how will you choose the best one to reflect who you really are? It’s not only something your eyes like, but it must represent you and be suitable for the job and company you’re applying for. Here are some tips you can follow. 

**Choose a CV That Suits Your Taste and Personality**

Why not choose a CV template with your favorite colors? Choose a CV template that suits your style, but be careful not to overdo it. You don’t want it to look like a 4th of July poster. It’s still a professional document that can’t be fancy. 

**Choose a CV Template According to Your Profession**

It is vital to choose a template suitable for your profession and the company you’re applying for. If you’re applying for a graphic designing job in an IT company, you would want to impress the company with your editing skills by choosing a one-of-a-kind template. 

If you aim to land a corporate job, you might want to lessen those fancy little details. So it’s not just about what looks pretty or cool; you haveto be mindful of your choices. Ask yourself, ‘Is this something the HR specialist likes to look at? Or will it look annoying?’ 

**Choose a Template According to Your Professional Experience**

There are CV templates designed for students and entry-level professionals with no experience. These mostly highlight your training and academic background. Meanwhile, there are also templates for professionals emphasizing the job experience. 

That is why you have to choose the CV structure depending on your work experience. Landing your dream job is all about a good strategy, and your best weapon is an outstanding resume template from [My Smart CV](/).

`,
      ],
      id: [
        `
## Template CV: yang termudah untuk memulai dengan baik (untuk MySmartCV)
Memiliki CV yang unggul adalah langkah pertama untuk mendapatkan pekerjaan impian anda. Jika anda tidak tahu cara membuatnya, tidak apa-apa. Itulah gunanya template resume dibuat. Anda tidak perlu menghabiskan berjam-jam atau beberapa hari untuk memikirkan tentang urutan kronologis terbaik, format yang tepat, pernyataan judul, detail grafik, atau informasi yang relevan untuk ditempatkan di CV Anda.

Di sini, di My Smart CV, yang perlu anda lakukan hanyalah memilih template resume yang paling anda suka. Isi saja informasi yang diminta, unggah foto profesional, dan anda bisa mulai melamar pekerjaan. Jika anda ingin mempelajari seberapa efisien template resume, tetap bacalah postingan ini.

### Apa Itu Template CV?

Template CV adalah format curriculum vitae yang sudah jadi dimana anda hanya perlu memasukkan isi yang dibutuhkan. Ini adalah bingkai CV siap pakai yang dapat disesuaikan dan sudah berisi bagian berbeda untuk referensi yang mudah.

Ini biasanya sudah dibagi menjadi bagian bersangkutan yang harus ada di CV, seperti latar belakang akademis, pengalaman kerja, pelatihan yang pernah diikuti, afiliasi, dan sebagainya. Singkatnya, jika anda ingin memiliki CV yang berbeda dari lainnya tanpa harus bersusah payah, template resume adalah pilihan terbaik untuk anda.

Di sini, di My Smart CV, kami memiliki berbagai jenis template resume yang dapat anda pilih. Ada template klasik hingga desain modern. Apa pun profesi anda, anda akan menemukan yang paling cocok untuk anda. Membuat resume dari awal tidak akan mudah. Itulah mengapa kami mempermudah pekerjaan anda.

### Template CV: Pekerjaan Anda Menjadi Mudah

Pada suatu titik, anda pernah mencoba membuat CV dari awal. Berapa lama waktu yang anda butuhkan? 3-5 jam di desain saja? Tambahkan waktu ketika anda berubah pikiran pada pola warna dan urutan kronologis. Lalu 1-2 jam lagi untuk konten dan mengoreksi?

Mungkin anda perlu waktu hingga 8 jam untuk menghasilkan CV yang bagus. Tapi itu bisa membuat anda tertekan, dan itu akan menguras tenaga. Mengapa harus melalui semua itu jika anda dapat membuat CV dalam waktu kurang dari satu jam?

Pekerjaan anda menjadi mudah, dan anda dapat lebih fokus pada kualitas konten anda. Itulah yang harus anda fokuskan jika anda mencoba membuat pihak yang merekrut terkesan dengan portofolio yang luar biasa. Berikut adalah manfaat dari resume CV dan bagaimana ini membuat pekerjaan anda lebih mudah:

* Template resume ini telah diuji dan dibuktikan oleh perekrut.
* Anda menghemat waktu merancang dan membuat grafik yang sesuai.
* Yang harus anda lakukan adalah mengikuti format CV dan memasukkan informasi penting.
* Anda dapat mengubah detail seperti warna, font, dan bagian hanya dengan satu klik.
* Semua bagian yang diperlukan ada di sana; Anda tidak perlu menambahkan apapun.

### Bagaimana Cara Memilih Template CV untuk Anda?

Ada banyak desain template CV; bagaimana cara memilih yang terbaik untuk mencerminkan siapa anda sebenarnya? Ini bukan hanya sesuatu yang menarik untuk dilihat, tetapi harus mewakili diri dan cocok untuk pekerjaan dan perusahaan yang anda lamar. Berikut beberapa tips yang bisa anda ikuti.

**Pilih CV Yang Sesuai Dengan Selera dan Kepribadian Anda**

Mengapa tidak memilih template CV dengan warna favorit anda? Pilih template CV yang sesuai dengan selera anda, tapi hati-hati dan jangan sampai berlebihan. Jangan sampai terlihat seperti poster kemerdekaan. Ini merupakan dokumen profesional yang tidak seharusnya terlalu berlebihan.

**Pilih Template CV Sesuai Profesi Anda**

Sangat penting untuk memilih template yang sesuai dengan profesi anda dan perusahaan yang anda lamar. Jika anda melamar pekerjaan desain grafis di perusahaan IT, anda pasti ingin membuat perusahaan terkesan dengan keterampilan mengedit anda dengan memilih template spesial.

Jika anda ingin mendapatkan pekerjaan di perusahaan, Anda mungkin harus mengurangi detail-detail kecil yang terkesan berlebihan. Jadi, ini bukan hanya tentang apa yang terlihat bagus atau keren; Anda harus memperhatikan apa yang anda pilih. Tanyakan pada diri anda, ‘Apakah ini sesuatu yang akan disukai oleh spesialis HR? Atau malah akan terlihat mengganggu? "

**Pilih Template Sesuai dengan Pengalaman Profesional Anda**

Ada template CV yang dirancang untuk siswa dan profesional tingkat pemula yang tidak memiliki pengalaman. Ini sebagian besar menyoroti pelatihan dan latar belakang akademis anda. Sementara itu, ada juga template untuk para profesional yang menekankan pada pengalaman kerja.

Itulah mengapa anda harus memilih struktur CV tergantung pada pengalaman kerja. Mendapatkan pekerjaan impian juga tergantung pada pemilihan strategi yang baik, dan senjata terbaik anda adalah template resume yang unggul dari My Smart CV.

`,
      ],
      es: [
        `
## Plantilla de CV: la más fácil Para empezar bien (para MySmartCV)

Crear un excelente currículum es el primer paso para conseguir el trabajo de tus sueños. Si no tienes idea de cómo hacer uno, está bien. Para eso están las plantillas de CV. No necesitas pasar horas o un par de días pensando en el mejor orden cronológico, el formato correcto, la alineación de encabezado, los detalles gráficos o la información relevante para colocar en tu CV.

Aquí en My Smart CV, todo lo que necesitas hacer, es elegir la plantilla de currículum que más le guste. Simplemente completa la información solicitada, carga una foto profesional y podrás comenzar a postularte para los trabajos. Si quieres saber qué tan eficientes son las plantillas de currículum, continua leyendo esta publicación.

### ¿Qué es una plantilla de CV?

Una plantilla de CV es un formato de curriculum vitae listo para usar en el que solo se necesita ingresar los contenidos requeridos. Es un marco de CV listo para usar que se puede personalizar y ya contiene diferentes secciones para una fácil referencia.

Por lo general, se divide fácilmente en las secciones pertinentes que se deben encontrar en un CV, como antecedentes académicos, experiencia laboral, capacitación asistida, afiliaciones, etc. En resumen, si tienes la intención de tener un CV excepcional sin sudar entonces una plantilla de CV es su mejor opción.

Aquí en My Smart CV, tenemos diferentes tipos de plantillas de currículum entre las que puedes elegir. Hay plantillas clásicas para diseños modernos. Independientemente de tu profesión, encontrarás la opción perfecta para ti. Hacer un currículum desde cero no será fácil. Es por eso que te hemos facilitado el trabajo.

### Plantilla de CV: Tu trabajo simplificado

En un momento de tu vida, has intentado hacer un CV desde cero. ¿Cuánto tiempo te llevó? 3-5 horas solo en el diseño? Suma el tiempo en el que cambiaste de opinión sobre la combinación de colores y el orden cronológico. ¿Luego otras 1-2 horas sobre el contenido y la revisión?

Probablemente te tomará hasta 8 horas de tu tiempo en crear un excelente CV, pero puede ser una fuente de estrés y será agotador. ¿Por qué pasar por todo esto cuando puedes hacer tu CV en menos de una hora?

Tu trabajo es fácil y puedes concentrarte más en la calidad de tu contenido. Después de todo, está stratando de impresionar a los reclutadores con tu  excelente cartera. Eso es en lo que debes de concentrarte. Estos son los beneficios de un currículum vitae y cómo puede facilitarle el trabajo:

* Los reclutadores prueban estas plantillas de currículum.
* Ahorras tiempo diseñando y creando gráficos coincidentes.
* Todo lo que tienes que hacer es seguir el formato del CV e ingresar tu  información importante. 
* Puede modificar detalles como colores, fuentes y secciones con solo un clic. 
* Todas las secciones necesarias están ahí; no es necesario que agregues nada. 

### ¿Cómo elegir la plantilla de CV para ti?

Hay muchos diseños de plantillas de CV; ¿Cómo elegirás el mejor para reflejar quién eres realmente? No solo es algo que le gusta a tus ojos, sino que debe representarte y ser adecuado para el trabajo y la empresa que solicita. Aquí hay algunos consejos que puedes seguir.

**Elije un CV que se adapte a tu  gusto y personalidad**

¿Por qué no elegir una plantilla de CV con tus colores favoritos? Elija una plantilla de CV que se adapte a tu  estilo, pero ten cuidado de no exagerar. No querrás que parezca un póster del 4 de julio. Sigue siendo un documento profesional que no puede ser elegante.

**Elije una plantilla de CV según tu profesión**

Es vital elegir una plantilla adecuada para tu profesión y la empresa a la que estás solicitando. Si estás solicitando un trabajo de diseño gráfico en una empresa de IT, querrás impresionar a la empresa con tus habilidades de edición eligiendo una plantilla única.

Si tu objetivo es conseguir un trabajo corporativo, es posible que desees reducir esos pequeños detalles elegantes. Así que no se trata solo de lo que se ve bonito o genial; tienes que ser consciente de tus elecciones. Pregúntate: "¿Es esto algo que le gustaría mirar al especialista de recursos humanos? o ¿se verá molesto?

**Elije una plantilla de acuerdo con tu experiencia profesional**

Hay plantillas de CV diseñadas para estudiantes y profesionales principiantes sin experiencia. Estos destacan principalmente su formación y antecedentes académicos. Mientras tanto, también hay plantillas para profesionales que enfatizan la experiencia laboral.

Por eso tienes que elegir la estructura del CV en función de tu experiencia laboral. Conseguir el trabajo de tus sueños se trata de una buena estrategia, y tu mejor arma es una plantilla de currículum excepcional de My Smart CV

`,
      ],
      ar: [
        `
## قالب السيرة الذاتية: أسهل طريقة للبدء بشكل جيد (لـ MySmartCV)
أن تكون لديك سيرة ذاتية متميزة هو الخطوة الأولى للحصول على وظيفة أحلامك. إذا لم تكن لديك فكرة عن كيفية صنع السيرة الذاتية من الأساس، فلا بأس بذلك. هذا هو الغرض من قوالب السيرة الذاتية. لا تحتاج إلى قضاء ساعات أو عدة أيام في التفكير في أفضل ترتيب زمني، أو التنسيق الصحيح، أو بيان العنوان، أو تفاصيل رسوم الجرافيك، أو المعلومات ذات الصلة لوضعها في سيرتك الذاتية.

هنا في My Smart CV كل ما عليك فعله هو اختيار قالب السيرة الذاتية الذي يعجبك أكثر. فقط قم بتعبئة المعلومات المطلوبة، وتحميل صورة شخصية احترافية، وسيمكنك البدء في التقدم للوظائف. إذا كنت تريد معرفة مدى كفاءة قوالب السيرة الذاتية، فما عليك سوى متابعة قراءة هذا المقال.

### ما هو قالب السيرة الذاتية؟

قالب السيرة الذاتية هو نموذج سيرة ذاتية جاهز مسبقًا حيث تحتاج فقط إلى إدخال المحتويات المطلوبة. إنه بمثابة إطار سيرة ذاتية جاهز للاستخدام يمكن تخصيصه وتعديله ويحتوي بالفعل على أقسام مختلفة لسهولة الرجوع إليها.

عادةً ما يكون مقسمًا بالفعل إلى الأقسام المهمة التي يجب أن تكون موجودة في السيرة الذاتية، مثل الخلفية الأكاديمية، وخبرات العمل، والتدريب الذي تلقيته، والجمعيات التي تنتمي إليها، وما إلى ذلك. باختصار إذا كنت تنوي الحصول على سيرة ذاتية استثنائية دون بذل أي جهد، فإن قالب السيرة الذاتية هو أفضل خيار لك.

هنا في My Smart CV لدينا أنواع مختلفة من قوالب السيرة الذاتية التي يمكنك الاختيار من بينها. هناك القوالب الكلاسيكية والتصميمات الحديثة. بغض النظر عن مهنتك، ستجد ما يناسبك تمامًا. لن يكون إنشاء سيرة ذاتية من الصفر أمرًا سهلًا. هذا هو السبب في أننا جعلنا هذه العملية سهلة تمامًا من أجلك.

### قالب السيرة الذاتية: عملك أصبح سهلًا
       
إذا كنت قد جربت في مرحلة ما من حياتك إنشاء سيرة ذاتية من الصفر. فما المدة التي استغرقتها؟ 3-5 ساعات على التصميم وحده؟ أضف إلى ذلك الوقت الذي تغير فيه رأيك بشأن نظام الألوان والترتيب الزمني لتسلسل الخبرات السابقة. ثم آخر ساعة أو ساعتين على المحتوى والتدقيق اللغوي؟

من المحتمل أن يستغرق الأمر ما يصل إلى 8 ساعات من وقتك للحصول على سيرة ذاتية ممتازة. لكنها يمكن أن تكون مصدرًا للتوتر والضغط العصبي، وسوف تستنزف طاقتك. لماذا تحمل نفسك كل ذلك العبء بينما يمكنك إنشاء سيرتك الذاتية في أقل من ساعة؟

عملك أصبح سهلًا، وهكذا يمكنك التركيز أكثر على جودة المحتوى الموجود بسيرتك الذاتية. في النهاية أنت تحاول إقناع مسؤولي التوظيف وإثارة إعجابهم بملفك الشخصي الممتاز. هذا ما عليك التركيز عليه. فيما يلي مزايا قوالب السيرة الذاتية وكيف يمكن أن تجعل عملك أسهل بالنسبة لك:

* تم اختبار قوالب السيرة الذاتية هذه واعتمادها بواسطة جهات التوظيف.
* يمكنك توفير الوقت المستغرق في التصميم والتوصل إلى رسومات مشابهة.
* كل ما عليك فعله هو اتباع نموذج السيرة الذاتية وإدخال معلوماتك المهمة.
* يمكنك تعديل التفاصيل مثل الألوان والخطوط والأقسام بنقرة واحدة فقط.
* جميع الأقسام الضرورية موجودة؛ لذا لن تكون بحاجة إلى إضافة أي شيء.

### كيف تختار قالب السيرة الذاتية المناسب لك؟

هناك العديد من تصميمات قوالب السيرة الذاتية. كيف ستختار الأفضل لتعبر عن شخصيتك حقًا؟ إنه ليس شيئًا تحبه عيناك فحسب، بل يجب أن يمثلك وأن يكون مناسبًا للوظيفة والشركة التي تتقدم لها. إليك بعض النصائح التي يمكنك اتباعها.

**اختر سيرة ذاتية تناسب ذوقك وشخصيتك**

لماذا لا تختار قالب السيرة الذاتية بألوانك المفضلة؟ اختر قالب سيرة ذاتية يناسب أسلوبك، لكن احرص على عدم المبالغة فيه. فأنت لا تريده أن يبدو مبهرجًا مثل ملصقات الأعياد. فلا تزال السيرة الذاتية مستندًا احترافيًا لا يمكن أن يكون طفوليًا.

**اختر قالب السيرة الذاتية وفقًا لمهنتك**

من الضروري اختيار قالب مناسب لمهنتك والشركة التي تتقدم لها. إذا كنت تتقدم لوظيفة تصميم جرافيك في إحدى شركات تكنولوجيا المعلومات، فقد ترغب في إثارة إعجاب الشركة بمهاراتك في التحرير من خلال اختيار نموذج فريد من نوعه.

إذا كنت تهدف إلى الحصول على وظيفة في شركة، فقد ترغب في تقليل هذه التفاصيل الصغيرة المزخرفة. لذا فالأمر لا يتعلق فقط بما يبدو جميلًا أو رائعًا؛ عليك أن تضع في اعتبارك اختياراتك وما يتوافق مع الوضع القائم. اسأل نفسك: "هل هذا الشكل سيحب اختصاصي الموارد البشرية النظر إليه؟ أم أنه سيبدو مزعجًا؟"

**اختر قالبًا وفقًا لخبراتك المهنية**

توجد قوالب سيرة ذاتية مصممة للطلاب والمهنيين المبتدئين بدون خبرة. هذه في الغالب تسلط الضوء على تدريبك وخلفيتك الأكاديمية. وفي الوقت نفسه هناك أيضًا قوالب مخصصة للمحترفين تبرز بشكل أكبر الخبرات الوظيفية وتسلط الضوء عليها.

لهذا السبب يتعين عليك اختيار بنية السيرة الذاتية اعتمادًا على خبرتك في العمل. إن الحصول على وظيفة أحلامك يعتمد أولًا وأخيرًا على اتباعك لاستراتيجية جيدة، وأفضل سلاح في جعبتك هو قالب سيرة ذاتية متميز من [My Smart CV](/ar).
`,
      ],
    },
    'sample-cv': {
      en: [
        `
  ## Resume Example: The Right Online Selection
  
  Starting your resume from scratch and most especially with little to no knowledge at all is never easy. Fortunately, you can find the best resume example from us that will help you begin on the right track.
  
  The use of an example will save you so much time while preventing yourself from making mistakes. You have to choose the best sample suited to your profile. Examples are your profession, qualifications, and personality.
  
  The use of an example will save you so much time while preventing yourself from making mistakes. You have to choose the best sample suited to your profile. Examples are your profession, qualifications, and personality.You will be guided accordingly throughout this article so that you can start your resume with ease.
  
  ### Search for the resume example that you need
  
  We offer you a wide selection of [online CV](/) examples to direct you to the one you specifically need. You can also check our examples whether you are looking for a simple or complex CV.
  
  To do this, you only have to proceed with these simple steps:
  
  1. Browse our various proposals
  2. Select your favorite colors and styles
  3. Thoroughly check the different sections of the CV
  4. Visualize your future job application
  
### Why use a sample CV?

You will find new and great ideas by browsing through our CV examples.

Not only that, we provide you with the following benefits just through scanning our samples.
  
  * **An Abundance of Ideas:** This is the first advantage of our CV examples because ideas will be sprouting unexpectedly.
      
  You have to make sure that your resume will be pleasing to the eyes. And so, you will find ideas for layout and graphics. You will also appreciate the use of certain colors.
  
  Furthermore, you will discover unique ways to highlight your skills and qualifications.
  
  * **Large Scale of Design Choices:** If you are someone who is not into graphic designing, imagining a well-suited design for your CV might be a tough job.
  
  The design alone may take much time and may require you to use tools. With an example CV, choosing your CV form will be uncomplicated.
  
  * **Time Saver:** Starting a CV with a blank page can sometimes make you feel dejected.
  
  You can easily modify your personal information, skills, qualifications, and job/training experiences through the use of sample CV.
  
  * **Error Rate Reduction:** Your goal in making an effective resume is to make it perfect or at least have fewer errors.
  
  You can easily make mistakes about the layout, styles, designs, paragraph arrangement, and titles if you decide to start your resume all alone.
  
  You can avoid these mistakes and land your dream job through a sample CV.
  
  * **Eye-catcher to the Recruiters:** Landing an interview or a job always starts with an effective resume. By using the resume examples, you will maximize the odds in your favor effortlessly.
  
  ### How to choose the best resume example?
  
  With the huge number of CV examples, it may not be easy to choose the best that you need.
  
  Of course, you have to make sure that your CV will stand out among the other applicants.
  
  And so, [MySmartCV](/) has listed down the criteria that you should consider while choosing.
  
  * Depending on your job preference: Whatever the job that you are applying for, you will find a large number of CV models that can suit you well.
  
  You willbe given a variety of selections once your profession has been identified. The CV examples are ready to be optimized to further highlight your experience. With this, you are sure that your resume will be spot on.
  
  * Based on your personality: Go for the mostcolorful and original if you want to convey cheerfulness and creativity in your CV.
  
  On the other hand, go for a monochrome CV if you want to highlight your seriousness and if you want to show your gold standard.
  
  It will be your choice what image you want your future employer to see. The wide variety of CVs being offered will make sure that you will accurately manifest the personality that you have.
  
  * Choose the Easiest to Modify: You can edit all the CV examples on the site. But you can save time and focusmainly on what to write if you do not overcomplicate the format.
  
  Most importantly, always choose the CV that will precisely correspond to what you want to write and what you want to show based on your background.
  
  Moreover, you will feel more comfortable and waste less time completing your resume while making sure to end up with an effective one.
      `,
      ],
      id: [
        `
  ## Contoh Resume: Pilihan Online yang Tepat
  
  Memulai resume dari awal apalagi dengan sedikit atau bahkan tanpa pengetahuan sama sekali tidaklah mudah. Untungnya, anda dapat menemukan contoh resume terbaik dari kami yang akan membantu anda memulai di jalan yang benar.
  
  Penggunaan contoh akan menghemat banyak waktu sekaligus mencegah anda membuat kesalahan. Anda harus memilih sampel terbaik yang sesuai dengan profil anda. Contohnya adalah profesi, kualifikasi, dan kepribadian.
  
  Anda akan dipandu sesuai dengan artikel ini sehingga dapat memulai resume dengan mudah.
  
  ### Cari contoh resume yang Anda butuhkan
  
  Kami menawarkan berbagai pilihan contoh CV online untuk mengarahkan anda khususnya pada yang anda butuhkan. Anda juga dapat memeriksa contoh kami baik CV yang sederhana maupun kompleks.
  
  Untuk melakukan ini, anda hanya perlu melanjutkan dengan langkah-langkah sederhana berikut:
  
  1. Telusuri berbagai proposal kami
  2. Pilih warna dan gaya favorit anda
  3. Cek secara menyeluruh bagian-bagian CV yang berbeda
  4. Bayangkan lamaran pekerjaan anda di masa depan
  
### Mengapa menggunakan sampel CV?

Anda akan menemukan ide-ide baru yang bagus dengan melihat-lihat contoh CV kami.

Tidak hanya itu, kami memberi anda manfaat berikut hanya dengan memindai sampel kami.
  
  * **Berlimpahnya Ide:** Ini adalah keuntungan pertama dari contoh CV kami karena ide akan tumbuh secara tak terduga.
      
  Anda harus memastikan bahwa resume anda enak dilihat. Jadi, anda akan menemukan ide untuk tata letak dan grafik. Anda juga akan memahami penggunaan warna tertentu.
  
  Selain itu, anda akan menemukan cara unik untuk menonjolkan keterampilan dan kualifikasi anda.
  
  * **Pilihan Desain dalam Skala Besar:** Jika anda tidak tertarik dengan desain grafis, membayangkan desain yang cocok untuk CV mungkin merupakan pekerjaan yang sulit.
  
  Desainnya sendiri mungkin membutuhkan banyak waktu dan mungkin mengharuskan Anda menggunakan alat. Dengan contoh CV, memilih bentuk CV tidak akan rumit.
  
  * **Penghemat Waktu:** Memulai CV dengan halaman kosong terkadang bisa membuat anda merasa susah.
  
  Anda dapat dengan mudah mengubah informasi pribadi, keterampilan, kualifikasi, dan pengalaman kerja / pelatihan melalui penggunaan sampel CV.
  
  * **Penurunan Tingkat Kesalahan:** Tujuan anda dalam membuat resume yang efektif adalah membuatnya sempurna atau setidaknya hanya memiliki sedikit kesalahan.
  
  Anda dapat dengan mudah membuat kesalahan tentang tata letak, gaya, desain, pengaturan paragraf, dan judul jika anda memutuskan untuk memulai resume sendiri.
  
  Anda dapat menghindari kesalahan ini dan mendapatkan pekerjaan impian anda melalui sampel CV.
  
  * **Menarik untuk perekrut:** Melakukan wawancara atau pekerjaan selalu dimulai dengan resume yang efektif. Dengan menggunakan contoh resume, anda akan memaksimalkan peluang berhasil dengan mudah.
  
  ### Bagaimana cara memilih contoh resume terbaik?
  
  Dengan banyaknya contoh CV, mungkin tidak mudah untuk memilih yang terbaik yang anda butuhkan.
  
  Tentu saja, anda harus memastikan CV menonjol di antara pelamar lainnya.
  
  Jadi, MySmartCV telah membuat daftar kriteria yang harus anda pertimbangkan saat memilih.
  
  * Tergantung pada preferensi pekerjaan anda:  Apa pun pekerjaan yang anda lamar, akan ada banyak model CV yang cocok untuk anda.
  
  Anda akan diberikan berbagai pilihan setelah profesi anda diidentifikasi. Contoh CV siap dioptimalkan untuk lebih menonjolkan pengalaman anda. Dengan ini, anda akan yakin bahwa resume anda sudah tepat.
  
  * Berdasarkan kepribadian anda: Pilih yang paling berwarna dan orisinal jika Anda ingin menyampaikan keceriaan dan kreativitas dalam CV anda.
  
  Di sisi lain, gunakan CV monokrom jika ingin menonjolkan keseriusan dan jika ingin menunjukkan standar emas anda.
  
  Ini akan menjadi pilihan anda, gambaran seperti apa yang ingin anda perlihatkan pada perusahaan anda kelak. Berbagai macam CV yang ditawarkan akan memastikan bahwa anda akan secara akurat mewujudkan kepribadian yang anda miliki.
  
  * Pilih yang Paling Mudah untuk Dimodifikasi: Anda dapat mengedit semua contoh CV di situs. Tetapi anda dapat menghemat waktu dan fokus terutama pada apa yang akan ditulis jika anda tidak memperumit formatnya.
  
  Yang terpenting, selalu pilih CV yang sesuai dengan apa yang ingin anda tulis dan apa yang ingin anda tampilkan berdasarkan latar belakang anda.
  
  Selain itu, anda akan merasa lebih nyaman dan tidak membuang-buang waktu untuk menyelesaikan resume anda sembari memastikan apakah hasilnya efektif.
      `,
      ],
      es: [
        `
  ## Ejemplo de currículum: la selección en línea correcta

  Comenzar tu currículum desde cero y muy especialmente con poco o ningún conocimiento nunca es fácil. Afortunadamente, puede encontrar nuestro mejor ejemplo de currículum que te ayudará a comenzar por el camino correcto.

  El uso de un ejemplo te ahorrará mucho tiempo y te evitará cometer errores. Tienes que elegir la muestra que mejor se adapte a tu perfil. Algunos ejemplos son tu profesión, calificaciones y personalidad.

  Se te guiará en consecuencia a lo largo de este artículo para que pueda comenzar tu currículum con facilidad.

  ### Busca el ejemplo de currículum que necesitas

  Te ofrecemos una amplia selección de ejemplos de CV en línea para dirigirte al que necesitas específicamente. También puedes consultar nuestros ejemplos si estás buscando un CV simple o complejo.

  Para ello, solo debes de seguir estos pasos sencillos:

  1. Explora nuestras diversas propuestas
  2. Selecciona tus colores y estilos favoritos
  3. Revisa a fondo las diferentes secciones del CV
  4. Visualiza tu futura solicitud de empleo

### ¿Por qué utilizar un CV de muestra?

Encontrará nuevas y excelentes ideas navegando a través de nuestros ejemplos de CV.

No solo eso, te brindamos los siguientes beneficios con solo escanear nuestras muestras.

  * **Una abundancia de ideas:** esta es la primera ventaja de nuestros ejemplos de CV porque las ideas brotarán inesperadamente.
      
  Debe asegurarse de que tu currículum sea agradable a la vista. Y así, encontrarás ideas de diseño y gráficos. También apreciarás el uso de ciertos colores.

  Además, descubrirás formas únicas de resaltar tus habilidades y calificaciones.

  * **Gran escala de opciones de diseño:**  si no te gusta el diseño gráfico, imaginar un diseño adecuado para tu CV puede ser un trabajo difícil.

  El diseño por sí solo puede llevar mucho tiempo y puede requerir el uso de herramientas. Con un ejemplo de CV, elegir el formulario de tu CV no será complicado.

  * **Ahorro de tiempo:** comenzar un CV con una página en blanco a veces puede hacer que se sienta abatido.

  Puedes modificar fácilmente tu información personal, habilidades, calificaciones y experiencias laborales / de capacitación mediante el uso de un CV de muestra.

  * **Reducción de la tasa de errores:** tu objetivo al hacer un currículum eficaz es hacerlo perfecto o al menos tener menos errores posibles.

  Puedes cometer errores fácilmente sobre el diseño gráfico, los estilos, los diseños, la división de los párrafos y los títulos si decides comenzar tu currículum solo.

  Puedes evitar estos errores y conseguir el trabajo de tus sueños a través de un CV de muestra.

  * **Llamativo para los reclutadores:** conseguir una entrevista o un trabajo siempre comienza con un currículum eficaz. Al usar los ejemplos de currículum, maximizarás las probabilidades a tu favor sin esfuerzo.

  ### ¿Cómo elegir el mejor ejemplo de CV?

  Con la gran cantidad de ejemplos de CV, puede que no sea fácil elegir el mejor que necesitas.

  Por supuesto, debes asegurarte de que tu CV se destaque entre los demás solicitantes.

  Y así, MySmartCV ha enumerado los criterios que debes considerar al elegir.

  * Dependiendo de tu preferencia laboral: sea cual sea el trabajo que estés solicitando, encontrarás una gran cantidad de modelos de CV que pueden adaptarse bien a ti.

  Se te dará una variedad de selecciones una vez que se haya identificado tu profesión. Los ejemplos de CV están listos para optimizarse para resaltar aún más tu experiencia. Con esto, está más que seguro de que tu currículum será perfecto.

  * Según tu personalidad: opta por lo más colorido y original si quieres transmitir alegría y creatividad en tu CV.

  Por otro lado, opta por un CV monocromático si deseas resaltar tu seriedad y si deseas mostrar tu estándar de oro.

  Será tu elección que imagen quieres que vea tu futuro empleador. La amplia variedad de CV que te ofrecen garantizará que manifiestes con precisión la personalidad que tienes.

  * Elije el más fácil de modificar: puedes editar todos los ejemplos de CV en el sitio. Pero puede ahorrar tiempo y concentrarte principalmente en qué escribir si no complica demasiado el formato.

  Lo más importante es elegir siempre el CV que corresponda con precisión a lo que deseas escribir y lo que deseas mostrar en función de tu experiencia.

  Además, te sentirás más cómodo y perderás menos tiempo completando tu currículum mientras te aseguras de terminar con uno efectivo.
    `,
      ],
      ar: [
        `
## نموذج لسيرة ذاتية: الاختيار الصحيح عبر الإنترنت

البدء في إنشاء سيرتك الذاتية من نقطة الصفر وخاصة مع وجود قدر ضئيل من المعرفة والخبرة بالأمر أو حتى عدم توفر المعرفة من الأساس، هذا بالتأكيد ليس بالأمر السهل على الإطلاق. لكن لحسن الحظ، يمكنك العثور على أفضل نموذج للسيرة الذاتية الذي نقدمه لك كمثال ليساعدك على البدء على المسار الصحيح.

استخدام أحد النماذج سيوفر عليك الكثير من الوقت ويجنبك الكثير من الأخطاء. عليك أن تختار أفضل عينة تناسب ملفك الشخصي. أي تتوافق مثلًا مع مهنتك ومؤهلاتك وشخصيتك.

استخدام أحد النماذج سيوفر عليك الكثير من الوقت ويجنبك الكثير من الأخطاء. عليك أن تختار أفضل عينة تناسب ملفك الشخصي. أي تتوافق مثلًا مع مهنتك ومؤهلاتك وشخصيتك. سيتم إرشادك وفقًا لذلك خلال هذا المقال حتى تتمكن من بدء إنشاء سيرتك الذاتية بسهولة.

### ابحث عن نموذج السيرة الذاتية التي تحتاجها        

نقدم لك تشكيلة واسعة من نماذج [السيرة الذاتية عبر الإنترنت](/ar) لتوجيهك إلى النموذج الذي تحتاجه على وجه التحديد. يمكنك أيضًا التحقق من النماذج التي نقدمها سواء كنت تبحث عن سيرة ذاتية بسيطة أو معقدة.


للقيام بذلك، كل ما عليك هو متابعة هذه الخطوات البسيطة:

1. استعرض مقترحاتنا المتنوعة.
2. اختر الألوان والأنماط المفضلة لديك.
3. تحقق بدقة من الأقسام المختلفة للسيرة الذاتية.
4. ضع تصورًا لطلب وظيفتك المستقبلية.

### لماذا ينبغي استخدام نموذج للسيرة الذاتية؟

ستجد أفكارًا جديدة ورائعة من خلال تصفح واستعراض نماذج السيرة الذاتية التي نقدمها.

ليس ذلك فحسب، فنحن نقدم لك المزايا التالية فقط من خلال استعراض نماذجنا.

* **وفرة من الأفكار:**
هذه هي الميزة الأولى لنماذج السيرة الذاتية التي نقدمها لأن الأفكار ستتولد بشكل غير متوقع.

عليك التأكد من أن سيرتك الذاتية ستكون ممتعة للنظر. وهكذا ستجد أفكارًا للتصميمات والرسومات. سوف تُقدِّر وتثمن أيضًا استخدام ألوان معينة.

علاوةً على ذلك، سوف تكتشف طرقًا فريدة لإبراز مهاراتك ومؤهلاتك.

* **نطاق واسع من خيارات التصميم:**
إذا كنت من الأشخاص الذين لا يهتمون بتصميمات الجرافيك، فإن تصور تصميم مناسب تمامًا لسيرتك الذاتية قد يكون مهمة صعبة.

قد يستغرق التصميم وحده الكثير من الوقت وقد يتطلب منك استخدام بعض الأدوات. مع وجود نماذج للسيرة الذاتية، سيكون اختيار نموذج سيرتك الذاتية أمرًا يسيرًا.

* **موفّر للوقت:**
بدء إنشاء سيرة ذاتية بصفحة فارغة قد يجعلك تشعر أحيانًا باليأس.

يمكنك بسهولة تعديل معلوماتك الشخصية ومهاراتك ومؤهلاتك وخبراتك في العمل أو التدريب من خلال استخدام عينة للسيرة الذاتية.

* **تقليل معدل الخطأ:**
حينما تقوم بعمل سيرة ذاتية فعالة فإنك تسعى لجعلها مثالية أو على الأقل ألا يكون بها سوى أقل قدر ممكن من الأخطاء.

يمكنك بسهولة ارتكاب أخطاء على صعيد المخطط والأنماط والتصاميم وترتيب الفقرات والعناوين إذا قررت بدء سيرتك الذاتية بالكامل بشكل منفصل.

يمكنك تجنب هذه الأخطاء والحصول على وظيفة أحلامك من خلال استخدام نموذج السيرة الذاتية.

* **ملفتة لأنظار المسؤولين عن التوظيف:**
الوصول إلى مرحلة المقابلة الشخصية أو نيل الوظيفة يبدأ دائمًا بسيرة ذاتية فعالة. باستخدام نماذج وأمثلة السيرة الذاتية، سوف تزيد من فرص النجاح وتجعل الاحتمالات تصب في صالحك بسهولة.

### كيف تختار أفضل نموذج للسيرة الذاتية؟

مع العدد الهائل من نماذج السيرة الذاتية، قد لا يكون من السهل اختيار أفضل ما تحتاجه.

بالطبع، عليك التأكد من أن سيرتك الذاتية ستبرز وتكون مميزة بين المتقدمين الآخرين.

ولهذا قام [MySmartCV](/ar) بإدراج المعايير التي يجب أخذها بعين الاعتبار أثناء الاختيار:

* بناءً على الوظيفة التي تفضلها: مهما كانت الوظيفة التي تتقدم لها، ستجد عددًا كبيرًا من نماذج السيرة الذاتية التي تناسبك جيدًا.

ستجد مجموعة متنوعة من الاختيارات بمجرد تحديد مهنتك. نماذج السيرة الذاتية جاهزة للتحسين لإبراز خبراتك بشكل أكبر. مع هذا، أنت على يقين من أن سيرتك الذاتية ستكون في بؤرة الاهتمام.

* بناءً على شخصيتك: يمكنك اختيار الألوان المشرقة والتصاميم المبتكرة إذا كنت تريد التعبير عن البهجة والإبداع في سيرتك الذاتية

في المقابل، اختر سيرة ذاتية أحادية اللون إذا كنت تريد التعبير عن جديتك وإذا كنت تريد إظهار دقتك المتناهية.

سيكون لك أن تختار الصورة التي تريد أن يراها صاحب العمل المستقبلي. ستضمن التشكيلة الواسعة من السير الذاتية المقدمة أنك ستعبر بدقة عن الشخصية التي تتمتع بها.

* اختر النموذج الأسهل للتعديل: يمكنك تعديل جميع نماذج السيرة الذاتية على الموقع. ولكن يمكنك توفير الوقت والتركيز بشكل أساسي على ما تكتبه إذا لم تبالغ في تعقيد التنسيق.

الأهم من ذلك، اختر دائمًا السيرة الذاتية التي تتوافق بدقة مع ما تريد كتابته وما تريد إظهاره بناءً على خلفيتك.

علاوةً على ذلك سوف تشعر براحة أكبر وستضيع وقتًا أقل في إكمال سيرتك الذاتية مع التأكد من أنك ستحصل في النهاية على سيرة ذاتية قوية.
`,
      ],
    },
    'cv-caregiver': {
      en: [
        `
  ## Caregiver CV: Guidelines for a Perfect Resume
  
  Create a relevant and well-crafted caregiver CV if you wish to land any nursing job you are aiming for. You should be ready to convey your qualities as a part of the medical community.
  
  Equip yourself with the right tools when you decide to [start building your CV](/). It is not that difficult though you should still be careful to avoid mistakes.
  
  And so, learn from our advice if you want to put the odds in your favor.
  
  ### Select a good caregiver CV template
  
  There are hundreds of CV types but not all will be best suited for a caregiver profile. Same with other professions, this kind has specific details to highlight your capabilities to convince the recruiter in hiring you.
  
  In choosing your caregiver CV template, you must first check on the two main characteristics. These characteristics are design and structure.
  
  The charm of the CV relies on the design such as the colors, the layout of the blocks, the icons used, or the graphics used.
  
  On the other hand, the structure includes the order of the elements, arrangement, and relative importance.
  
  ### Design
  
  Avoid bright colors and move towards sober CVs to convey seriousness and rigor as needed in this type of position. Also, use graphic designs in blocks or columns to separate information.
  
  And then, the font should be clear and readable. You should still aim for a readable resume even if you use a smaller font size when you need to put in a lot of information.
  
  ### Structure
  
  Your professional experience is your top priority. Therefore, it is better to focus on this part first to display your qualities unless you are a fresh graduate without any experience.
  
  Specify all the establishments and the departments you have worked for. Then, fill in the positions you have held with the responsibilities and duties entrusted to you.
  
  Also, the training section should be as complete as possible. Emphasize the courses or education taken through your diploma if you have just graduated.
  
  If you have professional experience or no longer a beginner, take pride in including all the certifications you have acquired throughout your career.
  
  Furthermore, do not focus on your technical skills alone. You can briefly indicate your natural qualities like your ability to work in a team, ease in written and verbal communication, management aspects, or various positive points that may be of best interest for this type of job.
  `,
        `
  ## How to Write a Perfect Caregiver CV
  
  ### Go for airy text
  
  Any CV should have complete details though it will be difficult to read if it is too dense. And so, get to the point and be concise.
  
  Since you are writing a caregiver CV, write only about what makes sense concerning this position. Therefore, do not hesitate to neglect experiences that are not related to the medical field.
  
  To bring clarity to your text, opt to use a bulleted list. You may also consider choosing light, readable, and clear typography.
  
  ### Choose an anti-chronological order
  
  An anti-chronological order also means a reverse-chronological order. With this, you should put your most recent experience first. It will fully justify your application may it be through similarity or logical career progression.
  
  ### Personalize your caregiver CV based on the advertisement
  
  Revise the content of your CV depending on the job posting. Identify the keywords for the required qualities to better respond to an ad.
  
  It is not “cheating” but it is all about transforming your CV with the precise answer to what they are looking for.
  
  ### Describe the tasks of your previous positions
  
  Your job as a caregiver is full of various tasks. Go into the detailsof the tasks entrusted to you in your various positions if you do not want to confuse the recruiter.
  
  The hiring personnel will certainly see not only your versatility but also your ability to be immediately operational.
  
  ### Proofread
  
  Sometimes, it is difficult to correct yourself or to use spell checker tools/applications.
  
  Since you do not always see everything, look for someone with sufficient experience in resume writing to check the resume.
  
  The proofreader may detect typographical and grammatical errors that you have not noticed.
  
  Moreover, ask for advice if you need additional details to include. This will also reassure you about the quality of the caregiver CV you have created.
  `,
      ],
      id: [
        `
  ## CV Pengasuh: Panduan untuk Resume yang Sempurna
  
  Buat CV pengasuh yang baik dan relevan jika ingin mendapatkan pekerjaan keperawatan apa pun yang anda inginkan. Anda harus siap untuk menunjukkan kualitas anda sebagai bagian dari komunitas medis.
  
  Lengkapi diri anda dengan alat yang tepat ketika memutuskan untuk mulai membangun CV. Tidaklah sesulit itu tetapi anda tetap harus berhati-hati untuk menghindari kesalahan.
  
  Jadi, belajarlah dari saran kami jika ingin apa yang anda lakukan berhasil
  
  ### Pilih template CV pengasuh yang baik
  
  Ada ratusan jenis CV, tetapi tidak semuanya sesuai untuk profil pengasuh. Sama dengan profesi lain, jenis ini memiliki rincian yang spesifik untuk menonjolkan kemampuan anda dalam meyakinkan orang yang mempekerjakan anda.
  
  Dalam memilih contoh CV pengasuh, anda harus memeriksa terlebih dahulu dua karakteristik utamanya. Karakteristik yang dimaksud adalah desain dan struktur.
  
  Daya tarik CV bergantung pada desain seperti pemilihan warna, tata letak blok, ikon yang digunakan, maupun grafik yang digunakan.
  
  Di sisi lain, struktur mencakup urutan elemen, pengaturan, dan kepentingan relatif.
  
  ### Rancangan
  
  Hindari warna-warna cerah dan ganti dengan CV yang sederhana untuk menyampaikan keseriusan dan ketegasan yang dibutuhkan dalam posisi jenis ini. Selain itu, gunakan desain grafis dalam blok atau kolom untuk memisahkan informasi.
  
  Lalu, font/jenis huruf yang digunakan harus jelas dan mudah dibaca. Anda tetap harus mencari resume yang mudah dibaca, bahkan jika menggunakan ukuran font yang lebih kecil ketika memasukkan banyak informasi.
  
  ### Struktur
  
  Pengalaman profesional adalah prioritas anda. Oleh karena itu, lebih baik fokus pada bagian ini terlebih dahulu untuk menunjukkan kualitas anda kecuali jika anda adalah lulusan baru yang belum berpengalaman.
  
  Tuliskan semua perusahaan dan kantor tempat anda pernah bekerja. Kemudian, isi posisi yang pernah anda jabat beserta tanggung jawab dan tugas yang diamanatkan.
  
  Selain itu, sesi pelatihan harus dibuat selengkap mungkin. Tekankan kursus atau pendidikan yang anda diambil jika anda merupakan lulusan baru
  
  Jika anda memiliki pengalaman profesional atau bukan lagi seorang pemula, sertakanlah semua sertifikasi yang sudah anda peroleh sepanjang riwayat anda bekerja.
  
  Selain itu, jangan bertumpu pada keterampilan teknis saja. Anda dapat menunjukkan secara singkat kualitas dasar anda seperti kemampuan untuk bekerja dalam tim, mudah untuk berkomunikasi tertulis dan verbal, aspek manajemen, atau berbagai hal positif yang dapat menjadi daya tarik terbaik untuk jenis pekerjaan ini.
  `,
        `
  ## Cara Menulis CV Pengasuh yang Sempurna
  
  ### Gunakan teks yang ringan
  
  CV apapun harus memiliki rincian yang lengkap walau akan sulit dibaca jika terlalu padat. Sehingga, langsung saja ke intinya agar ringkas.
  
  Karena anda menulis CV untuk pengasuh, tulis hanya hal yang mudah dimengerti terkait posisi ini. Oleh karena itu, jangan ragu untuk mengabaikan pengalaman yang tidak terkait bidang medis.
  
  Untuk memperjelas teks anda, gunakan daftar yang berpoin. Anda juga dapat memilih tata huruf yang ringan, mudah dibaca, dan jelas.
  
  ### Pilih tatanan kronologis
  
  Tatanan kronologis juga berarti tatanan kronologis terbalik. Dengan ini, anda harus mengutamakan pengalaman terbaru. Ini akan sepenuhnya mendukung lamaran anda mungkin melalui kemiripan atau perkembangan karir yang logis.
  
  ### Ubahlah CV pengasuh anda berdasarkan iklan
  
  Perbaiki isi CV anda tergantung pada iklan pekerjaan yang ada. Identifikasi kata kunci untuk kualitas yang diperlukan untuk respon iklan yang lebih baik.
  
  Ini bukan "curang" tetapi ini semua tentang mengubah CV anda dengan jawaban yang tepat untuk apa yang mereka cari.
  
  ### Jelaskan tugas dari posisi anda sebelumnya
  
  Pekerjaan anda sebagai pengasuh akan penuh dengan berbagai macam tugas. Jelaskan secara detail tugas yang pernah diamanatkan kepada anda di posisi sebelumnya agar tidak membingungkan pihak rekrutmen.
  
  Petugas yang merekrut pasti akan melihat tidak hanya kepandaian anda dalam hanyak hal, tetapi juga kemampuan anda untuk beroperasi secara langsung.
  
  ### Koreksi
  
  Terkadang, sulit untuk mengoreksi diri sendiri atau menggunakan alat/aplikasi pemeriksa ejaan.
  
  Karena anda tidak selalu melihat semuanya, carilah seseorang dengan pengalaman yang cukup dalam menulis resume untuk memeriksa resume yang anda buat.
  
  Korektor dapat mendeteksi kesalahan ketik dan tata bahasa yang tidak disadari.
  
  Selain itu, mintalah saran jika anda memerlukan detail tambahan untuk disertakan. Ini juga akan meyakinkan anda mengenai kualitas CV pengasuh yang telah anda buat.
  `,
      ],
      es: [
        `
  ## CV de Cuidador/a: Pautas para un currículum perfecto

  Crea un relevante y bien hecho CV de cuidador/a, muy bien elaborado si deseas conseguir cualquier trabajo de enfermería que estés buscando. Debes estar preparado/a para transmitir tus cualidades como parte de la comunidad médica.

  Equípate con las herramientas adecuadas cuando decidas empezar a construir tu CV. No es tan difícil, aunque debes tener cuidado de evitar errores.

  Entonces, aprende de nuestros consejos si quieres poner las probabilidades a tu favor.

  ### Selecciona una buena plantilla de CV para cuidadores

  Hay cientos de tipos de CV, pero no todos serán los más adecuados para un perfil de cuidador/a. Al igual que otras profesiones, este tipo tiene detalles específicos para resaltar tus capacidades para convencer al reclutador de que te contrate.

  Al elegir tu plantilla de CV de cuidador/a, primero debes verificar las dos características principales. Estas características son diseño y estructura.

  El encanto del CV se basa en el diseño, como los colores, el diseño de los bloques, los iconos utilizados o los gráficos utilizados.

  Por otro lado, la estructura incluye el orden de los elementos, la disposición y la importancia relativa.

  ### Diseño

  Evita los colores vivos y múdate hacia CVs sobrios para transmitir seriedad y rigor según sea necesario en este tipo de puestos de trabajo. Además, usa diseños gráficos en bloques o columnas para separar la información.

  Y luego, la fuente debe ser clara y legible.Debes aspirar a un currículum legible incluso si usa un tamaño de fuente más pequea cuando necesites ingresar mucha información.

  ### Estructura

  Tu experiencia profesional es tu máxima prioridad. Por lo tanto, es mejor concentrarse primero en esta parte para mostrar tus cualidades, a menos que seas un recién graduado sin ninguna experiencia.

  Indica todos los establecimientos y los departamentos para los que ha trabajado. Luego, completa los puestos que has ocupado con las responsabilidades y deberes que te encomienden.

  Además, la sección de formación debe ser la más completa posible. Enfatiza los cursos o la educación tomados a través de tu diploma si te acabas de graduar.

  Si tienes experiencia profesional o ya no eres un principiante, siéntete orgulloso de incluir todas las certificaciones que has adquirido a lo largo de tu carrera.

  Además, no te  centres únicamente en tus habilidades técnicas. Puedes indicar brevemente tus cualidades naturales como tu capacidad para trabajar en equipo, facilidad en la comunicación verbal y escrita, aspectos de gestión o varios puntos positivos que pueden ser de mayor interés para este tipo de trabajo.
  `,
        `
  ## Cómo escribir un CV perfecto para un cuidador/a

  ### Opta por un texto liviano

  Cualquier CV debe tener detalles completos, aunque será difícil de leer si es demasiado denso. Entonces, ve al grano y se conciso.

  Ya que estás escribiendo un CV de cuidador/a, escribe solo lo que tenga sentido en relación con este puesto. Por eso, no dudes en descuidar experiencias que no estén relacionadas con el campo médico.

  Para aportar claridad a tu  texto, opta por utilizar una lista con viñetas. También puede considerar elegir una tipografía ligera, legible y clara.

  ### Elije un orden anticronológico

  Un orden anticronológico también significa un orden cronológico inverso. Con esto, debes anteponer tu experiencia más reciente. Esto justificará completamente tu solicitud, ya sea a través de similitudes o progresión de carrera lógica.

  ### Personaliza tu CV de cuidador/a según el anuncio

  Revisa el contenido de tu CV en función del puesto de trabajo. Identifica las palabras clave de las cualidades necesarias para responder mejor a un anuncio.

  No se trata de “hacer trampa”, se trata de transformar tu CV con la respuesta precisa a lo que buscan.

  ### Describe las tareas de tus puestos anteriores.

  Tu trabajo como cuidador/a está lleno de varias tareas. Entra en los detalles de las tareas que se te encomiendan en tus distintos puestos si no deseas confundir al reclutador.

  Sin duda, el personal de contratación verá no solo tu versatilidad, sino también tu capacidad para estar inmediatamente operativo.

  ### Corregir

  A veces, es difícil corregirse o utilizar herramientas / aplicaciones de corrector ortográfico.

  Dado que no siempre se ve todo, busca a alguien con suficiente experiencia en la redacción de currículums para verificar el currículum.

  El corrector puede detectar errores tipográficos y gramaticales que tu  no ha notado.

  Además, pide consejo si necesitas incluir detalles adicionales. Esto también te asegurará la calidad del CV de cuidador/a que haz creado.
`,
      ],
      ar: [
        `
## السيرة الذاتية لمقدم الرعاية الصحية: إرشادات لكتابة سيرة ذاتية مثالية

قم بإنشاء سيرة ذاتية لمقدم الرعاية الصحية تكون مناسبة وجيدة الصياغة إذا كنت ترغب في الحصول على أية وظيفة تمريض تسعى إليها. ينبغي أن تكون مستعدًا لشرح ما تتمتع به من مؤهلات ومهارات كجزء من المجتمع الطبي.

قم بإنشاء سيرة ذاتية لمقدم الرعاية الصحية تكون مناسبة وجيدة الصياغة إذا كنت ترغب في الحصول على أية وظيفة تمريض تسعى إليها. ينبغي أن تكون مستعدًا لشرح ما تتمتع به من مؤهلات ومهارات كجزء من المجتمع الطبي.

ولذلك فأنت بحاجة إلى الأخذ بنصيحتنا إذا كنت تريد أن تجعل الاحتمالات تصب في صالحك.

### اختر قالبًا جيدًا يناسب السيرة الذاتية لمقدم الرعاية الصحية

هناك المئات من أشكال السيّر الذاتية ولكن لن تكون جميعها مناسبة للاستخدام في الملف الشخصي لمقدم الرعاية الصحية. كما هو الحال مع المهن الأخرى، تحتوي هذه المهنة على تفاصيل محددة قادرة على إظهار قدراتك ومهاراتك حتى تستطيع إقناع القائمين بالتوظيف باختيارك للوظيفة.

عند اختيارك قالب السيرة الذاتية كمقدم للرعاية الصحية، ينبغي عليك أولًا التحقق من أهم خاصيتين يجب الاهتمام بهما. هاتان الخاصيتان هما التصميم والبِنية.

تعتمد جاذبية السيرة الذاتية على التصميم مثل الألوان أو تنسيق الأجزاء أو الرموز والرسومات المستخدمة.

وعلى الناحية الأخرى، تتضمن بنية السيرة الذاتية ترتيب العناصر والترتيب العام والأهمية النسبية لكل عنصر.

### التصميم

تجنب الألوان الزاهية واختر أن تبدو السيرة الذاتية أكثر رصانة ووقارًا للتعبير عن الجدية والدقة وفقًا لما هو مطلوب في مثل هذا النوع من الوظائف. استخدم أيضًا تصميمات الرسوم في الوحدات أو الأعمدة لفصل المعلومات المختلفة.

وبعد ذلك يجب أن يكون الخط واضحًا وتسهل قراءته. وستظل بحاجة إلى السعي لجعل السيرة الذاتية قابلة للقراءة حتى إذا كنت تستخدم أشكال خطوط أصغر حجمًا عند الحاجة إلى إضافة كم كبير من المعلومات.

### البنية

خبراتك المهنية هي أولويتك القصوى. لذلك فمن الأفضل التركيز على هذا الجزء في المقام الأول لعرض ما تتمتع به من مؤهلات ومهارات إلا إذا كنت حديث التخرج بدون أي خبرة.

حدد جميع المؤسسات والأقسام التي عملت بها. بعد ذلك أضف المناصب التي شغلتها بالمسؤوليات والواجبات التي كانت موكلة إليك والمهام التي كنت منوطًا بأدائها.

أيضًا يجب أن يكون الجزء الخاص بالتدريب كاملًا قدر الإمكان. احرص على إبراز الدورات أو التعليم الذي حصلت عليه من خلال مؤهلك الدراسي إذا كنت قد تخرجت للتو.

أما إذا كانت لديك خبرات مهنية أو لم تعد مبتدئًا، فعليك أن تفخر بإدراج جميع الشهادات التي حصلت عليها طوال مسيرتك المهنية.

علاوةً على ذلك، فلا تركز على مهاراتك التقنية وحدها. يمكنك الإشارة بإيجاز إلى مواهبك الفطرية ومميزاتك الشخصية مثل قدرتك على العمل في فريق، أو سهولة التواصل الكتابي والشفهي، أو الجوانب الإدارية، أو غير ذلك من العوامل الإيجابية المختلفة التي قد تكون ذات فائدة كبيرة لهذا النوع من الوظائف.

      `,
        `
## كيف تكتب سيرة ذاتية مثالية كمقدم للرعاية الصحية

### استخدم صياغة رشيقة ومحتوى غير كثيف

يجب أن تحتوي أي سيرة ذاتية على التفاصيل الكاملة ولكنها سوف تصبح صعبة القراءة إذا كانت مكثفة للغاية. ولهذا احرص على الدخول في صلب الموضوع مباشرةً وأن تكون موجزًا.

نظرًا لأنك تكتب سيرة ذاتية لمقدم رعاية صحية، فاكتب فقط ما هو مهم ومعقول فيما يتعلق بهذه الوظيفة. ولذلك لا تتردد في التغاضي عن الخبرات التي ليست لها علاقة بالمجال الطبي.

لإضفاء الوضوح على محتوى سيرتك الذاتية، يفضل استخدام قائمة نقطية. كما يمكنك أيضًا التفكير في اختيار أسلوب كتابة خفيف وسهل القراءة وواضح.

### رتب خبراتك تنازليًا من الأحدث للأقدم

الترتيب التنازلي للأحداث هو عكس الترتيب التسلسلي الطبيعي. في هذا الترتيب ينبغي أن تضع أحدث خبراتك أولًا. سوف يفسر ذلك تمامًا سبب تقديمك لهذه الوظيفة سواء كان من خلال التشابه أو التقدم الوظيفي المنطقي.

### خصص سيرتك الذاتية كمقدم للرعاية الصحية وفقًا لإعلان الوظيفة

قم بمراجعة محتوى سيرتك الذاتية بناءً على الوظيفة المنشورة. حدد الكلمات الرئيسية للمؤهلات والمهارات المطلوبة لكي تتجاوب بشكل أفضل مع الإعلان.

ليس في هذا ما يُعتبر "غشًا" ولكن الأمر كله يتمحور حول تحويل سيرتك الذاتية لتقدم لهم بدقة ما يبحثون عنه.

### صِف مهام وظائفك السابقة

من المؤكد أن الأشخاص المسؤولين عن التوظيف لن ينظروا فقط إلى تنوع خبراتك وما تستطيع القيام به، ولكنهم أيضًا سيرون قدرتك على الانخراط في العمل على الفور.

### لا تنسَ المراجعة والتصحيح

في بعض الأحيان يكون من الصعب تصحيح ما كتبته بنفسك، أو حتى باستخدام أدوات وتطبيقات التدقيق الإملائي .نظرًا لأنك لا ترى كل شيء دائمًا، فابحث عن شخص لديه خبرة كافية في كتابة السيّر الذاتية للتحقق من السيرة الذاتية التي قمت بكتابتها.

قد يكتشف القائم بالمراجعة والتدقيق الأخطاء الكتابية والنحوية التي لم تلاحظها.

وبالإضافة إلى ذلك، اطلب المشورة إذا كنت بحاجة إلى تفاصيل أخرى لإضافتها. سيجعلك هذا أيضًا تطمئن بشأن جودة السيرة الذاتية لمقدم الرعاية الصحية التي قمت بإنشائها.

    `,
      ],
    },
    'cv-student': {
      en: [
        `
  ## How to Write a CV for Students?
  
  If you’re a college student looking for a job or a recent graduate ready to enter the workforce, you need a compelling CV that will catch the hiring manager's attention.
  
  You have to make sure that you have an eye-catching CV that must stand-out from all the other applicants. If you’re not quite sure how to craft a CV, don’t stress yourself out, you’re on the right page. After you read this post, you will walk away with an impressive student CV here at [My Smart CV](/).
  `,
        `
  ## How Do I Design My Student CV?
  
  The design of a student’s CV is everything. The important fact to remember when you’re building a resume is the CV’s overall design can make or break your chances of getting the job. 
  
  Here at My Smart CV, all of our templates have that modern and clear designs to make your profile stand out. Here are some effective recommendations you should remember when choosing the right design. 
  
  * **Choose the Right Design Depending on the Company and the Job:** When choosing a design, you have to consider the job and company you’re applying for. Let’s say you’re applying for a graphic designer job in a tech company, so you would want a visually captivating CV to prove your skills. However, if you're applying to a law firm for a paralegal position, you will want to keep the design simple but still astounding.
  
  * **Don't Go Overboard With the Colors: ** Never use a combination of bright or pastel colors for your CV. Going over the board with pastel or bright colors of your CV might turn off hiring managers and it would look immature. Instead, use sober or plain colors that are not too bright. 
  
  * **Choose a Professional Looking Photo:** When choosing a photo for your resume, place it at the upper left or right corner. It is best to place a head-shot of a professionally-taken photo. The photo must be front-facing, but never use a selfie as this may look unprofessional.      
        `,
        `
  ## How to Choose the Best CV Structure?
  
  The best structure for a student CV must highlight your training and your professional objective. Other than that, when choosing the best structure for the student CV, divide it into legible sections containing your basic personal information, education, relevant skills, training attended, work experience (if applicable).
  
  A tip when structuring your CV is to strategically place each piece of information according to its importance. Here at My Smart CV, we recommend choosing one of our student CVs that highlights the first section clearly. Here you can place your objectives for the job you intend to apply for.
  
  This is followed by another section that emphasizes your training. Always be honest in the skills you declare as you might be questioned during the interview.       
        `,
        `
  ## How to Write the Best Student CV?
  
  After you have chosen the best design and structure for your CV, think of the best way on how to translate into words who you really are. You must think like the hiring manager and ask yourself ‘What makes this candidate different? Is this candidate going to be an asset for the company?’
  
  This is a crucial part that needs a lot of thinking and unique strategy. Here are some tips you can follow on what to write on your CV. 
  
  * **Make your CV unique:** Do not sound generic or at least not make it obvious that you copy, paste your CV’s contents. Make it unique and personalize your CV for each job that you’re applying for. Each job has different demands that’s why it’s important to modify your resume. 
  
  * **Get into details on your curriculum:** Other people might miss out on this part, but vague details on a CV may give that impression that you’re not serious and dedicated to landing that job. So make sure to get into details on graduation dates, academic programs, or training dates. These are essential information hiring managers decipher on how you are fit for the role. 
  
  * **Be specific about the skills:** Avoid being too generic about the skills as well, as this may turn off the recruitment managers. State your skills so your recruiter can be enlightened of what you know, and how they can use your skills for the company’s operations. 
  
  * **Have someone check your CV:**  It won’t hurt to have someone check your CV for proofreading. Have someone check your CV to have at least an insight on what you can improve.      
        `,
      ],
      id: [
        `
  ## Bagaimana Cara Menulis CV untuk Pelajar?
  
  Jika anda seorang mahasiswa yang sedang mencari pekerjaan atau lulusan baru yang siap memasuki dunia kerja, anda memerlukan CV menarik yang akan menjadi perhatian manajer perekrutan.
  
  Anda harus memastikan bahwa CV yang anda buat menarik dan harus lebih menonjol dari semua pelamar lainnya. Jika anda tidak yakin bagaimana cara membuat CV, jangan merasa stress, anda berada di laman yang benar. Setelah anda membaca posting ini, anda akan mendapatkan CV pelajar yang bermutu di sini di My Smart CV.
  `,
        `
  ## Bagaimana Saya Mendesain CV Pelajar Saya?
  
  Desain CV pelajar adalah segalanya. Fakta penting yang perlu diingat saat anda membuat resume adalah keseluruhan desain CV dapat membuat atau merusak peluang anda untuk mendapatkan pekerjaan.
  
  Di sini, di My Smart CV, semua template kami memiliki desain modern dan jelas untuk membuat profil anda lebih unggul. Berikut adalah beberapa rekomendasi efektif yang harus diingat saat memilih desain yang tepat.
  
  * **Pilih Desain yang Tepat Tergantung pada Perusahaan dan Pekerjaan:** Saat memilih desain, anda harus mempertimbangkan pekerjaan dan perusahaan yang anda lamar. Misalkan anda melamar pekerjaan desainer grafis di sebuah perusahaan teknologi, jadi anda ingin CV yang menarik untuk membuktikan keahlian anda secara visual. Namun, jika anda melamar ke sebuah firma hukum untuk posisi paralegal, anda harus membuat desainnya tetap sederhana namun menakjubkan.
  
  * **Jangan Berlebihan Dengan Warna: ** Jangan pernah menggunakan kombinasi warna cerah atau pastel untuk CV anda. Terlalu banyak penggunaan warna pastel atau warna cerah pada CV anda mungkin akan mengurangi ketertarikan manajer perekrutan dan terlihat tidak dewasa. Sebaliknya, gunakan warna-warna sederhana atau polos yang tidak terlalu cerah.
  
  * **Pilih Foto Tampak Profesional:** Saat memilih foto untuk resume, letakkan di sudut kiri atau kanan atas. Akan lebih baik jika anda menempatkan foto berisi wajah anda yang diambil secara profesional. Foto harus menghadap ke depan, tetapi jangan pernah menggunakan foto selfie karena akan terlihat tidak profesional.
        `,
        `
  ## Bagaimana Cara Memilih Struktur CV Terbaik?
  
  Struktur terbaik untuk CV pelajar harus menyoroti pelatihan dan tujuan profesional anda. Selain itu, ketika memilih struktur terbaik untuk CV pelajar, bagilah menjadi bagian yang tetap dapat dibaca yang berisikan informasi pribadi anda yang utama, pendidikan, keterampilan yang relevan, pelatihan yang pernah diikuti, pengalaman kerja (jika ada).
  
  Tip ketika menyusun CV adalah menempatkan setiap informasi secara strategis sesuai dengan kepentingannya. Di sini, di My Smart CV, kami merekomendasikan untuk memilih salah satu CV pelajar kami yang menyoroti bagian pertama dengan jelas. Di sini anda dapat menempatkan tujuan untuk pekerjaan yang ingin anda lamar.
  
  Ini diikuti oleh bagian lain yang menekankan pelatihan Anda. Jujurlah selalu dalam keterampilan yang anda katakan karena mungkin akan ditanyakan pada saat wawancara.
        `,
        `
  ## Bagaimana Cara Terbaik Menulis CV Untuk Pelajar?
  
  Setelah memilih desain dan struktur terbaik untuk CV anda, pikirkan cara terbaik untuk mengartikan siapa diri anda sebenarnya. Anda harus berpikir seperti manajer perekrutan dan bertanya pada diri sendiri ‘Apa yang membuat calon ini berbeda? Apakah calon ini akan menjadi aset perusahaan? "
  
  Ini adalah bagian penting yang membutuhkan banyak pemikiran dan strategi unik. Berikut beberapa tip yang bisa diikuti terkait apa yang harus ditulis di CV anda.

  * **Buat CV anda unik:** Jangan terdengar terlalu umum atau setidaknya jangan terlihat jelas bahwa CV anda meniru CV lain. Jadikan CV anda berbeda dan sesuaikan CV untuk setiap pekerjaan yang anda lamar. Setiap pekerjaan memiliki tuntutan yang berbeda, itulah pentingnya mengubah resume anda.
  
  * **Pelajari detail kurikulum anda:** Orang lain mungkin melewatkan bagian ini, tetapi detail yang tidak jelas di CV dapat memberi kesan bahwa anda tidak serius dan berdedikasi untuk mendapatkan pekerjaan itu. Jadi, pastikan untuk memerinci tanggal kelulusan, program akademik, atau tanggal pelatihan. Ini adalah informasi penting yang diuraikan oleh manajer perekrutan tentang bagaimana anda sesuai untuk tugas tersebut.
  
  * **Lebih spesifik mengenai keterampilan:** Hindari keterampilan yang terlalu umum, karena ini dapat mengurangi ketertarikan manajer perekrutan. Nyatakan keterampilan anda sehingga perekrut dapat memahami apa yang anda ketahui, dan bagaimana mereka dapat menggunakan keterampilan anda untuk jalannya perusahaan.
  
  * **Minta seseorang memeriksa CV anda:**  Tidak ada salahnya meminta seseorang memeriksa CV anda untuk mengoreksi penulisan. Minta seseorang memeriksa CV anda untuk setidaknya memiliki wawasan tentang apa yang dapat anda tingkatkan lagi.
        `,
      ],
      es: [
        `
  ## ¿Cómo escribir un CV para estudiantes?
  
  Si eres un estudiante universitario que busca trabajo o un recién graduado listo para ingresar al ammbiente laboral, necesitas un currículum convincente que capte la atención del gerente de contratación.
  
  Debes asegurarte de tener un CV llamativo que debe destacarse de todos los demás solicitantes. Si no estás muy seguro de cómo crear un CV, no te estreses, estás en la página correcta. Después de leer esta publicación, te irás con un impresionante CV de estudiante aquí en My Smart CV.
  `,
        `
  ## ¿Cómo diseño mi CV de estudiante?
  
  El diseño del CV de un alumno lo es todo. El hecho importante que debes recordar cuando estás creando un currículum es que el diseño general del currículum puede mejorar o deshacer tus posibilidades de obtener el trabajo.
  
  Aquí en My Smart CV, todas nuestras plantillas tienen diseños modernos y claros para que tu perfil se destaque. Aquí hay algunas recomendaciones efectivas que debes recordar al elegir el diseño correcto.
  
  * **Elije el diseño adecuado según la empresa y el trabajo:** al elegir un diseño, debes considerar el trabajo y la empresa a la que estás postulando. Supongamos que estás postulando a un trabajo de diseñador gráfico en una empresa de tecnología, por lo que querrás un CV visualmente cautivador para demostrar tus habilidades. Sin embargo, si estás postulando a un bufete de abogados para un puesto de asistente legal, querrás que el diseño sea simple pero aún asombroso.
  
  * **No te excedas con los colores: ** Nunca uses una combinación de colores brillantes o pasteles para tu CV. Repasar el tablero con colores pastel o brillantes de tu CV podría desanimar a los gerentes de contratación y parecería inmaduro. En su lugar, usa colores sobrios o sencillos que no sean demasiado brillantes.
  
  * **Elije una foto de aspecto profesional:** cuando elijas una foto para tu currículum, colóquela en la esquina superior izquierda o derecha. Es mejor colocar una foto en la cabeza de una foto tomada por un profesional. La foto debe ser de frente, pero nunca uses una selfie, ya que puede parecer poco profesional.
        `,
        `
  ## ¿Cómo elegir la mejor estructura de CV?
  
  La mejor estructura para un CV de estudiante debe de destacar tu formación y tu objetivo profesional. Aparte de eso, al elegir la mejor estructura para el CV del estudiante, divídelo en secciones legibles que contengan tu información personal básica, educación, habilidades relevantes, capacitación asistida, experiencia laboral (si corresponde).
  
  Un consejo a la hora de estructurar tu CV es colocar estratégicamente cada dato según su importancia. Aquí, en My Smart CV, recomendamos elegir uno de nuestros CV de estudiantes que destaque claramente la primera sección. Aquí puede colocar tus objetivos para el trabajo al que deseas postularte.
  
  A esto le sigue otra sección que enfatiza tu entrenamiento. Se siempre honesto en las habilidades que declaras, ya que podría ser interrogado durante la entrevista.
        `,
        `
  ## ¿Cómo escribir el mejor CV de estudiante?
  
  Una vez que hayas elegido el mejor diseño y estructura para tu CV, piensa en la mejor manera de traducir en palabras quién eres realmente. Debes pensar como el gerente de contratación y preguntarte "¿Qué hace diferente a este candidato? ¿Este candidato va a ser un beneficio para la empresa? "
  
  Esta es una parte crucial que necesitas mucho pensamiento y una estrategia única. Aquí hay algunos consejos que puedes seguir sobre lo que debes escribir en tu CV.
  
  * **Haz que tu CV sea único:** No suenes genérico o al menos no hagas obvio que copias, pega el contenido de tu CV. Hazlo único y personaliza tu CV para cada trabajo que postules. Cada trabajo tiene diferentes demandas, por eso es importante modificar tu currículum.
  
  * **Detalla tu plan de estudios:** es posible que otras personas se pierdan esta parte, pero los detalles vagos en un CV pueden dar la impresión de que no eres serio y estás dedicado a conseguir ese trabajo. Así que asegúrate de entrar en detalles sobre las fechas de graduación, los programas académicos o las fechas de capacitación. Éstas son información esencial que los gerentes de contratación descifran sobre cómo tu eres apto para el puesto o no.
  
  * **Sea específico acerca de las habilidades:** evita ser demasiado genérico acerca de las habilidades también, ya que esto puede desanimar a los gerentes de contratación. Indica tus habilidades para que tu reclutador pueda conocer lo que tu sabes y cómo pueden usar tus habilidades para las operaciones de la empresa.
  
  * **Haz que alguien revise tu CV:**  no estará de más que alguien revise tu CV para corregirlo. Haz que alguien revise tu CV para tener al menos una idea de lo que puedes mejorar.
        `,
      ],
      ar: [
        `
## كيف تكتب سيرة ذاتية للطلاب؟

إذا كنت طالبًا جامعيًا وتبحث عن وظيفة أو كنت حديث التخرج للتو وتستعد لدخول سوق العمل، فسوف تكون بحاجة إلى إنشاء سيرة ذاتية مقنعة تنجح في جذب انتباه مدير التوظيف.

احرص على أن تكون لديك سيرة ذاتية تسترعي الأنظار وتجعلك مميزًا بين جميع المتقدمين الآخرين. إذا لم تكن متأكدًا تمامًا من كيفية صياغة السيرة الذاتية، فلا داعي للتوتر، فلقد وصلت الآن إلى المكان الصحيح. بعد قراءة هذا المنشور، ستخرج بسيرة ذاتية لطالب تكون رائعة وجذابة هنا في [My Smart CV](/ar).

        `,
        `

## كيف أصمم سيرتي الذاتية كطالب؟

التصميم الخاص بالسيرة الذاتية للطالب هو كل شيء. الحقيقة المهمة التي يجب عليك تذكرها عند إنشاء سيرة ذاتية هي أن التصميم العام للسيرة الذاتية يمكن أن يمنحك الكثير من الفرص في الحصول على الوظيفة التي تسعى إليها كما يمكن في المقابل أن يقضي على فرصتك تمامًا.
        
هنا في My Smart CV تحتوي جميع القوالب الخاصة بنا على تلك التصميمات العصرية والواضحة لإبراز ملفك الشخصي. فيما يلي إليك بعض التوصيات الفعالة التي ينبغي عليك تذكرها عندما تقوم باختيار التصميم المناسب.
       
* **اختر التصميم المناسب بناءً على الشركة والوظيفة:**
عند اختيار التصميم، عليك التفكير في الوظيفة والشركة التي تتقدم لها. لنفترض أنك تتقدم لوظيفة مصمم جرافيك في شركة تقنية، لذلك فأنت بالتأكيد سترغب في الحصول على سيرة ذاتية جذابة ومبهرة لإثبات مهاراتك. وفي المقابل إذا كنت تقدم طلب توظيف إلى مكتب محاماة لشغل منصب شبه قانوني، فسوف تحتاج إلى إبقاء التصميم بسيطًا قدر الإمكان على أن يظل رائعًا.

* **لا تسرف في استخدام الألوان:**
لا تستخدم أبدًا مزيجًا من الألوان الزاهية أو الباستيل لسيرتك الذاتية. قد تؤدي المبالغة في استخدام ألوان الباستيل أو الألوان الزاهية بسيرتك الذاتية إلى إحباط مديري التوظيف وستبدو سيرتك الذاتية طفولية. بدلًا من ذلك استخدم ألوانًا رصينة أو بسيطة لا تكون ساطعة أكثر من اللازم.

* **اختر صورة شخصية ذات مظهر احترافي:**
عند اختيار صورة لسيرتك الذاتية، ضعها في الزاوية العلوية اليسرى أو اليمنى. من الأفضل وضع لقطة احترافية لصورتك الشخصية. يجب أن تكون الصورة أمامية ومواجهة وليست جانبية، لكن لا تستخدم أبدًا صورة ملتقطة ذاتيًا (سيلفي) لأن هذا قد يبدو غير احترافي.
        `,
        `
## كيف تختار أفضل بِنية للسيرة الذاتية؟

يجب أن تُبرز أفضل بنية للسيرة الذاتية للطالب دراساته وكذلك تطلعاتك المهنية. بخلاف ذلك فعند اختيار أفضل بنية للسيرة الذاتية للطالب، قسّمها إلى أقسام واضحة ومقروءة تحتوي على معلوماتك الشخصية الأساسية، والتعليم، والمهارات ذات الصلة، والتدريب الذي حضرته، والخبرة المهنية (إن وُجدت).

من النصائح الهامة عند بناء وتكوين سيرتك الذاتية أن تضع كل معلومة بشكل استراتيجي وفقًا لأهميتها. هنا في My Smart CV نوصي باختيار إحدى السير الذاتية للطلاب التي تُبرز القسم الأول بوضوح. حيث يمكنك هنا وضع أهدافك للوظيفة التي تنوي التقدم لها.

يلي هذا قسم آخر يركز على دراستك وتدريبك. كن صريحًا دائمًا بشأن المهارات التي تذكرها حيث قد يتم استجوابك بشأنها أثناء المقابلة الشخصية.

        `,
        `
## كيف تكتب أفضل سيرة ذاتية لطالب؟

بعد أن تختار أفضل تصميم وبنية لسيرتك الذاتية، فكر في أفضل طريقة لترجمة ذلك كله إلى كلمات تعبر عن من تكون حقًا. يجب أن تفكر مثل مدير التوظيف وتسأل نفسك: "ما الذي يجعل هذا المرشح مختلفًا عن الباقين؟ هل سيكون هذا المرشح مفيدًا للشركة؟"

هذا جزء جوهري يحتاج إلى الكثير من التفكير واستخدام استراتيجية فريدة. إليك بعض النصائح التي يمكنك اتباعها بشأن ما تكتبه في سيرتك الذاتية.

* **اجعل سيرتك الذاتية فريدة من نوعها:**
لا تبدو عاديًا ومكررًا أو على الأقل لا تُظهر أنك تنسخ محتويات سيرتك الذاتية وتلصقها. اجعلها فريدة من نوعها وقم بتخصيص سيرتك الذاتية لتتناسب مع كل وظيفة تتقدم لها. لكل وظيفة مطالب مختلفة ولهذا فمن المهم تعديل سيرتك الذاتية في كل مرة تقدم لوظيفة مختلفة.

* **اذكر التفاصيل في سيرتك الذاتية:**
قد يهمل الكثيرون هذا الجزء، ولكن التفاصيل الغامضة في السيرة الذاتية قد تعطي انطباعًا بأنك لست جادًا ومتفانيًا للحصول على هذه الوظيفة. لذا احرص على الخوض في تفاصيل مواعيد التخرج أو البرامج الأكاديمية أو مواعيد التدريب. هذه معلومات أساسية يقوم مديرو التوظيف بفك شفرتها لتحديد مدى ملاءمتك للدور المطلوب.

* **كن محددًا بشأن المهارات:**
تجنب الإفراط في الحديث عن المهارات أيضًا، فقد يؤدي ذلك إلى استياء مديري التوظيف. اذكر مهاراتك بدقة حتى يصبح القائم بالتوظيف على بينة بما تعرفه تحديدًا، وكيف يمكنهم استخدام مهاراتك في أعمال الشركة.

* **دع شخصًا آخر يراجع سيرتك الذاتية:**
لن يضر أن يقوم شخص آخر بفحص سيرتك الذاتية من أجل التدقيق اللغوي. اطلب من أحد الأشخاص التحقق من سيرتك الذاتية على الأقل لكي تتبين بوضوح ما يمكنك تحسينه.
        `,
      ],
    },
    'cv-spanish': {
      en: [
        `
  ## Spanish CV: Write your Resume with Ease
  
  You should build a Spanish CV if you want to try your luck in Spain, South America, or Mexico. Your resume should be in tune with the customs and traditions of these countries to have a higher chance of getting hired.
  
  The world has a large scale of Spanish-speaking society and offers many work opportunities. And so, we are here to help you start your career with our [CV choices](/).
  `,
        `## Rules in Creating a Spanish CV
    
   The length of a Spanish CV is commonly accepted in a two-page format. Although, it is advisable to stick to one page only if you are a beginner.
   
   The "Curriculum Vitae" should be visible in the title in every Spanish CV.
   
   As for the photo, no need to include it unless requested in the ad. This is one of the differences in other CVs wherein the inclusion of the picture is systematic.
   
   In customary, mention the names and telephone numbers of former employers under the references.
   
   Furthermore, Spanish-speaking hiring personnel like those who are from Spain are very particular to extra-professional activities. Some of which are volunteering, associations, special projects, cultural, or sports activities
   
   And so, do not hesitate to discuss in detail all the areas that could enhance your qualities. Moreover, select a perfect Spanish CV template with a clear layout and neat designs.
  `,
        `## What are the headings in a Spanish CV?
   
   A Spanish CV may be quite similar to a French CV but there are still differences in the content. To avoid confusion and mistakes, the Spanish CV's particular structure should include the following.
   
   ### Personal information
   
   Personal Information will be called Datos personales and should be in a separate section. 
   
   Include your first and last name, address, contact number, and email address. Also, do not forget to mention the place and date of your birth.
   
   ### Professional objective
   
   The professional objection is not compulsory in a Spanish CV but it will be useful especially in an unsolicited job application.  
   
   This will allow you to display your goals and intention as a job-hunter.
   
   ### Initial training
   
   Citeyour initial training before your professional experience. In here, you must include your studies or what you major at.
   
   You can also mention all the diplomas obtained with the start and end dates. Include the subjects and the followed options as well.
   
   In the case of young graduates, it is better to report results or scores obtained because the Spaniards tend to give this section a lot of detail.
   
   ### Professional experience
   
   Your professional experience is the most important part of a Spanish CV that includes both internships and student jobs in addition to other positions held.
   
   For the training, you must precisely include the details of the jobs held and their content. It is necessary to mention dates while avoiding gaps in the chronology as well.
   
   French companies are sometimes not known internationally will appreciate it if the nature of the activities is explained well.
   
   ### Foreign languages
   
   Like any other resume, it is useful to provide a specific section of the foreign languages you speak with a language level. 
   
   On a Spanish CV, the level should be mentioned with the following terminologies(valid for all the languages you speak):
   
   * Nativo: mother tongue
   * Bilingual: bilingual
   * Bueno: current
   * Intermedio: intermediate
   * Principal: beginner
   
Certifications like DELE, BRIGHT Español, TOEIC for English, and other language certifications should also be mentioned with the obtained results.

### Specific skills

Mention all your proven and acquired skills that you have utilized in your professional field.

Examples are your knowledge of basic computer skills or mastery of specific software. 

You can also evoke mastery of particular technologies with professional certifications that could give truth to your claims.

### Extra-professional activities

You will find the extra-professional activities on any Spanish CV example under the heading Actividades extracurriculares.You should never miss this out because it is an important column observed in Spain.

In here, gather all the activities you have carried out or the associations you have joinedin your spare time. Leisure activities such as sports or music.

Charitable actions, social or political activities, commercial activities, and the likes can also be added.

Do not gloat but also do not hesitate to mention your abilities and your involvement in these activities. Quantify and explain your responsibilities, too.

All of these elements will be considered in your job application because Spanish recruiters take time to understand your personality as part of the hiring process.
  `,
      ],
      id: [
        `
  ## CV bahasa Spanyol: Tulis Resume Anda dengan Mudah
  
  Anda harus membuat CV bahasa Spanyol jika ingin mencoba peruntungan di Spanyol, Amerika Selatan, atau Meksiko. Resume anda harus selaras dengan kebiasaan dan tradisi negara-negara ini agar memiliki peluang lebih tinggi untuk diterima.
  
  Dunia memiliki skala besar masyarakat penutur bahasa Spanyol dan menawarkan banyak kesempatan kerja. Jadi, kami di sini untuk membantu anda memulai karir Anda dengan pilihan CV kami.
  `,
        `## Aturan dalam Membuat CV bahasa Spanyol
    
   Panjang CV berbahasa Spanyol umumnya diterima dalam format dua halaman. Meskipun begitu, disarankan untuk tetap berpegang pada satu halaman hanya jika anda seorang pemula.
   
   "Curriculum Vitae" harus terlihat pada judul di setiap CV bahasa Spanyol.
   
   Sedangkan untuk foto, tidak perlu menyertakannya kecuali diminta dalam iklan. Inilah salah satu perbedaan dengan CV lainnya dimana pencantuman gambar dilakukan secara sistematis.
   
   Secara adat, sebutkan nama dan nomor telepon atasan anda sebelumnya di bawah referensi.
   
   Selain itu, terdapat perekrutan khusus bagi yang berbahasa Spanyol seperti mereka yang berasal dari Spanyol untuk kegiatan ekstra-profesional. Beberapa di antaranya adalah kegiatan sukarela, asosiasi, proyek khusus, budaya, atau olahraga
   
   Maka, jangan ragu untuk membahas secara detail semua bidang yang dapat meningkatkan kualitas anda. Sebagai tambahan, pilih template CV bahasa Spanyol yang sudah benar dengan tata letak yang jelas dan desain yang rapi.
  `,
        `## Apa judul dalam CV bahasa Spanyol?
   
   CV bahasa Spanyol mungkin sangat mirip dengan CV bahasa Prancis tetapi masih ada perbedaan dalam konten. Untuk menghindari kebingungan dan kesalahan, struktur khusus CV Spanyol harus mencakup yang berikut ini.
   
   ### Informasi pribadi
   
   Informasi Pribadi disebut Datos personales dan harus berada di bagian terpisah.
   
   Cantumkan nama depan dan belakang, alamat, nomor telpon, dan alamat email anda. Selain itu, jangan lupa menyebutkan tempat dan tanggal lahir anda.
   
   ### Tujuan profesional
   
   Tujuan profesional tidak wajib dalam CV bahasa Spanyol tetapi akan berguna terutama dalam lamaran kerja yang tidak diminta.
   
   Ini akan memberi anda kemungkinan untuk menampilkan tujuan dan niat sebagai pencari kerja.
   
   ### Pelatihan awal
   
   Sebutkan pelatihan awal sebelum pengalaman professional anda. Di sini, anda harus memasukkan studi atau jurusan yang anda ambil.
   
   Anda juga dapat menyebutkan semua ijazah yang diperoleh dengan tanggal mulai dan berakhir. Sertakan subjek dan opsi yang dianut juga.
   
   Untuk lulusan muda, lebih baik melaporkan hasil atau skor yang diperoleh karena orang Spanyol cenderung rinci pada bagian ini.
   
   ### Pengalaman profesional
   
   Pengalaman profesional anda adalah bagian terpenting dari CV bahasa Spanyol yang mencakup magang dan pekerjaan pelajar selain posisi lain yang dijabat.
   
   Untuk pelatihan, anda harus memasukkan secara tepat detail pekerjaan yang ada dan isinya. Tanggal juga perlu disebutkan untuk menghindari celah dalam urutan waktu.
   
   Perusahaan Perancis yang terkadang tidak dikenal secara internasional akan menghargai jika dasar kegiatannya dijelaskan dengan baik.
   
   ### Bahasa asing
   
   Seperti resume lainnya, akan berguna untuk memberikan bagian tertentu dari bahasa asing yang anda kuasai dengan tingkat bahasa.
   
   Pada CV bahasa Spanyol, level harus disebutkan dengan terminologi berikut (berlaku untuk semua bahasa yang anda gunakan):
   
   * Nativo: bahasa ibu
   * Bilingual: dua bahasa
   * Bueno: saat ini
   * Intermedio: menengah
   * Principal: pemula
   
Sertifikasi seperti DELE, BRIGHT Español, TOEIC untuk bahasa Inggris, dan sertifikasi bahasa lainnya juga harus disebutkan dengan hasil yang diperoleh.

### Keahlian khusus

Sebutkan semua keterampilan yang sudah terbukti dan diperoleh yang telah anda manfaatkan di bidang profesional anda.

Contohnya adalah pengetahuan anda tentang keterampilan komputer dasar atau penguasaan perangkat lunak tertentu.

Anda juga dapat menunjukkan penguasaan teknologi tertentu dengan sertifikasi profesional yang dapat memberikan pembenaran atas pernyataan anda.

### Aktivitas ekstra profesional

Anda akan menemukan aktivitas ekstra-profesional pada contoh CV bahasa Spanyol di bawah heading Actividades extracurriculares. Anda tidak boleh melewatkan ini karena ini adalah kolom penting yang diamati di Spanyol.

Di sini, kumpulkan semua aktivitas yang telah Anda lakukan atau perkumpulan yang anda ikuti di waktu luang. Kegiatan waktu luang seperti olahraga atau musik.

Tindakan amal, aktivitas sosial atau politik, aktivitas komersial, dan sejenisnya juga dapat ditambahkan.

Jangan meremehkan tetapi juga jangan ragu untuk menyebutkan kemampuan dan keterlibatan anda dalam aktivitas tersebut. Ukur dan jelaskan juga tanggung jawab anda.

Semua elemen ini akan dipertimbangkan dalam lamaran kerja anda karena perekrut di Spanyol membutuhkan waktu untuk memahami kepribadian anda sebagai bagian dari proses perekrutan.
  `,
      ],
      es: [
        `
  ## CV en español: escribe tu currículum con facilidad
  
  Deberías crear un CV en español si quieres probar suerte en España, Sudamérica o México. Tu currículum debe estar en sintonía con las costumbres y tradiciones de estos países para tener una mayor probabilidad de ser contratado.
  
  El mundo tiene una gran escala de sociedad de habla hispana y ofrece muchas oportunidades laborales. Por eso, estamos aquí para ayudarte a comenzar tu carrera con nuestras opciones de CV.
  `,
        `## Reglas para crear un CV en español
    
   El cuerpo de un CV en español se acepta comúnmente en un formato de dos páginas. Sin embargo, es recomendable ceñirse a una página solo si es un principiante.
   
   El "Curriculum Vitae" debe estar visible en el título de cada CV en español.
   
   En cuanto a la foto, no es necesario incluirla a menos que se solicite en el anuncio. Esta es una de las diferencias con otros CVs en los que la inclusión de la imagen es sistemática.
   
   De costumbre, menciona los nombres y números de teléfono de los empleadores anteriores debajo de las referencias.
   
   Además, el personal de contratación de habla hispana como los que son de España es muy particular para las actividades extraprofesionales. Algunos de los cuales son voluntariado, asociaciones, proyectos especiales, actividades culturales o deportivas.
   
   Y así, no dudes en comentar a detalle todas las áreas que podrían potenciar tus cualidades. Además, selecciona una plantilla de CV en español perfecta con un diseño claro y diseños prolijos.
  `,
        `## ¿Cuáles son los apartados de un CV en español?
   
   Un CV en español puede ser bastante similar a un CV en francés, pero aún existen diferencias en el contenido. Para evitar confusiones y errores, la estructura particular del CV en español debe incluir lo siguiente.
   
   ### Informacion personal
   
   La información personal se denominará Datos personales y debe estar en una sección separada. 
   
   Incluye tu nombre y apellido, dirección, número de contacto y dirección de correo electrónico. Además, no olvides mencionar el lugar y la fecha de tu nacimiento.
   
   ### Objetivo profesional
   
   El objetivo profesional no es obligatorio en un CVespañol pero será útil especialmente en una solicitud de empleo no solicitada.
   
   Esto te permitirá mostrar tus objetivos e intención como buscador de empleos.
   
   ### Entrenamiento inicial
   
   Cita tu formación inicial antes que tu experiencia profesional. Aquí, debes incluir tus estudios o en qué te especializas.
   
   También puedes mencionar todos los diplomas obtenidas con las fechas de inicio y finalización. Incluye los temas y las opciones seguidas también.
   
   En el caso de los jóvenes titulados es mejor informar de los resultados o puntuaciones obtenidas porque los españoles suelen dar mucho detalle a este apartado.
   
   ### Experiencia profesional
   
   Tu experiencia profesional es la parte más importante de un CV en español que incluye tanto pasantías como trabajos para estudiantes, además de otros puestos ocupados.
   
   Para la formación, debe incluir con precisión el detalle de los trabajos realizados y su contenido. Es necesario mencionar las fechas evitando también lagunas en la cronología.
   
   Las empresas francesas a veces no son conocidas internacionalmente y agradecerán que se explique bien la naturaleza de las actividades.
   
   ### Idiomas extranjeros
   
   Como cualquier otro currículum, es útil proporcionar una sección específica de los idiomas extranjeros que hablas con el nivel en el idioma.
   
   En un CV en español, el nivel debe mencionarse con la siguiente terminología (válida para todos los idiomas que hablas):
   
   * Native: lengua materna
   * Bilingüe: bilingüe
   * Advance: Avanzado
   * Intermedie: intermedio
   * Basic: principiante
   
Las certificaciones como DELE, BRIGHT Español, TOEIC para inglés y otras certificaciones de idiomas también deben mencionarse con los resultados obtenidos.

### Habilidades especificas

Menciona todas tus habilidades comprobadas y adquiridas que hayas utilizado en tu campo profesional.

Algunos ejemplos son tu conocimiento de las habilidades informáticas básicas o el dominio de un software específico.

También puedes evocar el dominio de tecnologías particulares con certificaciones profesionales que podrían dar verdad a sus afirmaciones.

### Actividades extraprofesionales

Encontrarás las actividades extraprofesionales en cualquier ejemplo de CV en español bajo el título Actividades extracurriculares. No debes perderse esto porque es una columna importante que se observa en España.

Aquí reúnes todas las actividades que has realizado o las asociaciones a las que te has unido en tu tiempo libre. Actividades de ocio como deportes o música.

También se pueden agregar acciones benéficas, actividades sociales o políticas, actividades comerciales y similares.

No te regodees, pero tampoco dudes en mencionar tus habilidades y tu participación en estas actividades. También cuantifica y explica tus responsabilidades.

Todos estos elementos se tendrán en cuenta en tu solicitud de empleo porque los reclutadores españoles se toman el tiempo para comprender tu personalidad como parte del proceso de contratación.
  `,
      ],
      ar: [
        `
## السيرة الذاتية باللغة الإسبانية: اكتب سيرتك الذاتية بسهولة

سوف تكون بحاجة إلى إنشاء سيرة ذاتية باللغة الإسبانية إذا كنت ترغب في تجربة حظك في إسبانيا أو أمريكا الجنوبية أو المكسيك. يجب أن تكون سيرتك الذاتية متوافقة مع عادات وتقاليد هذه البلدان لكي تكون لديك فرصة أكبر للحصول على وظيفة.

يوجد في العالم نطاق واسع من المجتمعات الناطقة باللغة الإسبانية وتتوفر بها العديد من فرص العمل. وهكذا فإننا هنا لمساعدتك في بدء حياتك المهنية من خلال [خيارات السيرة الذاتية](/ar) التي نقدمها.

`,
        `
## قواعد إنشاء سيرة ذاتية باللغة الإسبانية

طول السيرة الذاتية الإسبانية المقبول عمومًا يتكون من صفحتين. ولكن في المقابل فإنه يُنصح بالاكتفاء بصفحة واحدة فقط إذا كنت مبتدئًا.

ينبغي أن تكون عبارة "السيرة الذاتية" ظاهرة في العنوان في كل سيرة ذاتية إسبانية.أما بالنسبة للصورة الشخصية فلا داعي لإضافتها إلا إذا طُلب ذلك في إعلان الوظيفة. هذا هو أحد الاختلافات بينها وبين أنواع السيّر الذاتية الأخرى والتي يتم إدراج الصور الشخصية فيها بشكل منهجي.وفقًا لما هو معتاد، أذكر أسماء وأرقام هواتف أرباب العمل السابقين في قسم المراجع أو الأشخاص المرجعيين.

علاوةً على ذلك، فإن المسؤولين القائمين بالتوظيف مِن الناطقين باللغة الإسبانية مثل مَن هم مِن إسبانيا يهتمون بشكل خاص بالأنشطة خارج نطاق العمل التقليدي. على غرار الأنشطة التطوعية أو الجمعيات أو المشاريع الخاصة أو الأنشطة الثقافية أو الرياضية.

ولذلك فلا تتردد في مناقشة جميع المجالات التي يمكن أن تدعم ما تتمتع به من مميزات وبشكل مفصّل. وبالإضافة إلى ذلك اختر قالبًا مثاليًا لسيرتك الذاتية الإسبانية بتنسيق واضح وتصميم أنيق.

      `,
        `
## ما هي العناوين المستخدمة في السيرة الذاتية الإسبانية؟

قد تكون السيرة الذاتية باللغة الإسبانية مشابهة بدرجة كبيرة للسيرة الذاتية الفرنسية أو الإنجليزية مثلًا ولكن لا تزال هناك اختلافات في المحتوى. تجنبًا للالتباس والخطأ، فإن البِنية الخاصة بالسيرة الذاتية الإسبانية يجب أن تتضمن ما يلي:

### البيانات الشخصية

سوف تحمل البيانات الشخصية بالإسبانية عنوان "Datos personales" وينبغي أن تكون في قسم منفصل.قم بتضمين اسمك الأول والأخير والعنوان ورقم الاتصال وعنوان البريد الإلكتروني. ولا تنسَ أيضًا أن تذكر مكان وتاريخ ميلادك.

### التطلعات المهنية

التطلعات المهنية أو الهدف الوظيفي ليست من العناصر الإلزامية في السيرة الذاتية الإسبانية ولكنها ستكون مفيدة بشكل خاص في الطلبات المقدمة لشغل وظائف لم تُعلن عن الحاجة إليها.

سيتيح لك ذلك عرض أهدافك ومقاصدك كباحث عن عمل.

### التدريب الأوّلي

اذكر تدريبك الأولي أو المؤهلات التي حصلت عليها قبل أن تذكر خبراتك المهنية. في هذا الجزء يجب عليك تضمين دراساتك أو ما تتخصص فيه.

يمكنك أيضًا ذكر جميع الشهادات والدرجات العلمية التي حصلت عليها مع تواريخ البدء والانتهاء. قم بتضمين المواد والموضوعات التي درستها وكذلك الخيارات القادمة أيضًا.

في حالة الشباب حديثي التخرج، من الأفضل ذكر النتائج أو الدرجات والتقديرات التي تم الحصول عليها لأن الإسبان يميلون إلى تزويد هذا القسم بالكثير من التفاصيل.

### الخبرات المهنية

خبراتك المهنية هي أهم جزء في السيرة الذاتية الإسبانية وهي تشمل التدريب الداخلي والوظائف الطلابية بالإضافة إلى الوظائف الأخرى التي كنت تشغلها.

بالنسبة للتدريب، يجب أن تُدرج بدقة تفاصيل الوظائف التي تم شغلها ومحتواها. من الضروري أيضًا ذكر التواريخ مع تجنب الثغرات في التسلسل الزمني كذلك.

إذا كانت الشركات التي عملت بها في بعض الأحيان غير معروفة دوليًا، فسيكون من الأفضل أن تقوم بشرح طبيعة أنشطة تلك الشركات بشكل جيد.

### اللغات الأجنبية

مثل أية سيرة ذاتية أخرى، سيكون من المفيد تزويدها بقسم مخصص حول اللغات الأجنبية التي تتحدثها مع ذكر درجة الإجادة أو المستوى اللغوي لكل واحدة منها.

في السيرة الذاتية الإسبانية، يجب ذكر المستوى بالمصطلحات التالية (وهي صالحة لجميع اللغات التي تتحدثها):

* اللغة الأم (Nativo)
* ثنائي اللغة (Bilingüe)
* جيد (Bueno)
* متوسط (Intermedio)
* مبتدئ (Principal)

يجب أيضًا ذكر الشهادات التي حصلت عليها مثل (DELE) و (BRIGHT Español) وكذلك شهادة (TOEIC) للغة الإنجليزية وغيرها من شهادات اللغات الأخرى مع النتائج التي تم الحصول عليها.
      
### المهارات الخاصة

اذكر جميع مهاراتك المثبتة والمكتسبة التي استخدمتها في مجال عملك.

ومن الأمثلة على ذلك معرفتك بمهارات الكمبيوتر الأساسية أو إتقانك لاستخدام برامج معينة.

يمكنك أيضًا إظهار إتقانك لتقنيات معينة بشهادات احترافية يمكن أن تعطي المصداقية لتلك المهارات المذكورة.

### الأنشطة خارج نطاق العمل التقليدي

ستجد الأنشطة خارج نطاق العمل التقليدي مذكورة في أي نموذج للسيرة الذاتية باللغة الإسبانية تحت عنوان "Actividades extracurriculares". ينبغي ألا تفوّت كتابة هذه الأنشطة أبدًا لأنها أحد الأمور التي يتم الاهتمام بها في إسبانيا.

في هذا القسم استجمع كل الأنشطة التي قمت بها أو الجمعيات التي انضممت إليها في أوقات فراغك. وحتى الأنشطة الترفيهية مثل الرياضة أو الموسيقى.

يمكن أيضًا إضافة الأعمال الخيرية والأنشطة الاجتماعية أو السياسية والأنشطة التجارية وما شابه ذلك.

لا تتباهى بصورة مبالغة ولكن أيضًا لا تتردد في ذكر قدراتك ومشاركاتك في هذه الأنشطة. حدد حجم المسؤوليات التي اضطلعت بها واشرحها أيضًا.

سيتم النظر في كل هذه العناصر في طلب الوظيفة الذي تقدمه لأن مسئولي التوظيف الإسبان يستغرقون وقتًا لفهم شخصيتك كجزء من عملية التوظيف.

`,
      ],
    },
    'cv-english': {
      en: [
        `
  ## English CV: Essential Rules to Follow
  Follow Knowing how to write an English or American CV is important when vying for international positions. Companies that follow Anglo-Saxon culture  would prefer an English CV over other types.
  
  There  are  essential  rules  tofollow  when  writing  an  English  CV  and  knowing  its  major differences compared to a French CV is crucial when applying.
  
  **The structure of an English CV**
  An English CV has its own structure. It is different from a French CV in a way that it follows a certainorder and it must contain details such as your personal information, professional objective, and experience.
  
  **Personal Information**
  
  The following particulars must be found on your cv:
  
  * First and last name
  * Address
  * Telephone or mobile number
  * Mail Address
  * Nationality
  
These  pieces  of  information  are  mandatory  in  an  English  CV  and  also  remember  that details  such  as  age,  gender,  and  marital  status  are  highly  forbidden.  These  are considered to be discriminatory so it must be opted out of your CV.

You  can  alsoplace  a  photo,  however,  this  is  only  optional.  It  is  one  of  the  major differences between an English CV and a French CV since the latter type almost always include a picture.

By following the above-recommended structure and taking note of what information must and must not be included, you can easily make an English CV that can help you easily get that job abroad.

**Career objective**
In this section, you must write what your goals are in your career as well as the type of position  that  you  want. It  is  highly  recommended  that  this  section  is  straightforward, concise, and placed inside a frame for better emphasis.

**Professional experience**
experienceThis section is by far the most crucial out of all parts of an English CV. Most employers would put a great value on job experiences which is why it is important to write this section well.

If  you  have  a  wide  range  of  experience,  it  is  recommended  to  include  those  which  are closely  related  to  the  job  that  you  are  applying  for.  This  would  likely  convince  your employer that you already are adept for the position.

As  for  the  structure,  list  down  all  your  experiences  in  an  anti-chronological  order.  It  is important  to  include  information  on  the  job  title,  name  of  previous  employer,  activities, and achievements. Also, use action verbs written in the past tense.

**Initial training**
For  an  English  CV,  what  is  needed  are  only  diplomas  obtained  after  getting  your bachelor’s degree. Take note that you also have to translate your French diplomas.

**Specific skills**
skillsLike  your  job experience,  this  section  on  skill  set  is  also  crucial  in  your  English  CV. Choose those that are related and meaningful for the job that you are applying for. In this modern age, office automation and software skills are also important.

In  addition,  sinceyou  are  applying  for  companies  in  Anglo-Saxon  countries,  language proficiency  is  also  a  must  in  an  English  CV.  Your  language  level  must  be  listed  in  the following order:
  * Bilingual
  * Fluent
  * Working Knowledge
  * Conversational

**Various**
This section deals with activities outside of work. This usually helps the employer have a good look at  your personality. You can include your extra-curricular activities but make sure to put only those that are highly significant such as heading a charitable organization or being the president of an association.

**References**
This section is mandatory in an English CV. List down reputable people and their positions that can vouch for your work abilities.

**Other particulars of an English CV**
It is important to keep your English CV concise since it is only usually written on one page. However, there are also instances where the CV would run on two pages on occasions where the person has a lot of important job experiences.

As for the formalities, do not use personal pronouns and allthe sentences must be written in the past tense. For example, use the sentence “Designed a software” instead of saying “I designed a software." Also, take note of the spelling differences between British English and American English since the former wouldspell “Programme” while the latter would write it as “Program”.

On a final note, do not be tempted to literally translate your French CV into English using automatic  translation  tools.  It  usually  leads  to  misinterpretations  and  wrong  grammar which can affect your CV. Using ready-made templates and looking for examples for an English CV always is the better option.
  `,
      ],
      id: [
        `
  ## CV bahasa Inggris: Aturan yang Penting untuk Diikuti
  Mengetahui cara menulis CV bahasa Inggris atau Amerika penting saat bersaing untuk posisi internasional. Perusahaan yang mengikuti budaya Anglo-Saxon lebih memilih CV berbahasa Inggris daripada jenis lainnya.
  
  Ada aturan penting yang harus diikuti saat menulis CV bahasa Inggris dan mengetahui perbedaan utamanya jika dibandingkan dengan CV bahasa Prancis sangat penting saat melamar pekerjaan.
  
  **Struktur CV bahasa Inggris**
  CV bahasa Inggris memiliki strukturnya sendiri. Ini berbeda dari CV bahasa Prancis yang mengikuti urutan tertentu dan harus berisi detail seperti informasi pribadi, tujuan profesional, dan pengalaman.
  
  **Informasi pribadi**
  
  Detail berikut harus ditemukan di cv Anda:
  
  * Nama depan dan belakang
  * Alamat
  * Telepon atau nomor ponsel
  * Alamat Email
  * Kebangsaan
  
Bagian informasi ini wajib ada dalam CV bahasa Inggris dan juga ingat bahwa detail seperti usia, jenis kelamin, dan status perkawinan sangat dilarang. Ini dianggap diskriminatif sehingga harus dihapus dari CV Anda.

Anda juga dapat menaruh foto, namun ini hanya opsional. Ini adalah salah satu perbedaan utama antara CV bahasa Inggris dan CV Prancis karena CV Perancis kebanyakan selalu menyertakan gambar.

Dengan mengikuti struktur yang direkomendasikan di atas dan mencatat informasi mengenai apa yang harus dan tidak boleh disertakan, anda dapat dengan mudah membuat CV bahasa Inggris yang dapat membantu anda mendapatkan pekerjaan di luar negeri dengan mudah.

**Tujuan karier**
Di bagian ini, anda harus menulis apa tujuan anda dalam karir serta jenis posisi yang diinginkan. Pada bagian ini, disarankan untuk lebih lugas, ringkas, dan diletakkan di dalam bingkai agar lebih terlihat penekanannya.

**Pengalaman profesional**
Bagian ini adalah yang paling penting dari semua bagian CV bahasa Inggris. Sebagian besar pemberi pekerjaan akan sangat menghargai pengalaman kerja, itulah sebabnya bagian ini harus ditulis dengan baik.

Jika anda memiliki pengalaman yang luas, disarankan untuk menyertakan pengalaman yang terkait dengan pekerjaan yang anda lamar. Hal ini kemungkinan akan meyakinkan atasan anda bahwa anda sudah ahli untuk posisi itu.

Mengenai strukturnya, tuliskan semua pengalaman anda dalam urutan kronologis. Penting juga untuk memasukkan informasi tentang jabatan, nama perusahaan sebelumnya, kegiatan, dan prestasi. Jangan lupa gunakan kata kerja lampau dalam penulisannya.

**Pelatihan awal**
Untuk CV bahasa Inggris, yang dibutuhkan hanyalah ijazah yang diperoleh setelah mendapatkan gelar sarjana. Perhatikan bahwa anda juga harus menerjemahkan ijazah Prancis Anda.

**Keahlian khusus**
Seperti pengalaman kerja, bagian keahlian ini juga penting dalam CV bahasa Inggris. Pilih yang berkaitan dan bermakna untuk pekerjaan yang anda lamar. Di zaman modern ini, otomatisasi kantor dan keterampilan perangkat lunak juga penting.

Selain itu, karena anda melamar perusahaan di negara Anglo-Saxon, kemahiran bahasa juga merupakan suatu keharusan di CV bahasa Inggris. Tingkat bahasa anda harus dicantumkan dengan urutan berikut:
  * Dua bahasa
  * Fasih
  * Pengetahuan Kerja
  * Percakapan

**Jenis**
Bagian ini membahas aktivitas di luar pekerjaan. Ini biasanya membantu pemberi pekerjaan melihat kepribadian anda dengan seksama. Anda dapat memasukkan aktivitas ekstra kurikuler tetapi pastikan untuk hanya memasukkan aktivitas yang sangat penting seperti memimpin organisasi amal atau menjadi ketua asosiasi.

**Referensi**
Bagian ini wajib ada di CV bahasa Inggris. Buat daftar orang-orang terkemuka dan posisi mereka yang dapat menjamin kemampuan kerja anda.

**Keterangan lain dari CV Inggris**
Penting untuk menjaga CV bahasa Inggris Anda tetap ringkas karena biasanya hanya ditulis dalam satu halaman. Namun, ada juga contoh di mana CV terbagi menjadi dua halaman pada saat orang tersebut memiliki banyak pengalaman kerja yang penting.

Untuk formalitas, jangan gunakan kata ganti orang dan semua kalimat harus menggunakan kata kerja bentuk lampau. Misalnya, gunakan kalimat "Mendesain perangkat lunak" bukan "Saya merancang perangkat lunak". Selain itu, perhatikan perbedaan ejaan antara Inggris British dan Inggris Amerika karena ejaan "Program" dalam Inggris British akan berubah menjadi "Program" dalam ejaan Inggris Amerika.

Sebagai catatan terakhir, jangan tergoda untuk menerjemahkan CV Prancis anda ke dalam bahasa Inggris menggunakan alat terjemahan otomatis. Ini biasanya akan menyebabkan salah pengartian dan tata bahasa yang salah yang dapat memengaruhi CV anda. Menggunakan template yang sudah jadi dan mencari contoh CV bahasa Inggris merupakan pilihan yang lebih baik.
  `,
      ],
      es: [
        `
  ## CV en inglés: Reglas esenciales a seguir
  Saber cómo redactar un CV en inglés o estadounidense es importante al competir por puestos internacionales. Las empresas que siguen la cultura anglosajona preferirían un CV en inglés sobre otros tipos.
  
  Hay reglas esenciales a seguir al escribir un CV en inglés y conocer sus principales diferencias en comparación con un CV francés es crucial al momento de presentar la solicitud.
  
  **La estructura de un CV en inglés**
  Un CV en inglés tiene su propia estructura. Es diferente de un CV español en una forma que sigue un orden determinado y debe contener detalles como tu información personal, objetivo profesional y experiencia.
  
  **Información personal**
  
  Los siguientes datos se deben encontrar en tu CV:
  
  * Nombre y apellido
  * Dirección
  * Número de teléfono o móvil
  * Correo Electrónico
  * Nacionalidad
  
Estos datos son obligatorios en un CV en inglés y también recuerda que los detalles como la edad, el sexo y el estado civil están estrictamente prohibidos. Estos se consideran discriminatorios, por lo que debe excluirse de tu CV.

También puedes colocar una foto, sin embargo, esto solo es opcional. Es una de las principales diferencias entre un CV en inglés y un CV en francés, ya que este último tipo casi siempre incluye una imágen.

Siguiendo la estructura recomendada anteriormente y tomando nota de que información debes y no debes incluir, puedes hacer fácilmente un CV en inglés que puede ayudarte a obtener fácilmente ese trabajo en el extranjero.

**Objetivos de carrera**
En esta sección, debes escribir cuáles son tus objetivos en tu carrera, así como el tipo de puesto que deseas. Se recomienda que esta sección sea sencilla, concisa y se coloque dentro de un marco para un mejor énfasis.

**Experiencia Profesional**
Esta sección es, con mucho, la más importante de todas las partes de un CV en inglés. La mayoría de los empleadores valorarán mucho las experiencias laborales, por lo que es importante escribir bien esta sección.

Si tienes una amplia experiencia, se recomienda incluir aquellos que estén estrechamente relacionados con el trabajo que estás solicitando. Esto probablemente convencería a tu empleador de que tú ya eres un experto en el puesto.

En cuanto a la estructura, enumera todas tus experiencias en un orden anticronológico. Es importante incluir información sobre el cargo, el nombre del empleador anterior, las actividades y los logros. Además, usa verbos de acción escritos en tiempo pasado.

**Entrenamiento inicial**
Para un CV en inglés, solo se necesitan diplomas obtenidos después de obtener tu título de licenciatura. Ten en cuenta que también tienes que traducir tus diplomas de Español.

**Habilidades especificas**
Al igual que tu experiencia laboral, esta sección sobre el conjunto de habilidades también es crucial en tu CV en inglés. Elije aquellos que estén relacionados y sean significativos para el trabajo que estás solicitando. En esta era moderna, la automatización de oficinas y las habilidades de software también son importantes.

Además, dado que estás solicitando empresas en países anglosajones, el dominio del idioma también es imprescindible en un CV en inglés. Tu nivel de idioma debe aparecer en el siguiente orden:
* Bilingüe
* Fluido
* Trabajando en el conocimiento
* Conversacional

**Varios**
Esta sección trata sobre las actividades fuera del trabajo. Esto generalmente ayuda al empleador a ver bien tu personalidad. Puedes incluir tus actividades extracurriculares, pero asegúrate de incluir solo aquellas que sean muy importantes, como dirigir una organización benéfica o ser presidente de una asociación.

**Referencias**
Esta sección es obligatoria en un CV en inglés. Haz una lista de personas de renombre y sus puestos que puedan dar fe de tus habilidades laborales.

**Otros datos de un CV en inglés**
Es importante que tu CV en inglés sea conciso, ya que generalmente solo está escrito en una página. Sin embargo, también hay casos en los que el CV se realizará en dos páginas en ocasiones en que la persona tenga muchas experiencias laborales importantes.

En cuanto a las formalidades, no utilices pronombres personales y todas las frases deben estar escritas en tiempo pasado. Por ejemplo, usa la oración " Designed a software" en vez de decir " I designed a software ". Además, ten en cuenta las diferencias de ortografía entre el inglés británico y el inglés estadounidense, ya que el primero deletrearía " Programme " mientras que el segundo lo escribirías como " Program ".

Por último, no te sientas tentado a traducir literalmente tu CV de español al inglés utilizando herramientas de traducción automática. Por lo general, conduce a malas interpretaciones y gramática incorrecta que pueden afectar tu CV. Usar plantillas listas para el uso y buscar ejemplos para un CV en inglés siempre es la mejor opción.
  `,
      ],
      ar: [
        `
## السيرة الذاتية باللغة الإنجليزية: القواعد الأساسية الواجب اتباعها

لمعرفة كيفية كتابة السيرة الذاتية باللغة الإنجليزية أو بالإنجليزية الأمريكية أهمية كبيرة عند التنافس على الوظائف الدولية. تفضل الشركات التي تتبع الثقافة الأنجلو ساكسونية السيرة الذاتية الإنجليزية على أنواع السيّر الذاتية الأخرى.

هناك قواعد أساسية يجب اتباعها عند كتابة سيرة ذاتية باللغة الإنجليزية، كما أن معرفة الاختلافات الرئيسية بها مقارنةً بالسيرة الذاتية المكتوبة بالعربية أو الفرنسية من الأمور بالغة الأهمية عند التقديم.

**بِنية السيرة الذاتية باللغة الإنجليزية**
السيرة الذاتية المكتوبة باللغة الإنجليزية لها بنيتها الخاصة وتركيبها المتفرد. وهي تختلف عن السيرة الذاتية المكتوبة باللغة العربية مثلًا في أنها تتبع ترتيبًا معينًا ويجب أن تحتوي على تفاصيل مثل بياناتك الشخصية وتطلعاتك المهنية وخبراتك.

**البيانات الشخصية**

يجب أن تحتوي سيرتك الذاتية على البيانات التالية:

* الاسم الأول والأخير
* العنوان
* رقم الهاتف أو الهاتف المحمول
* عنوان البريد الإلكتروني
* الجنسية

هذه المعلومات إلزامية ولا بد من تواجدها في السيرة الذاتية الإنجليزية وتذكر أيضًا أن بعض التفاصيل مثل العمر والجنس والحالة الاجتماعية محظورة للغاية. حيث تعتبر هذه البيانات تمييزية لذا يجب استبعادها من سيرتك الذاتية.

يمكنك أيضًا وضع صورة، ولكن هذا الأمر اختياري فقط. وهذا أحد الاختلافات الرئيسية بين السيرة الذاتية الإنجليزية والسيرة الذاتية الفرنسية لأن النوع الأخير يتضمن صورة بشكل شبه دائم.

باتباع البنية الموصى بها كما هو موضح أعلاه ومعرفة البيانات التي يجب تضمينها وتلك الأخرى التي يجب عدم تضمينها، يمكنك بسهولة إنشاء سيرة ذاتية باللغة الإنجليزية تكون قادرة على مساعدتك في الفوز بسهولة بهذه الوظيفة في الخارج.

**التطلعات المهنية**
في هذا القسم الخاص بالتطلعات المهنية أو الهدف الوظيفي، يجب أن تكتب ما هي أهدافك في حياتك المهنية وكذلك نوع الوظيفة التي تريد الحصول عليها. يُوصى بشدة أن يكون هذا القسم مباشرًا وموجزًا ​​وأن تضعه داخل إطار لإبرازه بصورة أفضل.

**الخبرات المهنية**
هذا القسم هو الأكثر أهمية من بين جميع أجزاء السيرة الذاتية باللغة الإنجليزية. يولي معظم أصحاب العمل اهتمامًا شديدًا للخبرات الوظيفية وهذا هو السبب الذي يجعل من الأهمية بمكان أن تكتب هذا القسم بشكل جيد.

إذا كانت لديك مجموعة كبيرة ومتنوعة من الخبرات، فمن المستحسن تضمين تلك الخبرات التي ترتبط ارتباطًا وثيقًا بالوظيفة التي تتقدم لها. لأن ذلك على الأغلب سوف يقنع صاحب العمل أنك تمتلك بالفعل البراعة المطلوبة لتولي هذا المنصب.

بالنسبة لقوام هذا الجزء، قم بتدوين جميع تجاربك بترتيب زمني تنازلي أي من الأحدث إلى الأقدم. من المهم تضمين معلومات عن المسمى الوظيفي واسم صاحب العمل السابق والأنشطة والإنجازات. كذلك ينبغي استخدام أفعال العمل وكتابتها بصيغة الماضي.

**التدريب الأوّلي**
بالنسبة للسيرة الذاتية باللغة الإنجليزية، فإن المطلوب فقط هو ذكر الدرجات العلمية والشهادات التي حصلت عليها بعد درجة البكالوريوس. لاحظ أنه يجب عليك أيضًا ترجمة شهاداتك العربية.

**المهارات الخاصة**
مثلما هو الحال بالنسبة لخبراتك الوظيفية، فإن هذا القسم الخاص بمجموعة المهارات مهم أيضًا في سيرتك الذاتية باللغة الإنجليزية. اختر المهارات التي تكون ذات صلة ومفيدة للوظيفة التي تتقدم لها. في هذا العصر الحديث، تعتبر مهارات التشغيل الآلي للمكاتب والبرمجيات من المهارات المهمة أيضًا.

بالإضافة إلى ذلك، فنظرًا لأنك تتقدم لشركات في البلدان الأنجلو ساكسونية، فإن إتقان اللغة أمر لا بد منه أيضًا في السيرة الذاتية باللغة الإنجليزية. يجب أن يتم ذكر مستوى إجادتك للغة الإنجليزية وغيرها من اللغات بالترتيب التالي:

* ثنائي اللغة (Bilingual)
* طليق (Fluent)
* المعرفة العملية (Working Knowledge)
* مستوى المحادثة (Conversational)
 
**متفرقات&**
يتناول هذا القسم الأنشطة خارج نطاق العمل. وهذا عادةً ما يساعد صاحب العمل في التعرف بشكل أفضل على شخصيتك. يمكنك تضمين الأنشطة الخارجة عن نطاق الدراسة ولكن احرص على ألا تضع سوى الأنشطة المهمة للغاية فقط مثل قيادة منظمة خيرية أو تولي رئاسة جمعية أو اتحاد.

**المراجع**
هذا القسم إلزامي في السيرة الذاتية الإنجليزية. ويتضمن الأشخاص المرجعيين أي الذين يمكن الرجوع إليهم للتأكيد على ما أوردته بسيرتك الذاتية. قم بإدراج قائمة الأشخاص ذوي السمعة الطيبة وكذلك مناصبهم، ممن يمكن أن يشهدوا على قدراتك في العمل.

**تفاصيل أخرى عن السيرة الذاتية الإنجليزية**
من المهم أن تحافظ على سيرتك الذاتية باللغة الإنجليزية موجزة ومختصرة لأنها عادةً ما تكون مكتوبة من صفحة واحدة فقط. ومع ذلك فهناك أيضًا حالات يمكن أن تكون فيها السيرة الذاتية على صفحتين في المناسبات التي يكون لدى الشخص فيها الكثير من الخبرات الوظيفية المهمة.

أما بالنسبة للشكليات والصيغة الرسمية، فلا تستخدم الضمائر الشخصية ويجب كتابة جميع الجمل بصيغة الماضي. على سبيل المثال؛ حينما تريد القول أنك صممت أحد البرمجيات استخدم العبارة الآتية: "Designed a software" بمعنى "صممت برنامجًا"، بدلاً من أن تقول: "I designed a software" أي: "أنا صممت برنامجًا". كذلك ينبغي أن تلاحظ أيضًا الاختلافات الإملائية بين الإنجليزية البريطانية والإنجليزية الأمريكية حيث أن الأولى ستكتب مثلًا كلمة "Programme" بمعنى برنامج، بينما الثانية سوف تكتبها "Program".

في ملاحظة أخيرة لا تقع في فخ استسهال ترجمة سيرتك الذاتية العربية ترجمة حرفية إلى الإنجليزية باستخدام أدوات الترجمة الآلية. لأن ذلك عادةً ما يؤدي إلى وجود تفسيرات خاطئة وقواعد غير سليمة يمكن أن تؤثر على سيرتك الذاتية. لذلك يعتبر استخدام القوالب الجاهزة والبحث عن أمثلة لسيرة ذاتية باللغة الإنجليزية هو الخيار الأفضل دائمًا.
        `,
      ],
    },
  },
};
