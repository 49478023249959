import React, { useEffect, useState } from 'react';
import mailcheck from 'mailcheck';
import styled from 'styled-components';

import useIntl from '/imports/core/api/useIntl';

function MailCheck({
  domainThreshold,
  secondLevelThreshold,
  topLevelThreshold,
  domains,
  topLevelDomains,
  secondLevelDomains,
  distanceFunction,
  email,
  onChange,
}) {
  const { t } = useIntl();
  const [suggestion, setSuggestion] = useState(null);
  useEffect(() => {
    //Threshold for custom domain's
    mailcheck.domainThreshold = domainThreshold || mailcheck.domainThreshold;
    mailcheck.secondLevelThreshold = secondLevelThreshold || mailcheck.secondLevelThreshold;
    mailcheck.topLevelThreshold = topLevelThreshold || mailcheck.topLevelThreshold;
    mailcheck.run({
      email,
      domains,
      topLevelDomains,
      secondLevelDomains,
      distanceFunction,
      suggested: (data) => {
        setSuggestion(data);
      },
      empty: () => {
        setSuggestion(null);
      },
    });
  }, [email]);

  if (!suggestion) return null;
  return (
    <FlexView>
      <Label>
        {t('header.suggestions_did_you_mean')}:{' '}
        <LeftSpan onClick={onChange(suggestion)}>{suggestion.address + '@'}</LeftSpan>
        <RightSpan onClick={onChange(suggestion)}>{suggestion.domain}</RightSpan>
      </Label>
    </FlexView>
  );
}

export default MailCheck;

const FlexView = styled.div`
  margin-top: 15px;
  cursor: default;
  ${({ theme }) => theme.max('xs')`
    margin-top: 5px;
  `}
`;

const Label = styled.div`
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 14px;
  font-weight: 500;
  color: #7c7eb2;
`;

const LeftSpan = styled.span`
  color: #17a3ff;
  cursor: pointer;
`;

const RightSpan = styled.span`
  color: #17a3ff;
  font-weight: bold;
  cursor: pointer;
`;
