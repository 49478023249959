import HobbyIconV3 from './HobbyIconV3';
import IconsExperiment from './IconsExperiment';

const HobbyIconExperiment = (props) => {
  return <IconsExperiment iconV3={<HobbyIconV3 {...props} />} />;
};

HobbyIconExperiment.displayName = 'HobbyIconExperiment';

export default HobbyIconExperiment;
