export default (markup, style) => `
<!DOCTYPE html>
<html>
<head>
  ${style}
</head>
<body>
  <div id="pageContent">
    ${markup}
  </div>
</body>
`;
