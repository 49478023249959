import React from 'react';
import PropTypes from 'prop-types';
import { getDomainName, isBlogDomain } from 'lib/helpers';
import { Push } from './Link';
import styled, { css } from 'styled-components';
import { useResponsive } from 'imports/core/api/responsiveContext';

import { Divider } from '/imports/help/ui/atoms/Divider';
import Flex from '/imports/core/ui/atoms/Flex';
import { getBrandName } from '/lib/helpers';
import { HELP_FOOTER_LINKS } from '/imports/help/api/constants';
import useIntl from '/imports/core/api/useIntl';

const Footer = () => {
  const { t, locale } = useIntl();
  const { host } = useResponsive();
  const links = HELP_FOOTER_LINKS();
  const blog = isBlogDomain(host);

  const getURL = (href) => {
    return blog ? `builder/${locale}${href}` : `${locale}${href}`;
  };
  const baseURL = (href) => `https://${getDomainName(host)}/${getURL(href)}`;

  const goToContactPage = () => {
    window.open(baseURL('/contact-us'), '_blank');
  };
  const goTo = (href) => {
    if (href === '/') {
      return Push(href, locale);
    }
    window.open(baseURL(href), '_blank');
  };
  return (
    <FooterWrapper justifyContent="center" alignItems="center" direction="column">
      <SupportLink>
        <span onClick={goToContactPage}>{t('help.footer_support_link')}</span>&nbsp;
        {t('help.footer_support_description')}
      </SupportLink>
      <Divider />
      <LinksWrapper>
        <LinkFlex>
          {links.map((item, index) => (
            <Flex key={index}>
              <LinkWrapper onClick={() => goTo(item.href)}>{item.text ? item.text : t(item.slug)}</LinkWrapper>
            </Flex>
          ))}
        </LinkFlex>
        <Copyright>&copy;&nbsp;{getBrandName(true, host)}&nbsp;2023</Copyright>
      </LinksWrapper>
    </FooterWrapper>
  );
};

export default Footer;

Footer.propTypes = {
  showHelpFeedback: PropTypes.bool,
};

const LinksWrapper = styled(Flex)`
  justify-content: space-between;
  width: 100%;
  @media (max-width: 767px) {
    flex-direction: column;
    > div:not(:last-child) {
      margin-bottom: 16px;
    }
  }
`;
const SupportLink = styled.div`
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #4c4c55;
  & span {
    color: #1688fe;
    cursor: pointer;
  }
  width: 100%;
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
      text-align: right;
    `}
`;

const LinkWrapper = styled(Flex)`
  cursor: pointer;
  font-family: ${({ theme }) => theme.font.family.websiteRegular};
  font-size: 14px;
  font-weight: 600;
  line-height: 1.45;
  color: #595964;
  text-decoration: none;
`;

const Copyright = styled(LinkWrapper)`
  cursor: unset;
`;

const LinkFlex = styled(Flex)`
  display: flex;
  align-self: flex-start;
  > div:not(:last-child) {
    margin-right: 24px;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    > div:not(:last-child) {
      margin-bottom: 16px;
      margin-right: 0;
    }
  }
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      > div:not(:last-child) {
        margin-left: 24px;
        margin-right: 0;
      }
      @media (max-width: 767px) {
        > div:not(:last-child) {
          margin-left: 0;
        }
      }
    `}
`;

const FooterWrapper = styled(Flex)`
  width: 100%;
  display: flex;
  align-items: center;
  > div:not(:last-child) {
    margin-bottom: 16px;
  }
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
`;
