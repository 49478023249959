import React from 'react';
import styled from 'styled-components';

import PreviewSkeleton from '/imports/generator/ui/skeleton/PreviewSkeleton';

const TemplatesSkeleton = () => (
  <Section>
    <PreviewSkeleton />
  </Section>
);

const Section = styled.div`
  width: ${({ theme }) => theme.width}px;
`;

export default TemplatesSkeleton;
