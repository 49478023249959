import styled from 'styled-components';

import Flex from '/imports/core/ui/atoms/Flex';

const Container = styled(Flex)`
  max-width: 1320px;
  padding: 0 20px;
  margin: 0 auto;
  flex-direction: column;
  width: 100%;
  ${({ theme }) => theme.max('sm')`
    padding : 0 20px;
  `}

  ${({ theme }) => theme.max('lg')`
   padding : 0 20px;
  `}
  ${({ theme }) => theme.max('md')`
  padding : 0 20px;
 `}
`;

export default Container;
