import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const UpgradedAccountIcon = ({ width, height }) => {
  return (
    <SvgIcon viewBox="0 0 512 512" width={width} height={height} xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient x1="65.832%" y1="0%" x2="33.637%" y2="98.291%" id="a">
          <stop stopColor="#6079A0" offset="0%" />
          <stop stopColor="#1B2946" offset="76.548%" />
        </linearGradient>
        <linearGradient x1="52.656%" y1="-4.684%" x2="46.691%" y2="108.841%" id="h">
          <stop stopColor="#E7B067" offset="0%" />
          <stop stopColor="#FECA85" stopOpacity="0" offset="83.851%" />
        </linearGradient>
        <linearGradient x1="46.511%" y1=".057%" x2="54.911%" y2="109.264%" id="i">
          <stop stopColor="#FEC475" offset="0%" />
          <stop stopColor="#FFD59C" stopOpacity="0" offset="83.851%" />
        </linearGradient>
        <linearGradient x1="46.511%" y1=".057%" x2="54.911%" y2="109.264%" id="j">
          <stop stopColor="#FDBD67" offset="0%" />
          <stop stopColor="#FFD7A0" stopOpacity="0" offset="80.655%" />
        </linearGradient>
        <linearGradient x1="52.749%" y1="-4.684%" x2="46.77%" y2="109.098%" id="k">
          <stop stopColor="#FDBD67" offset="0%" />
          <stop stopColor="#F8C582" stopOpacity="0" offset="83.851%" />
        </linearGradient>
        <linearGradient x1="64.861%" y1="14.936%" x2="34.266%" y2="108.049%" id="l">
          <stop stopColor="#FDBD67" offset="0%" />
          <stop stopColor="#A85122" offset="73.438%" />
          <stop stopColor="#A85122" offset="100%" />
        </linearGradient>
        <linearGradient x1="67.474%" y1="13.984%" x2="19.765%" y2="87.216%" id="m">
          <stop stopColor="#FDBD67" offset="0%" />
          <stop stopColor="#A85122" offset="64.178%" />
          <stop stopColor="#A85122" offset="100%" />
        </linearGradient>
        <linearGradient x1="84.101%" y1="50.138%" x2=".016%" y2="47.896%" id="n">
          <stop stopColor="#C16736" offset="0%" />
          <stop stopColor="#FFBF68" offset="100%" />
        </linearGradient>
        <linearGradient x1="56.258%" y1="5.109%" x2="46.264%" y2="83.867%" id="o">
          <stop stopColor="#FFBF68" offset="0%" />
          <stop stopColor="#C16736" offset="100%" />
        </linearGradient>
        <radialGradient
          cx="50%"
          cy="50%"
          fx="50%"
          fy="50%"
          r="50%"
          gradientTransform="matrix(0 1 -.9284 0 .964 0)"
          id="b"
        >
          <stop stopColor="#E8BA75" offset="0%" />
          <stop stopColor="#5FB6E4" stopOpacity="0" offset="100%" />
        </radialGradient>
        <radialGradient
          cx="50%"
          cy="50%"
          fx="50%"
          fy="50%"
          r="50%"
          gradientTransform="matrix(0 1 -.9284 0 .964 0)"
          id="c"
        >
          <stop stopColor="#5FB6E4" offset="0%" />
          <stop stopColor="#5FB6E4" stopOpacity="0" offset="100%" />
        </radialGradient>
        <radialGradient
          cx="50%"
          cy="50%"
          fx="50%"
          fy="50%"
          r="50%"
          gradientTransform="matrix(0 1 -.9284 0 .964 0)"
          id="d"
        >
          <stop stopColor="#5FB6E4" offset="0%" />
          <stop stopColor="#5FB6E4" stopOpacity="0" offset="100%" />
        </radialGradient>
        <radialGradient
          cx="50%"
          cy="50%"
          fx="50%"
          fy="50%"
          r="50%"
          gradientTransform="matrix(0 1 -.9284 0 .964 0)"
          id="e"
        >
          <stop stopColor="#E8BA75" offset="0%" />
          <stop stopColor="#5FB6E4" stopOpacity="0" offset="100%" />
        </radialGradient>
        <radialGradient
          cx="50%"
          cy="50%"
          fx="50%"
          fy="50%"
          r="50%"
          gradientTransform="matrix(0 1 -.9284 0 .964 0)"
          id="f"
        >
          <stop stopColor="#FFF2DE" offset="0%" />
          <stop stopColor="#5FB6E4" stopOpacity="0" offset="100%" />
        </radialGradient>
        <radialGradient
          cx="50%"
          cy="50%"
          fx="50%"
          fy="50%"
          r="50%"
          gradientTransform="matrix(0 1 -.9284 0 .964 0)"
          id="g"
        >
          <stop stopColor="#FFF2DE" offset="0%" />
          <stop stopColor="#5FB6E4" stopOpacity="0" offset="100%" />
        </radialGradient>
      </defs>
      <g transform="translate(0 6)" fillRule="nonzero" fill="none">
        <path
          d="M157.446 492.312C87.904 477.16 27.004 419.196 14.492 390.155-1.172 365.375-1.071 323.684.937 305.937c14.18-60.405 59.539-60.663 87.691-53.242 28.153 7.422 64.54 4.195 73.925 0 6.603-6.13 25.441-39.108 47.963-121.97 22.522-82.864 88.63-81.638 118.867-70.667 29.543 10.648 93.216 61.567 111.567 180.053 18.351 118.486-27.004 182.829-89.565 222.192-60.238 37.903-123.095 45.446-193.94 30.009z"
          fill="url(#a)"
        />
        <ellipse fill="url(#b)" cx="33.366" cy="273.538" rx="18.768" ry="17.425" />
        <ellipse fill="url(#c)" cx="275.269" cy="463.272" rx="22.939" ry="21.297" />
        <ellipse fill="url(#d)" cx="78.202" cy="344.204" rx="15.64" ry="14.52" />
        <ellipse fill="url(#b)" cx="262.757" cy="118.654" rx="18.768" ry="17.425" />
        <ellipse fill="url(#e)" cx="108.44" cy="416.806" rx="10.427" ry="9.68" />
        <ellipse fill="url(#e)" cx="298.208" cy="393.573" rx="10.427" ry="9.68" />
        <ellipse fill="url(#f)" cx="377.453" cy="168.991" rx="10.427" ry="9.68" />
        <ellipse fill="url(#g)" cx="316.977" cy="72.189" rx="18.768" ry="17.425" />
        <path fill="url(#h)" transform="rotate(31.77 248.556 302.672)" d="M222.541 170.041h52.028v265.264h-52.028z" />
        <path fill="url(#i)" transform="rotate(31.77 165.39 277.768)" d="M138.748 144.245h53.285v267.048h-53.285z" />
        <path fill="url(#j)" transform="rotate(31.77 185.873 277.768)" d="M159.231 144.245h53.285v267.048h-53.285z" />
        <path fill="url(#k)" transform="rotate(31.77 228.506 302.22)" d="M201.864 168.696h53.285v267.048h-53.285z" />
        <path d="M140.263.684L96.07 205.468l94.42 3.066c25.42-117.792 54.37-174.437-50.228-207.85z" fill="url(#l)" />
        <path
          d="M511.916 196.96L344.9 345.123l-53.207-84.334c96.067-85.223 133.343-141.432 220.223-63.829z"
          fill="url(#m)"
        />
        <path fill="url(#n)" d="M185.598 257.081l-89.67-51.305 249.2-125.843-65.688 100.674z" />
        <path fill="url(#o)" d="M345.129 345.172V79.932l-68.817 105.515L250.245 292.9z" />
      </g>
    </SvgIcon>
  );
};

const SvgIcon = styled.svg`
  stroke: none;
`;

UpgradedAccountIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

export default UpgradedAccountIcon;
