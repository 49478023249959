const LeftAlign = () => (
  <svg width="19" height="19" xmlns="http://www.w3.org/2000/svg">
    <g stroke="null" fillRule="evenodd">
      <rect id="Rectangle" x="0" y="0" width="16" height="2"></rect>
      <rect id="Rectangle-Copy" x="0" y="4" width="11" height="2"></rect>
      <rect id="Rectangle-Copy-2" x="0" y="8" width="16" height="2"></rect>
      <rect id="Rectangle-Copy-3" x="0" y="12" width="12" height="2"></rect>
    </g>
  </svg>
);

const RightAlign = () => (
  <svg width="19" height="19" xmlns="http://www.w3.org/2000/svg">
    <g stroke="null" fillRule="evenodd">
      <rect id="Rectangle" x="0" y="0" width="16" height="2"></rect>
      <rect id="Rectangle-Copy" x="5" y="4" width="11" height="2"></rect>
      <rect id="Rectangle-Copy-2" x="0" y="8" width="16" height="2"></rect>
      <rect id="Rectangle-Copy-3" x="4" y="12" width="12" height="2"></rect>
    </g>
  </svg>
);

const CenterAlign = () => (
  <svg width="19" height="19" xmlns="http://www.w3.org/2000/svg">
    <g stroke="null" fillRule="evenodd">
      <rect id="Rectangle" x="0" y="0" width="16" height="2"></rect>
      <rect id="Rectangle-Copy" x="3" y="4" width="11" height="2"></rect>
      <rect id="Rectangle-Copy-2" x="0" y="8" width="16" height="2"></rect>
      <rect id="Rectangle-Copy-3" x="2" y="12" width="12" height="2"></rect>
    </g>
  </svg>
);

const JustifyAlign = () => (
  <svg width="19" height="19" xmlns="http://www.w3.org/2000/svg">
    <g stroke="null" fillRule="evenodd">
      <rect id="Rectangle" x="0" y="0" width="16" height="2"></rect>
      <rect id="Rectangle-Copy" x="0" y="4" width="16" height="2"></rect>
      <rect id="Rectangle-Copy-2" x="0" y="8" width="16" height="2"></rect>
      <rect id="Rectangle-Copy-3" x="0" y="12" width="16" height="2"></rect>
    </g>
  </svg>
);

export default {
  LeftAlign,
  RightAlign,
  CenterAlign,
  JustifyAlign,
};
