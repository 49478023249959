import React from 'react';
import Button from 'imports/core/ui/atoms/Button';
import Flex from 'imports/core/ui/atoms/Flex';
import WishlistHeartIcon from 'imports/job-tracking/ui/assets/WishlistHeartIcon';
import { darken } from 'polished';
import { getHelpArticleThanksState, updateHelpArticleThanksState } from 'imports/help/api/helpers';
import { Push } from 'components/Link';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';

import useIntl from '/imports/core/api/useIntl';

const HelpFeedback = () => {
  const { t, locale } = useIntl();
  const { query } = useRouter();
  const [thanks, setThanks] = React.useState(false);
  const goToContactPage = () => {
    setThanks(updateHelpArticleThanksState(query.slug, false));
    return Push(`/contact-us`, locale);
  };

  const handleThanks = () => {
    setThanks(updateHelpArticleThanksState(query.slug, !thanks));
  };

  React.useEffect(() => {
    setThanks(getHelpArticleThanksState(query.slug));
  }, []);
  return (
    <StyledFlex>
      <ThanksButton onClick={handleThanks} thanks={thanks}>
        <WishlistHeartIcon />
        <span>{t('help.thanks')}</span>
      </ThanksButton>
      <NotHelpButton onClick={goToContactPage}>{t('help.it_didnt_help')}</NotHelpButton>
    </StyledFlex>
  );
};

const StyledFlex = styled(Flex)`
  > button:not(:last-child) {
    margin-right: 12px;
  }
  margin: 0;
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
      > button:first-child {
        margin-right: 0px;
      }
      > button:last-child {
        margin-right: 12px;
      }
    `};
`;

const ThanksButton = styled(Button)`
  min-width: 96px;
  height: 32px;
  border-radius: 4px;
  font-family: ${({ theme }) => theme.font.family.websiteSmiBold};
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  padding: 0;
  background: #1688fe;
  color: #fff;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  > svg {
    margin-right: 4px;
    ${({ theme: { isRTL } }) =>
      isRTL &&
      css`
        margin-right: 0px;
        margin-left: 4px;
      `};
    ${({ thanks }) =>
      thanks &&
      css`
        fill: #fff;
      `}
  }
`;

const NotHelpButton = styled(ThanksButton)`
  min-width: 120px;
  color: #1688fe;
  background: #fff;
  &:hover {
    background: ${darken(0.1, '#fff')};
    border-color: ${darken(0.1, '#fff')};
  }
`;

export default HelpFeedback;
