import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';

import Flex from '/imports/core/ui/atoms/Flex';
import { Option as StyledOption, OptionWrapper as StyledOptionWrapper } from '/imports/carrer/atmos/CarreeModalAtmos';

import intlHook from '/imports/core/api/useIntl';
import useTracking from '/imports/core/hooks/useTracking';
import { getCountry } from 'imports/checkout/api/utils';
import { isWithCareerLead } from 'imports/carrer/api/helper';

const Tab1Sub = ({ field, handleClick, newVariant }) => {
  const { t, locale } = intlHook();
  const country = getCountry();
  const { trackEvent } = useTracking();
  const isNewWithoutPriceVar =
    ['without_first_step_flow'].includes(marvelEmitter.getActiveVariant('exp_career_status_v2')) ||
    isWithCareerLead(locale, country);

  useEffect(() => {
    trackEvent('how_quickly_you_Looking_job_view');
  }, []);

  if (isNewWithoutPriceVar) {
    return (
      <Fragment>
        <StyledOptionWrapper fullWidth direction="column" smallGap>
          <StyledFlex alignItems="center">
            <RadioInput
              type="radio"
              name="howQuicklyLookingForNewJob"
              value="As soon as possible"
              onClick={handleClick('howQuicklyLookingForNewJob', 'As soon as possible')}
            />{' '}
            <StyledOption
              onClick={handleClick('howQuicklyLookingForNewJob', 'As soon as possible')}
              checked={field.howQuicklyLookingForNewJob === 'As soon as possible'}
            >
              {t('career_option_2_1')}
            </StyledOption>
          </StyledFlex>
          <StyledFlex alignItems="center">
            <RadioInput
              type="radio"
              name="howQuicklyLookingForNewJob"
              value="1-3 months"
              onClick={handleClick('howQuicklyLookingForNewJob', '1-3 months')}
            />{' '}
            <StyledOption
              onClick={handleClick('howQuicklyLookingForNewJob', '1-3 months')}
              checked={field.howQuicklyLookingForNewJob === '1-3 months'}
            >
              {t('career_option_2_2')}
            </StyledOption>
          </StyledFlex>
          <StyledFlex alignItems="center">
            <RadioInput
              type="radio"
              name="howQuicklyLookingForNewJob"
              value="4-6 months"
              onClick={handleClick('howQuicklyLookingForNewJob', '4-6 months')}
            />{' '}
            <StyledOption
              onClick={handleClick('howQuicklyLookingForNewJob', '4-6 months')}
              checked={field.howQuicklyLookingForNewJob === '4-6 months'}
            >
              {t('career_option_6_3')}
            </StyledOption>
          </StyledFlex>
          <StyledFlex alignItems="center">
            <RadioInput
              type="radio"
              name="howQuicklyLookingForNewJob"
              value="6+ months"
              onClick={handleClick('howQuicklyLookingForNewJob', '6+ months')}
            />{' '}
            <StyledOption
              onClick={handleClick('howQuicklyLookingForNewJob', '6+ months')}
              checked={field.howQuicklyLookingForNewJob === '6+ months'}
            >
              {t('career_option_6_4')}
            </StyledOption>
          </StyledFlex>
          {newVariant && (
            <StyledFlex alignItems="center">
              <RadioInput
                type="radio"
                name="howQuicklyLookingForNewJob"
                value="As soon as possible"
                onClick={handleClick('howQuicklyLookingForNewJob', "I'm not looking for a job")}
              />{' '}
              <StyledOption
                onClick={handleClick('howQuicklyLookingForNewJob', "I'm not looking for a job")}
                checked={field.howQuicklyLookingForNewJob === "I'm not looking for a job"}
              >
                {t('new_var_option')}
              </StyledOption>
            </StyledFlex>
          )}
        </StyledOptionWrapper>
      </Fragment>
    );
  }
  return (
    <Fragment>
      <Wrapper direction="column" fullWidth alignItems="center">
        <Title>{t('career_question_2')}</Title>
        <OptionWrapper>
          <Option
            alignItems="center"
            justifyContent="center"
            onClick={handleClick('howQuicklyLookingForNewJob', 'As soon as possible')}
            selected={field.howQuicklyLookingForNewJob === 'As soon as possible'}
          >
            {t('career_option_2_1')}
          </Option>
          <Option
            alignItems="center"
            justifyContent="center"
            onClick={handleClick('howQuicklyLookingForNewJob', '1-3 months')}
            selected={field.howQuicklyLookingForNewJob === '1-3 months'}
          >
            {t('career_option_2_2')}
          </Option>
          <Option
            alignItems="center"
            justifyContent="center"
            onClick={handleClick('howQuicklyLookingForNewJob', '4-6 months')}
            selected={field.howQuicklyLookingForNewJob === '4-6 months'}
          >
            {t('career_option_6_3')}
          </Option>{' '}
          <Option
            alignItems="center"
            justifyContent="center"
            onClick={handleClick('howQuicklyLookingForNewJob', '6+ months')}
            selected={field.howQuicklyLookingForNewJob === '6+ months'}
          >
            {t('career_option_6_4')}
          </Option>
        </OptionWrapper>
      </Wrapper>
    </Fragment>
  );
};

const RadioInput = styled.input`
  position: absolute;
  right: 16px;
  z-index: 1;
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      right: unset;
      left: 16px;
    `}
`;
const StyledFlex = styled(Flex)`
  width: 100%;
  position: relative;
`;

const Option = styled(Flex)`
  min-width: 300px;
  padding: 24px;
  border-radius: 8px;
  border: 1px solid var(--grayscale-n200);
  background: var(--light-values-white);
  cursor: pointer;
  color: var(--dark-values-black);
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 24px;
  &:hover {
    border-color: #1688fe;
  }
  ${({ selected }) =>
    selected &&
    css`
      border-color: #1688fe;
    `}
`;

const OptionWrapper = styled(Flex)`
  gap: 24px;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
`;

const SubTitle = styled.div`
  color: var(--grayscale-n500);
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 24px;
`;

const Title = styled.div`
  color: #33334f;
  text-align: center;
  font-family: ${({ theme }) => theme.font.family.websiteBold};
  font-size: 50px;
  line-height: 60px;
  letter-spacing: -0.25px;
`;

const Wrapper = styled(Flex)`
  gap: 32px;
`;

Tab1Sub.propTypes = {
  field: PropTypes.object,
  handleClick: PropTypes.func,
  newVariant: PropTypes.bool,
};

export default Tab1Sub;
