import styled from 'styled-components';

import TextAtom from '/imports/pdf/core/ui/atoms/Text';

const Text = styled(TextAtom)`
  font-family: ${({ theme }) => theme.contentFont()};
  font-size: ${({ theme }) => theme.convertPx(11.5, true)};
  line-height: 1.3;
  color: #808389;
`;

export default Text;
