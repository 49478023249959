import styled from 'styled-components';

const StyledParagraph = styled.p`
  margin: 0;
  color: #3a3a43;
  font-weight: 500;
  @media (max-width: 479px) {
    font-size: 14px;
    line-height: 22px;
  }
`;
export default StyledParagraph;
