import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const FreeAccountIcon = ({ width, height }) => {
  return (
    <SvgIcon viewBox="0 0 512 512" width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M181.124 503.351c-80-17.431-150.06-84.112-164.453-117.52-18.019-28.508-17.903-76.468-15.593-96.884 16.313-69.489 68.493-69.786 100.879-61.249 32.387 8.538 74.247 4.826 85.042 0 7.597-7.052 29.268-44.989 55.177-140.314C268.085-7.941 344.134-6.531 378.919 6.09c33.986 12.25 107.235 70.826 128.346 207.132C528.376 349.527 476.2 423.546 404.23 468.829c-69.297 43.603-141.607 52.281-223.106 34.522z"
        fill="url(#paint0_linear)"
      />
      <ellipse cx="38.384" cy="251.676" rx="21.591" ry="20.045" fill="url(#paint1_radial)" />
      <ellipse cx="316.667" cy="469.943" rx="26.389" ry="24.499" fill="url(#paint2_radial)" />
      <ellipse cx="89.962" cy="332.969" rx="17.992" ry="16.704" fill="url(#paint3_radial)" />
      <ellipse cx="302.273" cy="73.498" rx="21.591" ry="20.045" fill="url(#paint4_radial)" />
      <ellipse cx="124.748" cy="416.49" rx="11.995" ry="11.136" fill="url(#paint5_radial)" />
      <ellipse cx="343.056" cy="389.763" rx="11.995" ry="11.136" fill="url(#paint6_radial)" />
      <ellipse cx="434.218" cy="131.406" rx="11.995" ry="11.136" fill="url(#paint7_radial)" />
      <ellipse cx="364.647" cy="20.045" rx="21.591" ry="20.045" fill="url(#paint8_radial)" />
      <path
        transform="matrix(.88329 .46883 -.54702 .83712 342.966 143.435)"
        fill="url(#paint9_linear)"
        d="M0 0h59.853v305.157H0z"
      />
      <path
        transform="matrix(.88329 .46883 -.54702 .83712 247.217 113.588)"
        fill="url(#paint10_linear)"
        d="M0 0h61.298v307.209H0z"
      />
      <path
        transform="matrix(.88329 .46883 -.54702 .83712 270.78 113.588)"
        fill="url(#paint11_linear)"
        d="M0 0h61.298v307.209H0z"
      />
      <path
        transform="matrix(.88329 .46883 -.54702 .83712 319.824 141.717)"
        fill="url(#paint12_linear)"
        d="M0 0h61.298v307.209H0z"
      />
      <g filter="url(#filter0_d)">
        <path d="M397.033 334.083V28.953l-79.166 121.384-29.988 123.611 109.154 60.135z" fill="url(#paint13_linear)" />
      </g>
      <g filter="url(#filter1_d)">
        <path
          d="M213.51 232.744l-103.156-59.021 286.679-144.77-75.568 115.816-107.955 87.975z"
          fill="url(#paint14_linear)"
        />
      </g>
      <defs>
        <radialGradient
          id="paint1_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(0 20.045 -21.5909 0 38.384 251.676)"
        >
          <stop stopColor="#E8BA75" />
          <stop offset="1" stopColor="#5FB6E4" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint2_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(0 24.4994 -26.3889 0 316.667 469.943)"
        >
          <stop stopColor="#5FB6E4" />
          <stop offset="1" stopColor="#5FB6E4" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint3_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(0 16.7041 -17.9924 0 89.962 332.969)"
        >
          <stop stopColor="#5FB6E4" />
          <stop offset="1" stopColor="#5FB6E4" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint4_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(0 20.045 -21.5909 0 302.273 73.498)"
        >
          <stop stopColor="#E8BA75" />
          <stop offset="1" stopColor="#5FB6E4" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint5_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(0 11.1361 -11.995 0 124.748 416.49)"
        >
          <stop stopColor="#E8BA75" />
          <stop offset="1" stopColor="#5FB6E4" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint6_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(0 11.1361 -11.995 0 343.056 389.763)"
        >
          <stop stopColor="#E8BA75" />
          <stop offset="1" stopColor="#5FB6E4" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint7_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(0 11.1361 -11.995 0 434.218 131.406)"
        >
          <stop stopColor="#FFF2DE" />
          <stop offset="1" stopColor="#5FB6E4" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint8_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(0 20.045 -21.5909 0 364.647 20.045)"
        >
          <stop stopColor="#FFF2DE" />
          <stop offset="1" stopColor="#5FB6E4" stopOpacity="0" />
        </radialGradient>
        <linearGradient id="paint0_linear" x1="337.059" y1="0" x2="172.221" y2="503.248" gradientUnits="userSpaceOnUse">
          <stop stopColor="#6079A0" />
          <stop offset=".765" stopColor="#1B2946" />
        </linearGradient>
        <linearGradient
          id="paint9_linear"
          x1="71.255"
          y1="-14.293"
          x2="-21.555"
          y2="332.137"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#91C4E5" />
          <stop offset=".839" stopColor="#D8F0FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint10_linear"
          x1="-23.073"
          y1=".176"
          x2="106.267"
          y2="335.668"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#91C4E5" />
          <stop offset=".839" stopColor="#D8F0FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint11_linear"
          x1="-23.073"
          y1=".176"
          x2="106.267"
          y2="335.668"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3DA1E2" />
          <stop offset=".807" stopColor="#79C0EF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint12_linear"
          x1="72.975"
          y1="-14.389"
          x2="-19.078"
          y2="335.16"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3DA1E2" />
          <stop offset=".839" stopColor="#79C0EF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint13_linear"
          x1="395.834"
          y1="44.544"
          x2="310.591"
          y2="284.856"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5DB4E3" />
          <stop offset="1" stopColor="#137CC2" />
        </linearGradient>
        <linearGradient
          id="paint14_linear"
          x1="351.453"
          y1="131.406"
          x2="110.4"
          y2="122.364"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2181C0" />
          <stop offset="1" stopColor="#5FB6E4" />
        </linearGradient>
        <filter
          id="filter0_d"
          x="278.879"
          y="26.954"
          width="118.154"
          height="313.129"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dx="-5" dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix values="0 0 0 0 0.568627 0 0 0 0 0.768627 0 0 0 0 0.898039 0 0 0 1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="filter1_d"
          x="104.354"
          y="28.954"
          width="294.68"
          height="213.79"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dx="-2" dy="6" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix values="0 0 0 0 0.54902 0 0 0 0 0.737255 0 0 0 0 0.87451 0 0 0 1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </SvgIcon>
  );
};

const SvgIcon = styled.svg`
  stroke: none;
`;

FreeAccountIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

export default FreeAccountIcon;
