import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { displayTemplateTitle } from '/imports/generator/api/helpers';
import { staticFile } from '/lib/helpers';
import View from '/imports/pdf/core/ui/atoms/View';

const circumference = 314;

const CircleText = ({ firstName, lastName, swapRtlColumns }) => {
  // Refer https://bl.ocks.org/tophtucker/62f93a4658387bb61e4510c37e2e97cf
  // We could have used document to create a canvas instead, but canvas doesn't work outside of browser window
  const measureText = (string, fontSize = 10) => {
    // ASCII character widths indexed by character code
    const widths = [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.2484375,
      0.334375, 0.409375, 0.5, 0.5, 0.834375, 0.778125, 0.18125, 0.334375, 0.334375, 0.5, 0.5640625, 0.25, 0.334375,
      0.25, 0.278125, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.278125, 0.278125, 0.5640625, 0.5640625,
      0.5640625, 0.4453125, 0.921875, 0.7234375, 0.6671875, 0.6671875, 0.7234375, 0.6109375, 0.55625, 0.7234375,
      0.7234375, 0.334375, 0.390625, 0.7234375, 0.6109375, 0.890625, 0.7234375, 0.7234375, 0.55625, 0.7234375,
      0.6671875, 0.55625, 0.6109375, 0.7234375, 0.7234375, 0.9453125, 0.7234375, 0.7234375, 0.6109375, 0.334375,
      0.340625, 0.334375, 0.4703125, 0.5, 0.3453125, 0.4453125, 0.5, 0.4453125, 0.5, 0.4453125, 0.3828125, 0.5, 0.5,
      0.278125, 0.3359375, 0.5, 0.278125, 0.778125, 0.5, 0.5, 0.5, 0.5, 0.3375, 0.390625, 0.2796875, 0.5, 0.5,
      0.7234375, 0.5, 0.5, 0.4453125, 0.48125, 0.2015625, 0.48125, 0.5421875,
    ];
    const avg = 0.5126315789473684;
    return parseInt(
      string
        .split('')
        .map((c) => (c.charCodeAt(0) < widths.length ? widths[c.charCodeAt(0)] : avg))
        .reduce((cur, acc) => acc + cur) * fontSize,
    );
  };

  if (!firstName && !lastName) return null;
  const fullName = displayTemplateTitle(firstName, lastName, ' ') + ' ';
  const fullNameLength = measureText(fullName);
  const repeatTimes = Math.floor(circumference / fullNameLength);
  const textLength = parseInt(circumference - fullNameLength / fullName.length);

  return (
    <Container>
      <SVG viewBox="0 0 100 100" swapRtlColumns={swapRtlColumns}>
        <path d="M 50, 50 m -50, 0 a 50,50 0 0,1 100,0 a 40,40 0 0,1 -100,0" id="curve" />
        <text textLength={textLength} lengthAdjust="spacingAndGlyphs">
          <textPath xlinkHref="#curve" textLength={textLength} lengthAdjust="spacingAndGlyphs">
            {fullName.repeat(repeatTimes > 2 ? repeatTimes - 1 : repeatTimes)}
          </textPath>
        </text>
      </SVG>
      <Logo swapRtlColumns={swapRtlColumns}>
        <span>{firstName && firstName.charAt(0)}</span>
        {firstName && lastName && <div />}
        <span>{lastName && lastName.charAt(0)}</span>
      </Logo>
    </Container>
  );
};

CircleText.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  swapRtlColumns: PropTypes.bool,
};

const Container = styled(View)`
  width: 25%;
  position: relative;
  direction: unset;
  @font-face {
    font-family: 'Roboto';
    src: url(${staticFile('fonts/Roboto-Regular.ttf')});
  }
  @font-face {
    font-family: 'Raleway Light';
    src: url(${staticFile('fonts/Raleway-Light.ttf')});
  }
`;

const SVG = styled.svg`
  ${({ theme: { convertPx, isRTL }, swapRtlColumns }) => css`
    width: ${convertPx(100)};
    height: ${convertPx(100)};
    overflow: visible;
    position: absolute;
    top: ${convertPx(-26)};
    right: 0;
    direction: ltr;
    path {
      fill: transparent;
    }
    text {
      font-size: ${convertPx(10)};
      font-family: Roboto;
      text-transform: uppercase;
      color: #808389;
      letter-spacing: ${convertPx(2)};
      white-space: pre;
    }
    ${isRTL &&
    swapRtlColumns &&
    css`
      left: 0;
      right: unset;
    `}
  `}
`;

const Logo = styled(View)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  ${({ theme: { convertPx, isRTL }, swapRtlColumns }) => css`
    width: ${convertPx(100)};
    height: ${convertPx(100)};
    top: ${convertPx(-26)};
    right: 0;
    span {
      font-family: 'Raleway Light';
      text-transform: uppercase;
      font-size: ${convertPx(28)};
      line-height: 0.8em;
      color: #808389;
    }
    div {
      width: ${convertPx(20)};
      height: ${convertPx(1)};
      background: #808389;
      margin-bottom: ${convertPx(2)};
    }
    ${isRTL &&
    swapRtlColumns &&
    css`
      left: 0;
      right: unset;
    `}
  `}
`;

export default CircleText;
