import { getArticlesImages } from 'imports/help/api/articlesImages';
import { useResponsive } from 'imports/core/api/responsiveContext';

import ArticlePageWrapper from '/imports/help/ui/components/ArticlePageWrapper';
import ImageWrapper from '/imports/help/ui/atoms/ImageWrapper';
import { StyledTextWithSpan } from '/imports/help/ui/atoms/Typography';
import useIntl from '/imports/core/api/useIntl';

const DeleteAccount = () => {
  const { t } = useIntl();
  const { host } = useResponsive();
  const images = getArticlesImages(host);
  return (
    <ArticlePageWrapper title="help.delete_account">
      <StyledTextWithSpan dangerouslySetInnerHTML={{ __html: t('help.delete_account.delete_account') }} />
      <ImageWrapper src={images.delete_account_1}></ImageWrapper>
      <StyledTextWithSpan dangerouslySetInnerHTML={{ __html: t('help.delete_account.downgrade_account') }} />
      <ImageWrapper src={images.delete_account_2}></ImageWrapper>
    </ArticlePageWrapper>
  );
};

export default DeleteAccount;
