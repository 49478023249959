import React from 'react';

const LoginIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#bwc9ea2jba)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 8a3 3 0 1 1 0-6 3 3 0 0 1 0 6zM3 5a5 5 0 1 1 10 0A5 5 0 0 1 3 5zm1.906 6a4.521 4.521 0 0 0-4.027 2.466l-.51.999A3.377 3.377 0 0 0 0 16h2c0-.218.052-.432.15-.626l.51-.999A2.521 2.521 0 0 1 4.907 13h6.188c.947 0 1.815.531 2.245 1.375l.51 1c.1.193.151.407.151.625h2c0-.534-.127-1.06-.37-1.535l-.51-1A4.521 4.521 0 0 0 11.095 11H4.906z"
          fill="#4F5E75"
        />
      </g>
      <defs>
        <clipPath id="bwc9ea2jba">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LoginIcon;
