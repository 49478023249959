import React from 'react';
import styled from 'styled-components';

const MoveUp = () => {
  return (
    <SvgIcon width="12" height="14" xmlns="http://www.w3.org/2000/svg">
      <g>
        <g stroke="null" clipPath="url(#a)">
          <path
            stroke="#429FF0"
            strokeLinejoin="round"
            strokeLinecap="round"
            strokeWidth="2"
            d="M5.813 13.75V0m0 0L0 6.21M5.813 0l5.812 6.21"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

const SvgIcon = styled.svg`
  stroke: none;
`;

export default MoveUp;
