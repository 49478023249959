import React from 'react';
import useDomain from 'imports/core/hooks/useDomain';
import { getDomainName, isBlogDomain } from 'lib/helpers';
import { replaceString } from 'imports/core/ui/helpers';
import { useResponsive } from 'imports/core/api/responsiveContext';

import ArticlePageWrapper from '/imports/help/ui/components/ArticlePageWrapper';
import { TextWithLinkWrapper } from '/imports/help/ui/atoms/Typography';
import useIntl from '/imports/core/api/useIntl';

const RefundPolicy = () => {
  const { t, locale } = useIntl();
  const { host } = useResponsive();
  const { getBrandName } = useDomain();
  const blog = isBlogDomain(host);

  const getRefundUrl = () => {
    return blog ? `builder/${locale}/refund-cancellation-policy` : `${locale}/refund-cancellation-policy`;
  };
  const getCancelSubscriptionUrl = () => {
    return blog ? `builder/${locale}/cancel-subscription` : `${locale}/cancel-subscription`;
  };
  const refundUrl = `https://${getDomainName(host)}/${getRefundUrl()}`;
  const cancelSubscriptionUrl = `https://${getDomainName(host)}/${getCancelSubscriptionUrl()}`;
  const content = replaceString(t('help.refund_policy.description'), {
    REFUND_URL: refundUrl,
    CANCEL_URL: cancelSubscriptionUrl,
  });

  React.useEffect(() => {
    const goToPolicyPage = () => {
      window.open(refundUrl, '_ blank');
    };
    const goToCancelPage = () => {
      window.open(cancelSubscriptionUrl, '_blank');
    };
    const policyLink = document.getElementById('policy_link');
    const cancelLink = document.getElementById('cancel_link');
    policyLink?.addEventListener('click', goToPolicyPage);
    cancelLink?.addEventListener('click', goToCancelPage);
    return () => {
      policyLink?.removeEventListener('click', goToPolicyPage);
      cancelLink?.removeEventListener('click', goToCancelPage);
    };
  }, []);

  return (
    <ArticlePageWrapper title="help.refund_policy">
      <TextWithLinkWrapper dangerouslySetInnerHTML={{ __html: content }} />
    </ArticlePageWrapper>
  );
};

export default RefundPolicy;
