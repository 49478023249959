import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const AlertIcon = ({ width, height }) => {
  return (
    <SvgIcon viewBox="0 0 113 113" width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M56.098 112.196c30.982 0 56.098-25.116 56.098-56.098S87.08 0 56.098 0 0 25.116 0 56.098s25.116 56.098 56.098 56.098z"
        fill="#ECD0D0"
      />
      <path
        d="M81.345 72.763L59.182 36.782c-1.476-2.398-3.888-2.364-5.364.033L31.655 72.748C30.18 75.148 31.282 77 34.103 77h44.792c2.824 0 3.925-1.84 2.45-4.237zM56.481 44.746c1.42 0 2.518 1.561 2.445 3.47l-.45 11.5c-.072 1.906-.972 3.467-2 3.467-1.027 0-1.93-1.561-2.002-3.468l-.444-11.5c-.073-1.908 1.029-3.47 2.451-3.47zm0 27.1c-1.79 0-3.019-1.304-2.982-3.086 0-1.82 1.232-3.086 2.982-3.086 1.825 0 2.98 1.265 3.018 3.086 0 1.782-1.193 3.086-3.018 3.086z"
        fill="#F80F0F"
      />
    </SvgIcon>
  );
};

const SvgIcon = styled.svg`
  stroke: none;
`;

AlertIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

export default AlertIcon;
